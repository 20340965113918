import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

interface DelayIconProps {
  delayVerification: boolean;
  active: boolean;
}

export const DelayIcon: React.FC<DelayIconProps> = props => {
  return (
    <>
      {props.delayVerification && props.active && (
        <OverlayTrigger
          overlay={<Tooltip>Czeka na weryfikację wniosku</Tooltip>}
        >
          <div className="px-2">
            <Icon.AlarmFill className="icon-red" />
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};
