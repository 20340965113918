import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const UsersInRoleLink = (props: { roleId: string }) => {
  const nav = useNavigation();

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>Użytkownicy przypisani do roli</Tooltip>}
      >
        <Button
          onClick={() => nav.usersInRole(props.roleId)}
          variant="btn-info"
        >
          <Icon.PersonFill />
        </Button>
      </OverlayTrigger>
    </>
  );
};
