import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  GetCompanyDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  ApplicationTypeEnum,
  ApplicationTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import TeamSelector from '../Selects/TeamSelector';
import { Option } from '../Selects/Selector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { ApplicationEditorBasicSpecificFormFields } from './ApplicationEditorBasicSpecificFormFields';

interface ApplicationEditorBasicFormFieldsProps {
  application: UpdateAdminApplicationDto;
  show?: boolean;
  isReadOnly?: boolean;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

export const ApplicationEditorBasicFormFields: React.FC<
  ApplicationEditorBasicFormFieldsProps
> = props => {
  const [cardClassName, setCardClassName] = useState('card');
  const pok = usePokCore();
  const notifications = useNotifications();
  const [company, setCompany] = useState<GetCompanyDto>();

  useEffect(() => {
    const companyId = pok.getCompanyId();
    pok.companies
      .getById(companyId)
      .then(company => {
        setCompany(company);
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  }, [pok, notifications]);

  const applicationTypeOptions = useMemo(() => {
    if (
      company &&
      (company.shortname === 'CH' || company.shortname === 'MRE')
    ) {
      return Object.entries(ApplicationTypeEnum)
        .filter(([, value]) => value !== ApplicationTypeEnum.DictionaryValue)
        .map(([key, value]) => ({
          value: key,
          label: ApplicationTypeEnumText[value],
        }));
    }

    return Object.entries(ApplicationTypeEnum).map(([key, value]) => ({
      value: key,
      label: ApplicationTypeEnumText[value],
    }));
  }, [company]);

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  useEffect(() => {
    switch (props.application.applicationType) {
      case ApplicationTypeEnum.Position:
        setCardClassName('card col-lg-6 col-12');
        break;
      case ApplicationTypeEnum.Purchaser:
        setCardClassName('card col-lg-6 col-12');
        break;
      case ApplicationTypeEnum.Permission:
        setCardClassName('card col-lg-6 col-12');
        break;
      default:
        setCardClassName('card');
    }
  }, [props.application.applicationType]);

  return (
    <>
      <Card className={cardClassName}>
        <Card.Header>Dane wniosku</Card.Header>
        <Card.Body className="m-3">
          <div className="d-grid gap-4 mb-3">
            <FormRow controlId="applicationType" label="Typ wniosku">
              <Select
                value={
                  props.application?.applicationType && applicationTypeOptions
                    ? applicationTypeOptions.find(
                        ({ value }) =>
                          props.application?.applicationType === value,
                      )
                    : null
                }
                options={applicationTypeOptions}
                onChange={option =>
                  propertyChange({
                    applicationType: option?.value,
                  })
                }
                isDisabled={props.isReadOnly}
                placeholder="Wskaż..."
              />
            </FormRow>
            <FormRow controlId="team" label="Zespół">
              <TeamSelector
                value={props.application.teamId}
                companyId={props.application.companyId}
                permission={PermissionsEnum.Projects}
                onChange={option => {
                  option
                    ? propertyChange({ teamId: (option as Option).value })
                    : null;
                }}
                readOnly={props.isReadOnly}
              />
            </FormRow>
            <FormRow controlId="description" label="Opis">
              <DynamicTextarea
                value={props.application.description ?? ''}
                onChange={e =>
                  propertyChange({
                    description: e.target.value || undefined,
                  })
                }
                disabled={props.isReadOnly}
              />
            </FormRow>
            <ApplicationEditorBasicSpecificFormFields
              application={props.application}
              propertyChange={propertyChange}
              show={props.show}
              isReadOnly={props.isReadOnly}
            />
            <FormRow controlId="active" label="Aktywny">
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={props.application?.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
                disabled={props.isReadOnly}
              />
            </FormRow>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
