import React, { useMemo } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { InvoiceStatusEnum } from '../../../common/pokCore/validation/schemas';
import { SalesInvoiceSearch } from '../../components/SalesInvoices/SalesInvoiceSearch';

enum SalesInvoicesTabs {
  DRAFTS_INVOICES = 'Szkice',
  GENERATED_INVOICES = 'Zatwierdzone',
  ALL_INVOICES = 'Wszystkie',
}

export const SalesInvoiceView: React.FC = () => {
  const nav = useNavigation();
  const pok = usePokCore();

  const getData = useMemo(
    () => (status?: InvoiceStatusEnum) =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.salesInvoices.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          status,
        )) as GridGetterFunction,
    [pok.salesInvoices],
  );

  const getCount = useMemo(
    () => (status?: InvoiceStatusEnum) =>
      ((filterText, filterColumns) =>
        pok.salesInvoices.getAllCount(
          filterText,
          filterColumns,
          status,
        )) as GridCountFunction,
    [pok.salesInvoices],
  );

  const onRowClick = (salesInvoiceId: string, newWindow?: boolean) => {
    nav.salesInvoiceEditor({ salesInvoiceId, newWindow });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Faktury sprzedaży</Card.Title>
        <TabsHistory
          activeKey={SalesInvoicesTabs.DRAFTS_INVOICES}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={SalesInvoicesTabs.DRAFTS_INVOICES}
            title={SalesInvoicesTabs.DRAFTS_INVOICES}
            className="m-3"
          >
            <SalesInvoiceSearch
              getCount={getCount(InvoiceStatusEnum.Draft)}
              getData={getData(InvoiceStatusEnum.Draft)}
              onRowClick={onRowClick}
              hideStatusColumn
              withFilterInColumns
              showNewWindowButton
            />
          </Tab>
          <Tab
            eventKey={SalesInvoicesTabs.GENERATED_INVOICES}
            title={SalesInvoicesTabs.GENERATED_INVOICES}
            className="m-3"
          >
            <SalesInvoiceSearch
              getCount={getCount(InvoiceStatusEnum.Generated)}
              getData={getData(InvoiceStatusEnum.Generated)}
              onRowClick={onRowClick}
              hideStatusColumn
              withFilterInColumns
              showNewWindowButton
            />
          </Tab>
          <Tab
            eventKey={SalesInvoicesTabs.ALL_INVOICES}
            title={SalesInvoicesTabs.ALL_INVOICES}
            className="m-3"
          >
            <SalesInvoiceSearch
              getCount={getCount()}
              getData={getData()}
              onRowClick={onRowClick}
              withFilterInColumns
              showNewWindowButton
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
