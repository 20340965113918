/*eslint complexity: ["error", 15]*/
import React from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import TeamSelector from '../Selects/TeamSelector';
import { Option } from '../Selects/Selector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';

interface ProjectMainFieldsFormProps {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  isCreatedFromOrder?: boolean;
  budgetId?: string;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
}

export const ProjectMainFieldsForm: React.FC<
  ProjectMainFieldsFormProps
> = props => {
  const pok = usePokCore();
  const auth = useAuth();

  const onChangeTeam = (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    let teamIds: string[] = [];
    if (Array.isArray(option)) {
      teamIds = (option as Option[]).map(({ value }) => value);
    } else {
      teamIds = option ? [(option as Option).value] : [];
    }
    props.propertyChange && props.propertyChange({ teamIds: teamIds });
  };

  const notAuthorized = props.project.id
    ? !auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  return (
    <>
      <FormRow controlId="name" label="Nazwa">
        <Form.Control
          type="text"
          value={props.project.name}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ name: e.target.value })
          }
          disabled={props.readOnly}
        />
      </FormRow>
      <FormRow controlId="team" label="Zespół">
        <TeamSelector
          value={
            props.project.id && !props.isCreatedFromOrder
              ? props.project.teamIds
              : props.project?.teamIds[0]
          }
          companyIds={[props.project.companyId]}
          permission={
            props.project.id && !props.isCreatedFromOrder
              ? null
              : PermissionsEnum.Projects
          }
          isMulti={props.project.id && !props.isCreatedFromOrder ? true : false}
          readOnly={props.readOnly || props.isCreatedFromOrder || notAuthorized}
          onChange={options => onChangeTeam(options)}
        />
      </FormRow>
      {props.project.id && (
        <FormRow controlId="team" label="Zespół prowadzący">
          <TeamSelector
            value={props.project?.mainTeamId}
            companyIds={[props.project.companyId]}
            permission={
              props.project.id && !props.isCreatedFromOrder
                ? null
                : PermissionsEnum.Projects
            }
            readOnly={
              props.readOnly || props.isCreatedFromOrder || notAuthorized
            }
            onChange={option =>
              props.propertyChange &&
              props.propertyChange({ mainTeamId: (option as Option)?.value })
            }
          />
        </FormRow>
      )}
    </>
  );
};
