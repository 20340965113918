import React from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { SpecialInvoicingSearch } from '../../components/SpecialInvoicing/SpecialInvoicingSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { SpecialInvoicingStatus } from '../../../common/pokCore/validation/schemas';

enum SpecialInvoicingDecisionTabs {
  NEW = 'Oczekujące',
  PENDING = 'Oczekujące na korektę',
  ALL = 'Wszystkie',
}

export const SpecialInvoicingView: React.FC = () => {
  const nav = useNavigation();
  const pok = usePokCore();

  const onRowClick = (id: string, newWindow?: boolean) => {
    nav.specialInvoicingEditor(id, undefined, newWindow);
  };

  const handleAdd = () => {
    nav.specialInvoicingEditor('utworz');
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          Wnioski o niestandardowe fakturowanie
        </Card.Title>
        <Button className="mb-4" variant="outline-primary" onClick={handleAdd}>
          Nowy wniosek
        </Button>
        <TabsHistory
          activeKey={SpecialInvoicingDecisionTabs.NEW}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={SpecialInvoicingDecisionTabs.NEW}
            title={SpecialInvoicingDecisionTabs.NEW}
            className="m-3"
          >
            <SpecialInvoicingSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                pok.specialInvoicing.getAllByPortion(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                  [SpecialInvoicingStatus.New],
                )
              }
              getCount={filterText =>
                pok.specialInvoicing.getAllCount(filterText, [
                  SpecialInvoicingStatus.New,
                ])
              }
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab
            eventKey={SpecialInvoicingDecisionTabs.PENDING}
            title={SpecialInvoicingDecisionTabs.PENDING}
            className="m-3"
          >
            <SpecialInvoicingSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                pok.specialInvoicing.getAllByPortion(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                  [SpecialInvoicingStatus.ToBeCorrected],
                )
              }
              getCount={filterText =>
                pok.specialInvoicing.getAllCount(filterText, [
                  SpecialInvoicingStatus.ToBeCorrected,
                ])
              }
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab
            eventKey={SpecialInvoicingDecisionTabs.ALL}
            title={SpecialInvoicingDecisionTabs.ALL}
            className="m-3"
          >
            <SpecialInvoicingSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                pok.specialInvoicing.getAllByPortion(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                )
              }
              getCount={filterText =>
                pok.specialInvoicing.getAllCount(filterText)
              }
              onRowClick={onRowClick}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
