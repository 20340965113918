import { schema, TranslationSchema } from './resources';

type RawNamespaceType = { readonly [key: string]: SchemaType };
type NamespaceType<N extends RawNamespaceType, K extends keyof N> = {
  readonly [k in K]: MapSchema<N[k], keyof N[k]>;
};
type SchemaType = { readonly [key: string]: string | SchemaType };
type MapSchema<S extends SchemaType, K extends keyof S> = {
  [k in K]: S[k] extends SchemaType ? MapSchema<S[k], keyof S[k]> : string;
};

function assertNotALeaf(obj: SchemaType[string]): asserts obj is SchemaType {
  if (typeof obj === 'string' || obj === undefined) {
    console.error('Translation key mapping failed verifying leaf');
  }
}

function deepRewrite<O extends SchemaType, K extends keyof O>(
  obj: O,
  key: K,
  value: string,
) {
  const test = obj[key];
  if (typeof test === 'string' || test === undefined) {
    return value;
  }
  assertNotALeaf(test);
  const rewrite: Record<string, unknown> = {};
  for (const subKey of Object.keys(test)) {
    rewrite[subKey] = deepRewrite(
      test,
      subKey,
      value ? `${value}.${subKey}` : subKey,
    );
  }

  return rewrite as MapSchema<O, K>;
}

function rewrite<NS extends RawNamespaceType, K extends keyof NS>(data: NS) {
  const namespaces: Partial<Record<K, unknown>> = {};
  for (const ns of Object.keys(data) as Array<K>) {
    namespaces[ns] = deepRewrite(data, ns as K, '');
  }

  return namespaces as NamespaceType<NS, K>;
}

export const keys = rewrite(schema);

export type TKeys<T extends keyof TranslationSchema> = MapSchema<
  TranslationSchema[T],
  keyof TranslationSchema[T]
>;
export const getKeys = <T extends keyof TranslationSchema>(ns: T) => {
  return keys[ns] as TKeys<T>;
};
