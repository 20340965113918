/*eslint max-lines-per-function: ["error", 178]*/
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  GetEstimateByMonthDto,
  GetPaeEstimateItemsDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { usePokCore } from '../../../common/hooks/usePokCore';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';

import { PaePositionsGrid } from './PaePositionsGrid';

interface PaeImportFormProps {
  estimateByMonth: GetEstimateByMonthDto;
  project: GetProjectDto;
  show?: boolean;
  handleClose?: () => void;
  onCompleted: (paePositions: GetPaeEstimateItemsDto[]) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const PaeImportForm: React.FC<PaeImportFormProps> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [paePositions, setPaePositions] = useState<GetPaeEstimateItemsDto[]>(
    [],
  );
  const [paeUrl, setPaeUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);

  const notifications = useNotifications();
  const pok = usePokCore();

  const refreshFunc = useCallback(() => {
    setIsLoading(true);
    pok.estimateItems
      .getEstimateItemsFromPae(
        props.project.number,
        props.estimateByMonth.date.getFullYear(),
        props.estimateByMonth.date.getMonth() + 1,
      )
      .then(data => {
        setPaePositions(data.positions);
        setPaeUrl(data.paeUrl);
        setSaveErrors([]);
        setShowSaveErrors(true);
        setIsLoading(false);
      })
      .catch(async errorResponse => {
        const errors = await responseErrors(errorResponse);
        setSaveErrors(errors);
        setShowSaveErrors(true);
        setIsLoading(false);
      });
  }, [pok.estimateItems, props.estimateByMonth.date, props.project.number]);

  useEffect(() => {
    if (props.show) {
      refreshFunc();
    }
  }, [props.show, refreshFunc]);

  const importSelectedPositions = () => {
    const selected = paePositions.filter(p =>
      selectedPositions.includes(p.idx),
    );
    if (selected.length === 0) {
      setSaveErrors(['Nie wybrano żadnych pozycji do importu.']);
      setShowSaveErrors(true);
      return;
    }
    void importPositions(selected);
  };

  const importAllPositions = () => {
    void importPositions(paePositions);
  };

  const importPositions = async (positions: GetPaeEstimateItemsDto[]) => {
    props
      .onCompleted(positions)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const handleOnRowClick = (key: string) => {
    let sel = [];

    if (!selectedPositions.includes(key)) {
      sel = [...selectedPositions, key];
    } else {
      sel = selectedPositions.filter(o => o !== key);
    }
    setSelectedPositions(sel);
  };

  const refreshPositions = async () => {
    refreshFunc();
  };

  const openPae = async () => {
    if (paeUrl) {
      window.open(paeUrl, '_blank');
    }
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Lista pozycji w PAE dla kampanii ${
            props.project?.number
          } za ${momencik(props.estimateByMonth?.date, 'YYYY-MM')}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-5 mt-5">
          {isLoading ? (
            <FormSkeleton />
          ) : (
            <PaePositionsGrid
              data={paePositions}
              enableMultiSelect={true}
              onRowClick={handleOnRowClick}
            />
          )}
        </div>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
        <section className="side-by-side">
          <Button variant="outline-success" onClick={refreshPositions}>
            Odśwież dane
            <Icon.ArrowClockwise className="icon mx-2 mb-1" />
          </Button>
          {paeUrl && (
            <Button
              variant="outline-success"
              onClick={openPae}
              className="ms-2"
            >
              Otwórz w PAE
              <Icon.BoxArrowUpRight className="icon mx-2 mb-1" />
            </Button>
          )}
          <div className="marginLeft">
            <Button variant="outline-secondary" onClick={props.handleClose}>
              Zamknij
            </Button>
          </div>
          <div>
            {paePositions.length > 0 && (
              <>
                <Button
                  variant="primary"
                  onClick={importSelectedPositions}
                  className="ms-2"
                >
                  Importuj zaznaczone
                </Button>
                <Button
                  variant="primary"
                  onClick={importAllPositions}
                  className="ms-2"
                >
                  Importuj wszystkie
                </Button>
              </>
            )}
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
