import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetEmployeeDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface EmployeeSelectorProps {
  fetchForOrders?: boolean;
  readOnly?: boolean;
  isMulti?: boolean;
  value?: string | string[];
  className?: string;
  onlyCurrentCompany?: boolean;
  onlyFromCompany?: string;
  withTechnical?: boolean;
  setOptions?: (employees: GetEmployeeDto[]) => void;
  onChange?: (option: Option | MultiValue<Option> | null | undefined) => void;
}

export default (props: EmployeeSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [employees, setEmployees] = useState<GetEmployeeDto[]>([]);
  const [employeesLoaded, setEmployeesLoaded] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState<string>();

  useEffect(() => {
    let mounted = true;
    if (props.onlyFromCompany !== currentCompanyId && employeesLoaded) {
      setEmployeesLoaded(false);
      setEmployees([]);
    }
    if (!employees.length && !employeesLoaded) {
      let companyId = undefined;
      if (props.onlyCurrentCompany) {
        companyId = pok.getCompanyId();
      }
      if (props.onlyFromCompany) {
        companyId = props.onlyFromCompany;
      }
      setCurrentCompanyId(companyId);
      let fetchEmployees;

      if (props.fetchForOrders) {
        fetchEmployees = pok.employees.getAllForOrders();
      } else {
        fetchEmployees = pok.employees.getByCompanyId(
          companyId,
          props.withTechnical,
        );
      }

      fetchEmployees
        ?.then(data => {
          if (mounted) {
            setEmployees(data);
            props.setOptions?.(data);
            setEmployeesLoaded(true);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pok.employees,
    pok.getCompanyId,
    pok,
    employees,
    employeesLoaded,
    currentCompanyId,
    notifications,
    props.onlyCurrentCompany,
    props.onlyFromCompany,
    props.withTechnical,
    props.fetchForOrders,
  ]);

  useEffect(() => {
    if (employees && props.onChange) {
      if (props.isMulti) {
        const newEmployees = employees?.filter(({ id }) =>
          props.value?.includes(id),
        );
        if ((props.value?.length || 0) !== (newEmployees?.length || 0)) {
          props.onChange(
            newEmployees.map(v => ({ value: v.id, label: v.name }) as Option),
          );
        }
      } else if (
        props.value &&
        !employees.find(employee => employee.id === props.value)
      ) {
        props.onChange(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, props.value, props.isMulti]);

  const provider = async (text: string) => {
    const values = employees.filter(v =>
      v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );

    return values.map(v => {
      return {
        badge: '',
        label: v.name,
        value: v.id,
      };
    });
  };

  if (!employees.length && !employeesLoaded) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={'empl-' + employees?.map(o => o.id).join('_').length}
      className={props.className}
      isMulti={props.isMulti}
      readOnly={props.readOnly}
      creatable={false}
      provider={provider}
      value={props.value}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
