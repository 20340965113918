import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import {
  GetEstimateItemDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';

import {
  getItemsGridData,
  getSummary as getItemsGridSummary,
} from './EstimateItemsGridData';

interface EstimateItemsGridProps {
  data: GetEstimateItemDto[];
  schema?: ProfileSchemaDto;
}

export const EstimateItemsGrid: React.FC<EstimateItemsGridProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa pozycji', property: 'positionName' },
  ];

  for (const key in props.schema) {
    if (
      props.schema[key as keyof ProfileSchemaDto] === undefined ||
      props.schema[key as keyof ProfileSchemaDto]?.displayName.includes('%')
    ) {
      continue;
    }
    columns.push({
      header: props.schema[key as keyof ProfileSchemaDto]?.displayName || '',
      property: key,
    });
  }

  columns.push(
    {
      header: 'Kwota do zafakturowania',
      property: 'salesAmountToInvoice',
    },
    {
      header: '',
      property: 'salesAmountToInvoicePB',
      noFilter: true,
      noSorting: true,
    },
    {
      header: 'Kwota do zaakceptowania',
      property: 'purchaseAmountToAccept',
    },
    {
      header: '',
      property: 'purchaseAmountToAcceptPB',
      noFilter: true,
      noSorting: true,
    },
  );

  return (
    <Grid
      data={getItemsGridData(props.data)}
      columns={columns}
      showFilter={false}
      pageSize={20}
      emptyGridMessage="Brak pozycji"
      summaryData={getItemsGridSummary(props.data)}
      trClassName="amounts-align-right font-90"
      withFilterInColumns={true}
    />
  );
};
