import { Buffer } from 'buffer';

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useNotifications } from '../../common/hooks/useNotifications';
import { usePokCore } from '../../common/hooks/usePokCore';
import versionWeb from '../../assets/version.json';
import { useAuth } from '../../common/hooks/useAuth';
import { PermissionsEnum } from '../../common/pokCore/authorization/permissions';

export const DocumentationView: React.FC = () => {
  const pok = usePokCore();
  const auth = useAuth();
  const notifications = useNotifications();
  const [content, setContent] = useState('');
  const [coreInfo, setCoreInfo] = useState('');

  const params = new URLSearchParams(window.location.search);
  const filename = params.get('plik');

  const [directory, setDirectory] = useState<string>('docsShort');
  const [pdf, setPdf] = useState<string>('docsShort/poks.pdf');

  useEffect(() => {
    if (
      auth.check(PermissionsEnum.Administration) ||
      auth.check(PermissionsEnum.Clients) ||
      auth.check(PermissionsEnum.Finance) ||
      auth.check(PermissionsEnum.FinanceAdministration) ||
      auth.check(PermissionsEnum.Permissions)
    ) {
      setDirectory('docsFull');
      setPdf('docsFull/pok.pdf');
    } else {
      setDirectory('docsShort');
      setPdf('docsShort/poks.pdf');
    }
  }, [auth]);

  const getPdf = () => {
    pok.docs
      .getForFilename(pdf)
      .then(doc => {
        const file = new Blob([Buffer.from(doc.content, 'base64')], {
          type: 'application/pdf',
        });
        window.open(URL.createObjectURL(file));
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  useEffect(() => {
    pok.docs
      .getForFilename(filename || `/${directory}/index.html`)
      .then(doc => {
        let content = Buffer.from(doc.content, 'base64').toString();
        content = content.replaceAll(
          'href="ch',
          `href="/pomoc/dokumentacja?plik=${directory}/ch`,
        );
        content = content.replaceAll(
          'href="idp',
          `href="/pomoc/dokumentacja?plik=${directory}/idp`,
        );
        content = content.replaceAll(
          'href="index',
          `href="/pomoc/dokumentacja?plik=${directory}/index`,
        );
        content = content.replaceAll('../../common/', `../${directory}/`);
        content = content.replaceAll(
          'src="images/',
          `src="../${directory}/images/`,
        );

        setContent(content);

        if (window.location.hash) {
          setTimeout(() => {
            const elem = document.getElementById(window.location.hash.slice(1));
            elem?.scrollIntoView();
          }, 0);
        }
      })
      .catch(() => notifications.badLink());

    pok.docs
      .getVersionInfo()
      .then(versionCore => {
        if (versionCore.date) {
          setCoreInfo(
            `DB: kompilacja z ${versionCore.buildTime} (repo: ${versionCore.date}, ${versionCore.commit})`,
          );
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok.docs, filename, notifications, directory]);

  const webInfo = versionWeb['date']
    ? `POK: kompilacja z ${versionWeb['buildTime']} (repo: ${versionWeb['date']}, ${versionWeb['commit']})`
    : '';

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="d-flex mt-3">
        <div className="flex-grow-1 small text-secondary">
          {webInfo}
          <br />
          {coreInfo}
        </div>
        <div>
          <Button variant="link" onClick={getPdf}>
            Dokumentacja w formacie PDF
            <img
              src={`../${directory}/images/pdf-file.png`}
              alt="Dokumentacja PDF"
            />
          </Button>
        </div>
      </div>
    </>
  );
};
