import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { CreateUpdatePurchaseInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow, {
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import momencik from '../../../common/momencik';

interface PurchaseInvoiceEditorFormGeneralFormDatesProps {
  purchaseInvoice?: CreateUpdatePurchaseInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => void;
  readOnly?: boolean;
}

export const PurchaseInvoiceGeneralFormDates: React.FC<
  PurchaseInvoiceEditorFormGeneralFormDatesProps
> = props => {
  return (
    <>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="issueDate" label="Data wystawienia">
            <Form.Control
              type="date"
              name="issueDate"
              value={momencik(props.purchaseInvoice?.issueDate, 'YYYY-MM-DD')}
              onChange={e =>
                props.propertyChange({
                  issueDate: new Date(e.target.value),
                })
              }
              disabled={props.readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="sellDate" label="Data sprzedaży">
            <Form.Control
              type="date"
              name="sellDate"
              value={momencik(props.purchaseInvoice?.sellDate, 'YYYY-MM-DD')}
              onChange={e =>
                props.propertyChange({
                  sellDate: new Date(e.target.value),
                })
              }
              disabled={props.readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="paymentDate" label="Termin płatności">
        <Form.Control
          type="date"
          name="paymentDate"
          value={momencik(props.purchaseInvoice?.paymentDate, 'YYYY-MM-DD')}
          onChange={e =>
            props.propertyChange({
              paymentDate: new Date(e.target.value),
            })
          }
          disabled={props.readOnly}
        />
      </FormRow>
    </>
  );
};
