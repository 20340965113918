import { isEqual } from 'lodash';

import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';

export const checkDataChanged = (
  originalData: object,
  changedData: object,
  tab: PurchaseInvoiceTabsEnum,
  handleChangeTab: (tab: PurchaseInvoiceTabsEnum | undefined) => void,
) => {
  const dataChanged = !isEqual(originalData, changedData);
  handleChangeTab(dataChanged ? tab : undefined);
};
