/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GusClientDto
 */
export interface GusClientDto {
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    regon?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    nip?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    voivodeship?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    place?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    numbers?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    flat?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    postPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    activityTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof GusClientDto
     */
    activityTypeShort?: string;
}

/**
 * Check if a given object implements the GusClientDto interface.
 */
export function instanceOfGusClientDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GusClientDtoFromJSON(json: any): GusClientDto {
    return GusClientDtoFromJSONTyped(json, false);
}

export function GusClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GusClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regon': !exists(json, 'regon') ? undefined : json['regon'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'voivodeship': !exists(json, 'voivodeship') ? undefined : json['voivodeship'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'numbers': !exists(json, 'numbers') ? undefined : json['numbers'],
        'flat': !exists(json, 'flat') ? undefined : json['flat'],
        'postPlace': !exists(json, 'postPlace') ? undefined : json['postPlace'],
        'activityTypeId': !exists(json, 'activityTypeId') ? undefined : json['activityTypeId'],
        'activityTypeShort': !exists(json, 'activityTypeShort') ? undefined : json['activityTypeShort'],
    };
}

export function GusClientDtoToJSON(value?: GusClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regon': value.regon,
        'nip': value.nip,
        'name': value.name,
        'voivodeship': value.voivodeship,
        'place': value.place,
        'postcode': value.postcode,
        'street': value.street,
        'numbers': value.numbers,
        'flat': value.flat,
        'postPlace': value.postPlace,
        'activityTypeId': value.activityTypeId,
        'activityTypeShort': value.activityTypeShort,
    };
}

