/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetProfileRelatedTeams } from './GetProfileRelatedTeams';
import {
    GetProfileRelatedTeamsFromJSON,
    GetProfileRelatedTeamsFromJSONTyped,
    GetProfileRelatedTeamsToJSON,
} from './GetProfileRelatedTeams';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';
import type { ProfileSchemaDto } from './ProfileSchemaDto';
import {
    ProfileSchemaDtoFromJSON,
    ProfileSchemaDtoFromJSONTyped,
    ProfileSchemaDtoToJSON,
} from './ProfileSchemaDto';

/**
 * 
 * @export
 * @interface GetExtendedProfileDto
 */
export interface GetExtendedProfileDto {
    /**
     * 
     * @type {string}
     * @memberof GetExtendedProfileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedProfileDto
     */
    name: string;
    /**
     * 
     * @type {ProfileSchemaDto}
     * @memberof GetExtendedProfileDto
     */
    schema: ProfileSchemaDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedProfileDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedProfileDto
     */
    commission: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedProfileDto
     */
    tv: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedProfileDto
     */
    tvBuying: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedProfileDto
     */
    autoSalesBlockDisabled: boolean;
    /**
     * 
     * @type {Array<GetCompanyDto>}
     * @memberof GetExtendedProfileDto
     */
    companies?: Array<GetCompanyDto>;
    /**
     * 
     * @type {Array<GetTeamDto>}
     * @memberof GetExtendedProfileDto
     */
    teams?: Array<GetTeamDto>;
    /**
     * 
     * @type {Array<GetProfileRelatedTeams>}
     * @memberof GetExtendedProfileDto
     */
    relatedTeams?: Array<GetProfileRelatedTeams>;
}

/**
 * Check if a given object implements the GetExtendedProfileDto interface.
 */
export function instanceOfGetExtendedProfileDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "schema" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "tv" in value;
    isInstance = isInstance && "tvBuying" in value;
    isInstance = isInstance && "autoSalesBlockDisabled" in value;

    return isInstance;
}

export function GetExtendedProfileDtoFromJSON(json: any): GetExtendedProfileDto {
    return GetExtendedProfileDtoFromJSONTyped(json, false);
}

export function GetExtendedProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExtendedProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'schema': ProfileSchemaDtoFromJSON(json['schema']),
        'active': json['active'],
        'commission': json['commission'],
        'tv': json['tv'],
        'tvBuying': json['tvBuying'],
        'autoSalesBlockDisabled': json['autoSalesBlockDisabled'],
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(GetCompanyDtoFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(GetTeamDtoFromJSON)),
        'relatedTeams': !exists(json, 'relatedTeams') ? undefined : ((json['relatedTeams'] as Array<any>).map(GetProfileRelatedTeamsFromJSON)),
    };
}

export function GetExtendedProfileDtoToJSON(value?: GetExtendedProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'schema': ProfileSchemaDtoToJSON(value.schema),
        'active': value.active,
        'commission': value.commission,
        'tv': value.tv,
        'tvBuying': value.tvBuying,
        'autoSalesBlockDisabled': value.autoSalesBlockDisabled,
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(GetCompanyDtoToJSON)),
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(GetTeamDtoToJSON)),
        'relatedTeams': value.relatedTeams === undefined ? undefined : ((value.relatedTeams as Array<any>).map(GetProfileRelatedTeamsToJSON)),
    };
}

