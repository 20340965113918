import React, { useMemo } from 'react';

import numberFormatter from '../../../common/numberFormatter';
import { CreateUpdateOrderExecutionDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';

const OrderExecutionPositionsSumRow = ({
  orderExecution,
  positionsSalesSum,
  purchaseSum,
  isDecisionView,
  isActionGroupAvailable,
}: {
  orderExecution: CreateUpdateOrderExecutionDto;
  isActionGroupAvailable?: boolean;
  positionsSalesSum: number;
  purchaseSum?: number;
  isDecisionView?: boolean;
}) => {
  const positionsSum = useMemo(
    () =>
      mathUtils.add(
        ...orderExecution.orderExecutionPositions.map(({ amount }) =>
          mathUtils.round(amount),
        ),
      ),
    [orderExecution.orderExecutionPositions],
  );

  return (
    <>
      <tr className="align-middle">
        <td
          style={{ textAlign: 'end' }}
          colSpan={isActionGroupAvailable ? 4 : 3}
        >
          <b>Suma</b>
        </td>
        <td>
          <b>{numberFormatter(positionsSum, true)}</b>
        </td>
      </tr>
      <tr className="align-middle">
        <td
          style={{ textAlign: 'end' }}
          colSpan={isActionGroupAvailable ? 4 : 3}
        >
          Suma pozycji mediaplanu
        </td>
        <td>
          {numberFormatter(
            isDecisionView ? purchaseSum || 0 : positionsSalesSum,
            true,
          )}
        </td>
      </tr>
    </>
  );
};

export default OrderExecutionPositionsSumRow;
