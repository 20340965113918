/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetOrderExecutionEmployeeDto } from './GetOrderExecutionEmployeeDto';
import {
    GetOrderExecutionEmployeeDtoFromJSON,
    GetOrderExecutionEmployeeDtoFromJSONTyped,
    GetOrderExecutionEmployeeDtoToJSON,
} from './GetOrderExecutionEmployeeDto';
import type { GetOrderExecutionPositionDto } from './GetOrderExecutionPositionDto';
import {
    GetOrderExecutionPositionDtoFromJSON,
    GetOrderExecutionPositionDtoFromJSONTyped,
    GetOrderExecutionPositionDtoToJSON,
} from './GetOrderExecutionPositionDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';

/**
 * 
 * @export
 * @interface GetOrderExecutionDto
 */
export interface GetOrderExecutionDto {
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionDto
     */
    id: string;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetOrderExecutionDto
     */
    project: GetProjectDto;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetOrderExecutionDto
     */
    resultProject: GetProjectDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderExecutionDto
     */
    addTeamToEmails: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetOrderExecutionDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof GetOrderExecutionDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionDto
     */
    formalConditionsId: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionDto
     */
    status: GetOrderExecutionDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetOrderExecutionDto
     */
    systemNumber: number;
    /**
     * 
     * @type {Array<GetOrderExecutionPositionDto>}
     * @memberof GetOrderExecutionDto
     */
    orderExecutionPositions?: Array<GetOrderExecutionPositionDto>;
    /**
     * 
     * @type {Array<GetOrderExecutionEmployeeDto>}
     * @memberof GetOrderExecutionDto
     */
    orderExecutionEmployees?: Array<GetOrderExecutionEmployeeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderExecutionDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetOrderExecutionDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetOrderExecutionDto
     */
    creationTs: Date;
    /**
     * 
     * @type {number}
     * @memberof GetOrderExecutionDto
     */
    salesSum?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetOrderExecutionDto
     */
    purchaseSum?: number | null;
}


/**
 * @export
 */
export const GetOrderExecutionDtoStatusEnum = {
    New: 'New',
    Accepted: 'Accepted',
    Rejected: 'Rejected'
} as const;
export type GetOrderExecutionDtoStatusEnum = typeof GetOrderExecutionDtoStatusEnum[keyof typeof GetOrderExecutionDtoStatusEnum];


/**
 * Check if a given object implements the GetOrderExecutionDto interface.
 */
export function instanceOfGetOrderExecutionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "resultProject" in value;
    isInstance = isInstance && "addTeamToEmails" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "formalConditionsId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTs" in value;

    return isInstance;
}

export function GetOrderExecutionDtoFromJSON(json: any): GetOrderExecutionDto {
    return GetOrderExecutionDtoFromJSONTyped(json, false);
}

export function GetOrderExecutionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderExecutionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'project': GetProjectDtoFromJSON(json['project']),
        'resultProject': GetProjectDtoFromJSON(json['resultProject']),
        'addTeamToEmails': json['addTeamToEmails'],
        'emails': json['emails'],
        'date': (new Date(json['date'])),
        'formalConditionsId': json['formalConditionsId'],
        'status': json['status'],
        'systemNumber': json['systemNumber'],
        'orderExecutionPositions': !exists(json, 'orderExecutionPositions') ? undefined : ((json['orderExecutionPositions'] as Array<any>).map(GetOrderExecutionPositionDtoFromJSON)),
        'orderExecutionEmployees': !exists(json, 'orderExecutionEmployees') ? undefined : ((json['orderExecutionEmployees'] as Array<any>).map(GetOrderExecutionEmployeeDtoFromJSON)),
        'active': json['active'],
        'rejectedNote': json['rejectedNote'],
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'creationTs': (new Date(json['creationTs'])),
        'salesSum': !exists(json, 'salesSum') ? undefined : json['salesSum'],
        'purchaseSum': !exists(json, 'purchaseSum') ? undefined : json['purchaseSum'],
    };
}

export function GetOrderExecutionDtoToJSON(value?: GetOrderExecutionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project': GetProjectDtoToJSON(value.project),
        'resultProject': GetProjectDtoToJSON(value.resultProject),
        'addTeamToEmails': value.addTeamToEmails,
        'emails': value.emails,
        'date': (value.date.toISOString()),
        'formalConditionsId': value.formalConditionsId,
        'status': value.status,
        'systemNumber': value.systemNumber,
        'orderExecutionPositions': value.orderExecutionPositions === undefined ? undefined : ((value.orderExecutionPositions as Array<any>).map(GetOrderExecutionPositionDtoToJSON)),
        'orderExecutionEmployees': value.orderExecutionEmployees === undefined ? undefined : ((value.orderExecutionEmployees as Array<any>).map(GetOrderExecutionEmployeeDtoToJSON)),
        'active': value.active,
        'rejectedNote': value.rejectedNote,
        'creator': GetEmployeeDtoToJSON(value.creator),
        'creationTs': (value.creationTs.toISOString()),
        'salesSum': value.salesSum,
        'purchaseSum': value.purchaseSum,
    };
}

