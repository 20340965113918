/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEmailDto
 */
export interface GetEmailDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    sentMessageInfo: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    status: GetEmailDtoStatusEnum;
}


/**
 * @export
 */
export const GetEmailDtoStatusEnum = {
    NotSent: 'NotSent',
    Sent: 'Sent',
    Error: 'Error'
} as const;
export type GetEmailDtoStatusEnum = typeof GetEmailDtoStatusEnum[keyof typeof GetEmailDtoStatusEnum];


/**
 * Check if a given object implements the GetEmailDto interface.
 */
export function instanceOfGetEmailDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sentMessageInfo" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetEmailDtoFromJSON(json: any): GetEmailDto {
    return GetEmailDtoFromJSONTyped(json, false);
}

export function GetEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sentMessageInfo': json['sentMessageInfo'],
        'status': json['status'],
    };
}

export function GetEmailDtoToJSON(value?: GetEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sentMessageInfo': value.sentMessageInfo,
        'status': value.status,
    };
}

