/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEstimateItemAmountsOnlyDto
 */
export interface GetEstimateItemAmountsOnlyDto {
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    salesInvoicesSum: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    purchaseInvoicesSum: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    rcSalesTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    salesNet: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    salesCommissionAmount: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    salesNetTechnicalCost: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    salesNetTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    rcPurchase: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    purchaseTechnicalCost: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    purchaseNetTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemAmountsOnlyDto
     */
    purchaseNet: string;
}

/**
 * Check if a given object implements the GetEstimateItemAmountsOnlyDto interface.
 */
export function instanceOfGetEstimateItemAmountsOnlyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "salesInvoicesSum" in value;
    isInstance = isInstance && "purchaseInvoicesSum" in value;
    isInstance = isInstance && "rcSalesTotal" in value;
    isInstance = isInstance && "salesNet" in value;
    isInstance = isInstance && "salesCommissionAmount" in value;
    isInstance = isInstance && "salesNetTechnicalCost" in value;
    isInstance = isInstance && "salesNetTotal" in value;
    isInstance = isInstance && "rcPurchase" in value;
    isInstance = isInstance && "purchaseTechnicalCost" in value;
    isInstance = isInstance && "purchaseNetTotal" in value;
    isInstance = isInstance && "purchaseNet" in value;

    return isInstance;
}

export function GetEstimateItemAmountsOnlyDtoFromJSON(json: any): GetEstimateItemAmountsOnlyDto {
    return GetEstimateItemAmountsOnlyDtoFromJSONTyped(json, false);
}

export function GetEstimateItemAmountsOnlyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEstimateItemAmountsOnlyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'salesInvoicesSum': json['salesInvoicesSum'],
        'purchaseInvoicesSum': json['purchaseInvoicesSum'],
        'rcSalesTotal': json['rcSalesTotal'],
        'salesNet': json['salesNet'],
        'salesCommissionAmount': json['salesCommissionAmount'],
        'salesNetTechnicalCost': json['salesNetTechnicalCost'],
        'salesNetTotal': json['salesNetTotal'],
        'rcPurchase': json['rcPurchase'],
        'purchaseTechnicalCost': json['purchaseTechnicalCost'],
        'purchaseNetTotal': json['purchaseNetTotal'],
        'purchaseNet': json['purchaseNet'],
    };
}

export function GetEstimateItemAmountsOnlyDtoToJSON(value?: GetEstimateItemAmountsOnlyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'salesInvoicesSum': value.salesInvoicesSum,
        'purchaseInvoicesSum': value.purchaseInvoicesSum,
        'rcSalesTotal': value.rcSalesTotal,
        'salesNet': value.salesNet,
        'salesCommissionAmount': value.salesCommissionAmount,
        'salesNetTechnicalCost': value.salesNetTechnicalCost,
        'salesNetTotal': value.salesNetTotal,
        'rcPurchase': value.rcPurchase,
        'purchaseTechnicalCost': value.purchaseTechnicalCost,
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseNet': value.purchaseNet,
    };
}

