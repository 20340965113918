/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetEmailConfigTeamDto } from './GetEmailConfigTeamDto';
import {
    GetEmailConfigTeamDtoFromJSON,
    GetEmailConfigTeamDtoFromJSONTyped,
    GetEmailConfigTeamDtoToJSON,
} from './GetEmailConfigTeamDto';

/**
 * 
 * @export
 * @interface GetEmailConfigDto
 */
export interface GetEmailConfigDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmailConfigDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmailConfigDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetEmailConfigDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetEmailConfigDto
     */
    type: GetEmailConfigDtoTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEmailConfigDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<GetEmailConfigTeamDto>}
     * @memberof GetEmailConfigDto
     */
    emailConfigTeams?: Array<GetEmailConfigTeamDto>;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetEmailConfigDto
     */
    company: GetCompanyDto;
}


/**
 * @export
 */
export const GetEmailConfigDtoTypeEnum = {
    Applications: 'Applications',
    PositionAppForeign: 'PositionAppForeign',
    ApplicationExecutions: 'ApplicationExecutions',
    Orders: 'Orders',
    OrdersRemind: 'OrdersRemind',
    OrderExecutions: 'OrderExecutions',
    OrderExecutionsRemind: 'OrderExecutionsRemind',
    UnblockEstimateByMonth: 'UnblockEstimateByMonth',
    ProjectsDelayed: 'ProjectsDelayed',
    ProjectsToCancel: 'ProjectsToCancel',
    PurchaseDeficiencies: 'PurchaseDeficiencies',
    SpecialInvoicing: 'SpecialInvoicing'
} as const;
export type GetEmailConfigDtoTypeEnum = typeof GetEmailConfigDtoTypeEnum[keyof typeof GetEmailConfigDtoTypeEnum];


/**
 * Check if a given object implements the GetEmailConfigDto interface.
 */
export function instanceOfGetEmailConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "company" in value;

    return isInstance;
}

export function GetEmailConfigDtoFromJSON(json: any): GetEmailConfigDto {
    return GetEmailConfigDtoFromJSONTyped(json, false);
}

export function GetEmailConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmailConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': json['active'],
        'systemNumber': json['systemNumber'],
        'type': json['type'],
        'emails': json['emails'],
        'emailConfigTeams': !exists(json, 'emailConfigTeams') ? undefined : ((json['emailConfigTeams'] as Array<any>).map(GetEmailConfigTeamDtoFromJSON)),
        'company': GetCompanyDtoFromJSON(json['company']),
    };
}

export function GetEmailConfigDtoToJSON(value?: GetEmailConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'active': value.active,
        'systemNumber': value.systemNumber,
        'type': value.type,
        'emails': value.emails,
        'emailConfigTeams': value.emailConfigTeams === undefined ? undefined : ((value.emailConfigTeams as Array<any>).map(GetEmailConfigTeamDtoToJSON)),
        'company': GetCompanyDtoToJSON(value.company),
    };
}

