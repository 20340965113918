import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateTeamDto,
  GetTeamDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { TeamModalEditor } from '../../components/Teams/TeamModalEditor';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/TeamContext';
import { TeamsSearch } from '../../components/Teams/TeamSearch';
import { useNavigation } from '../../../common/navigation';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

export const TeamsView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const [team, setTeam] = useState<GetTeamDto>();
  const [showNew, setShowNew] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAdd = () => {
    setShowNew(true);
    setTeam(undefined);
  };

  const addEditTeam = async (teamCU: CreateUpdateTeamDto, id?: string) => {
    const status = await validate(teamCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.teams.update(id, teamCU);
        } else {
          await pok.teams.create(teamCU);
        }
        setShowNew(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleCloseNew = () => {
    setShowNew(false);
  };

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.teams.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.teams],
  );

  const getCount = useMemo(
    () =>
      ((filterText: string) =>
        pok.teams.getAllCount(filterText)) as GridCountFunction,
    [pok.teams],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Zespoły</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          Nowy zespół
        </Button>
        <TeamsSearch
          getData={getData}
          getCount={getCount}
          onRowClick={nav.team}
          refresh={refresh}
        />
        <TeamModalEditor
          id={team?.id}
          systemNumber={team?.systemNumber}
          team={convert(team)}
          onCompleted={addEditTeam}
          show={showNew}
          handleClose={handleCloseNew}
        />
      </Card.Body>
    </Card>
  );
};
