import React from 'react';

import { ApplicationSearch } from './ApplicationSearch';

interface AllApplicationTabProps {
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const AllApplicationTab: React.FC<AllApplicationTabProps> = props => {
  return <ApplicationSearch {...props} />;
};
