import React, { useState, useEffect } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetBudgetDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface BudgetSelectorProps {
  documentControl?: boolean;
  readOnly?: boolean;
  value?: string;
  className?: string;
  clearable?: boolean;
  onChange?: (option: Option) => void;
}

export default (props: BudgetSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [budgets, setBudgets] = useState<GetBudgetDto[]>([]);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (!props.value) {
      setName('');
      return;
    }
    const budg = budgets?.find(u => u.id === props.value);
    if (budg) {
      setName(budg.name);
    } else {
      void pok.budgets.getById(props.value).then(budget => {
        setName(budget.name);
      });
    }
  }, [pok.budgets, budgets, props.value]);

  const provider = async (text: string) => {
    let values: GetBudgetDto[] | undefined | void;
    if (!props.documentControl) {
      values = await pok.budgets.getManyByName(text).catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
    } else {
      values = await pok.budgets
        .getForDocumentControlByName(text)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }

    if (!values) {
      values = [];
    }

    setBudgets(values);
    return values.map(v => {
      return {
        badge: '',
        label: v.name,
        value: v.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={'bdgt-' + props.value ?? 'new'}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      provider={provider}
      value={name}
      clearable={props.clearable}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
