import React from 'react';
import { Trash } from 'react-bootstrap-icons';
import moment from 'moment/moment';

import { GetAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import momencik from '../../../common/momencik';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';
import { AttachmentObjectTypeText } from '../../../common/pokCore/validation/schemas';
import SelectOptionInfo from '../Selects/SelectOptionInfo';
import oneliner from '../../../common/oneliner';
import mathUtils from '../../../utils/mathUtils';
import numberFormatter from '../../../common/numberFormatter';

import { AttachmentViewTypes } from './AttachmentViewTypes';
import useIsEditAttachmentAllowedProjects from './useIsEditAttachmentAllowedProjects';

export const AttachmentsGrid = (props: {
  documents: GetAttachmentDto[];
  onClick: (doc: GetAttachmentDto) => void;
  onDelete: (id: string) => Promise<void>;
  readOnly?: boolean;
  type?: AttachmentViewTypes;
}) => {
  const isDeleteAllow = useIsEditAttachmentAllowedProjects(props.type);
  const isNote = props.type === 'notes';

  const COLUMNS: GridColumn[] = [
    ...(isNote ? [] : [{ header: 'Typ', property: 'type' }]),
    { header: 'Tytuł', property: 'description' },
    { header: 'Nazwa', property: 'name' },
    ...(isNote ? [] : [{ header: 'Fakturujący', property: 'client.name' }]),
    ...(isNote
      ? []
      : [
          {
            header: 'Suma zakupu powiązanych pozycji',
            property: 'positionsSum',
            noSorting: true,
          },
        ]),
    { header: 'Data dodania', property: 'date' },
    ...(isNote ? [] : [{ header: 'Powiązany z', property: 'relationType' }]),
    { header: 'Notatka', property: 'note', noSorting: true },
    { header: 'Autor', property: 'creator', noSorting: true },
    ...(props.readOnly
      ? []
      : [{ header: '', property: 'deleteLink', noSorting: true }]),
  ];
  const NOTE_MAX_LENGTH = 150;
  const data = (props.documents || []).map(document => {
    const didCreatedLessThanHourAgo = !moment(document.creationTs).isBefore(
      moment().subtract(1, 'hours'),
    );

    return {
      key: document.id,
      values: {
        name: document.name,
        description: document.description,
        note: document.note && (
          <>
            {oneliner(document.note, NOTE_MAX_LENGTH)}
            <SelectOptionInfo
              label={document.note}
              title="Notatka"
              placement="auto"
            />
          </>
        ),
        sortDate: new Date(document.creationTs || new Date()).getTime(),
        date: momencik(document.creationTs),
        type: document.dictionaryType.value,
        relationType: AttachmentObjectTypeText[document.enObjectType],
        creator: document.creator?.name,
        'client.name': document?.client?.name,
        positionsSum: document?.estimateItems?.length
          ? numberFormatter(
              document?.estimateItems?.reduce(
                (acc, { estimateItem }) =>
                  mathUtils.add(acc, estimateItem?.purchaseNetTotal || 0),
                0,
              ),
              true,
            )
          : '',

        deleteLink: (isDeleteAllow || didCreatedLessThanHourAgo) && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className="d-flex justify-content-center"
            onClick={e => e.stopPropagation()}
          >
            <ConfirmationButton
              variant="outline-danger"
              confirmation={`Czy na pewno usunąć ${
                isNote ? 'notatkę' : 'załącznik'
              }?`}
              tooltip={`Usuń ${isNote ? 'notatkę' : 'załącznik'}`}
              onOK={() => props.onDelete(document.id)}
            >
              <Trash size={16} />
            </ConfirmationButton>
          </div>
        ),
      },
    };
  }) as unknown as GridRecord[];

  return (
    <Grid
      data={data}
      columns={COLUMNS}
      showFilter={true}
      onRowClick={
        props.readOnly
          ? undefined
          : (id: string) => {
              const foundDocument = props.documents.find(doc => doc.id === id);
              if (foundDocument) {
                props.onClick(foundDocument);
              }
            }
      }
      emptyGridMessage={isNote ? 'Brak notatek' : 'Brak załączników'}
      initialOrderColumn="sortDate"
      initialOrderDirection="DESC"
    />
  );
};
