import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetPurchaserDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface PurchaserSearchProps {
  hasClient?: boolean;
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh: boolean;
}

export const purchaserForGrid = (
  purchaser: GetPurchaserDto,
  orderBy?: string,
) => {
  let teamNames = purchaser.teams?.map(
    team => team.name + ' (' + team.company.shortname + ')',
  );
  if (teamNames && orderBy !== 'teams.name') {
    teamNames = teamNames.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  return {
    key: purchaser.id,
    values: {
      'purchaser.systemNumber': purchaser.systemNumber,
      'purchaser.name': purchaser.name,
      'company.shortname': purchaser.company.shortname,
      'client.name': purchaser.client.name,
      'budget.name': purchaser.budget.name,
      'manager.name': purchaser.budget.manager.name,
      'teams.name': teamNames?.join(', '),
      purchaser_active_yes_no: purchaser.active ? 'TAK' : 'NIE',
    },
    isInactive: !purchaser.active,
  };
};

export const PurchaserSearch: React.FC<PurchaserSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'purchaser.systemNumber' },
    { header: 'Nazwa', property: 'purchaser.name' },
    ...(props.hasClient
      ? []
      : [{ header: 'Kontrahent', property: 'client.name' }]),
    { header: 'Budżet', property: 'budget.name' },
    { header: 'Szef budżetu', property: 'manager.name' },
    { header: 'Spółka', property: 'company.shortname' },
    { header: 'Zespoły', property: 'teams.name' },
    { header: 'Aktywny', property: 'purchaser_active_yes_no' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="purchaser.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={
        props.hasClient
          ? 'Brak zamawiających dla tego kontrahenta.'
          : 'Brak zamawiających w systemie.'
      }
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
