/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBudgetDto } from './GetBudgetDto';
import {
    GetBudgetDtoFromJSON,
    GetBudgetDtoFromJSONTyped,
    GetBudgetDtoToJSON,
} from './GetBudgetDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetRoleDto } from './GetRoleDto';
import {
    GetRoleDtoFromJSON,
    GetRoleDtoFromJSONTyped,
    GetRoleDtoToJSON,
} from './GetRoleDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetEmployeeRoleDto
 */
export interface GetEmployeeRoleDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeRoleDto
     */
    id: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetEmployeeRoleDto
     */
    user: GetEmployeeDto;
    /**
     * 
     * @type {GetRoleDto}
     * @memberof GetEmployeeRoleDto
     */
    role: GetRoleDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetEmployeeRoleDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {GetTeamDto}
     * @memberof GetEmployeeRoleDto
     */
    team: GetTeamDto;
    /**
     * 
     * @type {GetBudgetDto}
     * @memberof GetEmployeeRoleDto
     */
    budget: GetBudgetDto;
}

/**
 * Check if a given object implements the GetEmployeeRoleDto interface.
 */
export function instanceOfGetEmployeeRoleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "budget" in value;

    return isInstance;
}

export function GetEmployeeRoleDtoFromJSON(json: any): GetEmployeeRoleDto {
    return GetEmployeeRoleDtoFromJSONTyped(json, false);
}

export function GetEmployeeRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmployeeRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': GetEmployeeDtoFromJSON(json['user']),
        'role': GetRoleDtoFromJSON(json['role']),
        'company': GetCompanyDtoFromJSON(json['company']),
        'team': GetTeamDtoFromJSON(json['team']),
        'budget': GetBudgetDtoFromJSON(json['budget']),
    };
}

export function GetEmployeeRoleDtoToJSON(value?: GetEmployeeRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': GetEmployeeDtoToJSON(value.user),
        'role': GetRoleDtoToJSON(value.role),
        'company': GetCompanyDtoToJSON(value.company),
        'team': GetTeamDtoToJSON(value.team),
        'budget': GetBudgetDtoToJSON(value.budget),
    };
}

