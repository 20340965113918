import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import Select from 'react-select';

import { CreateUpdateApplicationDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  ApplicationTypeEnum,
  ApplicationTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import TeamSelector from '../Selects/TeamSelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import CompanySelector from '../Selects/CompanySelector';
import { PolishClientFormFields } from '../Clients/PolishClientFormFields';
import { ForeignClient } from '../Clients/ForeignClient';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import ProfileSelector from '../Selects/ProfileSelector';

import { ApplicationAdminEditorInfoBrand } from './ApplicationAdminEditorInfoBrand';
import { ApplicationAdminEditorInfoPermission } from './ApplicationAdminEditorInfoPermission';

interface ApplicationAdminEditorInfoProps {
  application: CreateUpdateApplicationDto;
}

export const ApplicationAdminEditorInfo: React.FC<
  ApplicationAdminEditorInfoProps
> = props => {
  const [showClientData, setShowClientData] = useState(false);
  const { application } = props;
  const [companyIds, setCompanyIds] = useState<string[]>([
    props.application.companyId,
  ]);
  const [teamIds, setTeamIds] = useState<string[]>([props.application.teamId]);

  const applicationTypeOptions = useMemo(() => {
    return Object.entries(ApplicationTypeEnum).map(([key, value]) => ({
      value: key,
      label: ApplicationTypeEnumText[value],
    }));
  }, []);

  useEffect(() => {
    const show =
      props.application.applicationType === ApplicationTypeEnum.Permission ||
      props.application.applicationType === ApplicationTypeEnum.Position ||
      props.application.applicationType === ApplicationTypeEnum.Purchaser;
    setShowClientData(show);
  }, [props.application.applicationType]);

  useEffect(() => {
    setCompanyIds([props.application.companyId]);
    setTeamIds([props.application.teamId]);
  }, [props.application.companyId, props.application.teamId]);

  return (
    <>
      <Card className="card col-lg-6">
        <Card.Header>Dane wniosku</Card.Header>
        <Card.Body className="m-1">
          <div className="d-grid gap-3 mb-3">
            <FormRow controlId="applicationType" label="Typ wniosku">
              <Select
                value={
                  application?.applicationType
                    ? applicationTypeOptions.find(
                        ({ value }) => application?.applicationType === value,
                      )
                    : null
                }
                options={applicationTypeOptions}
                isDisabled
              />
            </FormRow>
            <FormRow controlId="team" label="Zespół">
              <TeamSelector
                value={application.teamId}
                companyId={application.companyId}
                readOnly
              />
            </FormRow>
            <FormRow controlId="companyId" label="Spółka">
              <CompanySelector value={application.companyId} readOnly />
            </FormRow>
            <FormRow controlId="description" label="Opis">
              <DynamicTextarea value={application.description || ''} disabled />
            </FormRow>
            {application.applicationType === ApplicationTypeEnum.Position && (
              <>
                <FormRow controlId="applicationName" label="Nazwa pozycji">
                  <Form.Control
                    type="text"
                    value={application.elementName}
                    disabled
                  />
                </FormRow>
                <FormRow controlId="profile" label="Profil">
                  <ProfileSelector
                    isMulti={false}
                    value={application.profileId}
                    companyIds={companyIds}
                    teamIds={teamIds}
                    readOnly
                  />
                </FormRow>
              </>
            )}
            {application.applicationType === ApplicationTypeEnum.Purchaser && (
              <>
                <FormRow
                  controlId="applicationName"
                  label="Nazwa zamawiającego"
                >
                  <Form.Control
                    type="text"
                    value={application.elementName}
                    disabled
                  />
                </FormRow>
              </>
            )}
            {application.applicationType ===
              ApplicationTypeEnum.DictionaryValue && (
              <>
                <FormRow controlId="applicationName" label="Nazwa">
                  <Form.Control
                    type="text"
                    value={application.elementName}
                    disabled
                  />
                </FormRow>
              </>
            )}

            <ApplicationAdminEditorInfoBrand application={application} />
            <ApplicationAdminEditorInfoPermission application={application} />
          </div>
        </Card.Body>
      </Card>
      {showClientData && (
        <Card className="card flex-grow-1">
          <Card.Header>Dane kontrahenta</Card.Header>
          <Card.Body className="m-1">
            <div className="d-grid gap-2 mb-1">
              <>
                <FormRow controlId="country" label="Państwo">
                  <CreatableClearableSelector
                    value={application?.country}
                    readOnly
                  />
                </FormRow>
                <FormRow controlId="company" label="Spółka">
                  <CompanySelector value={application.companyId} readOnly />
                </FormRow>
                {application.country === 'Polska' && (
                  <PolishClientFormFields client={application} readonly />
                )}
                {application.country !== 'Polska' && (
                  <ForeignClient client={application} readonly />
                )}
              </>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
