/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParametersSchemaDto } from './ParametersSchemaDto';
import {
    ParametersSchemaDtoFromJSON,
    ParametersSchemaDtoFromJSONTyped,
    ParametersSchemaDtoToJSON,
} from './ParametersSchemaDto';

/**
 * 
 * @export
 * @interface CreateUpdateExcelConfigPositionDto
 */
export interface CreateUpdateExcelConfigPositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigPositionDto
     */
    excelPositionName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigPositionDto
     */
    positionId: string;
    /**
     * 
     * @type {ParametersSchemaDto}
     * @memberof CreateUpdateExcelConfigPositionDto
     */
    parameters: ParametersSchemaDto;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigPositionDto
     */
    excelConfigId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExcelConfigPositionDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the CreateUpdateExcelConfigPositionDto interface.
 */
export function instanceOfCreateUpdateExcelConfigPositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "excelPositionName" in value;
    isInstance = isInstance && "positionId" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "excelConfigId" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function CreateUpdateExcelConfigPositionDtoFromJSON(json: any): CreateUpdateExcelConfigPositionDto {
    return CreateUpdateExcelConfigPositionDtoFromJSONTyped(json, false);
}

export function CreateUpdateExcelConfigPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExcelConfigPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'excelPositionName': json['excelPositionName'],
        'positionId': json['positionId'],
        'parameters': ParametersSchemaDtoFromJSON(json['parameters']),
        'excelConfigId': json['excelConfigId'],
        'active': json['active'],
    };
}

export function CreateUpdateExcelConfigPositionDtoToJSON(value?: CreateUpdateExcelConfigPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'excelPositionName': value.excelPositionName,
        'positionId': value.positionId,
        'parameters': ParametersSchemaDtoToJSON(value.parameters),
        'excelConfigId': value.excelConfigId,
        'active': value.active,
    };
}

