/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateOrderDto
 */
export interface CreateUpdateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderDto
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderDto
     */
    teamId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOrderDto
     */
    addTeamToEmails: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateOrderDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateOrderDto
     */
    orderEstimateItemsIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateOrderDto
     */
    orderEmployeesIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOrderDto
     */
    active?: boolean;
}

/**
 * Check if a given object implements the CreateUpdateOrderDto interface.
 */
export function instanceOfCreateUpdateOrderDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "addTeamToEmails" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "orderEstimateItemsIds" in value;
    isInstance = isInstance && "orderEmployeesIds" in value;

    return isInstance;
}

export function CreateUpdateOrderDtoFromJSON(json: any): CreateUpdateOrderDto {
    return CreateUpdateOrderDtoFromJSONTyped(json, false);
}

export function CreateUpdateOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['projectId'],
        'teamId': json['teamId'],
        'addTeamToEmails': json['addTeamToEmails'],
        'emails': json['emails'],
        'orderEstimateItemsIds': json['orderEstimateItemsIds'],
        'orderEmployeesIds': json['orderEmployeesIds'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function CreateUpdateOrderDtoToJSON(value?: CreateUpdateOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectId': value.projectId,
        'teamId': value.teamId,
        'addTeamToEmails': value.addTeamToEmails,
        'emails': value.emails,
        'orderEstimateItemsIds': value.orderEstimateItemsIds,
        'orderEmployeesIds': value.orderEmployeesIds,
        'active': value.active,
    };
}

