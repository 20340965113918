import React from 'react';

import { ApplicationSearch } from './ApplicationSearch';

interface CompanyApplicationTabProps {
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const CompanyApplicationTab: React.FC<
  CompanyApplicationTabProps
> = props => {
  return (
    <ApplicationSearch
      withContextCompany={true}
      hideCompanyColumn={true}
      {...props}
    />
  );
};
