import React, { useState } from 'react';
import { Row } from 'react-bootstrap';

import {
  CreateUpdateApplicationUnblockDto,
  GetApplicationUnblockDto,
  GetEstimateByMonthDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ApplicationUnblockContext';
import {
  ApplicationUnblockStatusEnum,
  ApplicationUnblockTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { ApplicationUnblockEditor } from './ApplicationUnblockEditor';
import { ApplicationUnblockSearch } from './ApplicationUnblockSearch';
import { ApplicationUnblockControls } from './ApplicationUnblockControls';

interface ApplicationUnblockSectionProps {
  estimateByMonth?: GetEstimateByMonthDto;
  toggleBlocked: (value: boolean, type: ApplicationUnblockTypeEnum) => void;
  project?: GetProjectDto;
  isLoadingToggle: boolean;
  salesBlockedMonth: boolean;
  purchaseBlockedMonth: boolean;
}

export const ApplicationUnblockSection: React.FC<
  ApplicationUnblockSectionProps
> = props => {
  const [applicationUnblock, setApplicationUnblock] =
    useState<GetApplicationUnblockDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();

  const getData = ((
    pageSize,
    pageNumber,
    orderBy,
    orderDirection,
    filterText,
  ) =>
    pok.applicationsUnblock.getAllByPortion(
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
      props.estimateByMonth?.id,
    )) as GridGetterFunction;

  const getCount = (filterText =>
    pok.applicationsUnblock.getAllCount(
      filterText,
      props.estimateByMonth?.id,
    )) as GridCountFunction;

  const addEditApplicationUnblock = async (
    applicationUnblockCU: CreateUpdateApplicationUnblockDto,
    id?: string,
  ) => {
    const status = await validate(applicationUnblockCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.applicationsUnblock.update(id, applicationUnblockCU);
          void sendEmail(id);
        } else {
          const au = await pok.applicationsUnblock.create(applicationUnblockCU);
          void sendEmail(au.id);
        }
        setShow(false);
        handleRefresh();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const sendEmail = async (id: string) => {
    pok.emails.sendUnblockEmail(id).catch(err => {
      notifications.caughtError(err);
    });
  };

  const handleClick = async (id: string) => {
    const getApplicationUnblock = await pok.applicationsUnblock.getById(id);
    setApplicationUnblock(getApplicationUnblock);
    setShow(true);
  };

  const handleAdd = async () => {
    setShow(true);
  };

  const handleClose = () => {
    setApplicationUnblock(undefined);
    setShow(false);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Row className="mt-5 mb-5">
      <ApplicationUnblockControls
        estimateByMonth={props.estimateByMonth}
        handleAddApplicationUnblock={handleAdd}
        toggleBlocked={props.toggleBlocked}
        project={props.project}
        isLoading={props.isLoadingToggle}
      />
      <ApplicationUnblockSearch
        getCount={getCount}
        getData={getData}
        onRowClick={handleClick}
        refresh={refresh}
        emptyGridMessage="Brak wniosków przypisanych do tego profilu miesięcznego"
      />
      <ApplicationUnblockEditor
        id={applicationUnblock?.id}
        onCompleted={addEditApplicationUnblock}
        show={show}
        applicationUnblock={convert(
          applicationUnblock,
          props.estimateByMonth?.id,
        )}
        handleClose={handleClose}
        estimateByMonth={props.estimateByMonth}
        systemNumber={applicationUnblock?.systemNumber}
        status={applicationUnblock?.status}
        rejectedNote={applicationUnblock?.rejectedNote}
        readOnly={
          applicationUnblock &&
          (applicationUnblock.status !== ApplicationUnblockStatusEnum.New ||
            !applicationUnblock.active)
        }
        handleRefresh={handleRefresh}
        salesBlockedMonth={props.salesBlockedMonth}
        purchaseBlockedMonth={props.purchaseBlockedMonth}
      />
    </Row>
  );
};
