/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateEmployeeRoleDto
 */
export interface CreateUpdateEmployeeRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRoleDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRoleDto
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRoleDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRoleDto
     */
    teamId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRoleDto
     */
    budgetId: string;
}

/**
 * Check if a given object implements the CreateUpdateEmployeeRoleDto interface.
 */
export function instanceOfCreateUpdateEmployeeRoleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "roleId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "budgetId" in value;

    return isInstance;
}

export function CreateUpdateEmployeeRoleDtoFromJSON(json: any): CreateUpdateEmployeeRoleDto {
    return CreateUpdateEmployeeRoleDtoFromJSONTyped(json, false);
}

export function CreateUpdateEmployeeRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateEmployeeRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'userId': json['userId'],
        'roleId': json['roleId'],
        'companyId': json['companyId'],
        'teamId': json['teamId'],
        'budgetId': json['budgetId'],
    };
}

export function CreateUpdateEmployeeRoleDtoToJSON(value?: CreateUpdateEmployeeRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'userId': value.userId,
        'roleId': value.roleId,
        'companyId': value.companyId,
        'teamId': value.teamId,
        'budgetId': value.budgetId,
    };
}

