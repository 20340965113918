import React, { useState } from 'react';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { GetClientExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

export default (props: {
  id?: string;
  className?: string;
  iconSize?: number;
  placement?: 'left' | 'right' | 'top' | 'bottom';
}) => {
  const [client, setClient] = useState<GetClientExtendedDto>();
  const notifications = useNotifications();
  const pok = usePokCore();

  const getClient = () => {
    if (!props.id) {
      return;
    }
    pok.clients
      .getByIdFormattedAddress(props.id)
      .then(setClient)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <OverlayTrigger
      placement={props.placement ?? 'top'}
      onEnter={getClient}
      delay={{ show: 250, hide: 4000 }}
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" className="text-center">
            Kontrahent
          </Popover.Header>
          <Popover.Body className="text-center">
            {client ? (
              <>
                Nazwa: <b>{client?.name || 'Brak informacji'}</b> <br />
                Adres: <b>{client?.address || 'Brak informacji'}</b> <br />
                NIP: <b>{client?.nip || 'Brak informacji'}</b> <br />
              </>
            ) : (
              <Spinner animation="border" variant="secondary" />
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <Icon.InfoCircle
        className={props.className ?? ''}
        size={props.iconSize ?? 22}
      />
    </OverlayTrigger>
  );
};
