import React, { Dispatch, SetStateAction, useState } from 'react';

import { CreateUpdateEmployeeRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';

interface EmployeeRoleEditorProps {
  employeeId: string | undefined;
  handleClose?: () => void;
  handleDelete?: () => void;
  onCompleted: (
    employeeId: string,
    employeeRoles: CreateUpdateEmployeeRoleDto[] | undefined,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  setShowSaveErrors: Dispatch<SetStateAction<boolean>>;
  setSaveErrors: Dispatch<SetStateAction<string[]>>;
  employeeRoles?: CreateUpdateEmployeeRoleDto[];
}

export const EmployeeRoleEditorControls: React.FC<
  EmployeeRoleEditorProps
> = props => {
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();

  const createEmployeeWithRoles = async () => {
    if (props.employeeId) {
      setIsLoadingSave(true);
      props
        .onCompleted(props.employeeId, props.employeeRoles)
        .then(saveStatus => {
          if (!saveStatus.saved) {
            props.setSaveErrors(saveStatus.errors);
            props.setShowSaveErrors(true);
            return;
          }
          notifications.saveCompleted();
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoadingSave(false));
    }
  };

  const deleteEmployeeRoles = async () => {
    if (props.employeeId) {
      setIsLoadingDelete(true);
      await notifications.onPromise(
        pok.employeesRoles.delete(props.employeeId),
        props.handleDelete,
      );
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        onClick={props.handleClose}
        disabled={isLoadingDelete || isLoadingSave}
      >
        Zamknij
      </Button>
      {props.employeeId && (
        <ConfirmationButton
          className="mx-1"
          confirmation="Czy na pewno usunąć wszystkie uprawnienia przypisane do tego użytkownika?"
          variant="outline-danger"
          onOK={deleteEmployeeRoles}
          isLoading={isLoadingDelete}
        >
          Usuń
        </ConfirmationButton>
      )}
      <Button
        variant="primary"
        onClick={createEmployeeWithRoles}
        isLoading={isLoadingSave}
      >
        Zapisz
      </Button>
    </>
  );
};
