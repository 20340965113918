/*eslint complexity: ["error", 18]*/
import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEstimateItemPurchaseDeficienciesDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { AttachmentObjectType } from '../../../common/pokCore/validation/schemas';

import { SimpleAttachmentLink } from './SimpleAttachmentLink';

interface EstimateItemPurchaseDeficienciesSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const estimateItemPurchaseDeficienciesForGrid = (
  estimateItem: GetEstimateItemPurchaseDeficienciesDto,
  refresh: boolean,
  setRefresh: (refresh: boolean) => void,
) => {
  const today = new Date();
  const estimateDate = estimateItem.estimateByMonth.date;
  const lastDayOfMonth = new Date(
    estimateDate.getFullYear(),
    estimateDate.getMonth() + 1,
    0,
  );
  const differenceInDays = Math.ceil(
    (today.getTime() - lastDayOfMonth.getTime()) / (1000 * 60 * 60 * 24) - 1,
  );
  const differenceInMonths =
    (today.getFullYear() - lastDayOfMonth.getFullYear()) * 12 +
    (today.getMonth() - lastDayOfMonth.getMonth());

  const countDaysCss =
    differenceInMonths === 1
      ? 'bg-custom-yellow amounts-align-right'
      : differenceInMonths > 1 && differenceInMonths < 4
        ? 'bg-custom-dark-yellow amounts-align-right'
        : differenceInMonths > 3 && differenceInMonths < 7
          ? 'bg-custom-pink amounts-align-right'
          : differenceInMonths > 6
            ? 'bg-custom-red amounts-align-right'
            : '';

  return {
    key: estimateItem.id,
    values: {
      'estimateByMonth.date': momencik(
        estimateItem.estimateByMonth.date,
        'YYYY-MM',
      ),
      'position.name': `${estimateItem.position.name} (${
        estimateItem.position.client?.shortname ||
        estimateItem.position.client?.name
      })`,
      'project.name': estimateItem.estimateByMonth.project.name,
      'project.number': estimateItem.estimateByMonth.project.number,
      'profile.name': estimateItem.estimateByMonth.profile.name,
      'responsibleOwner.name':
        estimateItem.estimateByMonth.project.responsibleOwner?.name,
      'budget.name': estimateItem.estimateByMonth.project.purchaser.budget.name,
      'team.name': estimateItem.estimateByMonth.project.projectTeams
        ?.map(({ team }) => team?.name)
        ?.join(', '),
      differenceInDays: differenceInDays,
      label: (
        <div className={countDaysCss}>
          {differenceInMonths === 1
            ? '1 m-c'
            : differenceInMonths > 1 && differenceInMonths < 4
              ? `2-3 m-ce`
              : differenceInMonths > 3 && differenceInMonths < 7
                ? `4-6 m-cy`
                : differenceInMonths > 6
                  ? `powyżej 6 m-cy`
                  : ''}
        </div>
      ),
      'project.purchaseNetTotal': numberFormatter(
        estimateItem?.purchaseNetTotal,
        true,
      ),
      'project.purchaseInvoicesSum': numberFormatter(
        estimateItem?.purchaseInvoicesSum || 0,
        true,
      ),
      difference: numberFormatter(
        mathUtils.subtract(
          estimateItem?.purchaseNetTotal || 0,
          estimateItem?.purchaseInvoicesSum || 0,
        ),
        true,
      ),
      commentMediaplanner: (
        <SimpleAttachmentLink
          objectId={estimateItem.id}
          objectType={AttachmentObjectType.EstimateItemCommentMediaplanner}
          note={estimateItem.commentMediaplanner}
          setRefresh={setRefresh}
          refresh={refresh}
          additionalText="mediaplanera"
        />
      ),
      commentFinance: (
        <SimpleAttachmentLink
          objectId={estimateItem.id}
          objectType={AttachmentObjectType.EstimateItemCommentFinance}
          note={estimateItem.commentFinance}
          setRefresh={setRefresh}
          refresh={refresh}
          additionalText="finansów"
          checkFinance={true}
        />
      ),
    },
  };
};

export const EstimateItemPurchaseDeficienciesSearch: React.FC<
  EstimateItemPurchaseDeficienciesSearchProps
> = props => {
  const { t, tk } = useTranslation('menu');

  const columns: GridColumn[] = [
    { header: 'Zespoły', property: 'team.name' },
    { header: 'Budżet', property: 'budget.name' },
    { header: `Nazwa ${t(tk.menu.genitiveProject)}`, property: 'project.name' },
    {
      header: `Numer ${t(tk.menu.genitiveProject)}`,
      property: 'project.number',
    },
    { header: 'Miesiąc', property: 'estimateByMonth.date' },
    { header: 'Profil', property: 'profile.name' },
    { header: 'Nazwa pozycji', property: 'position.name' },
    {
      header: 'Liczba dni po terminie',
      property: 'differenceInDays',
      noFilter: true,
      noSorting: true,
    },
    {
      header: 'Zakup',
      property: 'project.purchaseNetTotal',
      noFilter: true,
      noSorting: true,
    },
    {
      header: 'Suma FVZ',
      property: 'project.purchaseInvoicesSum',
      noFilter: true,
      noSorting: true,
    },
    {
      header: 'Do wyjaśnienia',
      property: 'difference',
      noFilter: true,
      noSorting: true,
    },
    {
      header: 'Komentarz mediaplanera',
      property: 'commentMediaplanner',
      noFilter: true,
      noSorting: true,
    },

    {
      header: 'Komentarz finanse',
      property: 'commentFinance',
      noFilter: true,
      noSorting: true,
    },
    { header: 'Osoba odpowiedzialna', property: 'responsibleOwner.name' },
    { header: 'Etykieta', property: 'label', noFilter: true, noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="estimateByMonth.date" //, project.number, position.name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak braków FVZ w systemie"
      refresh={props.refresh}
      onRowClick={props.onRowClick}
      withHistory={true}
      withFilterInColumns={true}
      withOverflowXAuto={true}
    />
  );
};
