import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { groupBy, isEqual, sortBy, uniq } from 'lodash';

import {
  CreateUpdateSalesInvoicePositionDto,
  GetEstimateItemDto,
  GetProjectDto,
  GetSalesInvoiceDto,
  GetSalesInvoicePositionDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { newInvoicePosition } from '../../../common/pokCore/contexts/SalesInvoicesContext';
import mathUtils from '../../../utils/mathUtils';
import { UseSelectedGridRowReturn } from '../../../common/hooks/useSelectGridRow';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

export interface UseSalesInvoicePositionsReturn {
  positions: CreateUpdateSalesInvoicePositionDto[];
  parentPositions: GetSalesInvoicePositionDto[];
  setPositions: Dispatch<SetStateAction<CreateUpdateSalesInvoicePositionDto[]>>;
  setInitialPositions: Dispatch<
    SetStateAction<CreateUpdateSalesInvoicePositionDto[]>
  >;
  propertyChange: (
    obj: Partial<CreateUpdateSalesInvoicePositionDto>,
    key: number,
  ) => void;
  insertPosition: (
    position: CreateUpdateSalesInvoicePositionDto,
    indexToInsert: number,
  ) => void;
  clonePosition: (key: number) => void;
  mergePositions: (keys: number[]) => void;
  mergePositionsByProject: (keys: number[]) => void;
  isMergeDisabled: boolean;
  estimateItems: GetEstimateItemDto[];
  checkDidDataChanged: (
    positions: CreateUpdateSalesInvoicePositionDto[],
  ) => void;
}

const mergePositionsByKeys = (
  positions: CreateUpdateSalesInvoicePositionDto[],
  keys: number[],
  projects: GetProjectDto[],
) => {
  if (keys.length < 2) {
    return positions;
  }

  const foundPositions = positions.filter(position =>
    keys.includes(position.order),
  );

  const mergedPositionOrder = Math.min(...keys);

  const position = foundPositions[0];
  const mergedPosition = newInvoicePosition({
    ...position,
    name: `${projects.find(({ id }) => position.projectId === id)?.number}${position.financialAccount.includes('(pr.)') ? ' (Prowizja)' : ''}`,
    amount: mathUtils
      .add(...foundPositions.map(({ amount }) => amount))
      .toString(),
    order: mergedPositionOrder,
  });

  const filteredPositions = positions.filter(
    position => !keys.includes(position.order),
  );

  let nextOrder = mergedPositionOrder + 1;
  filteredPositions.forEach(position => {
    if (position.order > mergedPositionOrder) {
      position.order = nextOrder++;
    }
  });

  return sortBy([...filteredPositions, mergedPosition], ['order']);
};

const mergePositionsByProjects = (
  positions: CreateUpdateSalesInvoicePositionDto[],
  keys: number[],
  projects: GetProjectDto[],
) => {
  if (!keys.length) {
    keys = positions.map(({ order }) => order);
  }
  let result = [...positions];

  const projectsToMerge = uniq(
    keys
      .map(key => positions.find(({ order }) => order === key)?.projectId)
      .filter(value => !!value),
  );

  projectsToMerge.forEach(projectId => {
    const groupedKeysToMerge = Object.values(
      groupBy(
        result.filter(({ projectId: pId }) => pId === projectId),
        'financialAccount',
      ),
    );

    groupedKeysToMerge.forEach(keysToMerge => {
      result = mergePositionsByKeys(
        result,
        keysToMerge.map(({ order }) => order),
        projects,
      );
    });
  });

  return result;
};

function useSalesInvoicePositions(
  projects: GetProjectDto[],
  select: UseSelectedGridRowReturn<number>,
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void,
  salesInvoice?: GetSalesInvoiceDto,
): UseSalesInvoicePositionsReturn {
  const [positions, setPositions] = useState<
    CreateUpdateSalesInvoicePositionDto[]
  >([]);
  const [initialPositions, setInitialPositions] = useState<
    CreateUpdateSalesInvoicePositionDto[]
  >([]);
  const [parentPositions, setParentPositions] = useState<
    GetSalesInvoicePositionDto[]
  >([]);
  const [estimateItems, setEstimateItems] = useState<GetEstimateItemDto[]>([]);

  const pok = usePokCore();
  const notifications = useNotifications();

  const checkDidDataChanged = (
    updatedPositions: CreateUpdateSalesInvoicePositionDto[],
  ) => {
    const dataChanged = !isEqual(updatedPositions, initialPositions);
    setNotSavedTab(
      dataChanged ? SalesInvoiceEditorTabs.INVOICE_POSITIONS : undefined,
    );
  };

  const isMergeDisabled = useMemo(
    () =>
      !positions
        .filter(position => select.selected.includes(position.order))
        .every(
          (position, _, array) =>
            position.financialAccount === array[0].financialAccount &&
            position.projectId === array[0].projectId,
        ),
    [positions, select.selected],
  );

  const propertyChange = (
    obj: Partial<CreateUpdateSalesInvoicePositionDto>,
    key: number,
  ) => {
    const updatedPositions = positions.map(position => {
      if (position.order === key) {
        return { ...position, ...obj };
      }

      return position;
    });
    setPositions(updatedPositions);
    checkDidDataChanged(updatedPositions);
  };

  const updatePositions = (
    positions: CreateUpdateSalesInvoicePositionDto[],
  ) => {
    setPositions(positions);
    select.handleUnselectAll();
    checkDidDataChanged(positions);
  };

  const insertPosition = (
    position: CreateUpdateSalesInvoicePositionDto,
    indexToInsert: number,
  ) => {
    const updatedPositions = positions.map((item, index) =>
      index + 1 >= indexToInsert ? { ...item, order: item.order + 1 } : item,
    );

    updatedPositions.splice(indexToInsert, 0, position);
    const newPositions = sortBy(updatedPositions, ['order']);
    updatePositions(newPositions);
  };

  const clonePosition = (key: number) => {
    const foundPosition = positions.find(({ order }) => order === key);

    if (foundPosition) {
      const newOrder = foundPosition.order + 1;
      insertPosition(
        { ...foundPosition, order: newOrder, amount: '0' },
        newOrder,
      );
    }
  };

  const mergePositions = (keys: number[]) => {
    const foundPositions = positions.filter(position =>
      keys.includes(position.order),
    );

    const mergedPositionOrder = Math.min(...keys);

    const mergedPosition = newInvoicePosition({
      ...foundPositions[0],
      name: uniq(foundPositions.map(({ name }) => name)).join(', '),
      amount: mathUtils
        .add(...foundPositions.map(({ amount }) => amount))
        .toString(),
      order: mergedPositionOrder,
    });

    const filteredPositions = positions.filter(
      position => !keys.includes(position.order),
    );

    let nextOrder = mergedPositionOrder + 1;
    filteredPositions.forEach(position => {
      if (position.order > mergedPositionOrder) {
        position.order = nextOrder++;
      }
    });

    const updatedPositions = sortBy(
      [...filteredPositions, mergedPosition],
      ['order'],
    );
    updatePositions(updatedPositions);
  };

  const mergePositionsByProject = (keys: number[]) => {
    updatePositions(mergePositionsByProjects(positions, keys, projects));
  };

  useEffect(() => {
    if (salesInvoice?.parent?.id && !parentPositions.length) {
      pok.salesInvoices
        .getById(salesInvoice.parent.id)
        .then(data => {
          setParentPositions(data.salesInvoicePositions || []);
        })
        .catch(async errorResponse => notifications.caughtError(errorResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesInvoice?.parent]);

  useEffect(() => {
    if (salesInvoice?.salesInvoiceProjects) {
      pok.estimateItems
        .findReadyToInvoiceByProjects(
          salesInvoice.salesInvoiceProjects?.map(({ project }) => project.id),
          salesInvoice.id,
        )
        .then(setEstimateItems)
        .catch(async errorResponse => notifications.caughtError(errorResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesInvoice]);

  return {
    positions,
    setPositions,
    propertyChange,
    insertPosition,
    clonePosition,
    mergePositions,
    mergePositionsByProject,
    isMergeDisabled,
    setInitialPositions,
    checkDidDataChanged,
    parentPositions,
    estimateItems,
  };
}

export default useSalesInvoicePositions;
