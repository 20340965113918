/*eslint max-lines-per-function: ["error", 250]*/
/*eslint complexity: ["error", 18]*/
import React, { useEffect, useState } from 'react';
import { Modal, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as mime from 'mime';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { Option } from '../Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  GetAttachmentDtoEnObjectTypeEnum,
  GetClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { AssociateDocumentModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { convert } from '../../../common/pokCore/contexts/AttachmentContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import momencik from '../../../common/momencik';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ValidationAlert from '../../../app/components/ValidationAlert';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import Button from '../../../common/components/Button/Button';
import { ClientSelector } from '../Selects/ClientSelector';
import EstimateItemSelector from '../Selects/EstimateItemSelector';
import CurrencyControl from '../../../app/components/CurrencyControl';
import mathUtils from '../../../utils/mathUtils';
import { usePokCore } from '../../../common/hooks/usePokCore';

const getTitle = (document: GetAttachmentDto, isNote: boolean) => {
  if (isNote) {
    return `Notatka${
      document.creationTs ? ` dodana ${momencik(document.creationTs)}` : ''
    }`;
  }

  return `Załącznik${
    document.creationTs ? ` dodany ${momencik(document.creationTs)}` : ''
  }`;
};

export const Attachment = (
  props: AssociateDocumentModalProps<
    GetAttachmentDto,
    CreateUpdateAttachmentDto
  >,
) => {
  const pok = usePokCore();
  const isNote = props.type === 'notes';
  const isFileExist = mime.getType(props.document?.name || '') !== null;
  const [attachment, setAttachment] = useState(props.document);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [client, setClient] = useState<GetClientDto>();
  const [estimateItemIds, setEstimateItemIds] = useState<string[]>([]);
  const [positionsSum, setPositionsSum] = useState(0);

  const saveAttachment = () => {
    const converted = convert(attachment, attachment.dictionaryType?.shortname);
    converted.clientId = client?.id;
    converted.estimateItemsIds = estimateItemIds;
    if (attachment.name === 'Notatka' && !attachment.note) {
      setShowSaveErrors(true);
      setSaveErrors(['Proszę podać notatkę.']);
      return;
    }

    void props.validate?.(converted).then(result => {
      if (result.valid) {
        props.onUpdate?.(attachment.id, converted);
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  const onChangePositions = async (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      setEstimateItemIds((option as Option[]).map(({ value }) => value));
    } else {
      setEstimateItemIds([]);
    }
    let result = 0;

    for (const { value } of option as Option[]) {
      result = mathUtils.add(
        result,
        (await pok.estimateItems.getById(value))?.purchaseNetTotal || 0,
      );
    }

    setPositionsSum(result);
  };

  useEffect(() => {
    setShowSaveErrors(false);
    setSaveErrors([]);
    setAttachment(props.document);
    setClient(props.document?.client);
    setEstimateItemIds(
      props.document?.estimateItems?.map(o => o.estimateItem?.id) || [],
    );
    setPositionsSum(
      props.document?.estimateItems?.reduce(
        (acc, { estimateItem }) =>
          mathUtils.add(acc, estimateItem?.purchaseNetTotal || 0),
        0,
      ) || 0,
    );
  }, [props.document]);

  if (!props.document) {
    return null;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      className="modal-on-modal"
      backdropClassName="modal-on-modal-backdrop"
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{getTitle(props.document, isNote)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="company" label="Spółka" static={true}>
            {props.document.company?.name || ''}
          </FormRow>
          <FormRow controlId="type" label="Typ" static={true}>
            {props.document.dictionaryType.value}
          </FormRow>
          <FormRow controlId="description" label="Tytuł">
            <DynamicTextarea
              value={attachment?.description || ''}
              onChange={event =>
                setAttachment({
                  ...attachment,
                  description: event.target.value,
                })
              }
              readOnly={props.readonly}
            />
          </FormRow>
          {isFileExist && (
            <FormRow controlId="file" label="Nazwa">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Otwórz w nowym oknie</Tooltip>}
              >
                <Button
                  variant="link"
                  className="px-0"
                  onClick={() =>
                    props.onGet && props.onGet(props.document, 'open')
                  }
                >
                  {props.document.name}
                </Button>
              </OverlayTrigger>
            </FormRow>
          )}
          <FormRow controlId="note" label="Notatka" static>
            <DynamicTextarea
              minRows={3}
              value={attachment?.note || ''}
              onChange={e =>
                setAttachment({ ...attachment, note: e.target.value })
              }
              readOnly={props.readonly}
            />
          </FormRow>
          {attachment &&
            attachment.dictionaryType?.shortname?.startsWith('POZ') &&
            attachment.enObjectType ===
              GetAttachmentDtoEnObjectTypeEnum.Project && (
              <>
                <FormRow controlId="clientId" label="Fakturujący">
                  <ClientSelector
                    projectId={attachment.objectId}
                    onChange={option =>
                      setClient({
                        id: option.value,
                        name: option.label,
                      } as GetClientDto)
                    }
                    value={attachment.client}
                    // readOnly={props.readonly}
                  />
                </FormRow>
                <FormRow controlId="estimateItemIds" label="Powiązane pozycje">
                  <EstimateItemSelector
                    isMulti={true}
                    projectId={attachment.objectId}
                    clientId={client?.id || undefined}
                    value={estimateItemIds}
                    onChange={options => onChangePositions(options)}
                    readOnly={!client?.id}
                  />
                </FormRow>
                <FormRow
                  controlId="positionsSum"
                  label="Powiązane pozycje - suma zakupu"
                >
                  <CurrencyControl
                    decimalScale={2}
                    value={positionsSum}
                    suffix=" zł"
                    allowNegative
                    disabled={true}
                  />
                </FormRow>
              </>
            )}
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <LastEditorInfo id={props.document.id} method={props.onGetLastEditor} />
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {!props.readonly && (
          <ConfirmationButton
            variant="outline-danger"
            onOK={() => props.onDelete(props.document.id)}
            confirmation={`Czy na pewno usunąć ${
              isNote ? 'notatkę' : 'załącznik'
            }?`}
            isLoading={props.isLoadingDelete}
          >
            Usuń
          </ConfirmationButton>
        )}
        {isFileExist && (
          <Button
            variant="outline-primary"
            onClick={() =>
              props.onGet && props.onGet(props.document, 'download')
            }
          >
            Pobierz
          </Button>
        )}
        {(!props.readonly ||
          (attachment &&
            attachment.dictionaryType?.shortname?.startsWith('POZ') &&
            attachment.enObjectType ===
              GetAttachmentDtoEnObjectTypeEnum.Project)) && (
          <Button
            variant="primary"
            onClick={saveAttachment}
            isLoading={props.isLoadingSave}
          >
            Zapisz
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
