/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePurchaseNetTotalDto
 */
export interface UpdatePurchaseNetTotalDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePurchaseNetTotalDto
     */
    purchaseNetTotal: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurchaseNetTotalDto
     */
    purchaseInvoiceId: string;
}

/**
 * Check if a given object implements the UpdatePurchaseNetTotalDto interface.
 */
export function instanceOfUpdatePurchaseNetTotalDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "purchaseNetTotal" in value;
    isInstance = isInstance && "purchaseInvoiceId" in value;

    return isInstance;
}

export function UpdatePurchaseNetTotalDtoFromJSON(json: any): UpdatePurchaseNetTotalDto {
    return UpdatePurchaseNetTotalDtoFromJSONTyped(json, false);
}

export function UpdatePurchaseNetTotalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePurchaseNetTotalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseNetTotal': json['purchaseNetTotal'],
        'purchaseInvoiceId': json['purchaseInvoiceId'],
    };
}

export function UpdatePurchaseNetTotalDtoToJSON(value?: UpdatePurchaseNetTotalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseInvoiceId': value.purchaseInvoiceId,
    };
}

