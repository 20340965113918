/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetExcelAndPokRowDto } from './GetExcelAndPokRowDto';
import {
    GetExcelAndPokRowDtoFromJSON,
    GetExcelAndPokRowDtoFromJSONTyped,
    GetExcelAndPokRowDtoToJSON,
} from './GetExcelAndPokRowDto';
import type { GetExcelColumnsDto } from './GetExcelColumnsDto';
import {
    GetExcelColumnsDtoFromJSON,
    GetExcelColumnsDtoFromJSONTyped,
    GetExcelColumnsDtoToJSON,
} from './GetExcelColumnsDto';
import type { GetExcelConfigDto } from './GetExcelConfigDto';
import {
    GetExcelConfigDtoFromJSON,
    GetExcelConfigDtoFromJSONTyped,
    GetExcelConfigDtoToJSON,
} from './GetExcelConfigDto';

/**
 * 
 * @export
 * @interface GetExcelAndPokDataDto
 */
export interface GetExcelAndPokDataDto {
    /**
     * 
     * @type {number}
     * @memberof GetExcelAndPokDataDto
     */
    newCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetExcelAndPokDataDto
     */
    updateCount: number;
    /**
     * 
     * @type {Array<GetExcelAndPokRowDto>}
     * @memberof GetExcelAndPokDataDto
     */
    excelRows: Array<GetExcelAndPokRowDto>;
    /**
     * 
     * @type {Array<GetExcelColumnsDto>}
     * @memberof GetExcelAndPokDataDto
     */
    columns?: Array<GetExcelColumnsDto>;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokDataDto
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokDataDto
     */
    projectMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokDataDto
     */
    projectName?: string;
    /**
     * 
     * @type {GetExcelConfigDto}
     * @memberof GetExcelAndPokDataDto
     */
    config: GetExcelConfigDto;
}

/**
 * Check if a given object implements the GetExcelAndPokDataDto interface.
 */
export function instanceOfGetExcelAndPokDataDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newCount" in value;
    isInstance = isInstance && "updateCount" in value;
    isInstance = isInstance && "excelRows" in value;
    isInstance = isInstance && "config" in value;

    return isInstance;
}

export function GetExcelAndPokDataDtoFromJSON(json: any): GetExcelAndPokDataDto {
    return GetExcelAndPokDataDtoFromJSONTyped(json, false);
}

export function GetExcelAndPokDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExcelAndPokDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newCount': json['newCount'],
        'updateCount': json['updateCount'],
        'excelRows': ((json['excelRows'] as Array<any>).map(GetExcelAndPokRowDtoFromJSON)),
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(GetExcelColumnsDtoFromJSON)),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'projectMonth': !exists(json, 'projectMonth') ? undefined : json['projectMonth'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'config': GetExcelConfigDtoFromJSON(json['config']),
    };
}

export function GetExcelAndPokDataDtoToJSON(value?: GetExcelAndPokDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newCount': value.newCount,
        'updateCount': value.updateCount,
        'excelRows': ((value.excelRows as Array<any>).map(GetExcelAndPokRowDtoToJSON)),
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(GetExcelColumnsDtoToJSON)),
        'fileName': value.fileName,
        'projectMonth': value.projectMonth,
        'projectName': value.projectName,
        'config': GetExcelConfigDtoToJSON(value.config),
    };
}

