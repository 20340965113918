import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Option } from '../../components/Selects/Selector';
import TeamSelector from '../../components/Selects/TeamSelector';
import { ProjectApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';

interface ProjectsApplicationDetailsEditFormProps {
  project?: CreateUpdateProjectWithTeamDto;
  applicationType: ProjectApplicationTypeEnum;
  purchaserName: string;
  brandName: string;
  readOnly?: boolean;
  status: Option;
  onStatusChange: (status: Option) => void;
  savedRejectedNote?: string;
  rejectedNote?: string;
  onRejectedNoteChange: (rejectedNote: string | undefined) => void;
}

const STATUS_OPTIONS = [
  { value: 'approved', label: 'Zatwierdzony' },
  { value: 'rejected', label: 'Odrzucony' },
];

const getValidStatus = (
  status: Option,
  type: ProjectApplicationTypeEnum,
  statusReversible: boolean,
  savedRejectedNote?: string,
  active?: boolean,
) => {
  const possibleOptions = getStatusOptionForApplicationType(
    type,
    statusReversible,
    savedRejectedNote,
    active,
  );

  if (possibleOptions.indexOf(status) >= 0) {
    return status;
  }
  if (possibleOptions.length) {
    return possibleOptions[0];
  }
  return undefined;
};

const getStatusOptionForApplicationType = (
  type: ProjectApplicationTypeEnum,
  statusReversible: boolean,
  savedRejectedNote?: string,
  active?: boolean,
) => {
  switch (type) {
    case ProjectApplicationTypeEnum.Delayed:
      if (active === false) {
        return [STATUS_OPTIONS[0]];
      }
      if (!savedRejectedNote) {
        return STATUS_OPTIONS;
      }
      break;
    case ProjectApplicationTypeEnum.ToCancel:
      if (active === false) {
        return [STATUS_OPTIONS[1]];
      }
      break;
  }
  return STATUS_OPTIONS;
};

export const ProjectsApplicationDetailsEditForm: React.FC<
  ProjectsApplicationDetailsEditFormProps
> = props => {
  const isStatusReversible =
    !props.project?.active &&
    (props.applicationType === ProjectApplicationTypeEnum.Delayed
      ? props.savedRejectedNote !== undefined
      : true);

  const chooseAndUpdateStatus = (): Option | null => {
    const newStatus = getValidStatus(
      props.status,
      props.applicationType,
      isStatusReversible,
      props.savedRejectedNote,
      props.project?.active,
    );

    if (newStatus && newStatus !== props.status) {
      props.onStatusChange(newStatus);
    }

    if (!newStatus) {
      return null;
    }
    return newStatus;
  };

  return (
    <Form className="d-grid gap-3">
      <FormRow controlId="team" label="Zespół">
        <TeamSelector
          value={props.project?.teamIds}
          companyId={props.project?.companyId}
          isMulti={true}
          readOnly={true}
        />
      </FormRow>
      <FormRow controlId="purchaser" label="Zamawiający">
        <DynamicTextarea value={props.purchaserName} disabled={true} />
      </FormRow>
      <FormRow controlId="brand" label="Brand">
        <DynamicTextarea value={props.brandName} disabled={true} />
      </FormRow>
      <FormRow controlId="startDate" label="Data rozpoczęcia">
        <Form.Control
          type="date"
          name="startDate"
          value={momencik(props.project?.startDate, 'YYYY-MM-DD')}
          disabled={true}
        />
      </FormRow>
      <FormRow controlId="endDate" label="Data zakończenia">
        <Form.Control
          type="date"
          name="endDate"
          value={momencik(props.project?.endDate, 'YYYY-MM-DD')}
          disabled={true}
        />
      </FormRow>
      <FormRow controlId="delayMessage" label="Treść wniosku">
        <DynamicTextarea
          value={
            props.applicationType === ProjectApplicationTypeEnum.Delayed
              ? props.project?.delayMessage
              : props.project?.cancelMessage
          }
          disabled={true}
        />
      </FormRow>
      {props.savedRejectedNote && (
        <FormRow controlId="rejectedNote" label="Uzasadnienie odrzucenia">
          <DynamicTextarea
            value={props.savedRejectedNote || ''}
            disabled={true}
          />
        </FormRow>
      )}
      {props.readOnly && !isStatusReversible ? null : (
        <>
          <hr />
          <FormRow controlId="status" label="Status">
            <Select
              value={chooseAndUpdateStatus()}
              options={getStatusOptionForApplicationType(
                props.applicationType,
                isStatusReversible,
                props.savedRejectedNote,
                props.project?.active,
              )}
              onChange={option => {
                props.onStatusChange(option || STATUS_OPTIONS[0]);
              }}
            />
          </FormRow>
          {props.status.value === STATUS_OPTIONS[1].value && (
            <FormRow controlId="rejectedNote" label="Uzasadnienie odrzucenia">
              <DynamicTextarea
                value={props.rejectedNote || ''}
                onChange={e =>
                  props.onRejectedNoteChange(e.target.value || undefined)
                }
              />
            </FormRow>
          )}
        </>
      )}
    </Form>
  );
};
