/*eslint complexity: ["error", 15]*/
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { usePokCore } from '../../../common/hooks/usePokCore';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import Button from '../../../common/components/Button/Button';

interface EstimateItemTabsFooterProps {
  id?: string;
  deleteItem: () => void;
  createEstimateItem: () => void;
  cloneItem: (id: string, withAmounts: boolean) => () => void;
  isSalesBlocked: boolean;
  isPurchaseBlocked: boolean;
  isLoadingSave: boolean;
  isLoadingDelete: boolean;
}

export const EstimateItemTabsFooter: React.FC<
  EstimateItemTabsFooterProps
> = props => {
  const pok = usePokCore();

  return (
    <div className="side-by-side">
      <div className="left">
        {props.id && (
          <LastEditorInfo
            className="color-primary"
            id={props.id || ''}
            method={pok.estimateItems.getLastEditor}
            additionalText={'Ostatnia modyfikacja pozycji przez mediaplanera'}
          />
        )}
      </div>
      <div className="right">
        {((!props.isSalesBlocked && !props.isPurchaseBlocked) || !props.id) && (
          <ConfirmationButton
            className="mx-1"
            variant="outline-danger"
            onOK={props.deleteItem}
            confirmation="Czy na pewno usunąć widoczną pozycję?"
            isLoading={props.isLoadingDelete}
          >
            {props.id ? 'Usuń pozycję' : 'Usuń nową pozycję'}
          </ConfirmationButton>
        )}
        {props.id && (!props.isSalesBlocked || !props.isPurchaseBlocked) && (
          <DropdownButton
            title="Klonuj"
            id="clone-dropdown"
            variant="outline-primary"
            className="mx-1 d-inline-block"
          >
            <Dropdown.Item
              onClick={props.cloneItem(props.id, false)}
              eventKey="1"
            >
              Bez kwot
            </Dropdown.Item>
            {!props.isSalesBlocked && !props.isPurchaseBlocked && (
              <Dropdown.Item
                onClick={props.cloneItem(props.id, true)}
                eventKey="2"
              >
                Z kwotami
              </Dropdown.Item>
            )}
          </DropdownButton>
        )}
        <Button
          variant="primary"
          onClick={props.createEstimateItem}
          className="mx-1"
          isLoading={props.isLoadingSave}
        >
          {props.id ? 'Zapisz zmiany' : 'Zapisz nową pozycję'}
        </Button>
      </div>
    </div>
  );
};
