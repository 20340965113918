/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentFromExcel
 */
export interface AttachmentFromExcel {
    /**
     * 
     * @type {string}
     * @memberof AttachmentFromExcel
     */
    projectNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFromExcel
     */
    attachmentType: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFromExcel
     */
    attachmentDescription: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFromExcel
     */
    fileName: string;
    /**
     * 
     * @type {object}
     * @memberof AttachmentFromExcel
     */
    file: object;
}

/**
 * Check if a given object implements the AttachmentFromExcel interface.
 */
export function instanceOfAttachmentFromExcel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectNumber" in value;
    isInstance = isInstance && "attachmentType" in value;
    isInstance = isInstance && "attachmentDescription" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "file" in value;

    return isInstance;
}

export function AttachmentFromExcelFromJSON(json: any): AttachmentFromExcel {
    return AttachmentFromExcelFromJSONTyped(json, false);
}

export function AttachmentFromExcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentFromExcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectNumber': json['projectNumber'],
        'attachmentType': json['attachmentType'],
        'attachmentDescription': json['attachmentDescription'],
        'fileName': json['fileName'],
        'file': json['file'],
    };
}

export function AttachmentFromExcelToJSON(value?: AttachmentFromExcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectNumber': value.projectNumber,
        'attachmentType': value.attachmentType,
        'attachmentDescription': value.attachmentDescription,
        'fileName': value.fileName,
        'file': value.file,
    };
}

