import React from 'react';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetPositionDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

import { isFieldHidden } from './CommissionService';

interface SpecialSalesParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  position?: GetPositionDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const SpecialSalesParams: React.FC<SpecialSalesParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.serviceExternalWorkPercent &&
        !isFieldHidden(
          'serviceExternalWorkPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="serviceExternalWorkPercent"
            label={schema?.serviceExternalWorkPercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.serviceExternalWorkPercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  serviceExternalWorkPercent: value,
                })
              }
            />
          </FormRow>
        )}

      {schema?.serviceCreationPercent &&
        !isFieldHidden(
          'serviceCreationPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="serviceCreationPercent"
            label={schema?.serviceCreationPercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.serviceCreationPercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  serviceCreationPercent: value,
                })
              }
            />
          </FormRow>
        )}

      {schema?.serviceChangeProductionPercent &&
        !isFieldHidden(
          'serviceChangeProductionPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="serviceChangeProductionPercent"
            label={schema?.serviceChangeProductionPercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.serviceChangeProductionPercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  serviceChangeProductionPercent: value,
                })
              }
            />
          </FormRow>
        )}
    </>
  );
};
