/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePrivateMemoDto
 */
export interface CreatePrivateMemoDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePrivateMemoDto
     */
    recipientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrivateMemoDto
     */
    content: string;
}

/**
 * Check if a given object implements the CreatePrivateMemoDto interface.
 */
export function instanceOfCreatePrivateMemoDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipientId" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function CreatePrivateMemoDtoFromJSON(json: any): CreatePrivateMemoDto {
    return CreatePrivateMemoDtoFromJSONTyped(json, false);
}

export function CreatePrivateMemoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePrivateMemoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipientId': json['recipientId'],
        'content': json['content'],
    };
}

export function CreatePrivateMemoDtoToJSON(value?: CreatePrivateMemoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipientId': value.recipientId,
        'content': value.content,
    };
}

