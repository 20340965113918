/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateBudgetDto
 */
export interface CreateUpdateBudgetDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBudgetDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBudgetDto
     */
    managerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBudgetDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateBudgetDto
     */
    brandIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBudgetDto
     */
    downloadDocuments: boolean;
}

/**
 * Check if a given object implements the CreateUpdateBudgetDto interface.
 */
export function instanceOfCreateUpdateBudgetDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "managerId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "brandIds" in value;
    isInstance = isInstance && "downloadDocuments" in value;

    return isInstance;
}

export function CreateUpdateBudgetDtoFromJSON(json: any): CreateUpdateBudgetDto {
    return CreateUpdateBudgetDtoFromJSONTyped(json, false);
}

export function CreateUpdateBudgetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateBudgetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'managerId': json['managerId'],
        'active': json['active'],
        'brandIds': json['brandIds'],
        'downloadDocuments': json['downloadDocuments'],
    };
}

export function CreateUpdateBudgetDtoToJSON(value?: CreateUpdateBudgetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'managerId': value.managerId,
        'active': value.active,
        'brandIds': value.brandIds,
        'downloadDocuments': value.downloadDocuments,
    };
}

