import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { BlockadeMonthSearch } from '../../components/BlockadeMonth/BlockadeMonthSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateBlockadeMonthDto,
  GetBlockadeMonthDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { BlockadeMonthEditor } from '../../components/BlockadeMonth/BlockadeMonthEditor';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/BlockadeMonthContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';

export const BlockadeView: React.FC = () => {
  const [blockadeMonth, setBlockadeMonth] = useState<GetBlockadeMonthDto>();
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const pok = usePokCore();

  const handleAdd = () => {
    setShow(true);
    setBlockadeMonth(undefined);
  };

  const handleClick = async (id: string) => {
    setBlockadeMonth(await pok.blockades.getById(id));
    setShow(true);
  };

  const addEditBlockadeMonth = async (
    blockadeCU: CreateUpdateBlockadeMonthDto,
    id?: string,
  ) => {
    const status = await validate(blockadeCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.blockades.update(id, blockadeCU);
        } else {
          await pok.blockades.create(blockadeCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setBlockadeMonth(undefined);
    setShow(false);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Blokady miesięczne</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          Nowa blokada miesięczna
        </Button>
        <BlockadeMonthSearch
          getData={pok.blockades.getAllByPortion}
          getCount={pok.blockades.getAllCount}
          onRowClick={handleClick}
          refresh={refresh}
          showFilter={true}
        />
        <BlockadeMonthEditor
          id={blockadeMonth?.id}
          blockadeMonth={convert(blockadeMonth, pok.getCompanyId())}
          show={show}
          handleClose={handleClose}
          onCompleted={addEditBlockadeMonth}
        />
      </Card.Body>
    </Card>
  );
};
