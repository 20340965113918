import React from 'react';

import Button from '../../../common/components/Button/Button';
import { GetProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNavigation } from '../../../common/navigation';

interface SpecialInvoicingButtonProps {
  project: GetProjectDto;
}
export const SpecialInvoicingButton: React.FC<
  SpecialInvoicingButtonProps
> = props => {
  const nav = useNavigation();

  const handleClick = () => {
    nav.specialInvoicingEditor('utworz', props.project.id);
  };

  return (
    <Button variant="outline-primary" onClick={handleClick}>
      Utwórz wniosek o niestandardowe fakturowanie
    </Button>
  );
};
