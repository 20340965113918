/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEstimateItemDto } from './GetEstimateItemDto';
import {
    GetEstimateItemDtoFromJSON,
    GetEstimateItemDtoFromJSONTyped,
    GetEstimateItemDtoToJSON,
} from './GetEstimateItemDto';

/**
 * 
 * @export
 * @interface GetInvoiceEstimateItemDto
 */
export interface GetInvoiceEstimateItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetInvoiceEstimateItemDto
     */
    id: string;
    /**
     * 
     * @type {GetEstimateItemDto}
     * @memberof GetInvoiceEstimateItemDto
     */
    estimateItem: GetEstimateItemDto;
    /**
     * 
     * @type {string}
     * @memberof GetInvoiceEstimateItemDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof GetInvoiceEstimateItemDto
     */
    salesInvoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof GetInvoiceEstimateItemDto
     */
    commissionAmount: string;
}

/**
 * Check if a given object implements the GetInvoiceEstimateItemDto interface.
 */
export function instanceOfGetInvoiceEstimateItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "estimateItem" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "salesInvoiceId" in value;
    isInstance = isInstance && "commissionAmount" in value;

    return isInstance;
}

export function GetInvoiceEstimateItemDtoFromJSON(json: any): GetInvoiceEstimateItemDto {
    return GetInvoiceEstimateItemDtoFromJSONTyped(json, false);
}

export function GetInvoiceEstimateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInvoiceEstimateItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'estimateItem': GetEstimateItemDtoFromJSON(json['estimateItem']),
        'amount': json['amount'],
        'salesInvoiceId': json['salesInvoiceId'],
        'commissionAmount': json['commissionAmount'],
    };
}

export function GetInvoiceEstimateItemDtoToJSON(value?: GetInvoiceEstimateItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'estimateItem': GetEstimateItemDtoToJSON(value.estimateItem),
        'amount': value.amount,
        'salesInvoiceId': value.salesInvoiceId,
        'commissionAmount': value.commissionAmount,
    };
}

