import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { isEqual } from 'lodash';

import {
  CreateUpdateSalesInvoiceDto,
  GetExtendedSalesInvoiceDto,
  GetProjectDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { SalesInvoiceBasicDataEditor } from './SalesInvoiceBasicDataEditor';
import { SalesInvoiceBasicDataControls } from './SalesInvoiceBasicDataControls';
import { SalesInvoiceProjectsFormGrid } from './SalesInvoiceProjectsFormGrid';
import { SalesInvoiceModifiedProjectsAlert } from './SalesInvoiceModifiedProjectsAlert';

interface SalesInvoiceBasicDataProps {
  convertedSalesInvoice: CreateUpdateSalesInvoiceDto;
  salesInvoice?: GetExtendedSalesInvoiceDto;
  readOnly?: boolean;
  isDeactivated?: boolean;
  purchaser?: GetPurchaserDto;
  id?: string;
  handleRefresh: () => void;
  handleRemove: () => void;
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void;
  projects: GetProjectDto[];
  setProjects: Dispatch<SetStateAction<GetProjectDto[]>>;
  initialProjectIds: string[] | undefined;
  refresh: boolean;
  allowCorrection?: boolean;
}

export const SalesInvoiceBasicData: React.FC<
  SalesInvoiceBasicDataProps
> = props => {
  const [salesInvoice, setSalesInvoice] = useState(props.convertedSalesInvoice);
  const [parentProjectId, setParentProjectId] = useState<string[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    if (props.salesInvoice?.parent?.id) {
      pok.salesInvoices
        .getById(props.salesInvoice.parent.id)
        .then(salesInvoiceParent => {
          setParentProjectId(
            salesInvoiceParent?.salesInvoiceProjects?.map(
              o => o?.project?.id,
            ) || [],
          );
        })
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [pok.salesInvoices, props.salesInvoice?.parent?.id, notifications]);

  const propertyChange = (obj: Partial<CreateUpdateSalesInvoiceDto>) => {
    const updatedSalesInvoice = {
      ...salesInvoice,
      ...obj,
    };

    const dataChanged = !isEqual(
      {
        ...updatedSalesInvoice,
        exchangeRate: updatedSalesInvoice.exchangeRate || undefined,
        exchangeRateDescription:
          updatedSalesInvoice.exchangeRateDescription || undefined,
      },
      props.convertedSalesInvoice,
    );
    props.setNotSavedTab(
      dataChanged ? SalesInvoiceEditorTabs.BASIC_DATA : undefined,
    );

    setTimeout(() => {
      setSalesInvoice({
        ...updatedSalesInvoice,
        status: props.convertedSalesInvoice.status,
      });
    }, 1);
  };

  return (
    <>
      <Form className="d-grid gap-4 mb-3">
        <SalesInvoiceBasicDataEditor
          salesInvoice={salesInvoice}
          propertyChange={propertyChange}
          purchaser={props.purchaser}
          number={props.salesInvoice?.number}
          readOnly={props.readOnly}
          refresh={props.refresh}
        />
        <SalesInvoiceProjectsFormGrid
          salesInvoice={salesInvoice}
          propertyChange={propertyChange}
          projects={props.projects}
          projectsIdsInParent={parentProjectId}
          setProjects={props.setProjects}
          readOnly={props.readOnly}
          purchaser={props.purchaser}
        />
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
        <SalesInvoiceModifiedProjectsAlert
          salesInvoice={salesInvoice}
          initialProjectIds={props.initialProjectIds}
        />
        <div className="d-flex">
          {props.id && (
            <LastEditorInfo
              id={props.id}
              className="color-primary"
              method={pok.salesInvoices.getLastEditor}
              additionalText="Ostatnia modyfikacja do zatwierdzenia"
            />
          )}
          <SalesInvoiceBasicDataControls
            {...props}
            salesInvoice={salesInvoice}
            invoiceStatus={props.convertedSalesInvoice.status}
            setShowSaveErrors={setShowSaveErrors}
            setSaveErrors={setSaveErrors}
          />
        </div>
      </Form>
    </>
  );
};
