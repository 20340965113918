/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRoleDto
 */
export interface GetRoleDto {
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetRoleDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRoleDto
     */
    permissions: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetRoleDto
     */
    withBudget: boolean;
}

/**
 * Check if a given object implements the GetRoleDto interface.
 */
export function instanceOfGetRoleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "withBudget" in value;

    return isInstance;
}

export function GetRoleDtoFromJSON(json: any): GetRoleDto {
    return GetRoleDtoFromJSONTyped(json, false);
}

export function GetRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'permissions': json['permissions'],
        'withBudget': json['withBudget'],
    };
}

export function GetRoleDtoToJSON(value?: GetRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions,
        'withBudget': value.withBudget,
    };
}

