/*eslint complexity: ["error", 15]*/
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Alert } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  FilterYear,
  FilterYearReportData,
} from '../../components/Reports/FilterYear';
import { ReportType } from '../../../common/pokCore/autogenerated/pokApiClient';
import { Waiting } from '../../../common/components/Waiting/Waiting';

export const ReportEstimateItemsDataRawView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [waiting, setWaiting] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');

  const downloadExcel = (dataReports: ReportType) => {
    const worksheet = XLSX.utils.json_to_sheet(dataReports?.data || []);

    if (worksheet && worksheet['!ref']) {
      const columnsToFormat0 = ['D', 'E'];
      const columnsToFormat4 = [
        'AI',
        'AJ',
        'AM',
        'AQ',
        'AR',
        'CD',
        'CE',
        'CF',
        'CG',
        'CH',
        'CI',
      ];
      const columnsToFormat2 = [
        'AH',
        'AK',
        'AL',
        'AN',
        'AO',
        'AP',
        'AS',
        'AT',
        'AU',
        'AV',
        'AX',
        'BY',
        'BZ',
      ];

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        columnsToFormat0.forEach(col => {
          const cellAddress = `${col}${rowNum + 1}`;

          if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
            worksheet[cellAddress].t = 'n';
            worksheet[cellAddress].z = '###0';
          }
        });

        columnsToFormat4.forEach(col => {
          const cellAddress = `${col}${rowNum + 1}`;

          if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
            worksheet[cellAddress].t = 'n';
            worksheet[cellAddress].z = '#,##0.0000';
          }
        });

        columnsToFormat2.forEach(col => {
          const cellAddress = `${col}${rowNum + 1}`;

          if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
            worksheet[cellAddress].t = 'n';
            worksheet[cellAddress].z = '#,##0.00';
          }
        });
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Pozycje kampanii');

      const currentDate = new Date().toISOString().split('T')[0];
      XLSX.writeFile(workbook, `Surowka_${currentDate}.xlsx`);
    }
  };

  const onSearch = (filter: FilterYearReportData) => {
    setAlert('Trwa pobieranie danych. Proszę czekać...');
    setWaiting(true);
    pok.reports
      .reportEstimateItemsRawData(filter)
      .then(dataReports => {
        downloadExcel(dataReports);
        setWaiting(false);
      })
      .catch(error => {
        notifications.caughtError(error);
        setWaiting(false);
      });
  };

  return (
    <>
      <FilterYear
        onSearch={onSearch}
        reportName="Surówka - rozbudowane dane pozycji mediaplanów"
      />
      {waiting && (
        <>
          <Alert variant="warning">{alert}</Alert>
          <Waiting />
        </>
      )}
    </>
  );
};
