import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useNotifications } from '../../common/hooks/useNotifications';
import { useAuth } from '../../common/hooks/useAuth';
import { currentMenuItems } from '../../common/navigation';
import { usePokCore } from '../../common/hooks/usePokCore';
import { NotAuthorized } from '../../common/components/NotAuthorized/NotAuthorized';

import SideNav from './SideNav/SideNav';

interface MainProps {
  children: React.ReactNode;
  location: string;
  color: string;
}

const Main: React.FC<MainProps> = ({
  children,
  location,
  color,
}: MainProps) => {
  const auth = useAuth();
  const pok = usePokCore();
  const [authorized, setAuthorized] = useState<boolean>(true);
  const notifications = useNotifications();
  const items = currentMenuItems(location);

  useEffect(() => {
    if (!items) {
      setAuthorized(true);
    }
    const item = items.filter(o => !o.hr).slice(-1)[0];

    let authItem =
      (!item.requiresAnyOf ||
        item.requiresAnyOf.length === 0 ||
        item.requiresAnyOf.filter(role =>
          auth.check(role, item.withContext ? pok.getCompanyId() : undefined),
        ).length > 0) &&
      (!item.requiresTeamAnyOf ||
        item.requiresTeamAnyOf.length === 0 ||
        item.requiresTeamAnyOf.filter(teamName =>
          auth.checkTeam(
            teamName,
            item.withContext ? pok.getCompanyId() : undefined,
          ),
        ).length > 0);

    if (item.withContext && item.requiresCompanyAnyOf) {
      if (!item.requiresCompanyAnyOf?.includes(pok.getCompanyId())) {
        authItem = false;
      }
    }

    setAuthorized(authItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, auth, pok]);

  useEffect(() => {
    notifications.accessForbidden = () => {
      setAuthorized(false);
    };
  }, [notifications]);

  return (
    <Container fluid>
      <Row className="flex-xl-nowrap">
        <Col
          as={SideNav}
          xs={12}
          md={3}
          xl={2}
          location={location}
          color={color}
        />
        <Col
          xs={12}
          md={9}
          xl={10}
          as="main"
          id="main-content"
          className="main"
        >
          {authorized ? children : <NotAuthorized />}
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
