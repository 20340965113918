import React from 'react';

import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';

export const PositionInfo = (props: { id: string }) => {
  const pok = usePokCore();

  return (
    <LastEditorInfo
      id={props.id}
      placement="left"
      method={pok.positions.getLastEditor}
      className=""
      iconSize={18}
    />
  );
};
