import {
  CreateUpdatePurchaseInvoicePositionDto,
  CreateUpdatePurchaseInvoicePositionDtoFromJSON,
  GetPurchaseInvoicePositionDto,
} from '../autogenerated/pokApiClient';
import { PurchaseInvoicePositionSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export const newPurchaseInvoicePosition = () =>
  CreateUpdatePurchaseInvoicePositionDtoFromJSON({});

export const validate = (position: CreateUpdatePurchaseInvoicePositionDto) =>
  validateAgainst(PurchaseInvoicePositionSchema, position);

export const convert = (position?: GetPurchaseInvoicePositionDto) => {
  if (!position) {
    return newPurchaseInvoicePosition();
  }
  return CreateUpdatePurchaseInvoicePositionDtoFromJSON(position);
};
