/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';

/**
 * 
 * @export
 * @interface GetNumberingDto
 */
export interface GetNumberingDto {
    /**
     * 
     * @type {string}
     * @memberof GetNumberingDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetNumberingDto
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetNumberingDto
     */
    actualNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetNumberingDto
     */
    month?: number | null;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetNumberingDto
     */
    company?: GetCompanyDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetNumberingDto
     */
    symbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNumberingDto
     */
    object: GetNumberingDtoObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof GetNumberingDto
     */
    number?: string | null;
}


/**
 * @export
 */
export const GetNumberingDtoObjectEnum = {
    Project: 'Project',
    Invoice: 'Invoice',
    CorrectInvoice: 'CorrectInvoice'
} as const;
export type GetNumberingDtoObjectEnum = typeof GetNumberingDtoObjectEnum[keyof typeof GetNumberingDtoObjectEnum];


/**
 * Check if a given object implements the GetNumberingDto interface.
 */
export function instanceOfGetNumberingDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "object" in value;

    return isInstance;
}

export function GetNumberingDtoFromJSON(json: any): GetNumberingDto {
    return GetNumberingDtoFromJSONTyped(json, false);
}

export function GetNumberingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNumberingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'actualNumber': !exists(json, 'actualNumber') ? undefined : json['actualNumber'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'company': !exists(json, 'company') ? undefined : GetCompanyDtoFromJSON(json['company']),
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'object': json['object'],
        'number': !exists(json, 'number') ? undefined : json['number'],
    };
}

export function GetNumberingDtoToJSON(value?: GetNumberingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'actualNumber': value.actualNumber,
        'month': value.month,
        'company': GetCompanyDtoToJSON(value.company),
        'symbol': value.symbol,
        'object': value.object,
        'number': value.number,
    };
}

