import React from 'react';
import { Alert } from 'react-bootstrap';

export const NotAuthorized: React.FC = () => {
  return (
    <Alert variant="danger">
      <Alert.Heading>Brak wymaganych uprawnień</Alert.Heading>
      <hr />
      <p>W celu podwyższenia uprawnień prosimy o kontakt z administracją.</p>
    </Alert>
  );
};
