import React, { useState, useEffect } from 'react';

import { useAuth } from '../../common/hooks/useAuth';
import { useNotifications } from '../../common/hooks/useNotifications';
import { useSockets } from '../../common/hooks/useSockets';
import {
  ConcurrencyArgs,
  DataChangedArgs,
  DataKey,
  keyId,
} from '../../common/pokCore/sockets/socket.datatypes';

import AlertModal from './AlertModal';

interface ConcurrencySignalerProps {
  dataKey: DataKey;
  objectDescription: string;
  editAllowed: boolean;
  refreshAction: () => void;
}

export const ConcurrencySignaler: React.FC<
  ConcurrencySignalerProps
> = props => {
  const [showInfo, setShowInfo] = useState(false);
  const [info, setInfo] = useState('');
  const sockets = useSockets();
  const notifications = useNotifications();
  const auth = useAuth();

  const propsAreReady = (
    key: DataKey,
    editAllowed: boolean,
    description: string,
  ) => {
    if (!key || !editAllowed) {
      return false;
    }

    if (!description || description.includes('undefined')) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    let mounted = true;

    if (
      !propsAreReady(props.dataKey, props.editAllowed, props.objectDescription)
    ) {
      return;
    }

    const editModeHandler = (data: ConcurrencyArgs) => {
      if (!mounted || keyId(props.dataKey) !== keyId(data.key)) {
        return;
      }

      notifications.conflict(
        <>
          {props.objectDescription + ': '}
          <span className="fw-bold">{data.user.username}</span> jest w trybie
          edycji.
        </>,
      );
    };

    const dataChangedHandler = (args: DataChangedArgs) => {
      if (!mounted) {
        return;
      }
      if (
        props.dataKey.scope === args.key.scope &&
        props.dataKey.id === args.key.id &&
        args.user.sub !== auth.currentUser?.sub
      ) {
        setInfo(
          `${props.objectDescription}: ${args.user.username} właśnie wprowadza zmiany w danych.`,
        );
        setShowInfo(true);
      }
    };

    sockets.onDataChanged(dataChangedHandler);
    sockets.objectInEditMode(props.dataKey, editModeHandler);

    return () => {
      if (
        !propsAreReady(
          props.dataKey,
          props.editAllowed,
          props.objectDescription,
        )
      ) {
        return;
      }
      sockets.objectOutEditMode(props.dataKey, editModeHandler);
      sockets.removeOnDataChanged(dataChangedHandler);
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.currentUser,
    sockets,
    notifications,
    props.dataKey.id,
    props.dataKey.scope,
    props.objectDescription,
    props.editAllowed,
  ]);

  return (
    <AlertModal
      variant="info"
      buttonText="Odśwież"
      buttonVariant="outline-success"
      size="lg"
      show={showInfo}
      onHide={() => {
        setShowInfo(false);
        props.refreshAction();
      }}
    >
      {info}
    </AlertModal>
  );
};
