import React from 'react';
import { Button } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';

interface OrderExecutionPositionsControlsProps {
  handleResetPositions?: () => void;
  handleMergePositionsByActionGroup?: (keys: number[]) => void;
  selected: number[];
  handleMergePositions?: (keys: number[]) => void;
  handleClonePosition?: (key: number) => void;
  readOnly?: boolean;
  isActionGroupAvailable?: boolean;
}

export const OrderExecutionPositionsControls: React.FC<
  OrderExecutionPositionsControlsProps
> = ({
  handleResetPositions,
  selected,
  handleMergePositions,
  handleClonePosition,
  readOnly,
  isActionGroupAvailable,
  handleMergePositionsByActionGroup,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-1">
        {isActionGroupAvailable && (
          <Button
            onClick={() => handleMergePositionsByActionGroup?.(selected)}
            disabled={readOnly}
          >
            Scal {selected.length ? ' wybrane ' : ''} pozycje według grupy
            działań
          </Button>
        )}
        {selected.length > 1 && (
          <Button
            onClick={() => handleMergePositions?.(selected)}
            disabled={readOnly}
          >
            Scal pozycje
          </Button>
        )}
        {selected.length === 1 && (
          <Button
            onClick={() => handleClonePosition?.(selected[0])}
            disabled={readOnly}
          >
            Klonuj pozycję
          </Button>
        )}
        <ConfirmationButton
          variant="outline-danger"
          onOK={() => handleResetPositions?.()}
          confirmation="Czy na pewno chcesz zresetować pozycje?"
          disabled={readOnly}
        >
          Resetuj wszystkie pozycje
        </ConfirmationButton>
      </div>
    </div>
  );
};
