/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUpdateInvoiceEstimateItemDto } from './CreateUpdateInvoiceEstimateItemDto';
import {
    CreateUpdateInvoiceEstimateItemDtoFromJSON,
    CreateUpdateInvoiceEstimateItemDtoFromJSONTyped,
    CreateUpdateInvoiceEstimateItemDtoToJSON,
} from './CreateUpdateInvoiceEstimateItemDto';

/**
 * 
 * @export
 * @interface CreateUpdateInvoiceEstimateItemsDto
 */
export interface CreateUpdateInvoiceEstimateItemsDto {
    /**
     * 
     * @type {Array<CreateUpdateInvoiceEstimateItemDto>}
     * @memberof CreateUpdateInvoiceEstimateItemsDto
     */
    estimateItems: Array<CreateUpdateInvoiceEstimateItemDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvoiceEstimateItemsDto
     */
    salesInvoiceId: string;
}

/**
 * Check if a given object implements the CreateUpdateInvoiceEstimateItemsDto interface.
 */
export function instanceOfCreateUpdateInvoiceEstimateItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimateItems" in value;
    isInstance = isInstance && "salesInvoiceId" in value;

    return isInstance;
}

export function CreateUpdateInvoiceEstimateItemsDtoFromJSON(json: any): CreateUpdateInvoiceEstimateItemsDto {
    return CreateUpdateInvoiceEstimateItemsDtoFromJSONTyped(json, false);
}

export function CreateUpdateInvoiceEstimateItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInvoiceEstimateItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimateItems': ((json['estimateItems'] as Array<any>).map(CreateUpdateInvoiceEstimateItemDtoFromJSON)),
        'salesInvoiceId': json['salesInvoiceId'],
    };
}

export function CreateUpdateInvoiceEstimateItemsDtoToJSON(value?: CreateUpdateInvoiceEstimateItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimateItems': ((value.estimateItems as Array<any>).map(CreateUpdateInvoiceEstimateItemDtoToJSON)),
        'salesInvoiceId': value.salesInvoiceId,
    };
}

