/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBudgetDto } from './GetBudgetDto';
import {
    GetBudgetDtoFromJSON,
    GetBudgetDtoFromJSONTyped,
    GetBudgetDtoToJSON,
} from './GetBudgetDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetInvoiceEstimateItemDto } from './GetInvoiceEstimateItemDto';
import {
    GetInvoiceEstimateItemDtoFromJSON,
    GetInvoiceEstimateItemDtoFromJSONTyped,
    GetInvoiceEstimateItemDtoToJSON,
} from './GetInvoiceEstimateItemDto';
import type { GetPurchaserDto } from './GetPurchaserDto';
import {
    GetPurchaserDtoFromJSON,
    GetPurchaserDtoFromJSONTyped,
    GetPurchaserDtoToJSON,
} from './GetPurchaserDto';
import type { GetSalesInvoicePositionDto } from './GetSalesInvoicePositionDto';
import {
    GetSalesInvoicePositionDtoFromJSON,
    GetSalesInvoicePositionDtoFromJSONTyped,
    GetSalesInvoicePositionDtoToJSON,
} from './GetSalesInvoicePositionDto';
import type { GetSalesInvoiceProjectDto } from './GetSalesInvoiceProjectDto';
import {
    GetSalesInvoiceProjectDtoFromJSON,
    GetSalesInvoiceProjectDtoFromJSONTyped,
    GetSalesInvoiceProjectDtoToJSON,
} from './GetSalesInvoiceProjectDto';

/**
 * 
 * @export
 * @interface GetSalesInvoiceDto
 */
export interface GetSalesInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    number: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSalesInvoiceDto
     */
    invoiceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetSalesInvoiceDto
     */
    saleDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    type: GetSalesInvoiceDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    currency: GetSalesInvoiceDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    status: GetSalesInvoiceDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    paymentForm: GetSalesInvoiceDtoPaymentFormEnum;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetSalesInvoiceDto
     */
    payableDays: GetDictionaryDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoiceDto
     */
    printProjectNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoiceDto
     */
    isPaymentFromReceiptDate: boolean;
    /**
     * 
     * @type {Array<GetInvoiceEstimateItemDto>}
     * @memberof GetSalesInvoiceDto
     */
    invoiceEstimateItems?: Array<GetInvoiceEstimateItemDto>;
    /**
     * 
     * @type {Array<GetSalesInvoicePositionDto>}
     * @memberof GetSalesInvoiceDto
     */
    salesInvoicePositions?: Array<GetSalesInvoicePositionDto>;
    /**
     * 
     * @type {Array<GetSalesInvoiceProjectDto>}
     * @memberof GetSalesInvoiceDto
     */
    salesInvoiceProjects?: Array<GetSalesInvoiceProjectDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoiceDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoiceDto
     */
    isOnePositionOnInvoice: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    onePositionName: string;
    /**
     * 
     * @type {number}
     * @memberof GetSalesInvoiceDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    exchangeRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    exchangeRateDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    gtuCodeId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSalesInvoiceDto
     */
    documentCodeIds: Array<string>;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetSalesInvoiceDto
     */
    parent: GetSalesInvoiceDto;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetSalesInvoiceDto
     */
    originalInvoice: GetSalesInvoiceDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetSalesInvoiceDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {GetPurchaserDto}
     * @memberof GetSalesInvoiceDto
     */
    purchaser: GetPurchaserDto;
    /**
     * 
     * @type {GetBudgetDto}
     * @memberof GetSalesInvoiceDto
     */
    budget: GetBudgetDto;
    /**
     * 
     * @type {number}
     * @memberof GetSalesInvoiceDto
     */
    netSum?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoiceDto
     */
    isSymphonyCode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    textOverItems: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceDto
     */
    correctionReason: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetSalesInvoiceDto
     */
    approver?: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetSalesInvoiceDto
     */
    approvedTs?: Date | null;
}


/**
 * @export
 */
export const GetSalesInvoiceDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type GetSalesInvoiceDtoTypeEnum = typeof GetSalesInvoiceDtoTypeEnum[keyof typeof GetSalesInvoiceDtoTypeEnum];

/**
 * @export
 */
export const GetSalesInvoiceDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type GetSalesInvoiceDtoCurrencyEnum = typeof GetSalesInvoiceDtoCurrencyEnum[keyof typeof GetSalesInvoiceDtoCurrencyEnum];

/**
 * @export
 */
export const GetSalesInvoiceDtoStatusEnum = {
    Draft: 'Draft',
    Generated: 'Generated',
    Sent: 'Sent',
    Paid: 'Paid'
} as const;
export type GetSalesInvoiceDtoStatusEnum = typeof GetSalesInvoiceDtoStatusEnum[keyof typeof GetSalesInvoiceDtoStatusEnum];

/**
 * @export
 */
export const GetSalesInvoiceDtoPaymentFormEnum = {
    BankTransfer: 'BankTransfer',
    Compensation: 'Compensation',
    Prepayment: 'Prepayment',
    Paid: 'Paid'
} as const;
export type GetSalesInvoiceDtoPaymentFormEnum = typeof GetSalesInvoiceDtoPaymentFormEnum[keyof typeof GetSalesInvoiceDtoPaymentFormEnum];


/**
 * Check if a given object implements the GetSalesInvoiceDto interface.
 */
export function instanceOfGetSalesInvoiceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "invoiceDate" in value;
    isInstance = isInstance && "saleDate" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "paymentForm" in value;
    isInstance = isInstance && "payableDays" in value;
    isInstance = isInstance && "printProjectNumber" in value;
    isInstance = isInstance && "isPaymentFromReceiptDate" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "isOnePositionOnInvoice" in value;
    isInstance = isInstance && "onePositionName" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "gtuCodeId" in value;
    isInstance = isInstance && "documentCodeIds" in value;
    isInstance = isInstance && "parent" in value;
    isInstance = isInstance && "originalInvoice" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "purchaser" in value;
    isInstance = isInstance && "budget" in value;
    isInstance = isInstance && "textOverItems" in value;
    isInstance = isInstance && "correctionReason" in value;

    return isInstance;
}

export function GetSalesInvoiceDtoFromJSON(json: any): GetSalesInvoiceDto {
    return GetSalesInvoiceDtoFromJSONTyped(json, false);
}

export function GetSalesInvoiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalesInvoiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'invoiceDate': (new Date(json['invoiceDate'])),
        'saleDate': (new Date(json['saleDate'])),
        'type': json['type'],
        'currency': json['currency'],
        'status': json['status'],
        'paymentForm': json['paymentForm'],
        'payableDays': GetDictionaryDtoFromJSON(json['payableDays']),
        'printProjectNumber': json['printProjectNumber'],
        'isPaymentFromReceiptDate': json['isPaymentFromReceiptDate'],
        'invoiceEstimateItems': !exists(json, 'invoiceEstimateItems') ? undefined : ((json['invoiceEstimateItems'] as Array<any>).map(GetInvoiceEstimateItemDtoFromJSON)),
        'salesInvoicePositions': !exists(json, 'salesInvoicePositions') ? undefined : ((json['salesInvoicePositions'] as Array<any>).map(GetSalesInvoicePositionDtoFromJSON)),
        'salesInvoiceProjects': !exists(json, 'salesInvoiceProjects') ? undefined : ((json['salesInvoiceProjects'] as Array<any>).map(GetSalesInvoiceProjectDtoFromJSON)),
        'active': json['active'],
        'isOnePositionOnInvoice': json['isOnePositionOnInvoice'],
        'onePositionName': json['onePositionName'],
        'systemNumber': json['systemNumber'],
        'exchangeRate': !exists(json, 'exchangeRate') ? undefined : json['exchangeRate'],
        'exchangeRateDescription': !exists(json, 'exchangeRateDescription') ? undefined : json['exchangeRateDescription'],
        'gtuCodeId': json['gtuCodeId'],
        'documentCodeIds': json['documentCodeIds'],
        'parent': GetSalesInvoiceDtoFromJSON(json['parent']),
        'originalInvoice': GetSalesInvoiceDtoFromJSON(json['originalInvoice']),
        'company': GetCompanyDtoFromJSON(json['company']),
        'purchaser': GetPurchaserDtoFromJSON(json['purchaser']),
        'budget': GetBudgetDtoFromJSON(json['budget']),
        'netSum': !exists(json, 'netSum') ? undefined : json['netSum'],
        'isSymphonyCode': !exists(json, 'isSymphonyCode') ? undefined : json['isSymphonyCode'],
        'textOverItems': json['textOverItems'],
        'correctionReason': json['correctionReason'],
        'approver': !exists(json, 'approver') ? undefined : GetEmployeeDtoFromJSON(json['approver']),
        'approvedTs': !exists(json, 'approvedTs') ? undefined : (json['approvedTs'] === null ? null : new Date(json['approvedTs'])),
    };
}

export function GetSalesInvoiceDtoToJSON(value?: GetSalesInvoiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'invoiceDate': (value.invoiceDate.toISOString()),
        'saleDate': (value.saleDate.toISOString()),
        'type': value.type,
        'currency': value.currency,
        'status': value.status,
        'paymentForm': value.paymentForm,
        'payableDays': GetDictionaryDtoToJSON(value.payableDays),
        'printProjectNumber': value.printProjectNumber,
        'isPaymentFromReceiptDate': value.isPaymentFromReceiptDate,
        'invoiceEstimateItems': value.invoiceEstimateItems === undefined ? undefined : ((value.invoiceEstimateItems as Array<any>).map(GetInvoiceEstimateItemDtoToJSON)),
        'salesInvoicePositions': value.salesInvoicePositions === undefined ? undefined : ((value.salesInvoicePositions as Array<any>).map(GetSalesInvoicePositionDtoToJSON)),
        'salesInvoiceProjects': value.salesInvoiceProjects === undefined ? undefined : ((value.salesInvoiceProjects as Array<any>).map(GetSalesInvoiceProjectDtoToJSON)),
        'active': value.active,
        'isOnePositionOnInvoice': value.isOnePositionOnInvoice,
        'onePositionName': value.onePositionName,
        'systemNumber': value.systemNumber,
        'exchangeRate': value.exchangeRate,
        'exchangeRateDescription': value.exchangeRateDescription,
        'gtuCodeId': value.gtuCodeId,
        'documentCodeIds': value.documentCodeIds,
        'parent': GetSalesInvoiceDtoToJSON(value.parent),
        'originalInvoice': GetSalesInvoiceDtoToJSON(value.originalInvoice),
        'company': GetCompanyDtoToJSON(value.company),
        'purchaser': GetPurchaserDtoToJSON(value.purchaser),
        'budget': GetBudgetDtoToJSON(value.budget),
        'netSum': value.netSum,
        'isSymphonyCode': value.isSymphonyCode,
        'textOverItems': value.textOverItems,
        'correctionReason': value.correctionReason,
        'approver': GetEmployeeDtoToJSON(value.approver),
        'approvedTs': value.approvedTs === undefined ? undefined : (value.approvedTs === null ? null : value.approvedTs.toISOString()),
    };
}

