import React from 'react';

import LastEditorInfo from '../../../app/components/LastEditorInfo';
import Button from '../../../common/components/Button/Button';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

interface SpecialInvoicingControlsProps {
  id?: string;
  readOnly?: boolean;
  handleSave: () => Promise<void>;
  handleDeactivate?: () => Promise<void>;
  isLoadingSave: boolean;
  isLoadingDeactivate?: boolean;
}

export const SpecialInvoicingControls: React.FC<
  SpecialInvoicingControlsProps
> = props => {
  const id = props.id && props.id !== 'utworz' ? props.id : undefined;
  const pok = usePokCore();

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      {id && (
        <LastEditorInfo
          id={id}
          className="color-primary"
          method={pok.specialInvoicing.getLastEditor}
        />
      )}
      <div className="d-flex marginLeft gap-2">
        {!props.readOnly && props.id && props.handleDeactivate && (
          <ConfirmationButton
            className="mx-1"
            confirmation="Czy na pewno chcesz usunąć ten wniosek?"
            variant="outline-danger"
            onOK={props.handleDeactivate}
            isLoading={props.isLoadingDeactivate}
            disabled={props.isLoadingSave}
          >
            Usuń
          </ConfirmationButton>
        )}
        {!props.readOnly && (
          <Button
            variant="primary"
            onClick={props.handleSave}
            isLoading={props.isLoadingSave}
            disabled={props.isLoadingDeactivate}
          >
            Zapisz
          </Button>
        )}
      </div>
    </div>
  );
};
