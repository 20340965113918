/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProfileSchemaDto } from './ProfileSchemaDto';
import {
    ProfileSchemaDtoFromJSON,
    ProfileSchemaDtoFromJSONTyped,
    ProfileSchemaDtoToJSON,
} from './ProfileSchemaDto';

/**
 * 
 * @export
 * @interface GetProfileDto
 */
export interface GetProfileDto {
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    name: string;
    /**
     * 
     * @type {ProfileSchemaDto}
     * @memberof GetProfileDto
     */
    schema: ProfileSchemaDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetProfileDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProfileDto
     */
    commission: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProfileDto
     */
    tv: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProfileDto
     */
    tvBuying: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProfileDto
     */
    autoSalesBlockDisabled: boolean;
}

/**
 * Check if a given object implements the GetProfileDto interface.
 */
export function instanceOfGetProfileDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "schema" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "tv" in value;
    isInstance = isInstance && "tvBuying" in value;
    isInstance = isInstance && "autoSalesBlockDisabled" in value;

    return isInstance;
}

export function GetProfileDtoFromJSON(json: any): GetProfileDto {
    return GetProfileDtoFromJSONTyped(json, false);
}

export function GetProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'schema': ProfileSchemaDtoFromJSON(json['schema']),
        'active': json['active'],
        'commission': json['commission'],
        'tv': json['tv'],
        'tvBuying': json['tvBuying'],
        'autoSalesBlockDisabled': json['autoSalesBlockDisabled'],
    };
}

export function GetProfileDtoToJSON(value?: GetProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'schema': ProfileSchemaDtoToJSON(value.schema),
        'active': value.active,
        'commission': value.commission,
        'tv': value.tv,
        'tvBuying': value.tvBuying,
        'autoSalesBlockDisabled': value.autoSalesBlockDisabled,
    };
}

