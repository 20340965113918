import { estimateByMonthForGrid } from '../../../pok/components/EstimatesByMonth/EstimateByMonthSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { FilterColumn } from '../../types/FilterColumn';
import {
  GetEstimateByMonthDtoStatusEnum,
  GetEstimateByMonthWithItemsDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import {
  CreateUpdateEstimateByMonthDto,
  CreateUpdateEstimateByMonthDtoFromJSON,
  DefaultApi,
  GetEstimateByMonthDto,
} from '../autogenerated/pokApiClient';
import {
  ApplicationUnblockTypeEnum,
  EstimateByMonthSchema,
  ProjectEstimatesStatusEnum,
} from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface EstimateByMonthContextInterface {
  getById: (id: string) => Promise<GetEstimateByMonthDto>;
  create: (
    dto: CreateUpdateEstimateByMonthDto,
  ) => Promise<GetEstimateByMonthDto>;
  findByProjectId: (projectId: string) => Promise<GetEstimateByMonthDto[]>;
  findWithItemsByProjectId: (
    projectId: string,
  ) => Promise<GetEstimateByMonthWithItemsDto[]>;
  updateStatus: (
    status: ProjectEstimatesStatusEnum,
    id: string,
  ) => Promise<void>;
  toggleBlocked: (
    id: string,
    value: boolean,
    type: ApplicationUnblockTypeEnum,
  ) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  clone: (
    id: string,
    date: Date,
    withAmounts: boolean,
  ) => Promise<GetEstimateByMonthDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
}

export const EstimateByMonthContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.estimateByMonthControllerGet(id),
    create: (dto: CreateUpdateEstimateByMonthDto) =>
      api.estimateByMonthControllerCreate(dto),
    findByProjectId: (projectId: string) =>
      api.estimateByMonthControllerFindByProject(projectId).then(profiles => {
        profiles.sort((a, b) => {
          if (
            a.status === GetEstimateByMonthDtoStatusEnum.Cancelled &&
            b.status !== GetEstimateByMonthDtoStatusEnum.Cancelled
          ) {
            return 1;
          } else if (
            a.status !== GetEstimateByMonthDtoStatusEnum.Cancelled &&
            b.status === GetEstimateByMonthDtoStatusEnum.Cancelled
          ) {
            return -1;
          } else {
            const datesComparison = a.date.getTime() - b.date.getTime();
            if (datesComparison !== 0) {
              return datesComparison;
            }

            return a.profile.name.localeCompare(b.profile.name);
          }
        });
        return profiles;
      }),
    findWithItemsByProjectId: (projectId: string) =>
      api
        .estimateByMonthControllerFindEstimateItemsByProject(projectId)
        .then(profiles => {
          profiles.sort((a, b) => {
            const names = a.profile.name.localeCompare(b.profile.name);
            if (names !== 0) {
              return names;
            }
            return a.date.getTime() - b.date.getTime();
          });
          return profiles;
        }),
    updateStatus: (status: ProjectEstimatesStatusEnum, id: string) =>
      api.estimateByMonthControllerUpdateStatus(status, id),
    toggleBlocked: (
      id: string,
      value: boolean,
      type: ApplicationUnblockTypeEnum,
    ) => api.estimateByMonthControllerToggleBlocked(type, value, id),
    delete: (id: string) => api.estimateByMonthControllerDeactivate(id),
    getLastEditor: (id: string) =>
      api.estimateByMonthControllerGetLastEditor(id),
    clone: (id: string, date: Date, withAmounts: boolean) =>
      api.estimateByMonthControllerClone(id, date, withAmounts),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.estimateByMonthControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(data => estimateByMonthForGrid(data, true));
    },
    getAllCount: async (filterText?: string, filterColumns?: FilterColumn) => {
      return await api.estimateByMonthControllerGetAllCount(
        filterText || '',
        filterColumns,
      );
    },
  };
};

export const newEstimateByMonth = (projectId?: string) => {
  return CreateUpdateEstimateByMonthDtoFromJSON({
    projectId: projectId,
    status: ProjectEstimatesStatusEnum.New,
    active: true,
  });
};

export const validate = (position: CreateUpdateEstimateByMonthDto) =>
  validateAgainst(EstimateByMonthSchema, position);
