import { employeeForGrid } from '../../../pok/components/Employees/EmployeeSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { DefaultApi, GetEmployeeDto } from '../autogenerated/pokApiClient';

export interface EmployeeContextInterface {
  getCurrent: () => Promise<GetEmployeeDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  addToEmployeesFromOneTool: () => Promise<void>;
  getByCompanyId: (
    id?: string,
    withTechnical?: boolean,
  ) => Promise<GetEmployeeDto[]>;
  getAllForOrders: () => Promise<GetEmployeeDto[]>;
}

export const EmployeeContext = (api: DefaultApi) => {
  return {
    getCurrent: () => api.employeeControllerGetCurrent(),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.employeeControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(employeeForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.employeeControllerGetAllCount(filterText || ''),

    addToEmployeesFromOneTool: () =>
      api.employeeControllerAddToEmployeesFromOneTool(),
    getByCompanyId: (id?: string, withTechnical?: boolean) =>
      api
        .employeeControllerGetByCompany(id, withTechnical)
        .then(emp => emp.sort((a, b) => a.name?.localeCompare(b.name, 'pl'))),
    getAllForOrders: () => api.employeeControllerGetAllForOrders(),
  };
};
