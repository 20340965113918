import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetBrandDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface BrandSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const brandForGrid = (brand: GetBrandDto) => {
  return {
    key: brand.id,
    values: {
      'brand.systemNumber': brand.systemNumber,
      'brand.name': brand.name,
      'budget.name': brand.budget.name,
    },
    isInactive: !brand.active,
  };
};

export const BrandSearch: React.FC<BrandSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'brand.systemNumber' },
    { header: 'Nazwa', property: 'brand.name' },
    { header: 'Budżet brandu', property: 'budget.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="brand.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak brandów w systemie"
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
