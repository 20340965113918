import {
  CreateUpdateExcelDto,
  CreateUpdateExcelDtoFromJSON,
  DefaultApi,
  GetExcelAndPokDataDto,
  GetExcelDataDto,
  GetExcelRowDto,
  GetResultsDto,
} from '../autogenerated/pokApiClient';
import { ProjectExcelImportSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface ProjectExcelImportContextInterface {
  getDataFromExcel: (body: CreateUpdateExcelDto) => Promise<GetExcelDataDto>;
  getAggregatedData: (body: GetExcelDataDto) => Promise<GetExcelRowDto[]>;
  getExcelAndPokData: (body: GetExcelDataDto) => Promise<GetExcelAndPokDataDto>;
  importExcelData(excel: GetExcelAndPokDataDto): Promise<GetResultsDto>;
  recheckData: (
    body: GetExcelDataDto,
    aggregatePositions: boolean,
  ) => Promise<GetExcelDataDto>;
}

export const ProjectExcelImportContext = (api: DefaultApi) => {
  return {
    getDataFromExcel: (body: CreateUpdateExcelDto) =>
      api.excelControllerGetDataFromExcel(body),
    getAggregatedData: (body: GetExcelDataDto) =>
      api.excelControllerGetAggregatedData(body),
    getExcelAndPokData: (body: GetExcelDataDto) =>
      api.excelControllerGetExcelAndPokData(body),
    importExcelData: (excel: GetExcelAndPokDataDto) =>
      api.excelControllerImportExcelData(excel),
    recheckData: (body: GetExcelDataDto, aggregatePositions: boolean) =>
      api.excelControllerRecheckData(aggregatePositions, body),
  };
};

export const newExcel = () => {
  return CreateUpdateExcelDtoFromJSON({});
};

export const validate = (purchaser: CreateUpdateExcelDto) =>
  validateAgainst(ProjectExcelImportSchema, purchaser);
