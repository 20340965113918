/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeDto
 */
export interface ExchangeDto {
    /**
     * 
     * @type {Date}
     * @memberof ExchangeDto
     */
    effectiveDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExchangeDto
     */
    currencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeDto
     */
    currencyIsoCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeDto
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeDto
     */
    exchangeTableNo?: string;
}

/**
 * Check if a given object implements the ExchangeDto interface.
 */
export function instanceOfExchangeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExchangeDtoFromJSON(json: any): ExchangeDto {
    return ExchangeDtoFromJSONTyped(json, false);
}

export function ExchangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'effectiveDate': !exists(json, 'effectiveDate') ? undefined : (new Date(json['effectiveDate'])),
        'currencyName': !exists(json, 'currencyName') ? undefined : json['currencyName'],
        'currencyIsoCode': !exists(json, 'currencyIsoCode') ? undefined : json['currencyIsoCode'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'exchangeTableNo': !exists(json, 'exchangeTableNo') ? undefined : json['exchangeTableNo'],
    };
}

export function ExchangeDtoToJSON(value?: ExchangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'effectiveDate': value.effectiveDate === undefined ? undefined : (value.effectiveDate.toISOString()),
        'currencyName': value.currencyName,
        'currencyIsoCode': value.currencyIsoCode,
        'rate': value.rate,
        'exchangeTableNo': value.exchangeTableNo,
    };
}

