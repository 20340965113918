import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateExcelConfigPositionDto,
  GetProfileDto,
  ParametersSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import PositionSelector from '../Selects/PositionSelector';
import { hasAnyParameterFilled } from '../../../common/pokCore/contexts/ExcelConfigPositionContext';

import { PositionParameters } from './PositionParameters';

interface PositionEditorProps {
  id?: string;
  position: CreateUpdateExcelConfigPositionDto;
  profile: GetProfileDto;
  teamIds?: string[];
  handleClose?: () => void;
  show?: boolean;
  showParameters?: boolean;
  hideActiveChbx?: boolean;
  onCompleted: (
    position: CreateUpdateExcelConfigPositionDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const PositionEditor: React.FC<PositionEditorProps> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showParameters, setShowParameters] = useState(props.showParameters);
  const [position, setPosition] = useState(props.position);

  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setPosition(props.position);
      setShowParameters(
        hasAnyParameterFilled(props.position.parameters) ||
          props.showParameters,
      );
    }
  }, [props.position, props.show, props.showParameters]);

  const createPosition = async () => {
    setIsLoading(true);
    props
      .onCompleted(position, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateExcelConfigPositionDto>) => {
    setPosition({ ...position, ...obj });
  };
  const parametersPropertyChange = (obj: Partial<ParametersSchemaDto>) => {
    for (const key in obj) {
      if (obj[key as keyof ParametersSchemaDto] === null) {
        obj[key as keyof ParametersSchemaDto] = undefined;
      }
    }

    setPosition({
      ...position,
      parameters: { ...position.parameters, ...obj },
    });
  };

  const showParametersChange = (checked: boolean) => {
    if (!checked) {
      for (const key in position.parameters) {
        // eslint-disable-next-line no-prototype-builtins
        if (position.parameters[key as keyof ParametersSchemaDto]) {
          position.parameters[key as keyof ParametersSchemaDto] = undefined;
        }
      }
      setPosition({ ...position });
    }
    setShowParameters(checked);
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? `Edycja mapowania` : 'Dodawanie nowego mapowania'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3">
            <FormRow controlId="excelPositionName" label="Pozycja w Excel">
              <Form.Control
                type="text"
                value={position?.excelPositionName || ''}
                onChange={e =>
                  propertyChange({ excelPositionName: e.target.value })
                }
              />
            </FormRow>
            <FormRow controlId="positionName" label="Pozycja w POK">
              <PositionSelector
                teamIds={props.teamIds ? props.teamIds : []}
                profileId={props.profile?.id}
                commission={props.profile?.commission}
                value={position?.positionId}
                onChange={option =>
                  propertyChange({
                    positionId: option?.value,
                  })
                }
              />
            </FormRow>
            {!props.hideActiveChbx && (
              <FormRow controlId="active" label="Aktywny">
                <Form.Check
                  type="checkbox"
                  className="pt-2"
                  checked={position?.active ?? true}
                  onChange={e => propertyChange({ active: e.target.checked })}
                />
              </FormRow>
            )}
            <FormRow controlId="parameters" label="Parametry">
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={showParameters}
                onChange={e => showParametersChange(e.target.checked)}
              />
            </FormRow>
            {props.profile?.schema && showParameters && (
              <PositionParameters
                schema={props.profile.schema}
                parameters={position.parameters}
                propertyChange={parametersPropertyChange}
              />
            )}
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.excelConfigPositions.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            Zamknij
          </Button>
          <Button
            variant="primary"
            onClick={createPosition}
            isLoading={isLoading}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
