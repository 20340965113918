/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSymphonyClientDto
 */
export interface CreateUpdateSymphonyClientDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSymphonyClientDto
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSymphonyClientDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSymphonyClientDto
     */
    companyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSymphonyClientDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the CreateUpdateSymphonyClientDto interface.
 */
export function instanceOfCreateUpdateSymphonyClientDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function CreateUpdateSymphonyClientDtoFromJSON(json: any): CreateUpdateSymphonyClientDto {
    return CreateUpdateSymphonyClientDtoFromJSONTyped(json, false);
}

export function CreateUpdateSymphonyClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSymphonyClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'code': json['code'],
        'companyId': json['companyId'],
        'active': json['active'],
    };
}

export function CreateUpdateSymphonyClientDtoToJSON(value?: CreateUpdateSymphonyClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'code': value.code,
        'companyId': value.companyId,
        'active': value.active,
    };
}

