import {
  CreateUpdateSpecialInvoicingDto,
  CreateUpdateSpecialInvoicingDtoFromJSON,
  DecisionSpecialInvoicingDto,
  DecisionSpecialInvoicingDtoFromJSON,
  DefaultApi,
  GetLastEditorDto,
  GetSpecialInvoicingDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import {
  SpecialInvoicingDecisionSchema,
  SpecialInvoicingSchema,
  SpecialInvoicingStatus,
} from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { specialInvoicingForGrid } from '../../../pok/components/SpecialInvoicing/SpecialInvoicingSearch';

export interface SpecialInvoicingContextInterface {
  getById: (id: string) => Promise<GetSpecialInvoicingDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    statuses?: SpecialInvoicingStatus[],
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    statuses?: SpecialInvoicingStatus[],
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateSpecialInvoicingDto) => Promise<void>;
  create: (
    dto: CreateUpdateSpecialInvoicingDto,
  ) => Promise<GetSpecialInvoicingDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  decision: (id: string, dto: DecisionSpecialInvoicingDto) => Promise<void>;
  deactivate: (id: string) => Promise<void>;
}

export const SpecialInvoicingContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.specialInvoicingControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      statuses?: SpecialInvoicingStatus[],
    ) => {
      const data = await api.specialInvoicingControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        statuses?.map(v => v.toString()),
      );

      return data.map(specialInvoicingForGrid);
    },
    getAllCount: async (
      filterText?: string,
      statuses?: SpecialInvoicingStatus[],
    ) =>
      api.specialInvoicingControllerGetAllCount(
        filterText || '',
        statuses?.map(v => v.toString()),
      ),
    update: (id: string, dto: CreateUpdateSpecialInvoicingDto) =>
      api.specialInvoicingControllerUpdate(id, dto),
    create: (dto: CreateUpdateSpecialInvoicingDto) =>
      api.specialInvoicingControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.specialInvoicingControllerGetLastEditor(id),
    decision: (id: string, dto: DecisionSpecialInvoicingDto) =>
      api.specialInvoicingControllerUpdateDecision(id, dto),
    deactivate: (id: string) => api.specialInvoicingControllerDeactivate(id),
  };
};

export const validate = (specialInvoicing: CreateUpdateSpecialInvoicingDto) =>
  validateAgainst(SpecialInvoicingSchema, specialInvoicing);

export const validateDecision = (
  specialInvoicing: DecisionSpecialInvoicingDto,
) => validateAgainst(SpecialInvoicingDecisionSchema, specialInvoicing);

export const newSpecialInvoicing = (companyId?: string) => {
  return CreateUpdateSpecialInvoicingDtoFromJSON({
    companyId: companyId,
  });
};

export const convert = (
  specialInvoicing?: GetSpecialInvoicingDto,
  companyId?: string,
) => {
  if (!specialInvoicing) {
    return newSpecialInvoicing(companyId);
  }
  const converted = CreateUpdateSpecialInvoicingDtoFromJSON(specialInvoicing);
  converted.projectIds =
    specialInvoicing.specialInvoicingProjects?.map(
      project => project.project.id,
    ) || [];
  converted.companyId = specialInvoicing.company?.id;

  return converted;
};

export const convertDecision = (specialInvoicing: GetSpecialInvoicingDto) => {
  return DecisionSpecialInvoicingDtoFromJSON(specialInvoicing);
};
