/*eslint complexity: ["error", 12]*/
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { CreateUpdateCompanyDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  PersonalLanguageType,
  PersonalLanguageTypeText,
} from '../../../common/pokCore/validation/schemas';
import { UploadFile } from '../../../common/components/File/UploadFile';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

interface CompanyEditorFormProps {
  id?: string;
  company: CreateUpdateCompanyDto;
  show?: boolean;
  creatingCompany?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateCompanyDto>) => void;
}

export const CompanyEditorForm: React.FC<CompanyEditorFormProps> = props => {
  const propertyChange = (obj: Partial<CreateUpdateCompanyDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  const setFile = (logo: string) => {
    propertyChange({ logo: logo });
  };

  const getAllLanguages = () => {
    const arr = Object.keys(PersonalLanguageTypeText).map(name => {
      return {
        value: PersonalLanguageType[name as keyof typeof PersonalLanguageType],
        label:
          PersonalLanguageTypeText[
            name as keyof typeof PersonalLanguageTypeText
          ],
      };
    });
    return arr;
  };

  const getSelectedLanguage = useCallback(() => {
    return getAllLanguages().filter(
      item => item.value === props.company.personalLanguage,
    );
  }, [props.company.personalLanguage]);

  const [selectedLanguage, setSelectedLanguage] = useState(
    getSelectedLanguage(),
  );

  useEffect(() => {
    const lang = getSelectedLanguage();
    setSelectedLanguage(lang);
  }, [props.company.personalLanguage, getSelectedLanguage]);

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="name" label="Nazwa">
          <Form.Control
            type="text"
            value={props.company?.name || ''}
            onChange={e => propertyChange({ name: e.target.value })}
          />
        </FormRow>

        <FormRow controlId="shortname" label="Skrót">
          <Form.Control
            type="text"
            value={props.company?.shortname || ''}
            onChange={e => propertyChange({ shortname: e.target.value })}
          />
        </FormRow>

        <FormRow controlId="language" label="Nazewnictwo">
          <Select
            options={getAllLanguages()}
            value={selectedLanguage}
            onChange={options =>
              propertyChange({ personalLanguage: options?.value })
            }
            placeholder="Wskaż..."
          />
        </FormRow>

        <FormRow controlId="delay" label="Maks. opóźnienie kampanii">
          <Form.Control
            type="number"
            min={0}
            value={props.company?.projectMaxDaysDelay ?? ''}
            onChange={e =>
              propertyChange({
                projectMaxDaysDelay: parseInt(e.target.value, 10),
              })
            }
          />
        </FormRow>

        <FormRow controlId="order" label="Liczba porządkowa">
          <Form.Control
            type="number"
            min={0}
            value={props.company?.orderField ?? ''}
            onChange={e =>
              propertyChange({
                orderField: parseInt(e.target.value, 10),
              })
            }
          />
        </FormRow>
        <FormRow controlId="invoicesEmail" label="E-mail na faktury">
          <Form.Control
            type="text"
            value={props.company?.invoicesEmail || ''}
            onChange={e => propertyChange({ invoicesEmail: e.target.value })}
          />
        </FormRow>
        <FormRow controlId="footer" label="Stopka">
          <DynamicTextarea
            onChange={e => propertyChange({ footer: e.target.value })}
            value={props.company?.footer || ''}
          />
        </FormRow>
        <FormRow controlId="footerInEnglish" label="Stopka w j. angielskim">
          <DynamicTextarea
            onChange={e => propertyChange({ footerInEnglish: e.target.value })}
            value={props.company?.footerInEnglish || ''}
          />
        </FormRow>

        <FormRow controlId="color" label="Kolor">
          <Form.Control
            type="color"
            value={props.company?.color || '#ffffff'}
            onChange={e => propertyChange({ color: e.target.value })}
          />
        </FormRow>

        <FormRow controlId="logo" label="Logo" className="mt-3">
          <UploadFile
            file={props.company.logo}
            setFile={setFile}
            imageHeight={50}
            buttonText={'Wgraj plik graficzny'}
          />
        </FormRow>
        <FormRow controlId="showMargin" label="Pokaż marżę" className="mt-3">
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.company.showMargin}
            onChange={e => propertyChange({ showMargin: e.target.checked })}
          />
        </FormRow>
        <FormRow controlId="active" label="Aktywna">
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={
              props.company?.active ||
              props.company?.active === undefined ||
              props.company?.active === null
                ? true
                : false
            }
            onChange={e => propertyChange({ active: e.target.checked })}
          />
        </FormRow>
      </Form>
    </>
  );
};
