/*eslint max-lines-per-function: ["error", 200]*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreatePrivateMemoDto,
  GetEmployeeDto,
  GetEmployeeDtoFromJSON,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useMemosProvider } from '../../../common/hooks/useMemosProvider';

import MemosLoadingSpinner from './MemosLoadingSpinner';
import { NewPrivateMemo } from './NewPrivateMemo';
import { Threads } from './Threads';
import { UserMemosHeader } from './UserMemosHeader';

export const UserMemos = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const memosProvider = useMemosProvider();
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [currentThread, setCurrentThread] = useState<string>();
  const [sender, setSender] = useState<GetEmployeeDto>(
    GetEmployeeDtoFromJSON({}),
  );
  const [loading, setLoading] = useState(false);
  const mountedRef = useRef(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const onboarding = memosProvider.threads.length === 0;
  const newMemo = onboarding || showNew;
  const pulsingNumber =
    memosProvider.threadsStats?.newParticipatedThreadsCount || 0;

  const quickMemo = (content: string) => {
    const thread = memosProvider.threads.find(th => th.id === currentThread);
    if (thread) {
      pok.memos
        .quickMemo(content, sender, thread)
        .then(() => {
          memosProvider.refreshMemos(thread.id, 'current', () =>
            memosProvider.refreshThreads(),
          );
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  };

  const sendPrivateMemo = (dto: CreatePrivateMemoDto) => {
    pok.memos
      .createPrivateMemo(dto)
      .then(memo => {
        if (mountedRef.current) {
          setShowNew(false);
        }
        memosProvider
          .refreshThreads()
          .then(() => {
            setCurrentThread(memo.threadId);
          })
          .catch(errorResponse => {
            notifications.caughtError(errorResponse);
          });
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const changeActiveThread = useCallback(
    (threadId: string) => {
      setCurrentThread(threadId);
      setLoading(true);
      memosProvider.refreshMemos(threadId, 'initial', () => setLoading(false));
    },
    [memosProvider],
  );

  const setFirstThreadAsActive = useCallback(() => {
    if (memosProvider.threads.length) {
      const firstThreadId = memosProvider.threads[0].id;
      changeActiveThread(firstThreadId);
    }
  }, [changeActiveThread, memosProvider.threads]);

  const open = () => {
    setFirstThreadAsActive();
    setShowNew(false);
    setShow(true);
    buttonRef.current?.blur();
  };

  const close = () => {
    setShow(false);
    memosProvider.resetThreads();
  };

  useEffect(() => {
    // for deleted threads
    if (
      currentThread &&
      !memosProvider.threads.some(t => t.id === currentThread)
    ) {
      setFirstThreadAsActive();
    }
  }, [memosProvider.threads, currentThread, setFirstThreadAsActive]);

  useEffect(() => {
    mountedRef.current = true;

    pok.employees
      .getCurrent()
      .then(employee => {
        if (!employee) {
          throw new Error('Cannot find out current user employee');
        }
        if (mountedRef.current) {
          setSender(employee);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    return () => {
      mountedRef.current = false;
    };
  }, [pok.employees, notifications]);

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip>
            {memosProvider.threadsStats?.threadsCount ? (
              <>
                <p>
                  Liczba wszystkich wątków:{' '}
                  {memosProvider.threadsStats?.threadsCount}
                </p>
                {pulsingNumber > 0 && (
                  <p>Liczba wątków z nowymi wiadomościami: {pulsingNumber}</p>
                )}
              </>
            ) : (
              <p>Brak notatek</p>
            )}
          </Tooltip>
        }
      >
        <Button
          ref={buttonRef}
          className="py-1"
          variant="outline-light"
          onClick={open}
        >
          {pulsingNumber > 0 ? (
            <div className="spinner-grow text-success align-items-center">
              <span className="badge text-white memos-pulsing-circle">
                {pulsingNumber}
              </span>
            </div>
          ) : (
            <Icons.ChatLeft />
          )}
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        centered
        backdrop="static"
        size={newMemo ? 'lg' : 'xl'}
        onHide={close}
        // dialogClassName={newMemo ? '' : 'max-height-modal'}
      >
        <UserMemosHeader
          onboarding={onboarding}
          showNew={showNew}
          onNewMemo={() => setShowNew(true)}
          onCloseMemo={() => setShowNew(false)}
        />
        <Modal.Body className="d-flex">
          {newMemo ? (
            <NewPrivateMemo onSend={sendPrivateMemo} />
          ) : currentThread ? (
            <Threads
              activeThread={currentThread}
              loading={loading}
              loggedIn={sender}
              onChangeActiveThread={changeActiveThread}
              onQuickMemo={quickMemo}
              onClose={close}
            />
          ) : (
            <MemosLoadingSpinner />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
