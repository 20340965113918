/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEstimateItemDto } from './GetEstimateItemDto';
import {
    GetEstimateItemDtoFromJSON,
    GetEstimateItemDtoFromJSONTyped,
    GetEstimateItemDtoToJSON,
} from './GetEstimateItemDto';

/**
 * 
 * @export
 * @interface GetPurchaseInvoiceEstimateItemDto
 */
export interface GetPurchaseInvoiceEstimateItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceEstimateItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceEstimateItemDto
     */
    purchaseInvoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceEstimateItemDto
     */
    estimateItemId: string;
    /**
     * 
     * @type {GetEstimateItemDto}
     * @memberof GetPurchaseInvoiceEstimateItemDto
     */
    estimateItem: GetEstimateItemDto;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceEstimateItemDto
     */
    amount: string;
}

/**
 * Check if a given object implements the GetPurchaseInvoiceEstimateItemDto interface.
 */
export function instanceOfGetPurchaseInvoiceEstimateItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "purchaseInvoiceId" in value;
    isInstance = isInstance && "estimateItemId" in value;
    isInstance = isInstance && "estimateItem" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function GetPurchaseInvoiceEstimateItemDtoFromJSON(json: any): GetPurchaseInvoiceEstimateItemDto {
    return GetPurchaseInvoiceEstimateItemDtoFromJSONTyped(json, false);
}

export function GetPurchaseInvoiceEstimateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaseInvoiceEstimateItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'purchaseInvoiceId': json['purchaseInvoiceId'],
        'estimateItemId': json['estimateItemId'],
        'estimateItem': GetEstimateItemDtoFromJSON(json['estimateItem']),
        'amount': json['amount'],
    };
}

export function GetPurchaseInvoiceEstimateItemDtoToJSON(value?: GetPurchaseInvoiceEstimateItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'purchaseInvoiceId': value.purchaseInvoiceId,
        'estimateItemId': value.estimateItemId,
        'estimateItem': GetEstimateItemDtoToJSON(value.estimateItem),
        'amount': value.amount,
    };
}

