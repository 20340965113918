import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { EmployeeSearch } from '../../components/Employees/EmployeeSearch';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Waiting } from '../../../common/components/Waiting/Waiting';

export const EmployeesView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);

  const getFromOneTool = async () => {
    setWaiting(true);
    pok.employees
      .addToEmployeesFromOneTool()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Pracownicy</Card.Title>
        <Button variant="outline-primary" onClick={getFromOneTool}>
          Synchronizuj z OneTool
        </Button>
        {waiting && <Waiting />}
        {!waiting && (
          <EmployeeSearch
            getData={pok.employees.getAllByPortion}
            getCount={pok.employees.getAllCount}
            refresh={refresh}
            withHistory={true}
          />
        )}
      </Card.Body>
    </Card>
  );
};
