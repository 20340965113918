/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';
import Calendar from 'react-calendar';

import {
  CreateUpdateOrderExecutionDto,
  GetEmployeeDto,
  GetOrderExecutionDtoStatusEnum,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { useTranslation } from '../../../common/hooks/useTranslation';
import DictionarySelector from '../Selects/DictionarySelector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import useConfirmationModal from '../../../common/hooks/useConfirmationModal';

import { OrderExecutionStatusFields } from './OrderExeuctionStatusFields';

interface OrderExecutionFormProps {
  orderExecution: CreateUpdateOrderExecutionDto;
  propertyChange?: (obj: Partial<CreateUpdateOrderExecutionDto>) => void;
  emails: string[];
  setEmails?: (emails: string[]) => void;
  project?: GetProjectDto;
  resultProject?: GetProjectDto;
  readOnly?: boolean;
  status?: GetOrderExecutionDtoStatusEnum;
  rejectedNote?: string;
}

export const OrderExecutionBasicData: React.FC<OrderExecutionFormProps> = ({
  orderExecution,
  propertyChange,
  emails,
  setEmails,
  project,
  readOnly,
  status,
  rejectedNote,
}) => {
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();
  const notifications = useNotifications();
  const { confirm, RenderModal } = useConfirmationModal();
  const [teamsEmails, setTeamsEmails] = useState<string[]>([]);
  const [fetchedEmployees, setFetchedEmployees] = useState<GetEmployeeDto[]>(
    [],
  );
  const onChangeEmployees = (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      propertyChange?.({
        orderExecutionEmployeesIds: (option as Option[]).map(
          ({ value }) => value,
        ),
      });
    }
  };

  const generateEmailsList = (): string[] => {
    const employeesEmails = orderExecution.orderExecutionEmployeesIds.map(
      employee => {
        return fetchedEmployees.find(fE => fE.id === employee)?.email;
      },
    );

    const emailsList = orderExecution.addTeamToEmails
      ? [...teamsEmails, ...employeesEmails]
      : employeesEmails;

    return emailsList.filter(value => !!value) as string[];
  };

  const handleSetFetchedEmployees = (options: GetEmployeeDto[]) =>
    setFetchedEmployees(options);

  const handleChangeDate = async (date: Date) => {
    try {
      if (orderExecution.orderExecutionPositions.length) {
        await confirm(
          'Zmiana miesiąca spowoduje dostosowanie pozycji w realizacji zamówienia. Czy na pewno kontynuować?',
        );
      }

      date.setMinutes(-date.getTimezoneOffset());
      propertyChange?.({
        orderExecutionPositions: [],
        date,
      });
    } catch {
      /* empty */
    }
  };

  useEffect(() => {
    setEmails?.(generateEmailsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedEmployees,
    orderExecution.orderExecutionEmployeesIds,
    orderExecution.addTeamToEmails,
    teamsEmails,
  ]);

  useEffect(() => {
    if (project?.parent) {
      pok.projects
        .getById(project.parent.id)
        .then(data => {
          setTeamsEmails(
            data.projectTeams?.map(({ team }) => team.email || '') || [],
          );
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      if (project?.id) {
        pok.projects
          .getById(project?.id)
          .then(data => {
            setTeamsEmails(
              data.projectTeams?.map(({ team }) => team.email || '') || [],
            );
          })
          .catch(errorResponse => {
            notifications.caughtError(errorResponse);
          });
      }
    }
  }, [project?.id, notifications, pok.projects, project?.parent]);

  return (
    <>
      <Form className="d-grid gap-4 mb-3">
        {project && (
          <FormRow controlId="project" label={t(tk.menu.project)}>
            <Form.Control
              value={`${project.name} (${project?.number})`}
              disabled
            />
          </FormRow>
        )}
        {project?.parent && (
          <FormRow controlId="resultProject" label={t(tk.menu.internalProject)}>
            <Form.Control
              value={`${project.parent.name} (${project.parent?.number})`}
              disabled
            />
          </FormRow>
        )}
        <FormRow controlId="employees" label="Odbiorcy">
          <EmployeeSelector
            value={orderExecution.orderExecutionEmployeesIds}
            isMulti
            fetchForOrders
            setOptions={handleSetFetchedEmployees}
            onChange={onChangeEmployees}
            readOnly={readOnly}
          />
        </FormRow>
        <FormRow controlId="sendEmail" label="Wyślij e-mail na adres zespołu">
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={orderExecution.addTeamToEmails}
            onChange={e => {
              propertyChange?.({
                addTeamToEmails: e.target.checked,
              });
            }}
            disabled={readOnly}
          />
        </FormRow>
        <FormRow controlId="emails" label="Adresy e-mail">
          <DynamicTextarea value={emails.join(', ')} disabled />
        </FormRow>
        <FormRow controlId="formalsConditions" label="Warunki formalne">
          <DictionarySelector
            dictionary="Warunki formalne"
            value={orderExecution.formalConditionsId || ''}
            clearable={true}
            onChange={option =>
              propertyChange?.({
                formalConditionsId: option.value,
              })
            }
            readOnly={readOnly}
            showInfoIcon={readOnly}
            infoIconTitle="Warunki formalne"
          />
        </FormRow>
        <FormRow controlId="estimateByMonth" label="Wybierz miesiąc">
          <Calendar
            value={orderExecution.date}
            onChange={date => {
              if (!readOnly) {
                return handleChangeDate(date as Date);
              }
            }}
            defaultView="year"
            minDetail="year"
            maxDetail="year"
          />
        </FormRow>
        <OrderExecutionStatusFields
          status={status}
          rejectedNote={rejectedNote}
          active={orderExecution.active}
        />
      </Form>
      <RenderModal />
    </>
  );
};
