import React from 'react';
import * as rstm from 'react-simple-tree-menu';
import * as Icon from 'react-bootstrap-icons';
import { Badge } from 'react-bootstrap';

import { TreeRowActionButton } from './TreeRowActionButton';

type TreeRowContentProps = {
  item: rstm.TreeMenuItem;
  variantTree: string;
  onUpdate?: (id?: string) => void;
};

const ExpandIcon: React.FC<{ expanded: boolean; expandable: boolean }> = ({
  expanded,
  expandable,
}) =>
  expanded ? (
    <Icon.ChevronDown className="icon me-2" />
  ) : expandable ? (
    <Icon.ChevronRight className="icon me-2" />
  ) : (
    <Icon.Dot className="icon me-2" />
  );

export const TreeRowContent: React.FC<TreeRowContentProps> = ({
  item,
  variantTree,
  onUpdate,
}) => {
  const { label, shortname, systemNumber, toggleNode, level, isOpen, active } =
    item;

  const handleUpdate = (id?: string) => {
    onUpdate && onUpdate(id);
  };

  return (
    <div
      style={{
        marginLeft: `${level * 40}px`,
      }}
      className="d-flex justify-content-between"
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            active && toggleNode && toggleNode();
          }
        }}
        className="pt-3 pb-3 flex-fill"
        onClick={() => {
          active && toggleNode && toggleNode();
        }}
      >
        <ExpandIcon expanded={isOpen} expandable={toggleNode !== undefined} />
        <Badge bg="warning" text="dark">
          {shortname}
        </Badge>
        {label}
        {systemNumber ? ` (${systemNumber})` : ''}
      </div>
      <div className="pt-2 pb-2">
        {active && onUpdate && item.editable && (
          <TreeRowActionButton
            item={item}
            variantTree={variantTree}
            variantOperation="edit"
            onActionConfirmed={handleUpdate}
          />
        )}
        {active && !item.notified && onUpdate && (
          <TreeRowActionButton
            item={item}
            variantTree={variantTree}
            variantOperation="add"
            onActionConfirmed={handleUpdate}
          />
        )}
        {active && onUpdate && item.editable && (
          <TreeRowActionButton
            item={item}
            variantTree={variantTree}
            variantOperation="delete"
            onActionConfirmed={handleUpdate}
          />
        )}
      </div>
    </div>
  );
};
