import React, { forwardRef } from 'react';
import TextareaAutosize, * as reactTextareaAutosize from 'react-textarea-autosize';

const DynamicTextarea = forwardRef<
  HTMLTextAreaElement,
  reactTextareaAutosize.TextareaAutosizeProps
>((props, ref) => {
  return (
    <TextareaAutosize
      {...props}
      ref={ref}
      className={
        'form-control' + (props.className ? ' ' + props.className : '')
      }
    />
  );
});

export default DynamicTextarea;
