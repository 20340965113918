import * as yup from 'yup';

// import { ValidationStatus } from '../autogenerated/pokApiClient';

export interface ValidationResult {
  valid: boolean;
  errors: string[];
}

export const validateAgainst = async (
  schema: yup.AnySchema,
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  createUpdateDto: any,
) => {
  const result: ValidationResult = {
    valid: true,
    errors: [],
  };
  await schema
    .validate(createUpdateDto, { abortEarly: false })
    .catch(errors => {
      const verrors: yup.ValidationError[] = errors.inner || [errors];
      const messages = verrors.map(e => e.message);
      result.valid = false;
      result.errors.push(...messages);
    });
  return result;
};

// export const toResult = (status: ValidationStatus) => {
//   return {
//     valid: status.state === 'ok',
//     errors: status.message ? [status.message] : [],
//   };
// };

export const ValidationResultOK = {
  valid: true,
  errors: [] as string[],
};
