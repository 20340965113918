import React from 'react';
import { Col, Dropdown, DropdownButton, Form } from 'react-bootstrap';

import { FormRow48 } from '../../../app/components/FormRow';
import { SchemaAttributesDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface SchemaItemInputProps {
  id: string;
  label: string;
  value?: SchemaAttributesDto;
  onChange: (obj: Partial<SchemaAttributesDto> | undefined) => void;
}

export const SchemaItemInput: React.FC<SchemaItemInputProps> = props => {
  const activeColumnLabel = !props.value
    ? 'Brak'
    : props.value && !props.value.required
      ? 'Widoczne'
      : 'Obowiązkowe';

  return (
    <FormRow48 controlId={props.id} label={props.label}>
      <Col>
        <Form.Group className="d-flex">
          <DropdownButton
            variant="outline-secondary"
            title={activeColumnLabel}
            id="input-group-dropdown-1"
            className="me-2"
          >
            <Dropdown.Item onClick={() => props.onChange(undefined)}>
              Brak
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() =>
                props.onChange({
                  displayName: props.value
                    ? props.value.displayName
                    : props.label,
                })
              }
            >
              Widoczne
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                props.onChange({
                  required: true,
                  displayName: props.value
                    ? props.value.displayName
                    : props.label,
                })
              }
            >
              Obowiązkowe
            </Dropdown.Item>
          </DropdownButton>
          {props.value && (
            <Form.Control
              type="text"
              value={props.value?.displayName || ''}
              onChange={e =>
                props.onChange({
                  displayName: e.target.value || undefined,
                  required: props.value?.required,
                })
              }
              disabled={!props.value}
            />
          )}
        </Form.Group>
      </Col>
    </FormRow48>
  );
};
