import React, { useEffect, useState } from 'react';
import { Form, Spinner, FloatingLabel } from 'react-bootstrap';

interface GridFilterProps {
  filter: (text: string) => void;
  indicator: boolean;
  defaultText: string;
}

export const GridFilter: React.FC<GridFilterProps> = props => {
  const [handle, setHandle] = useState<NodeJS.Timeout>();
  const [text, setText] = useState<string>(props.defaultText);

  useEffect(() => {
    setText(props.defaultText);
  }, [props.defaultText]);

  const onText = (filterText: string) => {
    if (handle) {
      clearTimeout(handle);
    }

    const timeoutId = setTimeout(() => {
      props.filter(filterText);
    }, 500);

    setHandle(timeoutId);
    setText(filterText);
  };

  return (
    <Form.Group className="grid-filter-size">
      <FloatingLabel
        label="Szybkie wyszukiwanie"
        className="mb-3 position-relative z-index-0"
      >
        <Form.Control
          type="text"
          value={text}
          onChange={e => onText(e.target.value)}
        />
        {props.indicator && (
          <div className="position-absolute top-50 end-0 translate-middle">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Trwa wyszukiwanie...</span>
            </Spinner>
          </div>
        )}
      </FloatingLabel>
    </Form.Group>
  );
};
