import { DefaultApi, GetAttachmentDto } from '../autogenerated/pokApiClient';

export interface ImportContextInterface {
  addClientsFromExcel: () => Promise<void>;
  addBudgetsFromExcel: () => Promise<void>;
  addTeamsFromExcel: () => Promise<void>;
  addPurchasersFromExcel: () => Promise<void>;
  addPositionsFromExcel: () => Promise<void>;
  addBrandsFromExcel: () => Promise<void>;
  addEmployeeRolesFromExcel: () => Promise<void>;
  addBankAccountsFromExcel: () => Promise<void>;
  addProjectsFromExcel: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createStandardJobConfig: (cron: string, job: any) => Promise<void>;

  addAttachmentsFromExcel: (
    projectNumber: string,
    attachmentType: string,
    attachmentDescription: string,
    fileName: string,
    file: Buffer,
  ) => Promise<GetAttachmentDto>;
}

export const ImportContext = (api: DefaultApi) => {
  return {
    addClientsFromExcel: () => api.importControllerAddClientsFromExcel(),
    addBudgetsFromExcel: () => api.importControllerAddBudgetsFromExcel(),
    addTeamsFromExcel: () => api.importControllerAddTeamsFromExcel(),
    addPurchasersFromExcel: () => api.importControllerAddPurchasersFromExcel(),
    addPositionsFromExcel: () => api.importControllerAddPositionsFromExcel(),
    addBrandsFromExcel: () => api.importControllerAddBrandsFromExcel(),
    addEmployeeRolesFromExcel: () =>
      api.importControllerAddEmployeeRolesFromExcel(),
    addBankAccountsFromExcel: () =>
      api.importControllerAddBankAccountsFromExcel(),
    addProjectsFromExcel: () => api.importControllerAddProjectsFromExcel(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createStandardJobConfig: (cron: string, job: any) =>
      api.importControllerCreateStandardJobConfig(cron, job),

    addAttachmentsFromExcel: (
      projectNumber: string,
      attachmentType: string,
      attachmentDescription: string,
      fileName: string,
      file: Buffer,
    ) =>
      api.importControllerAddAttachmentsFromExcel({
        projectNumber,
        attachmentType,
        attachmentDescription,
        fileName,
        file,
      }),
  };
};
