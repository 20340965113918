import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

import { CreateUpdatePurchaseInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow, {
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import CurrencyControl from '../../../app/components/CurrencyControl';
import { CurrencyIsoTypeEnum } from '../../../common/pokCore/validation/schemas';

interface PurchaseInvoiceEditorFormGeneralFormCurrencyProps {
  purchaseInvoice?: CreateUpdatePurchaseInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => void;
  readOnly?: boolean;
}

const currencyOptions = Object.entries(CurrencyIsoTypeEnum).map(
  ([key, value]) => ({
    value: key as CurrencyIsoTypeEnum,
    label: value,
  }),
);

export const PurchaseInvoiceGeneralFormCurrency: React.FC<
  PurchaseInvoiceEditorFormGeneralFormCurrencyProps
> = props => {
  const currencySelect = (
    <Select
      value={currencyOptions.find(
        ({ value }) => props.purchaseInvoice?.currency === value,
      )}
      options={currencyOptions}
      onChange={option =>
        props.propertyChange({
          currency: option?.value,
          exchangeRate: undefined,
        })
      }
      isDisabled={props.readOnly}
    />
  );

  if (props.purchaseInvoice?.currency === CurrencyIsoTypeEnum.PLN) {
    return (
      <FormRow controlId="currency" label="Waluta">
        {currencySelect}
      </FormRow>
    );
  }

  return (
    <Row>
      <Col md={6}>
        <FormRow66 controlId="currency" label="Waluta">
          {currencySelect}
        </FormRow66>
      </Col>
      <Col md={6}>
        <FormRow66R controlId="exchange" label="Kurs waluty">
          <Row>
            <Col md={6}>
              <CurrencyControl
                value={props.purchaseInvoice?.exchangeRate}
                suffix=" zł"
                onChange={value =>
                  props.propertyChange({
                    exchangeRate: value || undefined,
                  })
                }
                disabled={props.readOnly}
              />
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <a
                href="https://kalkulator-walut.groupone.pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kalkulator walut
              </a>
            </Col>
          </Row>
        </FormRow66R>
      </Col>
    </Row>
  );
};
