/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetProjectTeamDto
 */
export interface GetProjectTeamDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectTeamDto
     */
    id: string;
    /**
     * 
     * @type {GetTeamDto}
     * @memberof GetProjectTeamDto
     */
    team: GetTeamDto;
    /**
     * 
     * @type {string}
     * @memberof GetProjectTeamDto
     */
    teamId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectTeamDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectTeamDto
     */
    main: boolean;
}

/**
 * Check if a given object implements the GetProjectTeamDto interface.
 */
export function instanceOfGetProjectTeamDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "main" in value;

    return isInstance;
}

export function GetProjectTeamDtoFromJSON(json: any): GetProjectTeamDto {
    return GetProjectTeamDtoFromJSONTyped(json, false);
}

export function GetProjectTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProjectTeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'team': GetTeamDtoFromJSON(json['team']),
        'teamId': json['teamId'],
        'active': json['active'],
        'main': json['main'],
    };
}

export function GetProjectTeamDtoToJSON(value?: GetProjectTeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'team': GetTeamDtoToJSON(value.team),
        'teamId': value.teamId,
        'active': value.active,
        'main': value.main,
    };
}

