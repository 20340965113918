import React, { useMemo } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { PackagesInvoicesXMLSearch } from '../../components/PackagesInvoicesXML/PackagesInvoicesXMLSearch';
import { TabsHistory } from '../../../app/components/TabsHistory';
import {
  InvoiceTypeEnum,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import { useNavigation } from '../../../common/navigation';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

import { ExportFVZToXMLByType } from './ExportFVZToXMLByTypeView';

export const ExportFVZToXMLView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();

  const onRowClick = (id: string) => {
    nav.packagesFVZ(id);
  };

  const getDataPackages = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.packagesXML.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          PackageTypeEnum.Purchase,
        )) as GridGetterFunction,
    [pok.packagesXML],
  );

  const getCountPackages = useMemo(
    () =>
      ((filterText: string) =>
        pok.packagesXML.getAllCount(
          filterText,
          PackageTypeEnum.Purchase,
        )) as GridCountFunction,
    [pok.packagesXML],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Eksport faktur zakupu</Card.Title>
        <TabsHistory
          activeKey="invoicesDomesticToSent"
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey="invoicesDomesticToSent"
            title="FVZ krajowe do wysłania"
            className="m-3"
          >
            <ExportFVZToXMLByType
              type={InvoiceTypeEnum.Domestic}
              isRepeat={false}
              title="Utwórz XML z krajowymi FVZ"
            />
          </Tab>
          <Tab
            eventKey="invoicesForeignToSent"
            title="FVZ zagraniczne do wysłania"
            className="m-3"
          >
            <ExportFVZToXMLByType
              type={InvoiceTypeEnum.Foreign}
              isRepeat={false}
              title="Utwórz XML z zagranicznymi FVZ"
            />
          </Tab>
          <Tab
            eventKey="invoicesDomesticToSentAgain"
            title="FVZ krajowe ponownie do wysłania"
            className="m-3"
          >
            <ExportFVZToXMLByType
              type={InvoiceTypeEnum.Domestic}
              isRepeat={true}
              title="Utwórz XML z krajowymi FVZ, które już były wysyłane"
            />
          </Tab>
          <Tab
            eventKey="invoicesForeignToSentAgain"
            title="FVZ zagraniczne ponownie do wysłania"
            className="m-3"
          >
            <ExportFVZToXMLByType
              type={InvoiceTypeEnum.Foreign}
              isRepeat={true}
              title="Utwórz XML z zagranicznymi FVZ, które już były wysyłane"
            />
          </Tab>
          <Tab eventKey="packagesFVZ" title="Paczki FVZ" className="m-3">
            <PackagesInvoicesXMLSearch
              getData={getDataPackages}
              getCount={getCountPackages}
              onRowClick={onRowClick}
              packageType={PackageTypeEnum.Purchase}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
