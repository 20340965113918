/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParametersSchemaDto
 */
export interface ParametersSchemaDto {
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    ownName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    actionGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    additionalDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    numberOfMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    copySize?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    purchaseMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    purchaseFormInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    categoryInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    formatInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    purchaseFormInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    categoryInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    formatInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    labconChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    categoryLabcon?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    technologyDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    regionDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    formatPress?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    formatRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    emissionTypeRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    emissionTypeCinema?: string;
    /**
     * 
     * @type {string}
     * @memberof ParametersSchemaDto
     */
    medium?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParametersSchemaDto
     */
    isUpSell?: boolean;
}

/**
 * Check if a given object implements the ParametersSchemaDto interface.
 */
export function instanceOfParametersSchemaDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParametersSchemaDtoFromJSON(json: any): ParametersSchemaDto {
    return ParametersSchemaDtoFromJSONTyped(json, false);
}

export function ParametersSchemaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParametersSchemaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'ownName': !exists(json, 'ownName') ? undefined : json['ownName'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'actionGroup': !exists(json, 'actionGroup') ? undefined : json['actionGroup'],
        'additionalDescription': !exists(json, 'additionalDescription') ? undefined : json['additionalDescription'],
        'numberOfMedia': !exists(json, 'numberOfMedia') ? undefined : json['numberOfMedia'],
        'copySize': !exists(json, 'copySize') ? undefined : json['copySize'],
        'purchaseMethod': !exists(json, 'purchaseMethod') ? undefined : json['purchaseMethod'],
        'purchaseFormInternet': !exists(json, 'purchaseFormInternet') ? undefined : json['purchaseFormInternet'],
        'categoryInternet': !exists(json, 'categoryInternet') ? undefined : json['categoryInternet'],
        'formatInternet': !exists(json, 'formatInternet') ? undefined : json['formatInternet'],
        'purchaseFormInternetST': !exists(json, 'purchaseFormInternetST') ? undefined : json['purchaseFormInternetST'],
        'categoryInternetST': !exists(json, 'categoryInternetST') ? undefined : json['categoryInternetST'],
        'formatInternetST': !exists(json, 'formatInternetST') ? undefined : json['formatInternetST'],
        'labconChannel': !exists(json, 'labconChannel') ? undefined : json['labconChannel'],
        'categoryLabcon': !exists(json, 'categoryLabcon') ? undefined : json['categoryLabcon'],
        'technologyDictionary': !exists(json, 'technologyDictionary') ? undefined : json['technologyDictionary'],
        'regionDictionary': !exists(json, 'regionDictionary') ? undefined : json['regionDictionary'],
        'formatPress': !exists(json, 'formatPress') ? undefined : json['formatPress'],
        'formatRadio': !exists(json, 'formatRadio') ? undefined : json['formatRadio'],
        'emissionTypeRadio': !exists(json, 'emissionTypeRadio') ? undefined : json['emissionTypeRadio'],
        'emissionTypeCinema': !exists(json, 'emissionTypeCinema') ? undefined : json['emissionTypeCinema'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'isUpSell': !exists(json, 'isUpSell') ? undefined : json['isUpSell'],
    };
}

export function ParametersSchemaDtoToJSON(value?: ParametersSchemaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderNumber': value.orderNumber,
        'ownName': value.ownName,
        'action': value.action,
        'service': value.service,
        'actionGroup': value.actionGroup,
        'additionalDescription': value.additionalDescription,
        'numberOfMedia': value.numberOfMedia,
        'copySize': value.copySize,
        'purchaseMethod': value.purchaseMethod,
        'purchaseFormInternet': value.purchaseFormInternet,
        'categoryInternet': value.categoryInternet,
        'formatInternet': value.formatInternet,
        'purchaseFormInternetST': value.purchaseFormInternetST,
        'categoryInternetST': value.categoryInternetST,
        'formatInternetST': value.formatInternetST,
        'labconChannel': value.labconChannel,
        'categoryLabcon': value.categoryLabcon,
        'technologyDictionary': value.technologyDictionary,
        'regionDictionary': value.regionDictionary,
        'formatPress': value.formatPress,
        'formatRadio': value.formatRadio,
        'emissionTypeRadio': value.emissionTypeRadio,
        'emissionTypeCinema': value.emissionTypeCinema,
        'medium': value.medium,
        'isUpSell': value.isUpSell,
    };
}

