import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';

import { ForeignClientFormFields } from './ForeignClientFormFields';

interface ForeignClientProps {
  client: CreateUpdateClientDto | CreateUpdateApplicationDto;
  propertyChange?: (obj: Partial<CreateUpdateClientDto>) => void;
  readonly?: boolean;
}

export const ForeignClient: React.FC<ForeignClientProps> = props => {
  return (
    <Form className="d-grid gap-4">
      <ForeignClientFormFields
        client={props.client}
        propertyChange={props.propertyChange}
        readonly={props.readonly}
      />
    </Form>
  );
};
