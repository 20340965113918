import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';

interface PermissionSearchProps {
  permissions: string[];
  onRowClick: (key: string) => Promise<void> | void | undefined;
  selectedKeys?: string[];
  withHistory?: boolean;
}

export const PermissionSearch: React.FC<PermissionSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa', property: 'permission.name' },
  ];

  const data = props.permissions.map(permission => {
    return {
      key: permission,
      values: {
        'permission.name': permission,
      },
      orderByStringValues: {
        name: permission,
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={false}
      initialOrderColumn="permission.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak uprawnień w systemie"
      enableMultiSelect={true}
      selectedKeys={props.selectedKeys}
      onRowClick={props.onRowClick}
      withHistory={props.withHistory}
    />
  );
};
