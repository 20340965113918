import React from 'react';

import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';
import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import { buildParametersString } from '../Orders/OrderEstimateItemsGrid';

interface EstimateItemsCloneGridProps {
  data: GetEstimateItemDto[];
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const EstimateItemsCloneGrid: React.FC<
  EstimateItemsCloneGridProps
> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa', property: 'position.name' },
    { header: 'Miesiąc', property: 'estimateByMonth.date' },
    { header: 'Profil', property: 'profile.name' },
    { header: 'Kontrahent', property: 'client.name' },
    { header: 'Parametry', property: 'parameters', noSorting: true },
    { header: 'Netto sprzedaż total', property: 'estimateItem.salesNetTotal' },
    { header: 'Netto zakup total', property: 'estimateItem.purchaseNetTotal' },
  ];

  const data = props.data.map(estimateItem => {
    return {
      key: estimateItem.id,
      values: {
        'position.name': estimateItem.position.name,
        'estimateByMonth.date': momencik(
          estimateItem.estimateByMonth.date,
          'YYYY-MM',
        ),
        'client.name':
          estimateItem.position.client.shortname ||
          estimateItem.position.client.name,
        'profile.name': estimateItem.estimateByMonth.profile.name,
        'estimateItem.salesNetTotal': numberFormatter(
          estimateItem.salesNetTotal || '0',
          true,
        ),
        'estimateItem.purchaseNetTotal': numberFormatter(
          estimateItem.purchaseNetTotal || '0',
          true,
        ),
        parameters: buildParametersString(estimateItem),
      },
      orderByStringValues: {
        name: estimateItem.position.name,
      },
    };
  });

  return (
    <Grid
      key={props.data.length}
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="position.name"
      pageSize={10}
      emptyGridMessage="Brak pozycji do wyświetlenia."
      enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
      onRowClick={props.onRowClick}
    />
  );
};
