import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetProfileCategoryDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface ProfileSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const profileCategoryForGrid = (
  profileCategory: GetProfileCategoryDto,
) => {
  return {
    key: profileCategory.id,
    values: {
      'profileCategory.name': profileCategory.name,
      'profileCategory.salesFinancialAccount':
        profileCategory.salesFinancialAccount,
      'profileCategory.purchasesFinancialAccount':
        profileCategory.purchasesFinancialAccount,
      'profileCategory.commissionFinancialAccount':
        profileCategory.commissionFinancialAccount,
      'profile.name': profileCategory.profile.name,
    },
    isInactive: !profileCategory.active,
  };
};

export const ProfileCategorySearch: React.FC<ProfileSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa', property: 'profileCategory.name' },
    {
      header: 'Konto księgowe sprzedaży',
      property: 'profileCategory.salesFinancialAccount',
    },
    {
      header: 'Konto księgowe zakupu',
      property: 'profileCategory.purchasesFinancialAccount',
    },
    {
      header: 'Konto księgowe prowizji',
      property: 'profileCategory.commissionFinancialAccount',
    },
    { header: 'Profil', property: 'profile.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="profileCategory.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak kategorii profili w systemie."
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
    />
  );
};
