import { budgetForGrid } from '../../../pok/components/Budgets/BudgetSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateBudgetDto,
  CreateUpdateBudgetDtoFromJSON,
  DefaultApi,
  GetBudgetDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { BudgetSchema } from '../validation/schemas';
import { defaultSelectorLimit } from '../../../pok/components/Selects/Selector';
import { validateAgainst } from '../validation/validateAgainst';
import * as AttachmentContext from '../../../common/pokCore/contexts/AttachmentContext';
import { PokCoreContextType } from '../pokCore';
import { NotificationsProviderType } from '../../notifications';
import { FilterColumn } from '../../types/FilterColumn';

export interface BudgetContextInterface {
  getById: (id: string) => Promise<GetBudgetDto>;
  getManyByName: (pattern: string) => Promise<GetBudgetDto[]>;
  getForDocumentControlByName: (pattern: string) => Promise<GetBudgetDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateBudgetDto) => Promise<void>;
  create: (dto: CreateUpdateBudgetDto) => Promise<GetBudgetDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const BudgetContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.budgetControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.budgetControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(budgetForGrid);
    },
    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.budgetControllerGetAllCount(filterText || '', filterColumns),
    update: (id: string, dto: CreateUpdateBudgetDto) =>
      api.budgetControllerUpdate(id, dto),
    create: (dto: CreateUpdateBudgetDto) => api.budgetControllerCreate(dto),
    getLastEditor: (id: string) => api.budgetControllerGetLastEditor(id),
    getManyByName: (pattern: string) => {
      return api.budgetControllerGetByPattern(pattern, defaultSelectorLimit);
    },
    getForDocumentControlByName: (pattern: string) => {
      return api.budgetControllerGetForDocumentControlByPattern(
        pattern,
        defaultSelectorLimit,
      );
    },
  };
};

export const newBudget = (managerId?: string) => {
  return CreateUpdateBudgetDtoFromJSON({
    managerId: managerId,
  });
};

export const validate = (budget: CreateUpdateBudgetDto) =>
  validateAgainst(BudgetSchema, budget);

export const convert = (budget?: GetBudgetDto) => {
  if (!budget) {
    return newBudget();
  }
  const converted = CreateUpdateBudgetDtoFromJSON(budget);
  converted.managerId = budget.manager.id;
  return converted;
};

export const forBudgetView = async (
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const attachments = await pok.attachments.getAllForObject(
    AttachmentContext.ObjectType.Budget,
    id,
  );

  const ac = AttachmentContext.forAssociatedDocuments;

  const assocAttachments = ac(pok, notifications, attachments, action);

  assocAttachments.new = () => {
    const attachment = AttachmentContext.newAttachment();
    attachment.enObjectType = AttachmentContext.ObjectType.Budget;
    attachment.objectId = id;
    return attachment;
  };
  return {
    attachments: assocAttachments,
  };
};
