import {
  CreateExportToSymphonyDataDto,
  DefaultApi,
} from '../autogenerated/pokApiClient';

export interface SymphonyContextInterface {
  exportFSV: (body: CreateExportToSymphonyDataDto) => Promise<void>;
}

export const SymphonyContext = (api: DefaultApi) => {
  return {
    exportFSV: (body: CreateExportToSymphonyDataDto) =>
      api.tbInvoiceControllerExportFSV(body),
  };
};
