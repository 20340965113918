import {
  CreateUpdateApplicationUnblockDto,
  CreateUpdateApplicationUnblockDtoFromJSON,
  DecisionApplicationUnblockDto,
  DecisionApplicationUnblockDtoFromJSON,
  DefaultApi,
  GetApplicationUnblockDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import {
  ApplicationUnblockDecisionSchema,
  ApplicationUnblockSchema,
  ApplicationUnblockStatusEnum,
} from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { applicationUnblockForGrid } from '../../../pok/components/ApplicationsUnblock/ApplicationUnblockSearch';

export interface ApplicationUnblockContextInterface {
  getById: (id: string) => Promise<GetApplicationUnblockDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    estimateByMonthId?: string,
    status?: ApplicationUnblockStatusEnum,
  ) => Promise<GridRecord[]>;
  getAllPendingByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    estimateByMonthId?: string,
    status?: ApplicationUnblockStatusEnum,
  ) => Promise<number>;
  getAllPendingCount: (filterText?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateApplicationUnblockDto) => Promise<void>;
  create: (
    dto: CreateUpdateApplicationUnblockDto,
  ) => Promise<GetApplicationUnblockDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  deactivate: (id: string) => Promise<void>;
  updateDecision: (
    id: string,
    body: DecisionApplicationUnblockDto,
  ) => Promise<void>;
}

export const ApplicationUnblockContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.applicationUnblockControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      estimateByMonthId?: string,
      status?: ApplicationUnblockStatusEnum,
    ) => {
      const data = await api.applicationUnblockControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        estimateByMonthId || '',
        status,
      );

      return data.map(applicationUnblockForGrid);
    },
    getAllPendingByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.applicationUnblockControllerGetAllPendingByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(applicationUnblockForGrid);
    },
    getAllCount: async (
      filterText?: string,
      estimateByMonthId?: string,
      status?: ApplicationUnblockStatusEnum,
    ) =>
      api.applicationUnblockControllerGetAllCount(
        filterText || '',
        estimateByMonthId || '',
        status,
      ),
    getAllPendingCount: async (filterText?: string) =>
      api.applicationUnblockControllerGetAllPendingCount(filterText || ''),
    update: (id: string, dto: CreateUpdateApplicationUnblockDto) =>
      api.applicationUnblockControllerUpdate(id, dto),
    create: (dto: CreateUpdateApplicationUnblockDto) =>
      api.applicationUnblockControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.applicationUnblockControllerGetLastEditor(id),
    deactivate: (id: string) => api.applicationUnblockControllerDeactivate(id),
    updateDecision: (id: string, body: DecisionApplicationUnblockDto) =>
      api.applicationUnblockControllerUpdateDecision(id, body),
  };
};

export const newApplicationUnblock = (estimateByMonthId?: string) => {
  return CreateUpdateApplicationUnblockDtoFromJSON({
    estimateByMonthId,
  });
};

export const validate = (
  applicationUnblock: CreateUpdateApplicationUnblockDto,
) => validateAgainst(ApplicationUnblockSchema, applicationUnblock);

export const validateDecision = (
  applicationUnblock: DecisionApplicationUnblockDto,
) => validateAgainst(ApplicationUnblockDecisionSchema, applicationUnblock);

export const convert = (
  applicationUnblock?: GetApplicationUnblockDto,
  estimateByMonthId?: string,
) => {
  if (!applicationUnblock) {
    return newApplicationUnblock(estimateByMonthId);
  }

  const converted =
    CreateUpdateApplicationUnblockDtoFromJSON(applicationUnblock);
  converted.estimateByMonthId = applicationUnblock.estimateByMonth.id;

  return converted;
};

export const convertDecision = (
  applicationUnblock: GetApplicationUnblockDto,
) => {
  const converted = DecisionApplicationUnblockDtoFromJSON(applicationUnblock);
  converted.estimateByMonthId = applicationUnblock.estimateByMonth.id;

  if (
    applicationUnblock.active &&
    applicationUnblock.status === ApplicationUnblockStatusEnum.New
  ) {
    converted.status = ApplicationUnblockStatusEnum.Accepted;
  }

  return converted;
};
