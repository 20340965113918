import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';

import { OrderExecutionSearch } from './OrderExecutionSearch';

export const OrdersExecutionWaitingTab: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();

  return (
    <OrderExecutionSearch
      getData={pok.ordersExecution.getAllByPortionWaiting}
      getCount={pok.ordersExecution.getAllCountWaiting}
      onRowClick={nav.orderExecutionEditor}
    />
  );
};
