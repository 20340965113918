import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  FilterYear,
  FilterYearReportData,
} from '../../components/Reports/FilterYear';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { downloadExcel } from '../../components/Excel/downloadExcel';

export const ReportFVSControllingByFilterRawDataView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [waiting, setWaiting] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');

  const auth = useAuth();
  const [authorized, setAuthorized] = useState<boolean>();

  useEffect(() => {
    setAuthorized(auth.check(PermissionsEnum.FinanceAdministration));
  }, [pok, auth]);

  const onSearch = async (filter: FilterYearReportData) => {
    setAlert('Trwa pobieranie danych. Proszę czekać...');
    setWaiting(true);
    try {
      const dataReports =
        await pok.reports.reportFVSControllingByFilterRawData(filter);

      const columnsToFormat0 = ['C', 'L', 'R', 'S'];
      const columnsToFormat2 = ['H'];

      await downloadExcel(
        dataReports,
        columnsToFormat0,
        columnsToFormat2,
        ['J'],
        'FVS',
        'RaportKontrolingFVS',
      );
      setWaiting(false);
    } catch (error) {
      notifications.caughtError(error as ResponseError);
      setWaiting(false);
    }
  };

  return (
    <>
      <FilterYear
        onSearch={onSearch}
        reportName="Zestawienie faktur sprzedaży w kontekście miesięcy"
        withoutCompany={authorized ? false : true}
        withInvoiceType={true}
        withCurrency={true}
        withClient={true}
        withDataSales={true}
        withDataInvoices={true}
        withStatus={false}
        isLoading={waiting}
      />
      {waiting && (
        <>
          <Alert variant="warning">{alert}</Alert>
          <Waiting />
        </>
      )}
    </>
  );
};
