/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateProfileCategoryDto
 */
export interface CreateUpdateProfileCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileCategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileCategoryDto
     */
    purchasesFinancialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileCategoryDto
     */
    salesFinancialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileCategoryDto
     */
    commissionFinancialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileCategoryDto
     */
    profileId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileCategoryDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the CreateUpdateProfileCategoryDto interface.
 */
export function instanceOfCreateUpdateProfileCategoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "purchasesFinancialAccount" in value;
    isInstance = isInstance && "salesFinancialAccount" in value;
    isInstance = isInstance && "commissionFinancialAccount" in value;
    isInstance = isInstance && "profileId" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function CreateUpdateProfileCategoryDtoFromJSON(json: any): CreateUpdateProfileCategoryDto {
    return CreateUpdateProfileCategoryDtoFromJSONTyped(json, false);
}

export function CreateUpdateProfileCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateProfileCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'purchasesFinancialAccount': json['purchasesFinancialAccount'],
        'salesFinancialAccount': json['salesFinancialAccount'],
        'commissionFinancialAccount': json['commissionFinancialAccount'],
        'profileId': json['profileId'],
        'active': json['active'],
    };
}

export function CreateUpdateProfileCategoryDtoToJSON(value?: CreateUpdateProfileCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'purchasesFinancialAccount': value.purchasesFinancialAccount,
        'salesFinancialAccount': value.salesFinancialAccount,
        'commissionFinancialAccount': value.commissionFinancialAccount,
        'profileId': value.profileId,
        'active': value.active,
    };
}

