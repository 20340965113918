import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Waiting: React.FC = () => {
  return (
    <div className="loading">
      <Spinner animation="grow" variant="info" className="m-1" />
      <Spinner animation="grow" variant="warning" className="m-1" />
      <Spinner animation="grow" variant="info" className="m-1" />
    </div>
  );
};
