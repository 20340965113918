import React from 'react';
import { Card } from 'react-bootstrap';
import Select from 'react-select';

import { DecisionApplicationUnblockDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  ApplicationUnblockStatusEnum,
  ApplicationUnblockStatusEnumText,
} from '../../../common/pokCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

interface ApplicationUnblockDecisionFormProps {
  id?: string;
  applicationUnblock: DecisionApplicationUnblockDto;
  readOnly?: boolean;
  propertyChange: (obj: Partial<DecisionApplicationUnblockDto>) => void;
}

const applicationStatusOptions = [
  {
    value: ApplicationUnblockStatusEnum.Accepted,
    label: ApplicationUnblockStatusEnumText.Accepted,
  },
  {
    value: ApplicationUnblockStatusEnum.Rejected,
    label: ApplicationUnblockStatusEnumText.Rejected,
  },
];

export const ApplicationUnblockDecisionForm: React.FC<
  ApplicationUnblockDecisionFormProps
> = props => {
  return (
    <>
      <Card className="card flex-grow-1 mt-3">
        <Card.Header>Realizacja wniosku</Card.Header>
        <Card.Body className="m-1">
          <div className="d-grid gap-3 mb-3">
            <FormRow controlId="applicationStatus" label="Status">
              <Select
                value={
                  props.applicationUnblock?.status
                    ? applicationStatusOptions.find(
                        ({ value }) =>
                          props.applicationUnblock?.status === value,
                      )
                    : null
                }
                options={applicationStatusOptions}
                onChange={option =>
                  props.propertyChange({
                    status: option?.value,
                    rejectedNote: '',
                  })
                }
                placeholder="Wskaż..."
                isDisabled={props.readOnly}
              />
            </FormRow>
            {props.applicationUnblock.status ===
              ApplicationUnblockStatusEnum.Rejected && (
              <>
                <FormRow
                  controlId="rejectedNote"
                  label="Uzasadnienie odrzucenia"
                >
                  <DynamicTextarea
                    value={props.applicationUnblock.rejectedNote || ''}
                    onChange={e =>
                      props.propertyChange({
                        rejectedNote: e.target.value || '',
                      })
                    }
                    disabled={props.readOnly}
                  />
                </FormRow>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
