/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEstimateItemLockDto
 */
export interface GetEstimateItemLockDto {
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemLockDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateItemLockDto
     */
    lockedSale: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateItemLockDto
     */
    lockedPurchase: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemLockDto
     */
    saleLockReason: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemLockDto
     */
    purchaseLockReason: string;
}

/**
 * Check if a given object implements the GetEstimateItemLockDto interface.
 */
export function instanceOfGetEstimateItemLockDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "lockedSale" in value;
    isInstance = isInstance && "lockedPurchase" in value;
    isInstance = isInstance && "saleLockReason" in value;
    isInstance = isInstance && "purchaseLockReason" in value;

    return isInstance;
}

export function GetEstimateItemLockDtoFromJSON(json: any): GetEstimateItemLockDto {
    return GetEstimateItemLockDtoFromJSONTyped(json, false);
}

export function GetEstimateItemLockDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEstimateItemLockDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lockedSale': json['lockedSale'],
        'lockedPurchase': json['lockedPurchase'],
        'saleLockReason': json['saleLockReason'],
        'purchaseLockReason': json['purchaseLockReason'],
    };
}

export function GetEstimateItemLockDtoToJSON(value?: GetEstimateItemLockDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lockedSale': value.lockedSale,
        'lockedPurchase': value.lockedPurchase,
        'saleLockReason': value.saleLockReason,
        'purchaseLockReason': value.purchaseLockReason,
    };
}

