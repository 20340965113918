import { profileCategoryForGrid } from '../../../pok/components/ProfileCategories/ProfileCategorySearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { DefaultApi } from '../autogenerated/pokApiClient';
import {
  CreateUpdateProfileCategoryDto,
  CreateUpdateProfileCategoryDtoFromJSON,
} from '../autogenerated/pokApiClient';
import { GetLastEditorDto } from '../autogenerated/pokApiClient';
import { GetProfileCategoryDto } from '../autogenerated/pokApiClient';
import { ProfileCategorySchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { Option } from '../../../pok/components/Selects/Selector';

export interface ProfileCategoryContextInterface {
  getById: (id: string) => Promise<GetProfileCategoryDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateProfileCategoryDto) => Promise<void>;
  create: (
    dto: CreateUpdateProfileCategoryDto,
  ) => Promise<GetProfileCategoryDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getOptionById: (id: string) => Promise<Option>;
  getByProfile: (profileId: string) => Promise<GetProfileCategoryDto[]>;
}

export const ProfileCategoryContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.profileCategoryControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.profileCategoryControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(item => profileCategoryForGrid(item));
    },
    getAllCount: (filterText?: string) =>
      api.profileCategoryControllerGetAllCount(filterText || ''),
    update: (id: string, dto: CreateUpdateProfileCategoryDto) =>
      api.profileCategoryControllerUpdate(id, dto),
    create: (dto: CreateUpdateProfileCategoryDto) =>
      api.profileCategoryControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.profileCategoryControllerGetLastEditor(id),
    getOptionById: async (id: string) => {
      const data = await api.profileCategoryControllerGet(id);
      return {
        value: data.id,
        label: data.name,
      };
    },
    getByProfile: async (profileId: string) =>
      api.profileCategoryControllerGetAllByProfile(profileId),
  };
};

export const newProfileCategory = () => {
  return CreateUpdateProfileCategoryDtoFromJSON({});
};

export const validate = (profile: CreateUpdateProfileCategoryDto) =>
  validateAgainst(ProfileCategorySchema, profile);

export const convert = (profileCategory?: GetProfileCategoryDto) => {
  if (!profileCategory) {
    return newProfileCategory();
  }
  const converted = CreateUpdateProfileCategoryDtoFromJSON(profileCategory);
  converted.profileId = profileCategory.profile.id;

  return converted;
};
