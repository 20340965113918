import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { downloadZIP } from './downloadZIP';

export const DownloadZIPLink = (props: {
  packageXMLId: string;
  copy?: boolean;
}) => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const handleClick = () => {
    pok.packagesXML
      .getZipFromPackageXML(props.packageXMLId, props.copy || false)
      .then(async zip => {
        const packageXML = await pok.packagesXML.getById(props.packageXMLId);

        await downloadZIP(packageXML, zip);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }}>
            Paczka ZIP z wydrukami faktur
          </Tooltip>
        }
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Download />
        </Button>
      </OverlayTrigger>
    </>
  );
};
