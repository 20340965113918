import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import FormRow, {
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import {
  InvoiceTypeEnum,
  InvoiceTypeEnumText,
  PaymentFormEnum,
  PaymentFormEnumText,
} from '../../../common/pokCore/validation/schemas';
import {
  CreateUpdateSalesInvoiceDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import DictionarySelector from '../Selects/DictionarySelector';

import { SalesInvoiceCurrencyForm } from './SalesInvoiceCurrencyForm';
import { SalesInvoiceClientEditor } from './SalesInvoiceClientEditor';
import { SalesInvoiceFormCodes } from './SalesInvoiceFormCodes';
import { SalesInvoiceBasicDataNumber } from './SalesInvoiceBasicDataNumber';

interface SalesInvoiceBasicDataEditorProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
  number?: string;
  purchaser?: GetPurchaserDto;
  refresh: boolean;
}

const paymentFormOptions = Object.entries(PaymentFormEnum).map(
  ([key, value]) => ({
    value: key as PaymentFormEnum,
    label: PaymentFormEnumText[value],
  }),
);

const typeOptions = Object.entries(InvoiceTypeEnum).map(([key, value]) => ({
  value: key as InvoiceTypeEnum,
  label: InvoiceTypeEnumText[value],
}));

export const SalesInvoiceBasicDataEditor: React.FC<
  SalesInvoiceBasicDataEditorProps
> = props => {
  const { salesInvoice, propertyChange, readOnly, purchaser } = props;

  return (
    <>
      <SalesInvoiceBasicDataNumber {...props} />
      <SalesInvoiceClientEditor purchaser={purchaser} />
      <SalesInvoiceCurrencyForm
        salesInvoice={salesInvoice}
        propertyChange={propertyChange}
        readOnly={readOnly}
      />
      <Row>
        <Col md={6}>
          <FormRow66 controlId="type" label="Typ faktury">
            <Select
              value={
                salesInvoice?.type
                  ? typeOptions.find(
                      ({ value }) => salesInvoice?.type === value,
                    )
                  : null
              }
              options={typeOptions}
              onChange={option =>
                propertyChange({
                  type: option?.value,
                })
              }
              isDisabled={readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="paymentForm" label="Forma płatności">
            <Select
              className="full-width"
              value={
                salesInvoice?.paymentForm
                  ? paymentFormOptions.find(
                      ({ value }) => salesInvoice?.paymentForm === value,
                    )
                  : null
              }
              options={paymentFormOptions}
              onChange={option =>
                propertyChange({
                  paymentForm: option?.value,
                  isPaymentFromReceiptDate:
                    option?.value === PaymentFormEnum.BankTransfer
                      ? salesInvoice.isPaymentFromReceiptDate
                      : false,
                  payableDaysId:
                    option?.value === PaymentFormEnum.BankTransfer
                      ? salesInvoice.payableDaysId
                      : null,
                })
              }
              isDisabled={readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      <SalesInvoiceFormCodes {...props} />
      <Row>
        <Col md={6}>
          <FormRow66 controlId="invoiceDate" label="Data wystawienia">
            <Form.Control
              type="date"
              name="invoiceDate"
              placeholder="DateRange"
              value={momencik(
                salesInvoice.invoiceDate || new Date(),
                'YYYY-MM-DD',
              )}
              onChange={e =>
                propertyChange({
                  invoiceDate: new Date(e.target.value),
                })
              }
              disabled={readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="saleDate" label="Data sprzedaży">
            <Form.Control
              type="date"
              name="saleDate"
              placeholder="DateRange"
              value={momencik(salesInvoice?.saleDate, 'YYYY-MM-DD')}
              onChange={e =>
                propertyChange({
                  saleDate: new Date(e.target.value),
                })
              }
              disabled={readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="payableDays" label="Termin płatności">
            <DictionarySelector
              dictionary="Termin płatności faktury"
              value={props.salesInvoice?.payableDaysId || ''}
              onChange={option =>
                propertyChange({
                  payableDaysId: option.value,
                })
              }
              clearable
              sortFunction={(a, b) => +a.value - +b.value}
              readOnly={
                readOnly ||
                salesInvoice.paymentForm !== PaymentFormEnum.BankTransfer
              }
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R
            controlId="isPaymentFromReceiptDate"
            label="Data płatności od dnia otrzymania"
          >
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={salesInvoice.isPaymentFromReceiptDate}
              onChange={e =>
                propertyChange({ isPaymentFromReceiptDate: e.target.checked })
              }
              disabled={
                readOnly ||
                salesInvoice.paymentForm !== PaymentFormEnum.BankTransfer
              }
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="printProjectNumber" label="Drukuj numer kampanii">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={salesInvoice.printProjectNumber}
          onChange={e =>
            propertyChange({ printProjectNumber: e.target.checked })
          }
          disabled={readOnly}
        />
      </FormRow>
    </>
  );
};
