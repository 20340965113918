/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSalesInvoiceDto
 */
export interface CreateUpdateSalesInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    currency: CreateUpdateSalesInvoiceDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    status: CreateUpdateSalesInvoiceDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    paymentForm: CreateUpdateSalesInvoiceDtoPaymentFormEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    type: CreateUpdateSalesInvoiceDtoTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    invoiceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    saleDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    payableDaysId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    printProjectNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    isPaymentFromReceiptDate: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    projectIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    isOnePositionOnInvoice: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    onePositionName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    exchangeRate: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    exchangeRateDescription: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    gtuCodeId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    documentCodeIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    textOverItems: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    correctionReason: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    purchaserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    budgetId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    approverId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSalesInvoiceDto
     */
    approvedTs?: Date | null;
}


/**
 * @export
 */
export const CreateUpdateSalesInvoiceDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type CreateUpdateSalesInvoiceDtoCurrencyEnum = typeof CreateUpdateSalesInvoiceDtoCurrencyEnum[keyof typeof CreateUpdateSalesInvoiceDtoCurrencyEnum];

/**
 * @export
 */
export const CreateUpdateSalesInvoiceDtoStatusEnum = {
    Draft: 'Draft',
    Generated: 'Generated',
    Sent: 'Sent',
    Paid: 'Paid'
} as const;
export type CreateUpdateSalesInvoiceDtoStatusEnum = typeof CreateUpdateSalesInvoiceDtoStatusEnum[keyof typeof CreateUpdateSalesInvoiceDtoStatusEnum];

/**
 * @export
 */
export const CreateUpdateSalesInvoiceDtoPaymentFormEnum = {
    BankTransfer: 'BankTransfer',
    Compensation: 'Compensation',
    Prepayment: 'Prepayment',
    Paid: 'Paid'
} as const;
export type CreateUpdateSalesInvoiceDtoPaymentFormEnum = typeof CreateUpdateSalesInvoiceDtoPaymentFormEnum[keyof typeof CreateUpdateSalesInvoiceDtoPaymentFormEnum];

/**
 * @export
 */
export const CreateUpdateSalesInvoiceDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type CreateUpdateSalesInvoiceDtoTypeEnum = typeof CreateUpdateSalesInvoiceDtoTypeEnum[keyof typeof CreateUpdateSalesInvoiceDtoTypeEnum];


/**
 * Check if a given object implements the CreateUpdateSalesInvoiceDto interface.
 */
export function instanceOfCreateUpdateSalesInvoiceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "paymentForm" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "invoiceDate" in value;
    isInstance = isInstance && "saleDate" in value;
    isInstance = isInstance && "payableDaysId" in value;
    isInstance = isInstance && "printProjectNumber" in value;
    isInstance = isInstance && "isPaymentFromReceiptDate" in value;
    isInstance = isInstance && "projectIds" in value;
    isInstance = isInstance && "isOnePositionOnInvoice" in value;
    isInstance = isInstance && "onePositionName" in value;
    isInstance = isInstance && "exchangeRate" in value;
    isInstance = isInstance && "exchangeRateDescription" in value;
    isInstance = isInstance && "gtuCodeId" in value;
    isInstance = isInstance && "documentCodeIds" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "textOverItems" in value;
    isInstance = isInstance && "correctionReason" in value;

    return isInstance;
}

export function CreateUpdateSalesInvoiceDtoFromJSON(json: any): CreateUpdateSalesInvoiceDto {
    return CreateUpdateSalesInvoiceDtoFromJSONTyped(json, false);
}

export function CreateUpdateSalesInvoiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSalesInvoiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'number': json['number'],
        'status': json['status'],
        'paymentForm': json['paymentForm'],
        'type': json['type'],
        'invoiceDate': (new Date(json['invoiceDate'])),
        'saleDate': (new Date(json['saleDate'])),
        'payableDaysId': json['payableDaysId'],
        'printProjectNumber': json['printProjectNumber'],
        'isPaymentFromReceiptDate': json['isPaymentFromReceiptDate'],
        'projectIds': json['projectIds'],
        'isOnePositionOnInvoice': json['isOnePositionOnInvoice'],
        'onePositionName': json['onePositionName'],
        'exchangeRate': json['exchangeRate'],
        'exchangeRateDescription': json['exchangeRateDescription'],
        'gtuCodeId': json['gtuCodeId'],
        'documentCodeIds': json['documentCodeIds'],
        'active': json['active'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'textOverItems': json['textOverItems'],
        'correctionReason': json['correctionReason'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'purchaserId': !exists(json, 'purchaserId') ? undefined : json['purchaserId'],
        'budgetId': !exists(json, 'budgetId') ? undefined : json['budgetId'],
        'approverId': !exists(json, 'approverId') ? undefined : json['approverId'],
        'approvedTs': !exists(json, 'approvedTs') ? undefined : (json['approvedTs'] === null ? null : new Date(json['approvedTs'])),
    };
}

export function CreateUpdateSalesInvoiceDtoToJSON(value?: CreateUpdateSalesInvoiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'number': value.number,
        'status': value.status,
        'paymentForm': value.paymentForm,
        'type': value.type,
        'invoiceDate': (value.invoiceDate.toISOString()),
        'saleDate': (value.saleDate.toISOString()),
        'payableDaysId': value.payableDaysId,
        'printProjectNumber': value.printProjectNumber,
        'isPaymentFromReceiptDate': value.isPaymentFromReceiptDate,
        'projectIds': value.projectIds,
        'isOnePositionOnInvoice': value.isOnePositionOnInvoice,
        'onePositionName': value.onePositionName,
        'exchangeRate': value.exchangeRate,
        'exchangeRateDescription': value.exchangeRateDescription,
        'gtuCodeId': value.gtuCodeId,
        'documentCodeIds': value.documentCodeIds,
        'active': value.active,
        'parentId': value.parentId,
        'textOverItems': value.textOverItems,
        'correctionReason': value.correctionReason,
        'companyId': value.companyId,
        'purchaserId': value.purchaserId,
        'budgetId': value.budgetId,
        'approverId': value.approverId,
        'approvedTs': value.approvedTs === undefined ? undefined : (value.approvedTs === null ? null : value.approvedTs.toISOString()),
    };
}

