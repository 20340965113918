import { brandForGrid } from '../../../pok/components/Brands/BrandSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateBrandDto,
  CreateUpdateBrandDtoFromJSON,
  DefaultApi,
  GetBrandDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { BrandSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { Option } from '../../../pok/components/Selects/Selector';

export interface BrandContextInterface {
  getById: (id: string) => Promise<GetBrandDto>;
  getAllByBudgetId: (budgetId: string) => Promise<GetBrandDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPurchaserId: (purchaserId: string) => Promise<GetBrandDto[]>;
  update: (id: string, dto: CreateUpdateBrandDto) => Promise<void>;
  create: (dto: CreateUpdateBrandDto) => Promise<GetBrandDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getOptionById: (id: string) => Promise<Option>;
}

export const BrandContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.brandControllerGet(id),
    getAllByBudgetId: (budgetId: string) =>
      api
        .brandControllerGetAllByBudget(budgetId)
        .then(brand =>
          brand.sort((a, b) => a.name.localeCompare(b.name, 'pl')),
        ),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.brandControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(brandForGrid);
    },
    getAllCount: (filterText?: string) =>
      api.brandControllerGetAllCount(filterText || ''),
    getAllByPurchaserId: (purchaserId: string) =>
      api
        .brandControllerGetAllByPurchaserId(purchaserId)
        .then(brand =>
          brand.sort((a, b) => a.name.localeCompare(b.name, 'pl')),
        ),
    update: (id: string, dto: CreateUpdateBrandDto) =>
      api.brandControllerUpdate(id, dto),
    create: (dto: CreateUpdateBrandDto) => api.brandControllerCreate(dto),
    getLastEditor: (id: string) => api.brandControllerGetLastEditor(id),
    getOptionById: async (id: string) => {
      const data = await api.brandControllerGet(id);
      return {
        value: data.id,
        label: data.name,
      };
    },
  };
};

export const newBrand = (budgetId?: string) => {
  return CreateUpdateBrandDtoFromJSON({
    budgetId: budgetId,
  });
};

export const validate = (brand: CreateUpdateBrandDto) =>
  validateAgainst(BrandSchema, brand);

export const convert = (brand?: GetBrandDto) => {
  if (!brand) {
    return newBrand();
  }
  const converted = CreateUpdateBrandDtoFromJSON(brand);
  converted.budgetId = brand.budget.id;
  return converted;
};
