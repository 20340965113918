import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetSpecialInvoicingDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { buildProjectLabel } from '../../../utils/buildProjectLabel';
import { SpecialInvoicingStatusText } from '../../../common/pokCore/validation/schemas';

interface SpecialInvoicingSearchProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  onRowClick?: (
    key: string,
    newWindow?: boolean,
  ) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const specialInvoicingForGrid = (
  specialInvoicing: GetSpecialInvoicingDto,
) => {
  return {
    key: specialInvoicing.id,
    values: {
      'specialInvoicing.systemNumber': specialInvoicing.systemNumber,
      'specialInvoicing.creationTs': momencik(specialInvoicing.creationTs),
      'specialInvoicing.status':
        SpecialInvoicingStatusText[specialInvoicing.status],
      'project.name': specialInvoicing.specialInvoicingProjects
        ?.map(({ project }) => buildProjectLabel(project))
        .join(', '),
      'creator.name': specialInvoicing.creator?.name,
    },
    isInactive: !specialInvoicing.active,
  };
};

export const SpecialInvoicingSearch: React.FC<
  SpecialInvoicingSearchProps
> = props => {
  const { t, tk } = useTranslation('menu');
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'specialInvoicing.systemNumber' },
    { header: 'Data utworzenia', property: 'specialInvoicing.creationTs' },
    { header: 'Autor', property: 'creator.name' },
    { header: t(tk.menu.projects), property: 'project.name' },
    { header: 'Status', property: 'specialInvoicing.status' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn="specialInvoicing.systemNumber"
      initialOrderDirection={'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak wniosków o niestandardowe fakturowanie w systemie."
      onRowClick={props.onRowClick}
      withHistory={true}
    />
  );
};
