import React from 'react';
import { Badge } from 'react-bootstrap';
import { SquareFill } from 'react-bootstrap-icons';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { GetCompanyDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { PersonalLanguageTypeText } from '../../../common/pokCore/validation/schemas';

interface CompanySearchProps {
  data: GetCompanyDto[];
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const CompanySearch: React.FC<CompanySearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'l.p.', property: 'company.orderField' },
    { header: 'Nr sys.', property: 'company.systemNumber' },
    { header: 'Skrót', property: 'company.shortname' },
    { header: 'Nazwa', property: 'company.name' },
    { header: 'Logo', property: 'company.logo' },
    { header: 'Nazewnictwo', property: 'company.personalLanguage' },
    { header: 'Kolor', property: 'company.color' },
  ];

  const data = props.data.map(company => {
    return {
      key: company.id,
      values: {
        'company.orderField': company.orderField,
        'company.name': company.name,
        'company.shortname': (
          <Badge bg="warning" text="dark">
            {company.shortname}
          </Badge>
        ),
        'company.logo': company.logo && (
          <img src={company.logo} alt="logo" height="20" />
        ),
        'company.color': company.color && (
          <SquareFill color={company.color} size={20} />
        ),
        'company.personalLanguage':
          company.personalLanguage &&
          PersonalLanguageTypeText[company.personalLanguage],
        'company.systemNumber': company.systemNumber,
      },
      orderByStringValues: {
        'company.orderField':
          company.orderField?.toString().padStart(10, '0') ?? '9999999999',
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="company.orderField"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak spółek w systemie"
      onRowClick={props.onRowClick}
      withHistory={true}
    />
  );
};
