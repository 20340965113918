import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetPackageInvoicesXMLDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import {
  InvoiceTypeEnumText,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/schemas';

import { DownloadXMLLink } from './DownloadXMLLink';
import { DownloadZIPLink } from './DownloadZIPLink';

interface PackagesInvoicesXMLSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  packageType: PackageTypeEnum;
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const packageInvoicesXMLForGrid = (
  packageXML: GetPackageInvoicesXMLDto,
) => {
  return {
    key: packageXML.id,
    values: {
      'packageXML.systemNumber': packageXML.systemNumber,
      'packageXML.creator': packageXML.creator.name,
      'packageXML.creationTs': momencik(
        packageXML.creationTs,
        'YYYY-MM-DD HH:mm:ss',
      ),
      'packageXML.count':
        packageXML.packageType === PackageTypeEnum.Purchase
          ? packageXML.purchaseInvoices?.length
          : packageXML.saleInvoices?.length,
      'packageXML.isRepeat': packageXML.isRepeat ? 'Tak' : 'Nie',
      'packageXML.invoiceType': packageXML.invoiceType
        ? InvoiceTypeEnumText[packageXML.invoiceType]
        : '',
      preview: <DownloadXMLLink packageXMLId={packageXML.id} />,
      zip: <DownloadZIPLink packageXMLId={packageXML.id} />,
      zipCopy: <DownloadZIPLink packageXMLId={packageXML.id} copy={true} />,
    },
  };
};

export const PackagesInvoicesXMLSearch: React.FC<
  PackagesInvoicesXMLSearchProps
> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'packageXML.systemNumber' },
    { header: 'Wygenerowana przez', property: 'packageXML.creator' },
    { header: 'Data wygenerowania', property: 'packageXML.creationTs' },
    { header: 'Typ faktur', property: 'packageXML.invoiceType' },
    ...(props.packageType === PackageTypeEnum.Purchase
      ? [{ header: 'Wysłane ponownie', property: 'packageXML.isRepeat' }]
      : []),
    {
      header: 'Liczba faktur w paczce',
      property: 'packageXML.count',
      noSorting: true,
    },
    { header: 'Pobierz paczkę XML', property: 'preview', noSorting: true },
    {
      header: 'Pobierz wydruki',
      property: 'zip',
      noSorting: true,
    },
    ...(props.packageType === PackageTypeEnum.Sale
      ? [
          {
            header: 'Pobierz kopie',
            property: 'zipCopy',
            noSorting: true,
          },
        ]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="packageXML.creationTs"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak paczek faktur zakupu w systemie"
      refresh={props.refresh}
      withHistory={true}
      onRowClick={props.onRowClick}
    />
  );
};
