import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { OrdersSentTab } from '../../components/Orders/OrdersSentTab';
import { OrdersWaitingTab } from '../../components/Orders/OrdersWaitingTab';
import { OrdersCompletedTab } from '../../components/Orders/OrdersCompletedTab';

export enum ProjectOrdersTabs {
  SENT = 'Wysłane',
  WAITING = 'Otrzymane oczekujące',
  COMPLETED = 'Otrzymane obsłużone',
}

export const OrdersView: React.FC = () => {
  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Zamówienia</Card.Title>
        <TabsHistory
          activeKey={ProjectOrdersTabs.WAITING}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={ProjectOrdersTabs.WAITING}
            title={ProjectOrdersTabs.WAITING}
            className="m-3"
          >
            <OrdersWaitingTab />
          </Tab>
          <Tab
            eventKey={ProjectOrdersTabs.COMPLETED}
            title={ProjectOrdersTabs.COMPLETED}
            className="m-3"
          >
            <OrdersCompletedTab />
          </Tab>
          <Tab
            eventKey={ProjectOrdersTabs.SENT}
            title={ProjectOrdersTabs.SENT}
            className="m-3"
          >
            <OrdersSentTab />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
