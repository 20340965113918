import BigJS from 'big.js';

const Big = (value: number | string | null | undefined) => {
  const valueString = value ? value.toString().trim() : '0';
  return new BigJS(
    valueString ? valueString.replace(/\s/g, '').replace(/,/g, '') : 0,
  );
};

export const add = (...args: (number | string | null | undefined)[]): number =>
  args.reduce((acc, value) => acc.plus(Big(value)), Big(0)).toNumber();

export const subtract = (
  ...args: (number | string | null | undefined)[]
): number => {
  if (args.length < 2) {
    throw new Error('Subtraction requires at least two arguments');
  }
  return args
    .slice(1)
    .reduce((acc, value) => acc.minus(Big(value)), Big(args[0]))
    .toNumber();
};

export const min = (...args: (number | string | null | undefined)[]): number =>
  Math.min(...args.map(val => Big(val).toNumber()));

export const max = (...args: (number | string | null | undefined)[]): number =>
  Math.max(...args.map(val => Big(val).toNumber()));

export const multiply = (
  ...args: (number | string | null | undefined)[]
): number => {
  return args.reduce((acc, value) => acc.times(Big(value)), Big(1)).toNumber();
};

export const divide = (
  ...args: (number | string | null | undefined)[]
): number => {
  if (args.length < 2) {
    throw new Error('Division requires at least two arguments');
  }
  return args
    .slice(1)
    .reduce((acc, value) => acc.div(Big(value)), Big(args[0]))
    .toNumber();
};

export const round = (
  num: number | string | null | undefined,
  precision: number = 2,
): number => Big(Big(num).toFixed(precision)).toNumber();

export const parse = (num: number | string | null | undefined): number =>
  Big(num).toNumber();

export default { add, subtract, multiply, divide, round, parse, Big, min, max };
