import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetSymphonyClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface SymphonySearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh: boolean;
}

export const symphonyForGrid = (symphonyClient: GetSymphonyClientDto) => {
  return {
    key: symphonyClient.id,
    values: {
      'symphonyClient.code': symphonyClient.code,
      'company.name': symphonyClient.company.name,
    },
    isInactive: !symphonyClient.active,
  };
};

export const SymphonySearch: React.FC<SymphonySearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Spółka', property: 'company.name' },
    { header: 'Kod', property: 'symphonyClient.code' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn="company.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak kodów Symfonii dla tego kontrahenta."
      onRowClick={props.onRowClick}
      withHistory={true}
    />
  );
};
