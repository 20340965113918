/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUpdatePurchaseInvoiceEstimateItemDto } from './CreateUpdatePurchaseInvoiceEstimateItemDto';
import {
    CreateUpdatePurchaseInvoiceEstimateItemDtoFromJSON,
    CreateUpdatePurchaseInvoiceEstimateItemDtoFromJSONTyped,
    CreateUpdatePurchaseInvoiceEstimateItemDtoToJSON,
} from './CreateUpdatePurchaseInvoiceEstimateItemDto';

/**
 * 
 * @export
 * @interface CreateUpdatePurchaseInvoiceEstimateItemsDto
 */
export interface CreateUpdatePurchaseInvoiceEstimateItemsDto {
    /**
     * 
     * @type {Array<CreateUpdatePurchaseInvoiceEstimateItemDto>}
     * @memberof CreateUpdatePurchaseInvoiceEstimateItemsDto
     */
    purchaseInvoiceEstimateItems: Array<CreateUpdatePurchaseInvoiceEstimateItemDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePurchaseInvoiceEstimateItemsDto
     */
    changeStatus?: boolean;
}

/**
 * Check if a given object implements the CreateUpdatePurchaseInvoiceEstimateItemsDto interface.
 */
export function instanceOfCreateUpdatePurchaseInvoiceEstimateItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "purchaseInvoiceEstimateItems" in value;

    return isInstance;
}

export function CreateUpdatePurchaseInvoiceEstimateItemsDtoFromJSON(json: any): CreateUpdatePurchaseInvoiceEstimateItemsDto {
    return CreateUpdatePurchaseInvoiceEstimateItemsDtoFromJSONTyped(json, false);
}

export function CreateUpdatePurchaseInvoiceEstimateItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePurchaseInvoiceEstimateItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseInvoiceEstimateItems': ((json['purchaseInvoiceEstimateItems'] as Array<any>).map(CreateUpdatePurchaseInvoiceEstimateItemDtoFromJSON)),
        'changeStatus': !exists(json, 'changeStatus') ? undefined : json['changeStatus'],
    };
}

export function CreateUpdatePurchaseInvoiceEstimateItemsDtoToJSON(value?: CreateUpdatePurchaseInvoiceEstimateItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchaseInvoiceEstimateItems': ((value.purchaseInvoiceEstimateItems as Array<any>).map(CreateUpdatePurchaseInvoiceEstimateItemDtoToJSON)),
        'changeStatus': value.changeStatus,
    };
}

