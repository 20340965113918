/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetClientDto
 */
export interface GetClientDto {
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetClientDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    nip: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    addressEx: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    numbers: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientDto
     */
    flat: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientDto
     */
    isRelated: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetClientDto
     */
    dealGroupNumber: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientDto
     */
    notToSymphony: boolean;
}

/**
 * Check if a given object implements the GetClientDto interface.
 */
export function instanceOfGetClientDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nip" in value;
    isInstance = isInstance && "addressEx" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "numbers" in value;
    isInstance = isInstance && "flat" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "isRelated" in value;
    isInstance = isInstance && "dealGroupNumber" in value;
    isInstance = isInstance && "notToSymphony" in value;

    return isInstance;
}

export function GetClientDtoFromJSON(json: any): GetClientDto {
    return GetClientDtoFromJSONTyped(json, false);
}

export function GetClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'shortname': json['shortname'],
        'name': json['name'],
        'nip': json['nip'],
        'addressEx': json['addressEx'],
        'postcode': json['postcode'],
        'place': json['place'],
        'street': json['street'],
        'country': json['country'],
        'numbers': json['numbers'],
        'flat': json['flat'],
        'active': json['active'],
        'isRelated': json['isRelated'],
        'dealGroupNumber': json['dealGroupNumber'],
        'notToSymphony': json['notToSymphony'],
    };
}

export function GetClientDtoToJSON(value?: GetClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'shortname': value.shortname,
        'name': value.name,
        'nip': value.nip,
        'addressEx': value.addressEx,
        'postcode': value.postcode,
        'place': value.place,
        'street': value.street,
        'country': value.country,
        'numbers': value.numbers,
        'flat': value.flat,
        'active': value.active,
        'isRelated': value.isRelated,
        'dealGroupNumber': value.dealGroupNumber,
        'notToSymphony': value.notToSymphony,
    };
}

