/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CloneProjectDto
 */
export interface CloneProjectDto {
    /**
     * 
     * @type {string}
     * @memberof CloneProjectDto
     */
    sourceProjectId: string;
    /**
     * 
     * @type {string}
     * @memberof CloneProjectDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CloneProjectDto
     */
    notes: string;
    /**
     * 
     * @type {boolean}
     * @memberof CloneProjectDto
     */
    withAmounts: boolean;
}

/**
 * Check if a given object implements the CloneProjectDto interface.
 */
export function instanceOfCloneProjectDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceProjectId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "withAmounts" in value;

    return isInstance;
}

export function CloneProjectDtoFromJSON(json: any): CloneProjectDto {
    return CloneProjectDtoFromJSONTyped(json, false);
}

export function CloneProjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloneProjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceProjectId': json['sourceProjectId'],
        'name': json['name'],
        'notes': json['notes'],
        'withAmounts': json['withAmounts'],
    };
}

export function CloneProjectDtoToJSON(value?: CloneProjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceProjectId': value.sourceProjectId,
        'name': value.name,
        'notes': value.notes,
        'withAmounts': value.withAmounts,
    };
}

