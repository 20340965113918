/*eslint max-lines-per-function: ["error", 354]*/
/*eslint complexity: ["error", 22]*/
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import 'react-circular-progressbar/dist/styles.css';

import {
  CreateUpdateEstimateItemDtoPositionTypeEnum,
  GetExcelColumnsDto,
  GetExcelConfigDto,
  GetExcelRowDto,
  GetExcelRowDtoPositionTypeEnum,
  GetPositionExtendedDtoPositionTypeEnum,
  ParametersSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import {
  ExcelImportStatusEnum,
  ExcelImportStatusEnumText,
} from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import { useTranslation } from '../../../common/hooks/useTranslation';
import PopoverText from '../../../app/components/PopoverText';
import { isFieldHidden } from '../Estimates/CommissionService';
import mathUtils from '../../../utils/mathUtils';
import { EstimateItemFields } from '../../../common/pokCore/validation/EstimateItemFields';

import { PositionMapForm } from './PositionMapForm';
import { ParameterMapperForm } from './ParameterMapperForm';
import { PositionChangeForm } from './PositionChangeForm';

interface ExcelPreviewGridProps {
  data: GetExcelRowDto[];
  columns: GetExcelColumnsDto[];
  otherRequiredColumns: GetExcelColumnsDto[];
  config: GetExcelConfigDto;
  hideErrors: boolean;
  refresh?: boolean;
  updatePosition: (
    obj: Partial<GetExcelRowDto>,
    parameters?: ParametersSchemaDto,
  ) => void;
  changePosition: (
    rowId: string,
    newPositionName: string,
    newPositionId: string,
    newPositionType: GetPositionExtendedDtoPositionTypeEnum,
  ) => void;
  updateParams?: (
    rowId: string,
    key: string,
    newValue: string,
    dictValue: string,
  ) => void;
}

export const ExcelPreviewGrid: React.FC<ExcelPreviewGridProps> = props => {
  const { t, tk } = useTranslation('menu');

  const generateErrMsg = (excelValue?: string) => {
    return (
      <>
        [Brak]{' '}
        <PopoverText
          headerText={'Nazwa pozycji w Excelu'}
          bodyText={excelValue || '-'}
          icon={Icon.ExclamationCircle}
          className="ms-2"
        />
      </>
    );
  };

  const generateAmountFieldValue = (
    key: string,
    value?: string,
    excelValue?: string,
    positionType?: GetExcelRowDtoPositionTypeEnum,
  ) => {
    if (
      isFieldHidden(
        key,
        positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
        props.config.profile.commission,
      )
    ) {
      return '';
    }
    return value
      ? numberFormatter(value, true)
      : excelValue
        ? generateErrMsg(excelValue)
        : '';
  };

  const generateDictionaryFieldValue = (
    rowId: string,
    key: string,
    aggregatedRows: boolean,
    dictValue?: string,
    dictKey?: string,
    excelValue?: string,
    dictName?: string,
  ) => {
    return (
      <ParameterMapperForm
        dictValue={dictValue}
        dictKey={dictKey}
        excelParameterName={excelValue}
        dictName={dictName}
        aggregatedRows={aggregatedRows}
        handleUpdate={(newDictKey, newDictValue) =>
          props.updateParams &&
          props.updateParams(rowId, key, newDictKey, newDictValue)
        }
      />
    );
  };

  const generatePositionField = (d: GetExcelRowDto) => {
    if (!d.excelPositionName) {
      return '[Brak nazwy]';
    }
    if (!d.projectNumber) {
      return '[Nie znaleziono]';
    }
    if (d.positionName && d.positionId) {
      return (
        <PositionChangeForm
          profile={props.config.profile}
          teamIds={props.config.excelConfigTeams?.map(t => t.team.id) || []}
          configId={props.config.id}
          positionId={d.positionId}
          positionName={d.positionName}
          aggregatedRows={d.rowId.toString().split(',').length > 1}
          handleUpdate={(newPositionId, newPositionName, newPositionType) =>
            props.changePosition &&
            props.changePosition(
              d.rowId,
              newPositionName,
              newPositionId,
              newPositionType,
            )
          }
        />
      );
    }

    return (
      <PositionMapForm
        excelPositionName={d.excelPositionName}
        profile={props.config.profile}
        teams={props.config.excelConfigTeams?.map(t => t.team.id) || []}
        configId={props.config.id}
        handleUpdate={(positionName, positionType, positionId, parameters) =>
          props.updatePosition &&
          props.updatePosition(
            {
              positionName: positionName,
              positionType: positionType,
              positionId: positionId,
              excelPositionName: d.excelPositionName,
            },
            parameters,
          )
        }
      />
    );
  };

  const getOrderByNumberColumns = (d: GetExcelRowDto, keys: string[]) => {
    const columns: Record<string, number> = {};

    for (const key of keys) {
      let value = d[key as keyof GetExcelRowDto] ?? '-9999999';
      if (
        isFieldHidden(
          key,
          d.positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
          props.config.profile.commission,
        )
      ) {
        value = '-9999999';
      }
      if (typeof value === 'string') {
        columns[key] = mathUtils.parse(value);
      }
    }
    return columns;
  };

  const generateErrorsInfo = (d: GetExcelRowDto) => {
    const errRow = [];
    if (d.positionNotFoundError) {
      errRow.push(
        <div key={d.rowId}>
          <b>{d.positionNotFoundError}</b>
        </div>,
      );
    }
    errRow.push(
      ...d.otherErrors
        .concat(d.parameterErrors)
        .concat(d.calculationErrors)
        .map((e, i) => <div key={i}>{e}</div>),
    );

    return errRow;
  };

  const generateAmountFields = (d: GetExcelRowDto) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.amountFieldsKeys) {
      const value =
        (d[key.name as keyof GetExcelRowDto] as string) ?? undefined;
      const excelKeyName = `excel${key.name.charAt(0).toUpperCase()}${key.name.slice(
        1,
      )}`;
      const excelValue =
        (d[excelKeyName as keyof GetExcelRowDto] as string) ?? undefined;
      columns[key.name] = isHidden(key.name, d)
        ? ''
        : generateAmountFieldValue(key.name, value, excelValue, d.positionType);
    }
    return columns;
  };

  const generatePercentageFields = (d: GetExcelRowDto) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.percentageFieldsKeys) {
      const value = (d[key as keyof GetExcelRowDto] as string) ?? undefined;
      let percentageValue = '';
      if (value) {
        percentageValue = isNaN(Number(value))
          ? value
          : `${mathUtils.parse(value).toString()}%`;
      }
      columns[key] = isHidden(key, d) ? '' : percentageValue;
    }
    return columns;
  };

  const generateStringFields = (d: GetExcelRowDto) => {
    const columns: Record<string, string> = {};
    for (const key of EstimateItemFields.stringFields) {
      const value = (d[key as keyof GetExcelRowDto] as string) ?? undefined;
      columns[key] = isHidden(key, d) ? '' : value;
    }
    return columns;
  };

  const generateDictionaryFields = (d: GetExcelRowDto) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.dictionaryFields) {
      const dictValue =
        (d[(key.fieldName + 'Value') as keyof GetExcelRowDto] as string) ??
        undefined;
      const dictKey = d[key.fieldName as keyof GetExcelRowDto] as string;
      const excelKeyName = `excel${key.fieldName.charAt(0).toUpperCase()}${key.fieldName.slice(1)}`;
      const excelValue =
        (d[excelKeyName as keyof GetExcelRowDto] as string) ?? undefined;
      const aggregatedRows = d.rowId.toString().split(',').length > 1;
      columns[key.fieldName] = isHidden(key.fieldName, d)
        ? ''
        : generateDictionaryFieldValue(
            d.rowId,
            key.fieldName,
            aggregatedRows,
            dictValue,
            dictKey,
            excelValue,
            key.dictionaryName,
          );
    }
    return columns;
  };

  const generateBoolFields = (d: GetExcelRowDto) => {
    const columns: Record<string, string> = {};
    for (const key of EstimateItemFields.boolFields) {
      const value = d[key as keyof GetExcelRowDto] as boolean;
      columns[key] = value === undefined ? '' : value === true ? 'Tak' : 'Nie';
    }
    return columns;
  };

  const isHidden = (key: string, d: GetExcelRowDto) => {
    return isFieldHidden(
      key,
      d.positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
      props.config.profile.commission,
    );
  };

  const columns: GridColumn[] = [
    { header: 'Nr wiersza', property: 'rowId' },
    { header: 'Status', property: 'status' },
  ];
  if (props.columns) {
    for (const column of props.columns) {
      if (column.columnName === 'projectNumber') {
        columns.push({
          header: `Nr ${t(tk.menu.genitiveProject)}`,
          property: 'projectNumber',
          columnCssClass: 'noWrap',
        });
        continue;
      }
      if (
        column.columnName === 'month' ||
        column.columnName === 'startDate' ||
        column.columnName === 'endDate'
      ) {
        if (column.columnName !== 'endDate') {
          columns.push({
            header: `Od`,
            property: 'startDate',
            columnCssClass: 'noWrap',
          });
        }
        if (column.columnName !== 'startDate') {
          columns.push({
            header: `Do`,
            property: 'endDate',
            columnCssClass: 'noWrap',
          });
        }
        continue;
      }
      columns.push({
        header: column.columnNamePl,
        property: column.columnName,
      });
    }
  }
  for (const column of props.otherRequiredColumns) {
    columns.push({
      header: column.columnNamePl + ' (z konfig.)',
      property: column.columnName,
    });
  }
  columns.push({ header: 'Błędy', property: 'errors' });

  const data: GridRecord[] = [];

  for (const d of props.data) {
    if (props.hideErrors && d.status === ExcelImportStatusEnum.Error) {
      continue;
    }
    if (d.emptyRow) {
      continue;
    }
    const orderByNumberValues = getOrderByNumberColumns(
      d,
      EstimateItemFields.amountFieldsKeys.map(key => key.name),
    );
    const amountFields = generateAmountFields(d);
    const percentageFields = generatePercentageFields(d);
    const stringFields = generateStringFields(d);
    const dictionaryFields = generateDictionaryFields(d);
    const boolFields = generateBoolFields(d);

    data.push({
      key: d.rowId,
      rowCssClass:
        d.status === ExcelImportStatusEnum.Error
          ? d.positionNotFoundError && d.excelPositionName && d.projectNumber
            ? 'info-row'
            : 'error-row'
          : d.status === ExcelImportStatusEnum.Duplicate
            ? 'warning-row'
            : '',
      values: {
        ...amountFields,
        ...percentageFields,
        ...stringFields,
        ...dictionaryFields,
        ...boolFields,
        rowId: d.rowId,
        status:
          ExcelImportStatusEnumText[
            d.status as keyof typeof ExcelImportStatusEnum
          ],
        errors: generateErrorsInfo(d),
        positionName: generatePositionField(d),
        projectNumber: d.projectNumber ?? generateErrMsg(d.excelProjectNumber),
        startDate: d.startDate ? momencik(d.startDate, 'YYYY-MM-DD') : '',
        endDate: d.endDate ? momencik(d.endDate, 'YYYY-MM-DD') : '',
        invoiceInstructionAttachment: d.invoiceInstructionAttachment,
      },
      orderByNumberValues: {
        ...orderByNumberValues,
        rowId: mathUtils.parse(d.rowId),
      },
    });
  }

  return (
    <Grid
      refresh={props.refresh}
      data={data}
      columns={columns}
      showFilter={false}
      availablePageSizes={[10, 20, 50, 100]}
      pageSize={100}
      emptyGridMessage="Brak pozycji"
      trClassName="amounts-align-right font-90"
    />
  );
};
