import { Buffer } from 'buffer';

import React from 'react';
import { Button, Tooltip } from 'react-bootstrap';
import { Download, FilePdf } from 'react-bootstrap-icons';
import { saveAs } from 'file-saver';
import * as mime from 'mime';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { CreateUpdateAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';

interface AttachmentInputDownloadIconProps {
  attachment?: CreateUpdateAttachmentDto;
  isOpen?: boolean;
}

export const AttachmentInputDownloadIcon: React.FC<
  AttachmentInputDownloadIconProps
> = props => {
  const pok = usePokCore();

  const loadFileContent = async (callback: (content: Blob) => void) => {
    if (props.attachment) {
      let fileContent = props.attachment.fileContent;
      if (!fileContent) {
        fileContent = (
          await pok.attachments.getFileByObjectIdAndObjectType(
            props.attachment.objectId,
            props.attachment.enObjectType,
          )
        )?.buffer;
      }
      if (fileContent && props.attachment.name) {
        const blob = new Blob([Buffer.from(fileContent as ArrayBuffer)], {
          type: mime.getType(props.attachment.name) || undefined,
        });
        callback(blob);
      }
    }
  };

  const handleDownload = () =>
    loadFileContent((blob: Blob) => saveAs(blob, props.attachment?.name || ''));

  const handleOpen = () =>
    loadFileContent((blob: Blob) => window.open(URL.createObjectURL(blob)));

  if (props.isOpen) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div onClick={e => e.stopPropagation()}>
        <OverlayTrigger
          overlay={<Tooltip>Otwórz załącznik w nowej karcie</Tooltip>}
        >
          <Button
            variant="outline-primary"
            onClick={handleOpen}
            disabled={!props.attachment}
          >
            <FilePdf />
          </Button>
        </OverlayTrigger>
      </div>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={e => e.stopPropagation()}>
      <OverlayTrigger overlay={<Tooltip>Pobierz załącznik</Tooltip>}>
        <Button
          variant="outline-primary"
          onClick={handleDownload}
          disabled={!props.attachment}
        >
          <Download />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
