import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useGoogleLogin } from '@react-oauth/google';
// import axios from 'axios';

export type Props = {
  onSignIn: (usr: string, pwd: string) => void;
  onSignInWithGoogle: (googleToken: string) => void;
};

export const SignIn: React.FC<Props> = ({ onSignIn, onSignInWithGoogle }) => {
  const [usr, setUsr] = useState('');
  const [pwd, setPwd] = useState('');
  const [userRequiredError, setUserRequiredError] = useState('');
  const [passwordRequiredError, setPasswordRequiredError] = useState('');

  const handleSignIn = async () => {
    setUserRequiredError('');
    setPasswordRequiredError('');
    if (!usr || !pwd) {
      if (!usr) {
        setUserRequiredError('Proszę podać login.');
      }
      if (!pwd) {
        setPasswordRequiredError('Proszę podać hasło.');
      }
      return;
    }

    onSignIn(usr, pwd);
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      onSignInWithGoogle(tokenResponse.access_token);
    },
  });

  return (
    <div className="bg-image d-flex justify-content-center">
      <main className="form-sign-in">
        <Form>
          <h3 className="fw-normal text-center">Logowanie do systemu POK</h3>
          <Form.Group className="form-floating mt-4" controlId="email">
            <Form.Control
              type="login"
              placeholder="Login"
              defaultValue={usr}
              onChange={e => setUsr(e.target.value)}
            />
            <Form.Label>Login</Form.Label>
          </Form.Group>
          <span className="text-danger">{userRequiredError}</span>
          <Form.Group className="form-floating mt-3" controlId="password">
            <Form.Control
              type="password"
              placeholder="Hasło"
              value={pwd}
              onChange={e => setPwd(e.target.value)}
            />
            <Form.Label>Hasło</Form.Label>
          </Form.Group>
          <span className="text-danger">{passwordRequiredError}</span>
        </Form>
        <Button
          type="submit"
          variant="dark"
          className="w-100 btn btn-lg mt-5"
          onClick={handleSignIn}
        >
          Zaloguj się
        </Button>
        <Button
          type="submit"
          variant="danger"
          className="w-100 btn btn-lg mt-3"
          onClick={() => login()}
        >
          <Icon.Google className="icon me-2 mb-1" />
          Zaloguj się z Google
        </Button>

        <Image className="img-logo-pok mx-auto mt-5 d-block" alt="Logo G1" />
      </main>
    </div>
  );
};
