/*eslint max-lines-per-function: ["error", 250]*/
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import saveAs from 'file-saver';

import {
  CreateUpdateOrderExecutionDto,
  GetOrderExecutionDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/OrderExecutionContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { OrderExecutionStatusEnum } from '../../../common/pokCore/validation/schemas';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';

import { OrderExecutionEditor } from './OrderExecutionEditor';
import { OrderExecutionSearch } from './OrderExecutionSearch';

interface OrdersExecutionTabProps {
  project: GetProjectDto;
}

export const OrdersExecutionTab: React.FC<OrdersExecutionTabProps> = props => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [orderExecution, setOrderExecution] = useState<GetOrderExecutionDto>();
  const pok = usePokCore();
  const notifications = useNotifications();
  const nav = useNavigation();
  const [orderExecutionCount, setOrderExecutionCount] = useState(0);

  useEffect(() => {
    pok.ordersExecution
      .getAllCountForProject(
        '',
        {},
        props.project.id,
        props.project.id,
        OrderExecutionStatusEnum.Accepted,
      )
      .then(o => setOrderExecutionCount(o))
      .catch(responseErrors => notifications.caughtError(responseErrors));
  }, [props.project.id, notifications, pok.ordersExecution]);

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.ordersExecution.getAllByPortionForProject(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          props.project.id,
          props.project.id,
          props.project.id,
        )) as GridGetterFunction,
    [pok.ordersExecution, props.project.id],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.ordersExecution.getAllCountForProject(
          filterText,
          filterColumns,
          props.project.id,
          props.project.id,
        )) as GridCountFunction,
    [pok.ordersExecution, props.project.id],
  );

  const addEditOrderExecution = async (
    orderExecutionCU: CreateUpdateOrderExecutionDto,
    id?: string,
  ) => {
    const status = await validate(orderExecutionCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveOrderExecution(orderExecutionCU, id);
  };

  const saveOrderExecution = async (
    orderExecutionCU: CreateUpdateOrderExecutionDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await pok.ordersExecution.update(id, orderExecutionCU);
      } else {
        const order = await pok.ordersExecution.create(orderExecutionCU);
        id = order.id;
      }
      void sendEmail(orderExecutionCU, id);
      setShow(false);
      handleRefresh();
      return {
        saved: true,
        errors: [],
      };
    } catch (err) {
      return {
        saved: false,
        errors: await responseErrors(err as ResponseError),
      };
    }
  };

  const sendEmail = async (
    orderCU: CreateUpdateOrderExecutionDto,
    id: string,
  ) => {
    if (orderCU.emails.length !== 0) {
      pok.emails.sendOrderExecutionEmail(id).catch(err => {
        notifications.caughtError(err);
      });
    }
  };

  const deactivateOrderExecution = async (id: string) => {
    try {
      await pok.ordersExecution.deactivate(id);
      handleClose();
      handleRefresh();

      return {
        saved: true,
        errors: [],
      };
    } catch (err) {
      return {
        saved: false,
        errors: await responseErrors(err as ResponseError),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleNew = () => {
    setOrderExecution(undefined);
    setShow(true);
  };

  const handleClick = async (id: string, newWindow?: boolean) => {
    const fetchedOrderExecution = await pok.ordersExecution.getById(id);
    if (
      fetchedOrderExecution.resultProject?.id === props.project.id &&
      fetchedOrderExecution.status === OrderExecutionStatusEnum.New
    ) {
      return nav.orderExecutionEditor(id, newWindow);
    }

    setOrderExecution(fetchedOrderExecution);
    setShow(true);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleDownload = async () => {
    if (props.project) {
      pok.pdfPrinters
        .getZipOrderExecutionsByProject(props.project.id)
        .then(async zip => {
          const fileName =
            `${props.project.number}_(${props.project.name})_${momencik(
              new Date(),
              'YYYY-MM-DD_HH-mm-ss',
            )}.zip`.replace(/[ /]/g, '_');

          if (zip) {
            const blob = new Blob([Buffer.from(zip as Buffer)], {
              type: 'application/zip',
            });
            saveAs(blob, fileName);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleNew}
        disabled={!props.project.parent}
      >
        Nowa realizacja zamówienia
      </Button>
      <OrderExecutionSearch
        getData={getData}
        getCount={getCount}
        onRowClick={handleClick}
        refresh={refresh}
        withAmount={true}
        isProjectView
      />
      {props.project?.purchaser?.budget?.downloadDocuments &&
        orderExecutionCount > 0 && (
          <Button onClick={handleDownload}>Pobierz dokumenty</Button>
        )}
      {show && (
        <OrderExecutionEditor
          id={orderExecution?.id}
          systemNumber={orderExecution?.systemNumber}
          project={props.project}
          orderExecution={convert(orderExecution, props.project.id)}
          onCompleted={addEditOrderExecution}
          show={show}
          handleClose={handleClose}
          deactivateOrderExecution={deactivateOrderExecution}
          status={orderExecution?.status}
          rejectedNote={orderExecution?.rejectedNote}
          readOnly={
            orderExecution &&
            (!orderExecution.active ||
              orderExecution.status !== OrderExecutionStatusEnum.New)
          }
        />
      )}
    </>
  );
};
