import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ImportFinanceView: React.FC = () => {
  const nav = useNavigation();

  return (
    <Card>
      <TabsHistory activeKey="imports" id="imports" className="mb-3">
        <Tab eventKey="imports" title="Importy">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="Import faktur FVZ z Google"
              text="Import faktur zakupu na podstawie pliku z Google i katalogu z wydrukami faktur"
              footer="Import FVZ"
              onClick={nav.purchasesInvoicesImport}
            />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
