import React from 'react';
import * as Icons from 'react-bootstrap-icons';

import oneliner from '../../../common/oneliner';
import {
  CreatePublicMemoDtoEnSubjectTypeEnum,
  GetProjectDto,
  GetPurchaseInvoiceDto,
  GetSalesInvoiceDto,
  GetSpecialInvoicingDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  subjectType,
  SubjectType,
} from '../../../common/pokCore/contexts/MemoContext';

export default (props: { subject: SubjectType; maxlen: number }) => {
  const type = subjectType(props.subject);

  const icon = () => {
    switch (type) {
      case CreatePublicMemoDtoEnSubjectTypeEnum.Project:
        return <Icons.CardChecklist />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice:
        return <Icons.Search />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice:
        return <Icons.CardList />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing:
        return <Icons.CardChecklist />;
    }
  };

  const identity = () => {
    switch (type) {
      case CreatePublicMemoDtoEnSubjectTypeEnum.Project: {
        const project = props.subject as GetProjectDto;
        return `Kampania nr sys. ${project.systemNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice: {
        const purchaseInvoice = props.subject as GetPurchaseInvoiceDto;
        return `Faktura zakupu nr sys. ${purchaseInvoice.systemNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice: {
        const saleInvoice = props.subject as GetSalesInvoiceDto;
        return `Faktura sprzedaży nr sys. ${saleInvoice.systemNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing: {
        const specialInvoicing = props.subject as GetSpecialInvoicingDto;
        return `Niestandardowe fakturowanie nr sys. ${specialInvoicing.systemNumber}`;
      }
    }
  };

  return (
    <div className="d-flex text-start align-items-center">
      {icon()}
      <span className="ms-1 flex-fill">
        {oneliner(identity(), props.maxlen)}
      </span>
    </div>
  );
};
