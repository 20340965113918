/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetSpecialInvoicingProjectDto } from './GetSpecialInvoicingProjectDto';
import {
    GetSpecialInvoicingProjectDtoFromJSON,
    GetSpecialInvoicingProjectDtoFromJSONTyped,
    GetSpecialInvoicingProjectDtoToJSON,
} from './GetSpecialInvoicingProjectDto';

/**
 * 
 * @export
 * @interface GetSpecialInvoicingDto
 */
export interface GetSpecialInvoicingDto {
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    note: string;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetSpecialInvoicingDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSpecialInvoicingDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSpecialInvoicingDto
     */
    systemNumber: number;
    /**
     * 
     * @type {Array<GetSpecialInvoicingProjectDto>}
     * @memberof GetSpecialInvoicingDto
     */
    specialInvoicingProjects?: Array<GetSpecialInvoicingProjectDto>;
    /**
     * 
     * @type {Date}
     * @memberof GetSpecialInvoicingDto
     */
    creationTs: Date;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetSpecialInvoicingDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    status: GetSpecialInvoicingDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    rejectedNote?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    correctionNote?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingDto
     */
    financeNote?: string;
}


/**
 * @export
 */
export const GetSpecialInvoicingDtoStatusEnum = {
    New: 'New',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
    ToBeCorrected: 'ToBeCorrected'
} as const;
export type GetSpecialInvoicingDtoStatusEnum = typeof GetSpecialInvoicingDtoStatusEnum[keyof typeof GetSpecialInvoicingDtoStatusEnum];


/**
 * Check if a given object implements the GetSpecialInvoicingDto interface.
 */
export function instanceOfGetSpecialInvoicingDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "creationTs" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetSpecialInvoicingDtoFromJSON(json: any): GetSpecialInvoicingDto {
    return GetSpecialInvoicingDtoFromJSONTyped(json, false);
}

export function GetSpecialInvoicingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSpecialInvoicingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'note': json['note'],
        'company': GetCompanyDtoFromJSON(json['company']),
        'active': json['active'],
        'systemNumber': json['systemNumber'],
        'specialInvoicingProjects': !exists(json, 'specialInvoicingProjects') ? undefined : ((json['specialInvoicingProjects'] as Array<any>).map(GetSpecialInvoicingProjectDtoFromJSON)),
        'creationTs': (new Date(json['creationTs'])),
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'status': json['status'],
        'rejectedNote': !exists(json, 'rejectedNote') ? undefined : json['rejectedNote'],
        'correctionNote': !exists(json, 'correctionNote') ? undefined : json['correctionNote'],
        'financeNote': !exists(json, 'financeNote') ? undefined : json['financeNote'],
    };
}

export function GetSpecialInvoicingDtoToJSON(value?: GetSpecialInvoicingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'note': value.note,
        'company': GetCompanyDtoToJSON(value.company),
        'active': value.active,
        'systemNumber': value.systemNumber,
        'specialInvoicingProjects': value.specialInvoicingProjects === undefined ? undefined : ((value.specialInvoicingProjects as Array<any>).map(GetSpecialInvoicingProjectDtoToJSON)),
        'creationTs': (value.creationTs.toISOString()),
        'creator': GetEmployeeDtoToJSON(value.creator),
        'status': value.status,
        'rejectedNote': value.rejectedNote,
        'correctionNote': value.correctionNote,
        'financeNote': value.financeNote,
    };
}

