import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { GetExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface HeaderSearchProps {
  config: GetExcelConfigDto;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const HeaderSearch: React.FC<HeaderSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Kolumna w POK', property: 'pokColumnName' },
    { header: 'Kolumna w Excel', property: 'excelColumnName' },
    { header: 'Kolejność kolumny w tabeli importu', property: 'order' },
  ];

  const data = props.config.excelConfigHeaders
    ?.filter(header => header.active === true)
    .map(header => {
      return {
        key: header.id,
        values: {
          pokColumnName: header.pokColumnNamePl,
          excelColumnName: header.excelColumnName,
          order: header.order,
        },
        orderByStringValues: {
          order: header.order?.toString().padStart(10, '0') ?? '9999999999',
        },
      };
    });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="order"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak zdefiniowanych mapowań kolumn"
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
