import React from 'react';

import { GetOrderExecutionDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { OrderExecutionStatusEnum } from '../../../common/pokCore/validation/schemas';
import Button from '../../../common/components/Button/Button';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useAuth } from '../../../common/hooks/useAuth';

interface OrderExecutionDecisionControlsProps {
  orderExecution?: GetOrderExecutionDto;
  handleAccept: () => void;
  handleReject: (rejectedNote?: string) => void;
  handleDownload: () => void;
  handleInactive: () => void;
  isLoading: boolean;
}

export const OrderExecutionDecisionControls: React.FC<
  OrderExecutionDecisionControlsProps
> = props => {
  const pok = usePokCore();
  const auth = useAuth();

  const isOrderCompleted =
    props.orderExecution?.status !== OrderExecutionStatusEnum.New ||
    !props.orderExecution.active;

  const authorized = props.orderExecution?.id
    ? auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  return (
    <>
      <div className="d-flex marginLeft gap-2">
        {authorized && props.orderExecution?.active && (
          <ConfirmationButton
            variant="outline-danger"
            confirmation="Czy na pewno usunąć tę realizację zamówienia?"
            confirmLabel="Usuń realizację"
            rejectLabel="Anuluj"
            dynamicInput
            focusInput
            onOK={props.handleInactive}
          >
            Usuń realizacje
          </ConfirmationButton>
        )}
        {isOrderCompleted &&
          props.orderExecution?.status ===
            OrderExecutionStatusEnum.Accepted && (
            <Button onClick={props.handleDownload} isLoading={props.isLoading}>
              Pobierz potwierdzenie
            </Button>
          )}
        <ConfirmationButton
          variant="outline-danger"
          confirmation="Odrzucenie realizacji zamówienia"
          confirmLabel="Odrzuć realizację zamówienia"
          errorLabel="Proszę uzupełnić powód odrzucenia realizacji zamówienia."
          reasonText="Powód odrzucenia"
          rejectLabel="Anuluj"
          dynamicInput
          focusInput
          askForAReason
          reasonNotEmpty
          onOK={props.handleReject}
          disabled={isOrderCompleted}
          isLoading={props.isLoading}
        >
          Odrzuć realizacje zamówienia
        </ConfirmationButton>
        <Button
          disabled={isOrderCompleted}
          onClick={props.handleAccept}
          isLoading={props.isLoading}
        >
          Zaakceptuj realizacje zamówienia
        </Button>
      </div>
    </>
  );
};
