import React from 'react';

import { PurchaseInvoiceView } from './PurchaseInvoiceView';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';

export const PurchaseInvoiceTvView: React.FC = () => {
  return (
    <PurchaseInvoiceView
      startingTab={PurchaseInvoiceTabsEnum.ESTIMATE_ITEM_POSITIONS}
      onlyTV={true}
    />
  );
};
