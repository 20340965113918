import React from 'react';
import Select from 'react-select';
import moment from 'moment';

import FormRow from '../../../app/components/FormRow';
import { CurrencyIsoTypeEnum } from '../../../common/pokCore/validation/schemas';
import { CreateUpdateSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';

import ExchangeRateControl from './ExchangeRateControl';

interface SalesInvoiceBasicDataEditorProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
}

const currencyOptions = Object.entries(CurrencyIsoTypeEnum).map(
  ([key, value]) => ({
    value: key as CurrencyIsoTypeEnum,
    label: value,
  }),
);

export const SalesInvoiceCurrencyForm: React.FC<
  SalesInvoiceBasicDataEditorProps
> = ({ salesInvoice, propertyChange, readOnly }) => {
  return (
    <>
      <FormRow controlId="currency" label="Waluta">
        <Select
          value={
            salesInvoice?.currency
              ? currencyOptions.find(
                  ({ value }) => salesInvoice?.currency === value,
                )
              : null
          }
          options={currencyOptions}
          onChange={option =>
            propertyChange({
              currency: option?.value,
              ...(option?.value === CurrencyIsoTypeEnum.PLN
                ? { exchangeRate: null, exchangeRateDescription: null }
                : {}),
            })
          }
          isDisabled={readOnly}
        />
      </FormRow>
      {salesInvoice.currency !== CurrencyIsoTypeEnum.PLN && (
        <FormRow controlId="exchangeRate" label="Kurs waluty">
          <ExchangeRateControl
            date={
              moment(salesInvoice.invoiceDate).isBefore(
                moment(salesInvoice.saleDate),
              )
                ? salesInvoice.invoiceDate
                : salesInvoice.saleDate
            }
            rate={salesInvoice.exchangeRate}
            description={salesInvoice.exchangeRateDescription}
            currency={salesInvoice.currency as CurrencyIsoTypeEnum}
            onChange={(exchangeRate, exchangeRateDescription) =>
              propertyChange({ exchangeRate, exchangeRateDescription })
            }
            readOnly={readOnly}
          />
        </FormRow>
      )}
    </>
  );
};
