import React, { useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { GetClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';

import { PurchaseInvoiceProjectsSearch } from './PurchaseInvoiceProjectsSearch';

interface PurchaseInvoiceProjectEditorProps {
  client?: GetClientDto;
  ignoreProjectIds?: string[];
  show?: boolean;
  onChange: (ids: string[]) => void;
  onClose: () => void;
  readOnly: boolean;
}

export const PurchaseInvoiceProjectEditor: React.FC<
  PurchaseInvoiceProjectEditorProps
> = props => {
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();

  const getData = useMemo(
    () =>
      (
        pageSize: number,
        pageNumber: number,
        orderBy?: string,
        orderDirection?: string,
        filterText?: string,
      ) =>
        pok.projects.getAllForPurchaseInvoiceByClientAndCompanyByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          props.client?.id,
          props.ignoreProjectIds,
        ),
    [props.client, pok.projects, props.ignoreProjectIds],
  );

  const getCount = useMemo(
    () => (filterText?: string) =>
      pok.projects.getAllForPurchaseInvoiceByClientAndCompanyCount(
        filterText,
        props.client?.id,
        props.ignoreProjectIds,
      ),
    [props.client, pok.projects, props.ignoreProjectIds],
  );

  const onRowClick = (id: string) => {
    setSelectedProjectsIds(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(prevId => prevId !== id);
      }

      return [...prevState, id];
    });
  };

  const handleSave = () => {
    setSaveErrors([]);
    setShowSaveErrors(false);
    if (selectedProjectsIds.length) {
      props.onChange(selectedProjectsIds);
    } else {
      setSaveErrors([t(tk.menu.selectProjectPurchaseInvoiceAlertError)]);
      setShowSaveErrors(true);
    }
  };

  useEffect(() => {
    if (props.show) {
      setShowSaveErrors(false);
      setSaveErrors([]);
      setSelectedProjectsIds([]);
    }
  }, [props.show]);

  return (
    <Modal
      onHide={props.onClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(tk.menu.chooseProject)}
          {props.client ? ` dla fakturującego ${props.client.name}` : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PurchaseInvoiceProjectsSearch
          getData={getData}
          getCount={getCount}
          onRowClick={onRowClick}
          readOnly={props.readOnly}
          enableMultiSelect
          isEditorView
        />
        <Alert>
          {t(tk.menu.selectProjectsPurchaseInvoiceAlert)} do faktury.
        </Alert>
        <ValidationAlert show={showSaveErrors} errors={saveErrors} />
        <div className="d-flex justify-content-end gap-2">
          <Button variant="outline-secondary" onClick={props.onClose}>
            Zamknij
          </Button>
          <Button onClick={handleSave}>Zapisz</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
