import { useEffect, useRef, useState } from 'react';
import { sortBy, uniq } from 'lodash';

import {
  CreateUpdateOrderExecutionDto,
  CreateUpdateOrderExecutionPositionDto,
  CreateUpdateSalesInvoicePositionDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { newOrderExecutionPosition } from '../../../common/pokCore/contexts/OrderExecutionContext';
import { UseSelectedGridRowReturn } from '../../../common/hooks/useSelectGridRow';
import mathUtils from '../../../utils/mathUtils';

export interface UseSalesInvoicePositionsReturn {
  positionChange: (
    obj: Partial<CreateUpdateSalesInvoicePositionDto>,
    key: number,
  ) => void;
  resetPositions: () => void;
  clonePosition: (key: number) => void;
  mergePositions: (key: number[]) => void;
  mergePositionsByActionGroup: (keys: number[]) => void;
  positionsSalesSum: number;
  showSumsDiscrepancyAlert: boolean;
  isActionGroupAvailable: boolean;
}

const mergePositionsByKeys = (
  positions: CreateUpdateOrderExecutionPositionDto[],
  keys: number[],
  byActionGroup: boolean,
) => {
  const foundPositions = positions.filter(position =>
    keys.includes(position.order),
  );

  const mergedPositionOrder = Math.min(...keys);
  const actionGroups = foundPositions
    .filter(({ actionGroup }) => !!actionGroup)
    .map(({ actionGroup }) => actionGroup.split(', '))
    .flat();

  const mergedPosition = newOrderExecutionPosition({
    ...foundPositions[0],
    actionGroup: uniq(actionGroups).join(', '),
    name: byActionGroup
      ? uniq(foundPositions.map(({ actionGroup }) => actionGroup)).join(', ')
      : uniq(foundPositions.map(({ name }) => name)).join(', '),
    amount: mathUtils
      .add(...foundPositions.map(({ amount }) => amount))
      .toString(),
    order: mergedPositionOrder,
  });

  const filteredPositions = positions.filter(
    position => !keys.includes(position.order),
  );

  let nextOrder = mergedPositionOrder + 1;
  filteredPositions.forEach(position => {
    if (position.order > mergedPositionOrder) {
      position.order = nextOrder++;
    }
  });

  return [...filteredPositions, mergedPosition];
};

export default function useOrderExecutionPositions(
  select: UseSelectedGridRowReturn<number>,
  orderExecution: CreateUpdateOrderExecutionDto,
  project?: GetProjectDto,
  propertyChange?: (obj: Partial<CreateUpdateOrderExecutionDto>) => void,
): UseSalesInvoicePositionsReturn {
  const [parentProjectPurchaseSum, setParentProjectPurchaseSum] = useState(0);
  const [positionsSalesSum, setPositionsSalesSum] = useState(0);
  const prevDateRef = useRef(orderExecution.date);
  const pok = usePokCore();
  const notifications = useNotifications();

  const setPositions = (positions: CreateUpdateOrderExecutionPositionDto[]) =>
    propertyChange?.({
      orderExecutionPositions: sortBy(positions, ['order']),
    });

  const positionChange = (
    obj: Partial<CreateUpdateOrderExecutionPositionDto>,
    key: number,
  ) => {
    const positions = orderExecution.orderExecutionPositions.map(position => {
      if (position.order === key) {
        return { ...position, ...obj };
      }

      return position;
    });
    setPositions(positions);
  };

  const initPositions = (omitSetPositions?: boolean) => {
    select.handleUnselectAll();
    pok.estimateItems
      .findByProjectAndMonth(orderExecution.projectId, orderExecution.date)
      .then(data => {
        const positions = data
          .filter(({ salesNetTotal }) => salesNetTotal !== null)
          .map((estimateItem, index) =>
            newOrderExecutionPosition({
              order: index + 1,
              name: estimateItem.position.name,
              amount: estimateItem.salesNetTotal,
              actionGroup: estimateItem.actionGroup,
              active: true,
            }),
          );

        if (!omitSetPositions) {
          setPositions(positions);
        }

        setPositionsSalesSum(
          mathUtils.add(
            ...positions.map(({ amount }) => mathUtils.round(amount)),
          ),
        );
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const insertPosition = (
    position: CreateUpdateOrderExecutionPositionDto,
    indexToInsert: number,
  ) => {
    const updatedPositions = orderExecution.orderExecutionPositions.map(
      (item, index) =>
        index + 1 >= indexToInsert ? { ...item, order: item.order + 1 } : item,
    );

    updatedPositions.splice(indexToInsert, 0, position);
    setPositions(updatedPositions);
    select.handleUnselectAll();
  };

  const clonePosition = (key: number) => {
    const foundPosition = orderExecution.orderExecutionPositions.find(
      ({ order }) => order === key,
    );

    if (foundPosition) {
      const newOrder = foundPosition.order + 1;
      insertPosition(
        { ...foundPosition, order: newOrder, amount: '0' },
        newOrder,
      );
    }
  };

  const mergePositions = (keys: number[]) => {
    setPositions(
      mergePositionsByKeys(orderExecution.orderExecutionPositions, keys, false),
    );
    select.handleUnselectAll();
  };

  const mergePositionsByActionGroup = (keys: number[]) => {
    let result = [...orderExecution.orderExecutionPositions];

    if (!keys.length) {
      keys = orderExecution.orderExecutionPositions.map(({ order }) => order);
    }

    const actionGroupsToMerge = uniq(
      keys
        .map(
          key =>
            orderExecution.orderExecutionPositions.find(
              ({ order }) => order === key,
            )?.actionGroup,
        )
        .filter(value => !!value),
    );

    actionGroupsToMerge.forEach(actionGroup => {
      const keysToMerge = result
        .filter(({ actionGroup: aG }) => aG === actionGroup)
        .map(({ order }) => order);

      result = mergePositionsByKeys(result, keysToMerge, true);
    });

    setPositions(result);
    select.handleUnselectAll();
  };

  useEffect(() => {
    if (prevDateRef.current !== orderExecution.date) {
      initPositions();
    }

    prevDateRef.current = orderExecution.date;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderExecution.date]);

  useEffect(() => {
    initPositions(orderExecution.orderExecutionPositions.length !== 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project && project.parent && project.company.client) {
      pok.estimateItems
        .findForOrderExecutionSum(
          project.parent.id,
          project.projectTeams?.map(({ teamId }) => teamId) || [],
          project.company?.client?.id,
          orderExecution.date,
        )
        .then(data => {
          setParentProjectPurchaseSum(
            mathUtils.add(
              ...data.map(({ purchaseNetTotal }) =>
                mathUtils.round(purchaseNetTotal),
              ),
            ),
          );
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderExecution.date]);

  return {
    positionChange,
    resetPositions: initPositions,
    clonePosition,
    mergePositions,
    mergePositionsByActionGroup,
    positionsSalesSum,
    showSumsDiscrepancyAlert:
      parentProjectPurchaseSum !== positionsSalesSum && !!project?.parent,
    isActionGroupAvailable: orderExecution.orderExecutionPositions.some(
      ({ actionGroup }) => !!actionGroup,
    ),
  };
}
