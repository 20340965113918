import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as rstm from 'react-simple-tree-menu';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { GetDictionaryTypeDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import {
  newDictionary,
  convert,
} from '../../../common/pokCore/contexts/DictionaryContext';
import * as DictionaryContext from '../../../common/pokCore/contexts/DictionaryContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import Button from '../../../common/components/Button/Button';

interface DictionaryDeleteModalProps {
  item: rstm.TreeMenuItem;
  onAction: (id?: string) => void;
  show?: boolean;
  onClose?: () => void;
}

export const DictionaryDeleteModal: React.FC<DictionaryDeleteModalProps> = ({
  item,
  onAction,
  show,
  onClose,
}) => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const [dictionary, setDictionary] = useState(newDictionary());
  const [dictionaryType, setDictionaryType] = useState<GetDictionaryTypeDto>();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    pok.dictionaries
      .getById(item.id)
      .then(dict => {
        setDictionary(convert(dict));
        setDictionaryType(dict.dictionaryType);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [item.dictionaryTypeId, item.id, pok, notifications]);

  const handleSave = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsLoading(true);
    DictionaryContext.validate(dictionary)
      .then(async status => {
        setValidationErrors(status.errors);
        if (status.valid) {
          setShowValidationErrors(false);
          try {
            await pok.dictionaries.changeToInactive(item.id);
            notifications.saveCompleted();
            onAction();
          } catch (response) {
            setValidationErrors(
              await responseErrors(response as ResponseError),
            );
            setShowValidationErrors(true);
          }
        } else {
          setShowValidationErrors(true);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      onHide={onClose}
      show={show}
      keyboard={true}
      centered={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Usuwanie ze słownika <b>{dictionaryType?.name}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Czy na pewno usunąć wartość: <b>{dictionary.value}</b>?
      </Modal.Body>
      <ValidationAlert
        show={showValidationErrors}
        errors={validationErrors}
        className="m-3"
      />
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={onClose}
          disabled={isLoading}
        >
          Zamknij
        </Button>
        <Button variant="danger" onClick={handleSave} isLoading={isLoading}>
          Usuń
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
