/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetExcelColumnsDto } from './GetExcelColumnsDto';
import {
    GetExcelColumnsDtoFromJSON,
    GetExcelColumnsDtoFromJSONTyped,
    GetExcelColumnsDtoToJSON,
} from './GetExcelColumnsDto';
import type { GetExcelConfigDto } from './GetExcelConfigDto';
import {
    GetExcelConfigDtoFromJSON,
    GetExcelConfigDtoFromJSONTyped,
    GetExcelConfigDtoToJSON,
} from './GetExcelConfigDto';
import type { GetExcelRowDto } from './GetExcelRowDto';
import {
    GetExcelRowDtoFromJSON,
    GetExcelRowDtoFromJSONTyped,
    GetExcelRowDtoToJSON,
} from './GetExcelRowDto';

/**
 * 
 * @export
 * @interface GetExcelDataDto
 */
export interface GetExcelDataDto {
    /**
     * 
     * @type {number}
     * @memberof GetExcelDataDto
     */
    okCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetExcelDataDto
     */
    errorCount: number;
    /**
     * 
     * @type {Array<GetExcelRowDto>}
     * @memberof GetExcelDataDto
     */
    excelRows: Array<GetExcelRowDto>;
    /**
     * 
     * @type {Array<GetExcelColumnsDto>}
     * @memberof GetExcelDataDto
     */
    columns?: Array<GetExcelColumnsDto>;
    /**
     * 
     * @type {string}
     * @memberof GetExcelDataDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelDataDto
     */
    projectMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelDataDto
     */
    projectName?: string;
    /**
     * 
     * @type {GetExcelConfigDto}
     * @memberof GetExcelDataDto
     */
    config: GetExcelConfigDto;
    /**
     * 
     * @type {Array<GetExcelColumnsDto>}
     * @memberof GetExcelDataDto
     */
    otherRequiredColumns?: Array<GetExcelColumnsDto>;
}

/**
 * Check if a given object implements the GetExcelDataDto interface.
 */
export function instanceOfGetExcelDataDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "okCount" in value;
    isInstance = isInstance && "errorCount" in value;
    isInstance = isInstance && "excelRows" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "config" in value;

    return isInstance;
}

export function GetExcelDataDtoFromJSON(json: any): GetExcelDataDto {
    return GetExcelDataDtoFromJSONTyped(json, false);
}

export function GetExcelDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExcelDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'okCount': json['okCount'],
        'errorCount': json['errorCount'],
        'excelRows': ((json['excelRows'] as Array<any>).map(GetExcelRowDtoFromJSON)),
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(GetExcelColumnsDtoFromJSON)),
        'fileName': json['fileName'],
        'projectMonth': !exists(json, 'projectMonth') ? undefined : json['projectMonth'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'config': GetExcelConfigDtoFromJSON(json['config']),
        'otherRequiredColumns': !exists(json, 'otherRequiredColumns') ? undefined : ((json['otherRequiredColumns'] as Array<any>).map(GetExcelColumnsDtoFromJSON)),
    };
}

export function GetExcelDataDtoToJSON(value?: GetExcelDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'okCount': value.okCount,
        'errorCount': value.errorCount,
        'excelRows': ((value.excelRows as Array<any>).map(GetExcelRowDtoToJSON)),
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(GetExcelColumnsDtoToJSON)),
        'fileName': value.fileName,
        'projectMonth': value.projectMonth,
        'projectName': value.projectName,
        'config': GetExcelConfigDtoToJSON(value.config),
        'otherRequiredColumns': value.otherRequiredColumns === undefined ? undefined : ((value.otherRequiredColumns as Array<any>).map(GetExcelColumnsDtoToJSON)),
    };
}

