import React from 'react';

import { PurchaseInvoiceView } from './PurchaseInvoiceView';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';

export const PurchaseInvoiceNotAssignedView: React.FC = () => {
  return (
    <PurchaseInvoiceView startingTab={PurchaseInvoiceTabsEnum.TEAM_PROJECTS} />
  );
};
