import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetBankAccountDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { InvoiceTypeEnumText } from '../../../common/pokCore/validation/schemas';

interface BankAccountSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const bankAccountForGrid = (bankAccount: GetBankAccountDto) => {
  return {
    key: bankAccount.id,
    values: {
      'bankAccount.systemNumber': bankAccount.systemNumber,
      'bankAccount.number': bankAccount.number,
      'company.shortname': bankAccount.company.shortname,
      invoice_type: InvoiceTypeEnumText[bankAccount.invoiceType],
      'bankAccount.currency': bankAccount.currency,
      'bankAccount.swiftBIC': bankAccount.swiftBIC,
      'bankAccount.bankName': bankAccount.bankName,
      'client.name': bankAccount.client?.name,
    },
    isInactive: !bankAccount.active,
  };
};

export const BankAccountSearch: React.FC<BankAccountSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'bankAccount.systemNumber' },
    { header: 'Numer konta bankowego', property: 'bankAccount.number' },
    { header: 'Spółka', property: 'company.shortname' },
    { header: 'Typ faktury', property: 'invoice_type' },
    { header: 'Waluta', property: 'bankAccount.currency' },
    { header: 'Swift BIC', property: 'bankAccount.swiftBIC' },
    { header: 'Nazwa banku', property: 'bankAccount.bankName' },
    { header: 'Kontrahent', property: 'client.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="bankAccount.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak rachunków bankowych w systemie."
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
