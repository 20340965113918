/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateProjectWithTeamDto
 */
export interface CreateUpdateProjectWithTeamDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    notes: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    purchaserId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    brandId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    companyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    delayVerification: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    delayMessage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    countryDictionaryId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    responsibleOwnerId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    purchaserDocNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    labelDictionaryId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    cancelVerification: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    cancelMessage: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    teamIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    mainTeamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProjectWithTeamDto
     */
    profileId?: string;
}

/**
 * Check if a given object implements the CreateUpdateProjectWithTeamDto interface.
 */
export function instanceOfCreateUpdateProjectWithTeamDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "purchaserId" in value;
    isInstance = isInstance && "brandId" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "delayVerification" in value;
    isInstance = isInstance && "delayMessage" in value;
    isInstance = isInstance && "countryDictionaryId" in value;
    isInstance = isInstance && "responsibleOwnerId" in value;
    isInstance = isInstance && "purchaserDocNumber" in value;
    isInstance = isInstance && "labelDictionaryId" in value;
    isInstance = isInstance && "cancelVerification" in value;
    isInstance = isInstance && "cancelMessage" in value;
    isInstance = isInstance && "teamIds" in value;

    return isInstance;
}

export function CreateUpdateProjectWithTeamDtoFromJSON(json: any): CreateUpdateProjectWithTeamDto {
    return CreateUpdateProjectWithTeamDtoFromJSONTyped(json, false);
}

export function CreateUpdateProjectWithTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateProjectWithTeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'name': json['name'],
        'number': json['number'],
        'notes': json['notes'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'purchaserId': json['purchaserId'],
        'brandId': json['brandId'],
        'parentId': json['parentId'],
        'companyId': json['companyId'],
        'active': json['active'],
        'delayVerification': json['delayVerification'],
        'delayMessage': json['delayMessage'],
        'countryDictionaryId': json['countryDictionaryId'],
        'responsibleOwnerId': json['responsibleOwnerId'],
        'purchaserDocNumber': json['purchaserDocNumber'],
        'labelDictionaryId': json['labelDictionaryId'],
        'cancelVerification': json['cancelVerification'],
        'cancelMessage': json['cancelMessage'],
        'teamIds': json['teamIds'],
        'mainTeamId': !exists(json, 'mainTeamId') ? undefined : json['mainTeamId'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
    };
}

export function CreateUpdateProjectWithTeamDtoToJSON(value?: CreateUpdateProjectWithTeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'name': value.name,
        'number': value.number,
        'notes': value.notes,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'purchaserId': value.purchaserId,
        'brandId': value.brandId,
        'parentId': value.parentId,
        'companyId': value.companyId,
        'active': value.active,
        'delayVerification': value.delayVerification,
        'delayMessage': value.delayMessage,
        'countryDictionaryId': value.countryDictionaryId,
        'responsibleOwnerId': value.responsibleOwnerId,
        'purchaserDocNumber': value.purchaserDocNumber,
        'labelDictionaryId': value.labelDictionaryId,
        'cancelVerification': value.cancelVerification,
        'cancelMessage': value.cancelMessage,
        'teamIds': value.teamIds,
        'mainTeamId': value.mainTeamId,
        'profileId': value.profileId,
    };
}

