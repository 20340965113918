/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';

/**
 * 
 * @export
 * @interface GetThreadDto
 */
export interface GetThreadDto {
    /**
     * 
     * @type {string}
     * @memberof GetThreadDto
     */
    id: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetThreadDto
     */
    sender: GetEmployeeDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetThreadDto
     */
    recipient: GetEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof GetThreadDto
     */
    subjectType: GetThreadDtoSubjectTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof GetThreadDto
     */
    subject: object;
    /**
     * 
     * @type {Date}
     * @memberof GetThreadDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetThreadDto
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof GetThreadDto
     */
    messagesCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadDto
     */
    newMessagesCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadDto
     */
    participatedCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetThreadDto
     */
    last: boolean;
}


/**
 * @export
 */
export const GetThreadDtoSubjectTypeEnum = {
    Project: 'Project',
    PurchaseInvoice: 'PurchaseInvoice',
    SaleInvoice: 'SaleInvoice',
    SpecialInvoicing: 'SpecialInvoicing'
} as const;
export type GetThreadDtoSubjectTypeEnum = typeof GetThreadDtoSubjectTypeEnum[keyof typeof GetThreadDtoSubjectTypeEnum];


/**
 * Check if a given object implements the GetThreadDto interface.
 */
export function instanceOfGetThreadDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sender" in value;
    isInstance = isInstance && "recipient" in value;
    isInstance = isInstance && "subjectType" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "messagesCount" in value;
    isInstance = isInstance && "newMessagesCount" in value;
    isInstance = isInstance && "participatedCount" in value;
    isInstance = isInstance && "last" in value;

    return isInstance;
}

export function GetThreadDtoFromJSON(json: any): GetThreadDto {
    return GetThreadDtoFromJSONTyped(json, false);
}

export function GetThreadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetThreadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sender': GetEmployeeDtoFromJSON(json['sender']),
        'recipient': GetEmployeeDtoFromJSON(json['recipient']),
        'subjectType': json['subjectType'],
        'subject': json['subject'],
        'date': (new Date(json['date'])),
        'content': json['content'],
        'messagesCount': json['messagesCount'],
        'newMessagesCount': json['newMessagesCount'],
        'participatedCount': json['participatedCount'],
        'last': json['last'],
    };
}

export function GetThreadDtoToJSON(value?: GetThreadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sender': GetEmployeeDtoToJSON(value.sender),
        'recipient': GetEmployeeDtoToJSON(value.recipient),
        'subjectType': value.subjectType,
        'subject': value.subject,
        'date': (value.date.toISOString()),
        'content': value.content,
        'messagesCount': value.messagesCount,
        'newMessagesCount': value.newMessagesCount,
        'participatedCount': value.participatedCount,
        'last': value.last,
    };
}

