import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsView: React.FC = () => {
  const nav = useNavigation();

  return (
    <Card>
      <TabsHistory activeKey="byPositions" id="reports" className="mb-3">
        <Tab eventKey="byPositions" title="Pozycje mediaplanów">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="Surówka"
              text="Rozbudowane dane z pozycji mediaplanów"
              footer="Pozycje mediaplanu"
              onClick={nav.reportEstimateItemsDataRaw}
            />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
