import { useEffect, useState } from 'react';

import { GetPurchaseInvoiceExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';

function useIsAuthorized(
  purchaseInvoice: GetPurchaseInvoiceExtendedDto | undefined,
): boolean {
  const [authorized, setAuthorized] = useState<boolean>(true);
  const pok = usePokCore();

  useEffect(() => {
    if (purchaseInvoice && purchaseInvoice?.companyId !== pok.getCompanyId()) {
      setAuthorized(false);
    } else {
      setAuthorized(true);
    }
  }, [pok, purchaseInvoice]);

  return authorized;
}

export default useIsAuthorized;
