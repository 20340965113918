import {
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';

export const getSavedItem = (
  item: CreateUpdatePurchaseInvoiceEstimateItemDto,
  savedPurchaseInvoiceEstimateItems?: GetPurchaseInvoiceEstimateItemDto[],
) =>
  savedPurchaseInvoiceEstimateItems?.find(
    ({ estimateItemId }) => estimateItemId === item.estimateItemId,
  );

export const getPurchaseInvoiceItem = (
  availableEstimateItemId: string,
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[],
) =>
  purchaseInvoiceEstimateItems.find(
    it => it.estimateItemId === availableEstimateItemId,
  ) || ({ amount: '0' } as CreateUpdatePurchaseInvoiceEstimateItemDto);

export const getNotAssignedAmount = (estimateItem: GetEstimateItemDto) =>
  mathUtils.subtract(
    mathUtils.round(estimateItem.purchaseNetTotal),
    mathUtils.round(estimateItem.purchaseInvoicesSum),
  ) || '0';

export interface SumData {
  purchaseNetTotalSum: number;
  notAssignedSum: number;
  amountSum: number;
  maxSum: number;
  unsettledAmount: number;
}

export const getSumData = (
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[],
  availableEstimateItems: GetEstimateItemDto[],
  filteredAvailableEstimateItems: GetEstimateItemDto[],
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto,
): SumData => {
  let purchaseNetTotalSum = 0;
  let notAssignedSum = 0;
  let amountSum = 0;
  let amountToExcludeFromMax = 0;

  for (const availableEstimateItem of filteredAvailableEstimateItems) {
    const notAssignedAmount = mathUtils.subtract(
      availableEstimateItem.purchaseNetTotal,
      availableEstimateItem.purchaseInvoicesSum,
    );

    if (availableEstimateItem) {
      purchaseNetTotalSum = mathUtils.add(
        purchaseNetTotalSum,
        availableEstimateItem.purchaseNetTotal,
      );
      notAssignedSum = mathUtils.add(notAssignedSum, notAssignedAmount);
    }
  }
  for (const availableEstimateItem of availableEstimateItems) {
    const item = purchaseInvoiceEstimateItems.find(
      ({ estimateItemId }) => estimateItemId === availableEstimateItem.id,
    );

    if (item) {
      amountSum = mathUtils.add(amountSum, item.amount);
    }
  }
  const availableEstimateItemsIds = availableEstimateItems.map(({ id }) => id);
  purchaseInvoiceEstimateItems.forEach(estimateItem => {
    if (!availableEstimateItemsIds.includes(estimateItem.estimateItemId)) {
      amountToExcludeFromMax = mathUtils.add(
        amountToExcludeFromMax,
        estimateItem.amount,
      );
    }
  });

  return {
    purchaseNetTotalSum,
    notAssignedSum,
    amountSum,
    unsettledAmount: mathUtils.subtract(
      +(extendedPurchaseInvoice?.amount || '0'),
      amountSum,
      amountToExcludeFromMax,
    ),
    maxSum: +(extendedPurchaseInvoice?.amount || '0'),
  };
};

export const checkAmountInfo = (
  max?: string | number,
  amount?: string | number,
) => {
  const amountDiff = mathUtils.subtract(max, amount);
  const info =
    'Suma kwot zapisanych przypisań, przekracza kwotę całkowitą faktury.';
  if (mathUtils.parse(max) * mathUtils.parse(amountDiff) < 0) {
    return [info];
  }

  return [];
};
