import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { OrdersExecutionSentTab } from '../../components/OrdersExecution/OrdersExecutionSentTab';
import { OrdersExecutionWaitingTab } from '../../components/OrdersExecution/OrdersExecutionWaitingTab';
import { OrdersExecutionCompletedTab } from '../../components/OrdersExecution/OrdersExecutionCompletedTab';

export enum OrdersExecutionTabs {
  SENT = 'Wysłane',
  WAITING = 'Otrzymane oczekujące',
  COMPLETED = 'Otrzymane obsłużone',
}

export const OrdersExecutionView: React.FC = () => {
  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Realizacje zamówień</Card.Title>
        <TabsHistory
          activeKey={OrdersExecutionTabs.WAITING}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={OrdersExecutionTabs.WAITING}
            title={OrdersExecutionTabs.WAITING}
            className="m-3"
          >
            <OrdersExecutionWaitingTab />
          </Tab>
          <Tab
            eventKey={OrdersExecutionTabs.COMPLETED}
            title={OrdersExecutionTabs.COMPLETED}
            className="m-3"
          >
            <OrdersExecutionCompletedTab />
          </Tab>
          <Tab
            eventKey={OrdersExecutionTabs.SENT}
            title={OrdersExecutionTabs.SENT}
            className="m-3"
          >
            <OrdersExecutionSentTab />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
