import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { downloadXML } from './downloadXML';

export const DownloadXMLLink = (props: { packageXMLId: string }) => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const handleClick = () => {
    pok.packagesXML
      .getXMLFromPackageXML(props.packageXMLId)
      .then(async xmlData => {
        const packageXML = await pok.packagesXML.getById(props.packageXMLId);
        await downloadXML(packageXML, xmlData);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }}>
            Paczka XML z fakturami
          </Tooltip>
        }
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Download />
        </Button>
      </OverlayTrigger>
    </>
  );
};
