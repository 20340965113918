import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export default (props: {
  className?: string;
  iconSize?: number;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  headerText?: string;
  bodyText?: string;
  icon?: Icon.Icon;
}) => {
  const SelectedIcon = props.icon ?? Icon.ExclamationCircle;
  return (
    <OverlayTrigger
      placement={props.placement ?? 'top'}
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" className="text-center">
            {props.headerText}
          </Popover.Header>
          <Popover.Body className="text-center">{props.bodyText}</Popover.Body>
        </Popover>
      }
    >
      <SelectedIcon
        className={props.className ?? ''}
        size={props.iconSize ?? 18}
      />
    </OverlayTrigger>
  );
};
