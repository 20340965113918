/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';

/**
 * 
 * @export
 * @interface GetPackageInvoicesXMLDto
 */
export interface GetPackageInvoicesXMLDto {
    /**
     * 
     * @type {string}
     * @memberof GetPackageInvoicesXMLDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetPackageInvoicesXMLDto
     */
    systemNumber: number;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPackageInvoicesXMLDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetPackageInvoicesXMLDto
     */
    creationTs: Date;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetPackageInvoicesXMLDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPackageInvoicesXMLDto
     */
    purchaseInvoices: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPackageInvoicesXMLDto
     */
    saleInvoices: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetPackageInvoicesXMLDto
     */
    invoiceType?: GetPackageInvoicesXMLDtoInvoiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPackageInvoicesXMLDto
     */
    packageType?: GetPackageInvoicesXMLDtoPackageTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetPackageInvoicesXMLDto
     */
    isCorrection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPackageInvoicesXMLDto
     */
    isRepeat?: boolean;
}


/**
 * @export
 */
export const GetPackageInvoicesXMLDtoInvoiceTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type GetPackageInvoicesXMLDtoInvoiceTypeEnum = typeof GetPackageInvoicesXMLDtoInvoiceTypeEnum[keyof typeof GetPackageInvoicesXMLDtoInvoiceTypeEnum];

/**
 * @export
 */
export const GetPackageInvoicesXMLDtoPackageTypeEnum = {
    Purchase: 'Purchase',
    Sale: 'Sale'
} as const;
export type GetPackageInvoicesXMLDtoPackageTypeEnum = typeof GetPackageInvoicesXMLDtoPackageTypeEnum[keyof typeof GetPackageInvoicesXMLDtoPackageTypeEnum];


/**
 * Check if a given object implements the GetPackageInvoicesXMLDto interface.
 */
export function instanceOfGetPackageInvoicesXMLDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTs" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "purchaseInvoices" in value;
    isInstance = isInstance && "saleInvoices" in value;

    return isInstance;
}

export function GetPackageInvoicesXMLDtoFromJSON(json: any): GetPackageInvoicesXMLDto {
    return GetPackageInvoicesXMLDtoFromJSONTyped(json, false);
}

export function GetPackageInvoicesXMLDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPackageInvoicesXMLDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'creationTs': (new Date(json['creationTs'])),
        'company': GetCompanyDtoFromJSON(json['company']),
        'purchaseInvoices': json['purchaseInvoices'],
        'saleInvoices': json['saleInvoices'],
        'invoiceType': !exists(json, 'invoiceType') ? undefined : json['invoiceType'],
        'packageType': !exists(json, 'packageType') ? undefined : json['packageType'],
        'isCorrection': !exists(json, 'isCorrection') ? undefined : json['isCorrection'],
        'isRepeat': !exists(json, 'isRepeat') ? undefined : json['isRepeat'],
    };
}

export function GetPackageInvoicesXMLDtoToJSON(value?: GetPackageInvoicesXMLDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'creator': GetEmployeeDtoToJSON(value.creator),
        'creationTs': (value.creationTs.toISOString()),
        'company': GetCompanyDtoToJSON(value.company),
        'purchaseInvoices': value.purchaseInvoices,
        'saleInvoices': value.saleInvoices,
        'invoiceType': value.invoiceType,
        'packageType': value.packageType,
        'isCorrection': value.isCorrection,
        'isRepeat': value.isRepeat,
    };
}

