import saveAs from 'file-saver';

import { GetPackageInvoicesXMLDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  InvoiceTypeEnum,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';

export const downloadZIP = async (
  packageXML: GetPackageInvoicesXMLDto,
  zip: object,
) => {
  let fileName =
    packageXML.packageType === PackageTypeEnum.Purchase
      ? 'Eksport_FVZ'
      : 'Eksport_FVS';

  fileName =
    packageXML.invoiceType === InvoiceTypeEnum.Domestic
      ? `${fileName}_Krajowe`
      : `${fileName}_Zagraniczne`;

  fileName = `${fileName}(${packageXML.systemNumber})_${momencik(
    packageXML.creationTs,
    'YYYY-MM-DD_HH-mm-ss',
  )}.zip`;

  if (zip) {
    const blob = new Blob([Buffer.from(zip as Buffer)], {
      type: 'application/zip',
    });
    saveAs(blob, fileName);
  }
};
