import { useEffect, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetSpecialInvoicingDto } from '../../../common/pokCore/autogenerated/pokApiClient';

function useIsAuthorized(
  specialInvoicing: GetSpecialInvoicingDto | undefined,
): boolean {
  const [authorized, setAuthorized] = useState<boolean>(true);
  const pok = usePokCore();

  useEffect(() => {
    if (
      specialInvoicing &&
      specialInvoicing.company?.id !== pok.getCompanyId()
    ) {
      setAuthorized(false);
    } else {
      setAuthorized(true);
    }
  }, [pok, specialInvoicing]);

  return authorized;
}

export default useIsAuthorized;
