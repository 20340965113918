/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateApplicationUnblockDto
 */
export interface CreateUpdateApplicationUnblockDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateApplicationUnblockDto
     */
    estimateByMonthId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateApplicationUnblockDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateApplicationUnblockDto
     */
    type: CreateUpdateApplicationUnblockDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateApplicationUnblockDto
     */
    salesCorrect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateApplicationUnblockDto
     */
    inDocumentation?: boolean;
}


/**
 * @export
 */
export const CreateUpdateApplicationUnblockDtoTypeEnum = {
    Sales: 'Sales',
    Purchase: 'Purchase'
} as const;
export type CreateUpdateApplicationUnblockDtoTypeEnum = typeof CreateUpdateApplicationUnblockDtoTypeEnum[keyof typeof CreateUpdateApplicationUnblockDtoTypeEnum];


/**
 * Check if a given object implements the CreateUpdateApplicationUnblockDto interface.
 */
export function instanceOfCreateUpdateApplicationUnblockDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimateByMonthId" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CreateUpdateApplicationUnblockDtoFromJSON(json: any): CreateUpdateApplicationUnblockDto {
    return CreateUpdateApplicationUnblockDtoFromJSONTyped(json, false);
}

export function CreateUpdateApplicationUnblockDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateApplicationUnblockDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimateByMonthId': json['estimateByMonthId'],
        'description': json['description'],
        'type': json['type'],
        'salesCorrect': !exists(json, 'salesCorrect') ? undefined : json['salesCorrect'],
        'inDocumentation': !exists(json, 'inDocumentation') ? undefined : json['inDocumentation'],
    };
}

export function CreateUpdateApplicationUnblockDtoToJSON(value?: CreateUpdateApplicationUnblockDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimateByMonthId': value.estimateByMonthId,
        'description': value.description,
        'type': value.type,
        'salesCorrect': value.salesCorrect,
        'inDocumentation': value.inDocumentation,
    };
}

