/*eslint max-lines-per-function: ["error", 200]*/
import { useSearchParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  CreateUpdateSalesInvoiceDto,
  GetDictionaryDto,
  GetExtendedSalesInvoiceDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { convert } from '../../../common/pokCore/contexts/SalesInvoicesContext';
import { useNavigation } from '../../../common/navigation';
import mathUtils from '../../../utils/mathUtils';

export interface AttachmentId {
  id: string;
  projectId: string;
}

export interface UseSalesInvoiceDataReturn {
  error: string;
  convertedSalesInvoice: CreateUpdateSalesInvoiceDto;
  handleRefresh: () => void;
  handleRemove: () => void;
  projects: GetProjectDto[];
  setProjects: Dispatch<SetStateAction<GetProjectDto[]>>;
  salesInvoice?: GetExtendedSalesInvoiceDto;
  attachmentIds?: AttachmentId[];
  isReady: boolean;
  isLoading: boolean;
  refresh: boolean;
  parentAmount?: string;
}

function useSalesInvoiceData(): UseSalesInvoiceDataReturn {
  const [salesInvoice, setSalesInvoice] =
    useState<GetExtendedSalesInvoiceDto>();
  const [error, setError] = useState('');
  const [projects, setProjects] = useState<GetProjectDto[]>([]);
  const [isInternalClient, setIsInternalClient] = useState<boolean>();
  const [gtuDefaultCodeId, setGtuDefaultId] = useState<string | undefined>('');
  const [payableDaysList, setPayableDaysList] = useState<GetDictionaryDto[]>(
    [],
  );
  const [parentAmount, setParentAmount] = useState<string>('0');
  const [documentDefaultIds, setDocumentDefaultIds] = useState<string[]>();
  const [refresh, setRefresh] = useState(false);
  const [attachmentIds, setAttachmentIds] = useState<AttachmentId[]>();
  const [isLoading, setIsLoading] = useState(false);
  const prevProjects = useRef(projects);

  const nav = useNavigation();
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('menu');

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const salesInvoiceId = searchParams.get('salesInvoiceId');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleRemove = () => {
    if (salesInvoice) {
      setIsLoading(true);
      pok.salesInvoices
        .deactivate(salesInvoice.id)
        .then(() => {
          notifications.saveCompleted();
          nav.salesInvoices();
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (projectId) {
      pok.projects
        .getWithParent(projectId)
        .then(data => {
          setProjects([data]);
        })
        .catch(() => setError(t(tk.menu.projectNotFound)));
    } else if (!salesInvoiceId) {
      setError(t(tk.menu.projectNotFound));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pok.salesInvoices, pok.projects, projectId, salesInvoiceId]);

  useEffect(() => {
    if (projects.length && !salesInvoice) {
      pok.companies
        .getCompanyIdByClientId(projects?.[0].purchaser.client.id)
        .then(id => setIsInternalClient(!!id))
        .catch(() => setError('Coś poszło nie tak. Spróbuj ponownie'));
    }
  }, [pok.companies, projects, salesInvoice]);

  useEffect(() => {
    if (salesInvoiceId) {
      pok.salesInvoices
        .getById(salesInvoiceId)
        .then(data => {
          setSalesInvoice(data);
          if (data.salesInvoiceProjects?.length) {
            setProjects(data.salesInvoiceProjects.map(data => data.project));
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesInvoiceId, refresh]);

  useEffect(() => {
    pok.dictionaries
      .getByDictionaryType('Kody GTU')
      .then(values => {
        const code = values?.find(({ shortname }) => shortname === 'GTU_12');
        setGtuDefaultId(code?.id);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    pok.dictionaries
      .getByDictionaryType('Kody dokumentu')
      .then(values => {
        const code = values?.find(({ shortname }) => shortname === 'TP');
        if (code) {
          setDocumentDefaultIds([code.id]);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    pok.dictionaries
      .getByDictionaryType('Termin płatności faktury')
      .then(setPayableDaysList)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [notifications, pok.dictionaries]);

  useEffect(() => {
    if (projects && projects !== prevProjects.current) {
      Promise.all(
        projects.map(async project => {
          const attachments =
            await pok.attachments.getLastForProjectByShortname(
              'IF',
              project.id,
            );

          return {
            id: attachments[0]?.id,
            projectId: project.id,
          };
        }),
      )
        .then(data => {
          setAttachmentIds(data.filter(({ id }) => id));
          prevProjects.current = projects;
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [notifications, pok.attachments, projects]);

  useEffect(() => {
    if (salesInvoice?.parent?.id) {
      pok.salesInvoices
        .getById(salesInvoice?.parent?.id)
        .then(parent =>
          setParentAmount(
            (
              parent.salesInvoicePositions
                ?.map(pos => Number(pos.amount))
                .reduce((a, b) => mathUtils.add(a, b)) || 0
            ).toString(),
          ),
        )
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [salesInvoice, notifications, pok.salesInvoices]);

  return {
    error,
    convertedSalesInvoice: convert(
      salesInvoice,
      projects?.[0],
      isInternalClient,
      gtuDefaultCodeId,
      documentDefaultIds,
      payableDaysList,
    ),
    handleRefresh,
    handleRemove,
    salesInvoice,
    projects,
    setProjects,
    attachmentIds,
    refresh,
    isLoading: (!!salesInvoiceId && !salesInvoice) || isLoading,
    isReady:
      gtuDefaultCodeId !== '' &&
      (salesInvoice !== undefined || isInternalClient !== undefined) &&
      (salesInvoice !== undefined || documentDefaultIds !== undefined),
    parentAmount,
  };
}

export default useSalesInvoiceData;
