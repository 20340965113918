import { FilterYearReportData } from '../../../pok/components/Reports/FilterYear';
import { DefaultApi, ReportType } from '../autogenerated/pokApiClient';

export interface ReportContextInterface {
  reportEstimateItemsRawData: (
    filter: FilterYearReportData,
  ) => Promise<ReportType>;

  reportProjectByMonthRawData: (
    filter: FilterYearReportData,
  ) => Promise<ReportType>;

  reportFVSByFilterRawData: (
    filter: FilterYearReportData,
  ) => Promise<ReportType>;

  reportFVZByFilterRawData: (
    filter: FilterYearReportData,
  ) => Promise<ReportType>;

  reportFVZControllingByFilterRawData: (
    filter: FilterYearReportData,
  ) => Promise<ReportType>;

  reportFVSControllingByFilterRawData: (
    filter: FilterYearReportData,
  ) => Promise<ReportType>;

  getReportForDocumentControl: (
    budgetId?: string,
    projectId?: string,
  ) => Promise<ReportType>;
}

export const ReportContext = (api: DefaultApi) => {
  return {
    reportEstimateItemsRawData: (filter: FilterYearReportData) =>
      api.reportControllerReportEstimateItemsRawData({ ...filter }),

    reportProjectByMonthRawData: (filter: FilterYearReportData) =>
      api.reportControllerReportProjectByMonthRawData({ ...filter }),

    reportFVSByFilterRawData: (filter: FilterYearReportData) =>
      api.reportControllerReportFVSByFilterRawData({ ...filter }),

    reportFVZByFilterRawData: (filter: FilterYearReportData) =>
      api.reportControllerReportFVZByFilterRawData({ ...filter }),

    reportFVZControllingByFilterRawData: (filter: FilterYearReportData) =>
      api.reportControllerReportFVZControllingByFilterRawData({ ...filter }),

    reportFVSControllingByFilterRawData: (filter: FilterYearReportData) =>
      api.reportControllerReportFVSControllingByFilterRawData({ ...filter }),

    getReportForDocumentControl: (budgetId?: string, projectId?: string) =>
      api.reportControllerReportForDocumentControl(budgetId, projectId),
  };
};
