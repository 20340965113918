import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';
import { Option } from '../Selects/Selector';
import ProfileSelector from '../Selects/ProfileSelector';
import { usePokCore } from '../../../common/hooks/usePokCore';

import { ApplicationEditorBasicSpecificFormFieldsBrand } from './ApplicationEditorBasicSpecificFormFieldsBrand';
import { ApplicationEditorBasicSpecificFormFieldsPermission } from './ApplicationEditorBasicSpecificFormFieldsPermission';

interface ApplicationEditorBasicSpecificFormFieldsProps {
  application: UpdateAdminApplicationDto;
  show?: boolean;
  isReadOnly?: boolean;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

const getOptionValue = (
  options: Option | MultiValue<Option> | null | undefined,
) => (options ? (options as Option).value : undefined);

export const ApplicationEditorBasicSpecificFormFields: React.FC<
  ApplicationEditorBasicSpecificFormFieldsProps
> = props => {
  const [companyIds, setCompanyIds] = useState<string[]>([
    props.application.companyId,
  ]);
  const [teamIds, setTeamIds] = useState<string[]>();
  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };
  const pok = usePokCore();

  useEffect(() => {
    setCompanyIds([props.application.companyId]);
    if (props.application.teamId) {
      setTeamIds([props.application.teamId]);
    } else {
      setTeamIds(undefined);
    }
  }, [props.application.companyId, props.application.teamId]);

  return (
    <>
      {props.application.applicationType === ApplicationTypeEnum.Purchaser && (
        <FormRow controlId="applicationName" label="Nazwa zamawiającego">
          <Form.Control
            type="text"
            value={props.application.elementName ?? ''}
            onChange={e => propertyChange({ elementName: e.target.value })}
            disabled={props.isReadOnly}
          />
        </FormRow>
      )}
      {props.application.applicationType === ApplicationTypeEnum.Position && (
        <>
          <FormRow controlId="applicationName" label="Nazwa pozycji">
            <Form.Control
              type="text"
              value={props.application.elementName ?? ''}
              onChange={e => propertyChange({ elementName: e.target.value })}
              disabled={props.isReadOnly}
            />
          </FormRow>
          <FormRow controlId="profile" label="Profil">
            <ProfileSelector
              provider={pok.profiles.getAllByCompanyIdsTeamIdsAuth}
              isMulti={false}
              value={props.application.profileId || undefined}
              companyIds={companyIds}
              teamIds={teamIds}
              onChange={options => {
                propertyChange({
                  profileId: getOptionValue(options),
                });
              }}
              readOnly={props.isReadOnly}
            />
          </FormRow>
        </>
      )}
      {props.application.applicationType ===
        ApplicationTypeEnum.DictionaryValue && (
        <FormRow controlId="applicationName" label="Nazwa wartości">
          <Form.Control
            type="text"
            value={props.application.elementName ?? ''}
            onChange={e => propertyChange({ elementName: e.target.value })}
            disabled={props.isReadOnly}
          />
        </FormRow>
      )}

      <ApplicationEditorBasicSpecificFormFieldsBrand
        application={props.application}
        propertyChange={propertyChange}
        isReadOnly={props.isReadOnly}
      />
      <ApplicationEditorBasicSpecificFormFieldsPermission
        application={props.application}
        propertyChange={propertyChange}
        isReadOnly={props.isReadOnly}
      />
    </>
  );
};
