import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';

import { Option, Selector } from './Selector';

export interface ProjectExcelConfigSelectorProps {
  onChange?: (option: Option) => void;
  value?: string;
}

export default (props: ProjectExcelConfigSelectorProps) => {
  const pok = usePokCore();
  const provider = async (text: string) => {
    const configurations = await pok.projectExcelConfigs.getOptions(text);
    return configurations;
  };

  return (
    <Selector
      creatable={false}
      provider={provider}
      onChange={option => props.onChange && props.onChange(option as Option)}
      value={props.value}
    />
  );
};
