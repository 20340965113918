/*eslint max-lines-per-function: ["error", 200]*/
/*eslint complexity: ["error", 12]*/
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import { Button, Col, Row } from 'react-bootstrap';

import { AttachmentViewTypes } from '../Attachments/AttachmentViewTypes';
import useIsEditAttachmentAllowedProjects from '../Attachments/useIsEditAttachmentAllowedProjects';

import { AssociateDocuments } from './AssociateDocumentsTypes';

export default <GetDto extends { id: string }, CreateUpdateDto>(props: {
  name: string;
  createModalType: React.ElementType;
  presentModalType: React.ElementType;
  gridType?: React.ElementType;
  docs: AssociateDocuments<GetDto, CreateUpdateDto>;
  companyId?: string;
  className?: string;
  readonly?: boolean;
  align?: 'start' | 'end';
  onRefresh?: () => void;
  withCalculator?: boolean;
  type?: AttachmentViewTypes;
  dictionaryId?: string;
  isDropdownButton?: boolean;
  projectId?: string;
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPresentModal, setShowPresentModal] = useState(false);
  const [presentedDocument, setPresentedDocument] = useState<GetDto>();
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const isEditAllowed = useIsEditAttachmentAllowedProjects(
    props.type,
    presentedDocument,
  );

  const createButtonText = 'Dodaj...';
  const showCreateButton = !props.readonly;

  const createDocument = async (doc: CreateUpdateDto) => {
    setIsLoadingSave(true);
    await props.docs.add(doc);
    setIsLoadingSave(false);
    setShowCreateModal(false);
    props.onRefresh && props.onRefresh();
  };

  const deleteDocument = async (id: string) => {
    setIsLoadingDelete(true);
    await props.docs.delete(id);
    setIsLoadingDelete(false);
    setShowPresentModal(false);
    props.onRefresh && props.onRefresh();
  };

  const updateDocument = async (id: string, doc: CreateUpdateDto) => {
    if (props.docs.update) {
      setIsLoadingSave(true);
      await props.docs.update(id, doc);
      setIsLoadingSave(false);
      setShowPresentModal(false);
      props.onRefresh && props.onRefresh();
    }
  };

  const action = (handler: () => void) => {
    return {
      onClick: handler,
      onKeyPress: (event: { key: string }) => {
        if (event.key === ' ' || event.key === 'Enter') {
          handler();
        }
      },
    };
  };

  useEffect(() => {
    if (presentedDocument) {
      const refreshed = props.docs.documents.find(
        d => d.id === presentedDocument.id,
      );
      if (refreshed) {
        setPresentedDocument(refreshed);
      }
    }
  }, [props.docs, presentedDocument]);

  return (
    <>
      <Row>
        <Col
          md={props.withCalculator ? 6 : 12}
          className={[
            'd-grid',
            props.gridType
              ? 'col-sm-12 justify-content-start'
              : 'd-grid justify-content-end float-end col-md-3 dropdown',
          ].join(' ')}
        >
          {props.isDropdownButton ? (
            <Dropdown
              align={props.align}
              className={[
                'd-grid',
                props.gridType
                  ? 'col-sm-12 justify-content-start'
                  : 'd-grid  float-end col-md-3',
              ].join(' ')}
            >
              <Dropdown.Toggle
                variant="outline-primary"
                disabled={props.readonly && props.docs.documents.length === 0}
              >
                {props.name}{' '}
                {props.docs.documents.length > 0 && (
                  <Badge bg="primary">{props.docs.documents.length}</Badge>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {showCreateButton && (
                  <>
                    <Dropdown.Item {...action(() => setShowCreateModal(true))}>
                      {createButtonText}
                    </Dropdown.Item>
                    {props.docs.documents.length > 0 && <Dropdown.Divider />}
                  </>
                )}
                {props.docs.documents.map(doc => (
                  <Dropdown.Item
                    key={doc.id}
                    {...action(() => {
                      setPresentedDocument(doc);
                      setShowPresentModal(true);
                    })}
                    className="text-wrap max-width-33vw"
                  >
                    {props.docs.labelGenerator(doc)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              variant="outline-primary"
              onClick={() => setShowCreateModal(true)}
            >
              {createButtonText}
            </Button>
          )}
        </Col>
        {props.withCalculator && (
          <Col md={6} className="d-flex justify-content-end align-items-center">
            <a
              href="https://kalkulator-walut.groupone.pl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kalkulator walut
            </a>
          </Col>
        )}
      </Row>
      <props.createModalType
        show={showCreateModal}
        newAssociate={props.docs.new}
        onGet={props.docs.get}
        onClose={() => setShowCreateModal(false)}
        onCreate={createDocument}
        validate={props.docs.validate}
        companyId={props.companyId}
        type={props.type}
        dictionaryId={props.dictionaryId}
        isLoading={isLoadingSave}
      />
      <props.presentModalType
        show={showPresentModal}
        readonly={props.readonly || !isEditAllowed}
        document={presentedDocument}
        validate={props.docs.validate}
        onGet={props.docs.get}
        onGetLastEditor={props.docs.getLastEditor}
        onUpdate={updateDocument}
        onDelete={deleteDocument}
        onClose={() => {
          setShowPresentModal(false);
          setPresentedDocument(undefined);
        }}
        companyId={props.companyId}
        type={props.type}
        isLoadingSave={isLoadingSave}
        isLoadingDelete={isLoadingDelete}
      />
      {props.gridType && (
        <props.gridType
          documents={props.docs.documents}
          readOnly={props.readonly}
          onClick={(doc: GetDto) => {
            setPresentedDocument(doc);
            setShowPresentModal(true);
          }}
          onDelete={deleteDocument}
          type={props.type}
        />
      )}
    </>
  );
};
