import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';

import { Selector, Option } from './Selector';

export interface PurchaserSelectorProps {
  value?: string;
  teamIds?: string[];
  budgetId?: string;
  readOnly?: boolean;
  className?: string;
  clearable?: boolean;
  role: string;
  parentProjectId?: string;
  onChange?: (option: Option) => void;
}

export default (props: PurchaserSelectorProps) => {
  const pok = usePokCore();

  return (
    <Selector
      uniqueKey={props.teamIds?.join('_')}
      className={props.className}
      readOnly={props.readOnly}
      provider={text => {
        if (props.role === PermissionsEnum.Projects) {
          if (props.budgetId) {
            return pok.purchasers.getOptionsByTeamIdsBudgetIdAndName(
              props.teamIds || [],
              props.budgetId,
              text,
              props.parentProjectId,
            );
          }

          return pok.purchasers.getOptionsByTeamIdsAndName(
            props.teamIds || [],
            text,
            props.parentProjectId,
          );
        } else {
          return pok.purchasers.getOptionsByTeamIdsAndNameForRoleWithoutTeams(
            props.teamIds || [],
            text,
          );
        }
      }}
      creatable={false}
      clearable={props.clearable}
      value={props.value}
      deletedValueName={id => pok.purchasers.getOptionById(id)}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
