import React, { useState } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';

import {
  CreateUpdateOrderExecutionDto,
  GetOrderExecutionDtoStatusEnum,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';

import { OrderExecutionBasicData } from './OrderExecutionBasicData';
import { OrderExecutionPositions } from './OrderExecutionPositions';
import { OrderExecutionEditorTabs } from './OrderExecutionEditorTabs';

interface OrderExecutionFormProps {
  id?: string;
  orderExecution: CreateUpdateOrderExecutionDto;
  propertyChange?: (obj: Partial<CreateUpdateOrderExecutionDto>) => void;
  emails: string[];
  setEmails?: (emails: string[]) => void;
  project?: GetProjectDto;
  resultProject?: GetProjectDto;
  readOnly?: boolean;
  disableSumsDiscrepancyAlert?: boolean;
  status?: GetOrderExecutionDtoStatusEnum;
  rejectedNote?: string;
  isDecisionView?: boolean;
  purchaseSum?: number;
}

export const OrderExecutionForm: React.FC<OrderExecutionFormProps> = props => {
  const [activeTab, setActiveTab] = useState(
    props.id
      ? OrderExecutionEditorTabs.POSITIONS
      : OrderExecutionEditorTabs.BASIC_DATA,
  );
  const [showSumsDiscrepancyAlert, setShowSumsDiscrepancyAlert] =
    useState(false);

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onSelect={tab => setActiveTab(tab as OrderExecutionEditorTabs)}
        className="mt-3"
      >
        <Tab
          eventKey={OrderExecutionEditorTabs.BASIC_DATA}
          title={OrderExecutionEditorTabs.BASIC_DATA}
          className="m-3"
        >
          <OrderExecutionBasicData {...props} />
        </Tab>
        <Tab
          eventKey={OrderExecutionEditorTabs.POSITIONS}
          title={OrderExecutionEditorTabs.POSITIONS}
          className="m-3"
        >
          <OrderExecutionPositions
            {...props}
            setShowSumsDiscrepancyAlert={setShowSumsDiscrepancyAlert}
          />
        </Tab>
      </Tabs>
      {showSumsDiscrepancyAlert && !props.disableSumsDiscrepancyAlert && (
        <Alert variant="warning" className="mt-3">
          Łączna kwota zakupu z kampanii zlecającej, jest różna od kwoty
          sprzedaży.
        </Alert>
      )}
    </>
  );
};
