import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  CloneProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { validateCloneProject } from '../../../common/pokCore/contexts/ProjectContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';

import { CloneProjectForm } from './CloneProjectForm';

interface CloneIconProps {
  active: boolean;
  projectId: string;
  projectName: string;
  projectStartDate: Date;
  enableClone?: boolean;
}

export const CloneIcon: React.FC<CloneIconProps> = props => {
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async (cloneProject: CloneProjectDto) => {
    const status = await validateCloneProject(cloneProject);
    if (status.valid) {
      try {
        const newProj = await pok.projects.clone(cloneProject);
        setShow(false);
        return {
          saved: true,
          errors: [],
          newProjectId: newProj.id,
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {props.active && props.enableClone && (
          <OverlayTrigger
            overlay={<Tooltip>Klonowanie {t(tk.menu.genitiveProject)}</Tooltip>}
          >
            <Button
              onClick={e => {
                e.stopPropagation();
                setShow(true);
              }}
              variant="btn-info"
              className="px-2"
            >
              <Icon.Subtract className="icon-blue" size={16} />
            </Button>
          </OverlayTrigger>
        )}
      </div>
      {show && (
        <CloneProjectForm
          projectId={props.projectId}
          projectName={props.projectName}
          show={show}
          projectStartDate={props.projectStartDate}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};
