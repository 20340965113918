import React from 'react';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';

import { Option, Selector } from './Selector';

export interface TeamAllSelectorProps {
  readOnly?: boolean;
  value?: string | string[];
  onChange?: (option: Option | MultiValue<Option> | null | undefined) => void;
}

export default (props: TeamAllSelectorProps) => {
  const pok = usePokCore();
  const provider = async (text: string) => {
    return pok.teams.getAllActive(text);
  };

  return (
    <Selector
      uniqueKey={'teams-' + JSON.stringify(props.value)}
      value={props.value}
      readOnly={props.readOnly}
      isMulti={true}
      creatable={false}
      provider={provider}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
