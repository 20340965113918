import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  ResponseError,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import Button from '../../../common/components/Button/Button';

import { ApplicationEditorFormFields } from './ApplicationEditorFormFields';
import { ApplicationAdminEditorStatus } from './ApplicationAdminEditorStatus';

interface ApplicationEditorProps {
  id?: string;
  application: UpdateAdminApplicationDto;
  purchaserName?: string;
  positionName?: string;
  brandName?: string;
  author?: string;
  createdDate?: Date | null;
  handleClose?: () => void;
  show?: boolean;
  isReadOnly?: boolean;
  onCompleted: (
    client: UpdateAdminApplicationDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ApplicationEditor: React.FC<ApplicationEditorProps> = props => {
  const [application, setApplication] = useState(props.application);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [modalSize, setModalSize] = useState<'lg' | 'sm' | 'xl' | undefined>(
    'sm',
  );
  const [formClassName, setFormClassName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();

  const createApplication = async () => {
    setIsLoading(true);
    props
      .onCompleted(application, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    setApplication({ ...application, ...obj });
  };

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setApplication(props.application);
    }
  }, [props.application, props.show]);

  useEffect(() => {
    const show =
      application.applicationType === ApplicationTypeEnum.Permission ||
      application.applicationType === ApplicationTypeEnum.Position ||
      application.applicationType === ApplicationTypeEnum.Purchaser;
    setModalSize(show ? 'xl' : 'lg');
    setFormClassName(show ? 'd-flex flex-wrap gap-1 mb-3' : '');
  }, [application.applicationType]);

  const findByNip = async (nip?: string) => {
    if (!nip) {
      setSaveErrors(['Proszę wprowadzić numer NIP.']);
      setShowSaveErrors(true);
      return;
    }
    try {
      const nipClient = await pok.clients.getByNip(nip.trim());
      if (nipClient) {
        setShowSaveErrors(false);
        setApplication({
          ...application,
          name: nipClient.name || '',
          postcode: nipClient.postcode || '',
          place: nipClient.place || '',
          street: nipClient.street || '',
          numbers: nipClient.numbers || '',
          flat: nipClient.flat || '',
        });
      }
    } catch (response) {
      const errors = await responseErrors(response as ResponseError);
      setSaveErrors(errors);
      setShowSaveErrors(true);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size={modalSize}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `Edycja wniosku z ${momencik(props.createdDate)}`
              : 'Dodawanie nowego wniosku'}
            {props.id && <small> (autor wniosku: {props.author})</small>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={formClassName}>
            <ApplicationEditorFormFields
              application={application}
              isReadOnly={props.isReadOnly}
              propertyChange={propertyChange}
              findByNip={findByNip}
              show={props.show}
            />
            {props.application.applicationStatus &&
              props.application.applicationStatus !==
                ApplicationStatusEnum.New && (
                <ApplicationAdminEditorStatus
                  application={application}
                  purchaserName={props.purchaserName}
                  positionName={props.positionName}
                  brandName={props.brandName}
                  isReadOnly={true}
                />
              )}
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.applications.getLastEditor}
            />
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button
            variant="primary"
            onClick={createApplication}
            disabled={props.isReadOnly}
            isLoading={isLoading}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
