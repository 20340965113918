import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';
import { FilterColumn } from '../../../common/types/FilterColumn';

import { PurchaseInvoiceSearch } from './PurchaseInvoiceSearch';

export const ProjectPurchaseInvoicesView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();

  const handleClick = (id: string, newWindow?: boolean) => {
    nav.projectPurchaseInvoiceEditor(id, newWindow);
  };

  const getSummary = useMemo(
    () => (filterText?: string, filterColumns?: FilterColumn) =>
      pok.purchaseInvoices.getSumAllWithTeamsForPurchaseInvoices(
        filterText,
        filterColumns,
      ),
    [pok.purchaseInvoices],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Faktury zakupu do wyjaśnienia</Card.Title>
        <PurchaseInvoiceSearch
          getData={pok.purchaseInvoices.getAllWithTeamsByPortion}
          getCount={pok.purchaseInvoices.getAllWithTeamsCount}
          getSummary={getSummary}
          onRowClick={handleClick}
          displayTeamsProjects={true}
          displayLastNote={true}
          withFilterInColumns={true}
          withCountDays={true}
        />
      </Card.Body>
    </Card>
  );
};
