/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDelayedProjectDto
 */
export interface GetDelayedProjectDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetDelayedProjectDto
     */
    delayed: boolean;
}

/**
 * Check if a given object implements the GetDelayedProjectDto interface.
 */
export function instanceOfGetDelayedProjectDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "delayed" in value;

    return isInstance;
}

export function GetDelayedProjectDtoFromJSON(json: any): GetDelayedProjectDto {
    return GetDelayedProjectDtoFromJSONTyped(json, false);
}

export function GetDelayedProjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDelayedProjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delayed': json['delayed'],
    };
}

export function GetDelayedProjectDtoToJSON(value?: GetDelayedProjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delayed': value.delayed,
    };
}

