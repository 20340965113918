/*eslint max-lines-per-function: ["error", 400]*/
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoiceDtoFromJSON,
  CreateUpdatePurchaseInvoiceEstimateItemsDto,
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSON,
  DefaultApi,
  GetLastEditorDto,
  GetPurchaseInvoiceDto,
  GetPurchaseInvoiceEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
  GetPurchaseInvoiceForItem,
  GetPurchaseInvoiceWithAttachmentIdDto,
} from '../autogenerated/pokApiClient';
import {
  CurrencyIsoTypeEnum,
  InvoiceTypeEnum,
  PurchaseInvoiceEstimateItemsTabSchema,
  PurchaseInvoiceGeneralTabSchema,
  PurchaseInvoiceTeamsProjectsTabSchema,
} from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import {
  getSummary,
  purchaseInvoiceForGrid,
} from '../../../pok/components/PurchaseInvoices/PurchaseInvoiceSearch';
import { PokCoreContextType } from '../pokCore';
import { NotificationsProviderType } from '../../notifications';
import * as AttachmentContext from '../../../common/pokCore/contexts/AttachmentContext';
import { FilterColumn } from '../../types/FilterColumn';

export interface PurchaseInvoiceContextInterface {
  getById: (id: string) => Promise<GetPurchaseInvoiceExtendedDto>;
  approve: (id: string) => Promise<void>;
  unApprove: (id: string) => Promise<void>;
  deactivate: (id: string) => Promise<void>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllWithTeamsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllUnsettledWithProjectsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllUnsettledWithProjectsCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllForMediaplanerByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllForMediaplanerCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllTvForMediaplanerByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllTvForMediaplanerCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllUnsettledTvByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllUnsettledTvCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getEstimateItems: (
    id: string,
  ) => Promise<GetPurchaseInvoiceEstimateItemDto[]>;
  getByEstimateItem: (
    estimateItemId: string,
  ) => Promise<GetPurchaseInvoiceForItem[]>;
  getByProject: (
    projectId: string,
    month?: Date,
    profileId?: string,
  ) => Promise<GetPurchaseInvoiceWithAttachmentIdDto[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllWithTeamsCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdatePurchaseInvoiceDto) => Promise<void>;
  updateTeamsAndProjects: (
    id: string,
    dto: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  ) => Promise<void>;
  updateEstimateItems: (
    id: string,
    dto: CreateUpdatePurchaseInvoiceEstimateItemsDto,
  ) => Promise<void>;
  create: (
    dto: CreateUpdatePurchaseInvoiceDto,
  ) => Promise<GetPurchaseInvoiceDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getLastEstimateItemEditor: (id: string) => Promise<GetLastEditorDto>;
  getAllReadyToSentByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    type?: InvoiceTypeEnum,
    isRepeat?: boolean,
  ) => Promise<GridRecord[]>;
  getCountReadyToSent: (
    filterText?: string,
    filterColumns?: FilterColumn,
    type?: InvoiceTypeEnum,
    isRepeat?: boolean,
  ) => Promise<number>;
  getAllGeneratedByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getCountGenerated: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllGeneratedSettledByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getCountGeneratedSettled: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllWithoutTeamsAndProjectsPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getCountWithoutTeamsAndProjects: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllByPackageFVZ: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    packageFVZId?: string,
  ) => Promise<GridRecord[]>;
  getCountByPackageFVZ: (
    filterText?: string,
    packageFVZId?: string,
  ) => Promise<number>;
  getZipByPurchaseIds: (
    projectNumber: string,
    purchaseInvoiceIds: string[],
  ) => Promise<object>;
  getSumAllWithTeamsForPurchaseInvoices: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord>;
  getSumAllUnsettledWithProjectsForPurchaseInvoices: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord>;
  getSumAllUnsettledTvForPurchaseInvoices: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord>;
}

export const PurchaseInvoiceContext = (
  api: DefaultApi,
): PurchaseInvoiceContextInterface => {
  return {
    getById: (id: string) => api.purchaseInvoiceControllerGet(id),
    approve: (id: string) => api.purchaseInvoiceControllerApprove(id),
    unApprove: (id: string) => api.purchaseInvoiceControllerUnApprove(id),
    deactivate: (id: string) => api.purchaseInvoiceControllerDeactivate(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.purchaseInvoiceControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },
    getAllWithTeamsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.purchaseInvoiceControllerGetAllWithTeamsByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },
    getAllUnsettledWithProjectsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getAllUnsettledWithProjectsCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.purchaseInvoiceControllerGetAllUnsettledWithProjectsCount(
        filterText || '',
        filterColumns,
      ),

    getAllForMediaplanerByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllForMediaplanerByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getAllForMediaplanerCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.purchaseInvoiceControllerGetAllForMediaplanerCount(
        filterText || '',
        filterColumns,
      ),

    getAllTvForMediaplanerByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllTvForMediaplanerByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getAllTvForMediaplanerCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.purchaseInvoiceControllerGetAllTvForMediaplanerCount(
        filterText || '',
        filterColumns,
      ),
    getAllUnsettledTvByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllUnsettledTvByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getAllUnsettledTvCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.purchaseInvoiceControllerGetAllUnsettledTvCount(
        filterText || '',
        filterColumns,
      ),

    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.purchaseInvoiceControllerGetAllCount(filterText || '', filterColumns),
    getAllWithTeamsCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.purchaseInvoiceControllerGetAllWithTeamsCount(
        filterText || '',
        filterColumns,
      ),

    getEstimateItems: (id: string) =>
      api.purchaseInvoiceControllerGetEstimateItems(id),
    getByEstimateItem: (estimateItemId: string) =>
      api.purchaseInvoiceControllerFindByEstimateItem(estimateItemId),
    getByProject: (projectId: string, month?: Date, profileId?: string) =>
      api.purchaseInvoiceControllerFindByProject(projectId, month, profileId),
    update: (id: string, dto: CreateUpdatePurchaseInvoiceDto) =>
      api.purchaseInvoiceControllerUpdate(id, dto),
    updateTeamsAndProjects: (
      id: string,
      dto: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
    ) => api.purchaseInvoiceControllerUpdateTeamsAndProjects(id, dto),
    updateEstimateItems: (
      id: string,
      dto: CreateUpdatePurchaseInvoiceEstimateItemsDto,
    ) =>
      api.purchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItems(id, dto),
    create: (dto: CreateUpdatePurchaseInvoiceDto) =>
      api.purchaseInvoiceControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.purchaseInvoiceControllerGetLastEditor(id),
    getLastEstimateItemEditor: (id: string) =>
      api.purchaseInvoiceControllerGetLastEstimateItemEditor(id),
    getAllReadyToSentByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      type?: InvoiceTypeEnum,
      isRepeat?: boolean,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllReadyToSentByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
          isRepeat || false,
          type,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getCountReadyToSent: (
      filterText?: string,
      filterColumns?: FilterColumn,
      type?: InvoiceTypeEnum,
      isRepeat?: boolean,
    ) =>
      api.purchaseInvoiceControllerGetCountReadyToSent(
        filterText || '',
        filterColumns,
        isRepeat || false,
        type,
      ),

    getAllGeneratedByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.purchaseInvoiceControllerGetAllGeneratedByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getCountGenerated: (filterText?: string, filterColumns?: FilterColumn) =>
      api.purchaseInvoiceControllerGetCountGenerated(
        filterText || '',
        filterColumns,
      ),
    getAllGeneratedSettledByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllGeneratedSettledByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getCountGeneratedSettled: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.purchaseInvoiceControllerGetCountGeneratedSettled(
        filterText || '',
        filterColumns,
      ),
    getAllWithoutTeamsAndProjectsPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getCountWithoutTeamsAndProjects: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.purchaseInvoiceControllerGetCountWithoutTeamsAndProjects(
        filterText || '',
        filterColumns,
      ),

    getAllByPackageFVZ: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      packageFVZId?: string,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetAllByPackageFVZByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          packageFVZId || '',
        );

      return data.map(item => purchaseInvoiceForGrid(item, orderBy));
    },

    getCountByPackageFVZ: (filterText?: string, packageFVZId?: string) =>
      api.purchaseInvoiceControllerGetCountByPackageFVZ(
        filterText || '',
        packageFVZId || '',
      ),
    getZipByPurchaseIds: (
      projectNumber: string,
      purchaseInvoiceIds: string[],
    ) =>
      api.purchaseInvoiceControllerGetZipByPurchaseIds(
        projectNumber,
        purchaseInvoiceIds,
      ),

    getSumAllWithTeamsForPurchaseInvoices: async (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoices(
          filterText || '',
          filterColumns,
        );
      return getSummary(data);
    },
    getSumAllUnsettledWithProjectsForPurchaseInvoices: async (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoices(
          filterText || '',
          filterColumns,
        );
      return getSummary(data);
    },
    getSumAllUnsettledTvForPurchaseInvoices: async (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.purchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoices(
          filterText || '',
          filterColumns,
        );
      return getSummary(data);
    },
  };
};

export const newPurchaseInvoice = (companyId?: string) =>
  CreateUpdatePurchaseInvoiceDtoFromJSON({
    type: InvoiceTypeEnum.Domestic,
    currency: CurrencyIsoTypeEnum.PLN,
    positions: [],
    companyId,
  });

export const newPurchaseInvoiceTeamsAndProjects = () =>
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSON({
    teamIds: [],
    projectIds: [],
  });

export const newPurchaseInvoiceEstimateItems = () => [];

export const validateGeneralTab = (
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto,
) => validateAgainst(PurchaseInvoiceGeneralTabSchema, purchaseInvoice);

export const validateTeamsProjectsTab = (
  teamsAndProjects: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
) => validateAgainst(PurchaseInvoiceTeamsProjectsTabSchema, teamsAndProjects);

export const validateEstimateItemsTab = (
  estimateItems: CreateUpdatePurchaseInvoiceEstimateItemsDto,
) => validateAgainst(PurchaseInvoiceEstimateItemsTabSchema, estimateItems);

export const convert = (
  companyId?: string,
  purchaseInvoice?: GetPurchaseInvoiceExtendedDto,
) => {
  if (!purchaseInvoice) {
    return newPurchaseInvoice(companyId);
  }
  const converted = CreateUpdatePurchaseInvoiceDtoFromJSON(purchaseInvoice);
  converted.clientId = purchaseInvoice.client?.id;

  if (!converted.comment) {
    converted.comment = '';
  }

  return converted;
};

export const convertTeamsAndProjects = (
  purchaseInvoice?: GetPurchaseInvoiceExtendedDto,
) => {
  if (!purchaseInvoice) {
    return newPurchaseInvoiceTeamsAndProjects();
  }
  const converted = CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSON({
    teamIds: purchaseInvoice.teamIds,
    projectIds: purchaseInvoice.projectIds,
  });

  return converted;
};

export const convertPurchaseInvoiceEstimateItems = (
  purchaseInvoice?: GetPurchaseInvoiceExtendedDto,
) => {
  if (!purchaseInvoice) {
    return newPurchaseInvoiceEstimateItems();
  }
  const converted =
    purchaseInvoice.purchaseInvoiceEstimateItems?.map(a => {
      return {
        amount: a.amount,
        estimateItemId: a.estimateItemId,
        projectId: a.estimateItem.estimateByMonth.project.id,
      };
    }) || [];

  return converted;
};

export const createNewAttachment = (id?: string) => {
  const attachment = AttachmentContext.newAttachment();
  attachment.enObjectType = AttachmentContext.ObjectType.PurchaseInvoice;
  attachment.objectId = id as string;
  return attachment;
};

export const forPurchaseInvoiceView = async (
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const attachments = await pok.attachments.getAllForObject(
    AttachmentContext.ObjectType.PurchaseInvoice,
    id,
  );

  const ac = AttachmentContext.forAssociatedDocuments;

  const assocAttachments = ac(pok, notifications, attachments, action);

  assocAttachments.new = () => createNewAttachment(id as string);
  return {
    attachments: assocAttachments,
  };
};

export const notesForPurchaseInvoiceView = async (
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const attachments = await pok.attachments.getAllForObjectAndShortname(
    AttachmentContext.ObjectType.PurchaseInvoice,
    id,
    'NOTE',
  );

  const ac = AttachmentContext.forAssociatedDocuments;

  const assocAttachments = ac(pok, notifications, attachments, action);

  assocAttachments.new = () => {
    const attachment = AttachmentContext.newAttachment();
    attachment.enObjectType = AttachmentContext.ObjectType.PurchaseInvoice;
    attachment.objectId = id;
    return attachment;
  };
  return {
    attachments: assocAttachments,
  };
};
