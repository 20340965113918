/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEstimateItemDto } from './GetEstimateItemDto';
import {
    GetEstimateItemDtoFromJSON,
    GetEstimateItemDtoFromJSONTyped,
    GetEstimateItemDtoToJSON,
} from './GetEstimateItemDto';

/**
 * 
 * @export
 * @interface GetAttachmentEstimateItemDto
 */
export interface GetAttachmentEstimateItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentEstimateItemDto
     */
    id: string;
    /**
     * 
     * @type {GetAttachmentEstimateItemDto}
     * @memberof GetAttachmentEstimateItemDto
     */
    attachment: GetAttachmentEstimateItemDto;
    /**
     * 
     * @type {GetEstimateItemDto}
     * @memberof GetAttachmentEstimateItemDto
     */
    estimateItem: GetEstimateItemDto;
}

/**
 * Check if a given object implements the GetAttachmentEstimateItemDto interface.
 */
export function instanceOfGetAttachmentEstimateItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "attachment" in value;
    isInstance = isInstance && "estimateItem" in value;

    return isInstance;
}

export function GetAttachmentEstimateItemDtoFromJSON(json: any): GetAttachmentEstimateItemDto {
    return GetAttachmentEstimateItemDtoFromJSONTyped(json, false);
}

export function GetAttachmentEstimateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAttachmentEstimateItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attachment': GetAttachmentEstimateItemDtoFromJSON(json['attachment']),
        'estimateItem': GetEstimateItemDtoFromJSON(json['estimateItem']),
    };
}

export function GetAttachmentEstimateItemDtoToJSON(value?: GetAttachmentEstimateItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attachment': GetAttachmentEstimateItemDtoToJSON(value.attachment),
        'estimateItem': GetEstimateItemDtoToJSON(value.estimateItem),
    };
}

