/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateBlockadeMonthDto
 */
export interface CreateUpdateBlockadeMonthDto {
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateBlockadeMonthDto
     */
    date?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBlockadeMonthDto
     */
    isSalesBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBlockadeMonthDto
     */
    isPurchaseBlocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockadeMonthDto
     */
    companyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBlockadeMonthDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateBlockadeMonthDto
     */
    teamIds: Array<string>;
}

/**
 * Check if a given object implements the CreateUpdateBlockadeMonthDto interface.
 */
export function instanceOfCreateUpdateBlockadeMonthDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isSalesBlocked" in value;
    isInstance = isInstance && "isPurchaseBlocked" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "teamIds" in value;

    return isInstance;
}

export function CreateUpdateBlockadeMonthDtoFromJSON(json: any): CreateUpdateBlockadeMonthDto {
    return CreateUpdateBlockadeMonthDtoFromJSONTyped(json, false);
}

export function CreateUpdateBlockadeMonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateBlockadeMonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'isSalesBlocked': json['isSalesBlocked'],
        'isPurchaseBlocked': json['isPurchaseBlocked'],
        'companyId': json['companyId'],
        'active': json['active'],
        'teamIds': json['teamIds'],
    };
}

export function CreateUpdateBlockadeMonthDtoToJSON(value?: CreateUpdateBlockadeMonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'isSalesBlocked': value.isSalesBlocked,
        'isPurchaseBlocked': value.isPurchaseBlocked,
        'companyId': value.companyId,
        'active': value.active,
        'teamIds': value.teamIds,
    };
}

