import React from 'react';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

interface PurchaseParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const PurchaseParams: React.FC<PurchaseParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.rcPurchase && (
        <FormRow controlId="rcPurchase" label={schema?.rcPurchase?.displayName}>
          <CurrencyControl
            value={props.estimateItem?.rcPurchase}
            suffix=" zł"
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                rcPurchase: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.purchaseSurchargePercent && (
        <FormRow
          controlId="purchaseSurchargePercent"
          label={schema?.purchaseSurchargePercent?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseSurchargePercent}
            suffix=" %"
            decimalScale={12}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseSurchargePercent: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.purchaseDiscountPercent && (
        <FormRow
          controlId="purchaseDiscountPercent"
          label={schema?.purchaseDiscountPercent?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseDiscountPercent}
            suffix=" %"
            max={100}
            decimalScale={12}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseDiscountPercent: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.purchaseNet && (
        <FormRow
          controlId="purchaseNet"
          label={schema?.purchaseNet?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseNet}
            suffix=" zł"
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseNet: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.purchaseTechnicalCost && (
        <FormRow
          controlId="purchaseTechnicalCost"
          label={schema?.purchaseTechnicalCost?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseTechnicalCost}
            suffix=" zł"
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseTechnicalCost: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
    </>
  );
};
