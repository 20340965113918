/*eslint max-lines-per-function: ["error", 240]*/
import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useLocation } from '../../hooks/useLocation';
import { FilterColumn } from '../../types/FilterColumn';

import { GridColumn, GridRecord } from './GridDataTypes';
import { GridLocationState } from './GridLocationState';

interface GridInnerTableProps {
  columns: GridColumn[];
  pageData: GridRecord[];
  orderBy?: string;
  orderDirection: 'ASC' | 'DESC';
  summaryData?: GridRecord;
  setOrderBy: (column: string) => void;
  setFilter: (filter: FilterColumn) => void;
  setPageNumber: (v: number) => void;
  filter: (text: string) => void;
  setOrderDirection: (direction: 'ASC' | 'DESC') => void;
  onRowClick?: (key: string, newWindow?: boolean) => void;
  enableMultiSelect?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
  withHistory?: boolean;
  withFilterInColumns?: boolean;
  trClassName?: string;
  withOverflowXAuto?: boolean;
}

export const GridInnerTable: React.FC<GridInnerTableProps> = props => {
  const [multiSelected, setMultiSelected] = useState<readonly string[]>(
    props.selectedKeys &&
      props.enableMultiSelect &&
      props.selectedKeys.length > 0
      ? props.selectedKeys
      : [],
  );
  const navigate = useNavigate();
  const location = useLocation<GridLocationState & unknown>();
  const [columnValues, setColumnValues] = useState<{
    [key: string]: string | undefined;
  }>({});

  useEffect(() => {
    setMultiSelected(
      props.selectedKeys &&
        props.enableMultiSelect &&
        props.selectedKeys.length > 0
        ? props.selectedKeys
        : [],
    );
  }, [props.selectedKeys, props.enableMultiSelect]);

  const [selected, setSelected] = useState<string>(
    props.selectedKeys && props.enableSingleSelect ? props.selectedKeys[0] : '',
  );
  useEffect(() => {
    if (props.selectedKeys && props.enableSingleSelect) {
      setSelected(props.selectedKeys[0]);
    }
  }, [props.selectedKeys, props.enableSingleSelect]);

  const isMultiSelected = (name: string) => multiSelected.indexOf(name) !== -1;

  const isSelected = (name: string) => selected === name;
  const row = (record: GridRecord, selected: boolean) => {
    let specialRowClass = '';
    if (record.isInactive) {
      specialRowClass = 'inactive-row';
    }
    if (record.rowCssClass && !selected) {
      specialRowClass += ' ' + record.rowCssClass;
    }
    if (record.readOnly) {
      specialRowClass += ' readonly-row';
    }
    return props.columns.map(column => (
      <td
        key={record.key + column.property}
        className={`${column.columnCssClass ? column.columnCssClass : ''} ${specialRowClass}`}
      >
        {record.values[column.property]}
      </td>
    ));
  };

  const sortColumn = (property: string, noSorting?: boolean) => {
    if (noSorting) {
      return;
    }

    props.setOrderBy(property);
    props.setOrderDirection(props.orderDirection === 'ASC' ? 'DESC' : 'ASC');
    props.withHistory &&
      navigate(location.pathname, {
        state: {
          ...location.state,
          sort: property,
          sortDirection: props.orderDirection === 'ASC' ? 'DESC' : 'ASC',
        },
      });
  };

  const filterColumns = (property: string, value?: string) => {
    setColumnValues(prevValues => ({
      ...prevValues,
      [property]: value,
    }));

    props.setFilter({
      ...columnValues,
      [property]: value,
    });

    props.setPageNumber(1);
  };

  const cleanFilterColumns = () => {
    setColumnValues({});
    props.setFilter({});
    props.filter('');
  };

  const handleMouseDown = (event: React.MouseEvent, key: string) => {
    if (event.button === 1) {
      event.preventDefault();
      event.stopPropagation();

      props.onRowClick && props.onRowClick(key, true);
    }
  };

  const rowClick = (key: string) => {
    if (props.enableMultiSelect) {
      const selectedIndex = multiSelected.indexOf(key);
      let newSelected: readonly string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(multiSelected, key);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(multiSelected.slice(1));
      } else if (selectedIndex === multiSelected.length - 1) {
        newSelected = newSelected.concat(multiSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          multiSelected.slice(0, selectedIndex),
          multiSelected.slice(selectedIndex + 1),
        );
      }
      setMultiSelected(newSelected);
    } else if (props.enableSingleSelect) {
      setSelected(key);
    }

    if (props.onRowClick) {
      props.onRowClick(key);
    }
  };

  const css = (key: string, readOnly?: boolean) => {
    if (!props.onRowClick) {
      return '';
    }
    if (readOnly) {
      return 'grid-not-clickable-row';
    }
    if (isMultiSelected(key) || isSelected(key)) {
      return 'grid-highlight-row grid-clickable-row';
    }
    return 'grid-clickable-row';
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {props.withFilterInColumns && (
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => cleanFilterColumns()}
          >
            Wyczyść filtr
          </Button>
        )}
      </div>
      <div
        className={props.withOverflowXAuto === false ? '' : 'overflow-x-auto'}
      >
        <Table striped bordered>
          <thead>
            <tr>
              {props.columns.map(column => (
                <th
                  key={column.property}
                  onClick={() => sortColumn(column.property, column.noSorting)}
                >
                  {column.header}
                  {column.property === props.orderBy &&
                    (props.orderDirection === 'ASC' ? (
                      <Icon.ArrowUpShort />
                    ) : (
                      <Icon.ArrowDownShort />
                    ))}
                  {props.withFilterInColumns && (
                    <input
                      type="text"
                      onBlur={e =>
                        filterColumns(column.property, e.target.value)
                      }
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                          filterColumns(
                            column.property,
                            (e.target as HTMLInputElement).value,
                          );
                        }
                      }}
                      disabled={column.noFilter}
                      className="grid-filter-column"
                      value={columnValues[column.property] || ''}
                      onChange={e =>
                        setColumnValues(prevValues => ({
                          ...prevValues,
                          [column.property]: e.target.value,
                        }))
                      }
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.pageData.map(r => (
              <tr
                key={r.key}
                onClick={() => (!r.readOnly ? rowClick(r.key) : undefined)}
                onMouseDown={event =>
                  !r.readOnly ? handleMouseDown(event, r.key) : undefined
                }
                className={
                  css(r.key, r.readOnly) + ' ' + (props.trClassName ?? '')
                }
              >
                {row(r, isMultiSelected(r.key))}
              </tr>
            ))}
          </tbody>
          <tfoot
            className={'grid-summary-foot' + ' ' + (props.trClassName ?? '')}
          >
            {props.summaryData && (
              <tr key="summary">{row(props.summaryData, false)}</tr>
            )}
          </tfoot>
        </Table>
      </div>
    </>
  );
};
