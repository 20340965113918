import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

import { OrderSearch } from './OrderSearch';

export const OrdersSentTab: React.FC = () => {
  const pok = usePokCore();

  const getData = ((
    pageSize,
    pageNumber,
    orderBy,
    orderDirection,
    filterText,
    filterColumns,
  ) =>
    pok.orders.getAllByPortion(
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
      filterColumns,
    )) as GridGetterFunction;

  const getCount = ((filterText, filterColumns) =>
    pok.orders.getAllCount(filterText, filterColumns)) as GridCountFunction;

  return <OrderSearch getData={getData} getCount={getCount} />;
};
