import React, { useEffect, useMemo, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { PurchaseInvoiceSearch } from '../../components/PurchaseInvoices/PurchaseInvoiceSearch';
import {
  InvoiceTypeEnum,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import { downloadXML } from '../../components/PackagesInvoicesXML/downloadXML';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { downloadZIP } from '../../components/PackagesInvoicesXML/downloadZIP';
import { Waiting } from '../../../common/components/Waiting/Waiting';

interface ExportFVZToXMLByTypeProps {
  type: InvoiceTypeEnum;
  isRepeat: boolean;
  title: string;
}
export const ExportFVZToXMLByType: React.FC<ExportFVZToXMLByTypeProps> = ({
  type,
  isRepeat,
  title,
}) => {
  const pok = usePokCore();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [buttonTitle, setButtonTitle] = useState<string>(
    'Utwórz XML z krajowymi FVZ (25 - najstarszych)',
  );
  const [invoiceType, setInvoiceType] = useState<InvoiceTypeEnum>(type);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setButtonTitle(`${title} (25 - najstarszych)`);
    setSelectedInvoices([]);
  }, [title]);

  useEffect(() => {
    setInvoiceType(type);
    setSelectedInvoices([]);
  }, [type, refresh]);

  const generateNewPackage = async (invoiceType: InvoiceTypeEnum) => {
    try {
      setWaiting(true);
      const packageFVZ = await pok.packagesXML.create({
        companyId: pok.getCompanyId(),
        packageType: PackageTypeEnum.Purchase,
        invoiceType,
        selectedInvoices,
        isRepeat: isRepeat,
      });

      const xmlData = await pok.packagesXML.getXMLFromPackageXML(packageFVZ.id);

      await downloadXML(packageFVZ, xmlData);

      const zip = await pok.packagesXML.getZipFromPackageXML(
        packageFVZ.id,
        false,
      );
      await downloadZIP(packageFVZ, zip);
      setWaiting(false);
      setShowSaveErrors(false);
      setRefresh(!refresh);
      setSelectedInvoices([]);
      setButtonTitle(`${title} (25 - najstarszych)`);

      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      setWaiting(false);
      setShowSaveErrors(true);
      setSaveErrors(await responseErrors(response as ResponseError));
    }
  };

  const handleOnRowClick = (key: string) => {
    let si = [];

    if (!selectedInvoices.includes(key)) {
      si = [...selectedInvoices, key];
    } else {
      si = selectedInvoices.filter(o => o !== key);
    }

    setButtonTitle(
      si.length > 0
        ? `${title} (${si.length} - zaznaczone)`
        : `${title} (25 - najstarszych)`,
    );
    setSelectedInvoices(si);
  };

  const getDataInvoices = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllReadyToSentByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          invoiceType,
          isRepeat,
        )) as GridGetterFunction,
    [pok, invoiceType, isRepeat],
  );

  const getCountInvoices = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.purchaseInvoices.getCountReadyToSent(
          filterText,
          filterColumns,
          invoiceType,
          isRepeat,
        )) as GridCountFunction,
    [pok, invoiceType, isRepeat],
  );

  return (
    <>
      {' '}
      <ConfirmationButton
        variant="outline-primary"
        className="mb-4 me-1"
        onOK={() => generateNewPackage(type)}
        confirmation="Czy na pewno utworzyć paczkę FVZ?"
        isLoading={waiting}
      >
        {buttonTitle}
      </ConfirmationButton>
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        className="m-3"
      />
      {waiting && <Waiting />}
      {!waiting && (
        <PurchaseInvoiceSearch
          getData={getDataInvoices}
          getCount={getCountInvoices}
          displayCurrency={true}
          displayTeamsProjects={true}
          refresh={refresh}
          enableMultiSelect={true}
          onRowClick={handleOnRowClick}
          withFilterInColumns={true}
          withPreview={true}
        />
      )}
    </>
  );
};
