/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSpecialInvoicingDto
 */
export interface CreateUpdateSpecialInvoicingDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSpecialInvoicingDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSpecialInvoicingDto
     */
    note: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSpecialInvoicingDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSpecialInvoicingDto
     */
    companyId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateSpecialInvoicingDto
     */
    projectIds: Array<string>;
}

/**
 * Check if a given object implements the CreateUpdateSpecialInvoicingDto interface.
 */
export function instanceOfCreateUpdateSpecialInvoicingDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "projectIds" in value;

    return isInstance;
}

export function CreateUpdateSpecialInvoicingDtoFromJSON(json: any): CreateUpdateSpecialInvoicingDto {
    return CreateUpdateSpecialInvoicingDtoFromJSONTyped(json, false);
}

export function CreateUpdateSpecialInvoicingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSpecialInvoicingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'note': json['note'],
        'active': json['active'],
        'companyId': json['companyId'],
        'projectIds': json['projectIds'],
    };
}

export function CreateUpdateSpecialInvoicingDtoToJSON(value?: CreateUpdateSpecialInvoicingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'note': value.note,
        'active': value.active,
        'companyId': value.companyId,
        'projectIds': value.projectIds,
    };
}

