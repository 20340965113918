/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';

/**
 * 
 * @export
 * @interface GetSymphonyClientDto
 */
export interface GetSymphonyClientDto {
    /**
     * 
     * @type {string}
     * @memberof GetSymphonyClientDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSymphonyClientDto
     */
    code: string;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetSymphonyClientDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetSymphonyClientDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSymphonyClientDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetSymphonyClientDto interface.
 */
export function instanceOfGetSymphonyClientDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetSymphonyClientDtoFromJSON(json: any): GetSymphonyClientDto {
    return GetSymphonyClientDtoFromJSONTyped(json, false);
}

export function GetSymphonyClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSymphonyClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'client': GetClientDtoFromJSON(json['client']),
        'company': GetCompanyDtoFromJSON(json['company']),
        'active': json['active'],
    };
}

export function GetSymphonyClientDtoToJSON(value?: GetSymphonyClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'client': GetClientDtoToJSON(value.client),
        'company': GetCompanyDtoToJSON(value.company),
        'active': value.active,
    };
}

