import { packageInvoicesXMLForGrid } from '../../../pok/components/PackagesInvoicesXML/PackagesInvoicesXMLSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdatePackageInvoicesXMLDto,
  DefaultApi,
  GetPackageInvoicesXMLDto,
} from '../autogenerated/pokApiClient';

export interface PackageInvoicesXMLContextInterface {
  getById: (id: string) => Promise<GetPackageInvoicesXMLDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    packageType?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string, packageType?: string) => Promise<number>;
  create: (
    body: CreateUpdatePackageInvoicesXMLDto,
  ) => Promise<GetPackageInvoicesXMLDto>;
  getXMLFromPackageXML: (packageXMLId: string) => Promise<string>;
  getZipFromPackageXML: (
    packageXMLId: string,
    copy: boolean,
  ) => Promise<object>;

  getZipBySalesInvoicesIds: (
    projectNumber: string,
    purchaseInvoiceIds: string[],
  ) => Promise<object>;
}

export const PackageInvoicesXMLContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.packageInvoicesXMLControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      packageType?: string,
    ) => {
      const data = await api.packageInvoicesXMLControllerGetAllByTypeByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        packageType || '',
      );
      return data.map(packageInvoicesXMLForGrid);
    },
    getAllCount: (filterText?: string, packageType?: string) =>
      api.packageInvoicesXMLControllerGetAllByTypeCount(
        filterText || '',
        packageType || '',
      ),
    create: (body: CreateUpdatePackageInvoicesXMLDto) =>
      api.packageInvoicesXMLControllerCreate(body),
    getXMLFromPackageXML: (packageXMLId: string) =>
      api.packageInvoicesXMLControllerGetXMLFromPackageXML(packageXMLId),
    getZipFromPackageXML: (packageXMLId: string, copy: boolean) =>
      api.packageInvoicesXMLControllerGetZipFromPackageXML(packageXMLId, copy),
    getZipBySalesInvoicesIds: (
      projectNumber: string,
      purchaseInvoiceIds: string[],
    ) =>
      api.packageInvoicesXMLControllerGetZipBySalesInvoicesIds(
        projectNumber,
        purchaseInvoiceIds,
      ),
  };
};
