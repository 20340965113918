import React from 'react';

import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';

interface SalesInvoicesGridProps {
  data: GridRecord[];
}

export const SalesInvoicesGrid: React.FC<SalesInvoicesGridProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'salesInvoice.systemNumber' },
    { header: 'Numer', property: 'salesInvoice.number' },
    {
      header: 'Data wystawienia',
      property: 'salesInvoice.invoiceDate',
    },
    { header: 'Kwota rozliczona', property: 'salesInvoice.amount' },
    { header: 'Status', property: 'salesInvoice.status' },
    { header: 'Ostatnia modyfikacja', property: 'employee.name' },
    { header: 'Załącznik', property: 'preview', noSorting: false },
  ];
  return (
    <Grid
      data={props.data}
      columns={columns}
      showFilter={false}
      initialOrderColumn="salesInvoice.systemNumber"
      initialOrderDirection="DESC"
      pageSize={20}
      emptyGridMessage="Brak wystawionych faktur sprzedaży"
    />
  );
};
