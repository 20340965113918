import moment from 'moment';

import { useAuth } from '../../../common/hooks/useAuth';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { GetAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';

import { AttachmentViewTypes } from './AttachmentViewTypes';

function useIsEditAttachmentAllowedProjects<GetDto>(
  type?: AttachmentViewTypes,
  presentedDocument?: GetDto,
): boolean {
  const auth = useAuth();
  const pok = usePokCore();

  const isAdminOrFinanceUser = [
    PermissionsEnum.Finance,
    PermissionsEnum.Administration,
  ].some(permission => auth.check(permission, pok.getCompanyId()));
  const isProjects = type === 'projects';

  if (!isProjects) {
    return true;
  }

  if (isAdminOrFinanceUser) {
    return true;
  }

  if (
    !moment((presentedDocument as GetAttachmentDto)?.creationTs).isBefore(
      moment().subtract(1, 'hours'),
    ) &&
    presentedDocument
  ) {
    return true;
  }

  return false;
}

export default useIsEditAttachmentAllowedProjects;
