import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface RoleSelectorProps {
  readOnly?: boolean;
  value?: string;
  onChange?: (option: Option) => void;
}

export default (props: RoleSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [roles, setRoles] = useState<GetRoleDto[]>([]);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [name, setName] = useState<string>();

  const setNameAndBadge = useCallback(() => {
    if (!props.value) {
      setName('');
      return;
    }
    const role = roles?.find(u => u.id === props.value);
    if (role) {
      setName(role.name);
    } else {
      void pok.roles.getById(props.value).then(role => {
        setName(role.name);
      });
    }
  }, [roles, pok.roles, props.value]);

  useEffect(() => {
    let mounted = true;
    if (!roles.length && !rolesLoaded) {
      pok.roles
        .getMany()
        .then(data => {
          if (mounted) {
            setRoles(data);
            setNameAndBadge();
            setRolesLoaded(true);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setNameAndBadge();
    }

    return () => {
      mounted = false;
    };
  }, [pok.roles, pok, roles, rolesLoaded, setNameAndBadge, notifications]);

  const provider = async (text: string) => {
    const values = roles.filter(v =>
      v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );

    return values.map(v => {
      return {
        badge: '',
        label: v.name,
        value: v.id,
      };
    });
  };

  if (!roles.length && !rolesLoaded) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={'role-' + roles?.map(o => o.id).join('_').length}
      readOnly={props.readOnly}
      creatable={false}
      provider={provider}
      value={name}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
