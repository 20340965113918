import { useParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  convert,
  convertTeamsAndProjects,
} from '../../../common/pokCore/contexts/PurchaseInvoiceContext';

import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';

type ParamTypes = {
  purchaseInvoiceId: string;
};

export interface UsePurchaseInvoiceDataReturn {
  purchaseInvoice?: CreateUpdatePurchaseInvoiceDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  teamsAndProjects?: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto;
  purchaseInvoiceId: string;
  handleRefresh: (id?: string) => Promise<void>;
  notSavedTab: PurchaseInvoiceTabsEnum | undefined;
  setNotSavedTab: Dispatch<SetStateAction<PurchaseInvoiceTabsEnum | undefined>>;
}

function usePurchaseInvoiceData(): UsePurchaseInvoiceDataReturn {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { purchaseInvoiceId } = useParams<ParamTypes>() as {
    purchaseInvoiceId: string;
  };
  const [extendedPurchaseInvoice, setExtendedPurchaseInvoice] =
    useState<GetPurchaseInvoiceExtendedDto>();
  const [notSavedTab, setNotSavedTab] = useState<
    PurchaseInvoiceTabsEnum | undefined
  >();
  const [convertedPurchaseInvoice, setConvertedPurchaseInvoice] =
    useState<CreateUpdatePurchaseInvoiceDto>();
  const [teamsAndProjects, setTeamsAndProjects] =
    useState<CreateUpdatePurchaseInvoiceTeamsAndProjectsDto>();

  const handleRefresh = async (id?: string) => {
    return pok.purchaseInvoices
      .getById(id || purchaseInvoiceId)
      .then(data => {
        setExtendedPurchaseInvoice(data);
        setConvertedPurchaseInvoice(convert(pok.getCompanyId(), data));
        setNotSavedTab(undefined);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  useEffect(() => {
    if (purchaseInvoiceId !== 'utworz') {
      pok.purchaseInvoices
        .getById(purchaseInvoiceId)
        .then(data => {
          setExtendedPurchaseInvoice(data);
          setConvertedPurchaseInvoice(convert(pok.getCompanyId(), data));
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setConvertedPurchaseInvoice(convert(pok.getCompanyId()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseInvoiceId]);

  useEffect(() => {
    setTeamsAndProjects(convertTeamsAndProjects(extendedPurchaseInvoice));
  }, [extendedPurchaseInvoice]);

  return {
    purchaseInvoiceId,
    extendedPurchaseInvoice,
    purchaseInvoice: convertedPurchaseInvoice,
    teamsAndProjects,
    handleRefresh,
    notSavedTab,
    setNotSavedTab,
  };
}

export default usePurchaseInvoiceData;
