/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBudgetDto } from './GetBudgetDto';
import {
    GetBudgetDtoFromJSON,
    GetBudgetDtoFromJSONTyped,
    GetBudgetDtoToJSON,
} from './GetBudgetDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetInvoiceEstimateItemDto } from './GetInvoiceEstimateItemDto';
import {
    GetInvoiceEstimateItemDtoFromJSON,
    GetInvoiceEstimateItemDtoFromJSONTyped,
    GetInvoiceEstimateItemDtoToJSON,
} from './GetInvoiceEstimateItemDto';
import type { GetPurchaserDto } from './GetPurchaserDto';
import {
    GetPurchaserDtoFromJSON,
    GetPurchaserDtoFromJSONTyped,
    GetPurchaserDtoToJSON,
} from './GetPurchaserDto';
import type { GetSalesInvoiceDto } from './GetSalesInvoiceDto';
import {
    GetSalesInvoiceDtoFromJSON,
    GetSalesInvoiceDtoFromJSONTyped,
    GetSalesInvoiceDtoToJSON,
} from './GetSalesInvoiceDto';
import type { GetSalesInvoicePositionDto } from './GetSalesInvoicePositionDto';
import {
    GetSalesInvoicePositionDtoFromJSON,
    GetSalesInvoicePositionDtoFromJSONTyped,
    GetSalesInvoicePositionDtoToJSON,
} from './GetSalesInvoicePositionDto';
import type { GetSalesInvoiceProjectDto } from './GetSalesInvoiceProjectDto';
import {
    GetSalesInvoiceProjectDtoFromJSON,
    GetSalesInvoiceProjectDtoFromJSONTyped,
    GetSalesInvoiceProjectDtoToJSON,
} from './GetSalesInvoiceProjectDto';

/**
 * 
 * @export
 * @interface GetExtendedSalesInvoiceDto
 */
export interface GetExtendedSalesInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    number: string;
    /**
     * 
     * @type {Date}
     * @memberof GetExtendedSalesInvoiceDto
     */
    invoiceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetExtendedSalesInvoiceDto
     */
    saleDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    type: GetExtendedSalesInvoiceDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    currency: GetExtendedSalesInvoiceDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    status: GetExtendedSalesInvoiceDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    paymentForm: GetExtendedSalesInvoiceDtoPaymentFormEnum;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    payableDays: GetDictionaryDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedSalesInvoiceDto
     */
    printProjectNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedSalesInvoiceDto
     */
    isPaymentFromReceiptDate: boolean;
    /**
     * 
     * @type {Array<GetInvoiceEstimateItemDto>}
     * @memberof GetExtendedSalesInvoiceDto
     */
    invoiceEstimateItems?: Array<GetInvoiceEstimateItemDto>;
    /**
     * 
     * @type {Array<GetSalesInvoicePositionDto>}
     * @memberof GetExtendedSalesInvoiceDto
     */
    salesInvoicePositions?: Array<GetSalesInvoicePositionDto>;
    /**
     * 
     * @type {Array<GetSalesInvoiceProjectDto>}
     * @memberof GetExtendedSalesInvoiceDto
     */
    salesInvoiceProjects?: Array<GetSalesInvoiceProjectDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedSalesInvoiceDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedSalesInvoiceDto
     */
    isOnePositionOnInvoice: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    onePositionName: string;
    /**
     * 
     * @type {number}
     * @memberof GetExtendedSalesInvoiceDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    exchangeRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    exchangeRateDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    gtuCodeId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExtendedSalesInvoiceDto
     */
    documentCodeIds: Array<string>;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    parent: GetSalesInvoiceDto;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    originalInvoice: GetSalesInvoiceDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {GetPurchaserDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    purchaser: GetPurchaserDto;
    /**
     * 
     * @type {GetBudgetDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    budget: GetBudgetDto;
    /**
     * 
     * @type {number}
     * @memberof GetExtendedSalesInvoiceDto
     */
    netSum?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedSalesInvoiceDto
     */
    isSymphonyCode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    textOverItems: string;
    /**
     * 
     * @type {string}
     * @memberof GetExtendedSalesInvoiceDto
     */
    correctionReason: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetExtendedSalesInvoiceDto
     */
    approver?: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetExtendedSalesInvoiceDto
     */
    approvedTs?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExtendedSalesInvoiceDto
     */
    allowCorrection?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExtendedSalesInvoiceDto
     */
    correctionsNumbers?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetExtendedSalesInvoiceDto
     */
    packageFVSNumber?: number;
}


/**
 * @export
 */
export const GetExtendedSalesInvoiceDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type GetExtendedSalesInvoiceDtoTypeEnum = typeof GetExtendedSalesInvoiceDtoTypeEnum[keyof typeof GetExtendedSalesInvoiceDtoTypeEnum];

/**
 * @export
 */
export const GetExtendedSalesInvoiceDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type GetExtendedSalesInvoiceDtoCurrencyEnum = typeof GetExtendedSalesInvoiceDtoCurrencyEnum[keyof typeof GetExtendedSalesInvoiceDtoCurrencyEnum];

/**
 * @export
 */
export const GetExtendedSalesInvoiceDtoStatusEnum = {
    Draft: 'Draft',
    Generated: 'Generated',
    Sent: 'Sent',
    Paid: 'Paid'
} as const;
export type GetExtendedSalesInvoiceDtoStatusEnum = typeof GetExtendedSalesInvoiceDtoStatusEnum[keyof typeof GetExtendedSalesInvoiceDtoStatusEnum];

/**
 * @export
 */
export const GetExtendedSalesInvoiceDtoPaymentFormEnum = {
    BankTransfer: 'BankTransfer',
    Compensation: 'Compensation',
    Prepayment: 'Prepayment',
    Paid: 'Paid'
} as const;
export type GetExtendedSalesInvoiceDtoPaymentFormEnum = typeof GetExtendedSalesInvoiceDtoPaymentFormEnum[keyof typeof GetExtendedSalesInvoiceDtoPaymentFormEnum];


/**
 * Check if a given object implements the GetExtendedSalesInvoiceDto interface.
 */
export function instanceOfGetExtendedSalesInvoiceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "invoiceDate" in value;
    isInstance = isInstance && "saleDate" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "paymentForm" in value;
    isInstance = isInstance && "payableDays" in value;
    isInstance = isInstance && "printProjectNumber" in value;
    isInstance = isInstance && "isPaymentFromReceiptDate" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "isOnePositionOnInvoice" in value;
    isInstance = isInstance && "onePositionName" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "gtuCodeId" in value;
    isInstance = isInstance && "documentCodeIds" in value;
    isInstance = isInstance && "parent" in value;
    isInstance = isInstance && "originalInvoice" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "purchaser" in value;
    isInstance = isInstance && "budget" in value;
    isInstance = isInstance && "textOverItems" in value;
    isInstance = isInstance && "correctionReason" in value;

    return isInstance;
}

export function GetExtendedSalesInvoiceDtoFromJSON(json: any): GetExtendedSalesInvoiceDto {
    return GetExtendedSalesInvoiceDtoFromJSONTyped(json, false);
}

export function GetExtendedSalesInvoiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExtendedSalesInvoiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'invoiceDate': (new Date(json['invoiceDate'])),
        'saleDate': (new Date(json['saleDate'])),
        'type': json['type'],
        'currency': json['currency'],
        'status': json['status'],
        'paymentForm': json['paymentForm'],
        'payableDays': GetDictionaryDtoFromJSON(json['payableDays']),
        'printProjectNumber': json['printProjectNumber'],
        'isPaymentFromReceiptDate': json['isPaymentFromReceiptDate'],
        'invoiceEstimateItems': !exists(json, 'invoiceEstimateItems') ? undefined : ((json['invoiceEstimateItems'] as Array<any>).map(GetInvoiceEstimateItemDtoFromJSON)),
        'salesInvoicePositions': !exists(json, 'salesInvoicePositions') ? undefined : ((json['salesInvoicePositions'] as Array<any>).map(GetSalesInvoicePositionDtoFromJSON)),
        'salesInvoiceProjects': !exists(json, 'salesInvoiceProjects') ? undefined : ((json['salesInvoiceProjects'] as Array<any>).map(GetSalesInvoiceProjectDtoFromJSON)),
        'active': json['active'],
        'isOnePositionOnInvoice': json['isOnePositionOnInvoice'],
        'onePositionName': json['onePositionName'],
        'systemNumber': json['systemNumber'],
        'exchangeRate': !exists(json, 'exchangeRate') ? undefined : json['exchangeRate'],
        'exchangeRateDescription': !exists(json, 'exchangeRateDescription') ? undefined : json['exchangeRateDescription'],
        'gtuCodeId': json['gtuCodeId'],
        'documentCodeIds': json['documentCodeIds'],
        'parent': GetSalesInvoiceDtoFromJSON(json['parent']),
        'originalInvoice': GetSalesInvoiceDtoFromJSON(json['originalInvoice']),
        'company': GetCompanyDtoFromJSON(json['company']),
        'purchaser': GetPurchaserDtoFromJSON(json['purchaser']),
        'budget': GetBudgetDtoFromJSON(json['budget']),
        'netSum': !exists(json, 'netSum') ? undefined : json['netSum'],
        'isSymphonyCode': !exists(json, 'isSymphonyCode') ? undefined : json['isSymphonyCode'],
        'textOverItems': json['textOverItems'],
        'correctionReason': json['correctionReason'],
        'approver': !exists(json, 'approver') ? undefined : GetEmployeeDtoFromJSON(json['approver']),
        'approvedTs': !exists(json, 'approvedTs') ? undefined : (json['approvedTs'] === null ? null : new Date(json['approvedTs'])),
        'allowCorrection': !exists(json, 'allowCorrection') ? undefined : json['allowCorrection'],
        'correctionsNumbers': !exists(json, 'correctionsNumbers') ? undefined : json['correctionsNumbers'],
        'packageFVSNumber': !exists(json, 'packageFVSNumber') ? undefined : json['packageFVSNumber'],
    };
}

export function GetExtendedSalesInvoiceDtoToJSON(value?: GetExtendedSalesInvoiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'invoiceDate': (value.invoiceDate.toISOString()),
        'saleDate': (value.saleDate.toISOString()),
        'type': value.type,
        'currency': value.currency,
        'status': value.status,
        'paymentForm': value.paymentForm,
        'payableDays': GetDictionaryDtoToJSON(value.payableDays),
        'printProjectNumber': value.printProjectNumber,
        'isPaymentFromReceiptDate': value.isPaymentFromReceiptDate,
        'invoiceEstimateItems': value.invoiceEstimateItems === undefined ? undefined : ((value.invoiceEstimateItems as Array<any>).map(GetInvoiceEstimateItemDtoToJSON)),
        'salesInvoicePositions': value.salesInvoicePositions === undefined ? undefined : ((value.salesInvoicePositions as Array<any>).map(GetSalesInvoicePositionDtoToJSON)),
        'salesInvoiceProjects': value.salesInvoiceProjects === undefined ? undefined : ((value.salesInvoiceProjects as Array<any>).map(GetSalesInvoiceProjectDtoToJSON)),
        'active': value.active,
        'isOnePositionOnInvoice': value.isOnePositionOnInvoice,
        'onePositionName': value.onePositionName,
        'systemNumber': value.systemNumber,
        'exchangeRate': value.exchangeRate,
        'exchangeRateDescription': value.exchangeRateDescription,
        'gtuCodeId': value.gtuCodeId,
        'documentCodeIds': value.documentCodeIds,
        'parent': GetSalesInvoiceDtoToJSON(value.parent),
        'originalInvoice': GetSalesInvoiceDtoToJSON(value.originalInvoice),
        'company': GetCompanyDtoToJSON(value.company),
        'purchaser': GetPurchaserDtoToJSON(value.purchaser),
        'budget': GetBudgetDtoToJSON(value.budget),
        'netSum': value.netSum,
        'isSymphonyCode': value.isSymphonyCode,
        'textOverItems': value.textOverItems,
        'correctionReason': value.correctionReason,
        'approver': GetEmployeeDtoToJSON(value.approver),
        'approvedTs': value.approvedTs === undefined ? undefined : (value.approvedTs === null ? null : value.approvedTs.toISOString()),
        'allowCorrection': value.allowCorrection,
        'correctionsNumbers': value.correctionsNumbers,
        'packageFVSNumber': value.packageFVSNumber,
    };
}

