import React from 'react';

import { usePokCore } from '../../common/hooks/usePokCore';
import { useAuth } from '../../common/hooks/useAuth';
import { SignInDialog } from '../components/SignIn';

export const SignInView: React.FC = () => {
  const pok = usePokCore();
  const auth = useAuth();

  const onSignIn = async (username: string, password: string) => {
    await auth.login(pok, username, password);
  };

  const onSignInWithGoogle = async (googleToken: string) => {
    await auth.loginWithGoogle(pok, googleToken);
  };

  return (
    <SignInDialog onSignIn={onSignIn} onSignInWithGoogle={onSignInWithGoogle} />
  );
};
