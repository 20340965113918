import { useAuth } from '../../../common/hooks/useAuth';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';

function usePurchaseInvoicePermissions(): {
  isFinanceUser: boolean;
  isProjectUser: boolean;
} {
  const auth = useAuth();
  const pok = usePokCore();

  const isFinanceUser = auth.check(PermissionsEnum.Finance, pok.getCompanyId());
  const isProjectUser = auth.check(
    PermissionsEnum.Projects,
    pok.getCompanyId(),
  );

  return {
    isFinanceUser,
    isProjectUser,
  };
}

export default usePurchaseInvoicePermissions;
