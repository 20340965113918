/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAttachmentDto } from './GetAttachmentDto';
import {
    GetAttachmentDtoFromJSON,
    GetAttachmentDtoFromJSONTyped,
    GetAttachmentDtoToJSON,
} from './GetAttachmentDto';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';
import type { GetPurchaseInvoiceEstimateItemDto } from './GetPurchaseInvoiceEstimateItemDto';
import {
    GetPurchaseInvoiceEstimateItemDtoFromJSON,
    GetPurchaseInvoiceEstimateItemDtoFromJSONTyped,
    GetPurchaseInvoiceEstimateItemDtoToJSON,
} from './GetPurchaseInvoiceEstimateItemDto';
import type { GetPurchaseInvoicePositionDto } from './GetPurchaseInvoicePositionDto';
import {
    GetPurchaseInvoicePositionDtoFromJSON,
    GetPurchaseInvoicePositionDtoFromJSONTyped,
    GetPurchaseInvoicePositionDtoToJSON,
} from './GetPurchaseInvoicePositionDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetPurchaseInvoiceExtendedDto
 */
export interface GetPurchaseInvoiceExtendedDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    systemNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    sellDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    issueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    paymentDate: Date;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    amountWithoutPosition: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    type: GetPurchaseInvoiceExtendedDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    status: GetPurchaseInvoiceExtendedDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    currency: GetPurchaseInvoiceExtendedDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    exchangeRate: string;
    /**
     * 
     * @type {Array<GetProjectDto>}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    projects?: Array<GetProjectDto>;
    /**
     * 
     * @type {Array<GetTeamDto>}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    teams?: Array<GetTeamDto>;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    creationTs: Date;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    tv: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    changeAmountsTs?: Date;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    changeAmountsOwner?: GetEmployeeDto;
    /**
     * 
     * @type {Array<GetPurchaseInvoicePositionDto>}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    positions?: Array<GetPurchaseInvoicePositionDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    teamIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    projectIds?: Array<string>;
    /**
     * 
     * @type {Array<GetPurchaseInvoiceEstimateItemDto>}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    purchaseInvoiceEstimateItems?: Array<GetPurchaseInvoiceEstimateItemDto>;
    /**
     * 
     * @type {GetAttachmentDto}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    attachment?: GetAttachmentDto;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    attachmentId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    packageFVZNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseInvoiceExtendedDto
     */
    countDaysFromCreation?: number;
}


/**
 * @export
 */
export const GetPurchaseInvoiceExtendedDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type GetPurchaseInvoiceExtendedDtoTypeEnum = typeof GetPurchaseInvoiceExtendedDtoTypeEnum[keyof typeof GetPurchaseInvoiceExtendedDtoTypeEnum];

/**
 * @export
 */
export const GetPurchaseInvoiceExtendedDtoStatusEnum = {
    Open: 'Open',
    Approved: 'Approved',
    Sent: 'Sent'
} as const;
export type GetPurchaseInvoiceExtendedDtoStatusEnum = typeof GetPurchaseInvoiceExtendedDtoStatusEnum[keyof typeof GetPurchaseInvoiceExtendedDtoStatusEnum];

/**
 * @export
 */
export const GetPurchaseInvoiceExtendedDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type GetPurchaseInvoiceExtendedDtoCurrencyEnum = typeof GetPurchaseInvoiceExtendedDtoCurrencyEnum[keyof typeof GetPurchaseInvoiceExtendedDtoCurrencyEnum];


/**
 * Check if a given object implements the GetPurchaseInvoiceExtendedDto interface.
 */
export function instanceOfGetPurchaseInvoiceExtendedDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "sellDate" in value;
    isInstance = isInstance && "issueDate" in value;
    isInstance = isInstance && "paymentDate" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountWithoutPosition" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "exchangeRate" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTs" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "tv" in value;

    return isInstance;
}

export function GetPurchaseInvoiceExtendedDtoFromJSON(json: any): GetPurchaseInvoiceExtendedDto {
    return GetPurchaseInvoiceExtendedDtoFromJSONTyped(json, false);
}

export function GetPurchaseInvoiceExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaseInvoiceExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'systemNumber': json['systemNumber'],
        'sellDate': (new Date(json['sellDate'])),
        'issueDate': (new Date(json['issueDate'])),
        'paymentDate': (new Date(json['paymentDate'])),
        'client': GetClientDtoFromJSON(json['client']),
        'amount': json['amount'],
        'amountWithoutPosition': json['amountWithoutPosition'],
        'active': json['active'],
        'companyId': json['companyId'],
        'type': json['type'],
        'status': json['status'],
        'currency': json['currency'],
        'exchangeRate': json['exchangeRate'],
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(GetProjectDtoFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(GetTeamDtoFromJSON)),
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'creationTs': (new Date(json['creationTs'])),
        'comment': json['comment'],
        'tv': json['tv'],
        'changeAmountsTs': !exists(json, 'changeAmountsTs') ? undefined : (new Date(json['changeAmountsTs'])),
        'changeAmountsOwner': !exists(json, 'changeAmountsOwner') ? undefined : GetEmployeeDtoFromJSON(json['changeAmountsOwner']),
        'positions': !exists(json, 'positions') ? undefined : ((json['positions'] as Array<any>).map(GetPurchaseInvoicePositionDtoFromJSON)),
        'teamIds': !exists(json, 'teamIds') ? undefined : json['teamIds'],
        'projectIds': !exists(json, 'projectIds') ? undefined : json['projectIds'],
        'purchaseInvoiceEstimateItems': !exists(json, 'purchaseInvoiceEstimateItems') ? undefined : ((json['purchaseInvoiceEstimateItems'] as Array<any>).map(GetPurchaseInvoiceEstimateItemDtoFromJSON)),
        'attachment': !exists(json, 'attachment') ? undefined : GetAttachmentDtoFromJSON(json['attachment']),
        'attachmentId': !exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'packageFVZNumber': !exists(json, 'packageFVZNumber') ? undefined : json['packageFVZNumber'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'countDaysFromCreation': !exists(json, 'countDaysFromCreation') ? undefined : json['countDaysFromCreation'],
    };
}

export function GetPurchaseInvoiceExtendedDtoToJSON(value?: GetPurchaseInvoiceExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'systemNumber': value.systemNumber,
        'sellDate': (value.sellDate.toISOString()),
        'issueDate': (value.issueDate.toISOString()),
        'paymentDate': (value.paymentDate.toISOString()),
        'client': GetClientDtoToJSON(value.client),
        'amount': value.amount,
        'amountWithoutPosition': value.amountWithoutPosition,
        'active': value.active,
        'companyId': value.companyId,
        'type': value.type,
        'status': value.status,
        'currency': value.currency,
        'exchangeRate': value.exchangeRate,
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(GetProjectDtoToJSON)),
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(GetTeamDtoToJSON)),
        'creator': GetEmployeeDtoToJSON(value.creator),
        'creationTs': (value.creationTs.toISOString()),
        'comment': value.comment,
        'tv': value.tv,
        'changeAmountsTs': value.changeAmountsTs === undefined ? undefined : (value.changeAmountsTs.toISOString()),
        'changeAmountsOwner': GetEmployeeDtoToJSON(value.changeAmountsOwner),
        'positions': value.positions === undefined ? undefined : ((value.positions as Array<any>).map(GetPurchaseInvoicePositionDtoToJSON)),
        'teamIds': value.teamIds,
        'projectIds': value.projectIds,
        'purchaseInvoiceEstimateItems': value.purchaseInvoiceEstimateItems === undefined ? undefined : ((value.purchaseInvoiceEstimateItems as Array<any>).map(GetPurchaseInvoiceEstimateItemDtoToJSON)),
        'attachment': GetAttachmentDtoToJSON(value.attachment),
        'attachmentId': value.attachmentId,
        'packageFVZNumber': value.packageFVZNumber,
        'note': value.note,
        'countDaysFromCreation': value.countDaysFromCreation,
    };
}

