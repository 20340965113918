/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleInCompanyInTokenDto
 */
export interface RoleInCompanyInTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RoleInCompanyInTokenDto
     */
    companyId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleInCompanyInTokenDto
     */
    roles: Array<string>;
}

/**
 * Check if a given object implements the RoleInCompanyInTokenDto interface.
 */
export function instanceOfRoleInCompanyInTokenDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function RoleInCompanyInTokenDtoFromJSON(json: any): RoleInCompanyInTokenDto {
    return RoleInCompanyInTokenDtoFromJSONTyped(json, false);
}

export function RoleInCompanyInTokenDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleInCompanyInTokenDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'roles': json['roles'],
    };
}

export function RoleInCompanyInTokenDtoToJSON(value?: RoleInCompanyInTokenDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'roles': value.roles,
    };
}

