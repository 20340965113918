import React from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

import { calculateFields } from './FieldCalculator';
import { SalesParams } from './SalesParams';
import { PurchaseParams } from './PurchaseParams';
import {
  isFieldHidden,
  isPurchaseTotalDisabled,
  isSalesTotalDisabled,
} from './CommissionService';
import { SpecialSalesParams } from './SpecialSalesParams';
import { SpecialSalesParams2 } from './SpecialSalesParams2';

interface SalesTabProps {
  estimateItem: CreateUpdateEstimateItemDto;
  project?: GetProjectDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  isSalesBlocked?: boolean;
  isPurchaseBlocked?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const SalesTab: React.FC<SalesTabProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    obj = calculateFields(obj, schema, props.estimateItem);
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {props.isSalesBlocked && (
        <Alert variant="warning">
          {'Aktywna blokada sprzedaży na profilu'}
        </Alert>
      )}
      {props.isPurchaseBlocked && (
        <Alert variant="warning">{'Aktywna blokada zakupu na profilu'}</Alert>
      )}
      <Row>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 pe-3">
            <SalesParams
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isSalesBlocked}
            />
            <SpecialSalesParams
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isSalesBlocked}
            />
            <SpecialSalesParams2
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isSalesBlocked}
            />
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 ps-3">
            <PurchaseParams
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isPurchaseBlocked}
            />
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 pe-3">
            {schema?.salesNetTotal && (
              <FormRow
                controlId="salesNetTotal"
                label={schema?.salesNetTotal?.displayName}
              >
                <CurrencyControl
                  value={props.estimateItem?.salesNetTotal}
                  suffix=" zł"
                  className="border-bold"
                  onChange={value =>
                    propertyChange({
                      salesNetTotal: value,
                    })
                  }
                  disabled={
                    isSalesTotalDisabled(
                      schema,
                      props.estimateItem,
                      props.profile?.commission,
                    ) || props.isSalesBlocked
                  }
                  allowNegative
                />
              </FormRow>
            )}
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 ps-3">
            {schema?.purchaseNetTotal &&
              !isFieldHidden(
                'purchaseNetTotal',
                props.estimateItem.positionType,
                props.profile?.commission,
              ) && (
                <FormRow
                  controlId="purchaseNetTotal"
                  label={schema?.purchaseNetTotal?.displayName}
                >
                  <CurrencyControl
                    value={props.estimateItem?.purchaseNetTotal}
                    suffix=" zł"
                    className="border-bold"
                    decimalScale={2}
                    onChange={value =>
                      propertyChange({
                        purchaseNetTotal: value,
                      })
                    }
                    disabled={
                      isPurchaseTotalDisabled(
                        schema,
                        props.estimateItem,
                        props.profile?.commission,
                      ) || props.isPurchaseBlocked
                    }
                    allowNegative
                  />
                </FormRow>
              )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
