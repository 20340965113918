import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface TeamsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const teamForGrid = (team: GetTeamDto) => {
  return {
    key: team.id,
    values: {
      'team.systemNumber': team.systemNumber,
      'team.name': team.name,
      'team.email': team.email,
      'employee.name': team.manager?.name,
      'company.shortname': team.company?.shortname,
    },
    isInactive: !team.active,
  };
};

export const TeamsSearch: React.FC<TeamsSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'team.systemNumber' },
    { header: 'Spółka', property: 'company.shortname' },
    { header: 'Nazwa', property: 'team.name' },
    { header: 'E-mail', property: 'team.email' },
    { header: 'Szef zespołu', property: 'employee.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="team.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak zespołów w systemie"
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
