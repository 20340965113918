import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import numberFormatter from '../../../common/numberFormatter';
import { GetPaeEstimateItemsDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface PaePositionsGridProps {
  data: GetPaeEstimateItemsDto[];
  enableMultiSelect?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const PaePositionsGrid: React.FC<PaePositionsGridProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa pozycji', property: 'positionName' },
    { header: 'Forma zakupu', property: 'purchaseForm' },
    { header: 'Sprzedaż', property: 'sale' },
    { header: 'Zakup', property: 'purchase' },
    { header: 'Format z PAE', property: 'paePurchaseForm' },
    { header: 'Nr pozycji z PAE', property: 'paePositionNumber' },
  ];

  const data = props.data.map(paePosition => {
    const incomplete =
      paePosition.position === null || paePosition.purchaseFormDict === null;
    return {
      key: paePosition.idx,
      values: {
        positionName: paePosition.position?.name
          ? `${paePosition.position?.name} (${paePosition.position?.client.name})`
          : '-',
        paePositionNumber: paePosition.paePositionSystemNumber,
        purchaseForm: paePosition.purchaseFormDict?.value ?? '-',
        paePurchaseForm: paePosition.paePurchaseForm,
        purchase: numberFormatter(paePosition.purchaseAmount, true),
        sale: numberFormatter(paePosition.salesAmount, true),
      },
      readOnly: incomplete,
      isInactive: incomplete,
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak pozycji"
      trClassName="amounts-align-right font-90"
      enableMultiSelect={props.enableMultiSelect}
      onRowClick={props.onRowClick}
    />
  );
};
