import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import * as rstm from 'react-simple-tree-menu';

import { Tree } from '../../../common/components/Tree/Tree';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetDictionaryDto as Dictionary } from '../../../common/pokCore/autogenerated/pokApiClient/models/GetDictionaryDto';
import { GetDictionaryTypeDto as DictionaryType } from '../../../common/pokCore/autogenerated/pokApiClient/models/GetDictionaryTypeDto';
import { useNotifications } from '../../../common/hooks/useNotifications';

const mapTreeItem: (
  key?: string,
  id?: string,
  dictionaryTypeId?: string,
  label?: string,
  shortname?: string,
  systemNumber?: number,
  editable?: boolean,
) => rstm.TreeNodeInArray = (
  key = '',
  id = undefined,
  dictionaryTypeId = undefined,
  label = '',
  shortname = '',
  systemNumber = undefined,
  editable = false,
) => ({
  key: key,
  id: id,
  dictionaryTypeId: dictionaryTypeId,
  label: label,
  shortname: shortname,
  systemNumber: systemNumber,
  editable: editable,
  nodes: undefined,
});

const mapDictChildren: (
  id: string | undefined,
  rootLevel: boolean,
  data: Dictionary[],
) => rstm.TreeNodeInArray[] = (id, rootLevel, data) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dicChildren: any[] = [];

  if (rootLevel) {
    dicChildren = data.filter(
      dict => dict.dictionaryType.id === id && !dict.parentId,
    );
  } else {
    dicChildren = data.filter(dict => dict.parentId === id);
  }

  return dicChildren.map(dic => ({
    ...mapTreeItem(
      dic.id,
      dic.id,
      dic.dictionaryType.id,
      dic.shortname?.length > 0 ? ` - ${dic.value}` : dic.value,
      dic.shortname,
      dic.systemNumber,
      true,
    ),
    nodes: mapDictChildren(dic.id, false, data),
  }));
};

export const DictionariesTreeView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const [dataDict, setDataDict] = useState<Dictionary[]>([]);
  const [dataDictType, setDataDictType] = useState<DictionaryType[]>([]);
  const [treeData, setTreeData] = useState<rstm.TreeNodeInArray[]>([]);

  useEffect(() => {
    pok.dictionaries
      .getActive()
      .then(dataDict => {
        setDataDict(dataDict);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
    pok.dictionaryTypes
      .getAll()
      .then(dataDictType => {
        setDataDictType(dataDictType);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok, notifications]);

  useEffect(() => {
    setTreeData(
      dataDictType.map(dict => ({
        ...mapTreeItem(
          dict.id,
          undefined,
          dict.id,
          dict.name,
          '',
          undefined,
          false,
        ),
        nodes: mapDictChildren(dict.id, true, dataDict),
      })),
    );
  }, [dataDict, dataDictType]);

  const handleRefresh = () => {
    pok.dictionaries
      .getActive()
      .then(dataDict => {
        setDataDict(dataDict);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Słowniki systemowe</Card.Title>
        <Tree
          treeData={treeData}
          variant="dictionary"
          onUpdate={handleRefresh}
        />
      </Card.Body>
    </Card>
  );
};
