import * as XLSX from 'xlsx';

import { ReportType } from '../../../common/pokCore/autogenerated/pokApiClient';

export const downloadExcel = async (
  report: ReportType,
  columnsToFormat0: string[],
  columnsToFormat2: string[],
  columnsToFormat4: string[],
  worksheetName: string,
  fileName: string,
) => {
  const worksheet = XLSX.utils.json_to_sheet(report.data || []);

  if (worksheet && worksheet['!ref']) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
      columnsToFormat0.forEach(col => {
        const cellAddress = `${col}${rowNum + 1}`;

        if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
          worksheet[cellAddress].t = 'n';
          worksheet[cellAddress].z = '###0';
        }
      });

      columnsToFormat4.forEach(col => {
        const cellAddress = `${col}${rowNum + 1}`;

        if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
          worksheet[cellAddress].t = 'n';
          worksheet[cellAddress].z = '#,##0.0000';
        }
      });

      columnsToFormat2.forEach(col => {
        const cellAddress = `${col}${rowNum + 1}`;

        if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
          worksheet[cellAddress].t = 'n';
          worksheet[cellAddress].z = '#,##0.00';
        }
      });
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);

    const currentDate = new Date().toISOString().split('T')[0];
    XLSX.writeFile(workbook, `${fileName}_${currentDate}.xlsx`);
  }
};
