import React from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment';

import {
  GetEmployeeDto,
  GetThreadDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import oneliner from '../../../common/oneliner';
import {
  getRecipient,
  SubjectType,
} from '../../../common/pokCore/contexts/MemoContext';
import TooltipText from '../../../app/components/TooltipText';

import Pulse from './Pulse';
import SubjectDescription from './SubjectDescription';

export default (props: {
  thread: GetThreadDto;
  active?: boolean;
  loggedIn: GetEmployeeDto;
  onClick: () => void;
}) => {
  if (!props.thread) {
    return <></>;
  }

  const newOne = props.thread.newMessagesCount > 0;

  return (
    <Card
      onClick={props.onClick}
      role="button"
      className={'mb-1' + (props.active ? ' bg-primary bg-opacity-25' : '')}
    >
      <Card.Header className={newOne ? 'fw-bold' : ''}>
        {props.thread.subject && (
          <div className="mb-1 flex-fill d-flex">
            <span className="flex-fill badge bg-light text-dark bg-opacity-25">
              <SubjectDescription
                subject={props.thread.subject as SubjectType}
                maxlen={46}
              />
            </span>
          </div>
        )}
        <div className="d-flex align-items-center">
          {newOne && <Pulse variant={props.active ? 'white' : 'green'} />}
          <span className={'flex-fill' + (newOne ? ' ms-1' : '')}>
            {getRecipient(props.loggedIn, props.thread)?.name}
          </span>
          <TooltipText
            text={moment(props.thread.date).fromNow()}
            tooltip={moment(props.thread.date).calendar()}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Text className="fst-italic">
          {oneliner(props.thread.content, 48)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
