/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DecisionApplicationUnblockDto
 */
export interface DecisionApplicationUnblockDto {
    /**
     * 
     * @type {string}
     * @memberof DecisionApplicationUnblockDto
     */
    estimateByMonthId: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionApplicationUnblockDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionApplicationUnblockDto
     */
    type: DecisionApplicationUnblockDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionApplicationUnblockDto
     */
    salesCorrect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionApplicationUnblockDto
     */
    inDocumentation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DecisionApplicationUnblockDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionApplicationUnblockDto
     */
    status: DecisionApplicationUnblockDtoStatusEnum;
}


/**
 * @export
 */
export const DecisionApplicationUnblockDtoTypeEnum = {
    Sales: 'Sales',
    Purchase: 'Purchase'
} as const;
export type DecisionApplicationUnblockDtoTypeEnum = typeof DecisionApplicationUnblockDtoTypeEnum[keyof typeof DecisionApplicationUnblockDtoTypeEnum];

/**
 * @export
 */
export const DecisionApplicationUnblockDtoStatusEnum = {
    New: 'New',
    Accepted: 'Accepted',
    Rejected: 'Rejected'
} as const;
export type DecisionApplicationUnblockDtoStatusEnum = typeof DecisionApplicationUnblockDtoStatusEnum[keyof typeof DecisionApplicationUnblockDtoStatusEnum];


/**
 * Check if a given object implements the DecisionApplicationUnblockDto interface.
 */
export function instanceOfDecisionApplicationUnblockDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimateByMonthId" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DecisionApplicationUnblockDtoFromJSON(json: any): DecisionApplicationUnblockDto {
    return DecisionApplicationUnblockDtoFromJSONTyped(json, false);
}

export function DecisionApplicationUnblockDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionApplicationUnblockDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimateByMonthId': json['estimateByMonthId'],
        'description': json['description'],
        'type': json['type'],
        'salesCorrect': !exists(json, 'salesCorrect') ? undefined : json['salesCorrect'],
        'inDocumentation': !exists(json, 'inDocumentation') ? undefined : json['inDocumentation'],
        'rejectedNote': json['rejectedNote'],
        'status': json['status'],
    };
}

export function DecisionApplicationUnblockDtoToJSON(value?: DecisionApplicationUnblockDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimateByMonthId': value.estimateByMonthId,
        'description': value.description,
        'type': value.type,
        'salesCorrect': value.salesCorrect,
        'inDocumentation': value.inDocumentation,
        'rejectedNote': value.rejectedNote,
        'status': value.status,
    };
}

