/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProfileDto } from './GetProfileDto';
import {
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './GetProfileDto';

/**
 * 
 * @export
 * @interface GetProfileCategoryDto
 */
export interface GetProfileCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetProfileCategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileCategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileCategoryDto
     */
    purchasesFinancialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileCategoryDto
     */
    salesFinancialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileCategoryDto
     */
    commissionFinancialAccount: string;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetProfileCategoryDto
     */
    profile: GetProfileDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetProfileCategoryDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetProfileCategoryDto interface.
 */
export function instanceOfGetProfileCategoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "purchasesFinancialAccount" in value;
    isInstance = isInstance && "salesFinancialAccount" in value;
    isInstance = isInstance && "commissionFinancialAccount" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetProfileCategoryDtoFromJSON(json: any): GetProfileCategoryDto {
    return GetProfileCategoryDtoFromJSONTyped(json, false);
}

export function GetProfileCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProfileCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'purchasesFinancialAccount': json['purchasesFinancialAccount'],
        'salesFinancialAccount': json['salesFinancialAccount'],
        'commissionFinancialAccount': json['commissionFinancialAccount'],
        'profile': GetProfileDtoFromJSON(json['profile']),
        'active': json['active'],
    };
}

export function GetProfileCategoryDtoToJSON(value?: GetProfileCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'purchasesFinancialAccount': value.purchasesFinancialAccount,
        'salesFinancialAccount': value.salesFinancialAccount,
        'commissionFinancialAccount': value.commissionFinancialAccount,
        'profile': GetProfileDtoToJSON(value.profile),
        'active': value.active,
    };
}

