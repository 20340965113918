/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUpdateEmployeeRoleDto } from './CreateUpdateEmployeeRoleDto';
import {
    CreateUpdateEmployeeRoleDtoFromJSON,
    CreateUpdateEmployeeRoleDtoFromJSONTyped,
    CreateUpdateEmployeeRoleDtoToJSON,
} from './CreateUpdateEmployeeRoleDto';

/**
 * 
 * @export
 * @interface CreateUpdateEmployeeRolesDto
 */
export interface CreateUpdateEmployeeRolesDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeRolesDto
     */
    employeeId: string;
    /**
     * 
     * @type {Array<CreateUpdateEmployeeRoleDto>}
     * @memberof CreateUpdateEmployeeRolesDto
     */
    employeeRoles: Array<CreateUpdateEmployeeRoleDto>;
}

/**
 * Check if a given object implements the CreateUpdateEmployeeRolesDto interface.
 */
export function instanceOfCreateUpdateEmployeeRolesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "employeeRoles" in value;

    return isInstance;
}

export function CreateUpdateEmployeeRolesDtoFromJSON(json: any): CreateUpdateEmployeeRolesDto {
    return CreateUpdateEmployeeRolesDtoFromJSONTyped(json, false);
}

export function CreateUpdateEmployeeRolesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateEmployeeRolesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'employeeRoles': ((json['employeeRoles'] as Array<any>).map(CreateUpdateEmployeeRoleDtoFromJSON)),
    };
}

export function CreateUpdateEmployeeRolesDtoToJSON(value?: CreateUpdateEmployeeRolesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'employeeRoles': ((value.employeeRoles as Array<any>).map(CreateUpdateEmployeeRoleDtoToJSON)),
    };
}

