import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import momencik from '../../../common/momencik';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { PurchaseInvoiceSearch } from '../../components/PurchaseInvoices/PurchaseInvoiceSearch';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetPackageInvoicesXMLDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';

type ParamTypes = {
  id: string;
};

export const PackageFVZView: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();

  const [packageFVZ, setPackageFVZ] = useState<GetPackageInvoicesXMLDto>();

  useEffect(() => {
    if (id) {
      pok.packagesXML
        .getById(id)
        .then(packageFVZ => {
          setPackageFVZ(packageFVZ);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [packageFVZ, notifications, pok.packagesXML, id]);

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.purchaseInvoices.getAllByPackageFVZ(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          id,
        )) as GridGetterFunction,
    [pok.purchaseInvoices, id],
  );

  const getCount = useMemo(
    () =>
      ((filterText: string) =>
        pok.purchaseInvoices.getCountByPackageFVZ(
          filterText,
          id,
        )) as GridCountFunction,
    [pok.purchaseInvoices, id],
  );

  const onRowClick = (id: string) => {
    nav.purchaseInvoiceEditor(id, true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          Faktury zawarte w paczce nr sys. {packageFVZ?.systemNumber} z dnia{' '}
          {momencik(packageFVZ?.creationTs, 'YYYY-MM-DD HH:mm:ss')}
        </Card.Title>

        <PurchaseInvoiceSearch
          getData={getData}
          getCount={getCount}
          displayCurrency={true}
          displayTeamsProjects={true}
          onRowClick={onRowClick}
        />
      </Card.Body>
    </Card>
  );
};
