/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetPurchaseInvoiceWithAttachmentIdDto
 */
export interface GetPurchaseInvoiceWithAttachmentIdDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    systemNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    sellDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    issueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    paymentDate: Date;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    amountWithoutPosition: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    type: GetPurchaseInvoiceWithAttachmentIdDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    status: GetPurchaseInvoiceWithAttachmentIdDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    currency: GetPurchaseInvoiceWithAttachmentIdDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    exchangeRate: string;
    /**
     * 
     * @type {Array<GetProjectDto>}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    projects?: Array<GetProjectDto>;
    /**
     * 
     * @type {Array<GetTeamDto>}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    teams?: Array<GetTeamDto>;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    creationTs: Date;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    tv: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    changeAmountsTs?: Date;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    changeAmountsOwner?: GetEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    attachmentId: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    owner: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceWithAttachmentIdDto
     */
    ts: Date;
}


/**
 * @export
 */
export const GetPurchaseInvoiceWithAttachmentIdDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type GetPurchaseInvoiceWithAttachmentIdDtoTypeEnum = typeof GetPurchaseInvoiceWithAttachmentIdDtoTypeEnum[keyof typeof GetPurchaseInvoiceWithAttachmentIdDtoTypeEnum];

/**
 * @export
 */
export const GetPurchaseInvoiceWithAttachmentIdDtoStatusEnum = {
    Open: 'Open',
    Approved: 'Approved',
    Sent: 'Sent'
} as const;
export type GetPurchaseInvoiceWithAttachmentIdDtoStatusEnum = typeof GetPurchaseInvoiceWithAttachmentIdDtoStatusEnum[keyof typeof GetPurchaseInvoiceWithAttachmentIdDtoStatusEnum];

/**
 * @export
 */
export const GetPurchaseInvoiceWithAttachmentIdDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type GetPurchaseInvoiceWithAttachmentIdDtoCurrencyEnum = typeof GetPurchaseInvoiceWithAttachmentIdDtoCurrencyEnum[keyof typeof GetPurchaseInvoiceWithAttachmentIdDtoCurrencyEnum];


/**
 * Check if a given object implements the GetPurchaseInvoiceWithAttachmentIdDto interface.
 */
export function instanceOfGetPurchaseInvoiceWithAttachmentIdDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "sellDate" in value;
    isInstance = isInstance && "issueDate" in value;
    isInstance = isInstance && "paymentDate" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountWithoutPosition" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "exchangeRate" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTs" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "tv" in value;
    isInstance = isInstance && "attachmentId" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "ts" in value;

    return isInstance;
}

export function GetPurchaseInvoiceWithAttachmentIdDtoFromJSON(json: any): GetPurchaseInvoiceWithAttachmentIdDto {
    return GetPurchaseInvoiceWithAttachmentIdDtoFromJSONTyped(json, false);
}

export function GetPurchaseInvoiceWithAttachmentIdDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaseInvoiceWithAttachmentIdDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'systemNumber': json['systemNumber'],
        'sellDate': (new Date(json['sellDate'])),
        'issueDate': (new Date(json['issueDate'])),
        'paymentDate': (new Date(json['paymentDate'])),
        'client': GetClientDtoFromJSON(json['client']),
        'amount': json['amount'],
        'amountWithoutPosition': json['amountWithoutPosition'],
        'active': json['active'],
        'companyId': json['companyId'],
        'type': json['type'],
        'status': json['status'],
        'currency': json['currency'],
        'exchangeRate': json['exchangeRate'],
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(GetProjectDtoFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(GetTeamDtoFromJSON)),
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'creationTs': (new Date(json['creationTs'])),
        'comment': json['comment'],
        'tv': json['tv'],
        'changeAmountsTs': !exists(json, 'changeAmountsTs') ? undefined : (new Date(json['changeAmountsTs'])),
        'changeAmountsOwner': !exists(json, 'changeAmountsOwner') ? undefined : GetEmployeeDtoFromJSON(json['changeAmountsOwner']),
        'attachmentId': json['attachmentId'],
        'owner': GetEmployeeDtoFromJSON(json['owner']),
        'ts': (new Date(json['ts'])),
    };
}

export function GetPurchaseInvoiceWithAttachmentIdDtoToJSON(value?: GetPurchaseInvoiceWithAttachmentIdDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'systemNumber': value.systemNumber,
        'sellDate': (value.sellDate.toISOString()),
        'issueDate': (value.issueDate.toISOString()),
        'paymentDate': (value.paymentDate.toISOString()),
        'client': GetClientDtoToJSON(value.client),
        'amount': value.amount,
        'amountWithoutPosition': value.amountWithoutPosition,
        'active': value.active,
        'companyId': value.companyId,
        'type': value.type,
        'status': value.status,
        'currency': value.currency,
        'exchangeRate': value.exchangeRate,
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(GetProjectDtoToJSON)),
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(GetTeamDtoToJSON)),
        'creator': GetEmployeeDtoToJSON(value.creator),
        'creationTs': (value.creationTs.toISOString()),
        'comment': value.comment,
        'tv': value.tv,
        'changeAmountsTs': value.changeAmountsTs === undefined ? undefined : (value.changeAmountsTs.toISOString()),
        'changeAmountsOwner': GetEmployeeDtoToJSON(value.changeAmountsOwner),
        'attachmentId': value.attachmentId,
        'owner': GetEmployeeDtoToJSON(value.owner),
        'ts': (value.ts.toISOString()),
    };
}

