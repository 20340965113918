import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';

import { ApplicationSearch } from './ApplicationSearch';

interface UserApplicationTabProps {
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const UserApplicationTab: React.FC<UserApplicationTabProps> = props => {
  const pok = usePokCore();

  return (
    <ApplicationSearch
      getData={pok.applications.getAllByPortionByCurrentUser}
      getCount={pok.applications.getCountByCurrentUser}
      hideAuthorColumn={true}
      hideCompanyColumn={true}
      {...props}
    />
  );
};
