import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Option, Selector } from './Selector';

export interface BrandSelectorProps {
  budgetId?: string;
  isMulti?: boolean;
  refresh?: boolean;
  readOnly?: boolean;
  value?: string | string[];
  valueOption?: Option | MultiValue<Option>;
  className?: string;
  placeholder?: string;
  onChange?: (option: Option | MultiValue<Option> | null | undefined) => void;
}

export default (props: BrandSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [brandOptions, setBrandOptions] = useState<Option[] | undefined>();

  useEffect(() => {
    if (props.budgetId) {
      pok.brands
        .getAllByBudgetId(props.budgetId)
        .then(data => {
          setBrandOptions(
            data.map(({ id, name }) => ({
              value: id,
              label: name || id,
            })),
          );
          if (props.onChange) {
            props.onChange(
              data.map(({ id, name }) => ({ value: id, label: name })),
            );
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setBrandOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.budgetId, props.refresh, notifications, pok.brands]);

  const provider = async (text: string) =>
    brandOptions?.filter(v =>
      v.label?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    ) || [];

  if (!brandOptions) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={`brand-${brandOptions?.length}-${props.budgetId}`}
      className={props.className}
      isMulti={props.isMulti}
      readOnly={props.readOnly || !brandOptions}
      creatable={true}
      clearable={true}
      provider={provider}
      value={props.value}
      valueOption={props.valueOption}
      noOptionsMessage={() => 'Brak wartości'}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  );
};
