import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

import { PermissionSearch } from './PermissionSearch';
import { RoleEditorControls } from './RoleEditorControls';

interface RoleEditorProps {
  id?: string;
  role: CreateUpdateRoleDto;
  handleClose?: () => void;
  handleDelete?: () => void;
  show?: boolean;
  onCompleted: (
    role: CreateUpdateRoleDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const RoleEditor: React.FC<RoleEditorProps> = props => {
  const [role, setRole] = useState(props.role);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const [selectedPermissions, setSelectedPermissions] = useState<
    string[] | undefined
  >(role.permissions || []);

  useEffect(() => {
    setSelectedPermissions(role.permissions);
  }, [role.permissions]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setRole(props.role);
    }
  }, [props.role, props.show]);

  const propertyChange = (obj: Partial<CreateUpdateRoleDto>) => {
    setRole({ ...role, ...obj });
  };

  const handleOnRowClick = (key: string) => {
    let tk: string[] | undefined;
    if (!selectedPermissions?.includes(key)) {
      tk = [...(selectedPermissions || []), key];
    } else {
      tk = selectedPermissions.filter(o => o !== key);
    }
    setSelectedPermissions(tk);
    propertyChange({ permissions: tk });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
        className="modal-on-modal"
        backdropClassName="modal-on-modal-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? 'Edycja roli' : 'Dodawanie nowej roli'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-lg-6 col-12 mb-1">
              <Card>
                <Card.Header>Rola</Card.Header>
                <Card.Body>
                  <Form className="d-grid gap-3">
                    <FormRow controlId="name" label="Nazwa">
                      <Form.Control
                        type="text"
                        value={role?.name || ''}
                        onChange={e => propertyChange({ name: e.target.value })}
                      />
                    </FormRow>
                    <FormRow controlId="description" label="Opis">
                      <DynamicTextarea
                        value={role?.description || ''}
                        onChange={e =>
                          propertyChange({ description: e.target.value })
                        }
                      />
                    </FormRow>
                    <FormRow controlId="permissions" label="Uprawnienia">
                      <DynamicTextarea
                        value={selectedPermissions?.join(', ') || ''}
                        readOnly={true}
                      />
                    </FormRow>
                    <FormRow controlId="active" label="Przypisany do budżetu">
                      <Form.Check
                        type="checkbox"
                        className="pt-2"
                        checked={role.withBudget ?? true}
                        disabled={true}
                        // onChange={e =>
                        //   props.propertyChange({ withBudget: e.target.checked })
                        // }
                      />
                    </FormRow>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-lg-6 col-12 mb-1">
              <Card>
                <Card.Header>Wybrane uprawnienia</Card.Header>
                <Card.Body>
                  <PermissionSearch
                    permissions={Object.values(PermissionsEnum)}
                    onRowClick={handleOnRowClick}
                    selectedKeys={selectedPermissions}
                    withHistory={false}
                  />
                </Card.Body>
              </Card>
            </Col>{' '}
          </Row>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <RoleEditorControls
            {...props}
            role={role}
            setShowSaveErrors={setShowSaveErrors}
            setSaveErrors={setSaveErrors}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
