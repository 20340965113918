import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';

import { PolishClientFormFields } from './PolishClientFormFields';

interface PolishClientProps {
  client: CreateUpdateClientDto | CreateUpdateApplicationDto;
  propertyChange?: (obj: Partial<CreateUpdateClientDto>) => void;
  findByNip?: (text: string) => void;
  readonly?: boolean;
}

export const PolishClient: React.FC<PolishClientProps> = props => {
  return (
    <Form className="d-grid gap-4">
      <PolishClientFormFields
        client={props.client}
        propertyChange={props.propertyChange}
        findByNip={props.findByNip}
        readonly={props.readonly}
      />
    </Form>
  );
};
