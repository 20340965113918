/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAdminApplicationDto
 */
export interface UpdateAdminApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    applicationType: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    teamId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    brandApplicationPurchaserId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminApplicationDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    permissionEmployeeId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    permissionEmployeeBossId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    elementName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    nip: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    addressEx: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    numbers: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    flat: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    applicationStatus: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    purchaserId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    positionId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    brandId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminApplicationDto
     */
    rejectedNote: string;
}

/**
 * Check if a given object implements the UpdateAdminApplicationDto interface.
 */
export function instanceOfUpdateAdminApplicationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationType" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "brandApplicationPurchaserId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "permissionEmployeeId" in value;
    isInstance = isInstance && "permissionEmployeeBossId" in value;
    isInstance = isInstance && "elementName" in value;
    isInstance = isInstance && "profileId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "nip" in value;
    isInstance = isInstance && "addressEx" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "numbers" in value;
    isInstance = isInstance && "flat" in value;
    isInstance = isInstance && "applicationStatus" in value;
    isInstance = isInstance && "purchaserId" in value;
    isInstance = isInstance && "positionId" in value;
    isInstance = isInstance && "brandId" in value;
    isInstance = isInstance && "rejectedNote" in value;

    return isInstance;
}

export function UpdateAdminApplicationDtoFromJSON(json: any): UpdateAdminApplicationDto {
    return UpdateAdminApplicationDtoFromJSONTyped(json, false);
}

export function UpdateAdminApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAdminApplicationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationType': json['applicationType'],
        'description': json['description'],
        'teamId': json['teamId'],
        'brandApplicationPurchaserId': json['brandApplicationPurchaserId'],
        'active': json['active'],
        'companyId': json['companyId'],
        'permissionEmployeeId': json['permissionEmployeeId'],
        'permissionEmployeeBossId': json['permissionEmployeeBossId'],
        'elementName': json['elementName'],
        'profileId': json['profileId'],
        'name': json['name'],
        'shortname': json['shortname'],
        'nip': json['nip'],
        'addressEx': json['addressEx'],
        'postcode': json['postcode'],
        'place': json['place'],
        'street': json['street'],
        'country': json['country'],
        'numbers': json['numbers'],
        'flat': json['flat'],
        'applicationStatus': json['applicationStatus'],
        'purchaserId': json['purchaserId'],
        'positionId': json['positionId'],
        'brandId': json['brandId'],
        'rejectedNote': json['rejectedNote'],
    };
}

export function UpdateAdminApplicationDtoToJSON(value?: UpdateAdminApplicationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationType': value.applicationType,
        'description': value.description,
        'teamId': value.teamId,
        'brandApplicationPurchaserId': value.brandApplicationPurchaserId,
        'active': value.active,
        'companyId': value.companyId,
        'permissionEmployeeId': value.permissionEmployeeId,
        'permissionEmployeeBossId': value.permissionEmployeeBossId,
        'elementName': value.elementName,
        'profileId': value.profileId,
        'name': value.name,
        'shortname': value.shortname,
        'nip': value.nip,
        'addressEx': value.addressEx,
        'postcode': value.postcode,
        'place': value.place,
        'street': value.street,
        'country': value.country,
        'numbers': value.numbers,
        'flat': value.flat,
        'applicationStatus': value.applicationStatus,
        'purchaserId': value.purchaserId,
        'positionId': value.positionId,
        'brandId': value.brandId,
        'rejectedNote': value.rejectedNote,
    };
}

