/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchemaAttributesDto
 */
export interface SchemaAttributesDto {
    /**
     * 
     * @type {string}
     * @memberof SchemaAttributesDto
     */
    displayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaAttributesDto
     */
    required?: boolean;
}

/**
 * Check if a given object implements the SchemaAttributesDto interface.
 */
export function instanceOfSchemaAttributesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "displayName" in value;

    return isInstance;
}

export function SchemaAttributesDtoFromJSON(json: any): SchemaAttributesDto {
    return SchemaAttributesDtoFromJSONTyped(json, false);
}

export function SchemaAttributesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaAttributesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'required': !exists(json, 'required') ? undefined : json['required'],
    };
}

export function SchemaAttributesDtoToJSON(value?: SchemaAttributesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'required': value.required,
    };
}

