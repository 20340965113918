import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  GetEstimateByMonthDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { ApplicationUnblockTypeEnum } from '../../../common/pokCore/validation/schemas';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface ApplicationUnblockSectionProps {
  estimateByMonth?: GetEstimateByMonthDto;
  handleAddApplicationUnblock: () => void;
  toggleBlocked: (value: boolean, type: ApplicationUnblockTypeEnum) => void;
  project?: GetProjectDto;
  isLoading: boolean;
}

export const ApplicationUnblockControls: React.FC<
  ApplicationUnblockSectionProps
> = props => {
  const [isSalesBlocked, setIsSalesBlocked] = useState(
    !!props.estimateByMonth?.isSalesBlocked,
  );
  const [isPurchaseBlocked, setIsPurchaseBlocked] = useState(
    !!props.estimateByMonth?.isSalesBlocked,
  );

  const pok = usePokCore();
  const notifications = useNotifications();
  const auth = useAuth();
  const isToggleBlockAvailable = auth.check(
    PermissionsEnum.Finance,
    props.project?.company.id,
  );

  useEffect(() => {
    if (props.estimateByMonth) {
      pok.estimatesByMonth
        .getById(props.estimateByMonth.id)
        .then(res => {
          setIsSalesBlocked(res.isSalesBlocked);
          setIsPurchaseBlocked(res.isPurchaseBlocked);
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  });

  return (
    <div className="d-flex flex-wrap gap-1 p-0 mb-5">
      {(isSalesBlocked || isPurchaseBlocked) && (
        <Button variant="primary" onClick={props.handleAddApplicationUnblock}>
          Utwórz wniosek o odblokowanie profilu miesięcznego
        </Button>
      )}
      {isToggleBlockAvailable && (
        <>
          <ConfirmationButton
            variant="outline-primary"
            onOK={() =>
              props.toggleBlocked(
                !isSalesBlocked,
                ApplicationUnblockTypeEnum.Sales,
              )
            }
            confirmation={`Czy na pewno chcesz ${
              isSalesBlocked ? 'odblokować' : 'zablokować'
            } sprzedaż?`}
            isLoading={props.isLoading}
          >
            {isSalesBlocked ? 'Odblokuj' : 'Zablokuj'} sprzedaż
          </ConfirmationButton>
          <ConfirmationButton
            variant="outline-primary"
            onOK={() =>
              props.toggleBlocked(
                !isPurchaseBlocked,
                ApplicationUnblockTypeEnum.Purchase,
              )
            }
            confirmation={`Czy na pewno chcesz ${
              isPurchaseBlocked ? 'odblokować' : 'zablokować'
            } zakup?`}
            isLoading={props.isLoading}
          >
            {isPurchaseBlocked ? 'Odblokuj' : 'Zablokuj'} zakup
          </ConfirmationButton>
        </>
      )}
    </div>
  );
};
