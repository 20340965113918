/*eslint complexity: ["error", 13]*/
import React, { ReactNode } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { GetPurchaseInvoiceExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { DownloadAttachment } from '../Attachments/DownloadAttachment';
import ClientInfo from '../Clients/ClientInfo';
import {
  PurchaseInvoiceStatusEnum,
  PurchaseInvoiceStatusEnumText,
} from '../../../common/pokCore/validation/schemas';

const renderAttachmentIcon = (
  purchaseInvoice: GetPurchaseInvoiceExtendedDto | undefined,
) =>
  purchaseInvoice?.attachment ? (
    <DownloadAttachment
      id={purchaseInvoice.attachment.id}
      icon={Icon.FilePdf}
      tooltipText="Otwórz fakturę w nowym oknie"
    />
  ) : null;

function buildPurchaseInvoiceTitle(
  purchaseInvoiceId: string,
  purchaseInvoice?: GetPurchaseInvoiceExtendedDto,
  tv?: boolean,
): ReactNode {
  const inActive = purchaseInvoice && !purchaseInvoice.active;

  return (
    <>
      {inActive && <b>ARCHIWUM </b>}
      <span style={inActive ? { textDecoration: 'line-through' } : {}}>
        {purchaseInvoiceId === 'utworz' ? (
          'Nowa faktura zakupu'
        ) : (
          <>
            <p>
              Faktura zakupu <b>{purchaseInvoice?.number}</b>
              {purchaseInvoice?.client?.name ? (
                <>
                  {' '}
                  dla <b>{purchaseInvoice.client.name}</b>
                  <ClientInfo
                    id={purchaseInvoice.client.id}
                    className="color-primary ms-2 mb-1"
                    iconSize={18}
                    placement="left"
                  />
                </>
              ) : null}
            </p>
            <p className="d-flex">
              {purchaseInvoice
                ? `Nr sys. ${purchaseInvoice.systemNumber} `
                : null}
              {purchaseInvoice?.status ? (
                <>
                  [
                  <b>
                    {PurchaseInvoiceStatusEnumText[
                      purchaseInvoice?.status
                    ].toUpperCase()}
                    {purchaseInvoice?.status ===
                      PurchaseInvoiceStatusEnum.Sent &&
                    purchaseInvoice?.packageFVZNumber
                      ? ` w paczce o nr sys. ${purchaseInvoice?.packageFVZNumber}`
                      : ''}
                  </b>
                  ]
                  {purchaseInvoice?.status !== PurchaseInvoiceStatusEnum.Sent &&
                  purchaseInvoice?.packageFVZNumber ? (
                    <>
                      &nbsp;
                      <span className="text-red">
                        Cofnięta ze stanu wysłana
                      </span>
                    </>
                  ) : null}
                </>
              ) : null}
              {tv && (
                <OverlayTrigger overlay={<Tooltip>Faktura TV</Tooltip>}>
                  <Icon.DisplayFill size={20} className="ms-2 icon-blue" />
                </OverlayTrigger>
              )}
              {renderAttachmentIcon(purchaseInvoice)}
            </p>
          </>
        )}
      </span>
    </>
  );
}

export default buildPurchaseInvoiceTitle;
