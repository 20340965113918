/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExcelConfigDto
 */
export interface CreateUpdateExcelConfigDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigDto
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigDto
     */
    profileCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigDto
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateExcelConfigDto
     */
    excelConfigTeamsIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExcelConfigDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExcelConfigDto
     */
    companyIndependent: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigDto
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExcelConfigDto
     */
    updateExisting: boolean;
}

/**
 * Check if a given object implements the CreateUpdateExcelConfigDto interface.
 */
export function instanceOfCreateUpdateExcelConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "profileId" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "excelConfigTeamsIds" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyIndependent" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "updateExisting" in value;

    return isInstance;
}

export function CreateUpdateExcelConfigDtoFromJSON(json: any): CreateUpdateExcelConfigDto {
    return CreateUpdateExcelConfigDtoFromJSONTyped(json, false);
}

export function CreateUpdateExcelConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExcelConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'profileId': json['profileId'],
        'profileCategoryId': !exists(json, 'profileCategoryId') ? undefined : json['profileCategoryId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'description': json['description'],
        'excelConfigTeamsIds': json['excelConfigTeamsIds'],
        'active': json['active'],
        'companyIndependent': json['companyIndependent'],
        'type': json['type'],
        'updateExisting': json['updateExisting'],
    };
}

export function CreateUpdateExcelConfigDtoToJSON(value?: CreateUpdateExcelConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'profileId': value.profileId,
        'profileCategoryId': value.profileCategoryId,
        'companyId': value.companyId,
        'description': value.description,
        'excelConfigTeamsIds': value.excelConfigTeamsIds,
        'active': value.active,
        'companyIndependent': value.companyIndependent,
        'type': value.type,
        'updateExisting': value.updateExisting,
    };
}

