import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateBudgetDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';

import { BudgetEditorForm } from './BudgetEditorForm';

interface BudgetModalEditorProps {
  id?: string;
  systemNumber?: number;
  budget: CreateUpdateBudgetDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    budget: CreateUpdateBudgetDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const BudgetModalEditor: React.FC<BudgetModalEditorProps> = props => {
  const [budget, setBudget] = useState(props.budget);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setBudget(props.budget);
    }
  }, [props.budget, props.show]);

  const createBudget = async () => {
    setIsLoading(true);
    props
      .onCompleted(budget, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateBudgetDto>) => {
    setBudget(prevState => ({ ...prevState, ...obj }));
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `Edycja budżetu nr sys. ${props.systemNumber}`
              : 'Dodawanie nowego budżetu'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BudgetEditorForm
            id={props.id}
            budget={budget}
            propertyChange={propertyChange}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo id={props.id} method={pok.budgets.getLastEditor} />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            Zamknij
          </Button>
          <Button
            variant="primary"
            onClick={createBudget}
            isLoading={isLoading}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
