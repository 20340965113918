import {
  DefaultApi,
  GetRoleDto,
  GetLastEditorDto,
  CreateUpdateRoleDto,
  CreateUpdateRoleDtoFromJSON,
} from '../autogenerated/pokApiClient';
import { RoleSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface RoleContextInterface {
  getById: (id: string) => Promise<GetRoleDto>;
  getMany: () => Promise<GetRoleDto[]>;
  update: (id: string, dto: CreateUpdateRoleDto) => Promise<void>;
  create: (dto: CreateUpdateRoleDto) => Promise<GetRoleDto>;
  delete: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const RoleContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.roleControllerGet(id),
    getMany: () => api.roleControllerGetMany(),
    update: (id: string, dto: CreateUpdateRoleDto) =>
      api.roleControllerUpdate(id, dto),
    create: (dto: CreateUpdateRoleDto) => api.roleControllerCreate(dto),
    delete: (id: string) => api.roleControllerDelete(id),
    getLastEditor: (id: string) => api.roleControllerGetLastEditor(id),
  };
};

export const newRole = () => {
  return CreateUpdateRoleDtoFromJSON({});
};

export const validate = (dictionary: CreateUpdateRoleDto) =>
  validateAgainst(RoleSchema, dictionary);

export const convert = (role?: GetRoleDto) => {
  if (!role) {
    return newRole();
  }
  const converted = CreateUpdateRoleDtoFromJSON(role);

  return converted;
};
