/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';

/**
 * 
 * @export
 * @interface GetBudgetDto
 */
export interface GetBudgetDto {
    /**
     * 
     * @type {string}
     * @memberof GetBudgetDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetBudgetDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetBudgetDto
     */
    name: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetBudgetDto
     */
    manager: GetEmployeeDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetBudgetDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBudgetDto
     */
    downloadDocuments: boolean;
}

/**
 * Check if a given object implements the GetBudgetDto interface.
 */
export function instanceOfGetBudgetDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "manager" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "downloadDocuments" in value;

    return isInstance;
}

export function GetBudgetDtoFromJSON(json: any): GetBudgetDto {
    return GetBudgetDtoFromJSONTyped(json, false);
}

export function GetBudgetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBudgetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'name': json['name'],
        'manager': GetEmployeeDtoFromJSON(json['manager']),
        'active': json['active'],
        'downloadDocuments': json['downloadDocuments'],
    };
}

export function GetBudgetDtoToJSON(value?: GetBudgetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'name': value.name,
        'manager': GetEmployeeDtoToJSON(value.manager),
        'active': value.active,
        'downloadDocuments': value.downloadDocuments,
    };
}

