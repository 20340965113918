import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateApplicationUnblockDto,
  GetApplicationUnblockDtoStatusEnum,
  GetEstimateByMonthDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import {
  ApplicationUnblockStatusEnumText,
  ApplicationUnblockTypeEnum,
  ApplicationUnblockTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';
import BooleanDropdown from '../../../app/components/BooleanDropdown';

interface ApplicationUnblockEditorFormProps {
  applicationUnblock: CreateUpdateApplicationUnblockDto;
  estimateByMonth?: GetEstimateByMonthDto;
  readOnly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateApplicationUnblockDto>) => void;
  status?: GetApplicationUnblockDtoStatusEnum;
  rejectedNote?: string;
}

const typeOptions = Object.entries(ApplicationUnblockTypeEnum).map(
  ([key, value]) => ({
    value: key as ApplicationUnblockTypeEnum,
    label: ApplicationUnblockTypeEnumText[value],
  }),
);

export const ApplicationUnblockEditorForm: React.FC<
  ApplicationUnblockEditorFormProps
> = props => {
  const { t, tk } = useTranslation('menu');

  return (
    <Form className="d-grid gap-3">
      {props.estimateByMonth?.project && (
        <FormRow controlId="project" label={t(tk.menu.project)}>
          <Form.Control
            type="text"
            value={`${props.estimateByMonth?.project.name} (${props.estimateByMonth?.project.number})`}
            disabled
          />
        </FormRow>
      )}
      <FormRow controlId="profile" label="Profil">
        <Form.Control
          type="text"
          value={props.estimateByMonth?.profile.name || ''}
          disabled
        />
      </FormRow>
      <FormRow controlId="date" label="Miesiąc">
        <Form.Control
          type="text"
          value={momencik(props.estimateByMonth?.date, 'YYYY-MM')}
          disabled
        />
      </FormRow>
      <FormRow controlId="type" label="Typ zgłoszenia">
        <Select
          value={
            props.applicationUnblock.type
              ? typeOptions.find(
                  ({ value }) => props.applicationUnblock.type === value,
                )
              : null
          }
          options={typeOptions}
          onChange={option =>
            props.propertyChange?.({
              type: option?.value,
            })
          }
          placeholder="Wskaż..."
          isDisabled={props.readOnly}
        />
      </FormRow>
      <FormRow controlId="description" label="Powód zgłoszenia">
        <DynamicTextarea
          value={props.applicationUnblock.description || ''}
          onChange={e =>
            props.propertyChange?.({ description: e.target.value })
          }
          disabled={props.readOnly}
        />
      </FormRow>
      <FormRow
        controlId="salesCorrect"
        label="Zgłoszenie dotyczy korekty sprzedaży"
      >
        <BooleanDropdown
          readOnly={props.readOnly}
          value={props.applicationUnblock.salesCorrect}
          onChange={value => props.propertyChange?.({ salesCorrect: value })}
          canBeUndefined={true}
        />
      </FormRow>

      <FormRow
        controlId="inDocumentation"
        label="Zmiana ma potwierdzenie w dokumentacji"
      >
        <BooleanDropdown
          readOnly={props.readOnly}
          value={props.applicationUnblock.inDocumentation}
          onChange={value => props.propertyChange?.({ inDocumentation: value })}
          canBeUndefined={true}
        />
      </FormRow>

      {props.status &&
        props.status !== GetApplicationUnblockDtoStatusEnum.New && (
          <FormRow controlId="status" label="Status">
            <Form.Control
              type="text"
              value={ApplicationUnblockStatusEnumText[props.status] || ''}
              disabled
            />
          </FormRow>
        )}
      {props.status === GetApplicationUnblockDtoStatusEnum.Rejected && (
        <FormRow controlId="status" label="Uzasadnienie odrzucenia">
          <Form.Control type="text" value={props.rejectedNote || ''} disabled />
        </FormRow>
      )}
    </Form>
  );
};
