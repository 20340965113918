import mathUtils from '../../../utils/mathUtils';
import {
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';

const checkEstimatesValue = (
  availableItem?: GetEstimateItemDto,
  itemAmount?: string,
  purchaseInvoiceAmount?: string,
  sumFromSaved?: string,
) => {
  if (!availableItem) {
    return false;
  }

  const invoiceAmount = mathUtils.parse(purchaseInvoiceAmount);
  return (
    invoiceAmount *
      (mathUtils.parse(availableItem.purchaseNetTotal) -
        mathUtils.parse(availableItem.purchaseInvoicesSum) +
        mathUtils.parse(sumFromSaved)) <
    mathUtils.parse(itemAmount) * invoiceAmount
  );
};

export const checkDivergedPurchaseInvoiceAmounts = (
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[],
  availableEstimateItems: GetEstimateItemDto[],
  amount: string,
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto,
) => {
  const hasNegativeAmounts = purchaseInvoiceEstimateItems.some(item => {
    const avItem = availableEstimateItems.find(
      availableItem => availableItem.id === item.estimateItemId,
    );
    return (
      avItem &&
      mathUtils.parse(avItem.purchaseNetTotal) ** 2 <
        mathUtils.parse(avItem.purchaseNetTotal) * mathUtils.parse(item.amount)
    );
  });

  const hasMoreThenAvailableAmounts = purchaseInvoiceEstimateItems.some(
    item => {
      const availableItem = availableEstimateItems.find(
        availableItem => availableItem.id === item.estimateItemId,
      );

      const sumFromSaved =
        extendedPurchaseInvoice?.purchaseInvoiceEstimateItems?.find(
          curr => curr.estimateItem.id === availableItem?.id,
        )?.amount ?? '0';

      return checkEstimatesValue(
        availableItem,
        item.amount,
        amount,
        sumFromSaved,
      );
    },
  );

  return { hasNegativeAmounts, hasMoreThenAvailableAmounts };
};
