import React, { useMemo } from 'react';

import {
  GetEstimateByMonthDto,
  GetEstimateItemDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { EstimateItemsGrid } from './EstimateItemsGrid';

interface EstimateSummaryTabProps {
  estimateByMonth?: GetEstimateByMonthDto;
  refresh: boolean;
}

export const EstimateSummaryTab: React.FC<EstimateSummaryTabProps> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [estimateItems, setEstimateItems] = React.useState<
    GetEstimateItemDto[]
  >([]);

  useMemo(() => {
    pok.estimateItems
      .findByEstimateMonth(props.estimateByMonth?.id || '')
      .then(items => {
        setEstimateItems(items);
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notifications,
    pok.estimateItems,
    props.estimateByMonth?.id,
    props.refresh,
  ]);

  return (
    <div className="mb-5 mt-5">
      <EstimateItemsGrid
        data={estimateItems}
        schema={props.estimateByMonth?.profile.schema}
      />
    </div>
  );
};
