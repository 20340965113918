/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetExcelConfigHeaderDto } from './GetExcelConfigHeaderDto';
import {
    GetExcelConfigHeaderDtoFromJSON,
    GetExcelConfigHeaderDtoFromJSONTyped,
    GetExcelConfigHeaderDtoToJSON,
} from './GetExcelConfigHeaderDto';
import type { GetExcelConfigPositionDto } from './GetExcelConfigPositionDto';
import {
    GetExcelConfigPositionDtoFromJSON,
    GetExcelConfigPositionDtoFromJSONTyped,
    GetExcelConfigPositionDtoToJSON,
} from './GetExcelConfigPositionDto';
import type { GetExcelConfigTeamDto } from './GetExcelConfigTeamDto';
import {
    GetExcelConfigTeamDtoFromJSON,
    GetExcelConfigTeamDtoFromJSONTyped,
    GetExcelConfigTeamDtoToJSON,
} from './GetExcelConfigTeamDto';
import type { GetProfileCategoryDto } from './GetProfileCategoryDto';
import {
    GetProfileCategoryDtoFromJSON,
    GetProfileCategoryDtoFromJSONTyped,
    GetProfileCategoryDtoToJSON,
} from './GetProfileCategoryDto';
import type { GetProfileDto } from './GetProfileDto';
import {
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './GetProfileDto';

/**
 * 
 * @export
 * @interface GetExcelConfigDto
 */
export interface GetExcelConfigDto {
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigDto
     */
    name: string;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetExcelConfigDto
     */
    profile: GetProfileDto;
    /**
     * 
     * @type {GetProfileCategoryDto}
     * @memberof GetExcelConfigDto
     */
    profileCategory: GetProfileCategoryDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetExcelConfigDto
     */
    company?: GetCompanyDto;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigDto
     */
    description: string;
    /**
     * 
     * @type {Array<GetExcelConfigTeamDto>}
     * @memberof GetExcelConfigDto
     */
    excelConfigTeams?: Array<GetExcelConfigTeamDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelConfigDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigDto
     */
    type: string;
    /**
     * 
     * @type {Array<GetExcelConfigHeaderDto>}
     * @memberof GetExcelConfigDto
     */
    excelConfigHeaders?: Array<GetExcelConfigHeaderDto>;
    /**
     * 
     * @type {Array<GetExcelConfigPositionDto>}
     * @memberof GetExcelConfigDto
     */
    excelConfigPositions?: Array<GetExcelConfigPositionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelConfigDto
     */
    updateExisting: boolean;
}

/**
 * Check if a given object implements the GetExcelConfigDto interface.
 */
export function instanceOfGetExcelConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "profileCategory" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "updateExisting" in value;

    return isInstance;
}

export function GetExcelConfigDtoFromJSON(json: any): GetExcelConfigDto {
    return GetExcelConfigDtoFromJSONTyped(json, false);
}

export function GetExcelConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExcelConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'profile': GetProfileDtoFromJSON(json['profile']),
        'profileCategory': GetProfileCategoryDtoFromJSON(json['profileCategory']),
        'company': !exists(json, 'company') ? undefined : GetCompanyDtoFromJSON(json['company']),
        'description': json['description'],
        'excelConfigTeams': !exists(json, 'excelConfigTeams') ? undefined : ((json['excelConfigTeams'] as Array<any>).map(GetExcelConfigTeamDtoFromJSON)),
        'active': json['active'],
        'type': json['type'],
        'excelConfigHeaders': !exists(json, 'excelConfigHeaders') ? undefined : ((json['excelConfigHeaders'] as Array<any>).map(GetExcelConfigHeaderDtoFromJSON)),
        'excelConfigPositions': !exists(json, 'excelConfigPositions') ? undefined : ((json['excelConfigPositions'] as Array<any>).map(GetExcelConfigPositionDtoFromJSON)),
        'updateExisting': json['updateExisting'],
    };
}

export function GetExcelConfigDtoToJSON(value?: GetExcelConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'profile': GetProfileDtoToJSON(value.profile),
        'profileCategory': GetProfileCategoryDtoToJSON(value.profileCategory),
        'company': GetCompanyDtoToJSON(value.company),
        'description': value.description,
        'excelConfigTeams': value.excelConfigTeams === undefined ? undefined : ((value.excelConfigTeams as Array<any>).map(GetExcelConfigTeamDtoToJSON)),
        'active': value.active,
        'type': value.type,
        'excelConfigHeaders': value.excelConfigHeaders === undefined ? undefined : ((value.excelConfigHeaders as Array<any>).map(GetExcelConfigHeaderDtoToJSON)),
        'excelConfigPositions': value.excelConfigPositions === undefined ? undefined : ((value.excelConfigPositions as Array<any>).map(GetExcelConfigPositionDtoToJSON)),
        'updateExisting': value.updateExisting,
    };
}

