import {
  DefaultApi,
  GetDictionaryTypeDto,
} from '../autogenerated/pokApiClient';

export interface DictionaryTypeContextInterface {
  getById: (id: string) => Promise<GetDictionaryTypeDto>;
  getAll: () => Promise<GetDictionaryTypeDto[]>;
}

export const DictionaryTypeContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.dictionaryTypeControllerGet(id),
    getAll: () =>
      api
        .dictionaryTypeControllerGetAll()
        .then(orgUnit =>
          orgUnit.sort((a, b) => a.name.localeCompare(b.name, 'pl')),
        ),
  };
};
