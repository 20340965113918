import { DefaultApi, GetEmailDto } from '../autogenerated/pokApiClient';
import { ProjectApplicationTypeEnum } from '../validation/schemas';

export interface EmailContextInterface {
  sendOrderEmail: (id: string) => Promise<GetEmailDto>;
  sendOrderExecutionEmail: (id: string) => Promise<GetEmailDto>;
  sendApplicationEmail: (id: string) => Promise<GetEmailDto>;
  sendApplicationExecutionEmail: (id: string) => Promise<GetEmailDto>;
  sendProjectEmail: (
    id: string,
    type: ProjectApplicationTypeEnum,
  ) => Promise<GetEmailDto>;
  sendProjectResponseEmail: (
    id: string,
    type: ProjectApplicationTypeEnum,
    accepted: boolean,
  ) => Promise<GetEmailDto>;
  sendUnblockEmail: (id: string) => Promise<GetEmailDto>;
  sendPurchaseDeficienciesEmail: (
    tv: boolean,
    email: string[],
    message: string,
    teamId?: string,
  ) => Promise<GetEmailDto>;
  sendSpecialInvoiceEmail: (
    id: string,
    correction?: boolean,
  ) => Promise<GetEmailDto>;
}

export const EmailContext = (api: DefaultApi) => {
  return {
    sendOrderEmail: (id: string) => api.emailControllerSendOrderEmail(id),
    sendOrderExecutionEmail: (id: string) =>
      api.emailControllerSendOrderExecutionEmail(id),
    sendApplicationEmail: (id: string) =>
      api.emailControllerSendApplicationEmail(id),
    sendApplicationExecutionEmail: (id: string) =>
      api.emailControllerSendApplicationExecutionEmail(id),
    sendProjectEmail: (id: string, type: ProjectApplicationTypeEnum) =>
      api.emailControllerSendProjectEmail(id, type),
    sendProjectResponseEmail: (
      id: string,
      type: ProjectApplicationTypeEnum,
      accepted: boolean,
    ) => api.emailControllerSendProjectResponseEmail(id, type, accepted),
    sendUnblockEmail: (id: string) => api.emailControllerSendUnblockEmail(id),
    sendPurchaseDeficienciesEmail: (
      tv: boolean,
      email: string[],
      message: string,
      teamId?: string,
    ) =>
      api.emailControllerSendPurchaseDeficienciesEmail(
        tv,
        email,
        message,
        teamId,
      ),
    sendSpecialInvoiceEmail: (id: string, correction?: boolean) =>
      api.emailControllerSendSpecialInvoiceEmail(id, correction),
  };
};
