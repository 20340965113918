import React from 'react';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

interface InternetProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const InternetProfileParams: React.FC<
  InternetProfileParamsProps
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.purchaseFormInternet && (
        <FormRow
          controlId="purchaseFormInternet"
          label={schema?.purchaseFormInternet?.displayName}
        >
          <DictionarySelector
            dictionary="Internet - forma zakupu"
            value={props.estimateItem?.purchaseFormInternetId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternetId: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.categoryInternet && (
        <FormRow
          controlId="categoryInternet"
          label={schema?.categoryInternet?.displayName}
        >
          <DictionarySelector
            dictionary="Internet - kategoria"
            value={props.estimateItem?.categoryInternetId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternetId: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.formatInternet && (
        <FormRow
          controlId="formatInternet"
          label={schema?.formatInternet?.displayName}
        >
          <DictionarySelector
            dictionary="Internet - format"
            value={props.estimateItem?.formatInternetId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternetId: option.value,
              })
            }
          />
        </FormRow>
      )}
    </>
  );
};
