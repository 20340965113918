import React, { useEffect, useState } from 'react';

import momencik from '../../../common/momencik';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface InternalProjectSelectorProps {
  readOnly?: boolean;
  purchaserId?: string;
  projects?: GetProjectDto[];
  value?: string;
  className?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
}

export default (props: InternalProjectSelectorProps) => {
  const [projects, setProjects] = useState<GetProjectDto[]>([]);
  const [value, setValue] = useState<string>();
  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    if (props.projects) {
      setProjects(props.projects);
    }
  }, [props.projects]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (value && !projects.some(p => p.id === value)) {
      pok.projects
        .getById(value)
        .then(res => {
          if (res) {
            setProjects([...projects, res]);
          }
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  }, [notifications, pok.projects, projects, value]);

  const provider = async (text: string) => {
    const values = projects.filter(
      v =>
        v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) ||
        v.number?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );

    return values.map(p => {
      return {
        badge: '',
        label: `${p.number} - ${p.name}   (${momencik(
          p.startDate,
          'L',
        )} - ${momencik(p.endDate, 'L')})`,
        value: p.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={'project-' + projects?.map(o => o.id).join('_')}
      className={props.className}
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={provider}
      value={value}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
