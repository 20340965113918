/*eslint max-lines-per-function: ["error", 220]*/
import React, { useMemo } from 'react';
import { Accordion } from 'react-bootstrap';

import {
  GetEstimateByMonthDto,
  GetEstimateByMonthDtoStatusEnum,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { ProjectEstimatesStatusEnumText } from '../../../common/pokCore/validation/schemas';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import mathUtils from '../../../utils/mathUtils';
import numberFormatter from '../../../common/numberFormatter';

import { EstimateProfileTabs } from './EstimateProfileTabs';

interface EstimateProfileAccordionProps {
  estimateByMonth: GetEstimateByMonthDto;
  activeItemIdx?: string;
  project?: GetProjectDto;
  paeTeam: boolean;
  refresh: boolean;
  handleOnClick: () => void;
  onDelete: (id?: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  clone: (
    id: string,
    date: Date,
    withAmounts: boolean,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const EstimateProfileAccordion: React.FC<
  EstimateProfileAccordionProps
> = props => {
  const showMargin = !!props.project?.purchaser?.company?.showMargin;
  const [status, setStatus] = React.useState<string>(
    ProjectEstimatesStatusEnumText[props.estimateByMonth?.status],
  );
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [saleGlobalBlocked, setSaleGlobalBlocked] =
    React.useState<boolean>(false);
  const [purchaseGlobalBlocked, setPurchaseGlobalBlocked] =
    React.useState<boolean>(false);
  const [estimateSums, setEstimateSums] = React.useState({
    sumSalesNetTotal: 0,
    sumPurchaseNetTotal: 0,
    sumPurchaseInvoicesSum: 0,
  });

  const pok = usePokCore();
  const notifications = useNotifications();

  useMemo(() => {
    pok.estimateItems
      .findByEstimateMonth(props.estimateByMonth.id)
      .then(res => {
        let sumSalesNetTotal = 0;
        let sumPurchaseNetTotal = 0;
        let sumPurchaseInvoicesSum = 0;
        res?.forEach(item => {
          sumSalesNetTotal = mathUtils.add(
            sumSalesNetTotal,
            item.salesNetTotal,
          );
          sumPurchaseNetTotal = mathUtils.add(
            sumPurchaseNetTotal,
            item.purchaseNetTotal,
          );
          sumPurchaseInvoicesSum = mathUtils.add(
            sumPurchaseInvoicesSum,
            item.purchaseInvoicesSum,
          );
        });
        setEstimateSums({
          sumSalesNetTotal: sumSalesNetTotal,
          sumPurchaseNetTotal: sumPurchaseNetTotal,
          sumPurchaseInvoicesSum: sumPurchaseInvoicesSum,
        });
      })
      .catch(err => {
        notifications.caughtError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.estimateByMonth.id,
    pok.estimateItems,
    notifications,
    refresh,
    props.refresh,
  ]);

  useMemo(() => {
    pok.blockades
      .checkBlockedMonth(
        props.estimateByMonth.date,
        pok.getCompanyId(),
        props.project?.projectTeams?.map(o => o.team?.id) || [],
        true,
        false,
      )
      .then(res => {
        setSaleGlobalBlocked(res.toString() === 'true');
      })
      .catch(err => {
        notifications.caughtError(err);
      });
    pok.blockades
      .checkBlockedMonth(
        props.estimateByMonth.date,
        pok.getCompanyId(),
        props.project?.projectTeams?.map(o => o.team?.id) || [],
        false,
        true,
      )
      .then(res => {
        setPurchaseGlobalBlocked(res.toString() === 'true');
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  }, [pok, props.estimateByMonth, props.project, notifications]);

  const generateTitle = () => {
    return (
      <section
        className={`layout-accordion-header ${
          props.estimateByMonth.status ===
          GetEstimateByMonthDtoStatusEnum.Cancelled
            ? 'deleted-item-accordion'
            : ''
        }`}
      >
        <div className="grow1">
          <b>{props.estimateByMonth?.profile.name}</b>&nbsp;
          {momencik(props.estimateByMonth?.date, 'YYYY-MM')} ({status})
        </div>
        <div className="marginLeft">
          Sprzedaż:{' '}
          <b>{numberFormatter(estimateSums.sumSalesNetTotal, true)}</b>{' '}
        </div>
        <div className="marginLeft">
          Zakup:{' '}
          <b>{numberFormatter(estimateSums.sumPurchaseNetTotal, true)}</b>{' '}
        </div>
        <div className="marginLeft">
          FVZ:{' '}
          <b>{numberFormatter(estimateSums.sumPurchaseInvoicesSum, true)}</b>{' '}
        </div>
        <div className="marginLeft">
          Różnica FVZ:{' '}
          <b>
            {numberFormatter(
              mathUtils.subtract(
                estimateSums.sumPurchaseNetTotal || 0,
                estimateSums.sumPurchaseInvoicesSum || 0,
              ),
              true,
            )}
          </b>{' '}
        </div>
        {showMargin && (
          <div className="marginLeft">
            Marża:{' '}
            <b>
              {numberFormatter(
                mathUtils.subtract(
                  estimateSums.sumSalesNetTotal || 0,
                  estimateSums.sumPurchaseNetTotal || 0,
                ),
                true,
              )}
            </b>{' '}
          </div>
        )}
      </section>
    );
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Accordion.Item
      eventKey={props.estimateByMonth.id}
      className={`flex-fill ${
        props.activeItemIdx === props.estimateByMonth.id
          ? 'active-item-accordion'
          : ''
      }`}
    >
      <Accordion.Header
        onClick={props.handleOnClick}
        className={`${
          props.activeItemIdx === props.estimateByMonth.id
            ? ''
            : 'profile-accordion'
        }`}
      >
        {generateTitle()}
      </Accordion.Header>
      <Accordion.Body>
        {props.activeItemIdx === props.estimateByMonth.id && (
          <EstimateProfileTabs
            estimateByMonth={props.estimateByMonth}
            project={props.project}
            paeTeam={props.paeTeam}
            onDelete={props.onDelete}
            updateStatus={setStatus}
            onRefresh={onRefresh}
            clone={props.clone}
            saleGlobalBlocked={saleGlobalBlocked}
            purchaseGlobalBlocked={purchaseGlobalBlocked}
          />
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};
