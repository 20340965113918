/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';

/**
 * 
 * @export
 * @interface GetSalesInvoiceProjectDto
 */
export interface GetSalesInvoiceProjectDto {
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceProjectDto
     */
    id: string;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetSalesInvoiceProjectDto
     */
    project: GetProjectDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoiceProjectDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetSalesInvoiceProjectDto interface.
 */
export function instanceOfGetSalesInvoiceProjectDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetSalesInvoiceProjectDtoFromJSON(json: any): GetSalesInvoiceProjectDto {
    return GetSalesInvoiceProjectDtoFromJSONTyped(json, false);
}

export function GetSalesInvoiceProjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalesInvoiceProjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'project': GetProjectDtoFromJSON(json['project']),
        'active': json['active'],
    };
}

export function GetSalesInvoiceProjectDtoToJSON(value?: GetSalesInvoiceProjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project': GetProjectDtoToJSON(value.project),
        'active': value.active,
    };
}

