import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';

import {
  CreateUpdateSalesInvoiceDto,
  CreateUpdateSalesInvoiceDtoStatusEnum,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';

interface SalesInvoicePrintControlsProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  invoiceStatus: CreateUpdateSalesInvoiceDtoStatusEnum;
  id?: string;
  setIsButtonsBlocked: Dispatch<SetStateAction<boolean>>;
}

const getDropdownTitle = (isLoading: boolean, title: string) => (
  <>
    {isLoading && <Spinner size={'sm'} className="me-1" />}
    {title}
  </>
);

export const SalesInvoicePrintControls: React.FC<
  SalesInvoicePrintControlsProps
> = props => {
  const { setIsButtonsBlocked } = props;
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [isPrintoutLoading, setIsPrintoutLoading] = useState(false);
  const [hasInvoiceSavedPdf, setHasInvoiceSavedPdf] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const notifications = useNotifications();
  const pok = usePokCore();

  const shouldDisableButtons =
    isPrintLoading || isDuplicateLoading || isCopyLoading || isPrintoutLoading;

  const printInvoice = async (
    download: boolean,
    duplicate: boolean,
    copy: boolean,
  ) => {
    try {
      if (download) {
        await pok.pdfPrinters.downloadInvoice(props.id!, duplicate, copy);
      } else {
        await pok.pdfPrinters.openInvoice(props.id!, duplicate, copy);
      }
    } catch (error) {
      notifications.caughtError(error as ResponseError);
    } finally {
      setIsPrintLoading(false);
      setIsDuplicateLoading(false);
      setIsCopyLoading(false);
    }
    setRefresh(!refresh);
  };

  const invoiceDataPrintout = async () => {
    try {
      setIsPrintoutLoading(true);
      await pok.pdfPrinters.invoiceDataPrintout(props.id!);
      setIsPrintoutLoading(false);
    } catch (error) {
      notifications.caughtError(error as ResponseError);
      setIsPrintoutLoading(false);
    }
  };

  useEffect(() => {
    if (
      props.id &&
      props.invoiceStatus !== CreateUpdateSalesInvoiceDtoStatusEnum.Draft
    ) {
      pok.savedPdfs
        .hasInvoiceSavedPdf(props.id)
        .then(setHasInvoiceSavedPdf)
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [notifications, pok.savedPdfs, props.id, props.invoiceStatus, refresh]);

  useEffect(() => {
    setIsButtonsBlocked(shouldDisableButtons);
  }, [setIsButtonsBlocked, shouldDisableButtons]);

  return (
    <>
      {props.invoiceStatus !== CreateUpdateSalesInvoiceDtoStatusEnum.Draft && (
        <>
          <DropdownButton
            title={getDropdownTitle(isPrintLoading, 'Wydruk')}
            id="print-dropdown"
            variant="primary"
            className="d-inline-block"
            disabled={shouldDisableButtons}
          >
            <Dropdown.Item
              onClick={() => {
                setIsPrintLoading(true);
                return printInvoice(true, false, false);
              }}
              eventKey="1"
            >
              Pobierz
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setIsPrintLoading(true);
                return printInvoice(false, false, false);
              }}
              eventKey="2"
            >
              Otwórz
            </Dropdown.Item>
          </DropdownButton>
          <Button
            variant="primary"
            onClick={() => invoiceDataPrintout()}
            isLoading={isPrintoutLoading}
            disabled={shouldDisableButtons}
          >
            Dane do FVS
          </Button>
        </>
      )}
      {props.invoiceStatus !== CreateUpdateSalesInvoiceDtoStatusEnum.Draft &&
        hasInvoiceSavedPdf && (
          <>
            <DropdownButton
              title={getDropdownTitle(isDuplicateLoading, 'Duplikat')}
              id="print-dropdown"
              variant="primary"
              className="d-inline-block"
              disabled={shouldDisableButtons}
            >
              <Dropdown.Item
                onClick={() => {
                  setIsDuplicateLoading(true);
                  return printInvoice(true, true, false);
                }}
                eventKey="1"
              >
                Pobierz
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setIsDuplicateLoading(true);
                  return printInvoice(false, true, false);
                }}
                eventKey="2"
              >
                Otwórz
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              title={getDropdownTitle(isCopyLoading, 'Kopia')}
              id="print-dropdown"
              variant="primary"
              className="d-inline-block"
              disabled={shouldDisableButtons}
            >
              <Dropdown.Item
                onClick={() => {
                  setIsCopyLoading(true);
                  return printInvoice(true, false, true);
                }}
                eventKey="1"
              >
                Pobierz
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setIsCopyLoading(true);
                  return printInvoice(false, false, true);
                }}
                eventKey="2"
              >
                Otwórz
              </Dropdown.Item>
            </DropdownButton>
          </>
        )}
    </>
  );
};
