import React from 'react';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetPositionDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

import { isFieldHidden } from './CommissionService';

interface SpecialSalesParams2Props {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  position?: GetPositionDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const SpecialSalesParams2: React.FC<
  SpecialSalesParams2Props
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.serviceStrategyPercent &&
        !isFieldHidden(
          'serviceStrategyPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="serviceStrategyPercent"
            label={schema?.serviceStrategyPercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.serviceStrategyPercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  serviceStrategyPercent: value,
                })
              }
            />
          </FormRow>
        )}

      {schema?.serviceProductionBroadcastPercent &&
        !isFieldHidden(
          'serviceProductionBroadcastPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="serviceProductionBroadcastPercent"
            label={schema?.serviceProductionBroadcastPercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.serviceProductionBroadcastPercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  serviceProductionBroadcastPercent: value,
                })
              }
            />
          </FormRow>
        )}

      {schema?.serviceClientServicePercent &&
        !isFieldHidden(
          'serviceClientServicePercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="serviceClientServicePercent"
            label={schema?.serviceClientServicePercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.serviceClientServicePercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  serviceClientServicePercent: value,
                })
              }
            />
          </FormRow>
        )}
    </>
  );
};
