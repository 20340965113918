/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetOrderExecutionDto } from './GetOrderExecutionDto';
import {
    GetOrderExecutionDtoFromJSON,
    GetOrderExecutionDtoFromJSONTyped,
    GetOrderExecutionDtoToJSON,
} from './GetOrderExecutionDto';

/**
 * 
 * @export
 * @interface GetOrderExecutionPositionDto
 */
export interface GetOrderExecutionPositionDto {
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionPositionDto
     */
    id: string;
    /**
     * 
     * @type {GetOrderExecutionDto}
     * @memberof GetOrderExecutionPositionDto
     */
    orderExecution: GetOrderExecutionDto;
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionPositionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionPositionDto
     */
    amount: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrderExecutionPositionDto
     */
    order: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderExecutionPositionDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionPositionDto
     */
    actionGroup: string;
}

/**
 * Check if a given object implements the GetOrderExecutionPositionDto interface.
 */
export function instanceOfGetOrderExecutionPositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orderExecution" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "actionGroup" in value;

    return isInstance;
}

export function GetOrderExecutionPositionDtoFromJSON(json: any): GetOrderExecutionPositionDto {
    return GetOrderExecutionPositionDtoFromJSONTyped(json, false);
}

export function GetOrderExecutionPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderExecutionPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderExecution': GetOrderExecutionDtoFromJSON(json['orderExecution']),
        'name': json['name'],
        'amount': json['amount'],
        'order': json['order'],
        'active': json['active'],
        'actionGroup': json['actionGroup'],
    };
}

export function GetOrderExecutionPositionDtoToJSON(value?: GetOrderExecutionPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderExecution': GetOrderExecutionDtoToJSON(value.orderExecution),
        'name': value.name,
        'amount': value.amount,
        'order': value.order,
        'active': value.active,
        'actionGroup': value.actionGroup,
    };
}

