import { CurrencyIsoTypeEnum } from './pokCore/validation/schemas';

export default (
  value: string | number | null,
  currency = false,
  currencyIso?: CurrencyIsoTypeEnum | undefined,
) =>
  value !== null && value !== undefined
    ? currency
      ? Intl.NumberFormat('pl-PL', {
          style: 'currency',
          currency: currencyIso || CurrencyIsoTypeEnum.PLN,
          useGrouping: true,
        }).format(Number(value))
      : Intl.NumberFormat('pl-PL').format(Number(value))
    : '';
