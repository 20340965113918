import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateBrandDto,
  GetBrandDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/BrandContext';
import { BrandEditor } from '../../components/Brands/BrandEditor';
import { BrandSearch } from '../../components/Brands/BrandSearch';

export const BrandsView: React.FC = () => {
  const pok = usePokCore();
  const [brand, setBrand] = useState<GetBrandDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAdd = () => {
    setShow(true);
    setBrand(undefined);
  };

  const addEditBrand = async (brandCU: CreateUpdateBrandDto, id?: string) => {
    const status = await validate(brandCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.brands.update(id, brandCU);
        } else {
          await pok.brands.create(brandCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const getBrand = await pok.brands.getById(id);
    setBrand(getBrand);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Brandy</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          Nowy brand
        </Button>
        <BrandSearch
          getData={pok.brands.getAllByPortion}
          getCount={pok.brands.getAllCount}
          onRowClick={handleClick}
          refresh={refresh}
        />
        <BrandEditor
          id={brand?.id}
          systemNumber={brand?.systemNumber}
          brand={convert(brand)}
          onCompleted={addEditBrand}
          show={show}
          handleClose={handleClose}
        />
      </Card.Body>
    </Card>
  );
};
