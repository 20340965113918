/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePurchaserDto
 */
export interface CreateUpdatePurchaserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaserDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaserDto
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaserDto
     */
    budgetId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePurchaserDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePurchaserDto
     */
    teamIds: Array<string>;
}

/**
 * Check if a given object implements the CreateUpdatePurchaserDto interface.
 */
export function instanceOfCreateUpdatePurchaserDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "budgetId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "teamIds" in value;

    return isInstance;
}

export function CreateUpdatePurchaserDtoFromJSON(json: any): CreateUpdatePurchaserDto {
    return CreateUpdatePurchaserDtoFromJSONTyped(json, false);
}

export function CreateUpdatePurchaserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePurchaserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'companyId': json['companyId'],
        'clientId': json['clientId'],
        'budgetId': json['budgetId'],
        'active': json['active'],
        'teamIds': json['teamIds'],
    };
}

export function CreateUpdatePurchaserDtoToJSON(value?: CreateUpdatePurchaserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'companyId': value.companyId,
        'clientId': value.clientId,
        'budgetId': value.budgetId,
        'active': value.active,
        'teamIds': value.teamIds,
    };
}

