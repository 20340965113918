import { useEffect, useState } from 'react';

import { GetProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';

function useIsAuthorized(projects: GetProjectDto[]): boolean {
  const [authorized, setAuthorized] = useState<boolean>(true);
  const pok = usePokCore();

  useEffect(() => {
    const companyId = pok.getCompanyId();
    if (
      projects.some(
        project => project.company && project?.company?.id !== companyId,
      )
    ) {
      setAuthorized(false);
    } else {
      setAuthorized(true);
    }
  }, [pok, projects]);

  return authorized;
}

export default useIsAuthorized;
