/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetEstimateByMonthDto } from './GetEstimateByMonthDto';
import {
    GetEstimateByMonthDtoFromJSON,
    GetEstimateByMonthDtoFromJSONTyped,
    GetEstimateByMonthDtoToJSON,
} from './GetEstimateByMonthDto';

/**
 * 
 * @export
 * @interface GetApplicationUnblockDto
 */
export interface GetApplicationUnblockDto {
    /**
     * 
     * @type {string}
     * @memberof GetApplicationUnblockDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationUnblockDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationUnblockDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {number}
     * @memberof GetApplicationUnblockDto
     */
    systemNumber: number;
    /**
     * 
     * @type {GetEstimateByMonthDto}
     * @memberof GetApplicationUnblockDto
     */
    estimateByMonth: GetEstimateByMonthDto;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationUnblockDto
     */
    type: GetApplicationUnblockDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationUnblockDto
     */
    status: GetApplicationUnblockDtoStatusEnum;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetApplicationUnblockDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetApplicationUnblockDto
     */
    active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetApplicationUnblockDto
     */
    creationTs: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetApplicationUnblockDto
     */
    salesCorrect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetApplicationUnblockDto
     */
    inDocumentation?: boolean;
}


/**
 * @export
 */
export const GetApplicationUnblockDtoTypeEnum = {
    Sales: 'Sales',
    Purchase: 'Purchase'
} as const;
export type GetApplicationUnblockDtoTypeEnum = typeof GetApplicationUnblockDtoTypeEnum[keyof typeof GetApplicationUnblockDtoTypeEnum];

/**
 * @export
 */
export const GetApplicationUnblockDtoStatusEnum = {
    New: 'New',
    Accepted: 'Accepted',
    Rejected: 'Rejected'
} as const;
export type GetApplicationUnblockDtoStatusEnum = typeof GetApplicationUnblockDtoStatusEnum[keyof typeof GetApplicationUnblockDtoStatusEnum];


/**
 * Check if a given object implements the GetApplicationUnblockDto interface.
 */
export function instanceOfGetApplicationUnblockDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "estimateByMonth" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "creationTs" in value;

    return isInstance;
}

export function GetApplicationUnblockDtoFromJSON(json: any): GetApplicationUnblockDto {
    return GetApplicationUnblockDtoFromJSONTyped(json, false);
}

export function GetApplicationUnblockDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApplicationUnblockDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'rejectedNote': json['rejectedNote'],
        'systemNumber': json['systemNumber'],
        'estimateByMonth': GetEstimateByMonthDtoFromJSON(json['estimateByMonth']),
        'type': json['type'],
        'status': json['status'],
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'active': json['active'],
        'creationTs': (new Date(json['creationTs'])),
        'salesCorrect': !exists(json, 'salesCorrect') ? undefined : json['salesCorrect'],
        'inDocumentation': !exists(json, 'inDocumentation') ? undefined : json['inDocumentation'],
    };
}

export function GetApplicationUnblockDtoToJSON(value?: GetApplicationUnblockDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'rejectedNote': value.rejectedNote,
        'systemNumber': value.systemNumber,
        'estimateByMonth': GetEstimateByMonthDtoToJSON(value.estimateByMonth),
        'type': value.type,
        'status': value.status,
        'creator': GetEmployeeDtoToJSON(value.creator),
        'active': value.active,
        'creationTs': (value.creationTs.toISOString()),
        'salesCorrect': value.salesCorrect,
        'inDocumentation': value.inDocumentation,
    };
}

