import React, { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { ProjectSearch } from '../Projects/ProjectSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateSalesInvoiceDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ValidationAlert from '../../../app/components/ValidationAlert';

interface SalesInvoiceAddProjectEditorProps {
  show: boolean;
  handleAdd: (id: string) => void;
  handleClose: () => void;
  handleSave: () => void;
  salesInvoice: CreateUpdateSalesInvoiceDto;
  purchaser?: GetPurchaserDto;
  isLoading?: boolean;
  showSaveErrors: boolean;
  saveErrors: string[];
}

export const SalesInvoiceAddProjectEditor: React.FC<
  SalesInvoiceAddProjectEditorProps
> = props => {
  const { projectIds } = props.salesInvoice;
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();

  const getData = useMemo(
    () =>
      (
        pageSize: number,
        pageNumber: number,
        orderBy?: string,
        orderDirection?: string,
        filterText?: string,
      ) =>
        pok.projects.getAllReadyToInvoiceByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          props.purchaser?.client.id || '',
          props.purchaser?.budget.id || '',
          projectIds,
          true,
        ),
    [
      pok.projects,
      props.purchaser?.client.id,
      props.purchaser?.budget.id,
      projectIds,
    ],
  );

  const getCount = useMemo(
    () => (filterText?: string) =>
      pok.projects.getAllReadyToInvoiceCount(
        filterText,
        props.purchaser?.client.id || '',
        props.purchaser?.budget.id || '',
        projectIds,
      ),
    [
      pok.projects,
      projectIds,
      props.purchaser?.budget.id,
      props.purchaser?.client.id,
    ],
  );

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      centered
      keyboard
      backdrop="static"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(tk.menu.chooseProject)} w ramach klienta{' '}
          <b>{props.purchaser?.client.name}</b> i budżetu{' '}
          <b>{props.purchaser?.budget.name}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectSearch
          getData={getData}
          getCount={getCount}
          onRowClick={props.handleAdd}
          showTotalDifferenceColumn
          totalDifferenceLabel="Kwota do zafakturowania"
          disableGridHistory
          enableMultiselect
        />
        <Alert>
          {t(tk.menu.selectProjectsPurchaseInvoiceAlert)} do faktury.
        </Alert>
        <ValidationAlert
          show={props.showSaveErrors}
          errors={props.saveErrors}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        <Button onClick={props.handleSave} disabled={props.isLoading}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
