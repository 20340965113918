import React from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Option } from '../Selects/Selector';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { usePokCore } from '../../../common/hooks/usePokCore';

interface LabconSpecialParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  project?: GetProjectDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const LabconSpecialParams: React.FC<
  LabconSpecialParamsProps
> = props => {
  const pok = usePokCore();
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.service && (
        <FormRow controlId="service" label={schema?.service?.displayName}>
          <Form.Control
            type="text"
            value={props.estimateItem?.service ?? ''}
            onChange={e =>
              propertyChange({
                service: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.action && (
        <FormRow controlId="action" label={schema?.action?.displayName}>
          <Form.Control
            type="text"
            value={props.estimateItem?.action ?? ''}
            onChange={e =>
              propertyChange({
                action: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.actionGroup && (
        <FormRow
          controlId="actionGroup"
          label={schema?.actionGroup?.displayName}
        >
          <CreatableClearableSelector
            value={props.estimateItem?.actionGroup ?? ''}
            onChange={option =>
              propertyChange({
                actionGroup: option ? (option as Option).label : option,
              })
            }
            provider={(text: string) =>
              pok.estimateItems.getActionGroupsOptionsByTextByCompanyAndBudget(
                text,
                props.project?.company?.id || '',
                props.project?.purchaser.budget.id || '',
              )
            }
          />
        </FormRow>
      )}
    </>
  );
};
