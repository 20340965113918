/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetResultsDto
 */
export interface GetResultsDto {
    /**
     * 
     * @type {number}
     * @memberof GetResultsDto
     */
    addCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetResultsDto
     */
    updateCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetResultsDto
     */
    errorCount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetResultsDto
     */
    okMessages?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetResultsDto
     */
    errorMessages?: Array<string>;
}

/**
 * Check if a given object implements the GetResultsDto interface.
 */
export function instanceOfGetResultsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addCount" in value;
    isInstance = isInstance && "updateCount" in value;
    isInstance = isInstance && "errorCount" in value;

    return isInstance;
}

export function GetResultsDtoFromJSON(json: any): GetResultsDto {
    return GetResultsDtoFromJSONTyped(json, false);
}

export function GetResultsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResultsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addCount': json['addCount'],
        'updateCount': json['updateCount'],
        'errorCount': json['errorCount'],
        'okMessages': !exists(json, 'okMessages') ? undefined : json['okMessages'],
        'errorMessages': !exists(json, 'errorMessages') ? undefined : json['errorMessages'],
    };
}

export function GetResultsDtoToJSON(value?: GetResultsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addCount': value.addCount,
        'updateCount': value.updateCount,
        'errorCount': value.errorCount,
        'okMessages': value.okMessages,
        'errorMessages': value.errorMessages,
    };
}

