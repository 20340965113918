import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import {
  GetCompanyDto,
  GetPositionExtendedDto,
  GetProfileDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { nameof } from '../../../utils/objectUtils';

import { PositionInfo } from './PositionInfo';

interface PositionSearchProps {
  hasClient?: boolean;
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh: boolean;
}

const getRelationNames = (
  items: GetCompanyDto[] | GetProfileDto[] | undefined,
  fieldName: keyof GetCompanyDto | keyof GetProfileDto,
  searchFieldName: string,
  orderBy: string,
) => {
  let names = items?.map(item => {
    if ('shortname' in item && fieldName === 'shortname') {
      return item.shortname;
    }
    return item.name;
  });
  if (names && orderBy !== searchFieldName) {
    names = names.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  return names?.join(', ');
};

const getRelationTeamNames = (
  items: GetTeamDto[] | undefined,
  fieldName: string,
  orderBy: string,
) => {
  let names = items?.map(
    team => team?.name + ' (' + team?.company?.shortname + ')',
  );
  if (names && orderBy !== fieldName) {
    names = names.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  return names?.join(', ');
};

export const positionForGrid = (
  position: GetPositionExtendedDto,
  orderBy: string = '',
) => {
  const companyNames = getRelationNames(
    position.companies,
    nameof<GetCompanyDto>('shortname'),
    'companies.shortname',
    orderBy,
  );
  const teamNames = getRelationTeamNames(position.teams, 'teams.name', orderBy);
  const profileNames = getRelationNames(
    position.profiles,
    nameof<GetProfileDto>('name'),
    'profiles.name',
    orderBy,
  );
  return {
    key: position.id,
    values: {
      'position.systemNumber': position.systemNumber,
      'position.name': position.name,
      'position.groupName': position.groupName,
      'dictionary.value':
        position.dictionary?.shortname || position.dictionary?.value,
      'position.positionType': position.positionType,
      'client.name': position.client.name,
      'team.name': position.team
        ? position.team?.name + ' (' + position.team?.company?.shortname + ')'
        : '',
      'teams.name': teamNames,
      'companies.shortname': companyNames,
      'profiles.name': profileNames,
      status: <PositionInfo id={position.id} />,
    },
    isInactive: !position.active,
  };
};

export const PositionSearch: React.FC<PositionSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'position.systemNumber' },
    { header: 'Nazwa', property: 'position.name' },
    ...(props.hasClient
      ? []
      : [{ header: 'Kontrahent', property: 'client.name' }]),
    { header: 'Nazwa grupy', property: 'position.groupName' },
    {
      header: 'Typ pozycji',
      property: 'position.positionType',
      noFilter: true,
    },
    { header: 'Kanał komunikacji', property: 'dictionary.value' },
    { header: 'Profile', property: 'profiles.name' },
    { header: 'Spółki', property: 'companies.shortname' },
    { header: 'Zespoły', property: 'teams.name' },
    { header: 'Zespół specjalistyczny', property: 'team.name' },
    { header: '', property: 'status', noSorting: true, noFilter: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn="position.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={
        props.hasClient
          ? 'Brak pozycji dla tego kontrahenta'
          : 'Brak pozycji w systemie.'
      }
      onRowClick={props.onRowClick}
      withHistory={true}
      withFilterInColumns={true}
      withOverflowXAuto
    />
  );
};
