import React, { useMemo } from 'react';
import { Accordion } from 'react-bootstrap';

import {
  GetEstimateItemDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { ServiceActionAccordion } from './ServiceActionAccordion';

interface ServiceActionTabProps {
  project: GetProjectDto;
  services?: string[];
}

export const ServiceActionTab: React.FC<ServiceActionTabProps> = props => {
  const [estimateItems, setEstimateItems] = React.useState<
    GetEstimateItemDto[]
  >([]);

  const pok = usePokCore();
  const notifications = useNotifications();

  useMemo(() => {
    pok.estimateItems
      .findByProject(props.project.id)
      .then(items => {
        setEstimateItems(items);
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [notifications, pok.estimateItems, props.project.id]);

  return (
    <Accordion className="mt-5">
      {props.services &&
        props.services.map((item, idx) => (
          <div key={idx} className="d-flex1 mb-1">
            <ServiceActionAccordion
              service={item}
              items={estimateItems}
              idx={idx.toString()}
              project={props.project}
            />
          </div>
        ))}
    </Accordion>
  );
};
