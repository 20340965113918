import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetExtendedProfileDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface ProfileSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const profileForGrid = (
  profile: GetExtendedProfileDto,
  orderBy: string = '',
) => {
  let companyNames = profile.companies?.map(({ shortname }) => shortname);
  if (companyNames && orderBy !== 'companies.shortname') {
    companyNames = companyNames.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  let teamNames = profile.teams?.map(
    team => team.name + ' (' + team.company.shortname + ')',
  );
  if (teamNames && orderBy !== 'teams.name') {
    teamNames = teamNames.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  return {
    key: profile.id,
    values: {
      'profile.name': profile.name,
      'companies.shortname': companyNames?.join(', '),
      'teams.name': teamNames?.join(', '),
      'profile.commission': profile.commission ? 'TAK' : 'NIE',
    },
    isInactive: !profile.active,
  };
};

export const ProfileSearch: React.FC<ProfileSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa', property: 'profile.name' },
    { header: 'Spółki', property: 'companies.shortname' },
    { header: 'Zespoły', property: 'teams.name' },
    { header: 'Prowizje', property: 'profile.commission' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="profile.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak profili w systemie."
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
    />
  );
};
