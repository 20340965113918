import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import {
  CreateUpdateEmailConfigDto,
  GetEmailConfigDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { EmailConfigEditor } from '../../components/EmailConfig/EmailConfigEditor';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/EmailConfigContext';
import { EmailConfigSearch } from '../../components/EmailConfig/EmailConfigSearch';

export const EmailConfigsView: React.FC = () => {
  const [emailConfig, setEmailConfig] = useState<GetEmailConfigDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const pok = usePokCore();

  const addEditEmailConfig = async (
    emailConfigCU: CreateUpdateEmailConfigDto,
    id?: string,
  ) => {
    const status = await validate(emailConfigCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.emailConfig.update(id, emailConfigCU);
        } else {
          await pok.emailConfig.create(emailConfigCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const onRowClick = async (id: string) => {
    const getEmailConfig = await pok.emailConfig.getById(id);
    setEmailConfig(getEmailConfig);
    setShow(true);
  };

  const handleAdd = () => {
    setEmailConfig(undefined);
    setShow(true);
  };

  const handleClose = () => {
    setEmailConfig(undefined);
    setShow(false);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Konfiguracja maili</Card.Title>
        <Button className="mb-4" variant="outline-primary" onClick={handleAdd}>
          Nowa konfiguracja
        </Button>
        <EmailConfigSearch
          getData={pok.emailConfig.getAllByPortion}
          getCount={pok.emailConfig.getAllCount}
          onRowClick={onRowClick}
          refresh={refresh}
        />
        <EmailConfigEditor
          emailConfig={convert(emailConfig)}
          readOnly={false}
          show={show}
          handleClose={handleClose}
          onCompleted={addEditEmailConfig}
          id={emailConfig?.id}
          systemNumber={emailConfig?.systemNumber}
        />
      </Card.Body>
    </Card>
  );
};
