/*eslint-disable max-lines-per-function*/
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import React from 'react';

import { LandingView } from '../pok/containers/LandingView/LandingView';
import { DocumentationView } from '../app/containers/DocumentationView';
import { ClientsView } from '../pok/containers/ClientsView/ClientsView';
import { CompaniesView } from '../pok/containers/CompaniesView/CompaniesView';
import { DictionariesTreeView } from '../pok/containers/DictionariesView/DictionariesTreeView';
import { EmployeesView } from '../pok/containers/EmployeesView/EmployeesView';
import { RolesView } from '../pok/containers/RolesView/RolesView';
import { EmployeesRolesView } from '../pok/containers/EmployeesRolesView/EmployeesRolesView';
import { TeamsView } from '../pok/containers/TeamsView/TeamsView';
import { TeamView } from '../pok/containers/TeamsView/TeamView';
import { BudgetsView } from '../pok/containers/BudgetsView/BudgetsView';
import { BudgetView } from '../pok/containers/BudgetsView/BudgetView';
import { BrandsView } from '../pok/containers/BrandsView/BrandsView';
import { ProfilesView } from '../pok/containers/ProfilesView/ProfilesView';
import { AllPurchasersView } from '../pok/containers/AllPurchasersView/AllPurchasersView';
import { AllPositionsView } from '../pok/containers/AllPositionsView/AllPositionsView';
import { ProjectsView } from '../pok/containers/ProjectsView/ProjectsView';
import { ProfileCategoriesView } from '../pok/containers/ProfileCategoriesView/ProfileCategoriesView';
import { ClientView } from '../pok/containers/ClientsView/ClientView';
import { ApplicationsView } from '../pok/containers/ApplicationsView/ApplicationsView';
import { ApplicationsAdminView } from '../pok/containers/ApplicationsView/ApplicationsAdminView';
import { ProjectDetailsView } from '../pok/containers/ProjectsView/ProjectDetailsView';
import { ProjectsApplicationView } from '../pok/containers/ProjectsApplicationView/ProjectsApplicationView';
import { ProjectsReadyToInvoiceView } from '../pok/containers/ProjectsReadyToInvoiceView/ProjectsReadyToInvoiceView';
import { SalesInvoiceView } from '../pok/containers/SalesInvoiceView/SalesInvoiceView';
import { PurchaseInvoicesView } from '../pok/components/PurchaseInvoices/PurchaseInvoicesView';
import { SalesInvoiceEditorView } from '../pok/containers/SalesInvoiceView/SalesInvoiceEditorView';
import { ImportView } from '../pok/containers/ImportView/ImportView';
import { ProjectPurchaseInvoicesView } from '../pok/components/PurchaseInvoices/ProjectPurchaseInvoicesView';
import { BankAccountsView } from '../pok/containers/BankAccountsView/BankAccountsView';
import { ProjectAssignedPurchaseInvoicesView } from '../pok/components/PurchaseInvoices/ProjectAssignedPurchaseInvoicesView';
import { UsersInRoleView } from '../pok/containers/RolesView/UsersInRoleView';
import {
  OrdersView,
  ProjectOrdersTabs,
} from '../pok/containers/OrdersView/OrdersView';
import { OrderDecisionView } from '../pok/containers/OrdersView/OrderDecisionView';
import {
  OrdersExecutionTabs,
  OrdersExecutionView,
} from '../pok/containers/OrdersExecutionView/OrdersExecutionView';
import { OrderExecutionDecisionView } from '../pok/containers/OrdersExecutionView/OrderExecutionDecisionView';
import { PurchaseInvoiceAssignedToProjectsView } from '../pok/components/PurchaseInvoices/PurchaseInvoiceAssignedToProjectsView';
import { PurchaseInvoiceNotAssignedView } from '../pok/components/PurchaseInvoices/PurchaseInvoiceNotAssignedView';
import { SalesInvoicesReadyToCorrection } from '../pok/containers/SalesInvoicesReadyToCorrection/SalesInvoicesReadyToCorrection';
import { InstructionsView } from '../app/containers/InstructionsView';
import { SalesInvoiceEditorTabs } from '../pok/containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import { VerificationView } from '../pok/containers/VerificationView/VerificationView';
import { ExportFVZToXMLView } from '../pok/containers/ExportFVZToXML/ExportFVZToXMLView';
import { ReportsView } from '../pok/containers/ReportsView/ReportsView';
import { ReportEstimateItemsDataRawView } from '../pok/containers/ReportsView/ReportEstimateItemsDataRawView';
import { ApplicationUnblockView } from '../pok/containers/ApplicaitonUnblockView/ApplicationUnblockView';
import { PurchasesInvoicesImportView } from '../pok/containers/PurchasesInvoicesImportView/PurchasesInvoicesImportView';
import { ReportProjectByMonthDataRawView } from '../pok/containers/ReportsView/ReportProjectByMonthDataRawView';
import { ReportsFinanceView } from '../pok/containers/ReportsView/ReportsFinanceView';
import { PackageFVZView } from '../pok/containers/ExportFVZToXML/PackageFVZView';
import { ExportFVSToXMLView } from '../pok/containers/ExportFVSToXML/ExportFVSToXMLView';
import { PackageFVSView } from '../pok/containers/ExportFVSToXML/PackageFVSView';
import { ClientsWithSymphonyView } from '../pok/containers/ClientsView/ClientsWithSymphonyView';
import { ClientSymphonyView } from '../pok/containers/ClientsView/ClientSymphonyView';
import { ExportFVSToSymphonyView } from '../pok/containers/ExportFVSToSymphony/ExportFVSToSymphonyView';
import { ReportFVSByFilterRawDataView } from '../pok/containers/ReportsView/ReportFVSByFilterRawDataView';
import { ReportFVZByFilterRawDataView } from '../pok/containers/ReportsView/ReportFVZByFilterRawDataView';
import { ProjectsFinanceView } from '../pok/containers/ProjectsView/ProjectsFinanceView';
import { ImportFinanceView } from '../pok/containers/PurchasesInvoicesImportView/ImportFinanceView';
import { PurchaseInvoiceView } from '../pok/components/PurchaseInvoices/PurchaseInvoiceView';
import { PurchaseInvoiceTabsEnum } from '../pok/components/PurchaseInvoices/PurchaseInvoiceTabsEnum';
import { SpecialInvoicingView } from '../pok/containers/SpecialInvoicingView/SpecialInvoicingView';
import { SpecialInvoicingEditorView } from '../pok/containers/SpecialInvoicingView/SpecialInvoicingEditorView';
import { ExcelConfigsView } from '../pok/containers/ExcelConfigsView/ExcelConfigsView';
import { ExcelConfigView } from '../pok/containers/ExcelConfigsView/ExcelConfigView';
import { SpecialInvoicingDecisionView } from '../pok/containers/SpecialInvoicingView/SpecialInvoicingDecisionView';
import { SpecialInvoicingDecisionEditorView } from '../pok/containers/SpecialInvoicingView/SpecialInvoicingDecisionEditorView';
import { EmailConfigsView } from '../pok/containers/EmailConfigView/EmailConfigsView';
import { EstimateItemPurchaseDeficienciesView } from '../pok/containers/EstimateItemPurchaseDefiencies/EstimateItemPurchaseDeficienciesView';
import { ProjectExcelImportView } from '../pok/containers/ProjectExcelImportView/ProjectExcelImportView';
import { DocumentControlView } from '../pok/containers/DocumentControlView/DocumentControlView';
import { ReportFVZControllingByFilterRawDataView } from '../pok/containers/ReportsView/ReportFVZControllingByFilterRawDataView';
import { PurchaseInvoicesTvView } from '../pok/components/PurchaseInvoices/PurchaseInvoicesTvView';
import { PurchaseInvoiceTvView } from '../pok/components/PurchaseInvoices/PurchaseInvoiceTvView';
import { BlockadeView } from '../pok/containers/BlockadeView/BlockadeView';
import { ReportFVSControllingByFilterRawDataView } from '../pok/containers/ReportsView/ReportFVSControllingByFilterRawDataView';
import { JobConfigurationView } from '../pok/containers/JobConfigurationView/JobConfigurationView';

import { useTranslation } from './hooks/useTranslation';
import { PermissionsEnum } from './pokCore/authorization/permissions';

// import { useAuth } from './hooks/useAuth';
// import { useNotifications } from './hooks/useNotifications';

export interface MenuItem {
  hr?: boolean;
  heading: string;
  path: string;
  icon?: Icon.Icon;
  element: JSX.Element;
  requiresAnyOf: PermissionsEnum[];
  requiresTeamAnyOf?: string[];
  requiresCompanyAnyOf?: string[];
  withContext?: boolean;
  submenu?: MenuItem[];
}

export const routesInMenu = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, tk } = useTranslation('menu');
  return [
    {
      heading: t(tk.menu.projects) || 'Projekty',
      path: '/projekty',
      icon: Icon.Megaphone,
      element: React.createElement(ProjectsView),
      requiresAnyOf: [PermissionsEnum.Projects],
      withContext: true,
      submenu: [
        {
          heading: t(tk.menu.projectList) || 'Lista projektów',
          path: '/projekty/projekty',
          element: React.createElement(ProjectsView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: 'Wnioski',
          path: '/projekty/wnioski',
          element: React.createElement(ApplicationsView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: 'Wnioski o niestandardowe fakturowanie',
          path: '/projekty/niestandardowe-fakturowanie',
          element: React.createElement(SpecialInvoicingView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: `Import ${t(tk.menu.genitiveProjectPlural)} z arkuszy`,
          path: '/projekty/import',
          element: React.createElement(ProjectExcelImportView),
          requiresAnyOf: [PermissionsEnum.Projects],
          requiresTeamAnyOf: [
            'TV Buying',
            'MPC',
            'I. Wachowicz',
            'Programmatic',
            'A. Pająk',
            'J. Rojewska',
          ], //'M. Kwit',
          withContext: true,
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Projects],
        },
        {
          heading: 'Faktury zakupu do wyjaśnienia',
          path: '/projekty/faktury-zakupu',
          element: React.createElement(ProjectPurchaseInvoicesView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: t(tk.menu.purchaseInvoicesAssignedToProject),
          path: '/projekty/przypisane-faktury-zakupu-do-projektu',
          element: React.createElement(ProjectAssignedPurchaseInvoicesView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: 'Faktury TV',
          path: '/projekty/faktury-tv',
          element: React.createElement(PurchaseInvoicesTvView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: 'Wyjaśnienia braków FVZ',
          path: '/projekty/braki-zakupowe',
          element: React.createElement(EstimateItemPurchaseDeficienciesView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Projects],
        },
        {
          heading: 'Zamówienia',
          path: '/projekty/zamowienia',
          element: React.createElement(OrdersView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
        {
          heading: 'Realizacje zamówień',
          path: '/projekty/realizacje-zamowien',
          element: React.createElement(OrdersExecutionView),
          requiresAnyOf: [PermissionsEnum.Projects],
          withContext: true,
        },
      ],
    },
    {
      heading: 'Finanse',
      path: '/finanse',
      icon: Icon.CreditCard,
      element: React.createElement(ProjectsReadyToInvoiceView),
      requiresAnyOf: [PermissionsEnum.Finance],
      withContext: true,
      submenu: [
        {
          heading: t(tk.menu.readyForInvoicing),
          path: '/finanse/gotowe-do-fakturowania',
          element: React.createElement(ProjectsReadyToInvoiceView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Faktury sprzedaży',
          path: '/finanse/faktury-sprzedazy',
          element: React.createElement(SalesInvoiceView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Faktury sprzedaży do skorygowania',
          path: '/finanse/faktury-do-skorygowania',
          element: React.createElement(SalesInvoicesReadyToCorrection),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Faktury zakupu',
          path: '/finanse/faktury-zakupu',
          element: React.createElement(PurchaseInvoicesView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Weryfikacja',
          path: '/finanse/weryfikacja',
          element: React.createElement(VerificationView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Importy',
          path: '/finanse/import',
          element: React.createElement(ImportFinanceView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Finance],
        },
        {
          heading: t(tk.menu.projectsApplications) || 'Wnioski do projektów',
          path: '/finanse/projekty-wnioski',
          element: React.createElement(ProjectsApplicationView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading:
            t(tk.menu.applicationUnblocks) ||
            'Wnioski o odblokowanie mediaplanu',
          path: '/finanse/wnioski-odblokowanie-mediaplanu',
          element: React.createElement(ApplicationUnblockView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Wnioski o niestandardowe fakturowanie',
          path: '/finanse/wnioski-niestandardowe-fakturowanie',
          element: React.createElement(SpecialInvoicingDecisionView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        ...(process.env.REACT_APP_VERSION?.toLocaleLowerCase() !== 'prod'
          ? [
              {
                heading: 'Blokady',
                path: '/finanse/blokady',
                element: React.createElement(BlockadeView),
                requiresAnyOf: [PermissionsEnum.Finance],
                withContext: true,
              },
            ]
          : []),
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Finance],
        },
        {
          heading: 'Eksport FVZ do XML',
          path: '/finanse/eksport-fvz-xml',
          element: React.createElement(ExportFVZToXMLView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Eksport FVS do XML',
          path: '/finanse/eksport-fvs-xml',
          element: React.createElement(ExportFVSToXMLView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Eksport FVS do Symfonii Handel',
          path: '/finanse/eksport-fvs-handel',
          element: React.createElement(ExportFVSToSymphonyView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
        {
          heading: 'Kontrahenci - Symfonia',
          path: '/finanse/kontrahenci',
          element: React.createElement(ClientsWithSymphonyView),
          requiresAnyOf: [PermissionsEnum.Finance],
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Finance],
        },
        {
          heading: 'Zestawienia finanse',
          path: '/finanse/zestawienia-finanse',
          icon: Icon.FileBarGraph,
          element: React.createElement(ReportsFinanceView),
          requiresAnyOf: [PermissionsEnum.Finance],
        },
        {
          heading: t(tk.menu.projectList) || 'Lista projektów',
          path: '/finanse/projekty-lista',
          element: React.createElement(ProjectsFinanceView),
          requiresAnyOf: [PermissionsEnum.Finance],
          withContext: true,
        },
      ],
    },
    {
      heading: 'Finanse - administracja',
      path: '/admin-finanse',
      icon: Icon.CreditCard,
      element: React.createElement(ReportsFinanceView),
      requiresAnyOf: [PermissionsEnum.FinanceAdministration],
      withContext: true,
      submenu: [
        {
          heading: 'Zestawienia finanse',
          path: '/admin-finanse/zestawienia-finanse',
          icon: Icon.FileBarGraph,
          element: React.createElement(ReportsFinanceView),
          requiresAnyOf: [PermissionsEnum.FinanceAdministration],
        },
        {
          heading: 'Konta bankowe',
          path: '/admin-finanse/konta-bankowe',
          element: React.createElement(BankAccountsView),
          requiresAnyOf: [PermissionsEnum.FinanceAdministration],
        },
      ],
    },
    {
      heading: 'Kontrahenci',
      path: '/kontrahenci',
      icon: Icon.People,
      element: React.createElement(ClientsView),
      requiresAnyOf: [PermissionsEnum.Clients],
      submenu: [
        {
          heading: 'Lista kontrahentów',
          path: '/kontrahenci/kontrahenci',
          element: React.createElement(ClientsView),
          requiresAnyOf: [PermissionsEnum.Clients],
        },
        {
          heading: 'Zamawiający',
          path: '/kontrahenci/zamawiajacy',
          element: React.createElement(AllPurchasersView),
          requiresAnyOf: [PermissionsEnum.Clients],
        },
        {
          heading: 'Pozycje profili',
          path: '/kontrahenci/pozycje',
          element: React.createElement(AllPositionsView),
          requiresAnyOf: [PermissionsEnum.Clients],
        },
        {
          heading: 'Budżety',
          path: '/kontrahenci/budzety',
          element: React.createElement(BudgetsView),
          requiresAnyOf: [PermissionsEnum.Clients],
        },
        {
          heading: 'Brandy',
          path: '/kontrahenci/brandy',
          element: React.createElement(BrandsView),
          requiresAnyOf: [PermissionsEnum.Clients],
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Clients],
        },
        {
          heading: 'Wnioski',
          path: '/kontrahenci/wnioski',
          element: React.createElement(ApplicationsAdminView),
          requiresAnyOf: [PermissionsEnum.Clients],
        },
      ],
    },
    {
      heading: 'Administracja',
      path: '/administracja',
      icon: Icon.Gear,
      element: React.createElement(CompaniesView),
      requiresAnyOf: [
        PermissionsEnum.Administration,
        PermissionsEnum.Permissions,
      ],
      submenu: [
        {
          heading: 'Spółki',
          path: '/administracja/spolki',
          element: React.createElement(CompaniesView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Pracownicy',
          path: '/administracja/pracownicy',
          element: React.createElement(EmployeesView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Zespoły',
          path: '/administracja/zespoly',
          element: React.createElement(TeamsView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Profile mediaplanów',
          path: '/administracja/profile',
          element: React.createElement(ProfilesView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Kategorie profili',
          path: '/administracja/kategorie-profili',
          element: React.createElement(ProfileCategoriesView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Słowniki',
          path: '/administracja/slowniki',
          element: React.createElement(DictionariesTreeView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Permissions],
        },
        {
          heading: 'Role',
          path: '/administracja/role',
          element: React.createElement(RolesView),
          requiresAnyOf: [PermissionsEnum.Permissions],
        },
        {
          heading: 'Uprawnienia',
          path: '/administracja/uprawnienia',
          element: React.createElement(EmployeesRolesView),
          requiresAnyOf: [PermissionsEnum.Permissions],
        },
        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Konfiguracja maili',
          path: '/administracja/konfiguracja-maili',
          element: React.createElement(EmailConfigsView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },

        {
          hr: true,
          path: '',
          requiresAnyOf: [PermissionsEnum.Administration],
        },
        {
          heading: 'Konfiguracja importu',
          path: '/administracja/konfiguracjaExcel',
          element: React.createElement(ExcelConfigsView),
          requiresAnyOf: [PermissionsEnum.Administration],
        },
      ],
    },
    {
      heading: 'Zestawienia',
      path: '/zestawienia',
      icon: Icon.FileBarGraph,
      element: React.createElement(ReportsView),
      requiresAnyOf: [PermissionsEnum.Administration],
    },
    ...(process.env.REACT_APP_IMPORT?.toLocaleLowerCase() === 'on'
      ? [
          {
            heading: 'Import',
            path: '/import',
            icon: Icon.FuelPump,
            element: React.createElement(ImportView),
            requiresAnyOf: [],
            submenu: [],
          },
        ]
      : []),
    {
      heading: 'Pomoc',
      path: '/pomoc',
      icon: Icon.InfoCircle,
      element: React.createElement(InstructionsView),
      requiresAnyOf: [],
      submenu: [
        {
          heading: 'Instrukcje obsługi',
          path: '/pomoc/instrukcje',
          element: React.createElement(InstructionsView),
          requiresAnyOf: [],
        },
        {
          heading: 'Dokumentacja',
          path: '/pomoc/dokumentacja',
          element: React.createElement(DocumentationView),
          requiresAnyOf: [],
        },
      ],
    },
  ] as MenuItem[];
};

export const routesOutsideMenu: MenuItem[] = [
  {
    heading: 'POK',
    path: '/',
    element: React.createElement(LandingView),
    requiresAnyOf: [],
  },
  {
    heading: 'Szczegóły',
    path: '/projekty/projekty/szczegoly/:projectId',
    element: React.createElement(ProjectDetailsView),
    requiresAnyOf: [],
    submenu: [],
  },
  {
    heading: 'Zamawiający',
    path: '/kontrahenci/kontrahenci/zamawiajacy/:clientId',
    icon: Icon.People,
    element: React.createElement(ClientView),
    requiresAnyOf: [],
    submenu: [],
  },
  {
    heading: 'Zespoły',
    path: '/administracja/zespoly/podglad/:id',
    element: React.createElement(TeamView),
    requiresAnyOf: [],
  },
  {
    heading: 'Budżety',
    path: '/kontrahenci/budzety/podglad/:id',
    element: React.createElement(BudgetView),
    requiresAnyOf: [],
  },
  {
    heading: 'Faktury sprzedaży',
    path: '/finanse/faktury-sprzedazy/utworz',
    element: React.createElement(SalesInvoiceEditorView),
    requiresAnyOf: [],
  },
  {
    heading: 'Faktura zakupu',
    path: '/finanse/faktury-zakupu/:purchaseInvoiceId',
    element: React.createElement(PurchaseInvoiceView),
    requiresAnyOf: [],
  },
  {
    heading: 'Faktura zakupu do wyjaśnienia',
    path: '/projekty/faktury-zakupu/:purchaseInvoiceId',
    element: React.createElement(PurchaseInvoiceNotAssignedView),
    requiresAnyOf: [],
  },
  {
    heading: 'Faktura zakupu przypisana do projektu',
    path: '/projekty/przypisane-faktury-zakupu-do-projektu/:purchaseInvoiceId',
    element: React.createElement(PurchaseInvoiceAssignedToProjectsView),
    requiresAnyOf: [],
  },
  {
    heading: 'Faktura zakupu TV',
    path: '/projekty/faktury-tv/:purchaseInvoiceId',
    element: React.createElement(PurchaseInvoiceTvView),
    requiresAnyOf: [],
  },
  {
    heading: 'Użytkownicy w roli',
    path: '/administracja/role/uzytkownicy-w-roli/:roleId',
    element: React.createElement(UsersInRoleView),
    requiresAnyOf: [],
  },
  {
    heading: 'Realizacja zamówienia',
    path: '/projekty/zamowienia/:orderId',
    element: React.createElement(OrderDecisionView),
    requiresAnyOf: [],
  },
  {
    heading: 'Akceptacja realizacji zamówienia',
    path: '/projekty/realizacje-zamowien/:orderExecutionId',
    element: React.createElement(OrderExecutionDecisionView),
    requiresAnyOf: [],
  },
  {
    heading: 'Surówka',
    path: '/zestawienia/surowka',
    element: React.createElement(ReportEstimateItemsDataRawView),
    requiresAnyOf: [PermissionsEnum.Administration],
  },
  {
    heading: 'Raport do budżetu',
    path: '/finanse/zestawienia-finanse/raport-do-budzetu',
    element: React.createElement(ReportProjectByMonthDataRawView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'FVS',
    path: '/finanse/zestawienia-finanse/fvs',
    element: React.createElement(ReportFVSByFilterRawDataView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'FVZ',
    path: '/finanse/zestawienia-finanse/fvz',
    element: React.createElement(ReportFVZByFilterRawDataView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'FVZ',
    path: '/finanse/zestawienia-finanse/fvz-kontroling',
    element: React.createElement(ReportFVZControllingByFilterRawDataView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'FVS',
    path: '/finanse/zestawienia-finanse/fvS-kontroling',
    element: React.createElement(ReportFVSControllingByFilterRawDataView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  // {
  //   heading: 'Import załączników',
  //   path: '/import/zalaczniki',
  //   element: React.createElement(ImportAttachmentsView),
  //   requiresAnyOf: [PermissionsEnum.Administration],
  // },
  {
    heading: 'Import FVZ z google',
    path: '/finanse/import/google-fvz',
    element: React.createElement(PurchasesInvoicesImportView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'Paczka FVZ',
    path: '/finanse/eksport-fvz-xml/paczka-FVZ/:id',
    element: React.createElement(PackageFVZView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'Paczka FVS',
    path: '/finanse/eksport-fvs-xml/paczka-FVS/:id',
    element: React.createElement(PackageFVSView),
    requiresAnyOf: [PermissionsEnum.Finance],
  },
  {
    heading: 'Kontrahenci',
    path: '/finanse/kontrahenci/:clientId',
    icon: Icon.People,
    element: React.createElement(ClientSymphonyView),
    requiresAnyOf: [],
    submenu: [],
  },
  {
    heading: 'Szczegóły konfiguracji',
    path: '/administracja/konfiguracjaExcel/szczegoly/:id',
    element: React.createElement(ExcelConfigView),
    requiresAnyOf: [],
    submenu: [],
  },
  {
    heading: 'Wnioski o niestandardowe fakturowanie',
    path: '/projekty/niestandardowe-fakturowanie/:specialInvoicingId',
    element: React.createElement(SpecialInvoicingEditorView),
    requiresAnyOf: [],
  },
  {
    heading: 'Wnioski o niestandardowe fakturowanie',
    path: '/finanse/wnioski-niestandardowe-fakturowanie/:specialInvoicingId',
    element: React.createElement(SpecialInvoicingDecisionEditorView),
    requiresAnyOf: [],
  },
  {
    heading: 'Kontrola dokumentów',
    path: '/projekty/kontrola-dokumentow/:id',
    element: React.createElement(DocumentControlView),
    requiresAnyOf: [PermissionsEnum.Projects],
    withContext: true,
  },
  {
    heading: 'Kontrola dokumentów',
    path: '/projekty/kontrola-dokumentow',
    element: React.createElement(DocumentControlView),
    requiresAnyOf: [PermissionsEnum.Projects],
    withContext: true,
  },
  {
    heading: 'Konfiguracja zadań',
    path: '/administracja/zadania',
    element: React.createElement(JobConfigurationView),
    requiresAnyOf: [PermissionsEnum.Administration],
  },
  {
    heading: 'POK',
    path: '*',
    element: React.createElement(LandingView),
    requiresAnyOf: [],
  },
];

const matchPath = (path: string, pattern: string) => {
  return path.startsWith(pattern);
};

export const currentMenuItems = (activePage: string) => {
  const items: MenuItem[] = [routesOutsideMenu[0]];

  for (const position of routesInMenu()) {
    if (matchPath(activePage, position.path)) {
      items.push(position);

      for (const subposition of position.submenu || []) {
        if (matchPath(activePage, subposition.path)) {
          items.push(subposition);
        }
      }
      break;
    }
  }

  return items;
};

export const useNavigation = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actions = (url: string, options?: any) => navigate(url, options);

  // const auth = useAuth();
  // const notifications = useNotifications();

  //const goWith = (role: Permissions, url: string, newWindow?: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const goWith = (url: string, newWindow?: boolean, options?: any) => {
    //if (!auth.check(role)) {
    //  notifications.unauthorized();
    //  return;
    //}
    if (!newWindow) {
      actions(url, options);
    } else {
      window.open(url, '_blank');
    }
  };

  // const doc = (documentId?: string) => {
  //   if (!documentId) {
  //     return '';
  //   }
  //   return `/${documentId}`;
  // };

  return {
    menuItem: (location: string) => actions(location),
    help: () => actions('/pomoc'),
    reload: () => navigate(0),
    projectDetails: (id: string, newWindow?: boolean, tab?: string) => {
      if (tab) {
        return goWith(
          `/projekty/projekty/szczegoly/${id}?activeTab=${tab}`,
          newWindow,
        );
      }
      return goWith(`/projekty/projekty/szczegoly/${id}`, newWindow);
    },
    purchaserEditor: (id: string, newWindow?: boolean) =>
      goWith(`/kontrahenci/kontrahenci/zamawiajacy/${id}`, newWindow),
    clientSymphonyEditor: (id: string, newWindow?: boolean) =>
      goWith(`/finanse/kontrahenci/${id}`, newWindow),
    team: (id: string, newWindow?: boolean) =>
      goWith(
        //Role.OrganizationsView,
        `/administracja/zespoly/podglad/${id}`,
        newWindow,
      ),
    budget: (id: string, newWindow?: boolean) =>
      goWith(
        //Role.OrganizationsView,
        `/kontrahenci/budzety/podglad/${id}`,
        newWindow,
      ),
    excelConfig: (id: string, newWindow?: boolean) =>
      goWith(
        //Role.OrganizationsView,
        `/administracja/konfiguracjaExcel/szczegoly/${id}`,
        newWindow,
      ),
    salesInvoiceEditor: ({
      projectId,
      salesInvoiceId,
      tab,
      notSavedTab,
      newWindow,
    }: {
      projectId?: string;
      salesInvoiceId?: string;
      tab?: SalesInvoiceEditorTabs;
      notSavedTab?: SalesInvoiceEditorTabs;
      newWindow?: boolean;
    }) => {
      const params = new URLSearchParams();
      const state: { tabKey?: string; notSavedTab?: string } = {};

      if (projectId) {
        params.set('projectId', projectId);
      }
      if (salesInvoiceId) {
        params.set('salesInvoiceId', salesInvoiceId);
      }

      if (tab) {
        state.tabKey = tab;
      }

      if (notSavedTab) {
        state.notSavedTab = notSavedTab;
      }

      return goWith(
        `/finanse/faktury-sprzedazy/utworz?${params.toString()}`,
        newWindow,
        {
          state,
        },
      );
    },
    salesInvoiceEditorNewWindow: (
      salesInvoiceId: string,
      newWindow?: boolean,
    ) => {
      const params = new URLSearchParams();
      params.set('salesInvoiceId', salesInvoiceId);
      return goWith(
        `/finanse/faktury-sprzedazy/utworz?${params.toString()}`,
        newWindow,
      );
    },
    purchaseInvoiceEditor: (id: string, newWindow?: boolean) =>
      goWith(`/finanse/faktury-zakupu/${id}`, newWindow),
    specialInvoicingEditor: (
      id: string,
      projectId?: string,
      newWindow?: boolean,
    ) =>
      goWith(
        `/projekty/niestandardowe-fakturowanie/${id}${projectId ? `?projectId=${projectId}` : ''}`,
        newWindow,
      ),
    specialInvoicingDecisionEditor: (id: string, newWindow?: boolean) =>
      goWith(`/finanse/wnioski-niestandardowe-fakturowanie/${id}`, newWindow),
    changePurchaseInvoiceEditorPath: (
      id: string,
      tab?: PurchaseInvoiceTabsEnum,
    ) =>
      navigate(`/finanse/faktury-zakupu/${id}`, {
        replace: true,
        state: {
          tabKey: tab,
        },
      }),
    projectPurchaseInvoiceEditor: (id: string, newWindow?: boolean) =>
      goWith(`/projekty/faktury-zakupu/${id}`, newWindow),
    tvPurchaseInvoiceEditor: (id: string, newWindow?: boolean) =>
      goWith(`/projekty/faktury-tv/${id}`, newWindow),
    projectAssignedPurchaseInvoiceEditor: (id: string, newWindow?: boolean) =>
      goWith(
        `/projekty/przypisane-faktury-zakupu-do-projektu/${id}`,
        newWindow,
      ),
    usersInRole: (id: string) =>
      navigate(`/administracja/role/uzytkownicy-w-roli/${id}`),
    orderEditorByTeam: (id: string, newWindow?: boolean) =>
      goWith(`/projekty/zamowienia/${id}`, newWindow),
    readyToInvoice: () => navigate('/finanse/gotowe-do-fakturowania'),
    salesInvoices: () => navigate('/finanse/faktury-sprzedazy'),
    orders: (tab?: ProjectOrdersTabs) =>
      navigate('/projekty/zamowienia', {
        state: {
          tabKey: tab,
        },
      }),
    orderExecutionEditor: (id: string, newWindow?: boolean) =>
      goWith(`/projekty/realizacje-zamowien/${id}`, newWindow),
    ordersExecution: (tab?: OrdersExecutionTabs) =>
      navigate('/projekty/realizacje-zamowien', {
        state: {
          tabKey: tab,
        },
      }),
    importAttachments: (newWindow?: boolean) =>
      goWith('/import/zalaczniki', newWindow),
    purchasesInvoicesImport: (newWindow?: boolean) =>
      goWith('/finanse/import/google-fvz', newWindow),

    packagesFVZ: (id: string) =>
      navigate(`/finanse/eksport-fvz-xml/paczka-FVZ/${id}`),
    packagesFVS: (id: string) =>
      navigate(`/finanse/eksport-fvs-xml/paczka-FVS/${id}`),

    reportEstimateItemsDataRaw: (newWindow?: boolean) =>
      goWith('/zestawienia/surowka', newWindow),
    reportProjectByMonthDataRaw: (newWindow?: boolean) =>
      goWith('/finanse/zestawienia-finanse/raport-do-budzetu', newWindow),
    reportFVSByFilterDataRaw: (newWindow?: boolean) =>
      goWith('/finanse/zestawienia-finanse/fvs', newWindow),
    reportFVZByFilterDataRaw: (newWindow?: boolean) =>
      goWith('/finanse/zestawienia-finanse/fvz', newWindow),
    reportFVZBWithEstimateItemsByFilterDataRaw: (newWindow?: boolean) =>
      goWith('/finanse/zestawienia-finanse/fvz-kontroling', newWindow),
    reportFVSControllingByFilterDataRaw: (newWindow?: boolean) =>
      goWith('/finanse/zestawienia-finanse/fvs-kontroling', newWindow),

    documentsControl: () => goWith('/projekty/kontrola-dokumentow', true),
    documentsControlForProject: (id: string) =>
      goWith(`/projekty/kontrola-dokumentow/${id}`, true),
    salesInvoiceReadyToCorrection: (getUrl?: boolean) => {
      const url = '/finanse/faktury-do-skorygowania';
      if (getUrl) {
        return url;
      }

      return navigate(url);
    },
  };
};
