import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  CreateUpdatePurchaserDto,
  GetClientDto,
  GetPurchaserDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  newPurchaser,
  validate,
} from '../../../common/pokCore/contexts/PurchaserContext';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

import { PurchaserSearch } from './PurchaserSearch';
import { PurchaserEditor } from './PurchaserEditor';

interface PurchaserTabProps {
  client?: GetClientDto;
}

export const PurchaserTab: React.FC<PurchaserTabProps> = props => {
  const [show, setShow] = useState(false);
  const [purchaser, setPurchaser] = useState<GetPurchaserDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const pok = usePokCore();

  const handleAdd = () => {
    setShow(true);
    setPurchaser(undefined);
  };

  const addEditPurchaser = async (
    purchaserCU: CreateUpdatePurchaserDto,
    id?: string,
  ) => {
    const status = await validate(purchaserCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.purchasers.update(id, purchaserCU);
        } else {
          await pok.purchasers.create(purchaserCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const getPurchaser = await pok.purchasers.getById(id);
    setPurchaser(getPurchaser);
    setShow(true);
  };

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchasers.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          props.client?.id,
        )) as GridGetterFunction,
    [pok.purchasers, props.client?.id],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.purchasers.getAllCount(
          filterText,
          filterColumns,
          props.client?.id,
        )) as GridCountFunction,
    [pok.purchasers, props.client?.id],
  );

  return (
    <>
      <Button variant="outline-primary" onClick={handleAdd}>
        Nowy zamawiający
      </Button>
      <PurchaserSearch
        getData={getData}
        getCount={getCount}
        hasClient={!!props.client?.id}
        onRowClick={handleClick}
        refresh={refresh}
      />
      {show && (
        <PurchaserEditor
          id={purchaser?.id}
          systemNumber={purchaser?.systemNumber}
          client={props.client || purchaser?.client}
          purchaser={
            purchaser
              ? convert(purchaser)
              : newPurchaser(props.client?.id, pok.getCompanyId())
          }
          teams={purchaser?.teams}
          onCompleted={addEditPurchaser}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
