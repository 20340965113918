import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  GetEstimateItemDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import numberFormatter from '../../../common/numberFormatter';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';

import { PurchaseNetTotalEditor } from './PurchaseNetTotalEditor';

interface PurchaseNetTotalFormProps {
  estimateItem: GetEstimateItemDto;
  purchaseInvoiceId?: string;
  handleRefresh: () => void;
  disabled?: boolean;
}

export const PurchaseNetTotalForm: React.FC<
  PurchaseNetTotalFormProps
> = props => {
  const [show, setShow] = useState(false);

  const pok = usePokCore();

  const updateAmount = async (amount: string) => {
    try {
      await pok.estimateItems.updatePurchaseNetTotal(
        props.estimateItem.id,
        amount,
        props.purchaseInvoiceId || '',
      );
      setShow(false);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {numberFormatter(props.estimateItem.purchaseNetTotal || '0', true)}
      {!props.disabled && (
        <OverlayTrigger overlay={<Tooltip>Edytuj kwotę total</Tooltip>}>
          <Button
            variant="outline-secondary"
            disabled={props.disabled}
            className="mx-2"
            onClick={() => setShow(true)}
          >
            <Icon.Pencil size={16} />
          </Button>
        </OverlayTrigger>
      )}
      {show && (
        <PurchaseNetTotalEditor
          estimateItem={props.estimateItem}
          onCompleted={updateAmount}
          handleRefresh={props.handleRefresh}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
