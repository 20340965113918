import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Select from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import FormRow from '../../../app/components/FormRow';
import TeamSelector from '../../components/Selects/TeamSelector';
import { Option } from '../../components/Selects/Selector';
import {
  JobTypeEnum,
  JobTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import CompanySelector from '../../components/Selects/CompanySelector';

export const JobConfigurationView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [teamId, setTeamId] = useState<string>();
  const [teamName, setTeamName] = useState<string>();
  const [companyId, setCompanyId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [cron, setCron] = useState<string>();
  const [emails, setEmails] = useState<string>();
  const [selectedType, setSelectedType] = useState<JobTypeEnum | undefined>();

  const createStandardJobConfig = async () => {
    setWaiting(true);
    pok.imports
      .createStandardJobConfig(cron || '0 0 * * *', {
        type: selectedType || JobTypeEnum.DailyFVZReminder,
        teamId: teamId,
        teamName: teamName,
        companyId: companyId,
        companyName: companyName,
        emails: emails,
      })
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const jobTypesOptions = Object.entries(JobTypeEnum).map(([key, value]) => ({
    value: key as JobTypeEnum,
    label: JobTypeEnumText[value],
  }));

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Konfiguracja zadań</Card.Title>
        {waiting && <Waiting />}
        {!waiting && (
          <Form className="d-grid gap-3">
            <FormRow controlId="type" label="Typ">
              <Select
                options={jobTypesOptions}
                onChange={option => setSelectedType(option?.value)}
                placeholder="Wskaż..."
              />
            </FormRow>
            <FormRow controlId="company" label="Spółka">
              <CompanySelector
                value={companyId}
                onChange={option => {
                  setCompanyId((option as Option)?.value);
                  setCompanyName((option as Option)?.label);
                }}
              />
            </FormRow>
            <FormRow controlId="team" label="Zespół">
              <TeamSelector
                value={teamId}
                companyId={companyId}
                onChange={option => {
                  option ? setTeamId((option as Option)?.value) : null;
                  option ? setTeamName((option as Option)?.label) : null;
                }}
                clearable
              />
            </FormRow>
            <FormRow controlId="cron" label="Cron">
              <Form.Control
                type="text"
                value={cron || ''}
                onChange={e => setCron(e.target.value || undefined)}
                onBlur={e => setCron(e.target.value || undefined)}
              />
            </FormRow>
            <FormRow controlId="additionalMail" label="Dodatkowe maile">
              <Form.Control
                type="text"
                value={emails || ''}
                onChange={e => setEmails(e.target.value || undefined)}
                onBlur={e => setEmails(e.target.value || undefined)}
              />
            </FormRow>
            <div className="d-grid gap-2">
              <Button
                variant="outline-success"
                size="lg"
                onClick={createStandardJobConfig}
              >
                Dodanie zadania
              </Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};
