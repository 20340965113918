import React from 'react';
import { Button, Card } from 'react-bootstrap';

import {
  CreateUpdateExcelDto,
  GetExcelAndPokDataDto,
  GetExcelDataDto,
  GetResultsDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { ExcelImportSummary } from '../ExcelConfig/ExcelImportSummary';

import { ExcelPreviewData } from './ExcelPreviewData';
import { ExcelImportForm } from './ExcelImportForm';
import { PreviewImportData } from './ExcelCompareData';

interface ExcelImportStepsProps {
  step: number;
  waiting: boolean;
  excelData: GetExcelDataDto | undefined;
  compareData: GetExcelAndPokDataDto | undefined;
  results: GetResultsDto | undefined;
  handleGetExcelData: (excel: CreateUpdateExcelDto) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  handleCompareExcelAndPOK: (excel: GetExcelDataDto) => void;
  handleImportExcel: (data: GetExcelAndPokDataDto) => void;
  setStartStep: () => void;
}

export const ExcelImportSteps: React.FC<ExcelImportStepsProps> = props => {
  if (props.waiting) {
    return (
      <div className="">
        <h2 className="text-center pt-5">
          {props.step === 1
            ? 'Trwa pobieranie danych...'
            : props.step === 2
              ? 'Trwa porównywanie danych...'
              : 'Trwa importowanie danych...'}
        </h2>
        <Waiting />
      </div>
    );
  }

  switch (props.step) {
    case 1:
      return <ExcelImportForm onImport={props.handleGetExcelData} />;
    case 2:
      return (
        <>
          {props.excelData ? (
            <ExcelPreviewData
              data={props.excelData}
              handleCompareExcelAndPOK={props.handleCompareExcelAndPOK}
            />
          ) : (
            <div>Brak danych</div>
          )}
          <ConfirmationButton
            variant="outline-primary"
            onOK={() => props.setStartStep()}
            confirmation={`Czy na pewno wyjść z aktualnego importu?`}
          >
            Nowy import
          </ConfirmationButton>
        </>
      );
    case 3:
      return (
        <>
          {props.compareData ? (
            <PreviewImportData
              data={props.compareData}
              handleImportExcel={props.handleImportExcel}
            />
          ) : (
            <div>Brak danych</div>
          )}
          <ConfirmationButton
            variant="outline-primary"
            onOK={() => props.setStartStep()}
            confirmation={`Czy na pewno wyjść z aktualnego importu?`}
            className="mt-5"
          >
            Nowy import
          </ConfirmationButton>
        </>
      );
    case 4:
      return (
        <>
          <Card.Title className="pb-3">Podsumowanie importu</Card.Title>
          <ExcelImportSummary results={props.results} />
          <Button
            variant="outline-primary"
            onClick={() => props.setStartStep()}
            className="mt-5"
          >
            Nowy import
          </Button>
        </>
      );
    default:
      return null;
  }
};
