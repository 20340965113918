import React from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import Select, { MultiValue } from 'react-select';

import { CreateUpdateEmailConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  EmailConfigTypeEnum,
  EmailConfigTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import CompanySelector from '../Selects/CompanySelector';
import TeamSelector from '../Selects/TeamSelector';

interface EmailConfigEditorProps {
  emailConfig: CreateUpdateEmailConfigDto;
  readOnly: boolean;
  propertyChange: (obj: Partial<CreateUpdateEmailConfigDto>) => void;
  createErrors: (errors: string[]) => void;
}

const typeOptions = Object.entries(EmailConfigTypeEnum).map(([key, value]) => ({
  value: key as EmailConfigTypeEnum,
  label: EmailConfigTypeEnumText[value],
}));

export const EmailConfigFrom: React.FC<EmailConfigEditorProps> = props => {
  const onChangeTeams = (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      props.propertyChange({
        teamIds: (option as Option[]).map(({ value }) => value),
      });
    }
  };

  return (
    <Form className="d-grid gap-3">
      <FormRow controlId="type" label="Typ konfiguracji">
        <Select
          className="full-width"
          value={
            props.emailConfig.type
              ? typeOptions.find(
                  ({ value }) => props.emailConfig.type === value,
                )
              : null
          }
          options={typeOptions}
          onChange={option =>
            props.propertyChange({
              type: option?.value,
            })
          }
          isDisabled={props.readOnly}
          placeholder="Wskaż..."
        />
      </FormRow>
      <FormRow controlId="emails" label="Adresy e-mail">
        <Form.Control
          type="text"
          value={props.emailConfig.emails.join(',')}
          onChange={e =>
            props.propertyChange({
              emails: e.target.value.split(','),
            })
          }
          readOnly={props.readOnly}
          placeholder="jan@kowalski.pl, adam@nowak.pl, mateusz@wojcik.pl"
        />
      </FormRow>
      <FormRow controlId="company" label="Spółka">
        <CompanySelector
          readOnly={props.readOnly}
          value={props.emailConfig.companyId}
          onChange={option =>
            props.propertyChange({
              companyId: (option as Option)?.value,
              teamIds: [],
            })
          }
          clearable
        />
      </FormRow>
      <FormRow controlId="team" label="Zespoły">
        <TeamSelector
          isMulti={true}
          value={props.emailConfig.teamIds}
          companyId={props.emailConfig.companyId}
          onChange={options => onChangeTeams(options)}
          clearable
        />
      </FormRow>
      <FormRow controlId="active" label="Aktywny">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.emailConfig?.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
    </Form>
  );
};
