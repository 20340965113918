import React from 'react';

import ToastDisplay from './ToastDisplay';

export default (props: { text: React.ReactNode; timestamp: number }) => {
  return (
    <ToastDisplay
      text={props.text}
      timestamp={props.timestamp}
      variant="danger"
    />
  );
};
