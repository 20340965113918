import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateSymphonyClientDto,
  CreateUpdateSymphonyClientDtoFromJSON,
  DefaultApi,
  GetSymphonyClientDto,
} from '../autogenerated/pokApiClient';
import { GetLastEditorDto } from '../autogenerated/pokApiClient/models/GetLastEditorDto';
import { SymphonyClientSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { symphonyForGrid } from '../../../pok/components/Symphony/SymphonySearch';

export interface SymphonyClientContextInterface {
  getById: (id: string) => Promise<GetSymphonyClientDto>;
  getAllByPortionForClient: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    clientId?: string,
    filterToCurrentCompany?: boolean,
  ) => Promise<GridRecord[]>;
  getAllCountForClient: (
    filterText?: string,
    clientId?: string,
    filterToCurrentCompany?: boolean,
  ) => Promise<number>;
  getByClientCompany: (
    clientId: string,
    companyId: string,
  ) => Promise<GetSymphonyClientDto[]>;
  update: (id: string, dto: CreateUpdateSymphonyClientDto) => Promise<void>;
  create: (dto: CreateUpdateSymphonyClientDto) => Promise<GetSymphonyClientDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const SymphonyClientContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.symphonyClientControllerGet(id),
    getByClientCompany: (clientId: string, companyId: string) =>
      api.symphonyClientControllerGetByClientCompany(clientId, companyId),
    getAllByPortionForClient: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      clientId?: string,
      filterToCurrentCompany?: boolean,
    ) => {
      const data = await api.symphonyClientControllerGetAllByPortionForClient(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        clientId,
        filterToCurrentCompany,
      );

      return data.map(symphonyForGrid);
    },
    getAllCountForClient: (
      filterText?: string,
      clientId?: string,
      filterToCurrentCompany?: boolean,
    ) =>
      api.symphonyClientControllerGetAllCountForClient(
        filterText || '',
        clientId,
        filterToCurrentCompany,
      ),
    update: (id: string, dto: CreateUpdateSymphonyClientDto) =>
      api.symphonyClientControllerUpdate(id, dto),
    create: (dto: CreateUpdateSymphonyClientDto) =>
      api.symphonyClientControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.symphonyClientControllerGetLastEditor(id),
  };
};

export const newSymphonyCode = (clientId?: string) => {
  return CreateUpdateSymphonyClientDtoFromJSON({ clientId });
};

export const validate = (symphonyClient: CreateUpdateSymphonyClientDto) =>
  validateAgainst(SymphonyClientSchema, symphonyClient);

export const convert = (symphonyClient?: GetSymphonyClientDto) => {
  if (!symphonyClient) {
    return newSymphonyCode();
  }

  const converted = CreateUpdateSymphonyClientDtoFromJSON(symphonyClient);
  converted.clientId = symphonyClient.client?.id;
  converted.companyId = symphonyClient.company?.id;

  return converted;
};
