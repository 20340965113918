/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetPositionDto } from './GetPositionDto';
import {
    GetPositionDtoFromJSON,
    GetPositionDtoFromJSONTyped,
    GetPositionDtoToJSON,
} from './GetPositionDto';

/**
 * 
 * @export
 * @interface GetPaeEstimateItemsDto
 */
export interface GetPaeEstimateItemsDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaeEstimateItemsDto
     */
    idx: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaeEstimateItemsDto
     */
    estimateDate: string;
    /**
     * 
     * @type {GetPositionDto}
     * @memberof GetPaeEstimateItemsDto
     */
    position: GetPositionDto;
    /**
     * 
     * @type {string}
     * @memberof GetPaeEstimateItemsDto
     */
    paePositionSystemNumber: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetPaeEstimateItemsDto
     */
    purchaseFormDict: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetPaeEstimateItemsDto
     */
    paePurchaseForm: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaeEstimateItemsDto
     */
    purchaseAmount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaeEstimateItemsDto
     */
    salesAmount: string;
}

/**
 * Check if a given object implements the GetPaeEstimateItemsDto interface.
 */
export function instanceOfGetPaeEstimateItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idx" in value;
    isInstance = isInstance && "estimateDate" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "paePositionSystemNumber" in value;
    isInstance = isInstance && "purchaseFormDict" in value;
    isInstance = isInstance && "paePurchaseForm" in value;
    isInstance = isInstance && "purchaseAmount" in value;
    isInstance = isInstance && "salesAmount" in value;

    return isInstance;
}

export function GetPaeEstimateItemsDtoFromJSON(json: any): GetPaeEstimateItemsDto {
    return GetPaeEstimateItemsDtoFromJSONTyped(json, false);
}

export function GetPaeEstimateItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaeEstimateItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idx': json['idx'],
        'estimateDate': json['estimateDate'],
        'position': GetPositionDtoFromJSON(json['position']),
        'paePositionSystemNumber': json['paePositionSystemNumber'],
        'purchaseFormDict': GetDictionaryDtoFromJSON(json['purchaseFormDict']),
        'paePurchaseForm': json['paePurchaseForm'],
        'purchaseAmount': json['purchaseAmount'],
        'salesAmount': json['salesAmount'],
    };
}

export function GetPaeEstimateItemsDtoToJSON(value?: GetPaeEstimateItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idx': value.idx,
        'estimateDate': value.estimateDate,
        'position': GetPositionDtoToJSON(value.position),
        'paePositionSystemNumber': value.paePositionSystemNumber,
        'purchaseFormDict': GetDictionaryDtoToJSON(value.purchaseFormDict),
        'paePurchaseForm': value.paePurchaseForm,
        'purchaseAmount': value.purchaseAmount,
        'salesAmount': value.salesAmount,
    };
}

