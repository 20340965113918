import React from 'react';

import { ApplicationStatusEnum } from '../../../common/pokCore/validation/schemas';

import { ApplicationSearch } from './ApplicationSearch';

interface PendingApplicationTabProps {
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const PendingApplicationTab: React.FC<
  PendingApplicationTabProps
> = props => {
  return (
    <ApplicationSearch
      status={ApplicationStatusEnum.New}
      onlyActive
      {...props}
    />
  );
};
