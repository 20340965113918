import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import {
  GetCompanyDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface SalesInvoiceMissingSymphonyCodeAlertProps {
  purchaser?: GetPurchaserDto;
  company?: GetCompanyDto;
}

export const SalesInvoiceMissingSymphonyCodeAlert: React.FC<
  SalesInvoiceMissingSymphonyCodeAlertProps
> = props => {
  const [isSymphonyCodeMissing, setIsSymphonyCodeMissing] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();
  const companyId = pok.getCompanyId();

  useEffect(() => {
    if (props.purchaser?.client?.id) {
      pok.symphonyClients
        .getByClientCompany(props.purchaser.client.id, companyId)
        .then(data => {
          setIsSymphonyCodeMissing(!data.length);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [
    companyId,
    notifications,
    pok.symphonyClients,
    props.purchaser?.client.id,
  ]);

  if (isSymphonyCodeMissing) {
    return (
      <Alert variant="warning">
        <p className="m-0">
          Nie znaleziono numeru Symfonii dla rozliczonego klienta i spółki{' '}
          <b>{props?.company?.name}</b>. Faktura po zatwierdzeniu nie zostanie
          wyeksportowana do systemu Symfonia.
        </p>
      </Alert>
    );
  }

  return null;
};
