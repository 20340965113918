import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  ApplicationStatusEnum,
  ApplicationStatusEnumText,
} from '../../../common/pokCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

import { ApplicationRecordSelector } from './ApplicationRecordSelector';

interface ApplicationAdminEditorStatusProps {
  id?: string;
  application: UpdateAdminApplicationDto;
  purchaserName?: string;
  positionName?: string;
  brandName?: string;
  isReadOnly?: boolean;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

const applicationStatusOptions = [
  {
    value: ApplicationStatusEnum.Completed,
    label: ApplicationStatusEnumText.Completed,
  },
  {
    value: ApplicationStatusEnum.Rejected,
    label: ApplicationStatusEnumText.Rejected,
  },
];

export const ApplicationAdminEditorStatus: React.FC<
  ApplicationAdminEditorStatusProps
> = props => {
  const [application, setApplication] = useState(props.application);

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  useEffect(() => {
    setApplication({
      ...props.application,
      applicationStatus:
        props.application.applicationStatus === ApplicationStatusEnum.New
          ? ApplicationStatusEnum.Completed
          : props.application.applicationStatus,
    });
  }, [props.application]);

  return (
    <>
      <Card className="card flex-grow-1">
        <Card.Header>Realizacja wniosku</Card.Header>
        <Card.Body className="m-1">
          <div className="d-grid gap-3 mb-3">
            <FormRow controlId="applicationStatus" label="Status">
              <Select
                value={
                  application?.applicationStatus
                    ? applicationStatusOptions.find(
                        ({ value }) => application?.applicationStatus === value,
                      )
                    : null
                }
                options={applicationStatusOptions}
                onChange={option =>
                  propertyChange({
                    applicationStatus: option?.value,
                    rejectedNote: '',
                  })
                }
                isDisabled={props.isReadOnly}
              />
            </FormRow>
            {application.applicationStatus ===
              ApplicationStatusEnum.Completed && (
              <ApplicationRecordSelector
                purchaserName={props.purchaserName}
                positionName={props.positionName}
                brandName={props.brandName}
                application={application}
                propertyChange={propertyChange}
                readOnly={props.isReadOnly}
              />
            )}
            {application.applicationStatus ===
              ApplicationStatusEnum.Rejected && (
              <>
                <FormRow
                  controlId="rejectedNote"
                  label="Uzasadnienie odrzucenia"
                >
                  <DynamicTextarea
                    value={application.rejectedNote || ''}
                    onChange={e =>
                      propertyChange({
                        rejectedNote: e.target.value || '',
                      })
                    }
                    disabled={props.isReadOnly}
                  />
                </FormRow>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
