import React, { useState, useEffect } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

export default (props: {
  text: React.ReactNode;
  timestamp: number;
  variant?: string;
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!props.text) {
      return;
    }

    setShow(true);
  }, [props.timestamp, props.text]);

  return (
    <>
      {show && (
        <ToastContainer className="p-3" position="bottom-start">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide={true}
            delay={6000}
            bg={props.variant || 'warning'}
          >
            <Toast.Body>{props.text}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};
