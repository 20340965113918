import React from 'react';
import { Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import ReactHtmlParser from 'react-html-parser';
import 'react-quill/dist/quill.snow.css';

export default (props: {
  value: string;
  readOnly?: boolean;
  onChange(value: string): void;
}) => {
  const toolbarOptions = [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];
  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      {props.readOnly ? (
        <div>
          <Alert
            className="alert-disabled ql-editor"
            style={{ minHeight: '38px', marginBottom: '0' }}
          >
            {ReactHtmlParser(props.value)}
          </Alert>
        </div>
      ) : (
        <ReactQuill
          modules={modules}
          theme="snow"
          value={props.value}
          onChange={value => props.onChange(value)}
        />
      )}
    </>
  );
};
