import React, { useRef } from 'react';
import { Alert, Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateSalesInvoiceDto,
  GetCompanyDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  InvoiceStatusEnum,
  InvoiceStatusEnumText,
} from '../../../common/pokCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import momencik from '../../../common/momencik';

import { SalesInvoiceMissingSymphonyCodeAlert } from './SalesInvoiceMissingSymphonyCodeAlert';

interface SalesInvoiceBasicDataEditorProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
  isOnePositionEnabled: boolean;
  purchaser?: GetPurchaserDto;
  company?: GetCompanyDto;
}

const availableStatusesToEditByUser = [
  InvoiceStatusEnum.Draft,
  InvoiceStatusEnum.Generated,
];

const statusOptions = Object.entries(InvoiceStatusEnum).map(([key, value]) => ({
  value: key as InvoiceStatusEnum,
  label: InvoiceStatusEnumText[value],
}));

const isOnePositionAlertVisible = ({
  isOnePositionEnabled,
  salesInvoice,
}: SalesInvoiceBasicDataEditorProps) =>
  !isOnePositionEnabled && salesInvoice.isOnePositionOnInvoice;

const isInvoiceDateAlertVisible = ({
  salesInvoice,
  readOnly,
}: SalesInvoiceBasicDataEditorProps) =>
  salesInvoice.status === InvoiceStatusEnum.Generated &&
  !readOnly &&
  momencik(salesInvoice.invoiceDate) !== momencik(new Date());

const isMissingSymphonyCodeAlertVisible = ({
  salesInvoice,
}: SalesInvoiceBasicDataEditorProps) =>
  salesInvoice.status !== InvoiceStatusEnum.Sent;

export const SalesInvoiceSummaryEditor: React.FC<
  SalesInvoiceBasicDataEditorProps
> = props => {
  const { salesInvoice, propertyChange, readOnly } = props;
  const onePositionNameRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      <FormRow controlId="Status" label="Status">
        <Select
          value={
            salesInvoice.status
              ? statusOptions.find(({ value }) => salesInvoice.status === value)
              : null
          }
          options={statusOptions.filter(({ value }) =>
            availableStatusesToEditByUser.includes(value),
          )}
          onChange={option =>
            propertyChange({
              status: option?.value,
            })
          }
          isDisabled={readOnly}
        />
      </FormRow>
      {salesInvoice.parentId && (
        <FormRow
          controlId="correctionReason"
          label="Przyczyna wystawienia korekty"
        >
          <DynamicTextarea
            value={salesInvoice.correctionReason || ''}
            onChange={e => propertyChange({ correctionReason: e.target.value })}
            disabled={readOnly}
          />
        </FormRow>
      )}
      <FormRow
        controlId="isOnePositionOnInvoice"
        label="Jedna pozycja na fakturze"
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={salesInvoice.isOnePositionOnInvoice ?? false}
          disabled={readOnly}
          onChange={e => {
            const { checked } = e.target;
            propertyChange({
              isOnePositionOnInvoice: checked,
              onePositionName: checked ? salesInvoice.onePositionName : null!,
            });

            if (checked) {
              setTimeout(() => {
                if (onePositionNameRef.current) {
                  onePositionNameRef.current?.focus();
                }
              }, 1);
            }
          }}
        />
      </FormRow>
      {salesInvoice.isOnePositionOnInvoice && (
        <FormRow controlId="onePositionName" label="Nazwa pozycji">
          <DynamicTextarea
            ref={onePositionNameRef}
            value={salesInvoice.onePositionName || ''}
            onChange={e => propertyChange({ onePositionName: e.target.value })}
            disabled={readOnly}
          />
        </FormRow>
      )}
      <FormRow controlId="textOverItems" label="Tekst do wydruku nad pozycjami">
        <DynamicTextarea
          value={salesInvoice.textOverItems || ''}
          onChange={e => propertyChange({ textOverItems: e.target.value })}
          disabled={readOnly}
        />
      </FormRow>
      {isInvoiceDateAlertVisible(props) && (
        <Alert variant="warning">
          <p className="m-0">
            Dokument zostanie zatwierdzony z datą wystawienia{' '}
            {momencik(salesInvoice.invoiceDate, 'DD.MM.YYYY')} oraz z datą
            sprzedaży {momencik(salesInvoice.saleDate, 'DD.MM.YYYY')}.
          </p>
        </Alert>
      )}
      {isOnePositionAlertVisible(props) && (
        <Alert variant="warning">
          <p className="m-0">
            W pozycjach faktury występują różne stawki VAT dla pozycji. Aby
            kontynuować, odznacz pole <b>Jedna pozycja na fakturze</b> lub ustaw
            jednakowe stawki VAT dla pozycji.
          </p>
        </Alert>
      )}
      {isMissingSymphonyCodeAlertVisible(props) && (
        <SalesInvoiceMissingSymphonyCodeAlert
          purchaser={props.purchaser}
          company={props.company}
        />
      )}
    </>
  );
};
