import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  CreateUpdatePurchaseInvoiceDto,
  GetPurchaseInvoiceDto,
  GetPurchaseInvoiceExtendedDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { validateGeneralTab } from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { usePokCore } from '../../../common/hooks/usePokCore';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { PurchaseInvoiceStatusEnum } from '../../../common/pokCore/validation/schemas';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';
import usePurchaseInvoicePermissions from '../PurchaseInvoices/usePurchaseInvoicePermissions';
import { useNavigation } from '../../../common/navigation';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import useChangeTab from './useChangeTab';

interface PurchaseInvoiceEditorFormGeneralControlsProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  readOnly?: boolean;
  handleRefresh: (id?: string) => Promise<void>;
  setSaveErrors: Dispatch<SetStateAction<string[]>>;
  changeAmountOwnerName?: string;
}

const isAllowedToUnApprove = (
  purchaseInvoice?: CreateUpdatePurchaseInvoiceDto,
) =>
  purchaseInvoice?.active &&
  purchaseInvoice?.status !== PurchaseInvoiceStatusEnum.Open;

const isAllowedToApprove = (purchaseInvoice?: CreateUpdatePurchaseInvoiceDto) =>
  purchaseInvoice?.active &&
  purchaseInvoice?.status === PurchaseInvoiceStatusEnum.Open;

export const PurchaseInvoiceEditorFormGeneralControls: React.FC<
  PurchaseInvoiceEditorFormGeneralControlsProps
> = props => {
  const { isFinanceUser } = usePurchaseInvoicePermissions();
  const pok = usePokCore();
  const notifications = useNotifications();
  const handleChangeTab = useChangeTab<PurchaseInvoiceTabsEnum>();
  const nav = useNavigation();
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const id = props.extendedPurchaseInvoice?.id || '';

  const handleSave = async (redirect?: boolean) => {
    let createdPurchaseInvoice: GetPurchaseInvoiceDto | undefined;
    if (id) {
      await pok.purchaseInvoices.update(id, props.purchaseInvoice);
      if (redirect) {
        await props.handleRefresh();
        handleGoNext();
      }
    } else {
      createdPurchaseInvoice = await pok.purchaseInvoices.create(
        props.purchaseInvoice,
      );
    }
    if (!id && createdPurchaseInvoice?.id && redirect) {
      nav.changePurchaseInvoiceEditorPath(
        createdPurchaseInvoice.id,
        PurchaseInvoiceTabsEnum.TEAM_PROJECTS,
      );
      await props.handleRefresh(createdPurchaseInvoice?.id);
    }
    notifications.saveCompleted();
  };

  const addEditPurchaseInvoice = async () => {
    props.setSaveErrors([]);
    const status = await validateGeneralTab(props.purchaseInvoice);
    if (status.valid) {
      try {
        setIsLoadingSave(true);
        await handleSave(true);
      } catch (response) {
        props.setSaveErrors(await responseErrors(response as ResponseError));
      } finally {
        setIsLoadingSave(false);
      }
    } else {
      props.setSaveErrors(status.errors);
    }
  };

  const handleSaveAndApprove = async () => {
    try {
      setIsLoadingApprove(true);
      await handleSave();
      await props.handleRefresh();
      await pok.purchaseInvoices.approve(id);
      await props.handleRefresh();
      props.setSaveErrors([]);
    } catch (response) {
      const errors = await responseErrors(response as ResponseError);
      props.setSaveErrors(errors);
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const handleUnApprove = () => {
    if (id) {
      setIsLoadingApprove(true);
      return pok.purchaseInvoices
        .unApprove(id)
        .then(() => props.handleRefresh())
        .catch(async response => {
          const errors = await responseErrors(response as ResponseError);
          props.setSaveErrors(errors);
        })
        .finally(() => setIsLoadingApprove(false));
    }
  };

  const handleDelete = () => {
    if (id) {
      setIsLoadingDelete(true);
      return pok.purchaseInvoices
        .deactivate(id)
        .then(() => props.handleRefresh())
        .catch(async response => {
          const errors = await responseErrors(response as ResponseError);
          props.setSaveErrors(errors);
        })
        .finally(() => setIsLoadingDelete(false));
    }
  };

  const handleGoNext = () =>
    handleChangeTab(PurchaseInvoiceTabsEnum.TEAM_PROJECTS);

  return (
    <>
      <div className="side-by-side">
        <div className="left">
          {id && (
            <LastEditorInfo
              className="mt-4 color-primary"
              id={id}
              method={pok.purchaseInvoices.getLastEditor}
              additionalText={
                props.purchaseInvoice?.changeAmountsTs
                  ? 'Ostatnia modyfikacja dowiązań do pozycji'
                  : ''
              }
            />
          )}
        </div>
        <div className="right">
          {isAllowedToApprove(props.purchaseInvoice) && isFinanceUser && (
            <>
              <ConfirmationButton
                variant="outline-danger"
                className="ms-2 mt-4"
                onOK={handleDelete}
                isLoading={isLoadingDelete}
                confirmation="Czy na pewno usunąć wyświetlaną fakturę zakupu?"
              >
                Usuń
              </ConfirmationButton>

              <Button
                className="ms-2 mt-4"
                variant="outline-primary"
                onClick={handleSaveAndApprove}
                isLoading={isLoadingApprove}
              >
                Zapisz i zatwierdź
              </Button>
            </>
          )}
          {isAllowedToUnApprove(props.purchaseInvoice) && isFinanceUser && (
            <ConfirmationButton
              variant="outline-primary"
              className="ms-2 mt-4"
              isLoading={isLoadingApprove}
              onOK={handleUnApprove}
              confirmation="Czy na pewno zmienić status faktury na 'Wprowadzona'?"
            >
              Cofnij stan
            </ConfirmationButton>
          )}
          <Button
            className="ms-2 mt-4"
            variant="primary"
            onClick={props.readOnly ? handleGoNext : addEditPurchaseInvoice}
            isLoading={isLoadingSave}
          >
            {props.readOnly ? 'Przejdź dalej' : 'Zapisz i przejdź dalej'}
          </Button>
        </div>
      </div>
    </>
  );
};
