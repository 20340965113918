import React from 'react';

import { ApplicationUnblockSearch } from '../../components/ApplicationsUnblock/ApplicationUnblockSearch';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { usePokCore } from '../../../common/hooks/usePokCore';

interface AllApplicationUnblockTabProps {
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const AllApplicationUnblockTab: React.FC<
  AllApplicationUnblockTabProps
> = props => {
  const pok = usePokCore();

  const getData = ((
    pageSize,
    pageNumber,
    orderBy,
    orderDirection,
    filterText,
  ) =>
    pok.applicationsUnblock.getAllByPortion(
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
    )) as GridGetterFunction;

  const getCount = (filterText =>
    pok.applicationsUnblock.getAllCount(filterText)) as GridCountFunction;

  return (
    <ApplicationUnblockSearch
      getData={getData}
      getCount={getCount}
      isNavView
      showFilter
      {...props}
    />
  );
};
