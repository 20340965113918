import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useNotifications } from '../../hooks/useNotifications';

interface CopyButtonProps {
  value: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const notifications = useNotifications();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true);
      },
      () => {
        notifications.error(
          'Coś poszło nie tak przy kopiowaniu tekstu do schowka.',
        );
      },
    );
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          {copied
            ? 'Skopiowano do schowka. Wklej skrótem Ctrl+V.'
            : 'Kopiuj do schowka'}
        </Tooltip>
      }
    >
      <Button
        onClick={() => copyToClipboard(value)}
        variant="outline-secondary"
        className="mx-2"
      >
        {copied ? <Icon.Clipboard2CheckFill /> : <Icon.Clipboard2Fill />}
      </Button>
    </OverlayTrigger>
  );
};

export default CopyButton;
