import React from 'react';
import {
  useTranslation as _useTranslation,
  Trans as BaseTrans,
  // eslint-disable-next-line import/named
  UseTranslationOptions,
} from 'react-i18next';

import { getKeys, keys } from '../../translations/keys';
import { TranslationSchema } from '../../translations/resources';

export type TKeys<NS extends keyof TranslationSchema> = (typeof keys)[NS];

export const useTranslation = <T extends keyof TranslationSchema>(
  ns: T,
  options?: UseTranslationOptions<T>,
) => {
  const { t, i18n } = _useTranslation(ns, options);

  const Trans: typeof BaseTrans = props => <BaseTrans i18n={i18n} {...props} />;

  return {
    t, // Include the 't' translation function
    i18n,
    Trans,
    tk: getKeys(ns),
  };
};
