/*eslint max-lines-per-function: ["error", 200]*/
import React, { useMemo } from 'react';

import {
  CreateUpdateInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';

import {
  calculateEstimateItemAmountSum,
  calculateEstimateItemCommissionAmountSum,
  calculateEstimateItemCommissionSum,
  calculateEstimateItemSalesSum,
  calculatePreviousEstimateItemAmountSum,
  calculatePreviousEstimateItemCommissionAmountSum,
} from './calculateSalesInvoiceAmountsUtils';
import { TableColumn } from './SalesInvoiceMediaPlanPositionsGrid';

interface SalesInvoiceMediaPlanPositionsSumRowProps {
  availableEstimateItems?: GetEstimateItemDto[];
  salesInvoiceEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  salesInvoice?: GetSalesInvoiceDto;
  isGenerated: boolean;
  columns: TableColumn[];
  filteredAvailableEstimateItems: GetEstimateItemDto[];
  parentAmount?: string;
}

const SalesInvoiceMediaPlanPositionsSumRow = ({
  filteredAvailableEstimateItems,
  availableEstimateItems,
  salesInvoiceEstimateItems,
  salesInvoiceOriginalEstimateItems,
  isGenerated,
  salesInvoice,
  columns,
  parentAmount,
}: SalesInvoiceMediaPlanPositionsSumRowProps) => {
  const salesSum = useMemo(
    () =>
      calculateEstimateItemSalesSum(
        availableEstimateItems,
        salesInvoiceOriginalEstimateItems,
      ),
    [availableEstimateItems, salesInvoiceOriginalEstimateItems],
  );

  const amountSum = useMemo(
    () =>
      calculateEstimateItemAmountSum(
        availableEstimateItems,
        salesInvoiceEstimateItems,
      ),
    [availableEstimateItems, salesInvoiceEstimateItems],
  );

  const commissionSum = useMemo(
    () =>
      calculateEstimateItemCommissionSum(
        availableEstimateItems,
        salesInvoiceOriginalEstimateItems,
      ),
    [availableEstimateItems, salesInvoiceOriginalEstimateItems],
  );

  const commissionAmountSum = useMemo(
    () =>
      calculateEstimateItemCommissionAmountSum(
        availableEstimateItems,
        salesInvoiceEstimateItems,
      ),
    [availableEstimateItems, salesInvoiceEstimateItems],
  );

  const filteredSalesSum = useMemo(
    () =>
      calculateEstimateItemSalesSum(
        filteredAvailableEstimateItems,
        salesInvoiceOriginalEstimateItems,
      ),
    [filteredAvailableEstimateItems, salesInvoiceOriginalEstimateItems],
  );

  const filteredAmountSum = useMemo(
    () =>
      calculateEstimateItemAmountSum(
        filteredAvailableEstimateItems,
        salesInvoiceEstimateItems,
      ),
    [filteredAvailableEstimateItems, salesInvoiceEstimateItems],
  );

  const filteredCommissionSum = useMemo(
    () =>
      calculateEstimateItemCommissionSum(
        filteredAvailableEstimateItems,
        salesInvoiceOriginalEstimateItems,
      ),
    [filteredAvailableEstimateItems, salesInvoiceOriginalEstimateItems],
  );

  const filteredCommissionAmountSum = useMemo(
    () =>
      calculateEstimateItemCommissionAmountSum(
        filteredAvailableEstimateItems,
        salesInvoiceEstimateItems,
      ),
    [filteredAvailableEstimateItems, salesInvoiceEstimateItems],
  );

  const saleAmountPrevious = useMemo(
    () =>
      calculatePreviousEstimateItemAmountSum(
        availableEstimateItems,
        salesInvoice?.parent?.invoiceEstimateItems,
      ),
    [availableEstimateItems, salesInvoice?.parent?.invoiceEstimateItems],
  );

  const commissionAmountPrevious = useMemo(
    () =>
      calculatePreviousEstimateItemCommissionAmountSum(
        availableEstimateItems,
        salesInvoice?.parent?.invoiceEstimateItems,
      ),
    [availableEstimateItems, salesInvoice?.parent?.invoiceEstimateItems],
  );

  const getSum = (column: TableColumn) => {
    switch (column.key) {
      case 'sale':
        return filteredSalesSum;

      case 'sale-amount':
      case 'sale-amount-correction':
        return filteredAmountSum;

      case 'sale-amount-previous':
        return saleAmountPrevious;

      case 'commission':
        return filteredCommissionSum;

      case 'commission-amount':
      case 'commission-amount-correction':
        return filteredCommissionAmountSum;

      case 'commission-amount-previous':
        return commissionAmountPrevious;

      case 'project':
        return <b>SUMA</b>;

      default:
        return null;
    }
  };

  return (
    <>
      <tr className="align-middle">
        {columns
          .filter(({ isHidden }) => !isHidden)
          .map(col => {
            const value = getSum(col);

            return (
              <td key={col.key}>
                {typeof value === 'number'
                  ? numberFormatter(value, true)
                  : value}
              </td>
            );
          })}
      </tr>
      <tr>
        <td colSpan={5} />
      </tr>
      {!isGenerated && (
        <tr className="align-middle">
          <td colSpan={4}>
            <b>Suma sprzedaży i prowizji mediaplanu</b>
          </td>
          <td>
            <b>
              {numberFormatter(mathUtils.add(salesSum, commissionSum), true)}
            </b>
          </td>
        </tr>
      )}
      <tr className="align-middle">
        <td colSpan={4}>
          <b>Suma do fakturowania sprzedaży i prowizji</b>
        </td>
        <td>
          <b>
            {numberFormatter(
              mathUtils.add(amountSum, commissionAmountSum),
              true,
            )}
          </b>
        </td>
      </tr>
      {salesInvoice?.parent?.id && (
        <tr className="align-middle">
          <td colSpan={4}>
            <b>Kwota korekty</b>
          </td>
          <td>
            <b>
              {numberFormatter(
                mathUtils.subtract(
                  mathUtils.add(amountSum, commissionAmountSum),
                  parentAmount,
                ),
                true,
              )}
            </b>
          </td>
        </tr>
      )}
    </>
  );
};

export default SalesInvoiceMediaPlanPositionsSumRow;
