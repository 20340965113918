import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { TeamEditorTabbed } from '../../components/Teams/TeamEditorTabbed';
import { useNotifications } from '../../../common/hooks/useNotifications';

export const TeamView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { id } = useParams<string>();
  const [team, setTeam] = useState<GetTeamDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      pok.teams
        .getById(id)
        .then(team => {
          setTeam(team);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [id, pok.teams, notifications, refresh]);

  const handleAfterEdit = () => {
    setRefresh(!refresh);
  };

  return <TeamEditorTabbed team={team} handleAfterEdit={handleAfterEdit} />;
};
