/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';

/**
 * 
 * @export
 * @interface GetSpecialInvoicingProjectDto
 */
export interface GetSpecialInvoicingProjectDto {
    /**
     * 
     * @type {string}
     * @memberof GetSpecialInvoicingProjectDto
     */
    id: string;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetSpecialInvoicingProjectDto
     */
    project: GetProjectDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSpecialInvoicingProjectDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetSpecialInvoicingProjectDto interface.
 */
export function instanceOfGetSpecialInvoicingProjectDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetSpecialInvoicingProjectDtoFromJSON(json: any): GetSpecialInvoicingProjectDto {
    return GetSpecialInvoicingProjectDtoFromJSONTyped(json, false);
}

export function GetSpecialInvoicingProjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSpecialInvoicingProjectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'project': GetProjectDtoFromJSON(json['project']),
        'active': json['active'],
    };
}

export function GetSpecialInvoicingProjectDtoToJSON(value?: GetSpecialInvoicingProjectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project': GetProjectDtoToJSON(value.project),
        'active': value.active,
    };
}

