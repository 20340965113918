import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { AllApplicationTab } from '../../components/Applications/AllApplicationTab';
import { PendingApplicationTab } from '../../components/Applications/PendingApplicationTab';
import {
  GetApplicationDto,
  ResponseError,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  convert,
  validateAdmin,
} from '../../../common/pokCore/contexts/ApplicationContext';
import { ApplicationAdminEditor } from '../../components/Applications/ApplicationAdminEditor';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';

enum ApplicationsTabs {
  PENDING = 'Oczekujące',
  ALL = 'Wszystkie',
}

export const ApplicationsAdminView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [application, setApplication] = useState<GetApplicationDto>();

  const editApplication = async (
    applicationCU: UpdateAdminApplicationDto,
    id: string,
  ) => {
    const status = await validateAdmin(applicationCU);
    if (status.valid) {
      try {
        await pok.applications.updateByAdmin(id, applicationCU);
        setApplication(undefined);
        setShowEditor(false);
        setRefresh(!refresh);
        void sendEmail(id);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const sendEmail = async (id: string) => {
    pok.emails.sendApplicationExecutionEmail(id).catch(err => {
      notifications.caughtError(err);
    });
  };

  const onRowClick = async (id: string) => {
    const getApplication = await pok.applications.getById(id);
    setApplication(getApplication);
    setShowEditor(true);
  };

  const handleCloseEditor = () => {
    setShowEditor(false);
    setApplication(undefined);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Wnioski</Card.Title>
        <TabsHistory
          activeKey={ApplicationsTabs.PENDING}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={ApplicationsTabs.PENDING}
            title={ApplicationsTabs.PENDING}
            className="m-3"
          >
            <PendingApplicationTab refresh={refresh} onRowClick={onRowClick} />
          </Tab>
          <Tab
            eventKey={ApplicationsTabs.ALL}
            title={ApplicationsTabs.ALL}
            className="m-3"
          >
            <AllApplicationTab refresh={refresh} onRowClick={onRowClick} />
          </Tab>
        </TabsHistory>
        {application && (
          <ApplicationAdminEditor
            id={application?.id}
            authorName={application.author.name}
            application={convert(application)}
            createdDate={application?.createdDate}
            show={showEditor}
            onCompleted={editApplication}
            handleClose={handleCloseEditor}
          />
        )}
      </Card.Body>
    </Card>
  );
};
