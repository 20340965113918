import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetApplicationUnblockDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  ApplicationUnblockStatusEnumText,
  ApplicationUnblockTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';
import { buildProjectLabel } from '../../../utils/buildProjectLabel';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationUnblockSearchProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  isNavView?: boolean;
  showFilter?: boolean;
  emptyGridMessage?: string;
}

export const applicationUnblockForGrid = (
  applicationUnblock: GetApplicationUnblockDto,
) => {
  return {
    key: applicationUnblock.id,
    values: {
      'applicationUnblock.systemNumber': applicationUnblock.systemNumber,
      'applicationUnblock.type':
        ApplicationUnblockTypeEnumText[applicationUnblock.type],
      'applicationUnblock.description': applicationUnblock.description,
      'project.name': buildProjectLabel(
        applicationUnblock.estimateByMonth.project,
      ),
      'profile.name': applicationUnblock.estimateByMonth.profile.name,
      'estimateByMonth.date': momencik(
        applicationUnblock.estimateByMonth.date,
        'YYYY-MM',
      ),
      'applicationUnblock.status':
        ApplicationUnblockStatusEnumText[applicationUnblock.status],
      'applicationUnblock.rejectedNote': applicationUnblock.rejectedNote,
      'creator.name': applicationUnblock.creator.name,
    },
    isInactive: !applicationUnblock.active,
  };
};

export const ApplicationUnblockSearch: React.FC<
  ApplicationUnblockSearchProps
> = props => {
  const { t, tk } = useTranslation('menu');

  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'applicationUnblock.systemNumber' },
    { header: 'Typ', property: 'applicationUnblock.type' },
    ...(props.isNavView
      ? [
          { header: t(tk.menu.project), property: 'project.name' },
          { header: 'Miesiąc', property: 'estimateByMonth.date' },
          { header: 'Profil', property: 'profile.name' },
        ]
      : []),
    { header: 'Powód', property: 'applicationUnblock.description' },
    { header: 'Status', property: 'applicationUnblock.status' },
    {
      header: 'Uzasadnienie odrzucenia',
      property: 'applicationUnblock.rejectedNote',
    },
    { header: 'Autor', property: 'creator.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      refresh={props.refresh}
      initialOrderColumn="applicationUnblock.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={
        props.emptyGridMessage || 'Brak wniosków o odblokowanie.'
      }
      onRowClick={props.onRowClick}
      withHistory={true}
      showFilter={props.showFilter}
    />
  );
};
