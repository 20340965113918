import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import {
  GetOrderExecutionDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { OrderExecutionStatusEnumText } from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';
import mathUtils from '../../../utils/mathUtils';
import numberFormatter from '../../../common/numberFormatter';

interface OrderExecutionSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  isProjectView?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  emptyGridMessage?: string;
  refresh?: boolean;
  withAmount?: boolean;
}

export const orderExecutionForGrid = (
  orderExecution: GetOrderExecutionDto,
  currentProjectId?: string,
) => {
  const buildProjectLabel = (project?: GetProjectDto) => {
    if (project) {
      return `${project.name} (${project.number})`;
    }

    return '-';
  };

  return {
    key: orderExecution.id,
    values: {
      'orderExecution.systemNumber': orderExecution.systemNumber,
      'employee.name': orderExecution?.orderExecutionEmployees
        ?.filter(({ active }) => active)
        .map(({ employee }) => employee?.name)
        .join(', '),
      'orderExecution.isResultProject':
        orderExecution.resultProject?.id === currentProjectId ? (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Realizacja zamówienia przychodząca</Tooltip>}
          >
            <Icon.ArrowLeftSquareFill className="icon-red" />
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Realizacja zamówienia wychodząca</Tooltip>}
          >
            <Icon.ArrowRightSquareFill className="icon-green" />
          </OverlayTrigger>
        ),
      result_project_label: buildProjectLabel(orderExecution.resultProject),
      project_label: buildProjectLabel(orderExecution.project),
      'orderExecution.rejectedNote': orderExecution.rejectedNote || '-',
      'orderExecution.status':
        OrderExecutionStatusEnumText[
          orderExecution.status as keyof typeof OrderExecutionStatusEnumText
        ],
      'creator.name': orderExecution.creator.name,
      'orderExecution.creationTs': momencik(
        orderExecution.creationTs,
        'YYYY-MM-DD',
      ),
      'orderExecution.date': momencik(orderExecution.date, 'YYYY-MM'),
      'team.name': orderExecution.project.projectTeams?.map(
        ({ team }) => `${team.name}`,
      ),
      'budget.name': orderExecution?.project?.purchaser?.budget?.name,
      amount: numberFormatter(
        orderExecution.orderExecutionPositions
          ?.map(inv => mathUtils.Big(inv.amount))
          .reduce(
            (a, c) =>
              (c = mathUtils.Big(mathUtils.add(c.toString(), a.toString()))),
          )
          .toString() || 0,
        true,
      ),
    },
    isInactive: !orderExecution.active,
  };
};

export const OrderExecutionSearch: React.FC<
  OrderExecutionSearchProps
> = props => {
  const { t, tk } = useTranslation('menu');
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'orderExecution.systemNumber' },
    { header: t(tk.menu.project), property: 'project_label' },
    { header: 'Budżet', property: 'budget.name' },
    ...(props.isProjectView
      ? [
          {
            header: 'Zespół realizujący',
            property: 'team.name',
            noSorting: true,
          },
        ]
      : []),
    { header: t(tk.menu.internalProject), property: 'result_project_label' },
    { header: 'Odbiorcy', property: 'employee.name' },
    { header: 'Autor', property: 'creator.name' },
    { header: 'Data utworzenia', property: 'orderExecution.creationTs' },
    { header: 'Miesiąc', property: 'orderExecution.date' },
    ...(props.withAmount
      ? [
          {
            header: 'Kwota',
            property: 'amount',
            noSorting: true,
          },
        ]
      : []),
    { header: 'Status', property: 'orderExecution.status', noFilter: true },
    {
      header: 'Uzasadnienie odrzucenia',
      property: 'orderExecution.rejectedNote',
    },
    ...(props.isProjectView
      ? [
          {
            header: '',
            property: 'orderExecution.isResultProject',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="orderExecution.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={
        props.emptyGridMessage || 'Brak realizacji zamówień w systemie.'
      }
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
