import React, { Dispatch, SetStateAction, useState } from 'react';

import { CreateUpdateRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';

interface RoleEditorProps {
  id?: string;
  role: CreateUpdateRoleDto;
  handleClose?: () => void;
  handleDelete?: () => void;
  onCompleted: (
    role: CreateUpdateRoleDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  setShowSaveErrors: Dispatch<SetStateAction<boolean>>;
  setSaveErrors: Dispatch<SetStateAction<string[]>>;
}

export const RoleEditorControls: React.FC<RoleEditorProps> = props => {
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();

  const createRole = async () => {
    setIsLoadingSave(true);
    props
      .onCompleted(props.role, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          props.setSaveErrors(saveStatus.errors);
          props.setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingSave(false));
  };

  const deleteRole = async () => {
    if (props.id) {
      setIsLoadingDelete(true);
      await notifications.onPromise(
        pok.roles.delete(props.id),
        props.handleDelete,
      );
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      {props.id && (
        <LastEditorInfo id={props.id} method={pok.roles.getLastEditor} />
      )}
      <Button
        variant="outline-secondary"
        onClick={props.handleClose}
        disabled={isLoadingDelete || isLoadingSave}
      >
        Zamknij
      </Button>
      {props.id && (
        <ConfirmationButton
          className="mx-1"
          confirmation="Czy na pewno usunąć rolę?"
          variant="outline-danger"
          onOK={deleteRole}
          isLoading={isLoadingDelete}
        >
          Usuń
        </ConfirmationButton>
      )}
      <Button variant="primary" onClick={createRole} isLoading={isLoadingSave}>
        Zapisz
      </Button>
    </>
  );
};
