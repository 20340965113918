import React, { useEffect, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetCompanyWithoutLogoDto } from '../../../common/pokCore/autogenerated/pokApiClient/models/GetCompanyWithoutLogoDto';
import { GetClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';

import { Selector, Option } from './Selector';

export interface ClientSelectorProps {
  projectId?: string;
  readOnly?: boolean;
  loadData?: () => Promise<GetCompanyWithoutLogoDto[]>;
  value?: GetClientDto;
  valueId?: string;
  buildName?: (client: GetClientDto) => string;
  className?: string;
  onChange?: (option: Option) => void;
  setLanguage?: boolean;
  clearable?: boolean;
}

export const ClientSelector: React.FC<ClientSelectorProps> = (
  props: ClientSelectorProps,
) => {
  const pok = usePokCore();
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (props.value) {
      setName(props.value?.name);
      return;
    }
    if (!props.valueId) {
      setName('');
      return;
    }
    void pok.clients.getById(props.valueId).then(client => {
      setName(
        props.buildName
          ? props.buildName(client)
          : `${client.name} (${client.nip})`,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pok.clients, props.value, props.valueId]);

  return (
    <Selector
      className={props.className}
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={text =>
        props.projectId
          ? pok.positions.getClientsOptionsByProjectId(props.projectId, text)
          : pok.clients.getOptionsByNipOrName(text)
      }
      value={name}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
