import React, { useEffect, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

export default (
  props: React.PropsWithChildren<{
    show: boolean;
    modal?: boolean;
    heading?: string;
    variant?: string;
    onHide?: () => void;
    errors: string[];
    className?: string;
    buttonText?: string;
  }>,
) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        (buttonRef.current as HTMLButtonElement)?.click();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  let i = 0;
  const message = props.errors.map(error => (
    <p key={'error' + ++i} className="m-0">
      {error === 'Forbidden resource'
        ? 'Brak wymaganych uprawnień'
        : error || 'Nieznany błąd!'}
    </p>
  ));

  return (
    <>
      {props.show &&
        props.errors.length > 0 &&
        (props.modal ? (
          <Modal
            show={props.show}
            centered={true}
            backdrop="static"
            backdropClassName="modal-on-modal-backdrop"
            className="modal-on-modal"
          >
            <Modal.Header className="p-3 h2 justify-content-center">
              <Icon.ExclamationTriangleFill
                className={'text-center text-' + (props.variant || 'danger')}
              />
            </Modal.Header>
            <Modal.Body>
              <h3>{props.heading || 'Nie można wykonać tej operacji'}</h3>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={props.onHide}
                variant={props.variant || 'danger'}
                ref={buttonRef}
              >
                {props.buttonText || 'Zamknij'}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Alert
            variant={props.variant || 'danger'}
            className={props.className}
          >
            {message}
          </Alert>
        ))}
    </>
  );
};
