/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBlockadeMonthTeamDto } from './GetBlockadeMonthTeamDto';
import {
    GetBlockadeMonthTeamDtoFromJSON,
    GetBlockadeMonthTeamDtoFromJSONTyped,
    GetBlockadeMonthTeamDtoToJSON,
} from './GetBlockadeMonthTeamDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';

/**
 * 
 * @export
 * @interface GetBlockadeMonthDto
 */
export interface GetBlockadeMonthDto {
    /**
     * 
     * @type {string}
     * @memberof GetBlockadeMonthDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetBlockadeMonthDto
     */
    date: Date;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetBlockadeMonthDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetBlockadeMonthDto
     */
    isSalesBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBlockadeMonthDto
     */
    isPurchaseBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBlockadeMonthDto
     */
    active: boolean;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetBlockadeMonthDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Array<GetBlockadeMonthTeamDto>}
     * @memberof GetBlockadeMonthDto
     */
    blockadeMonthTeams?: Array<GetBlockadeMonthTeamDto>;
}

/**
 * Check if a given object implements the GetBlockadeMonthDto interface.
 */
export function instanceOfGetBlockadeMonthDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "isSalesBlocked" in value;
    isInstance = isInstance && "isPurchaseBlocked" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "creator" in value;

    return isInstance;
}

export function GetBlockadeMonthDtoFromJSON(json: any): GetBlockadeMonthDto {
    return GetBlockadeMonthDtoFromJSONTyped(json, false);
}

export function GetBlockadeMonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBlockadeMonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'company': GetCompanyDtoFromJSON(json['company']),
        'isSalesBlocked': json['isSalesBlocked'],
        'isPurchaseBlocked': json['isPurchaseBlocked'],
        'active': json['active'],
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'blockadeMonthTeams': !exists(json, 'blockadeMonthTeams') ? undefined : ((json['blockadeMonthTeams'] as Array<any>).map(GetBlockadeMonthTeamDtoFromJSON)),
    };
}

export function GetBlockadeMonthDtoToJSON(value?: GetBlockadeMonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString()),
        'company': GetCompanyDtoToJSON(value.company),
        'isSalesBlocked': value.isSalesBlocked,
        'isPurchaseBlocked': value.isPurchaseBlocked,
        'active': value.active,
        'creator': GetEmployeeDtoToJSON(value.creator),
        'blockadeMonthTeams': value.blockadeMonthTeams === undefined ? undefined : ((value.blockadeMonthTeams as Array<any>).map(GetBlockadeMonthTeamDtoToJSON)),
    };
}

