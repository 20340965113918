import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import * as _ from 'lodash';

import Layout from '../components/Layout';
import {
  routesInMenu,
  routesOutsideMenu,
  MenuItem,
} from '../../common/navigation';
import ErrorBoundary from '../../common/components/ErrorBoundary/ErrorBoundary';
import { useAuth } from '../../common/hooks/useAuth';
import { responseErrors } from '../../common/pokCore/validation/responseErrors';
import AlertModal from '../components/AlertModal';
import { usePokCore } from '../../common/hooks/usePokCore';
import { Waiting } from '../../common/components/Waiting/Waiting';
import { ResponseError } from '../../common/pokCore/autogenerated/pokApiClient';
import { SocketsProvider } from '../../common/sockets';
import { MemosProvider } from '../../common/memos';

import { SignInView } from './SignInView';

const allMenuItemRoutes = (menuitem: MenuItem) => {
  const submenu = menuitem.submenu
    ?.filter(o => !o.hr)
    ?.map(subitem => (
      <Route key={subitem.path} path={subitem.path} element={subitem.element} />
    ));
  const item = (
    <Route
      key={menuitem.path}
      path={menuitem.path}
      element={menuitem.element}
    />
  );

  if (!submenu) {
    return item;
  }

  return [...submenu, item];
};

export const MyRoutes: React.FC = () => {
  const auth = useAuth();
  const pok = usePokCore();
  const routing = [
    ...routesInMenu().map(route => allMenuItemRoutes(route)),
    ...routesOutsideMenu.map(route => allMenuItemRoutes(route)),
  ];
  const [showProblem, setShowProblem] = useState(false);
  const [problem, setProblem] = useState<string>();
  const [authorized, setAuthorized] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setProblem('');
    setShowProblem(false);

    const userId = localStorage.getItem('user');
    if (!auth.currentUser) {
      if (userId) {
        pok.employees
          .getCurrent()
          .then(employee => {
            pok.employeesRoles
              .getWithTheSameUserByUserId(employee.id)
              .then(employeeRoles => {
                const rolesWithCompanies = employeeRoles
                  .map(empRole => {
                    return {
                      companyId: empRole.company?.id,
                      roles: empRole.role.permissions,
                    };
                  })
                  .flat();

                pok.employeesRoles
                  .findTeamsWithCompanyByUser(employee.id)
                  .then(teams => {
                    const teamsWithCompanies = teams;
                    auth.setCurrentUser(
                      employee,
                      _.uniqWith(rolesWithCompanies, _.isEqual),
                      _.uniqWith(teamsWithCompanies, _.isEqual),
                    );
                  })
                  .catch(() => {
                    setAuthorized(false);
                  });
              })
              .catch(() => {
                setAuthorized(false);
              });
          })
          .catch(() => {
            setAuthorized(false);
          });
      } else {
        setAuthorized(false);
      }
    }

    if (!userId && auth.currentUser) {
      pok.employees
        .getCurrent()
        .then(employee => {
          localStorage.setItem('user', employee.id);
          setAuthorized(true);
        })
        .catch(async response => {
          const errors = await responseErrors(response as ResponseError);
          for (const error of errors) {
            setProblem(error);
          }
          setShowProblem(true);
          setAuthorized(false);
        });
    }
    if (userId && auth.currentUser) {
      setAuthorized(true);
    }
  }, [auth, pok.employeesRoles, pok.employees]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        {authorized === undefined && (
          <Routes>
            <Route path="*" element={React.createElement(Waiting)} />
          </Routes>
        )}
        {authorized && auth.currentUser && (
          <SocketsProvider>
            <MemosProvider>
              <Layout>
                <Routes>{routing}</Routes>
              </Layout>
            </MemosProvider>
          </SocketsProvider>
        )}
        {authorized !== undefined && !auth.currentUser && (
          <Routes>
            <Route path="*" element={React.createElement(SignInView)} />
          </Routes>
        )}
        <AlertModal variant="danger" show={showProblem}>
          {problem}
        </AlertModal>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
