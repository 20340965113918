import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { GetExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { ExcelConfigEditor } from '../../components/ExcelConfig/ExcelConfigEditor';

export const ExcelConfigView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { id } = useParams<string>();
  const [config, setConfig] = useState<GetExcelConfigDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (id) {
      pok.projectExcelConfigs
        .getById(id)
        .then(setConfig)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [id, notifications, pok.projectExcelConfigs, refresh]);

  return (
    <>{config && <ExcelConfigEditor config={config} refresh={onRefresh} />}</>
  );
};
