import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetBudgetDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { BudgetEditorTabbed } from '../../components/Budgets/BudgetEditorTabbed';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { forBudgetView } from '../../../common/pokCore/contexts/BudgetContext';
import { forAssociatedDocuments } from '../../../common/pokCore/contexts/AttachmentContext';

export const BudgetView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { id } = useParams<string>();
  const [budget, setBudget] = useState<GetBudgetDto>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [attachments, setAttachments] = useState(
    forAssociatedDocuments(pok, notifications, []),
  );

  const refreshFunc = useCallback(() => {
    id &&
      forBudgetView(pok, notifications, id, refreshFunc)
        .then(model => {
          setAttachments(model.attachments);
        })
        .catch(() =>
          notifications.badLink(
            'Błąd przy pobieraniu załączników.',
            '/kontrahenci/budzety',
          ),
        );
  }, [id, notifications, pok]);

  useEffect(() => {
    if (id) {
      pok.budgets
        .getById(id)
        .then(budget => {
          setBudget(budget);
          refreshFunc();
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [id, pok.budgets, notifications, refresh, refreshFunc]);

  const handleAfterEdit = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      {budget && (
        <BudgetEditorTabbed
          budget={budget}
          attachments={attachments}
          handleAfterEdit={handleAfterEdit}
        />
      )}
    </>
  );
};
