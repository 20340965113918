import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsFinanceView: React.FC = () => {
  const nav = useNavigation();

  return (
    <Card>
      <TabsHistory activeKey="finance" id="reports" className="mb-3">
        <Tab eventKey="finance" title="Zestawienia">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="Raport do budżetu"
              text="Kampanie wg miesięcy"
              footer="Pozycje mediaplanu"
              onClick={nav.reportProjectByMonthDataRaw}
            />
            <ReportCardMenu
              title="FVS"
              text="Faktury sprzedaży"
              footer="FVS"
              onClick={nav.reportFVSByFilterDataRaw}
            />
            <ReportCardMenu
              title="FVZ"
              text="Faktury zakupu"
              footer="FVZ"
              onClick={nav.reportFVZByFilterDataRaw}
            />
          </Row>
        </Tab>
        <Tab eventKey="controlling" title="Kontroling">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="FVZ - przypisanie do pozycji mediaplanów"
              text="Faktury zakupu w powiązaniu z pozycjami mediaplanów"
              footer="FVZ, pozycje mediaplanu"
              onClick={nav.reportFVZBWithEstimateItemsByFilterDataRaw}
            />
            <ReportCardMenu
              title="FVS - raport dla kontrolingu"
              text="Faktury sprzedaży w podziale na miesiąc dowiązania"
              footer="FVS, pozycje mediaplanu"
              onClick={nav.reportFVSControllingByFilterDataRaw}
            />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
