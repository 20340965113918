import { useEffect, useState } from 'react';
import { isEqual, orderBy } from 'lodash';

import {
  CreateUpdateInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetProjectDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import {
  formatOriginalSalesInvoiceEstimateItem,
  formatSalesInvoiceEstimateItem,
} from '../../../common/pokCore/contexts/SalesInvoicesContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useLocation } from '../../../common/hooks/useLocation';
import { TabLocationState } from '../../../app/components/TabsHistory';

import { getFinancialAccountName } from './SalesInvoiceMediaPlanPositionsGrid';

export interface UseSalesInvoiceMediaplanPositionsReturn {
  availableEstimateItems?: GetEstimateItemDto[];
  salesInvoiceEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  initialSalesInvoiceEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  isLoading: boolean;
  propertyChange: (
    obj: Partial<CreateUpdateInvoiceEstimateItemDto>,
    id: string,
  ) => void;
}

function useSalesInvoiceMediaplanPositions(props: {
  readOnly?: boolean;
  salesInvoice?: GetSalesInvoiceDto;
  projects: GetProjectDto[];
  id?: string;
  handleRefresh: () => void;
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void;
}): UseSalesInvoiceMediaplanPositionsReturn {
  const location = useLocation<TabLocationState>();
  const isCurrentTabActive =
    location.state?.tabKey === SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS;

  const [availableEstimateItems, setAvailableEstimateItems] =
    useState<GetEstimateItemDto[]>();
  const [salesInvoiceEstimateItems, setSalesInvoiceEstimateItems] =
    useState<CreateUpdateInvoiceEstimateItemDto[]>();
  const [
    initialSalesInvoiceEstimateItems,
    setInitialSalesInvoiceEstimateItems,
  ] = useState<CreateUpdateInvoiceEstimateItemDto[]>();
  const [
    salesInvoiceOriginalEstimateItems,
    setSalesInvoiceOriginalEstimateItems,
  ] = useState<CreateUpdateInvoiceEstimateItemDto[]>();
  const [isLoading, setIsLoading] = useState(true);

  const pok = usePokCore();
  const notifications = useNotifications();

  const checkDidDataChanged = (
    updatedPositions?: CreateUpdateInvoiceEstimateItemDto[],
  ) => {
    const dataChanged = !isEqual(
      updatedPositions,
      initialSalesInvoiceEstimateItems,
    );

    props.setNotSavedTab(
      dataChanged ? SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS : undefined,
    );
  };

  const propertyChange = (
    obj: Partial<CreateUpdateInvoiceEstimateItemDto>,
    id: string,
  ) => {
    const updatedItems = salesInvoiceEstimateItems?.map(item =>
      item.estimateItemId === id ? { ...item, ...obj } : item,
    );

    setSalesInvoiceEstimateItems(updatedItems);
    checkDidDataChanged(updatedItems);
  };

  useEffect(() => {
    if (props.projects.length) {
      pok.estimateItems
        .findReadyToInvoiceByProjects(
          props.projects.map(project => project.id),
          props.id,
        )
        .then(data => {
          const formattedSalesInvoiceEstimateItems = data.map(estimateItem =>
            formatOriginalSalesInvoiceEstimateItem(
              estimateItem,
              props.salesInvoice,
            ),
          );
          const estimateItems = data.map(estimateItem =>
            formatSalesInvoiceEstimateItem(
              estimateItem,
              props.salesInvoice,
              formattedSalesInvoiceEstimateItems,
            ),
          );

          setInitialSalesInvoiceEstimateItems(estimateItems);
          setSalesInvoiceEstimateItems(estimateItems);
          setSalesInvoiceOriginalEstimateItems(
            formattedSalesInvoiceEstimateItems,
          );
          setAvailableEstimateItems(
            orderBy(
              data,
              [
                item => item.estimateByMonth?.project?.number,
                item => item.estimateByMonth?.date,
                item => getFinancialAccountName(item),
                item => item.position?.name,
                item => item.id,
              ],
              ['asc', 'asc', 'asc', 'asc', 'asc'],
            ),
          );
          setIsLoading(false);
        })
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [
    notifications,
    pok.estimateItems,
    pok.salesInvoices,
    props.id,
    props.projects,
    props.salesInvoice,
  ]);

  useEffect(() => {
    if (
      isCurrentTabActive &&
      props.salesInvoice &&
      !props.salesInvoice.invoiceEstimateItems?.length &&
      availableEstimateItems &&
      availableEstimateItems.length &&
      props.salesInvoice.active
    ) {
      props.setNotSavedTab(SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentTabActive, props.salesInvoice?.invoiceEstimateItems?.length]);

  return {
    availableEstimateItems,
    salesInvoiceEstimateItems,
    initialSalesInvoiceEstimateItems,
    salesInvoiceOriginalEstimateItems,
    isLoading,
    propertyChange,
  };
}

export default useSalesInvoiceMediaplanPositions;
