import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import Button from '../../../common/components/Button/Button';
import { newProject } from '../../../common/pokCore/contexts/ProjectContext';

import { ProjectForm } from './ProjectForm';

interface ProjectEditorProps {
  id?: string;
  project: CreateUpdateProjectWithTeamDto;
  responsibleOwnerName?: string;
  handleClose?: () => void;
  show?: boolean;
  readOnly?: boolean;
  isCreatedFromOrder?: boolean;
  budgetId?: string;
  onCompleted: (
    project: CreateUpdateProjectWithTeamDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
    delayedProject?: boolean;
  }>;
}

export const ProjectEditor: React.FC<ProjectEditorProps> = props => {
  const [project, setProject] = useState(props.project);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('menu');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setProject(props.project);
    }
  }, [props.project, props.show]);

  const createProject = async () => {
    setIsLoading(true);
    props
      .onCompleted(project, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
        setProject(newProject(pok.getCompanyId()));
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateProjectWithTeamDto>) => {
    if (
      obj.teamIds &&
      (obj.teamIds.length === 0 || props.id === undefined) &&
      !props.isCreatedFromOrder
    ) {
      setProject({
        ...project,
        ...obj,
        brandId: undefined!,
        purchaserId: undefined!,
      });
    } else if (obj.purchaserId && !props.isCreatedFromOrder) {
      setProject({
        ...project,
        ...obj,
        brandId: undefined!,
        parentId: undefined!,
      });
    } else {
      setProject({ ...project, ...obj });
    }
  };

  const handleClose = () => {
    if (props.handleClose) {
      setProject(newProject(pok.getCompanyId()));
      props.handleClose();
    }
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${t(tk.menu.editProject)} ${props.project.number}, nr sys. ${
                  props.project.systemNumber
                }`
              : t(tk.menu.addNewProject)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectForm
            project={project}
            responsibleOwnerName={props.responsibleOwnerName}
            propertyChange={propertyChange}
            readOnly={props.readOnly}
            isCreatedFromOrder={props.isCreatedFromOrder}
            budgetId={props.budgetId}
            show={props.show}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
          {props.project.cancelVerification === true && (
            <ValidationAlert
              show={true}
              errors={[
                `Dla ${t(tk.menu.genitiveProject)} został złożony wniosek 
                 o anulowanie ${t(tk.menu.genitiveProject)}.`,
              ]}
              className="m-3"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo id={props.id} method={pok.projects.getLastEditor} />
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Zamknij
          </Button>
          <Button
            variant="primary"
            onClick={createProject}
            isLoading={isLoading}
          >
            {project.delayVerification ? 'Wyślij wniosek' : 'Zapisz'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
