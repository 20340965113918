import saveAs from 'file-saver';

import momencik from '../../../common/momencik';
import { PokCoreContextType } from '../../../common/pokCore';
import { NotificationsProviderType } from '../../../common/notifications';

export const downloadZIP = async (
  name: string,
  number: string,
  purchaseInvoicesIds: string[],
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
) => {
  pok.purchaseInvoices
    .getZipByPurchaseIds(number, purchaseInvoicesIds)
    .then(async zip => {
      const fileName = `${number}_(${name})_${momencik(
        new Date(),
        'YYYY-MM-DD_HH-mm-ss',
      )}.zip`.replace(/[ /]/g, '_');

      if (zip) {
        const blob = new Blob([Buffer.from(zip as Buffer)], {
          type: 'application/zip',
        });
        saveAs(blob, fileName);
      }
    })
    .catch(errorResponse => {
      notifications.caughtError(errorResponse);
    });
};
