/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAttachmentEstimateItemDto } from './GetAttachmentEstimateItemDto';
import {
    GetAttachmentEstimateItemDtoFromJSON,
    GetAttachmentEstimateItemDtoFromJSONTyped,
    GetAttachmentEstimateItemDtoToJSON,
} from './GetAttachmentEstimateItemDto';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';

/**
 * 
 * @export
 * @interface GetAttachmentDto
 */
export interface GetAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetAttachmentDto
     */
    dictionaryType: GetDictionaryDto;
    /**
     * 
     * @type {Date}
     * @memberof GetAttachmentDto
     */
    creationTs?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    enObjectType: GetAttachmentDtoEnObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    objectId: string;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetAttachmentDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetAttachmentDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetAttachmentDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {Array<GetAttachmentEstimateItemDto>}
     * @memberof GetAttachmentDto
     */
    estimateItems?: Array<GetAttachmentEstimateItemDto>;
}


/**
 * @export
 */
export const GetAttachmentDtoEnObjectTypeEnum = {
    Budget: 'Budget',
    Project: 'Project',
    PurchaseInvoice: 'PurchaseInvoice',
    EstimateItemCommentMediaplanner: 'EstimateItemCommentMediaplanner',
    EstimateItemCommentFinance: 'EstimateItemCommentFinance'
} as const;
export type GetAttachmentDtoEnObjectTypeEnum = typeof GetAttachmentDtoEnObjectTypeEnum[keyof typeof GetAttachmentDtoEnObjectTypeEnum];


/**
 * Check if a given object implements the GetAttachmentDto interface.
 */
export function instanceOfGetAttachmentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "dictionaryType" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "enObjectType" in value;
    isInstance = isInstance && "objectId" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "client" in value;

    return isInstance;
}

export function GetAttachmentDtoFromJSON(json: any): GetAttachmentDto {
    return GetAttachmentDtoFromJSONTyped(json, false);
}

export function GetAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dictionaryType': GetDictionaryDtoFromJSON(json['dictionaryType']),
        'creationTs': !exists(json, 'creationTs') ? undefined : (json['creationTs'] === null ? null : new Date(json['creationTs'])),
        'description': json['description'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'enObjectType': json['enObjectType'],
        'objectId': json['objectId'],
        'company': GetCompanyDtoFromJSON(json['company']),
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'client': GetClientDtoFromJSON(json['client']),
        'estimateItems': !exists(json, 'estimateItems') ? undefined : ((json['estimateItems'] as Array<any>).map(GetAttachmentEstimateItemDtoFromJSON)),
    };
}

export function GetAttachmentDtoToJSON(value?: GetAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dictionaryType': GetDictionaryDtoToJSON(value.dictionaryType),
        'creationTs': value.creationTs === undefined ? undefined : (value.creationTs === null ? null : value.creationTs.toISOString()),
        'description': value.description,
        'note': value.note,
        'name': value.name,
        'enObjectType': value.enObjectType,
        'objectId': value.objectId,
        'company': GetCompanyDtoToJSON(value.company),
        'creator': GetEmployeeDtoToJSON(value.creator),
        'client': GetClientDtoToJSON(value.client),
        'estimateItems': value.estimateItems === undefined ? undefined : ((value.estimateItems as Array<any>).map(GetAttachmentEstimateItemDtoToJSON)),
    };
}

