/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSalesInvoicePositionDto
 */
export interface CreateUpdateSalesInvoicePositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    vat: CreateUpdateSalesInvoicePositionDtoVatEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    salesInvoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    financialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    profileCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionDto
     */
    projectId: string;
}


/**
 * @export
 */
export const CreateUpdateSalesInvoicePositionDtoVatEnum = {
    _23: '23%',
    _8: '8%',
    _5: '5%',
    _0: '0%',
    Zw: 'zw.',
    Np: 'np.'
} as const;
export type CreateUpdateSalesInvoicePositionDtoVatEnum = typeof CreateUpdateSalesInvoicePositionDtoVatEnum[keyof typeof CreateUpdateSalesInvoicePositionDtoVatEnum];


/**
 * Check if a given object implements the CreateUpdateSalesInvoicePositionDto interface.
 */
export function instanceOfCreateUpdateSalesInvoicePositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "vat" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "salesInvoiceId" in value;
    isInstance = isInstance && "financialAccount" in value;
    isInstance = isInstance && "profileCategoryId" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function CreateUpdateSalesInvoicePositionDtoFromJSON(json: any): CreateUpdateSalesInvoicePositionDto {
    return CreateUpdateSalesInvoicePositionDtoFromJSONTyped(json, false);
}

export function CreateUpdateSalesInvoicePositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSalesInvoicePositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'amount': json['amount'],
        'vat': json['vat'],
        'order': json['order'],
        'salesInvoiceId': json['salesInvoiceId'],
        'financialAccount': json['financialAccount'],
        'profileCategoryId': json['profileCategoryId'],
        'projectId': json['projectId'],
    };
}

export function CreateUpdateSalesInvoicePositionDtoToJSON(value?: CreateUpdateSalesInvoicePositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'amount': value.amount,
        'vat': value.vat,
        'order': value.order,
        'salesInvoiceId': value.salesInvoiceId,
        'financialAccount': value.financialAccount,
        'profileCategoryId': value.profileCategoryId,
        'projectId': value.projectId,
    };
}

