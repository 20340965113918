import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SalesInvoicePositionsControlsProps {
  handleResetPositions: () => void;
  selected: number[];
  handleMergePositions: (keys: number[]) => void;
  handleClonePosition: (key: number) => void;
  handleMergePositionsByProject: (key: number[]) => void;
  handleSave: () => void;
  isMergeDisabled: boolean;
  readOnly?: boolean;
  isLoading: boolean;
}

export const SalesInvoicePositionsControls: React.FC<
  SalesInvoicePositionsControlsProps
> = ({
  handleResetPositions,
  selected,
  handleMergePositions,
  handleClonePosition,
  handleMergePositionsByProject,
  handleSave,
  isMergeDisabled,
  readOnly,
  isLoading,
}) => {
  const { t, tk } = useTranslation('menu');

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-1">
        {selected.length > 1 && (
          <>
            <OverlayTrigger
              trigger={isMergeDisabled ? ['hover', 'focus'] : []}
              overlay={
                <Tooltip>
                  Scalane pozycje muszą być przypisane do tego samego konta
                  księgowego oraz należeć do {t(tk.menu.sameProject)}.
                </Tooltip>
              }
            >
              <div>
                <Button
                  onClick={() => handleMergePositions(selected)}
                  disabled={isMergeDisabled || readOnly}
                >
                  Scal pozycje
                </Button>
              </div>
            </OverlayTrigger>
          </>
        )}
        <Button
          onClick={() => handleMergePositionsByProject(selected)}
          disabled={readOnly}
        >
          Scal {selected.length ? 'wybrane ' : ''}pozycje według{' '}
          {t(tk.menu.genitiveProject)}
        </Button>
        {selected.length === 1 && (
          <Button
            onClick={() => handleClonePosition(selected[0])}
            disabled={readOnly}
          >
            Klonuj pozycję
          </Button>
        )}
        <ConfirmationButton
          variant="outline-danger"
          onOK={handleResetPositions}
          confirmation="Czy na pewno chcesz zresetować pozycje?"
          disabled={readOnly}
        >
          Resetuj wszystkie pozycje
        </ConfirmationButton>
      </div>
      <Button onClick={handleSave} disabled={readOnly} isLoading={isLoading}>
        Zapisz i przejdź dalej
      </Button>
    </div>
  );
};
