import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdatePurchaseInvoicePositionDto,
  CreateUpdatePurchaseInvoicePositionDtoVatEnum,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { validate } from '../../../common/pokCore/contexts/PurchaseInvoicePositionContext';
import FormRow from '../../../app/components/FormRow';
import { VatEnum } from '../../../common/pokCore/validation/schemas';
import CurrencyControl from '../../../app/components/CurrencyControl';
import { getVatAmountByVat } from '../../../utils/getVatAmountByVat';

interface PurchaseInvoicePositionEditorProps {
  id?: string;
  position: CreateUpdatePurchaseInvoicePositionDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    position: CreateUpdatePurchaseInvoicePositionDto,
    id?: string,
  ) => void;
}

const vatEnumKeys = Object.keys(VatEnum);
const vatEnumValues = Object.values(VatEnum);
const VAT_ENUM_OPTIONS = vatEnumKeys.map((value, i) => ({
  value,
  label: vatEnumValues[i],
}));

export const PurchaseInvoicePositionEditor: React.FC<
  PurchaseInvoicePositionEditorProps
> = props => {
  const [position, setPosition] = useState(props.position);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setPosition(props.position);
    }
  }, [props.position, props.show]);

  const addEditPosition = async () => {
    const status = await validate(position);
    if (status.valid) {
      props.onCompleted(position, props.id);
    } else {
      setSaveErrors(status.errors);
      setShowSaveErrors(true);
    }
  };

  const propertyChange = (
    obj: Partial<CreateUpdatePurchaseInvoicePositionDto>,
  ) => {
    let vatAmount = position.vatAmount;
    if ((obj.netAmount && position.vat) || (obj.vat && position.netAmount)) {
      const netAmount = parseFloat(obj.netAmount || position.netAmount);
      const vat = obj.vat || position.vat;
      vatAmount = getVatAmountByVat(netAmount, vat as VatEnum);
    }
    setPosition({
      ...position,
      vatAmount,
      ...obj,
    });
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id
            ? 'Edycja pozycji faktury zakupu'
            : 'Dodawanie nowej pozycji faktury zakupu'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-4 mb-3">
          <FormRow controlId="netAmount" label="Kwota netto">
            <CurrencyControl
              decimalScale={2}
              allowNegative
              value={position?.netAmount}
              suffix=" zł"
              onChange={value =>
                propertyChange({
                  netAmount: value || undefined,
                })
              }
            />
          </FormRow>
          <FormRow controlId="vat" label="VAT">
            <Select
              value={
                position?.vat
                  ? {
                      label: position.vat.toString(),
                      value: Object.keys(
                        CreateUpdatePurchaseInvoicePositionDtoVatEnum,
                      )[
                        Object.values(
                          CreateUpdatePurchaseInvoicePositionDtoVatEnum,
                        ).indexOf(position.vat)
                      ].toString(),
                    }
                  : null
              }
              options={VAT_ENUM_OPTIONS}
              defaultValue={VAT_ENUM_OPTIONS[0]}
              onChange={option =>
                propertyChange({
                  vat: option?.value
                    ? (VatEnum[
                        option.value as keyof typeof VatEnum
                      ] as unknown as CreateUpdatePurchaseInvoicePositionDtoVatEnum)
                    : undefined,
                })
              }
              placeholder="Wybierz..."
            />
          </FormRow>
          <FormRow controlId="vatAmount" label="Kwota VAT">
            <CurrencyControl
              decimalScale={2}
              allowNegative
              value={position?.vatAmount}
              suffix=" zł"
              onChange={value =>
                propertyChange({
                  vatAmount: value || undefined,
                })
              }
            />
          </FormRow>
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
        <div className="d-flex justify-content-end">
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button className="ms-2" variant="primary" onClick={addEditPosition}>
            Zapisz
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
