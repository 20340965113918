/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEstimateItemDto } from './GetEstimateItemDto';
import {
    GetEstimateItemDtoFromJSON,
    GetEstimateItemDtoFromJSONTyped,
    GetEstimateItemDtoToJSON,
} from './GetEstimateItemDto';

/**
 * 
 * @export
 * @interface GetExcelAndPokRowDto
 */
export interface GetExcelAndPokRowDto {
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    rowId: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelAndPokRowDto
     */
    emptyRow: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExcelAndPokRowDto
     */
    otherErrors: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    positionNotFoundError?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExcelAndPokRowDto
     */
    parameterErrors: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExcelAndPokRowDto
     */
    calculationErrors: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelProjectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    profileId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    positionName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    positionType: GetExcelAndPokRowDtoPositionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    positionId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPositionName?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetExcelAndPokRowDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetExcelAndPokRowDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    invoiceInstructionAttachment?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    duplicateMasterRowId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    rcSalesTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesCommissionAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesNetTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    rcPurchase?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    netCustomerAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelRcSalesTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesCommissionAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesNetTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelRcPurchase?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelNetCustomerAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    ownName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    actionGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    additionalDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    numberOfMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    copySize?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseFormInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    categoryInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseFormInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    categoryInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    labconChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    categoryLabcon?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    technologyDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    regionDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatPress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    emissionTypeRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    emissionTypeCinema?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    copySizeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseMethodValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseFormInternetValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    categoryInternetValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatInternetValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseFormInternetSTValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    categoryInternetSTValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatInternetSTValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    labconChannelValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    categoryLabconValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    technologyDictionaryValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    regionDictionaryValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatPressValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    formatRadioValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    emissionTypeRadioValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    emissionTypeCinemaValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    mediumValue?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    salesCommissionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    purchaseSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    serviceExternalWorkPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    serviceCreationPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    serviceChangeProductionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    serviceStrategyPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    serviceProductionBroadcastPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    serviceClientServicePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelSalesCommissionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelServiceExternalWorkPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelServiceCreationPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelServiceChangeProductionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelServiceStrategyPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelServiceProductionBroadcastPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelServiceClientServicePercent?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelAndPokRowDto
     */
    isUpSell?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelCopySize?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseFormInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelCategoryInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelFormatInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelPurchaseFormInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelCategoryInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelFormatInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelLabconChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelCategoryLabcon?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelTechnologyDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelRegionDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelFormatPress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelFormatRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelEmissionTypeRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelEmissionTypeCinema?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    excelMedium?: string;
    /**
     * 
     * @type {GetEstimateItemDto}
     * @memberof GetExcelAndPokRowDto
     */
    estimateItem?: GetEstimateItemDto;
    /**
     * 
     * @type {string}
     * @memberof GetExcelAndPokRowDto
     */
    comment?: string;
}


/**
 * @export
 */
export const GetExcelAndPokRowDtoPositionTypeEnum = {
    Standardowa: 'Standardowa',
    Prowizja: 'Prowizja',
    ProwizjaTransferowana: 'Prowizja-Transferowana'
} as const;
export type GetExcelAndPokRowDtoPositionTypeEnum = typeof GetExcelAndPokRowDtoPositionTypeEnum[keyof typeof GetExcelAndPokRowDtoPositionTypeEnum];


/**
 * Check if a given object implements the GetExcelAndPokRowDto interface.
 */
export function instanceOfGetExcelAndPokRowDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "emptyRow" in value;
    isInstance = isInstance && "otherErrors" in value;
    isInstance = isInstance && "parameterErrors" in value;
    isInstance = isInstance && "calculationErrors" in value;
    isInstance = isInstance && "positionType" in value;

    return isInstance;
}

export function GetExcelAndPokRowDtoFromJSON(json: any): GetExcelAndPokRowDto {
    return GetExcelAndPokRowDtoFromJSONTyped(json, false);
}

export function GetExcelAndPokRowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExcelAndPokRowDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowId': json['rowId'],
        'status': json['status'],
        'emptyRow': json['emptyRow'],
        'otherErrors': json['otherErrors'],
        'positionNotFoundError': !exists(json, 'positionNotFoundError') ? undefined : json['positionNotFoundError'],
        'parameterErrors': json['parameterErrors'],
        'calculationErrors': json['calculationErrors'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectNumber': !exists(json, 'projectNumber') ? undefined : json['projectNumber'],
        'excelProjectNumber': !exists(json, 'excelProjectNumber') ? undefined : json['excelProjectNumber'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'positionName': !exists(json, 'positionName') ? undefined : json['positionName'],
        'positionType': json['positionType'],
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'excelPositionName': !exists(json, 'excelPositionName') ? undefined : json['excelPositionName'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'excelStartDate': !exists(json, 'excelStartDate') ? undefined : json['excelStartDate'],
        'excelEndDate': !exists(json, 'excelEndDate') ? undefined : json['excelEndDate'],
        'invoiceInstructionAttachment': !exists(json, 'invoiceInstructionAttachment') ? undefined : json['invoiceInstructionAttachment'],
        'duplicateMasterRowId': !exists(json, 'duplicateMasterRowId') ? undefined : json['duplicateMasterRowId'],
        'rcSalesTotal': !exists(json, 'rcSalesTotal') ? undefined : json['rcSalesTotal'],
        'salesNet': !exists(json, 'salesNet') ? undefined : json['salesNet'],
        'salesCommissionAmount': !exists(json, 'salesCommissionAmount') ? undefined : json['salesCommissionAmount'],
        'salesNetTechnicalCost': !exists(json, 'salesNetTechnicalCost') ? undefined : json['salesNetTechnicalCost'],
        'salesNetTotal': !exists(json, 'salesNetTotal') ? undefined : json['salesNetTotal'],
        'rcPurchase': !exists(json, 'rcPurchase') ? undefined : json['rcPurchase'],
        'purchaseTechnicalCost': !exists(json, 'purchaseTechnicalCost') ? undefined : json['purchaseTechnicalCost'],
        'purchaseNetTotal': !exists(json, 'purchaseNetTotal') ? undefined : json['purchaseNetTotal'],
        'purchaseNet': !exists(json, 'purchaseNet') ? undefined : json['purchaseNet'],
        'netCustomerAccount': !exists(json, 'netCustomerAccount') ? undefined : json['netCustomerAccount'],
        'excelRcSalesTotal': !exists(json, 'excelRcSalesTotal') ? undefined : json['excelRcSalesTotal'],
        'excelSalesNet': !exists(json, 'excelSalesNet') ? undefined : json['excelSalesNet'],
        'excelSalesCommissionAmount': !exists(json, 'excelSalesCommissionAmount') ? undefined : json['excelSalesCommissionAmount'],
        'excelSalesNetTechnicalCost': !exists(json, 'excelSalesNetTechnicalCost') ? undefined : json['excelSalesNetTechnicalCost'],
        'excelSalesNetTotal': !exists(json, 'excelSalesNetTotal') ? undefined : json['excelSalesNetTotal'],
        'excelRcPurchase': !exists(json, 'excelRcPurchase') ? undefined : json['excelRcPurchase'],
        'excelPurchaseTechnicalCost': !exists(json, 'excelPurchaseTechnicalCost') ? undefined : json['excelPurchaseTechnicalCost'],
        'excelPurchaseNetTotal': !exists(json, 'excelPurchaseNetTotal') ? undefined : json['excelPurchaseNetTotal'],
        'excelPurchaseNet': !exists(json, 'excelPurchaseNet') ? undefined : json['excelPurchaseNet'],
        'excelNetCustomerAccount': !exists(json, 'excelNetCustomerAccount') ? undefined : json['excelNetCustomerAccount'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'ownName': !exists(json, 'ownName') ? undefined : json['ownName'],
        'actionGroup': !exists(json, 'actionGroup') ? undefined : json['actionGroup'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'additionalDescription': !exists(json, 'additionalDescription') ? undefined : json['additionalDescription'],
        'numberOfMedia': !exists(json, 'numberOfMedia') ? undefined : json['numberOfMedia'],
        'copySize': !exists(json, 'copySize') ? undefined : json['copySize'],
        'purchaseMethod': !exists(json, 'purchaseMethod') ? undefined : json['purchaseMethod'],
        'purchaseFormInternet': !exists(json, 'purchaseFormInternet') ? undefined : json['purchaseFormInternet'],
        'categoryInternet': !exists(json, 'categoryInternet') ? undefined : json['categoryInternet'],
        'formatInternet': !exists(json, 'formatInternet') ? undefined : json['formatInternet'],
        'purchaseFormInternetST': !exists(json, 'purchaseFormInternetST') ? undefined : json['purchaseFormInternetST'],
        'categoryInternetST': !exists(json, 'categoryInternetST') ? undefined : json['categoryInternetST'],
        'formatInternetST': !exists(json, 'formatInternetST') ? undefined : json['formatInternetST'],
        'labconChannel': !exists(json, 'labconChannel') ? undefined : json['labconChannel'],
        'categoryLabcon': !exists(json, 'categoryLabcon') ? undefined : json['categoryLabcon'],
        'technologyDictionary': !exists(json, 'technologyDictionary') ? undefined : json['technologyDictionary'],
        'regionDictionary': !exists(json, 'regionDictionary') ? undefined : json['regionDictionary'],
        'formatPress': !exists(json, 'formatPress') ? undefined : json['formatPress'],
        'formatRadio': !exists(json, 'formatRadio') ? undefined : json['formatRadio'],
        'emissionTypeRadio': !exists(json, 'emissionTypeRadio') ? undefined : json['emissionTypeRadio'],
        'emissionTypeCinema': !exists(json, 'emissionTypeCinema') ? undefined : json['emissionTypeCinema'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'copySizeValue': !exists(json, 'copySizeValue') ? undefined : json['copySizeValue'],
        'purchaseMethodValue': !exists(json, 'purchaseMethodValue') ? undefined : json['purchaseMethodValue'],
        'purchaseFormInternetValue': !exists(json, 'purchaseFormInternetValue') ? undefined : json['purchaseFormInternetValue'],
        'categoryInternetValue': !exists(json, 'categoryInternetValue') ? undefined : json['categoryInternetValue'],
        'formatInternetValue': !exists(json, 'formatInternetValue') ? undefined : json['formatInternetValue'],
        'purchaseFormInternetSTValue': !exists(json, 'purchaseFormInternetSTValue') ? undefined : json['purchaseFormInternetSTValue'],
        'categoryInternetSTValue': !exists(json, 'categoryInternetSTValue') ? undefined : json['categoryInternetSTValue'],
        'formatInternetSTValue': !exists(json, 'formatInternetSTValue') ? undefined : json['formatInternetSTValue'],
        'labconChannelValue': !exists(json, 'labconChannelValue') ? undefined : json['labconChannelValue'],
        'categoryLabconValue': !exists(json, 'categoryLabconValue') ? undefined : json['categoryLabconValue'],
        'technologyDictionaryValue': !exists(json, 'technologyDictionaryValue') ? undefined : json['technologyDictionaryValue'],
        'regionDictionaryValue': !exists(json, 'regionDictionaryValue') ? undefined : json['regionDictionaryValue'],
        'formatPressValue': !exists(json, 'formatPressValue') ? undefined : json['formatPressValue'],
        'formatRadioValue': !exists(json, 'formatRadioValue') ? undefined : json['formatRadioValue'],
        'emissionTypeRadioValue': !exists(json, 'emissionTypeRadioValue') ? undefined : json['emissionTypeRadioValue'],
        'emissionTypeCinemaValue': !exists(json, 'emissionTypeCinemaValue') ? undefined : json['emissionTypeCinemaValue'],
        'mediumValue': !exists(json, 'mediumValue') ? undefined : json['mediumValue'],
        'salesSurchargePercent': !exists(json, 'salesSurchargePercent') ? undefined : json['salesSurchargePercent'],
        'salesDiscountPercent': !exists(json, 'salesDiscountPercent') ? undefined : json['salesDiscountPercent'],
        'salesCommissionPercent': !exists(json, 'salesCommissionPercent') ? undefined : json['salesCommissionPercent'],
        'purchaseDiscountPercent': !exists(json, 'purchaseDiscountPercent') ? undefined : json['purchaseDiscountPercent'],
        'purchaseSurchargePercent': !exists(json, 'purchaseSurchargePercent') ? undefined : json['purchaseSurchargePercent'],
        'serviceExternalWorkPercent': !exists(json, 'serviceExternalWorkPercent') ? undefined : json['serviceExternalWorkPercent'],
        'serviceCreationPercent': !exists(json, 'serviceCreationPercent') ? undefined : json['serviceCreationPercent'],
        'serviceChangeProductionPercent': !exists(json, 'serviceChangeProductionPercent') ? undefined : json['serviceChangeProductionPercent'],
        'serviceStrategyPercent': !exists(json, 'serviceStrategyPercent') ? undefined : json['serviceStrategyPercent'],
        'serviceProductionBroadcastPercent': !exists(json, 'serviceProductionBroadcastPercent') ? undefined : json['serviceProductionBroadcastPercent'],
        'serviceClientServicePercent': !exists(json, 'serviceClientServicePercent') ? undefined : json['serviceClientServicePercent'],
        'excelSalesSurchargePercent': !exists(json, 'excelSalesSurchargePercent') ? undefined : json['excelSalesSurchargePercent'],
        'excelSalesDiscountPercent': !exists(json, 'excelSalesDiscountPercent') ? undefined : json['excelSalesDiscountPercent'],
        'excelSalesCommissionPercent': !exists(json, 'excelSalesCommissionPercent') ? undefined : json['excelSalesCommissionPercent'],
        'excelPurchaseDiscountPercent': !exists(json, 'excelPurchaseDiscountPercent') ? undefined : json['excelPurchaseDiscountPercent'],
        'excelPurchaseSurchargePercent': !exists(json, 'excelPurchaseSurchargePercent') ? undefined : json['excelPurchaseSurchargePercent'],
        'excelServiceExternalWorkPercent': !exists(json, 'excelServiceExternalWorkPercent') ? undefined : json['excelServiceExternalWorkPercent'],
        'excelServiceCreationPercent': !exists(json, 'excelServiceCreationPercent') ? undefined : json['excelServiceCreationPercent'],
        'excelServiceChangeProductionPercent': !exists(json, 'excelServiceChangeProductionPercent') ? undefined : json['excelServiceChangeProductionPercent'],
        'excelServiceStrategyPercent': !exists(json, 'excelServiceStrategyPercent') ? undefined : json['excelServiceStrategyPercent'],
        'excelServiceProductionBroadcastPercent': !exists(json, 'excelServiceProductionBroadcastPercent') ? undefined : json['excelServiceProductionBroadcastPercent'],
        'excelServiceClientServicePercent': !exists(json, 'excelServiceClientServicePercent') ? undefined : json['excelServiceClientServicePercent'],
        'isUpSell': !exists(json, 'isUpSell') ? undefined : json['isUpSell'],
        'excelCopySize': !exists(json, 'excelCopySize') ? undefined : json['excelCopySize'],
        'excelPurchaseMethod': !exists(json, 'excelPurchaseMethod') ? undefined : json['excelPurchaseMethod'],
        'excelPurchaseFormInternet': !exists(json, 'excelPurchaseFormInternet') ? undefined : json['excelPurchaseFormInternet'],
        'excelCategoryInternet': !exists(json, 'excelCategoryInternet') ? undefined : json['excelCategoryInternet'],
        'excelFormatInternet': !exists(json, 'excelFormatInternet') ? undefined : json['excelFormatInternet'],
        'excelPurchaseFormInternetST': !exists(json, 'excelPurchaseFormInternetST') ? undefined : json['excelPurchaseFormInternetST'],
        'excelCategoryInternetST': !exists(json, 'excelCategoryInternetST') ? undefined : json['excelCategoryInternetST'],
        'excelFormatInternetST': !exists(json, 'excelFormatInternetST') ? undefined : json['excelFormatInternetST'],
        'excelLabconChannel': !exists(json, 'excelLabconChannel') ? undefined : json['excelLabconChannel'],
        'excelCategoryLabcon': !exists(json, 'excelCategoryLabcon') ? undefined : json['excelCategoryLabcon'],
        'excelTechnologyDictionary': !exists(json, 'excelTechnologyDictionary') ? undefined : json['excelTechnologyDictionary'],
        'excelRegionDictionary': !exists(json, 'excelRegionDictionary') ? undefined : json['excelRegionDictionary'],
        'excelFormatPress': !exists(json, 'excelFormatPress') ? undefined : json['excelFormatPress'],
        'excelFormatRadio': !exists(json, 'excelFormatRadio') ? undefined : json['excelFormatRadio'],
        'excelEmissionTypeRadio': !exists(json, 'excelEmissionTypeRadio') ? undefined : json['excelEmissionTypeRadio'],
        'excelEmissionTypeCinema': !exists(json, 'excelEmissionTypeCinema') ? undefined : json['excelEmissionTypeCinema'],
        'excelMedium': !exists(json, 'excelMedium') ? undefined : json['excelMedium'],
        'estimateItem': !exists(json, 'estimateItem') ? undefined : GetEstimateItemDtoFromJSON(json['estimateItem']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function GetExcelAndPokRowDtoToJSON(value?: GetExcelAndPokRowDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowId': value.rowId,
        'status': value.status,
        'emptyRow': value.emptyRow,
        'otherErrors': value.otherErrors,
        'positionNotFoundError': value.positionNotFoundError,
        'parameterErrors': value.parameterErrors,
        'calculationErrors': value.calculationErrors,
        'projectId': value.projectId,
        'projectNumber': value.projectNumber,
        'excelProjectNumber': value.excelProjectNumber,
        'companyId': value.companyId,
        'profileId': value.profileId,
        'positionName': value.positionName,
        'positionType': value.positionType,
        'positionId': value.positionId,
        'excelPositionName': value.excelPositionName,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'excelStartDate': value.excelStartDate,
        'excelEndDate': value.excelEndDate,
        'invoiceInstructionAttachment': value.invoiceInstructionAttachment,
        'duplicateMasterRowId': value.duplicateMasterRowId,
        'rcSalesTotal': value.rcSalesTotal,
        'salesNet': value.salesNet,
        'salesCommissionAmount': value.salesCommissionAmount,
        'salesNetTechnicalCost': value.salesNetTechnicalCost,
        'salesNetTotal': value.salesNetTotal,
        'rcPurchase': value.rcPurchase,
        'purchaseTechnicalCost': value.purchaseTechnicalCost,
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseNet': value.purchaseNet,
        'netCustomerAccount': value.netCustomerAccount,
        'excelRcSalesTotal': value.excelRcSalesTotal,
        'excelSalesNet': value.excelSalesNet,
        'excelSalesCommissionAmount': value.excelSalesCommissionAmount,
        'excelSalesNetTechnicalCost': value.excelSalesNetTechnicalCost,
        'excelSalesNetTotal': value.excelSalesNetTotal,
        'excelRcPurchase': value.excelRcPurchase,
        'excelPurchaseTechnicalCost': value.excelPurchaseTechnicalCost,
        'excelPurchaseNetTotal': value.excelPurchaseNetTotal,
        'excelPurchaseNet': value.excelPurchaseNet,
        'excelNetCustomerAccount': value.excelNetCustomerAccount,
        'orderNumber': value.orderNumber,
        'ownName': value.ownName,
        'actionGroup': value.actionGroup,
        'action': value.action,
        'service': value.service,
        'additionalDescription': value.additionalDescription,
        'numberOfMedia': value.numberOfMedia,
        'copySize': value.copySize,
        'purchaseMethod': value.purchaseMethod,
        'purchaseFormInternet': value.purchaseFormInternet,
        'categoryInternet': value.categoryInternet,
        'formatInternet': value.formatInternet,
        'purchaseFormInternetST': value.purchaseFormInternetST,
        'categoryInternetST': value.categoryInternetST,
        'formatInternetST': value.formatInternetST,
        'labconChannel': value.labconChannel,
        'categoryLabcon': value.categoryLabcon,
        'technologyDictionary': value.technologyDictionary,
        'regionDictionary': value.regionDictionary,
        'formatPress': value.formatPress,
        'formatRadio': value.formatRadio,
        'emissionTypeRadio': value.emissionTypeRadio,
        'emissionTypeCinema': value.emissionTypeCinema,
        'medium': value.medium,
        'copySizeValue': value.copySizeValue,
        'purchaseMethodValue': value.purchaseMethodValue,
        'purchaseFormInternetValue': value.purchaseFormInternetValue,
        'categoryInternetValue': value.categoryInternetValue,
        'formatInternetValue': value.formatInternetValue,
        'purchaseFormInternetSTValue': value.purchaseFormInternetSTValue,
        'categoryInternetSTValue': value.categoryInternetSTValue,
        'formatInternetSTValue': value.formatInternetSTValue,
        'labconChannelValue': value.labconChannelValue,
        'categoryLabconValue': value.categoryLabconValue,
        'technologyDictionaryValue': value.technologyDictionaryValue,
        'regionDictionaryValue': value.regionDictionaryValue,
        'formatPressValue': value.formatPressValue,
        'formatRadioValue': value.formatRadioValue,
        'emissionTypeRadioValue': value.emissionTypeRadioValue,
        'emissionTypeCinemaValue': value.emissionTypeCinemaValue,
        'mediumValue': value.mediumValue,
        'salesSurchargePercent': value.salesSurchargePercent,
        'salesDiscountPercent': value.salesDiscountPercent,
        'salesCommissionPercent': value.salesCommissionPercent,
        'purchaseDiscountPercent': value.purchaseDiscountPercent,
        'purchaseSurchargePercent': value.purchaseSurchargePercent,
        'serviceExternalWorkPercent': value.serviceExternalWorkPercent,
        'serviceCreationPercent': value.serviceCreationPercent,
        'serviceChangeProductionPercent': value.serviceChangeProductionPercent,
        'serviceStrategyPercent': value.serviceStrategyPercent,
        'serviceProductionBroadcastPercent': value.serviceProductionBroadcastPercent,
        'serviceClientServicePercent': value.serviceClientServicePercent,
        'excelSalesSurchargePercent': value.excelSalesSurchargePercent,
        'excelSalesDiscountPercent': value.excelSalesDiscountPercent,
        'excelSalesCommissionPercent': value.excelSalesCommissionPercent,
        'excelPurchaseDiscountPercent': value.excelPurchaseDiscountPercent,
        'excelPurchaseSurchargePercent': value.excelPurchaseSurchargePercent,
        'excelServiceExternalWorkPercent': value.excelServiceExternalWorkPercent,
        'excelServiceCreationPercent': value.excelServiceCreationPercent,
        'excelServiceChangeProductionPercent': value.excelServiceChangeProductionPercent,
        'excelServiceStrategyPercent': value.excelServiceStrategyPercent,
        'excelServiceProductionBroadcastPercent': value.excelServiceProductionBroadcastPercent,
        'excelServiceClientServicePercent': value.excelServiceClientServicePercent,
        'isUpSell': value.isUpSell,
        'excelCopySize': value.excelCopySize,
        'excelPurchaseMethod': value.excelPurchaseMethod,
        'excelPurchaseFormInternet': value.excelPurchaseFormInternet,
        'excelCategoryInternet': value.excelCategoryInternet,
        'excelFormatInternet': value.excelFormatInternet,
        'excelPurchaseFormInternetST': value.excelPurchaseFormInternetST,
        'excelCategoryInternetST': value.excelCategoryInternetST,
        'excelFormatInternetST': value.excelFormatInternetST,
        'excelLabconChannel': value.excelLabconChannel,
        'excelCategoryLabcon': value.excelCategoryLabcon,
        'excelTechnologyDictionary': value.excelTechnologyDictionary,
        'excelRegionDictionary': value.excelRegionDictionary,
        'excelFormatPress': value.excelFormatPress,
        'excelFormatRadio': value.excelFormatRadio,
        'excelEmissionTypeRadio': value.excelEmissionTypeRadio,
        'excelEmissionTypeCinema': value.excelEmissionTypeCinema,
        'excelMedium': value.excelMedium,
        'estimateItem': GetEstimateItemDtoToJSON(value.estimateItem),
        'comment': value.comment,
    };
}

