/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateTeamDto
 */
export interface CreateUpdateTeamDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTeamDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTeamDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTeamDto
     */
    managerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateTeamDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTeamDto
     */
    companyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateTeamDto
     */
    pae: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateTeamDto
     */
    tv: boolean;
}

/**
 * Check if a given object implements the CreateUpdateTeamDto interface.
 */
export function instanceOfCreateUpdateTeamDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "managerId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "pae" in value;
    isInstance = isInstance && "tv" in value;

    return isInstance;
}

export function CreateUpdateTeamDtoFromJSON(json: any): CreateUpdateTeamDto {
    return CreateUpdateTeamDtoFromJSONTyped(json, false);
}

export function CreateUpdateTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateTeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'managerId': json['managerId'],
        'active': json['active'],
        'companyId': json['companyId'],
        'pae': json['pae'],
        'tv': json['tv'],
    };
}

export function CreateUpdateTeamDtoToJSON(value?: CreateUpdateTeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'managerId': value.managerId,
        'active': value.active,
        'companyId': value.companyId,
        'pae': value.pae,
        'tv': value.tv,
    };
}

