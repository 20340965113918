/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDocumentationDto
 */
export interface GetDocumentationDto {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentationDto
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentationDto
     */
    content: string;
}

/**
 * Check if a given object implements the GetDocumentationDto interface.
 */
export function instanceOfGetDocumentationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function GetDocumentationDtoFromJSON(json: any): GetDocumentationDto {
    return GetDocumentationDtoFromJSONTyped(json, false);
}

export function GetDocumentationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDocumentationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'content': json['content'],
    };
}

export function GetDocumentationDtoToJSON(value?: GetDocumentationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'content': value.content,
    };
}

