import React, { useState, useEffect } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import { SavingResult } from '../../common/types/SavingResult';

export default (props: { result?: SavingResult }) => {
  const [show, setShow] = useState(false);
  const [saved, setSaved] = useState(false);
  const [message, setMessage] = useState('Zmiany zostały zapisane.');

  useEffect(() => {
    if (!props.result) {
      return;
    }

    let saveMessage = 'Zmiany zostały zapisane.';
    if (!props.result.saved) {
      if (!props.result.errors) {
        saveMessage = 'Błąd przy zapisie.';
      } else {
        saveMessage = '';
        for (const error of props.result.errors) {
          saveMessage += (saveMessage.length > 0 ? '<br />' : '') + error;
        }
      }
    }
    setSaved(props.result.saved);
    setShow(true);
    setMessage(saveMessage);
  }, [props.result]);

  return (
    <>
      {show && (
        <ToastContainer className="p-3" position="bottom-start">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide={true}
            delay={saved ? 3000 : 6000}
            bg={saved ? 'success' : 'danger'}
            className="saving-toast"
          >
            <Toast.Body className="text-white">{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};
