import { purchaserForGrid } from '../../../pok/components/Purchasers/PurchaserSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { DefaultApi } from '../autogenerated/pokApiClient';
import {
  CreateUpdatePurchaserDto,
  CreateUpdatePurchaserDtoFromJSON,
} from '../autogenerated/pokApiClient/models/CreateUpdatePurchaserDto';
import { GetLastEditorDto } from '../autogenerated/pokApiClient/models/GetLastEditorDto';
import { GetPurchaserDto } from '../autogenerated/pokApiClient/models/GetPurchaserDto';
import { PurchaserSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import {
  defaultSelectorLimit,
  Option,
} from '../../../pok/components/Selects/Selector';
import { FilterColumn } from '../../types/FilterColumn';

export interface PurchaserContextInterface {
  getById: (id: string) => Promise<GetPurchaserDto>;
  getOptionById: (id: string) => Promise<Option>;
  getOptionsByTeamIdsAndName(
    teamIds: string[],
    text: string,
    parentProjectId?: string,
  ): Promise<Option[]>;
  getOptionsByTeamIdsBudgetIdAndName(
    teamIds: string[],
    budgetId: string,
    text: string,
    parentProjectId?: string,
  ): Promise<Option[]>;
  getOptionsByTeamIdsAndNameForRoleWithoutTeams(
    teamIds: string[],
    text: string,
  ): Promise<Option[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    clientId?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
    clientId?: string,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdatePurchaserDto) => Promise<void>;
  create: (dto: CreateUpdatePurchaserDto) => Promise<GetPurchaserDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const PurchaserContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.purchaserControllerGet(id),
    getOptionById: async (id: string) => {
      const data = await api.purchaserControllerGet(id);
      return {
        value: data.id,
        label: `${data.name} (${
          data.client.shortname ? data.client.shortname : data.client.name
        })`,
      };
    },
    getOptionsByTeamIdsAndNameForRoleWithoutTeams: async (
      teamIds: string[],
      text: string,
    ) => {
      const data = await api
        .purchaserControllerGetAllByTeamIdsForRoleWithoutTeams(
          teamIds,
          text,
          defaultSelectorLimit,
        )
        .then(p => p.sort((a, b) => a.name.localeCompare(b.name, 'pl')));
      return Array.from(data, purchaser => ({
        value: purchaser.id,
        label: `${purchaser.name} (${
          purchaser.client.shortname
            ? purchaser.client.shortname
            : purchaser.client.name
        })`,
      }));
    },
    getOptionsByTeamIdsAndName: async (
      teamIds: string[],
      text: string,
      parentProjectId?: string,
    ) => {
      const data = await api
        .purchaserControllerGetAllByTeamIds(
          teamIds,
          text,
          defaultSelectorLimit,
          parentProjectId,
        )
        .then(p => p.sort((a, b) => a.name.localeCompare(b.name, 'pl')));
      return Array.from(data, purchaser => ({
        value: purchaser.id,
        label: `${purchaser.name} (${
          purchaser.client.shortname
            ? purchaser.client.shortname
            : purchaser.client.name
        })`,
      }));
    },
    getOptionsByTeamIdsBudgetIdAndName: async (
      teamIds: string[],
      budgetId: string,
      text: string,
      parentProjectId?: string,
    ) => {
      const data = await api
        .purchaserControllerGetAllByTeamIdsAndBudget(
          teamIds,
          budgetId,
          text,
          defaultSelectorLimit,
          parentProjectId,
        )
        .then(p => p.sort((a, b) => a.name.localeCompare(b.name, 'pl')));
      return Array.from(data, purchaser => ({
        value: purchaser.id,
        label: `${purchaser.name} (${
          purchaser.client.shortname
            ? purchaser.client.shortname
            : purchaser.client.name
        })`,
      }));
    },
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      clientId?: string,
    ) => {
      const data = await api.purchaserControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        clientId,
      );

      return data.map(item => purchaserForGrid(item, orderBy));
    },

    getAllCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
      clientId?: string,
    ) =>
      api.purchaserControllerGetAllCount(
        filterText || '',
        filterColumns,
        clientId,
      ),
    update: (id: string, dto: CreateUpdatePurchaserDto) =>
      api.purchaserControllerUpdate(id, dto),
    create: (dto: CreateUpdatePurchaserDto) =>
      api.purchaserControllerCreate(dto),
    getLastEditor: (id: string) => api.purchaserControllerGetLastEditor(id),
  };
};

export const newPurchaser = (clientId?: string, companyId?: string) => {
  return CreateUpdatePurchaserDtoFromJSON({
    clientId: clientId,
    companyId: companyId,
  });
};

export const validate = (purchaser: CreateUpdatePurchaserDto) =>
  validateAgainst(PurchaserSchema, purchaser);

export const convert = (purchaser?: GetPurchaserDto) => {
  if (!purchaser) {
    return newPurchaser();
  }
  const converted = CreateUpdatePurchaserDtoFromJSON(purchaser);
  converted.companyId = purchaser.company.id;
  converted.budgetId = purchaser.budget.id;
  converted.clientId = purchaser.client.id;
  converted.teamIds = purchaser.teams?.map(({ id }) => id) || [];

  return converted;
};
