import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import {
  CreateUpdateAttachmentDto,
  CreateUpdateBudgetDto,
  GetAttachmentDto,
  GetBudgetDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import Tabs from '../../../app/components/Tabs';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/BudgetContext';
import { AddAttachment } from '../Attachments/AddAttachment';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Attachment } from '../Attachments/Attachment';

import { BudgetDetailsEditor } from './BudgetDetailsEditor';
import { BudgetEditorForm } from './BudgetEditorForm';

interface BudgetEditorTabbedProps {
  budget?: GetBudgetDto;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  handleAfterEdit?: () => void;
}
export const BudgetEditorTabbed: React.FC<BudgetEditorTabbedProps> = props => {
  const pok = usePokCore();
  const [refresh, setRefresh] = useState<boolean>(false);

  const editBudget = async (budgetCU: CreateUpdateBudgetDto, id?: string) => {
    const status = await validate(budgetCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.budgets.update(id, budgetCU);
        } else {
          await pok.budgets.create(budgetCU);
        }
        setRefresh(!refresh);
        props.handleAfterEdit && props.handleAfterEdit();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">
            Budżet <b>{props.budget?.name}</b>
            {props.budget ? `, nr sys. ${props.budget?.systemNumber}` : null}
            {props.budget?.active ? '' : ' (nieaktywny)'}{' '}
          </Card.Title>
          <Tabs>
            <Tab eventKey="budget" title="Budżet" className="m-3">
              <BudgetEditorForm
                id={props.budget?.id}
                budget={convert(props.budget)}
                refresh={refresh}
                readOnly
              />
              <br />
              <BudgetDetailsEditor
                id={props.budget?.id}
                systemNumber={props.budget?.systemNumber}
                budget={convert(props.budget)}
                onCompleted={editBudget}
              />
              <br />
              <AssociatedDocuments
                name="Załączniki"
                createModalType={AddAttachment}
                presentModalType={Attachment}
                docs={props.attachments}
                className="float-end col-md-3"
                isDropdownButton
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};
