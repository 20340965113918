import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import Button from '../../../common/components/Button/Button';
import { ProjectApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';

import { ProjectsApplicationDetailsEditForm } from './ProjectsApplicationDetailsEditForm';

interface ProjectsApplicationDetailsModalProps {
  project?: CreateUpdateProjectWithTeamDto;
  applicationType: ProjectApplicationTypeEnum;
  purchaserName: string;
  brandName: string;
  onAccept: () => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onReject: (rejectedNote?: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onClose: () => void;
  show?: boolean;
  readOnly?: boolean;
  rejectedNote?: string;
}

const STATUS_OPTIONS = [
  { value: 'approved', label: 'Zatwierdzony' },
  { value: 'rejected', label: 'Odrzucony' },
];

export const ProjectsApplicationDetailsModal: React.FC<
  ProjectsApplicationDetailsModalProps
> = props => {
  const { t, tk } = useTranslation('menu');
  const [status, setStatus] = useState(STATUS_OPTIONS[0]);
  const [rejectedNote, setRejectedNote] = useState<string | undefined>();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    setStatus(STATUS_OPTIONS[0]);
    setSaveErrors([]);
    setRejectedNote(undefined);
  }, [props.project?.id]);

  const handleClick = async () => {
    setIsLoading(true);
    const { saved, errors } = await (status.value === STATUS_OPTIONS[0].value
      ? props.onAccept()
      : props.onReject(rejectedNote));
    if (saved) {
      notifications.saveCompleted();
    } else {
      setSaveErrors(errors);
    }
    setIsLoading(false);
  };

  const isStatusReversible =
    !props.project?.active &&
    (props.applicationType === ProjectApplicationTypeEnum.Delayed
      ? props.rejectedNote !== undefined
      : true);

  return (
    <Modal
      onHide={props.onClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(tk.menu.project)}:{' '}
          <b>
            {props.project?.name}
            {props.project?.number ? ` (${props.project?.number})` : ''}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectsApplicationDetailsEditForm
          project={props.project}
          applicationType={props.applicationType}
          purchaserName={props.purchaserName}
          brandName={props.brandName}
          status={status}
          onStatusChange={setStatus}
          savedRejectedNote={props.rejectedNote}
          rejectedNote={rejectedNote}
          onRejectedNoteChange={setRejectedNote}
          readOnly={props.readOnly}
        />
      </Modal.Body>
      <ValidationAlert
        show={saveErrors?.length > 0}
        errors={saveErrors}
        className="m-3"
      />
      <Modal.Footer>
        {props.project?.id && (
          <LastEditorInfo
            id={props.project?.id}
            method={pok.projects.getLastEditor}
          />
        )}
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {(!props.readOnly || isStatusReversible) && (
          <Button variant="primary" onClick={handleClick} isLoading={isLoading}>
            Zapisz
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
