import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { AttachmentObjectType } from '../../../common/pokCore/validation/schemas';
import { SimpleNoteEditor } from '../Attachments/SimpleNoteEditor';
import { GetAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { convert } from '../../../common/pokCore/contexts/AttachmentContext';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useAuth } from '../../../common/hooks/useAuth';
import oneliner from '../../../common/oneliner';
import SelectOptionInfo from '../Selects/SelectOptionInfo';

interface SimpleAttachmentLinkProps {
  objectId: string;
  objectType: AttachmentObjectType;
  note: string;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  additionalText?: string;
  checkFinance?: boolean;
}

export const SimpleAttachmentLink: React.FC<
  SimpleAttachmentLinkProps
> = props => {
  const NOTE_MAX_LENGTH = 150;
  const pok = usePokCore();
  const notifications = useNotifications();
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState<GetAttachmentDto>();
  const authFinance = auth.check(PermissionsEnum.Finance);

  useEffect(() => {
    if (props.objectId && props.objectType) {
      pok.attachments
        .getAllForObject(props.objectType, props.objectId)
        .then(attachments => {
          if (attachments.length > 0) {
            setAttachment(attachments[0]);
          } else {
            setAttachment(undefined);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [pok.attachments, props.objectId, props.objectType, notifications, show]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    props.setRefresh(!props.refresh);
    setShow(false);
  };
  return (
    <>
      {(!props.checkFinance || authFinance) && (
        <OverlayTrigger overlay={<Tooltip>Komentarz</Tooltip>}>
          <Button
            className="p-0"
            onClick={e => {
              e.stopPropagation();
              handleClick();
            }}
            variant="btn-info"
          >
            {attachment?.id ? (
              <Icon.PencilFill className="icon-green" />
            ) : (
              <Icon.PlusCircleFill className="icon-green" />
            )}
          </Button>
        </OverlayTrigger>
      )}{' '}
      {props.note && (
        <>
          {oneliner(props.note, NOTE_MAX_LENGTH)}
          <SelectOptionInfo
            label={props.note}
            title="Notatka"
            placement="auto"
          />
        </>
      )}
      <SimpleNoteEditor
        show={show}
        existsAttachment={
          attachment?.id ? convert(attachment, 'NOTE') : undefined
        }
        existsAttachmentId={attachment?.id}
        onClose={handleClose}
        objectId={props.objectId}
        objectType={props.objectType}
        additionalText={props.additionalText}
      />
    </>
  );
};
