/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateOrderExecutionPositionDto
 */
export interface CreateUpdateOrderExecutionPositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionPositionDto
     */
    orderExecutionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionPositionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionPositionDto
     */
    amount: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOrderExecutionPositionDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateOrderExecutionPositionDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionPositionDto
     */
    actionGroup: string;
}

/**
 * Check if a given object implements the CreateUpdateOrderExecutionPositionDto interface.
 */
export function instanceOfCreateUpdateOrderExecutionPositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "actionGroup" in value;

    return isInstance;
}

export function CreateUpdateOrderExecutionPositionDtoFromJSON(json: any): CreateUpdateOrderExecutionPositionDto {
    return CreateUpdateOrderExecutionPositionDtoFromJSONTyped(json, false);
}

export function CreateUpdateOrderExecutionPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateOrderExecutionPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderExecutionId': !exists(json, 'orderExecutionId') ? undefined : json['orderExecutionId'],
        'name': json['name'],
        'amount': json['amount'],
        'active': json['active'],
        'order': json['order'],
        'actionGroup': json['actionGroup'],
    };
}

export function CreateUpdateOrderExecutionPositionDtoToJSON(value?: CreateUpdateOrderExecutionPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderExecutionId': value.orderExecutionId,
        'name': value.name,
        'amount': value.amount,
        'active': value.active,
        'order': value.order,
        'actionGroup': value.actionGroup,
    };
}

