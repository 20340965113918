/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEstimateItemAmountsOnlyDto } from './GetEstimateItemAmountsOnlyDto';
import {
    GetEstimateItemAmountsOnlyDtoFromJSON,
    GetEstimateItemAmountsOnlyDtoFromJSONTyped,
    GetEstimateItemAmountsOnlyDtoToJSON,
} from './GetEstimateItemAmountsOnlyDto';
import type { GetProfileDto } from './GetProfileDto';
import {
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './GetProfileDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';

/**
 * 
 * @export
 * @interface GetEstimateByMonthWithItemsDto
 */
export interface GetEstimateByMonthWithItemsDto {
    /**
     * 
     * @type {string}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    status: GetEstimateByMonthWithItemsDtoStatusEnum;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    profile: GetProfileDto;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    project: GetProjectDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    isSalesBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    isPurchaseBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<GetEstimateItemAmountsOnlyDto>}
     * @memberof GetEstimateByMonthWithItemsDto
     */
    estimateItems?: Array<GetEstimateItemAmountsOnlyDto>;
}


/**
 * @export
 */
export const GetEstimateByMonthWithItemsDtoStatusEnum = {
    New: 'New',
    AcceptedByClient: 'AcceptedByClient',
    ReadyForInvoice: 'ReadyForInvoice',
    Invoiced: 'Invoiced',
    Settled: 'Settled',
    Cancelled: 'Cancelled'
} as const;
export type GetEstimateByMonthWithItemsDtoStatusEnum = typeof GetEstimateByMonthWithItemsDtoStatusEnum[keyof typeof GetEstimateByMonthWithItemsDtoStatusEnum];


/**
 * Check if a given object implements the GetEstimateByMonthWithItemsDto interface.
 */
export function instanceOfGetEstimateByMonthWithItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "isSalesBlocked" in value;
    isInstance = isInstance && "isPurchaseBlocked" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetEstimateByMonthWithItemsDtoFromJSON(json: any): GetEstimateByMonthWithItemsDto {
    return GetEstimateByMonthWithItemsDtoFromJSONTyped(json, false);
}

export function GetEstimateByMonthWithItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEstimateByMonthWithItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'status': json['status'],
        'profile': GetProfileDtoFromJSON(json['profile']),
        'project': GetProjectDtoFromJSON(json['project']),
        'isSalesBlocked': json['isSalesBlocked'],
        'isPurchaseBlocked': json['isPurchaseBlocked'],
        'active': json['active'],
        'estimateItems': !exists(json, 'estimateItems') ? undefined : ((json['estimateItems'] as Array<any>).map(GetEstimateItemAmountsOnlyDtoFromJSON)),
    };
}

export function GetEstimateByMonthWithItemsDtoToJSON(value?: GetEstimateByMonthWithItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString()),
        'status': value.status,
        'profile': GetProfileDtoToJSON(value.profile),
        'project': GetProjectDtoToJSON(value.project),
        'isSalesBlocked': value.isSalesBlocked,
        'isPurchaseBlocked': value.isPurchaseBlocked,
        'active': value.active,
        'estimateItems': value.estimateItems === undefined ? undefined : ((value.estimateItems as Array<any>).map(GetEstimateItemAmountsOnlyDtoToJSON)),
    };
}

