/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBrandDto } from './GetBrandDto';
import {
    GetBrandDtoFromJSON,
    GetBrandDtoFromJSONTyped,
    GetBrandDtoToJSON,
} from './GetBrandDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetEstimateByMonthDto } from './GetEstimateByMonthDto';
import {
    GetEstimateByMonthDtoFromJSON,
    GetEstimateByMonthDtoFromJSONTyped,
    GetEstimateByMonthDtoToJSON,
} from './GetEstimateByMonthDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';
import type { GetProjectTeamDto } from './GetProjectTeamDto';
import {
    GetProjectTeamDtoFromJSON,
    GetProjectTeamDtoFromJSONTyped,
    GetProjectTeamDtoToJSON,
} from './GetProjectTeamDto';
import type { GetPurchaserDto } from './GetPurchaserDto';
import {
    GetPurchaserDtoFromJSON,
    GetPurchaserDtoFromJSONTyped,
    GetPurchaserDtoToJSON,
} from './GetPurchaserDto';

/**
 * 
 * @export
 * @interface GetProjectExtendedDto
 */
export interface GetProjectExtendedDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetProjectExtendedDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    delayMessage: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectExtendedDto
     */
    delayVerification: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetProjectExtendedDto
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetProjectExtendedDto
     */
    endDate: Date;
    /**
     * 
     * @type {GetPurchaserDto}
     * @memberof GetProjectExtendedDto
     */
    purchaser: GetPurchaserDto;
    /**
     * 
     * @type {GetBrandDto}
     * @memberof GetProjectExtendedDto
     */
    brand: GetBrandDto;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetProjectExtendedDto
     */
    parent: GetProjectDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetProjectExtendedDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectExtendedDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<GetProjectTeamDto>}
     * @memberof GetProjectExtendedDto
     */
    projectTeams?: Array<GetProjectTeamDto>;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProjectExtendedDto
     */
    countryDictionary: GetDictionaryDto;
    /**
     * 
     * @type {number}
     * @memberof GetProjectExtendedDto
     */
    totalDifference: number;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetProjectExtendedDto
     */
    responsibleOwner: GetEmployeeDto;
    /**
     * 
     * @type {Array<GetEstimateByMonthDto>}
     * @memberof GetProjectExtendedDto
     */
    estimatesByMonths?: Array<GetEstimateByMonthDto>;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    purchaserDocNumber: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProjectExtendedDto
     */
    labelDictionary: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    cancelMessage: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectExtendedDto
     */
    cancelVerification: boolean;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetProjectExtendedDto
     */
    cancelRequestedByEmployee: GetEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    purchaseNetTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    purchaseInvoicesSum: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectExtendedDto
     */
    purchaseInvoiceRemainingSum: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectExtendedDto
     */
    hasRights?: boolean;
}

/**
 * Check if a given object implements the GetProjectExtendedDto interface.
 */
export function instanceOfGetProjectExtendedDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "delayMessage" in value;
    isInstance = isInstance && "delayVerification" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "purchaser" in value;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "parent" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "countryDictionary" in value;
    isInstance = isInstance && "totalDifference" in value;
    isInstance = isInstance && "responsibleOwner" in value;
    isInstance = isInstance && "purchaserDocNumber" in value;
    isInstance = isInstance && "labelDictionary" in value;
    isInstance = isInstance && "cancelMessage" in value;
    isInstance = isInstance && "cancelVerification" in value;
    isInstance = isInstance && "cancelRequestedByEmployee" in value;
    isInstance = isInstance && "purchaseNetTotal" in value;
    isInstance = isInstance && "purchaseInvoicesSum" in value;
    isInstance = isInstance && "purchaseInvoiceRemainingSum" in value;

    return isInstance;
}

export function GetProjectExtendedDtoFromJSON(json: any): GetProjectExtendedDto {
    return GetProjectExtendedDtoFromJSONTyped(json, false);
}

export function GetProjectExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProjectExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'name': json['name'],
        'number': json['number'],
        'notes': json['notes'],
        'delayMessage': json['delayMessage'],
        'delayVerification': json['delayVerification'],
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'purchaser': GetPurchaserDtoFromJSON(json['purchaser']),
        'brand': GetBrandDtoFromJSON(json['brand']),
        'parent': GetProjectDtoFromJSON(json['parent']),
        'company': GetCompanyDtoFromJSON(json['company']),
        'active': json['active'],
        'projectTeams': !exists(json, 'projectTeams') ? undefined : ((json['projectTeams'] as Array<any>).map(GetProjectTeamDtoFromJSON)),
        'rejectedNote': json['rejectedNote'],
        'countryDictionary': GetDictionaryDtoFromJSON(json['countryDictionary']),
        'totalDifference': json['totalDifference'],
        'responsibleOwner': GetEmployeeDtoFromJSON(json['responsibleOwner']),
        'estimatesByMonths': !exists(json, 'estimatesByMonths') ? undefined : ((json['estimatesByMonths'] as Array<any>).map(GetEstimateByMonthDtoFromJSON)),
        'purchaserDocNumber': json['purchaserDocNumber'],
        'labelDictionary': GetDictionaryDtoFromJSON(json['labelDictionary']),
        'cancelMessage': json['cancelMessage'],
        'cancelVerification': json['cancelVerification'],
        'cancelRequestedByEmployee': GetEmployeeDtoFromJSON(json['cancelRequestedByEmployee']),
        'purchaseNetTotal': json['purchaseNetTotal'],
        'purchaseInvoicesSum': json['purchaseInvoicesSum'],
        'purchaseInvoiceRemainingSum': json['purchaseInvoiceRemainingSum'],
        'hasRights': !exists(json, 'hasRights') ? undefined : json['hasRights'],
    };
}

export function GetProjectExtendedDtoToJSON(value?: GetProjectExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'name': value.name,
        'number': value.number,
        'notes': value.notes,
        'delayMessage': value.delayMessage,
        'delayVerification': value.delayVerification,
        'startDate': (value.startDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'purchaser': GetPurchaserDtoToJSON(value.purchaser),
        'brand': GetBrandDtoToJSON(value.brand),
        'parent': GetProjectDtoToJSON(value.parent),
        'company': GetCompanyDtoToJSON(value.company),
        'active': value.active,
        'projectTeams': value.projectTeams === undefined ? undefined : ((value.projectTeams as Array<any>).map(GetProjectTeamDtoToJSON)),
        'rejectedNote': value.rejectedNote,
        'countryDictionary': GetDictionaryDtoToJSON(value.countryDictionary),
        'totalDifference': value.totalDifference,
        'responsibleOwner': GetEmployeeDtoToJSON(value.responsibleOwner),
        'estimatesByMonths': value.estimatesByMonths === undefined ? undefined : ((value.estimatesByMonths as Array<any>).map(GetEstimateByMonthDtoToJSON)),
        'purchaserDocNumber': value.purchaserDocNumber,
        'labelDictionary': GetDictionaryDtoToJSON(value.labelDictionary),
        'cancelMessage': value.cancelMessage,
        'cancelVerification': value.cancelVerification,
        'cancelRequestedByEmployee': GetEmployeeDtoToJSON(value.cancelRequestedByEmployee),
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseInvoicesSum': value.purchaseInvoicesSum,
        'purchaseInvoiceRemainingSum': value.purchaseInvoiceRemainingSum,
        'hasRights': value.hasRights,
    };
}

