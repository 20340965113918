import React, { useEffect, useMemo, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { ProjectSearch } from '../../components/Projects/ProjectSearch';
import {
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { convert } from '../../../common/pokCore/contexts/ProjectContext';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { validate } from '../../../common/pokCore/contexts/ProjectApplicationContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ProjectApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';

import { ProjectsApplicationDetailsModal } from './ProjectsApplicationDetailsModal';

const PROJECT_APPLICATIONS_TABS = [
  {
    id: 'delayed',
    label: 'Oczekujące po terminie',
    isWaiting: true,
    applicationType: ProjectApplicationTypeEnum.Delayed,
  },
  {
    id: 'canceling',
    label: 'Oczekujące do anulowania',
    isWaiting: true,
    applicationType: ProjectApplicationTypeEnum.ToCancel,
  },
  {
    id: 'alldelayed',
    label: 'Wszystkie po terminie',
    isWaiting: false,
    applicationType: ProjectApplicationTypeEnum.Delayed,
  },
  {
    id: 'allcanceling',
    label: 'Wszystkie do anulowania',
    isWaiting: false,
    applicationType: ProjectApplicationTypeEnum.ToCancel,
  },
];

export const ProjectsApplicationView: React.FC = () => {
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();

  const [project, setProject] = useState<GetProjectDto>();
  const [show, setShow] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('delayed');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setActiveTab(searchParams?.get('activeTab') ?? 'delayed');
  }, [searchParams]);

  const getLoadDataFunction = useMemo(
    () => (applicationType: ProjectApplicationTypeEnum, isWaiting?: boolean) =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) => {
        setRefresh(false);
        return (
          isWaiting
            ? pok.projectsApplication.getAllApplicationWaitingByPortion
            : pok.projectsApplication.getAllApplicationByPortion
        )(
          applicationType,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        );
      }) as GridGetterFunction,
    [pok.projectsApplication, setRefresh],
  );

  const getLoadCountFunction = useMemo(
    () => (applicationType: ProjectApplicationTypeEnum, isWaiting?: boolean) =>
      (filterText =>
        (isWaiting
          ? pok.projectsApplication.getAllApplicationWaitingCount
          : pok.projectsApplication.getAllApplicationCount)(
          applicationType,
          filterText,
        )) as GridCountFunction,
    [pok.projectsApplication],
  );

  const handleClick = async (id: string) => {
    const fetchedProject = await pok.projects.getById(id);
    setProject(fetchedProject);
    setShow(true);
  };

  const handleClose = () => {
    setProject(undefined);
    setShow(false);
  };

  const handleAcceptApplication = useMemo(
    () => (type: ProjectApplicationTypeEnum) => async () => {
      if (project) {
        try {
          await pok.projectsApplication.applicationAccept(type, project.id);
          await pok.emails.sendProjectResponseEmail(project.id, type, true);
          setProject(undefined);
          setShow(false);
          setRefresh(true);
          return { saved: true, errors: [] };
        } catch (response) {
          return {
            saved: false,
            errors: await responseErrors(response as ResponseError),
          };
        }
      } else {
        return {
          saved: false,
          errors: [],
        };
      }
    },
    [pok.emails, pok.projectsApplication, project],
  );

  const handleRejectApplication = useMemo(
    () =>
      (type: ProjectApplicationTypeEnum) =>
      async (rejectedNote?: string) => {
        const status = await validate({ rejectedNote: rejectedNote as string });
        if (project && status.valid) {
          try {
            await pok.projectsApplication.applicationReject(
              type,
              project.id,
              rejectedNote ?? '',
            );

            await pok.emails.sendProjectResponseEmail(project.id, type, false);

            setProject(undefined);
            setShow(false);
            setRefresh(true);
            return {
              saved: true,
              errors: [],
            };
          } catch (response) {
            return {
              saved: false,
              errors: await responseErrors(response as ResponseError),
            };
          }
        } else {
          return {
            saved: false,
            errors: status?.errors || [],
          };
        }
      },
    [pok.emails, pok.projectsApplication, project],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.menu.projectsApplications)}
        </Card.Title>
        <TabsHistory
          id="projectApplications"
          activeKey={activeTab}
          setActiveTab={setActiveTab}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {PROJECT_APPLICATIONS_TABS.map(
            ({ id, label, isWaiting, applicationType }) => (
              <Tab key={id} eventKey={id} title={label} className="m-3">
                <ProjectSearch
                  isDelayed={!isWaiting}
                  getData={getLoadDataFunction(applicationType, isWaiting)}
                  getCount={getLoadCountFunction(applicationType, isWaiting)}
                  onRowClick={handleClick}
                  refresh={refresh}
                  showActionColumn={true}
                  applicationType={applicationType}
                />
                <ProjectsApplicationDetailsModal
                  applicationType={applicationType}
                  rejectedNote={project?.rejectedNote}
                  project={convert(project)}
                  purchaserName={project?.purchaser?.name || ''}
                  brandName={project?.brand?.name || ''}
                  show={show}
                  onAccept={handleAcceptApplication(applicationType)}
                  onReject={handleRejectApplication(applicationType)}
                  onClose={handleClose}
                  readOnly={
                    applicationType === ProjectApplicationTypeEnum.Delayed
                      ? !project?.active || !project?.delayVerification
                      : !project?.active || !project?.cancelVerification
                  }
                />
              </Tab>
            ),
          )}
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
