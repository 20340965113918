import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

interface NewWindowButtonProps {
  purchaseInvoiceId?: string;
  projectId?: string;
  estimates?: boolean;
}

export const NewWindowButton: React.FC<NewWindowButtonProps> = props => {
  const nav = useNavigation();

  const handleClick = () => {
    if (props.purchaseInvoiceId) {
      nav.purchaseInvoiceEditor(props.purchaseInvoiceId, true);
    }
    if (props.projectId) {
      nav.projectDetails(
        props.projectId,
        true,
        props.estimates ? 'estimates' : undefined,
      );
    }
  };

  return (
    <OverlayTrigger overlay={<Tooltip>Otwórz w nowej karcie</Tooltip>}>
      <Button
        onClick={e => {
          e.stopPropagation();
          handleClick();
        }}
        variant="btn-info"
        className="px-2"
      >
        <Icon.BoxArrowUpRight className="icon-blue" size={16} />
      </Button>
    </OverlayTrigger>
  );
};
