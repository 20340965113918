import React from 'react';
import Button from 'react-bootstrap/Button';

import { useNavigation } from '../../../common/navigation';

export const Landing: React.FC = () => {
  const nav = useNavigation();

  return (
    <div className="main-banner p-5 rounded-lg m-3">
      <div className="main-banner-text">
        <h1>POK</h1>
        <p className="lead">Platforma Obsługi Kampanii</p>

        <hr className="my-4" />
        <div className="btn-banner d-flex justify-content-start mt-3">
          <Button className="btn-banner" variant="outline" onClick={nav.help}>
            Pomoc
          </Button>
        </div>
      </div>
    </div>
  );
};
