import React from 'react';
import { Alert } from 'react-bootstrap';

import { GetResultsDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface ExcelImportSummaryProps {
  results?: GetResultsDto;
}

export const ExcelImportSummary: React.FC<ExcelImportSummaryProps> = props => {
  const results = props.results;
  return (
    <>
      <Alert variant="warning" className="mt-3">
        {results && (
          <>
            <b>
              {' '}
              Poprawnie zaimportowanych pozycji:{' '}
              {results.addCount + results.updateCount}{' '}
            </b>{' '}
            (dodano: {results.addCount}, zaktualizowano: {results.updateCount})
            <br />
            <ul>
              {results.okMessages &&
                results.okMessages.length > 0 &&
                results.okMessages?.map((e, i) => (
                  <li key={i} dangerouslySetInnerHTML={{ __html: e }}></li>
                ))}
            </ul>
            {results.errorCount > 0 && (
              <>
                <b>Nie zaimportowanych pozycji: {results.errorCount} </b>
                <br />
                Błędy: <br />
                <ul>
                  {results.errorMessages && results.errorMessages.length > 0 ? (
                    results.errorMessages?.map((e, i) => (
                      <li key={i} dangerouslySetInnerHTML={{ __html: e }}></li>
                    ))
                  ) : (
                    <li>Brak błędów</li>
                  )}
                </ul>
              </>
            )}
          </>
        )}
      </Alert>
    </>
  );
};
