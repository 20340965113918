import { GridRecord } from '../../../common/components/Grid/GridDataTypes';
import numberFormatter from '../../../common/numberFormatter';
import { GetEstimateByMonthWithItemsDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';
import momencik from '../../../common/momencik';
import { ProjectEstimatesStatusEnumText } from '../../../common/pokCore/validation/schemas';

export const getItemsGridData = (items: GetEstimateByMonthWithItemsDto[]) => {
  const data: GridRecord[] = [];

  for (const d of items) {
    let salesNet = 0;
    let salesCommissionAmount = 0;
    let salesNetTotal = 0;
    let salesInvoicesSum = 0;
    let purchaseNetTotal = 0;
    let purchaseInvoicesSum = 0;
    let purchaseAmountToAccept = 0;

    d.estimateItems?.map(rec => {
      salesNet = mathUtils.add(salesNet, mathUtils.round(rec.salesNet));
      salesCommissionAmount = mathUtils.add(
        salesCommissionAmount,
        mathUtils.round(rec.salesCommissionAmount),
      );
      salesNetTotal = mathUtils.add(
        salesNetTotal,
        mathUtils.round(rec.salesNetTotal),
      );
      salesInvoicesSum = mathUtils.add(
        salesInvoicesSum,
        mathUtils.round(rec.salesInvoicesSum),
      );
      purchaseNetTotal = mathUtils.add(
        purchaseNetTotal,
        mathUtils.round(rec.purchaseNetTotal),
      );
      purchaseInvoicesSum = mathUtils.add(
        purchaseInvoicesSum,
        mathUtils.round(rec.purchaseInvoicesSum),
      );
      purchaseAmountToAccept = mathUtils.add(
        purchaseAmountToAccept,
        mathUtils.round(rec.salesNet),
      );
    });

    data.push({
      key: d.id,
      values: {
        profile: d.profile?.name,
        month: momencik(d.date, 'YYYY-MM'),
        salesNet: numberFormatter(salesNet, true),
        salesCommissionAmount: numberFormatter(salesCommissionAmount, true),
        salesNetTotal: numberFormatter(salesNetTotal, true),
        salesInvoicesSum: numberFormatter(salesInvoicesSum, true),
        purchaseNetTotal: numberFormatter(purchaseNetTotal, true),
        purchaseInvoicesSum: numberFormatter(purchaseInvoicesSum, true),
        purchaseAmountToAccept: numberFormatter(
          mathUtils.subtract(purchaseNetTotal, purchaseInvoicesSum),
          true,
        ),
        margin: numberFormatter(
          mathUtils.subtract(salesNetTotal, purchaseNetTotal),
          true,
        ),
        status: ProjectEstimatesStatusEnumText[d.status],
        salesLock: d.isSalesBlocked ? 'TAK' : 'NIE',
        purchaseLock: d.isPurchaseBlocked ? 'TAK' : 'NIE',
      },
    });
  }
  return data;
};

export const getItemsGridSummary = (data: GetEstimateByMonthWithItemsDto[]) => {
  let salesNet = 0;
  let salesCommissionAmount = 0;
  let salesNetTotal = 0;
  let salesInvoicesSum = 0;
  let purchaseNetTotal = 0;
  let purchaseInvoicesSum = 0;
  let purchaseAmountToAccept = 0;

  data.map(month => {
    month.estimateItems?.map(item => {
      salesNet = mathUtils.add(salesNet, mathUtils.round(item.salesNet));
      salesCommissionAmount = mathUtils.add(
        salesCommissionAmount,
        mathUtils.round(item.salesCommissionAmount),
      );
      salesNetTotal = mathUtils.add(
        salesNetTotal,
        mathUtils.round(item.salesNetTotal),
      );
      salesInvoicesSum = mathUtils.add(
        salesInvoicesSum,
        mathUtils.round(item.salesInvoicesSum),
      );
      purchaseNetTotal = mathUtils.add(
        purchaseNetTotal,
        mathUtils.round(item.purchaseNetTotal),
      );
      purchaseInvoicesSum = mathUtils.add(
        purchaseInvoicesSum,
        mathUtils.round(item.purchaseInvoicesSum),
      );
      purchaseAmountToAccept = mathUtils.add(
        purchaseAmountToAccept,
        mathUtils.round(item.salesNet),
      );
    });
  });

  return {
    key: 'summary',
    values: {
      profile: 'SUMA',
      month: '',
      salesNet: numberFormatter(salesNet, true),
      salesCommissionAmount: numberFormatter(salesCommissionAmount, true),
      salesNetTotal: numberFormatter(salesNetTotal, true),
      salesInvoicesSum: numberFormatter(salesInvoicesSum, true),
      purchaseNetTotal: numberFormatter(purchaseNetTotal, true),
      purchaseInvoicesSum: numberFormatter(purchaseInvoicesSum, true),
      purchaseAmountToAccept: numberFormatter(
        mathUtils.subtract(purchaseNetTotal, purchaseInvoicesSum),
        true,
      ),
      margin: numberFormatter(
        mathUtils.subtract(salesNetTotal, purchaseNetTotal),
        true,
      ),
    },
  };
};
