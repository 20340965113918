import { Option } from '../../../pok/components/Selects/Selector';
import { teamForGrid } from '../../../pok/components/Teams/TeamSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateTeamDto,
  CreateUpdateTeamDtoFromJSON,
  DefaultApi,
  GetTeamDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { TeamSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface TeamContextInterface {
  getById: (id: string) => Promise<GetTeamDto>;
  getAllByCompanyIds: (companyIds: string[]) => Promise<GetTeamDto[]>;
  getMyForPermissionByCompanyIds: (
    companyIds: string[],
    permission: string,
  ) => Promise<GetTeamDto[]>;
  getAllActiveByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    companyId?: string,
  ) => Promise<GridRecord[]>;
  getAllByProjectId: (projectId: string) => Promise<GetTeamDto[]>;
  getAllActiveCount: (filterText?: string) => Promise<number>;
  getAllCount: (filterText?: string, companyId?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateTeamDto) => Promise<void>;
  create: (dto: CreateUpdateTeamDto) => Promise<GetTeamDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  paeTeam: () => Promise<boolean>;
  getAllActive: (text?: string) => Promise<Option[]>;
}

export const TeamContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.teamControllerGet(id),
    getAllByCompanyIds: async (companyIds: string[]) =>
      api
        .teamControllerGetAllByCompanyIds(companyIds)
        .then(team => team.sort((a, b) => a.name.localeCompare(b.name, 'pl'))),
    getMyForPermissionByCompanyIds: async (
      companyIds: string[],
      permission: string,
    ) =>
      api
        .teamControllerGetMyForPermissionByCompanyIds(companyIds, permission)
        .then(team => team.sort((a, b) => a.name.localeCompare(b.name, 'pl'))),
    getAllActiveByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.teamControllerGetAllActiveByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(teamForGrid);
    },
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.teamControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(teamForGrid);
    },
    getAllByProjectId: (projectId: string) =>
      api.teamControllerGetAllByProjectId(projectId),
    getAllActiveCount: (filterText?: string) =>
      api.teamControllerGetAllActiveCount(filterText || ''),
    getAllCount: (filterText?: string) =>
      api.teamControllerGetAllCount(filterText || ''),
    update: (id: string, dto: CreateUpdateTeamDto) =>
      api.teamControllerUpdate(id, dto),
    create: (dto: CreateUpdateTeamDto) => api.teamControllerCreate(dto),
    getLastEditor: (id: string) => api.teamControllerGetLastEditor(id),
    paeTeam: () =>
      api
        .teamControllerPaeTeam()
        .then(isPae => (isPae as unknown as string) === 'true'),
    getAllActive: async (filterText?: string) => {
      const data = await api.teamControllerGetAllActive(filterText || '');
      data.sort((a, b) => a.name.localeCompare(b.name, 'pl'));

      return Array.from(data, p => ({
        value: p.id,
        label: `${p.name} (${p.company?.name})`,
      }));
    },
  };
};

export const newTeam = (companyId?: string) => {
  return CreateUpdateTeamDtoFromJSON({
    companyId: companyId,
  });
};

export const validate = (dictionary: CreateUpdateTeamDto) =>
  validateAgainst(TeamSchema, dictionary);

export const convert = (team?: GetTeamDto) => {
  if (!team) {
    return newTeam();
  }
  const converted = CreateUpdateTeamDtoFromJSON(team);
  converted.companyId = team.company.id;
  converted.managerId = team.manager.id;
  return converted;
};
