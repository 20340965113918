import React from 'react';
import { Card } from 'react-bootstrap';

import { PositionTab } from '../../components/Positions/PositionTab';

export const AllPositionsView: React.FC = () => (
  <Card className="main-card">
    <Card.Body className="m-3">
      <Card.Title className="pb-3">Pozycje profili</Card.Title>
      <PositionTab />
    </Card.Body>
  </Card>
);
