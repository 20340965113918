/*eslint complexity: ["error", 12]*/
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateOrderExecutionDto,
  GetOrderExecutionDtoStatusEnum,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { OrderExecutionStatusEnum } from '../../../common/pokCore/validation/schemas';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useAuth } from '../../../common/hooks/useAuth';

import { OrderExecutionForm } from './OrderExecutionForm';

interface OrderExecutionEditorProps {
  id?: string;
  systemNumber?: number;
  project: GetProjectDto;
  orderExecution: CreateUpdateOrderExecutionDto;
  handleClose?: () => void;
  readOnly?: boolean;
  show?: boolean;
  deactivateOrderExecution: (id: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  status?: GetOrderExecutionDtoStatusEnum;
  rejectedNote?: string;
  onCompleted: (
    order: CreateUpdateOrderExecutionDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const OrderExecutionEditor: React.FC<
  OrderExecutionEditorProps
> = props => {
  const [orderExecution, setOrderExecution] = useState(props.orderExecution);
  const [emails, setEmails] = useState(props.orderExecution.emails || []);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const notifications = useNotifications();
  const pok = usePokCore();
  const auth = useAuth();

  const authorized = props.orderExecution?.id
    ? auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  const createOrderExecution = async () => {
    setIsLoadingSave(true);
    props
      .onCompleted({ ...orderExecution, emails }, orderExecution.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingSave(false));
  };

  const deactivateOrderExecution = async () => {
    setIsLoadingRemove(true);
    props
      .deactivateOrderExecution(orderExecution.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingRemove(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateOrderExecutionDto>) => {
    setOrderExecution({ ...orderExecution, ...obj });
  };

  useEffect(() => {
    setEmails(props.orderExecution.emails);
  }, [props.orderExecution.emails]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setOrderExecution(props.orderExecution);
    }
  }, [props.orderExecution, props.show]);

  const handleDownload = () => {
    if (props.orderExecution) {
      pok.pdfPrinters
        .downloadOrderExecution(orderExecution.id!)
        .catch(async err => {
          setSaveErrors(await responseErrors(err as ResponseError));
          setShowSaveErrors(true);
        });
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${
                  props.readOnly ? 'Szczegóły' : 'Edycja'
                } realizacji zamówienia (nr sys. ${props?.systemNumber})`
              : 'Utwórz realizację zamówienia'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderExecutionForm
            id={props.id}
            orderExecution={orderExecution}
            propertyChange={propertyChange}
            setEmails={mail => {
              setEmails(mail);
            }}
            emails={emails}
            readOnly={props.readOnly}
            project={props.project}
            status={props.status}
            rejectedNote={props.rejectedNote}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-items-center w-100">
            {props.id && (
              <LastEditorInfo
                id={props.id}
                className="color-primary"
                method={pok.ordersExecution.getLastEditor}
              />
            )}
            <div className="d-flex marginLeft gap-2">
              {props.readOnly &&
                props.status === OrderExecutionStatusEnum.Accepted && (
                  <Button onClick={handleDownload}>
                    Pobierz potwierdzenie
                  </Button>
                )}
              {(authorized || (orderExecution.id && !props.readOnly)) && (
                <ConfirmationButton
                  onOK={deactivateOrderExecution}
                  confirmation="Czy na pewno chcesz usunąć realizację zamówienia?"
                  variant="outline-danger"
                  isLoading={isLoadingRemove}
                >
                  Usuń
                </ConfirmationButton>
              )}
              <Button variant="outline-secondary" onClick={props.handleClose}>
                Zamknij
              </Button>
              {!props.readOnly && (
                <Button
                  variant="primary"
                  onClick={createOrderExecution}
                  isLoading={isLoadingSave}
                >
                  Zapisz
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
