import React, { useEffect, useState } from 'react';
import * as Icons from 'react-bootstrap-icons';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { CreateUpdateEmployeeRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import CompanySelector from '../Selects/CompanySelector';
import TeamSelector from '../Selects/TeamSelector';
import BudgetSelector from '../Selects/BudgetSelector';
import { Option } from '../Selects/Selector';
import RoleSelector from '../Selects/RoleSelector';

export const EmployeeRole = (props: {
  value: CreateUpdateEmployeeRoleDto;
  onUpdate: (dto: CreateUpdateEmployeeRoleDto) => void;
  onDelete: () => void;
}) => {
  const [cuDto, setCuDto] = useState(props.value);

  const propertyChange = (obj: Partial<CreateUpdateEmployeeRoleDto>) => {
    const newValue = { ...cuDto, ...obj };
    props.onUpdate(newValue);
  };

  useEffect(() => {
    setCuDto(props.value);
  }, [props.value]);

  return (
    <tr className="align-middle">
      <td>
        <RoleSelector
          value={cuDto.roleId}
          onChange={option =>
            propertyChange({ roleId: (option as Option).value })
          }
        />
      </td>
      <td>
        <CompanySelector
          value={cuDto.companyId}
          onChange={option => {
            propertyChange({
              companyId: (option as Option)?.value,
            });
          }}
          clearable
        />
      </td>
      <td>
        <TeamSelector
          value={cuDto.teamId}
          companyId={cuDto.companyId}
          onChange={option => {
            option
              ? propertyChange({ teamId: (option as Option)?.value })
              : null;
          }}
          clearable
        />
      </td>
      <td>
        <BudgetSelector
          value={cuDto?.budgetId}
          onChange={option => {
            option
              ? propertyChange({ budgetId: (option as Option)?.value })
              : null;
          }}
          clearable
        />
      </td>
      <td className="text-center align-middle">
        <ConfirmationButton
          confirmation="Czy na pewno usunąć użytkownikowi rolę?"
          onOK={props.onDelete}
          variant="outline-danger"
        >
          <Icons.Trash />
        </ConfirmationButton>
      </td>
    </tr>
  );
};
