import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  CreateUpdateSpecialInvoicingDto,
  DecisionSpecialInvoicingDto,
  DecisionSpecialInvoicingDtoFromJSON,
  GetProjectDto,
  GetSpecialInvoicingDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  convert,
  convertDecision,
  newSpecialInvoicing,
} from '../../../common/pokCore/contexts/SpecialInvoicingContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { SpecialInvoicingStatus } from '../../../common/pokCore/validation/schemas';

type ParamTypes = {
  specialInvoicingId: string;
};

export interface UseSpecialInvoicingDataReturn {
  id: string;
  specialInvoicing?: GetSpecialInvoicingDto;
  decisionSpecialInvoicing: DecisionSpecialInvoicingDto;
  convertedSpecialInvoicing: CreateUpdateSpecialInvoicingDto;
  propertyChange: (obj: Partial<CreateUpdateSpecialInvoicingDto>) => void;
  propertyChangeDecision: (obj: Partial<DecisionSpecialInvoicingDto>) => void;
  projects: GetProjectDto[];
  setProjects: (projects: GetProjectDto[]) => void;
  handleRefresh: () => void;
  readOnly: boolean;
  isLoading: boolean;
}

function useSpecialInvoicingData(): UseSpecialInvoicingDataReturn {
  const { specialInvoicingId } = useParams<ParamTypes>() as {
    specialInvoicingId: string;
  };
  const pok = usePokCore();
  const notifications = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<GetProjectDto[]>([]);
  const [specialInvoicing, setSpecialInvoicing] =
    useState<GetSpecialInvoicingDto>();
  const [convertedSpecialInvoicing, setConvertedSpecialInvoicing] =
    useState<CreateUpdateSpecialInvoicingDto>(newSpecialInvoicing());
  const [decisionSpecialInvoicing, setDecisionSpecialInvoicing] =
    useState<DecisionSpecialInvoicingDto>(
      DecisionSpecialInvoicingDtoFromJSON({}),
    );
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const propertyChange = (obj: Partial<CreateUpdateSpecialInvoicingDto>) =>
    setConvertedSpecialInvoicing({ ...convertedSpecialInvoicing, ...obj });

  const propertyChangeDecision = (obj: Partial<DecisionSpecialInvoicingDto>) =>
    setDecisionSpecialInvoicing({ ...decisionSpecialInvoicing, ...obj });

  const fillProjects = (ids: string[]) =>
    Promise.all(ids.map(id => pok.projects.getById(id)))
      .then(fetchedProjects => {
        setProjects(fetchedProjects);
      })
      .catch(err => {
        notifications.caughtError(err);
      });

  const initSpecialInvoicing = () => {
    if (specialInvoicingId !== 'utworz') {
      setIsLoading(true);
      pok.specialInvoicing
        .getById(specialInvoicingId)
        .then(sI => {
          const convertedSI = convert(sI);
          setSpecialInvoicing(sI);
          setConvertedSpecialInvoicing(convertedSI);
          setDecisionSpecialInvoicing(convertDecision(sI));
          return convertedSI.projectIds;
        })
        .then(fillProjects)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoading(false));
    } else {
      if (projectId) {
        setIsLoading(true);
        pok.projects
          .getById(projectId)
          .then(project => {
            propertyChange({
              projectIds: [project.id],
            });
            setConvertedSpecialInvoicing({
              ...convert(undefined, pok.getCompanyId()),
              projectIds: [project.id],
            });
            setProjects([project]);
          })
          .catch(errorResponse => {
            notifications.caughtError(errorResponse);
          })
          .finally(() => setIsLoading(false));
      } else {
        setConvertedSpecialInvoicing(convert(undefined, pok.getCompanyId()));
      }
    }
  };

  useEffect(() => {
    initSpecialInvoicing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialInvoicingId, projectId]);

  return {
    id: specialInvoicingId,
    specialInvoicing,
    convertedSpecialInvoicing,
    decisionSpecialInvoicing,
    projects,
    setProjects,
    propertyChange,
    propertyChangeDecision,
    readOnly:
      (!specialInvoicing?.active ||
        [
          SpecialInvoicingStatus.Rejected,
          SpecialInvoicingStatus.Accepted,
        ].includes(specialInvoicing?.status as SpecialInvoicingStatus)) &&
      specialInvoicingId !== 'utworz',
    isLoading,
    handleRefresh: initSpecialInvoicing,
  };
}

export default useSpecialInvoicingData;
