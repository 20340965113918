import React, { useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';

import {
  CreateUpdateProfileDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import { Option } from '../Selects/Selector';
import TeamSelector from '../Selects/TeamSelector';
import { nameof } from '../../../utils/objectUtils';
import { SchemaItemInput } from '../SchemaItemInput/SchemaItemInput';

interface ProfileEditorFormProps {
  id?: string;
  profile: CreateUpdateProfileDto;
  propertyChange: (obj: Partial<CreateUpdateProfileDto>) => void;
}

const PROFILE_SCHEMA_ITEMS = [
  {
    id: 'sale',
    title: 'Sprzedaż',
    items: [
      {
        key: nameof<ProfileSchemaDto>('rcSalesTotal'),
        label: 'RC sprzedaż total',
      },
      {
        key: nameof<ProfileSchemaDto>('salesSurchargePercent'),
        label: '% Dopłata sprzedaż',
      },
      {
        key: nameof<ProfileSchemaDto>('salesDiscountPercent'),
        label: '% Rabat sprzedaż',
      },
      { key: nameof<ProfileSchemaDto>('salesNet'), label: 'Netto sprzedaż' },
      {
        key: nameof<ProfileSchemaDto>('salesCommissionPercent'),
        label: '% Prowizja sprzedaż',
      },
      {
        key: nameof<ProfileSchemaDto>('salesCommissionAmount'),
        label: 'Prowizja sprzedaż kwota',
      },
      {
        key: nameof<ProfileSchemaDto>('salesNetTechnicalCost'),
        label: 'Netto sprzedaż koszt techniczny',
      },
      {
        key: nameof<ProfileSchemaDto>('salesNetTotal'),
        label: 'Netto sprzedaż total',
      },
    ],
  },
  {
    id: 'buy',
    title: 'Zakup',
    items: [
      { key: nameof<ProfileSchemaDto>('rcPurchase'), label: 'RC zakup' },
      {
        key: nameof<ProfileSchemaDto>('purchaseSurchargePercent'),
        label: '% Dopłata zakup',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseDiscountPercent'),
        label: '% Rabat zakup',
      },
      { key: nameof<ProfileSchemaDto>('purchaseNet'), label: 'Netto zakup' },
      {
        key: nameof<ProfileSchemaDto>('purchaseTechnicalCost'),
        label: 'Netto zakup koszt techniczny',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseNetTotal'),
        label: 'Netto zakup total',
      },
    ],
  },
  {
    id: 'media',
    title: 'Media',
    items: [
      {
        key: nameof<ProfileSchemaDto>('numberOfMedia'),
        label: 'Liczba nośników',
      },
      {
        key: nameof<ProfileSchemaDto>('technologyDictionary'),
        label: 'Technologia (słownik)',
      },
      {
        key: nameof<ProfileSchemaDto>('regionDictionary'),
        label: 'Rejon (słownik)',
      },
      {
        key: nameof<ProfileSchemaDto>('additionalDescription'),
        label: 'Dodatkowy opis',
      },
    ],
  },
  {
    id: 'format',
    title: 'Format',
    items: [
      {
        key: nameof<ProfileSchemaDto>('formatRadio'),
        label: 'Format (radio)',
      },
      {
        key: nameof<ProfileSchemaDto>('formatPress'),
        label: 'Format (prasa)',
      },
      {
        key: nameof<ProfileSchemaDto>('formatInternet'),
        label: 'Format (internet)',
      },
      {
        key: nameof<ProfileSchemaDto>('formatInternetST'),
        label: 'Format (internet ST)',
      },
    ],
  },
  {
    id: 'category',
    title: 'Kategoria',
    items: [
      {
        key: nameof<ProfileSchemaDto>('categoryInternet'),
        label: 'Kategoria (internet)',
      },
      {
        key: nameof<ProfileSchemaDto>('categoryInternetST'),
        label: 'Kategoria (internet ST)',
      },
      {
        key: nameof<ProfileSchemaDto>('categoryLabcon'),
        label: 'Kategoria (Labcon)',
      },
    ],
  },
  {
    id: 'purchase',
    title: 'Forma zakupu',
    items: [
      {
        key: nameof<ProfileSchemaDto>('purchaseFormInternet'),
        label: 'Forma zakupu (internet)',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseFormInternetST'),
        label: 'Forma zakupu (internet ST)',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseMethod'),
        label: 'Sposób zakupu (TV)',
      },
    ],
  },
  {
    id: 'emissionType',
    title: 'Typ emisji',
    items: [
      {
        key: nameof<ProfileSchemaDto>('emissionTypeRadio'),
        label: 'Typ emisji (radio)',
      },
      {
        key: nameof<ProfileSchemaDto>('emissionTypeCinema'),
        label: 'Typ emisji (kino)',
      },
    ],
  },
  {
    id: 'service',
    title: 'Usługa',
    items: [
      {
        key: nameof<ProfileSchemaDto>('service'),
        label: 'Usługa',
      },
      {
        key: nameof<ProfileSchemaDto>('action'),
        label: 'Działanie',
      },
      {
        key: nameof<ProfileSchemaDto>('actionGroup'),
        label: 'Grupa działań',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceExternalWorkPercent'),
        label: '% Prace zewnętrzne',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceCreationPercent'),
        label: '% Kreacja',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceChangeProductionPercent'),
        label: '% Produkcja Change',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceStrategyPercent'),
        label: '% Strategia',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceProductionBroadcastPercent'),
        label: '% Produkcja broadcast',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceClientServicePercent'),
        label: '% Client service',
      },
    ],
  },
  {
    id: 'additionalInformation',
    title: 'Inne',
    items: [
      {
        key: nameof<ProfileSchemaDto>('isUpSell'),
        label: 'Czy upsell',
      },
      {
        key: nameof<ProfileSchemaDto>('netCustomerAccount'),
        label: 'Netto konto klienta',
      },
      {
        key: nameof<ProfileSchemaDto>('copySize'),
        label: 'Długość kopii',
      },
      {
        key: nameof<ProfileSchemaDto>('orderNumber'),
        label: 'Numer zlecenia',
      },
      {
        key: nameof<ProfileSchemaDto>('medium'),
        label: 'Medium (zagraniczne)',
      },
      {
        key: nameof<ProfileSchemaDto>('ownName'),
        label: 'Nazwa własna',
      },
      {
        key: nameof<ProfileSchemaDto>('labconChannel'),
        label: 'Kanał Labcon',
      },
    ],
  },
];

export const ProfileEditorForm: React.FC<ProfileEditorFormProps> = props => {
  const [activeTab, setActiveTab] = useState(PROFILE_SCHEMA_ITEMS[0].id);
  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="name" label="Nazwa">
        <Form.Control
          type="text"
          value={props.profile.name || ''}
          onChange={e => props.propertyChange({ name: e.target.value })}
        />
      </FormRow>
      <FormRow controlId="companyIds" label="Spółki">
        <CompanySelector
          isMulti={true}
          value={props.profile.companyIds}
          onChange={options => {
            props.propertyChange({
              companyIds: options
                ? (options as Option[])?.map(({ value }) => value) || []
                : [],
            });
          }}
        />
      </FormRow>
      <FormRow controlId="teamIds" label="Zespoły">
        <TeamSelector
          isMulti={true}
          value={props.profile.teamIds}
          companyIds={props.profile.companyIds}
          onChange={options => {
            props.propertyChange({
              teamIds: options
                ? (options as Option[]).map(({ value }) => value)
                : [],
            });
          }}
        />
      </FormRow>
      <FormRow controlId="relatedTeams" label="Zespoły powiązane">
        <TeamSelector
          isMulti={true}
          value={props.profile.relatedTeamIds}
          companyIds={props.profile.companyIds}
          onChange={options => {
            props.propertyChange({
              relatedTeamIds: options
                ? (options as Option[]).map(({ value }) => value)
                : [],
            });
          }}
        />
      </FormRow>
      <FormRow controlId="active" label="Prowizje">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.commission}
          onChange={e => props.propertyChange({ commission: e.target.checked })}
        />
      </FormRow>
      <FormRow controlId="tv" label="TV">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.tv ?? false}
          onChange={e => props.propertyChange({ tv: e.target.checked })}
        />
      </FormRow>
      <FormRow controlId="tvBuying" label="TV zakup">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.tvBuying ?? false}
          onChange={e => props.propertyChange({ tvBuying: e.target.checked })}
        />
      </FormRow>
      <FormRow
        controlId="salesBlockDisabled"
        label="Wyłączona auto blokada sprzedaży"
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.autoSalesBlockDisabled ?? false}
          onChange={e =>
            props.propertyChange({ autoSalesBlockDisabled: e.target.checked })
          }
        />
      </FormRow>
      <FormRow controlId="active" label="Aktywny">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
      <Tabs
        activeKey={activeTab}
        id={props.id}
        onSelect={tab => setActiveTab(tab as string)}
      >
        {props.profile.schema &&
          PROFILE_SCHEMA_ITEMS.map(({ id, title, items }) => (
            <Tab key={id} eventKey={id} title={title} className="m-3">
              <div className="d-grid gap-4 mb-3">
                {items.map(item => (
                  <SchemaItemInput
                    key={item.key}
                    id={item.key}
                    label={item.label}
                    value={props.profile.schema[item.key]}
                    onChange={data =>
                      props.propertyChange({
                        schema: {
                          ...props.profile.schema,
                          [item.key]: data,
                        },
                      })
                    }
                  />
                ))}
              </div>
            </Tab>
          ))}
      </Tabs>
    </Form>
  );
};
