/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetSalesInvoiceDto } from './GetSalesInvoiceDto';
import {
    GetSalesInvoiceDtoFromJSON,
    GetSalesInvoiceDtoFromJSONTyped,
    GetSalesInvoiceDtoToJSON,
} from './GetSalesInvoiceDto';

/**
 * 
 * @export
 * @interface GetSalesInvoiceForItem
 */
export interface GetSalesInvoiceForItem {
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceForItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoiceForItem
     */
    amount: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetSalesInvoiceForItem
     */
    owner: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetSalesInvoiceForItem
     */
    ts: Date;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetSalesInvoiceForItem
     */
    salesInvoice: GetSalesInvoiceDto;
}

/**
 * Check if a given object implements the GetSalesInvoiceForItem interface.
 */
export function instanceOfGetSalesInvoiceForItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "ts" in value;
    isInstance = isInstance && "salesInvoice" in value;

    return isInstance;
}

export function GetSalesInvoiceForItemFromJSON(json: any): GetSalesInvoiceForItem {
    return GetSalesInvoiceForItemFromJSONTyped(json, false);
}

export function GetSalesInvoiceForItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalesInvoiceForItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'owner': GetEmployeeDtoFromJSON(json['owner']),
        'ts': (new Date(json['ts'])),
        'salesInvoice': GetSalesInvoiceDtoFromJSON(json['salesInvoice']),
    };
}

export function GetSalesInvoiceForItemToJSON(value?: GetSalesInvoiceForItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'owner': GetEmployeeDtoToJSON(value.owner),
        'ts': (value.ts.toISOString()),
        'salesInvoice': GetSalesInvoiceDtoToJSON(value.salesInvoice),
    };
}

