/*eslint max-lines-per-function: ["error", 230]*/
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { useNavigation } from '../../../common/navigation';

export const ImportView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);

  const getClientsFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addClientsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getBudgetsFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addBudgetsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getTeamsFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addTeamsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getPurchasersFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addPurchasersFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getPositionsFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addPositionsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getBrandsFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addBrandsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getEmployeeRolesFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addEmployeeRolesFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getBankAccountFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addBankAccountsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getProjectsFromExcel = async () => {
    setWaiting(true);
    pok.imports
      .addProjectsFromExcel()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Import danych z plików Excel</Card.Title>
        {waiting && <Waiting />}
        {!waiting && (
          <div className="d-grid gap-2">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={getClientsFromExcel}
            >
              Importuj kontrahentów - wymaga wykomentowania w Entity
            </Button>
            <Button
              variant="outline-secondary"
              size="lg"
              onClick={getBudgetsFromExcel}
            >
              Importuj budżety - wymaga wykomentowania w Entity
            </Button>
            <Button
              variant="outline-success"
              size="lg"
              onClick={getTeamsFromExcel}
            >
              Importuj zespoły - wymaga wykomentowania w Entity
            </Button>
            <Button
              variant="outline-warning"
              size="lg"
              onClick={getPurchasersFromExcel}
            >
              Importuj zamawiających - wymaga wykomentowania w Entity
            </Button>
            <Button
              variant="outline-danger"
              size="lg"
              onClick={getPositionsFromExcel}
            >
              Importuj pozycje profili - wymaga wykomentowania w Entity
            </Button>
            <Button
              variant="outline-info"
              size="lg"
              onClick={getBrandsFromExcel}
            >
              Importuj brandy - wymaga wykomentowania w Entity
            </Button>
            <Button
              variant="outline-dark"
              size="lg"
              onClick={getEmployeeRolesFromExcel}
            >
              Importuj role pracowników (nie można powtarzać)
            </Button>
            <Button
              variant="outline-primary"
              size="lg"
              onClick={getBankAccountFromExcel}
            >
              Importuj konta bankowe (aktualizacja de facto nie istnieje - można
              wyciąć wszystko i wgrać ponownie)
            </Button>
            <Button
              variant="outline-secondary"
              size="lg"
              onClick={getProjectsFromExcel}
            >
              Importuj kampanie (główki)
            </Button>
            <Button
              variant="outline-dark"
              size="lg"
              onClick={() => nav.importAttachments()}
            >
              Importuj załączniki do kampanii
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
