/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePurchaseInvoiceTeamsAndProjectsDto
 */
export interface CreateUpdatePurchaseInvoiceTeamsAndProjectsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePurchaseInvoiceTeamsAndProjectsDto
     */
    teamIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePurchaseInvoiceTeamsAndProjectsDto
     */
    projectIds: Array<string>;
}

/**
 * Check if a given object implements the CreateUpdatePurchaseInvoiceTeamsAndProjectsDto interface.
 */
export function instanceOfCreateUpdatePurchaseInvoiceTeamsAndProjectsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teamIds" in value;
    isInstance = isInstance && "projectIds" in value;

    return isInstance;
}

export function CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSON(json: any): CreateUpdatePurchaseInvoiceTeamsAndProjectsDto {
    return CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSONTyped(json, false);
}

export function CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePurchaseInvoiceTeamsAndProjectsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamIds': json['teamIds'],
        'projectIds': json['projectIds'],
    };
}

export function CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoToJSON(value?: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamIds': value.teamIds,
        'projectIds': value.projectIds,
    };
}

