/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePublicMemoDto
 */
export interface CreatePublicMemoDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePublicMemoDto
     */
    enSubjectType: CreatePublicMemoDtoEnSubjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicMemoDto
     */
    subjectId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicMemoDto
     */
    content: string;
}


/**
 * @export
 */
export const CreatePublicMemoDtoEnSubjectTypeEnum = {
    Project: 'Project',
    PurchaseInvoice: 'PurchaseInvoice',
    SaleInvoice: 'SaleInvoice',
    SpecialInvoicing: 'SpecialInvoicing'
} as const;
export type CreatePublicMemoDtoEnSubjectTypeEnum = typeof CreatePublicMemoDtoEnSubjectTypeEnum[keyof typeof CreatePublicMemoDtoEnSubjectTypeEnum];


/**
 * Check if a given object implements the CreatePublicMemoDto interface.
 */
export function instanceOfCreatePublicMemoDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enSubjectType" in value;
    isInstance = isInstance && "subjectId" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function CreatePublicMemoDtoFromJSON(json: any): CreatePublicMemoDto {
    return CreatePublicMemoDtoFromJSONTyped(json, false);
}

export function CreatePublicMemoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePublicMemoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enSubjectType': json['enSubjectType'],
        'subjectId': json['subjectId'],
        'content': json['content'],
    };
}

export function CreatePublicMemoDtoToJSON(value?: CreatePublicMemoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enSubjectType': value.enSubjectType,
        'subjectId': value.subjectId,
        'content': value.content,
    };
}

