import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import {
  GetEstimateByMonthWithItemsDto,
  GetProjectDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';

import {
  getItemsGridData,
  getItemsGridSummary,
} from './ProjectEstimateItemsGridData';

interface ProjectEstimateItemsGridProps {
  data: GetEstimateByMonthWithItemsDto[];
  schema?: ProfileSchemaDto;
  project?: GetProjectDto;
}

export const ProjectEstimateItemsGrid: React.FC<
  ProjectEstimateItemsGridProps
> = props => {
  const columns: GridColumn[] = [
    { header: 'Profil', property: 'profile' },
    { header: 'Miesiąc', property: 'month' },
    { header: 'Netto sprzedaż', property: 'salesNet' },
    { header: 'Prowizja sprzedaż', property: 'salesCommissionAmount' },
    { header: 'Total sprzedaż', property: 'salesNetTotal' },
    { header: 'Suma FVS', property: 'salesInvoicesSum' },
    { header: 'Total Zakup', property: 'purchaseNetTotal' },
    { header: 'Suma FVZ', property: 'purchaseInvoicesSum' },
    { header: 'Różnica zakup', property: 'purchaseAmountToAccept' },
    ...(props.project?.company?.showMargin
      ? [{ header: 'Marża', property: 'margin' }]
      : []),
    { header: 'Status', property: 'status' },
    { header: 'Blokada sprzedaż', property: 'salesLock' },
    { header: 'Blokada zakup', property: 'purchaseLock' },
  ];

  return (
    <Grid
      data={getItemsGridData(props.data)}
      columns={columns}
      showFilter={false}
      pageSize={20}
      initialOrderColumn="month"
      initialOrderDirection="ASC"
      emptyGridMessage="Brak pozycji"
      summaryData={getItemsGridSummary(props.data)}
      trClassName="amounts-align-right font-90"
      withFilterInColumns={true}
    />
  );
};
