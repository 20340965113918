import * as projects from './projects';
import * as campaigns from './campaigns';

export const schema = projects;
export type TranslationSchema = typeof projects;

export const resources = {
  projects,
  campaigns,
} as const;
