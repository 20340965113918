import { DefaultApi, TokenDto } from '../autogenerated/pokApiClient';

export interface LoginContextInterface {
  login: (login: string, password: string) => Promise<TokenDto>;
  loginWithGoogle: (googleToken: string) => Promise<TokenDto>;
  refreshToken: (refreshToken: string) => Promise<TokenDto>;
}

export const LoginContext = (api: DefaultApi) => {
  return {
    login: (login: string, password: string) => {
      return api.loginControllerLogin({ login, password });
    },
    loginWithGoogle: (googleToken: string) => {
      return api.loginControllerLoginWithGoogle({ googleToken });
    },
    refreshToken: (refreshToken: string) =>
      api.loginControllerRefreshToken(refreshToken),
  };
};
