import React from 'react';
import { Alert } from 'react-bootstrap';

import FormSkeleton from '../../../app/components/FormSkeleton';

interface SalesInvoiceTabProps {
  component: React.ReactNode;
  error?: string;
  isLoading?: boolean;
}

export const SalesInvoiceTab: React.FC<SalesInvoiceTabProps> = props => {
  if (props.error) {
    return <Alert variant="danger">{props.error}</Alert>;
  }

  if (props.isLoading) {
    return <FormSkeleton />;
  }

  return props.component;
};
