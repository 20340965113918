/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUpdateSalesInvoicePositionDto } from './CreateUpdateSalesInvoicePositionDto';
import {
    CreateUpdateSalesInvoicePositionDtoFromJSON,
    CreateUpdateSalesInvoicePositionDtoFromJSONTyped,
    CreateUpdateSalesInvoicePositionDtoToJSON,
} from './CreateUpdateSalesInvoicePositionDto';

/**
 * 
 * @export
 * @interface CreateUpdateSalesInvoicePositionsDto
 */
export interface CreateUpdateSalesInvoicePositionsDto {
    /**
     * 
     * @type {Array<CreateUpdateSalesInvoicePositionDto>}
     * @memberof CreateUpdateSalesInvoicePositionsDto
     */
    positions: Array<CreateUpdateSalesInvoicePositionDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSalesInvoicePositionsDto
     */
    salesInvoiceId: string;
}

/**
 * Check if a given object implements the CreateUpdateSalesInvoicePositionsDto interface.
 */
export function instanceOfCreateUpdateSalesInvoicePositionsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "positions" in value;
    isInstance = isInstance && "salesInvoiceId" in value;

    return isInstance;
}

export function CreateUpdateSalesInvoicePositionsDtoFromJSON(json: any): CreateUpdateSalesInvoicePositionsDto {
    return CreateUpdateSalesInvoicePositionsDtoFromJSONTyped(json, false);
}

export function CreateUpdateSalesInvoicePositionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSalesInvoicePositionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positions': ((json['positions'] as Array<any>).map(CreateUpdateSalesInvoicePositionDtoFromJSON)),
        'salesInvoiceId': json['salesInvoiceId'],
    };
}

export function CreateUpdateSalesInvoicePositionsDtoToJSON(value?: CreateUpdateSalesInvoicePositionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'positions': ((value.positions as Array<any>).map(CreateUpdateSalesInvoicePositionDtoToJSON)),
        'salesInvoiceId': value.salesInvoiceId,
    };
}

