import React from 'react';

import { GetPurchaseInvoiceExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import numberFormatter from '../../../common/numberFormatter';
import CopyButton from '../../../common/components/CopyButton/CopyButton';

import { SumData } from './purchaseInvoicePositionsGridUtils';

interface PurchaseInvoiceEstimateItemPositionsGridProps {
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  sumData: SumData;
}

export const PurchaseInvoiceEstimateItemPositionsGridSum: React.FC<
  PurchaseInvoiceEstimateItemPositionsGridProps
> = props => {
  const {
    maxSum,
    notAssignedSum,
    purchaseNetTotalSum,
    amountSum,
    unsettledAmount,
  } = props.sumData;
  const isTV = props.extendedPurchaseInvoice?.tv;

  return (
    <>
      <tr>
        <td colSpan={2} className="bold">
          SUMA:
        </td>
        <td colSpan={isTV ? 3 : 2} />
        <td className="bold">{numberFormatter(purchaseNetTotalSum, true)}</td>
        <td className="bold">{numberFormatter(notAssignedSum, true)}</td>
        <td />
        <td className="bold">{numberFormatter(amountSum, true)}</td>
      </tr>
      <tr>
        <td colSpan={isTV ? 8 : 7} className="bold">
          KWOTA FAKTURY:
        </td>
        <td className="bold">{numberFormatter(maxSum, true)}</td>
      </tr>
      <tr>
        <td colSpan={isTV ? 8 : 7} className="bold align-middle">
          KWOTA NIEROZLICZONA:
        </td>
        <td className="bold">
          {numberFormatter(unsettledAmount, true)}
          <CopyButton value={numberFormatter(unsettledAmount)} />
        </td>
      </tr>
    </>
  );
};
