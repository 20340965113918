import React from 'react';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

import { isFieldHidden } from './CommissionService';

interface LabconProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const LabconProfileParams: React.FC<
  LabconProfileParamsProps
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.labconChannel &&
        !isFieldHidden(
          'labconChannel',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="labconChannel"
            label={schema?.labconChannel?.displayName}
          >
            <DictionarySelector
              dictionary="Labcon - kanał"
              value={props.estimateItem?.labconChannelId || ''}
              clearable={true}
              onChange={option =>
                propertyChange({
                  labconChannelId: option.value,
                })
              }
            />
          </FormRow>
        )}
      {schema?.categoryLabcon &&
        !isFieldHidden(
          'categoryLabcon',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="categoryLabcon"
            label={schema?.categoryLabcon?.displayName}
          >
            <DictionarySelector
              dictionary="Labcon - kategoria"
              value={props.estimateItem?.categoryLabconId || ''}
              clearable={true}
              onChange={option =>
                propertyChange({
                  categoryLabconId: option.value,
                })
              }
            />
          </FormRow>
        )}
    </>
  );
};
