import React from 'react';
import { NumericFormat } from 'react-number-format';

import mathUtils from '../../utils/mathUtils';

export default (props: {
  allowNegative?: boolean;
  value: string | number | null | undefined;
  suffix?: string;
  decimalScale?: number;
  noThousandSeparator?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (value: string | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  max?: number;
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      if (e.target.value === '') {
        props.onChange(null);
        return;
      }
      const formattedValues = e.target.value
        .replace(',', '.')
        .replace(/[^0-9.-]/g, '');
      if (
        formattedValues !== '-' &&
        ((!props.max && props.max !== 0) ||
          mathUtils.parse(formattedValues) <= props.max)
      ) {
        props.onChange(formattedValues);
      }
    }
  };

  return (
    <>
      <NumericFormat
        className={`form-control ${props.className ?? ''}`}
        value={props.value}
        suffix={props.suffix ?? ''}
        thousandSeparator={props.noThousandSeparator ? '' : ' '}
        decimalSeparator=","
        decimalScale={props.decimalScale ?? 4}
        disabled={props.disabled}
        onChange={e => onChange(e)}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        isAllowed={({ floatValue }) =>
          (!props.max && props.max !== 0) ||
          !floatValue ||
          floatValue <= props.max
        }
        allowNegative={props.allowNegative ?? false}
      />
    </>
  );
};
