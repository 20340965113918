import React from 'react';

import {
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';

import {
  getPurchaseInvoiceItem,
  getSavedItem,
} from './purchaseInvoicePositionsGridUtils';
import { TableColumn } from './PurchaseInvoiceEstimateItemPositionsGrid';

interface PurchaseInvoiceEstimateItemPositionsGridRowProps {
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[];
  availableEstimateItems: GetEstimateItemDto[];
  columns: TableColumn[];
  estimateItem: GetEstimateItemDto;
}

export const PurchaseInvoiceEstimateItemPositionsGridRow: React.FC<
  PurchaseInvoiceEstimateItemPositionsGridRowProps
> = props => {
  return (
    <tr key={props.estimateItem.id} className="align-middle">
      {props.columns
        .filter(({ isHidden }) => !isHidden)
        .map((col, index) => {
          const purchaseInvoiceEstimateItem = getPurchaseInvoiceItem(
            props.estimateItem.id,
            props.purchaseInvoiceEstimateItems,
          );
          const savedItem = getSavedItem(
            purchaseInvoiceEstimateItem,
            props.extendedPurchaseInvoice?.purchaseInvoiceEstimateItems,
          );

          return (
            <td key={`${props.estimateItem.id}-${col.header}-${index}`}>
              {col.renderCell(
                props.estimateItem,
                purchaseInvoiceEstimateItem,
                savedItem,
              )}
            </td>
          );
        })}
    </tr>
  );
};
