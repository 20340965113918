/*eslint max-lines-per-function: ["error", 200]*/
import React, { useMemo, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { SalesInvoiceSearch } from '../../components/SalesInvoices/SalesInvoiceSearch';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import AlertModal from '../../../app/components/AlertModal';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { useNavigation } from '../../../common/navigation';

export const ExportFVSToSymphonyView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState(false);
  const [info, setInfo] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [buttonTitle, setButtonTitle] = useState<string>(
    'Wyślij niewysłane FVS (wszystkie)',
  );
  const [isLoading, setIsLoading] = useState(false);

  const exportFVS = async () => {
    try {
      setIsLoading(true);
      await pok.symphonies.exportFSV({ selectedInvoices });
      setInfo(
        'Wysyłka faktur sprzedaży do tabel przejściowych Symfonia Handel została zakończona.',
      );
      setShowInfo(true);
      setShowSaveErrors(false);
      setRefresh(!refresh);
      setSelectedInvoices([]);
      setButtonTitle('Wyślij niewysłane FVS (wszystkie)');
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      setShowSaveErrors(true);
      setSaveErrors(await responseErrors(response as ResponseError));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnRowClick = (key: string) => {
    let si = [];

    if (!selectedInvoices.includes(key)) {
      si = [...selectedInvoices, key];
    } else {
      si = selectedInvoices.filter(o => o !== key);
    }

    setButtonTitle(
      si.length > 0
        ? `Wyślij niewysłane FVS (${si.length} - zaznaczone)`
        : `Wyślij niewysłane FVS (wszystkie)`,
    );
    setSelectedInvoices(si);
  };

  const handleOnRowSentClick = (
    salesInvoiceId: string,
    newWindow?: boolean,
  ) => {
    nav.salesInvoiceEditor({ salesInvoiceId, newWindow });
  };

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.salesInvoices.getAllSentToSymphonyByPortion(
          false,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.salesInvoices.getAllSentToSymphonyCount(
          false,
          filterText,
          filterColumns,
        )) as GridCountFunction,
    [pok],
  );

  const getSentData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.salesInvoices.getAllSentToSymphonyByPortion(
          true,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok],
  );

  const getSentCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.salesInvoices.getAllSentToSymphonyCount(
          true,
          filterText,
          filterColumns,
        )) as GridCountFunction,
    [pok],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          Eksport faktur sprzedaży do Symfonii Handel
        </Card.Title>
        <TabsHistory
          activeKey="invoicesToSent"
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey="invoicesToSent"
            title="FVS do wysłania"
            className="m-3"
          >
            <ConfirmationButton
              variant="outline-primary"
              className="mb-4 me-1"
              onOK={exportFVS}
              confirmation="Czy na pewno wysłać faktury do bazy przejściowej Symfonii Handel?"
              isLoading={isLoading}
            >
              {buttonTitle}
            </ConfirmationButton>

            <ValidationAlert
              show={showSaveErrors}
              errors={saveErrors}
              className="m-3"
            />
            <AlertModal
              variant="info"
              buttonText="Zamknij"
              buttonVariant="outline-success"
              size="lg"
              show={showInfo}
              onHide={() => {
                setShowInfo(false);
                setRefresh(!refresh);
              }}
            >
              {info}
            </AlertModal>
            <SalesInvoiceSearch
              getData={getData}
              getCount={getCount}
              refresh={refresh}
              gridMessage="Brak niewysłanych faktur sprzedaży do Symfonii Handel."
              enableMultiSelect={true}
              onRowClick={handleOnRowClick}
            />
          </Tab>
          <Tab eventKey="invoicesSent" title="FVS wysłane" className="m-3">
            <SalesInvoiceSearch
              getData={getSentData}
              getCount={getSentCount}
              refresh={refresh}
              gridMessage="Brak wysłanych faktur sprzedaży do Symfonii Handel."
              onRowClick={id => handleOnRowSentClick(id, true)}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
