// eslint-disable-next-line import/named
import i18next, { i18n as i18nType } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { resources } from './resources';

let i18n: i18nType;

export const initTranslations = (lng?: string) => {
  i18n = i18next.createInstance();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n.use(initReactI18next).init({
    debug: false,
    lng,
    fallbackLng: 'projects',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });
};

export const changeLang = (lng?: string) => {
  const language = lng ? lng.toLowerCase() : undefined;
  if (i18n.language !== language) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(language);
  }
};
