/*eslint complexity: ["error", 18]*/
import React from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';
import Select from 'react-select';

import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import { CreateUpdateExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import TeamSelector from '../Selects/TeamSelector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ProfileSelector from '../Selects/ProfileSelector';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useAuth } from '../../../common/hooks/useAuth';
import ProfileCategorySelector from '../Selects/ProfileCategorySelector';
import {
  ExcelImportTypeEnum,
  ExcelImportTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import TeamAllSelector from '../Selects/TeamAllSelector';
import QuillTextEditor from '../../../app/components/QuillTextEditor';

interface ExcelConfigFormProps {
  id?: string;
  refresh?: boolean;
  config: CreateUpdateExcelConfigDto;
  propertyChange?: (obj: Partial<CreateUpdateExcelConfigDto>) => void;
  readOnly?: boolean;
}

export const ExcelConfigForm: React.FC<ExcelConfigFormProps> = props => {
  const pok = usePokCore();
  const auth = useAuth();
  const getOptionValues = (
    options: Option | MultiValue<Option> | null | undefined,
  ) => (options ? (options as Option[]).map(({ value }) => value) : []);

  const getOptionValue = (
    options: Option | MultiValue<Option> | null | undefined,
  ) => (options ? (options as Option).value : undefined);

  const isAdmin = auth.check(PermissionsEnum.Administration);

  const propertyChange = (obj: Partial<CreateUpdateExcelConfigDto>) => {
    if (
      obj.description &&
      ['<p><br></p>', '<h1><br></h1>', '<h2><br></h2>'].includes(
        obj.description,
      )
    ) {
      obj.description = '';
    }
    props.propertyChange && props.propertyChange(obj);
  };

  const importOptions = Object.entries(ExcelImportTypeEnum).map(
    ([key, value]) => ({
      value: key as ExcelImportTypeEnum,
      label: ExcelImportTypeEnumText[value],
    }),
  );

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="name" label="Nazwa">
          <Form.Control
            type="text"
            value={props.config?.name || ''}
            onChange={e => propertyChange({ name: e.target.value })}
            disabled={props.readOnly}
          />
        </FormRow>
        <FormRow controlId="type" label="Rodzaj importu">
          <Select
            value={
              props.config.type
                ? importOptions.find(({ value }) => props.config.type === value)
                : null
            }
            options={importOptions}
            onChange={option =>
              propertyChange({
                type: option?.value,
              })
            }
            isDisabled={props.readOnly}
            placeholder="Wskaż..."
          />
        </FormRow>
        <FormRow controlId="team" label="Zespół">
          {props.config.companyIndependent && isAdmin ? (
            <TeamAllSelector
              onChange={options =>
                propertyChange({
                  excelConfigTeamsIds: getOptionValues(options),
                })
              }
              value={props.config.excelConfigTeamsIds || []}
              readOnly={props.readOnly}
            />
          ) : (
            <TeamSelector
              isMulti={true}
              value={props.config.excelConfigTeamsIds || []}
              companyId={pok.getCompanyId()}
              permission={PermissionsEnum.Projects}
              clearable={true}
              onChange={options => {
                propertyChange({
                  excelConfigTeamsIds: getOptionValues(options),
                });
              }}
              readOnly={props.readOnly}
            />
          )}
        </FormRow>
        <FormRow controlId="profile" label="Profil">
          <ProfileSelector
            value={props.config.profileId || ''}
            companyIds={[pok.getCompanyId()]}
            onChange={options => {
              propertyChange({
                profileId: getOptionValue(options),
              });
            }}
            readOnly={
              props.readOnly || !(props.config.excelConfigTeamsIds?.length > 0)
            }
          />
        </FormRow>
        {props.config.profileId && (
          <FormRow controlId="profileCat" label="Kategoria profilu">
            <ProfileCategorySelector
              profileId={props.config.profileId}
              value={props.config.profileCategoryId || ''}
              onChange={option =>
                propertyChange({
                  profileCategoryId: option?.value,
                })
              }
              autoSelect={false}
              readOnly={props.readOnly}
            />
          </FormRow>
        )}
        <FormRow controlId="description" label="Instrukcja importu">
          <QuillTextEditor
            value={props.config?.description}
            readOnly={props.readOnly}
            onChange={val => propertyChange({ description: val })}
          />
        </FormRow>
        <FormRow controlId="companyIndependent" label="Niezależna od spółki">
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.config.companyId ? false : true}
            onChange={e =>
              propertyChange({ companyIndependent: e.target.checked })
            }
            disabled={props.readOnly || !isAdmin}
          />
        </FormRow>
        <FormRow
          controlId="updateExisting"
          label="Aktualizacji istniejących pozycji"
        >
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.config.updateExisting ?? true}
            onChange={e => propertyChange({ updateExisting: e.target.checked })}
            disabled={props.readOnly}
          />
        </FormRow>
        <FormRow controlId="active" label="Aktywny">
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.config.active ?? true}
            onChange={e => propertyChange({ active: e.target.checked })}
            disabled={props.readOnly}
          />
        </FormRow>
      </Form>
    </>
  );
};
