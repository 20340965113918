import React, { useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../theme/theme.scss';
import '../theme/contrastDefault.scss';
import 'react-quill/dist/quill.snow.css';
import 'moment/locale/pl';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { CookiesProvider } from '../common/cookies';
import { AuthProvider } from '../common/auth';
import { PokCoreProvider } from '../common/pokCore';
import { initTranslations } from '../translations';

import { MyRoutes } from './containers/Routes';

function App() {
  useMemo(() => initTranslations('projects'), []);

  return (
    <AuthProvider>
      <PokCoreProvider>
        <CookiesProvider>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
          >
            <MyRoutes />
          </GoogleOAuthProvider>
        </CookiesProvider>
      </PokCoreProvider>
    </AuthProvider>
  );
}

export default App;
