import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';

import { Option, Selector } from './Selector';

export interface PositionSelectorProps {
  value?: string;
  teamIds: string[];
  profileId?: string;
  budgetId?: string;
  commission?: boolean;
  readOnly?: boolean;
  className?: string;
  onChange?: (option: Option) => void;
}

export default (props: PositionSelectorProps) => {
  const pok = usePokCore();
  const provider = async (text: string) => {
    if (props.profileId && props.commission === undefined) {
      return pok.positions.getOptionsByProfileIdTeamIdAndName(
        props.profileId,
        props.teamIds[0],
        text,
      );
    }

    return pok.positions.getOptionsByTeamIdsCommissionAndName(
      props.teamIds,
      props.profileId ?? '',
      props.commission ?? false,
      props.budgetId ?? '',
      text,
    );
  };

  return (
    <Selector
      uniqueKey={'positions-' + JSON.stringify(provider)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      provider={provider}
      deletedValueName={id => pok.positions.getOptionById(id)}
      value={props.value}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
