import React from 'react';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetPositionDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

import { isFieldHidden } from './CommissionService';

interface SalesParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  position?: GetPositionDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const SalesParams: React.FC<SalesParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.rcSalesTotal && (
        <FormRow
          controlId="rcSalesTotal"
          label={schema?.rcSalesTotal?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.rcSalesTotal}
            suffix=" zł"
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                rcSalesTotal: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.salesSurchargePercent && (
        <FormRow
          controlId="salesSurchargePercent"
          label={schema?.salesSurchargePercent?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.salesSurchargePercent}
            suffix=" %"
            decimalScale={20}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                salesSurchargePercent: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.salesDiscountPercent && (
        <FormRow
          controlId="salesDiscountPercent"
          label={schema?.salesDiscountPercent?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.salesDiscountPercent}
            suffix=" %"
            max={100}
            decimalScale={20}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                salesDiscountPercent: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
      {schema?.salesNet &&
        !isFieldHidden(
          'salesNet',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow controlId="salesNet" label={schema?.salesNet?.displayName}>
            <CurrencyControl
              value={props.estimateItem?.salesNet}
              suffix=" zł"
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  salesNet: value,
                })
              }
              allowNegative
            />
          </FormRow>
        )}
      {schema?.salesCommissionPercent &&
        !isFieldHidden(
          'salesCommissionPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="salesCommissionPercent"
            label={schema?.salesCommissionPercent?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.salesCommissionPercent}
              suffix=" %"
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  salesCommissionPercent: value,
                })
              }
              allowNegative
            />
          </FormRow>
        )}
      {schema?.salesCommissionAmount &&
        !isFieldHidden(
          'salesCommissionAmount',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <FormRow
            controlId="salesCommissionAmount"
            label={schema?.salesCommissionAmount?.displayName}
          >
            <CurrencyControl
              value={props.estimateItem?.salesCommissionAmount}
              suffix=" zł"
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  salesCommissionAmount: value,
                })
              }
              allowNegative
            />
          </FormRow>
        )}
      {schema?.salesNetTechnicalCost && (
        <FormRow
          controlId="salesNetTechnicalCost"
          label={schema?.salesNetTechnicalCost?.displayName}
        >
          <CurrencyControl
            value={props.estimateItem?.salesNetTechnicalCost}
            suffix=" zł"
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                salesNetTechnicalCost: value,
              })
            }
            allowNegative
          />
        </FormRow>
      )}
    </>
  );
};
