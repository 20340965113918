export class EstimateItemFields {
  static amountFieldsKeys = [
    { name: 'salesNetTotal', commission: false, transferredCommission: false },
    { name: 'rcSalesTotal', commission: false, transferredCommission: false },
    { name: 'salesNet', commission: false, transferredCommission: false },
    {
      name: 'salesNetTechnicalCost',
      commission: false,
      transferredCommission: false,
    },
    { name: 'salesNetTotal', commission: false, transferredCommission: false },
    {
      name: 'salesCommissionAmount',
      commission: true,
      transferredCommission: true,
    },
    { name: 'rcPurchase', commission: false, transferredCommission: false },
    {
      name: 'purchaseTechnicalCost',
      commission: false,
      transferredCommission: false,
    },
    {
      name: 'purchaseNetTotal',
      commission: false,
      transferredCommission: true,
    },
    { name: 'purchaseNet', commission: false, transferredCommission: false },
    {
      name: 'netCustomerAccount',
      commission: false,
      transferredCommission: false,
    },
  ];
  static percentageFieldsKeys = [
    'salesSurchargePercent',
    'salesDiscountPercent',
    'salesCommissionPercent',
    'purchaseDiscountPercent',
    'purchaseSurchargePercent',
    'serviceExternalWorkPercent',
    'serviceCreationPercent',
    'serviceChangeProductionPercent',
    'serviceStrategyPercent',
    'serviceProductionBroadcastPercent',
    'serviceClientServicePercent',
  ];
  static stringFields = [
    'orderNumber',
    'ownName',
    'actionGroup',
    'action',
    'service',
    'additionalDescription',
    'numberOfMedia',
  ];

  static boolFields = ['isUpSell'];
  static dictionaryFields = [
    {
      fieldName: 'copySize',
      dictionaryName: 'TV - długość kopii',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'purchaseMethod',
      dictionaryName: 'TV - sposób zakupu',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'purchaseFormInternet',
      dictionaryName: 'Internet - forma zakupu',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'categoryInternet',
      dictionaryName: 'Internet - kategoria',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'formatInternet',
      dictionaryName: 'Internet - format',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'purchaseFormInternetST',
      dictionaryName: 'InternetST - forma zakupu',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'categoryInternetST',
      dictionaryName: 'InternetST - kategoria',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'formatInternetST',
      dictionaryName: 'InternetST - format',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'labconChannel',
      dictionaryName: 'Labcon - kanał',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'categoryLabcon',
      dictionaryName: 'Labcon - kategoria',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'technologyDictionary',
      dictionaryName: 'OOH - technologia',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'regionDictionary',
      dictionaryName: 'OOH - rejon',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'formatPress',
      dictionaryName: 'Prasa - format',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'formatRadio',
      dictionaryName: 'Radio - format',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'emissionTypeRadio',
      dictionaryName: 'Radio - typ emisji',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'emissionTypeCinema',
      dictionaryName: 'Kino - typ emisji',
      commission: false,
      transferredCommission: false,
    },
    {
      fieldName: 'medium',
      dictionaryName: 'Zagraniczne - medium',
      commission: false,
      transferredCommission: false,
    },
  ];
}
