import React from 'react';

import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import ValidationAlert from '../../../app/components/ValidationAlert';

interface ProjectDelayFormProps {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
}

export const ProjectDelayForm: React.FC<ProjectDelayFormProps> = props => {
  return (
    <>
      <ValidationAlert
        show={true}
        errors={[
          'Założenie kampanii w wybranym okresie jest już zablokowane, wyślij wniosek o założenie kampanii.',
        ]}
        className="m-3"
      />
      <FormRow controlId="delayMessage" label="Treść wniosku">
        <DynamicTextarea
          value={props.project?.delayMessage || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ delayMessage: e.target.value })
          }
          readOnly={props.readOnly}
        />
      </FormRow>
    </>
  );
};
