import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
// eslint-disable-next-line import/named
import { Placement } from 'react-bootstrap/types';
// eslint-disable-next-line import/named
import { OverlayDelay } from 'react-bootstrap/OverlayTrigger';

export default (props: {
  label: string;
  title?: string;
  placement?: Placement;
  delay?: OverlayDelay;
}) => {
  return (
    <OverlayTrigger
      placement={props.placement || 'left'}
      delay={props.delay}
      overlay={
        <Popover
          id="popover-basic"
          style={{
            minWidth: props.label.length > 100 ? 800 : undefined,
          }}
        >
          <Popover.Header as="h3" className="text-center">
            {props.title}
          </Popover.Header>
          <Popover.Body>{props.label}</Popover.Body>
        </Popover>
      }
    >
      <Icon.InfoCircle
        className="icon-blue"
        // size={22}
        style={{ minWidth: 30 }}
      />
    </OverlayTrigger>
  );
};
