/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetPositionDto
 */
export interface GetPositionDto {
    /**
     * 
     * @type {string}
     * @memberof GetPositionDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetPositionDto
     */
    systemNumber: number;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetPositionDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {string}
     * @memberof GetPositionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPositionDto
     */
    groupName: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetPositionDto
     */
    dictionary: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetPositionDto
     */
    positionType: GetPositionDtoPositionTypeEnum;
    /**
     * 
     * @type {GetTeamDto}
     * @memberof GetPositionDto
     */
    team?: GetTeamDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetPositionDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPositionDto
     */
    notPurchaseDeficiencies: boolean;
}


/**
 * @export
 */
export const GetPositionDtoPositionTypeEnum = {
    Standardowa: 'Standardowa',
    Prowizja: 'Prowizja',
    ProwizjaTransferowana: 'Prowizja-Transferowana'
} as const;
export type GetPositionDtoPositionTypeEnum = typeof GetPositionDtoPositionTypeEnum[keyof typeof GetPositionDtoPositionTypeEnum];


/**
 * Check if a given object implements the GetPositionDto interface.
 */
export function instanceOfGetPositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groupName" in value;
    isInstance = isInstance && "dictionary" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "notPurchaseDeficiencies" in value;

    return isInstance;
}

export function GetPositionDtoFromJSON(json: any): GetPositionDto {
    return GetPositionDtoFromJSONTyped(json, false);
}

export function GetPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'client': GetClientDtoFromJSON(json['client']),
        'name': json['name'],
        'groupName': json['groupName'],
        'dictionary': GetDictionaryDtoFromJSON(json['dictionary']),
        'positionType': json['positionType'],
        'team': !exists(json, 'team') ? undefined : GetTeamDtoFromJSON(json['team']),
        'active': json['active'],
        'notPurchaseDeficiencies': json['notPurchaseDeficiencies'],
    };
}

export function GetPositionDtoToJSON(value?: GetPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'client': GetClientDtoToJSON(value.client),
        'name': value.name,
        'groupName': value.groupName,
        'dictionary': GetDictionaryDtoToJSON(value.dictionary),
        'positionType': value.positionType,
        'team': GetTeamDtoToJSON(value.team),
        'active': value.active,
        'notPurchaseDeficiencies': value.notPurchaseDeficiencies,
    };
}

