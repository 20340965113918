import React from 'react';
import { Form } from 'react-bootstrap';

import { GetOrderExecutionDtoStatusEnum } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { OrderExecutionStatusEnumText } from '../../../common/pokCore/validation/schemas';

interface OrderExecutionStatusFieldsProps {
  status?: GetOrderExecutionDtoStatusEnum;
  rejectedNote?: string;
  active?: boolean;
}

export const OrderExecutionStatusFields: React.FC<
  OrderExecutionStatusFieldsProps
> = ({ status, rejectedNote, active }) => {
  return (
    <>
      {status && (
        <FormRow controlId="status" label="Status">
          <Form.Control
            value={
              OrderExecutionStatusEnumText[status] +
              (!active ? ' - usunięte' : '')
            }
            disabled
          />
        </FormRow>
      )}
      {rejectedNote && (
        <FormRow controlId="rejectedNote" label="Uzasadnienie odrzucenia">
          <DynamicTextarea disabled readOnly value={rejectedNote} />
        </FormRow>
      )}
    </>
  );
};
