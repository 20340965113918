import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { ProjectSearch } from '../../components/Projects/ProjectSearch';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

export const ProjectsReadyToInvoiceView: React.FC = () => {
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();
  const nav = useNavigation();

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.projects.getAllReadyToInvoiceByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.projects],
  );

  const getCount = useMemo(
    () =>
      (filterText =>
        pok.projects.getAllReadyToInvoiceCount(
          filterText,
        )) as GridCountFunction,
    [pok.projects],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.menu.readyForInvoicing)}</Card.Title>
        <ProjectSearch
          getData={getData}
          getCount={getCount}
          onRowClick={id => nav.salesInvoiceEditor({ projectId: id })}
          showTotalDifferenceColumn
          totalDifferenceLabel="Kwota do zafakturowania"
        />
      </Card.Body>
    </Card>
  );
};
