import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import {
  GetDictionaryDto,
  GetEstimateItemDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import numberFormatter from '../../../common/numberFormatter';
import momencik from '../../../common/momencik';
import { PositionTypeEnum } from '../../../common/pokCore/validation/schemas';
import mathUtils from '../../../utils/mathUtils';

import { OrderEstimateItemsGridMonthsSum } from './OrderEstimateItemsGridMonthsSum';

interface OrderEstimateItemsGridProps {
  data: GetEstimateItemDto[];
}

const getItemsGridSummary = (data: GetEstimateItemDto[]) => {
  return {
    key: 'summary',
    values: {
      'estimateItem.comment': 'Suma',
      'estimateItem.purchaseNetTotal': numberFormatter(
        mathUtils.add(
          ...data.map(({ purchaseNetTotal }) =>
            mathUtils.round(purchaseNetTotal),
          ),
        ),
        true,
      ),
    },
  };
};

export const buildParametersString = (estimateItem: GetEstimateItemDto) => {
  const result: { label: string; value: string }[] = [];
  const { schema } = estimateItem.estimateByMonth.profile;

  const isCommission = [
    PositionTypeEnum.Commission,
    PositionTypeEnum.TransferredCommission,
  ].includes(estimateItem?.positionType as PositionTypeEnum);
  const schemaKeysToGet: (keyof ProfileSchemaDto)[] = ['service', 'action'];

  if (!isCommission) {
    schemaKeysToGet.push(
      'numberOfMedia',
      'technologyDictionary',
      'regionDictionary',
      'additionalDescription',
      'formatPress',
      'formatRadio',
      'emissionTypeRadio',
      'emissionTypeCinema',
      'purchaseFormInternet',
      'categoryInternet',
      'formatInternet',
      'purchaseFormInternetST',
      'categoryInternetST',
      'formatInternetST',
      'netCustomerAccount',
      'copySize',
      'orderNumber',
      'purchaseMethod',
      'labconChannel',
      'categoryLabcon',
      'medium',
      'ownName',
      'isUpSell',
      'actionGroup',
    );
  }

  // eslint-disable-next-line complexity
  schemaKeysToGet.forEach(key => {
    if (schema[key] && estimateItem[key]) {
      if (typeof estimateItem[key] === 'string') {
        return result.push({
          label: schema[key]?.displayName || '',
          value: (estimateItem[key] as string) || '',
        });
      }

      if (typeof estimateItem[key] === 'boolean') {
        return result.push({
          label: schema[key]?.displayName || '',
          value: estimateItem[key] ? 'TAK' : 'NIE',
        });
      }

      return result.push({
        label: schema[key]?.displayName || '',
        value: (estimateItem[key] as GetDictionaryDto)?.value || '',
      });
    }
  });

  return (
    <ul className="m-0 list-group-numbered p-0">
      {result.map(({ label, value }, index) => (
        <li key={index}>
          {label}: {value}
        </li>
      ))}
    </ul>
  );
};

export const OrderEstimateItemsGrid: React.FC<
  OrderEstimateItemsGridProps
> = props => {
  const columns: GridColumn[] = [
    { header: 'Nazwa', property: 'position.name' },
    { header: 'Miesiąc', property: 'estimateByMonth.date' },
    { header: 'Kontrahent', property: 'client.name' },
    { header: 'Parametry', property: 'parameters', noSorting: true },
    { header: 'Komentarz', property: 'estimateItem.comment' },
    { header: 'Kwota zakupu', property: 'estimateItem.purchaseNetTotal' },
  ];

  const data = props.data.map(estimateItem => {
    return {
      key: estimateItem.id,
      values: {
        'position.name': estimateItem.position.name,
        'estimateByMonth.date': momencik(
          estimateItem.estimateByMonth.date,
          'YYYY-MM',
        ),
        'client.name':
          estimateItem.position.client.shortname ||
          estimateItem.position.client.name,
        'estimateItem.purchaseNetTotal': numberFormatter(
          estimateItem.purchaseNetTotal || '0',
          true,
        ),
        parameters: buildParametersString(estimateItem),
        'estimateItem.comment': estimateItem.comment,
      },
      orderByStringValues: {
        name: estimateItem.position.name,
      },
    };
  });

  return (
    <>
      <Grid
        data={data}
        columns={columns}
        initialOrderColumn="estimateByMonth.date"
        initialOrderDirection="DESC"
        hidePagination
        emptyGridMessage="Brak pozycji przypisanych do tego zespołu."
        summaryData={getItemsGridSummary(props.data)}
      />
      <OrderEstimateItemsGridMonthsSum data={props.data} />
    </>
  );
};
