/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePackageInvoicesXMLDto
 */
export interface CreateUpdatePackageInvoicesXMLDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePackageInvoicesXMLDto
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePackageInvoicesXMLDto
     */
    invoiceType?: CreateUpdatePackageInvoicesXMLDtoInvoiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePackageInvoicesXMLDto
     */
    packageType: CreateUpdatePackageInvoicesXMLDtoPackageTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePackageInvoicesXMLDto
     */
    isCorrection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePackageInvoicesXMLDto
     */
    isRepeat?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePackageInvoicesXMLDto
     */
    selectedInvoices?: Array<string>;
}


/**
 * @export
 */
export const CreateUpdatePackageInvoicesXMLDtoInvoiceTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type CreateUpdatePackageInvoicesXMLDtoInvoiceTypeEnum = typeof CreateUpdatePackageInvoicesXMLDtoInvoiceTypeEnum[keyof typeof CreateUpdatePackageInvoicesXMLDtoInvoiceTypeEnum];

/**
 * @export
 */
export const CreateUpdatePackageInvoicesXMLDtoPackageTypeEnum = {
    Purchase: 'Purchase',
    Sale: 'Sale'
} as const;
export type CreateUpdatePackageInvoicesXMLDtoPackageTypeEnum = typeof CreateUpdatePackageInvoicesXMLDtoPackageTypeEnum[keyof typeof CreateUpdatePackageInvoicesXMLDtoPackageTypeEnum];


/**
 * Check if a given object implements the CreateUpdatePackageInvoicesXMLDto interface.
 */
export function instanceOfCreateUpdatePackageInvoicesXMLDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "packageType" in value;

    return isInstance;
}

export function CreateUpdatePackageInvoicesXMLDtoFromJSON(json: any): CreateUpdatePackageInvoicesXMLDto {
    return CreateUpdatePackageInvoicesXMLDtoFromJSONTyped(json, false);
}

export function CreateUpdatePackageInvoicesXMLDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePackageInvoicesXMLDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'invoiceType': !exists(json, 'invoiceType') ? undefined : json['invoiceType'],
        'packageType': json['packageType'],
        'isCorrection': !exists(json, 'isCorrection') ? undefined : json['isCorrection'],
        'isRepeat': !exists(json, 'isRepeat') ? undefined : json['isRepeat'],
        'selectedInvoices': !exists(json, 'selectedInvoices') ? undefined : json['selectedInvoices'],
    };
}

export function CreateUpdatePackageInvoicesXMLDtoToJSON(value?: CreateUpdatePackageInvoicesXMLDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'invoiceType': value.invoiceType,
        'packageType': value.packageType,
        'isCorrection': value.isCorrection,
        'isRepeat': value.isRepeat,
        'selectedInvoices': value.selectedInvoices,
    };
}

