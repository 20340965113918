import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

interface CancelIconProps {
  cancelVerification: boolean;
  active: boolean;
}

export const CancelIcon: React.FC<CancelIconProps> = props => {
  return (
    <>
      {props.cancelVerification && props.active && (
        <OverlayTrigger
          overlay={<Tooltip>Czeka na weryfikację wniosku o anulowanie</Tooltip>}
        >
          <div className="px-2">
            <Icon.BackspaceReverse className="icon-red" />
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};
