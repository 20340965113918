/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { useAuth } from '../../common/hooks/useAuth';
import { useNotifications } from '../../common/hooks/useNotifications';
import { PermissionsEnum } from '../../common/pokCore/authorization/permissions';
import Button from '../../common/components/Button/Button';

import FormRow from './FormRow';
import ValidationAlert from './ValidationAlert';
import DynamicTextarea from './DynamicTextarea';

interface ReasonInputProps {
  reason: string | undefined;
  setReason: Dispatch<SetStateAction<string | undefined>>;
  showError: boolean;
  reasonText?: string;
  dynamicInput?: boolean;
  focusInput?: boolean;
  errorLabel?: string;
}

const ReasonInput: React.FC<ReasonInputProps> = props => (
  <>
    <Form className="d-grid mb-3">
      <FormRow controlId="reason" label={props.reasonText || 'Powód'}>
        {props.dynamicInput ? (
          <DynamicTextarea
            value={props.reason || ''}
            onChange={e => props.setReason(e.target.value)}
            autoFocus={props.focusInput}
          />
        ) : (
          <Form.Control
            inputMode="text"
            value={props.reason || ''}
            onChange={e => props.setReason(e.target.value)}
            autoFocus={props.focusInput}
          />
        )}
      </FormRow>
    </Form>
    <ValidationAlert
      show={props.showError}
      errors={[props.errorLabel || 'Proszę podać powód.']}
    />
  </>
);

export default (
  props: React.PropsWithChildren<{
    confirmation: string;
    warnings?: string[];
    confirmLabel?: string;
    rejectLabel?: string;
    errorLabel?: string;
    disabled?: boolean;
    tooltip?: string;
    className?: string;
    variant?: string;
    roleAnyOf?: PermissionsEnum[];
    rolesAllOf?: PermissionsEnum[];
    askForAReason?: boolean;
    dynamicInput?: boolean;
    focusInput?: boolean;
    reasonText?: string;
    reason?: string;
    reasonNotEmpty?: boolean;
    onValidation?: () => Promise<boolean>;
    onOK: (reason?: string) => void;
    modalSize?: 'sm' | 'lg' | 'xl';
    isLoading?: boolean;
  }>,
) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState<string>();
  const [showError, setShowError] = useState(false);
  const auth = useAuth();
  const notifications = useNotifications();

  const validate = async () => {
    if (
      (props.roleAnyOf && !props.roleAnyOf.find(role => auth.check(role))) ||
      (props.rolesAllOf && props.rolesAllOf.find(role => !auth.check(role)))
    ) {
      notifications.unauthorized();
      return false;
    }
    if (props.onValidation) {
      const status = await props.onValidation();
      return status;
    }
    return true;
  };

  useEffect(() => {
    setShowError(false);
    setReason(props.reason);
  }, [show, props.reason]);

  return (
    <>
      {props.tooltip && (
        <OverlayTrigger overlay={<Tooltip>{props.tooltip}</Tooltip>}>
          <Button
            className={props.className}
            variant={props.variant}
            disabled={props.disabled}
            onClick={async () => {
              const valid = await validate();
              if (!valid) {
                return;
              }
              setShow(true);
            }}
            isLoading={props.isLoading}
          >
            {props.children}
          </Button>
        </OverlayTrigger>
      )}
      {!props.tooltip && (
        <Button
          className={props.className}
          variant={props.variant}
          disabled={props.disabled}
          isLoading={props.isLoading}
          onClick={async () => {
            const valid = await validate();
            if (!valid) {
              return;
            }
            setShow(true);
          }}
        >
          {props.children}
        </Button>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size={props.modalSize || (props.askForAReason ? 'lg' : undefined)}
        centered={true}
        backdrop="static"
        backdropClassName="modal-on-modal-backdrop"
        className="modal-on-modal"
      >
        <Modal.Header>
          <Modal.Title>{props.confirmation}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.warnings &&
            props.warnings?.length > 0 &&
            props.warnings.map(o => (
              <>
                <div>{o}</div>
              </>
            ))}
          {props.askForAReason && (
            <ReasonInput
              {...props}
              showError={showError}
              reason={reason}
              setReason={setReason}
            />
          )}
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-secondary">
              {props.rejectLabel || 'Nie'}
            </Button>
            <Button
              className="ms-1"
              onClick={() => {
                if (props.askForAReason && props.reasonNotEmpty && !reason) {
                  setShowError(true);
                  return;
                }
                setShow(false);
                props.onOK(reason);
              }}
              variant={props.variant}
              isLoading={props.isLoading}
            >
              {props.confirmLabel || 'Tak'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
