import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  GetProjectExtendedDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { GridRecord } from '../../../common/components/Grid/GridDataTypes';

import { PurchaseInvoiceProjectsSearch } from './PurchaseInvoiceProjectsSearch';
import { PurchaseInvoiceProjectEditor } from './PurchaseInvoiceProjectEditor';

interface PurchaseInvoiceEditorFormProjectsProps {
  projects: GetProjectExtendedDto[];
  teamsAndProjects: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  readOnly: boolean;
  propertyChange: (
    obj: Partial<CreateUpdatePurchaseInvoiceTeamsAndProjectsDto> & {
      projects?: GetProjectExtendedDto[];
    },
  ) => void;
}

export const PurchaseInvoiceEditorFormProjects: React.FC<
  PurchaseInvoiceEditorFormProjectsProps
> = props => {
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();

  const handleAdd = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async (selectedIds: string[]) => {
    if (selectedIds.length) {
      const projectIds = props.projects.map(({ id }) => id);
      selectedIds = selectedIds.filter(id => !projectIds.includes(id));
      const addedProjects = await Promise.all(
        selectedIds.map(id => pok.projects.getExtendedById(id)),
      );
      props.propertyChange({ projects: [...props.projects, ...addedProjects] });
      handleClose();
    }
  };

  const handleDelete = (removedId: string) => {
    props.propertyChange({
      projects: props.projects.filter(({ id }) => id !== removedId),
    });
  };

  const data = props.projects?.map(project => ({
    key: project.id,
    values: {
      ...project,
      startDate: momencik(project.startDate),
      endDate: momencik(project.endDate),
      purchaseInvoiceRemainingSum: numberFormatter(
        project.purchaseInvoiceRemainingSum,
        true,
      ),
      purchaseNetTotal: numberFormatter(project.purchaseNetTotal, true),
      purchaseInvoicesSum: numberFormatter(project.purchaseInvoicesSum, true),
      deleteLink:
        props.projects && project.hasRights ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className="d-flex justify-content-center"
            onClick={e => e.stopPropagation()}
          >
            <ConfirmationButton
              variant="outline-danger"
              confirmation={t(tk.menu.deleteProjectWarning)}
              tooltip={t(tk.menu.deleteProjectTooltip)}
              onOK={() => handleDelete(project.id)}
            >
              <Trash size={16} />
            </ConfirmationButton>
          </div>
        ) : undefined,
    },
    isInactive: !project.active,
  })) as unknown as GridRecord[];

  if (!props.extendedPurchaseInvoice) {
    return null;
  }

  return (
    <>
      {!props.readOnly && (
        <Button variant="outline-primary" className="mb-4" onClick={handleAdd}>
          {t(tk.menu.addProject)}
        </Button>
      )}
      <PurchaseInvoiceProjectsSearch readOnly={props.readOnly} data={data} />
      <PurchaseInvoiceProjectEditor
        ignoreProjectIds={
          props.teamsAndProjects.projectIds?.length
            ? props.teamsAndProjects.projectIds
            : undefined
        }
        client={props.extendedPurchaseInvoice.client}
        readOnly={props.readOnly}
        show={show}
        onClose={handleClose}
        onChange={handleSave}
      />
    </>
  );
};
