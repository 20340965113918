import {
  CreateUpdateExcelConfigPositionDto,
  CreateUpdateExcelConfigPositionDtoFromJSON,
  DefaultApi,
  GetExcelConfigPositionDto,
  GetLastEditorDto,
  ParametersSchemaDto,
} from '../autogenerated/pokApiClient';
import { ExcelConfigPositionSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface ExcelConfigPositionContextInterface {
  getById: (id: string) => Promise<GetExcelConfigPositionDto>;
  getAllByConfigId: (configId: string) => Promise<GetExcelConfigPositionDto[]>;
  update: (
    id: string,
    dto: CreateUpdateExcelConfigPositionDto,
  ) => Promise<void>;
  create: (
    dto: CreateUpdateExcelConfigPositionDto,
  ) => Promise<GetExcelConfigPositionDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getSimilarPositionWithParams: (
    positionId: string,
    configId: string,
  ) => Promise<GetExcelConfigPositionDto>;
}

export const ExcelConfigPositionContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.excelConfigPositionControllerGet(id),
    getAllByConfigId: (configId: string) =>
      api.excelConfigPositionControllerGetAllByConfig(configId),
    update: (id: string, dto: CreateUpdateExcelConfigPositionDto) =>
      api.excelConfigPositionControllerUpdate(id, dto),
    create: (dto: CreateUpdateExcelConfigPositionDto) =>
      api.excelConfigPositionControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.excelConfigPositionControllerGetLastEditor(id),
    getSimilarPositionWithParams: (positionId: string, configId: string) =>
      api.excelConfigPositionControllerGetSimilarPositionWithParams(
        positionId,
        configId,
      ),
  };
};

export const newPosition = (configId?: string, excelPositionName?: string) => {
  return CreateUpdateExcelConfigPositionDtoFromJSON({
    excelConfigId: configId,
    excelPositionName,
  });
};

export const validate = (position: CreateUpdateExcelConfigPositionDto) =>
  validateAgainst(ExcelConfigPositionSchema, position);

export const convert = (
  position?: GetExcelConfigPositionDto,
  configId?: string,
) => {
  if (!position) {
    return newPosition(configId);
  }
  const converted = CreateUpdateExcelConfigPositionDtoFromJSON(position);
  converted.positionId = position.position.id;
  return converted;
};

export const hasAnyParameterFilled = (
  parameters: ParametersSchemaDto,
): boolean => {
  for (const key in parameters) {
    if (parameters[key as keyof ParametersSchemaDto]) {
      return true;
    }
  }
  return false;
};
