import React from 'react';
import { Alert } from 'react-bootstrap';

export const BadCompany: React.FC = () => {
  return (
    <Alert variant="danger">
      <Alert.Heading>Niezgodna spółka</Alert.Heading>
      <hr />
      <p>
        Spółka, w której obecnie pracujesz, nie może obsługiwać wybranego
        obiektu.
      </p>
    </Alert>
  );
};
