import React from 'react';

import {
  GetProfileDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';

import { Selector, Option } from './Selector';

export interface FieldSelectorProps {
  readOnly?: boolean;
  profile: GetProfileDto;
  value?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
}

export const FieldSelector: React.FC<FieldSelectorProps> = props => {
  const provider = async (text: string) => {
    const coreValues = [
      { badge: '', label: 'Nr kampanii/projektu', value: 'projectNumber' },
      { badge: '', label: 'Nazwa pozycji', value: 'positionName' },
      { badge: '', label: 'Miesiąc pozycji', value: 'month' },
      { badge: '', label: 'Data rozpoczęcia pozycji', value: 'startDate' },
      { badge: '', label: 'Data zakończenia pozycji', value: 'endDate' },
      {
        badge: '',
        label: 'Instrukcja fakturowania',
        value: 'invoiceInstructionAttachment',
      },
    ];
    const values = [];
    const schemas = props.profile.schema;
    for (const key of Object.keys(schemas)) {
      const value = schemas[key as keyof ProfileSchemaDto];
      if (value !== undefined) {
        values.push({
          badge: '',
          label: value.displayName,
          value: key,
        });
      }
    }
    values.sort((a, b) => a.label.localeCompare(b.label));
    values.unshift(...coreValues);
    return values.filter(v =>
      v.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );
  };

  return (
    <Selector
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={provider}
      value={props.value}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
