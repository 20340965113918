import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';

import { OrderExecutionSearch } from './OrderExecutionSearch';

export const OrdersExecutionSentTab: React.FC = () => {
  const pok = usePokCore();

  return (
    <OrderExecutionSearch
      getData={pok.ordersExecution.getAllByPortion}
      getCount={pok.ordersExecution.getAllCount}
    />
  );
};
