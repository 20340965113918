/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProfileSchemaDto } from './ProfileSchemaDto';
import {
    ProfileSchemaDtoFromJSON,
    ProfileSchemaDtoFromJSONTyped,
    ProfileSchemaDtoToJSON,
} from './ProfileSchemaDto';

/**
 * 
 * @export
 * @interface CreateUpdateProfileDto
 */
export interface CreateUpdateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    name: string;
    /**
     * 
     * @type {ProfileSchemaDto}
     * @memberof CreateUpdateProfileDto
     */
    schema: ProfileSchemaDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateProfileDto
     */
    companyIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateProfileDto
     */
    teamIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateProfileDto
     */
    relatedTeamIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    commission: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    tv: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    tvBuying: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    autoSalesBlockDisabled: boolean;
}

/**
 * Check if a given object implements the CreateUpdateProfileDto interface.
 */
export function instanceOfCreateUpdateProfileDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "schema" in value;
    isInstance = isInstance && "companyIds" in value;
    isInstance = isInstance && "teamIds" in value;
    isInstance = isInstance && "relatedTeamIds" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "tv" in value;
    isInstance = isInstance && "tvBuying" in value;
    isInstance = isInstance && "autoSalesBlockDisabled" in value;

    return isInstance;
}

export function CreateUpdateProfileDtoFromJSON(json: any): CreateUpdateProfileDto {
    return CreateUpdateProfileDtoFromJSONTyped(json, false);
}

export function CreateUpdateProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'schema': ProfileSchemaDtoFromJSON(json['schema']),
        'companyIds': json['companyIds'],
        'teamIds': json['teamIds'],
        'relatedTeamIds': json['relatedTeamIds'],
        'active': json['active'],
        'commission': json['commission'],
        'tv': json['tv'],
        'tvBuying': json['tvBuying'],
        'autoSalesBlockDisabled': json['autoSalesBlockDisabled'],
    };
}

export function CreateUpdateProfileDtoToJSON(value?: CreateUpdateProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'schema': ProfileSchemaDtoToJSON(value.schema),
        'companyIds': value.companyIds,
        'teamIds': value.teamIds,
        'relatedTeamIds': value.relatedTeamIds,
        'active': value.active,
        'commission': value.commission,
        'tv': value.tv,
        'tvBuying': value.tvBuying,
        'autoSalesBlockDisabled': value.autoSalesBlockDisabled,
    };
}

