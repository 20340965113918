import React, { useMemo } from 'react';

import {
  GetEstimateByMonthWithItemsDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import FormSkeleton from '../../../app/components/FormSkeleton';

import { ProjectEstimateItemsGrid } from './ProjectEstimateItemsGrid';

interface ProjectSummaryTabProps {
  project?: GetProjectDto;
}

export const ProjectSummaryTab: React.FC<ProjectSummaryTabProps> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [projectEstimateItems, setProjectEstimateItems] = React.useState<
    GetEstimateByMonthWithItemsDto[]
  >([]);

  useMemo(() => {
    setIsLoading(true);
    pok.estimatesByMonth
      .findWithItemsByProjectId(props.project?.id || '')
      .then(items => {
        setProjectEstimateItems(items);
        setIsLoading(false);
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
        setIsLoading(false);
      });
  }, [notifications, pok.estimatesByMonth, props.project?.id]);

  return (
    <div className="pt-4">
      {isLoading ? (
        <FormSkeleton />
      ) : (
        <ProjectEstimateItemsGrid
          data={projectEstimateItems}
          project={props.project}
        />
      )}
    </div>
  );
};
