/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDictionaryTypeDto } from './GetDictionaryTypeDto';
import {
    GetDictionaryTypeDtoFromJSON,
    GetDictionaryTypeDtoFromJSONTyped,
    GetDictionaryTypeDtoToJSON,
} from './GetDictionaryTypeDto';

/**
 * 
 * @export
 * @interface GetDictionaryDto
 */
export interface GetDictionaryDto {
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetDictionaryDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    dictionaryTypeId: string;
    /**
     * 
     * @type {GetDictionaryTypeDto}
     * @memberof GetDictionaryDto
     */
    dictionaryType: GetDictionaryTypeDto;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    parentId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDictionaryDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetDictionaryDto interface.
 */
export function instanceOfGetDictionaryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "dictionaryTypeId" in value;
    isInstance = isInstance && "dictionaryType" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetDictionaryDtoFromJSON(json: any): GetDictionaryDto {
    return GetDictionaryDtoFromJSONTyped(json, false);
}

export function GetDictionaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDictionaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'shortname': json['shortname'],
        'value': json['value'],
        'dictionaryTypeId': json['dictionaryTypeId'],
        'dictionaryType': GetDictionaryTypeDtoFromJSON(json['dictionaryType']),
        'parentId': json['parentId'],
        'active': json['active'],
    };
}

export function GetDictionaryDtoToJSON(value?: GetDictionaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'shortname': value.shortname,
        'value': value.value,
        'dictionaryTypeId': value.dictionaryTypeId,
        'dictionaryType': GetDictionaryTypeDtoToJSON(value.dictionaryType),
        'parentId': value.parentId,
        'active': value.active,
    };
}

