/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUpdateOrderExecutionPositionDto } from './CreateUpdateOrderExecutionPositionDto';
import {
    CreateUpdateOrderExecutionPositionDtoFromJSON,
    CreateUpdateOrderExecutionPositionDtoFromJSONTyped,
    CreateUpdateOrderExecutionPositionDtoToJSON,
} from './CreateUpdateOrderExecutionPositionDto';

/**
 * 
 * @export
 * @interface CreateUpdateOrderExecutionDto
 */
export interface CreateUpdateOrderExecutionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionDto
     */
    projectId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOrderExecutionDto
     */
    addTeamToEmails: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateOrderExecutionDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateOrderExecutionDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOrderExecutionDto
     */
    formalConditionsId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateOrderExecutionDto
     */
    orderExecutionEmployeesIds: Array<string>;
    /**
     * 
     * @type {Array<CreateUpdateOrderExecutionPositionDto>}
     * @memberof CreateUpdateOrderExecutionDto
     */
    orderExecutionPositions: Array<CreateUpdateOrderExecutionPositionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOrderExecutionDto
     */
    active?: boolean;
}

/**
 * Check if a given object implements the CreateUpdateOrderExecutionDto interface.
 */
export function instanceOfCreateUpdateOrderExecutionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "addTeamToEmails" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "formalConditionsId" in value;
    isInstance = isInstance && "orderExecutionEmployeesIds" in value;
    isInstance = isInstance && "orderExecutionPositions" in value;

    return isInstance;
}

export function CreateUpdateOrderExecutionDtoFromJSON(json: any): CreateUpdateOrderExecutionDto {
    return CreateUpdateOrderExecutionDtoFromJSONTyped(json, false);
}

export function CreateUpdateOrderExecutionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateOrderExecutionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['projectId'],
        'addTeamToEmails': json['addTeamToEmails'],
        'emails': json['emails'],
        'date': (new Date(json['date'])),
        'formalConditionsId': json['formalConditionsId'],
        'orderExecutionEmployeesIds': json['orderExecutionEmployeesIds'],
        'orderExecutionPositions': ((json['orderExecutionPositions'] as Array<any>).map(CreateUpdateOrderExecutionPositionDtoFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function CreateUpdateOrderExecutionDtoToJSON(value?: CreateUpdateOrderExecutionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectId': value.projectId,
        'addTeamToEmails': value.addTeamToEmails,
        'emails': value.emails,
        'date': (value.date.toISOString()),
        'formalConditionsId': value.formalConditionsId,
        'orderExecutionEmployeesIds': value.orderExecutionEmployeesIds,
        'orderExecutionPositions': ((value.orderExecutionPositions as Array<any>).map(CreateUpdateOrderExecutionPositionDtoToJSON)),
        'active': value.active,
    };
}

