import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

import * as AttachmentContext from '../../../common/pokCore/contexts/AttachmentContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import Button from '../../../common/components/Button/Button';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { AttachmentObjectType } from '../../../common/pokCore/validation/schemas';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

interface SimpleNoteEditorProps {
  show: boolean;
  objectId: string;
  objectType: AttachmentObjectType;
  onClose: () => void;
  existsAttachmentId?: string;
  existsAttachment?: CreateUpdateAttachmentDto;
  additionalText?: string;
}

export const SimpleNoteEditor: React.FC<SimpleNoteEditorProps> = props => {
  const pok = usePokCore();
  const [attachment, setAttachment] = useState(props.existsAttachment);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const onAdd = async () => {
    if (attachment) {
      AttachmentContext.validate(attachment)
        .then(result => {
          if (result.valid) {
            if (props.existsAttachmentId) {
              pok.attachments
                .update(props.existsAttachmentId, attachment)
                .then(() => {
                  handleClose();
                })
                .catch(errorResponse => {
                  setShowSaveErrors(true);
                  setSaveErrors(errorResponse.errors);
                });
              return;
            } else {
              pok.attachments
                .create(attachment, true)
                .then(() => {
                  handleClose();
                })
                .catch(() => {
                  setShowSaveErrors(true);
                  setSaveErrors([
                    'Prawdopodobnie inny użytkownik dodał własną notatkę. Zamknij okno i wejdź ponownie w notatkę.',
                  ]);
                });
              return;
            }
          } else {
            setShowSaveErrors(true);
            setSaveErrors(result.errors);
          }
        })
        .catch(errorResponse => {
          setShowSaveErrors(true);
          setSaveErrors(errorResponse.errors);
        });
    }
  };

  useEffect(() => {
    if (props.existsAttachmentId) {
      setAttachment(props.existsAttachment);
    } else {
      AttachmentContext.newSimpleAttachment(
        props.objectId,
        props.objectType,
        pok,
      )
        .then(attachment => setAttachment(attachment))
        .catch(errorResponse => {
          setShowSaveErrors(true);
          setSaveErrors(errorResponse.errors);
        });
    }
    setSaveErrors([]);
    setShowSaveErrors(false);
  }, [
    pok,
    props.existsAttachment,
    props.objectId,
    props.objectType,
    props.existsAttachmentId,
  ]);

  const handleClose = () => {
    props.onClose();
    setSaveErrors([]);
    setShowSaveErrors(false);
  };

  const handleDelete = () => {
    if (props.existsAttachmentId) {
      pok.attachments
        .delete(props.existsAttachmentId)
        .then(() => {
          handleClose();
        })
        .catch(errorResponse => {
          setShowSaveErrors(true);
          setSaveErrors(errorResponse.errors);
        });
    }
  };

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={e => e.stopPropagation()}>
      <Modal
        show={props.show}
        onHide={handleClose}
        centered={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!props.existsAttachment
              ? `Nowa notatka ${props.additionalText}`
              : `Edycja notatki ${props.additionalText}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3">
            <FormRow controlId="note" label="Notatka">
              <DynamicTextarea
                minRows={3}
                value={attachment?.note || ''}
                onChange={e =>
                  attachment &&
                  setAttachment({ ...attachment, note: e.target.value })
                }
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.existsAttachmentId && (
            <LastEditorInfo
              id={props.existsAttachmentId}
              method={pok.attachments.getLastEditor}
            />
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Zamknij
          </Button>
          {props.existsAttachmentId && (
            <ConfirmationButton
              variant="outline-danger"
              onOK={handleDelete}
              confirmation={`Czy na pewno usunąć notatkę?`}
            >
              Usuń
            </ConfirmationButton>
          )}
          <Button variant="outline-primary" onClick={onAdd}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
