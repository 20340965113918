import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import {
  CreateUpdateTeamDto,
  GetTeamDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import Tabs from '../../../app/components/Tabs';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/TeamContext';

import { TeamDetailsEditor } from './TeamDetailsEditor';
import { TeamEditorForm } from './TeamEditorForm';

interface TeamEditorTabbedProps {
  team?: GetTeamDto;
  handleAfterEdit?: () => void;
}

export const TeamEditorTabbed: React.FC<TeamEditorTabbedProps> = props => {
  const pok = usePokCore();
  const [refresh, setRefresh] = useState<boolean>(false);

  const editTeam = async (teamCU: CreateUpdateTeamDto, id?: string) => {
    const status = await validate(teamCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.teams.update(id, teamCU);
        } else {
          await pok.teams.create(teamCU);
        }
        setRefresh(!refresh);
        props.handleAfterEdit && props.handleAfterEdit();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">
            Zespół{' '}
            <b>
              {props.team?.name}
              {props.team?.active ? '' : ' (nieaktywny)'}
            </b>
            {props.team ? `, nr sys. ${props.team?.systemNumber}` : null}
          </Card.Title>
          <Tabs>
            <Tab eventKey="team" title="Zespół" className="m-3">
              <TeamEditorForm team={convert(props.team)} readOnly />
              <br />
              <TeamDetailsEditor
                id={props.team?.id}
                systemNumber={props.team?.systemNumber}
                team={convert(props.team)}
                onCompleted={editTeam}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};
