/*eslint max-lines-per-function: ["error", 250]*/
import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import * as AttachmentContext from '../../../common/pokCore/contexts/AttachmentContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateAttachmentDto,
  GetClientDto,
  GetDictionaryDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import FormRow from '../../../app/components/FormRow';
import { AddAssociateModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Option } from '../Selects/Selector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import CompanySelector from '../Selects/CompanySelector';
import Button from '../../../common/components/Button/Button';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import EstimateItemSelector from '../Selects/EstimateItemSelector';
import { ClientSelector } from '../Selects/ClientSelector';
import mathUtils from '../../../utils/mathUtils';
import CurrencyControl from '../../../app/components/CurrencyControl';
// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

export const AddAttachment = (
  props: AddAssociateModalProps<CreateUpdateAttachmentDto>,
  projectId?: string,
) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const isNote = props.type === 'notes';
  const [emptyAttachment] = useState(props.newAssociate());
  const [attachment, setAttachment] = useState(
    AttachmentContext.newAttachment(),
  );
  const [dictionaryType, setDictionaryType] = useState<
    GetDictionaryDto | undefined
  >();
  const [typeName, setTypeName] = useState('');
  const [positionsSum, setPositionsSum] = useState(0);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [client, setClient] = useState<GetClientDto>();

  const setType = (type: Option) => {
    setAttachment({ ...attachment, dictionaryTypeId: type.value });
    setTypeName(type.label);
    setClient(undefined);
    setPositionsSum(0);
  };

  const setDescription = (description: string) => {
    setAttachment({ ...attachment, description: description });
  };

  const onChangePositions = async (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      setAttachment({
        ...attachment,
        estimateItemsIds: (option as Option[]).map(({ value }) => value),
      });
      let result = 0;

      for (const { value } of option as Option[]) {
        result = mathUtils.add(
          result,
          (await pok.estimateItems.getById(value))?.purchaseNetTotal || 0,
        );
      }

      setPositionsSum(result);
    }
  };

  const setCompanyId = (companyId: string) => {
    setAttachment({ ...attachment, companyId: companyId });
  };

  const setFile = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const f: File = files[0];
    const reader = new FileReader();
    const name = f.name;

    reader.onload = function (e) {
      if (e.target) {
        const target: FileReader = e.target;
        if (target.result) {
          attachment.fileContent = Buffer.from(target.result as ArrayBuffer);
          attachment.name = name;
          setAttachment(attachment);
        }
      }
    };
    reader.readAsArrayBuffer(f);
  };

  const onAdd = () => {
    const template = props.newAssociate();
    if (!attachment.fileContent) {
      attachment.name = 'Notatka';
    }

    attachment.enObjectType = template.enObjectType;
    attachment.objectId = template.objectId;
    attachment.companyId ??= props.companyId;
    attachment.dictionaryShortname = dictionaryType?.shortname;
    void props.validate(attachment).then(result => {
      if (result.valid) {
        props.onCreate(attachment, { type: typeName });
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  useEffect(() => {
    setAttachment({ ...emptyAttachment, dictionaryTypeId: props.dictionaryId });
    setSaveErrors([]);
    setShowSaveErrors(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    if (attachment.dictionaryTypeId) {
      pok.dictionaries
        .getById(attachment.dictionaryTypeId)
        .then(o => setDictionaryType(o))
        .catch(responseError =>
          notifications.caughtError(responseError as ResponseError),
        );
    } else {
      setDictionaryType(undefined);
    }
  }, [attachment.dictionaryTypeId, pok.dictionaries, notifications]);

  useEffect(() => {
    setAttachment({ ...attachment, dictionaryTypeId: props.dictionaryId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dictionaryId]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      className="modal-on-modal"
      backdropClassName="modal-on-modal-backdrop"
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isNote ? 'Nowa notatka' : 'Nowy załącznik / notatka'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="company" label="Spółka">
            <CompanySelector
              value={props.companyId}
              readOnly={props.companyId !== undefined}
              onChange={option => setCompanyId((option as Option)?.value)}
            />
          </FormRow>
          {!isNote && (
            <FormRow controlId="type" label="Typ">
              <DictionarySelector
                dictionary="Załączniki"
                value={attachment.dictionaryTypeId}
                onChange={option => setType(option)}
              />
            </FormRow>
          )}
          <FormRow controlId="description" label="Tytuł">
            <DynamicTextarea
              onChange={event => setDescription(event.target.value)}
            />
          </FormRow>
          <FormRow controlId="file" label="Plik">
            <Form.Control
              type="file"
              onChange={event =>
                setFile((event.currentTarget as HTMLInputElement).files)
              }
            />
          </FormRow>
          <FormRow controlId="note" label="Notatka">
            <DynamicTextarea
              minRows={3}
              value={attachment.note || ''}
              onChange={e =>
                setAttachment({ ...attachment, note: e.target.value })
              }
            />
          </FormRow>
          {props.type === 'projects' &&
            projectId &&
            dictionaryType?.shortname?.startsWith('POZ') && (
              <>
                <FormRow controlId="clientId" label="Fakturujący">
                  <ClientSelector
                    projectId={projectId}
                    onChange={option => {
                      setClient({
                        id: option.value,
                        name: option.label,
                      } as GetClientDto);
                      setAttachment({ ...attachment, clientId: option?.value });
                    }}
                    value={client}
                  />
                </FormRow>
                <FormRow controlId="estimateItemIds" label="Powiązane pozycje">
                  <EstimateItemSelector
                    isMulti={true}
                    projectId={projectId}
                    clientId={attachment.clientId || undefined}
                    value={attachment?.estimateItemsIds || ''}
                    onChange={options => onChangePositions(options)}
                    readOnly={!attachment.clientId}
                  />
                </FormRow>
                <FormRow
                  controlId="positionsSum"
                  label="Powiązane pozycje - suma zakupu"
                >
                  <CurrencyControl
                    decimalScale={2}
                    value={positionsSum}
                    suffix=" zł"
                    allowNegative
                    disabled={true}
                  />
                </FormRow>
              </>
            )}
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        <Button
          variant="outline-primary"
          onClick={onAdd}
          isLoading={props.isLoading}
        >
          Dodaj
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AddAttachmentMemoized = (
  props: AddAssociateModalProps<AttachmentContext.MemoizedAttachment>,
) => {
  const newAssociate = () => {
    const memoized = props.newAssociate();
    if (!memoized.newOne) {
      throw new Error('New attachment generator problem!');
    }
    return memoized.newOne;
  };
  const create = (
    cuDto: CreateUpdateAttachmentDto,
    opt?: Record<string, string>,
  ) => {
    props.onCreate({
      id: `${new Date().getTime()}`,
      type: opt?.type,
      newOne: cuDto,
    });
  };
  const validate = (cuDto: CreateUpdateAttachmentDto) => {
    return props.validate({
      id: `${new Date().getTime()}`,
      newOne: cuDto,
    });
  };
  return (
    <AddAttachment
      {...props}
      newAssociate={newAssociate}
      onCreate={create}
      validate={validate}
    />
  );
};
