import { useState } from 'react';

export interface UseSelectedGridRowReturn<T> {
  selected: T[];
  handleSelect: (id: T) => void;
  handleUnselect: (id: T) => void;
  handleUnselectAll: () => void;
}

function useSelectGridRow<T>(): UseSelectedGridRowReturn<T> {
  const [selected, setSelected] = useState<T[]>([]);

  const handleSelect = (id: T) => setSelected([...selected, id]);

  const handleUnselect = (id: T) =>
    setSelected(selected.filter(item => item !== id));
  const handleUnselectAll = () => setSelected([]);

  return { selected, handleSelect, handleUnselect, handleUnselectAll };
}

export default useSelectGridRow;
