/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import React from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  ParametersSchemaDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

interface PositionParametersProps {
  schema: ProfileSchemaDto;
  parameters: ParametersSchemaDto;
  propertyChange?: (obj: Partial<ParametersSchemaDto>) => void;
}

export const PositionParameters: React.FC<PositionParametersProps> = props => {
  const schema = props.schema;
  const propertyChange = (obj: Partial<ParametersSchemaDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {/* INTERNET */}
      {schema?.purchaseFormInternet && (
        <FormRow
          controlId="purchaseFormInternet"
          label={schema?.purchaseFormInternet?.displayName}
        >
          <DictionarySelector
            dictionary="Internet - forma zakupu"
            value={props.parameters?.purchaseFormInternet || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternet: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.categoryInternet && (
        <FormRow
          controlId="categoryInternet"
          label={schema?.categoryInternet?.displayName}
        >
          <DictionarySelector
            dictionary="Internet - kategoria"
            value={props.parameters?.categoryInternet || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternet: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.formatInternet && (
        <FormRow
          controlId="formatInternet"
          label={schema?.formatInternet?.displayName}
        >
          <DictionarySelector
            dictionary="Internet - format"
            value={props.parameters?.formatInternet || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternet: option.value,
              })
            }
          />
        </FormRow>
      )}
      {/* INTERNET ST */}
      {schema?.purchaseFormInternetST && (
        <FormRow
          controlId="purchaseFormInternetST"
          label={schema?.purchaseFormInternetST?.displayName}
        >
          <DictionarySelector
            dictionary="InternetST - forma zakupu"
            value={props.parameters?.purchaseFormInternetST || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternetST: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.categoryInternetST && (
        <FormRow
          controlId="categoryInternetST"
          label={schema?.categoryInternetST?.displayName}
        >
          <DictionarySelector
            dictionary="InternetST - kategoria"
            value={props.parameters?.categoryInternetST || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternetST: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.formatInternetST && (
        <FormRow
          controlId="formatInternetST"
          label={schema?.formatInternetST?.displayName}
        >
          <DictionarySelector
            dictionary="InternetST - format"
            value={props.parameters?.formatInternetST || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternetST: option.value,
              })
            }
          />
        </FormRow>
      )}

      {/* LABCON */}
      {schema?.labconChannel && (
        <FormRow
          controlId="labconChannel"
          label={schema?.labconChannel?.displayName}
        >
          <DictionarySelector
            dictionary="Labcon - kanał"
            value={props.parameters?.labconChannel || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                labconChannel: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.categoryLabcon && (
        <FormRow
          controlId="categoryLabcon"
          label={schema?.categoryLabcon?.displayName}
        >
          <DictionarySelector
            dictionary="Labcon - kategoria"
            value={props.parameters?.categoryLabcon || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryLabcon: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.service && (
        <FormRow controlId="service" label={schema?.service?.displayName}>
          <Form.Control
            type="text"
            value={props.parameters?.service ?? ''}
            onChange={e =>
              propertyChange({
                service: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.action && (
        <FormRow controlId="action" label={schema?.action?.displayName}>
          <Form.Control
            type="text"
            value={props.parameters?.action ?? ''}
            onChange={e =>
              propertyChange({
                action: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {/* {schema?.actionGroup && (
        <FormRow
          controlId="actionGroup"
          label={schema?.actionGroup?.displayName}
        >
          <CreatableClearableSelector
            value={props.attributes?.actionGroup ?? ''}
            onChange={option =>
              propertyChange({
                actionGroup: option ? (option as Option).label : option,
              })
            }
            provider={(text: string) =>
              pok.estimateItems.getActionGroupsOptionsByTextByCompanyAndBudget(
                text,
                props.project?.company?.id || '',
                props.project?.purchaser.budget.id || '',
              )
            }
          />
        </FormRow>
      )} */}
      {/* OFFLINE */}
      {schema?.numberOfMedia && (
        <FormRow
          controlId="numberOfMedia"
          label={schema?.numberOfMedia?.displayName}
        >
          <Form.Control
            type="number"
            min={0}
            value={props.parameters?.numberOfMedia || undefined}
            onChange={e =>
              propertyChange({
                numberOfMedia: e.target.value,
              })
            }
          />
        </FormRow>
      )}

      {schema?.technologyDictionary && (
        <FormRow
          controlId="technologyDictionary"
          label={schema?.technologyDictionary?.displayName}
        >
          <DictionarySelector
            dictionary="OOH - technologia"
            value={props.parameters?.technologyDictionary || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                technologyDictionary: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.regionDictionary && (
        <FormRow
          controlId="regionDictionary"
          label={schema?.regionDictionary?.displayName}
        >
          <DictionarySelector
            dictionary="OOH - rejon"
            value={props.parameters?.regionDictionary || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                regionDictionary: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.additionalDescription && (
        <FormRow
          controlId="additionalDescription"
          label={schema?.additionalDescription?.displayName}
        >
          <Form.Control
            type="text"
            value={props.parameters?.additionalDescription ?? ''}
            onChange={e =>
              propertyChange({
                additionalDescription: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {/* PRASA */}
      {schema?.formatPress && (
        <FormRow
          controlId="formatPress"
          label={schema?.formatPress?.displayName}
        >
          <DictionarySelector
            dictionary="Prasa - format"
            value={props.parameters?.formatPress || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatPress: option.value,
              })
            }
          />
        </FormRow>
      )}
      {/* RADIO */}
      {schema?.formatRadio && (
        <FormRow
          controlId="formatRadio"
          label={schema?.formatRadio?.displayName}
        >
          <DictionarySelector
            dictionary="Radio - format"
            value={props.parameters?.formatRadio || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatRadio: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.emissionTypeRadio && (
        <FormRow
          controlId="emissionTypeRadio"
          label={schema?.emissionTypeRadio?.displayName}
        >
          <DictionarySelector
            dictionary="Radio - typ emisji"
            value={props.parameters?.emissionTypeRadio || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                emissionTypeRadio: option.value,
              })
            }
          />
        </FormRow>
      )}
      {/* KINO */}
      {schema?.emissionTypeCinema && (
        <FormRow
          controlId="emissionTypeCinema"
          label={schema?.emissionTypeCinema?.displayName}
        >
          <DictionarySelector
            dictionary="Kino - typ emisji"
            value={props.parameters?.emissionTypeCinema || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                emissionTypeCinema: option.value,
              })
            }
          />
        </FormRow>
      )}
      {/* PROFILE PARAMS */}
      {schema?.medium && (
        <FormRow controlId="medium" label={schema?.medium?.displayName}>
          <DictionarySelector
            dictionary="Zagraniczne - medium"
            value={props.parameters?.medium || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                medium: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.ownName && (
        <FormRow controlId="ownName" label={schema?.ownName?.displayName}>
          <Form.Control
            type="text"
            value={props.parameters?.ownName ?? ''}
            onChange={e =>
              propertyChange({
                ownName: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.isUpSell && (
        <FormRow controlId="isUpSell" label={schema?.isUpSell?.displayName}>
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.parameters?.isUpSell}
            onChange={e => propertyChange({ isUpSell: e.target.checked })}
          />
        </FormRow>
      )}
      {/* TV */}
      {schema?.copySize && (
        <FormRow controlId="copySize" label={schema?.copySize?.displayName}>
          <DictionarySelector
            dictionary="TV - długość kopii"
            value={props.parameters?.copySize || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                copySize: option.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.orderNumber && (
        <FormRow
          controlId="orderNumber"
          label={schema?.orderNumber?.displayName}
        >
          <Form.Control
            type="text"
            value={props.parameters?.orderNumber ?? ''}
            onChange={e =>
              propertyChange({
                orderNumber: e.target.value,
              })
            }
          />
        </FormRow>
      )}
      {schema?.purchaseMethod && (
        <FormRow
          controlId="purchaseMethod"
          label={schema?.purchaseMethod?.displayName}
        >
          <DictionarySelector
            dictionary="TV - sposób zakupu"
            value={props.parameters?.purchaseMethod || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseMethod: option.value,
              })
            }
          />
        </FormRow>
      )}
    </>
  );
};
