import React, { Dispatch, SetStateAction, useState } from 'react';
import { Alert } from 'react-bootstrap';

import {
  CreateUpdatePurchaseInvoiceDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ValidationAlert from '../../../app/components/ValidationAlert';

import { PurchaseInvoiceEditorFormEstimateItemPositionsControls } from './PurchaseInvoiceEstimateItemPositionsControls';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import usePurchaseInvoiceEstimateItems from './usePurchaseInvoiceEstimateItems';
import { PurchaseInvoiceEstimateItemPositionsGrid } from './PurchaseInvoiceEstimateItemPositionsGrid';
import { PurchaseInvoiceEstimateItemPositionsFilters } from './PurchaseInvoiceEstimateItemPositionsFilters';
import usePurchaseInvoiceEstimateItemsFilters from './usePurchaseInvoiceEstimateItemsFilters';

interface PurchaseInvoiceEditorFormEstimateItemPositionsProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  setNotSavedTab: Dispatch<SetStateAction<PurchaseInvoiceTabsEnum | undefined>>;
  handleRefresh: () => Promise<void>;
  readOnly: boolean;
  onlyTV?: boolean;
}

export const PurchaseInvoiceEstimateItemPositions: React.FC<
  PurchaseInvoiceEditorFormEstimateItemPositionsProps
> = props => {
  const { filters, handleChangeFilters, applyFiltersToEstimateItems } =
    usePurchaseInvoiceEstimateItemsFilters(props.extendedPurchaseInvoice);
  const purchaseInvoiceEstimateItemsData = usePurchaseInvoiceEstimateItems({
    ...props,
    applyFiltersToEstimateItems,
    filters,
  });
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  if (purchaseInvoiceEstimateItemsData.isListEmpty) {
    return <Alert variant="warning">Brak danych.</Alert>;
  }

  return (
    <>
      <PurchaseInvoiceEstimateItemPositionsFilters
        filters={filters}
        handleChangeFilters={handleChangeFilters}
      />
      <PurchaseInvoiceEstimateItemPositionsGrid
        {...props}
        {...purchaseInvoiceEstimateItemsData}
      />
      <ValidationAlert
        show={!!saveErrors.length}
        errors={saveErrors}
        className="m-0 mt-4"
      />
      <PurchaseInvoiceEditorFormEstimateItemPositionsControls
        {...props}
        {...purchaseInvoiceEstimateItemsData}
        setSaveErrors={setSaveErrors}
      />
    </>
  );
};
