import { employeeRoleForGrid } from '../../../pok/components/EmployeesRoles/EmployeeRoleSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { FilterColumn } from '../../types/FilterColumn';
import {
  DefaultApi,
  GetEmployeeRoleDto,
  GetLastEditorDto,
  CreateUpdateEmployeeRoleDto,
  CreateUpdateEmployeeRoleDtoFromJSON,
  TeamInCompanyInTokenDto,
} from '../autogenerated/pokApiClient';
import {
  EmployeeRolesSchema,
  RoleEmployeesSchema,
} from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface EmployeeRoleContextInterface {
  getById: (id: string) => Promise<GetEmployeeRoleDto>;
  getWithTheSameUserByUserId: (id: string) => Promise<GetEmployeeRoleDto[]>;
  getWithTheSameRoleByRoleId: (id: string) => Promise<GetEmployeeRoleDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateEmployeeRoleDto) => Promise<void>;
  create: (dto: CreateUpdateEmployeeRoleDto) => Promise<GetEmployeeRoleDto>;
  createEmployeeWithRoles: (
    employeeId: string,
    employeeRoles: CreateUpdateEmployeeRoleDto[],
  ) => Promise<void>;
  delete: (employeeId: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  findTeamsWithCompanyByUser: (
    userId: string,
  ) => Promise<TeamInCompanyInTokenDto[]>;
}

export const EmployeeRoleContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.employeeRoleControllerGet(id),
    getWithTheSameUserByUserId: (id: string) =>
      api.employeeRoleControllerGetWithTheSameUserByUserId(id),
    getWithTheSameRoleByRoleId: (id: string) =>
      api.employeeRoleControllerGetWithTheSameRoleByRoleId(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.employeeRoleControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(employeeRoleForGrid);
    },

    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.employeeRoleControllerGetAllCount(filterText || '', filterColumns),

    update: (id: string, dto: CreateUpdateEmployeeRoleDto) =>
      api.employeeRoleControllerUpdate(id, dto),

    create: (dto: CreateUpdateEmployeeRoleDto) =>
      api.employeeRoleControllerCreate(dto),

    createEmployeeWithRoles: (
      employeeId: string,
      employeeRoles: CreateUpdateEmployeeRoleDto[],
    ) => {
      return api.employeeRoleControllerCreateEmployeeWithRoles({
        employeeId,
        employeeRoles,
      });
    },

    delete: (employeeId: string) =>
      api.employeeRoleControllerDelete(employeeId),

    getLastEditor: (id: string) => api.employeeRoleControllerGetLastEditor(id),
    findTeamsWithCompanyByUser: (userId: string) =>
      api.employeeRoleControllerFindTeamsWithCompanyByUser(userId),
  };
};

export const newEmployeeRole = () => {
  return CreateUpdateEmployeeRoleDtoFromJSON({});
};

export const validateEmployeeRoles = (emplRoles: {
  employeeId: string | undefined;
  rolesIds: string[] | undefined;
}) => validateAgainst(EmployeeRolesSchema, emplRoles);

export const validateRoleEmployee = (roleEmployees: {
  roleId: string | undefined;
  employeesIds: string[] | undefined;
}) => validateAgainst(RoleEmployeesSchema, roleEmployees);

export const convert = (employeeRole?: GetEmployeeRoleDto) => {
  if (!employeeRole) {
    return newEmployeeRole();
  }
  const converted = CreateUpdateEmployeeRoleDtoFromJSON(employeeRole);
  converted.roleId = employeeRole.role.id;
  converted.userId = employeeRole.user.id;
  converted.companyId = employeeRole.company?.id;
  converted.teamId = employeeRole.team?.id;
  converted.budgetId = employeeRole.budget?.id;

  return converted;
};
