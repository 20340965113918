import { Buffer } from 'buffer';

import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { useNotifications } from '../../common/hooks/useNotifications';
import { usePokCore } from '../../common/hooks/usePokCore';

export const InstructionsView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const getPdf = (name: string) => {
    pok.docs
      .getForFilename(name)
      .then(doc => {
        const file = new Blob([Buffer.from(doc.content, 'base64')], {
          type: 'application/pdf',
        });
        window.open(URL.createObjectURL(file));
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Instrukcje obsługi</Card.Title>
        <div className="d-grid gap-2">
          <Row>
            <Col md={6}>
              <div className="d-grid gap-2">
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() =>
                    getPdf(
                      'instructions/POK - Zakładanie kampanii i uzupełnianie 1.pdf',
                    )
                  }
                >
                  Zakładanie kampanii i uzupełnianie wniosków
                </Button>
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() =>
                    getPdf(
                      'instructions/POK - Import pozycji z kampanii do kampanii.pdf',
                    )
                  }
                >
                  Import pozycji z kampanii do kampanii
                </Button>
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() =>
                    getPdf('instructions/POK - Zamówienia do kampanii.pdf')
                  }
                >
                  Zamówienia do kampanii
                </Button>
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() =>
                    getPdf(
                      'instructions/POK - Realizacja zamówienia (specjaliści).pdf',
                    )
                  }
                >
                  Realizacja zamówienia (specjaliści)
                </Button>
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() =>
                    getPdf('instructions/POK - akceptacja faktur.pdf')
                  }
                >
                  Akceptacja faktur
                </Button>
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() =>
                    getPdf('instructions/POK - Import kampanii z arkuszy.pdf')
                  }
                >
                  Import kampanii z arkuszy
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};
