/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInvoiceEstimateItemDto
 */
export interface CreateUpdateInvoiceEstimateItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvoiceEstimateItemDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvoiceEstimateItemDto
     */
    commissionAmount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvoiceEstimateItemDto
     */
    estimateItemId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvoiceEstimateItemDto
     */
    salesInvoiceId: string;
}

/**
 * Check if a given object implements the CreateUpdateInvoiceEstimateItemDto interface.
 */
export function instanceOfCreateUpdateInvoiceEstimateItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "commissionAmount" in value;
    isInstance = isInstance && "estimateItemId" in value;
    isInstance = isInstance && "salesInvoiceId" in value;

    return isInstance;
}

export function CreateUpdateInvoiceEstimateItemDtoFromJSON(json: any): CreateUpdateInvoiceEstimateItemDto {
    return CreateUpdateInvoiceEstimateItemDtoFromJSONTyped(json, false);
}

export function CreateUpdateInvoiceEstimateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInvoiceEstimateItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'commissionAmount': json['commissionAmount'],
        'estimateItemId': json['estimateItemId'],
        'salesInvoiceId': json['salesInvoiceId'],
    };
}

export function CreateUpdateInvoiceEstimateItemDtoToJSON(value?: CreateUpdateInvoiceEstimateItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'commissionAmount': value.commissionAmount,
        'estimateItemId': value.estimateItemId,
        'salesInvoiceId': value.salesInvoiceId,
    };
}

