import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateBrandDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { Option } from '../Selects/Selector';
import BudgetSelector from '../Selects/BudgetSelector';
import Button from '../../../common/components/Button/Button';

interface BrandEditorProps {
  id?: string;
  systemNumber?: number;
  brand: CreateUpdateBrandDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    brand: CreateUpdateBrandDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const BrandEditor: React.FC<BrandEditorProps> = props => {
  const [brand, setBrand] = useState(props.brand);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setBrand(props.brand);
    }
  }, [props.brand, props.show]);

  const createBrand = async () => {
    setIsLoading(true);
    props
      .onCompleted(brand, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateBrandDto>) => {
    setBrand({ ...brand, ...obj });
  };

  const handleBudgetSelected = async (option: Option) => {
    if (option.value) {
      propertyChange({ budgetId: option.value });
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `Edycja brandu nr sys. ${props.systemNumber}`
              : 'Dodawanie nowego brandu'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3">
            <FormRow controlId="name" label="Nazwa">
              <Form.Control
                type="text"
                value={brand?.name || ''}
                onChange={e => propertyChange({ name: e.target.value })}
              />
            </FormRow>
            <FormRow controlId="budget" label="Budżet">
              <BudgetSelector
                value={brand?.budgetId}
                onChange={handleBudgetSelected}
              />
            </FormRow>
            <FormRow controlId="active" label="Aktywny">
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={brand?.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo id={props.id} method={pok.brands.getLastEditor} />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            Zamknij
          </Button>
          <Button variant="primary" onClick={createBrand} isLoading={isLoading}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
