/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateEstimateItemDto
 */
export interface CreateUpdateEstimateItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    estimateByMonthId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateEstimateItemDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateEstimateItemDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    positionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    positionType: CreateUpdateEstimateItemDtoPositionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    rcSalesTotal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesSurchargePercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesDiscountPercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesNet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesCommissionPercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesCommissionAmount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesNetTechnicalCost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesNetTotal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    rcPurchase?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseTechnicalCost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseDiscountPercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseNetTotal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseNet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseSurchargePercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesInvoicesSum?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    salesCommissionSum?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    profileCategoryId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateEstimateItemDto
     */
    isUpSell: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    netCustomerAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    numberOfMedia: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    additionalDescription: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    orderNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    ownName: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    technologyDictionaryId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    regionDictionaryId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    formatRadioId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    formatPressId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    formatInternetId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    formatInternetSTId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    emissionTypeRadioId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    emissionTypeCinemaId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseFormInternetId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseFormInternetSTId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    categoryInternetId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    categoryInternetSTId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    categoryLabconId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    copySizeId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    purchaseMethodId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    mediumId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    labconChannelId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    service: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    action: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    actionGroup: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    serviceExternalWorkPercent: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    serviceCreationPercent: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    serviceChangeProductionPercent: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    serviceStrategyPercent: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    serviceProductionBroadcastPercent: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    serviceClientServicePercent: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateEstimateItemDto
     */
    changeMediaplannerTs?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateItemDto
     */
    changeMediaplannerOwnerId?: string;
}


/**
 * @export
 */
export const CreateUpdateEstimateItemDtoPositionTypeEnum = {
    Standardowa: 'Standardowa',
    Prowizja: 'Prowizja',
    ProwizjaTransferowana: 'Prowizja-Transferowana'
} as const;
export type CreateUpdateEstimateItemDtoPositionTypeEnum = typeof CreateUpdateEstimateItemDtoPositionTypeEnum[keyof typeof CreateUpdateEstimateItemDtoPositionTypeEnum];


/**
 * Check if a given object implements the CreateUpdateEstimateItemDto interface.
 */
export function instanceOfCreateUpdateEstimateItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimateByMonthId" in value;
    isInstance = isInstance && "positionId" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "profileCategoryId" in value;
    isInstance = isInstance && "isUpSell" in value;
    isInstance = isInstance && "numberOfMedia" in value;
    isInstance = isInstance && "additionalDescription" in value;
    isInstance = isInstance && "orderNumber" in value;
    isInstance = isInstance && "ownName" in value;
    isInstance = isInstance && "technologyDictionaryId" in value;
    isInstance = isInstance && "regionDictionaryId" in value;
    isInstance = isInstance && "formatRadioId" in value;
    isInstance = isInstance && "formatPressId" in value;
    isInstance = isInstance && "formatInternetId" in value;
    isInstance = isInstance && "formatInternetSTId" in value;
    isInstance = isInstance && "emissionTypeRadioId" in value;
    isInstance = isInstance && "emissionTypeCinemaId" in value;
    isInstance = isInstance && "purchaseFormInternetId" in value;
    isInstance = isInstance && "purchaseFormInternetSTId" in value;
    isInstance = isInstance && "categoryInternetId" in value;
    isInstance = isInstance && "categoryInternetSTId" in value;
    isInstance = isInstance && "categoryLabconId" in value;
    isInstance = isInstance && "copySizeId" in value;
    isInstance = isInstance && "purchaseMethodId" in value;
    isInstance = isInstance && "mediumId" in value;
    isInstance = isInstance && "labconChannelId" in value;
    isInstance = isInstance && "service" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "actionGroup" in value;
    isInstance = isInstance && "serviceExternalWorkPercent" in value;
    isInstance = isInstance && "serviceCreationPercent" in value;
    isInstance = isInstance && "serviceChangeProductionPercent" in value;
    isInstance = isInstance && "serviceStrategyPercent" in value;
    isInstance = isInstance && "serviceProductionBroadcastPercent" in value;
    isInstance = isInstance && "serviceClientServicePercent" in value;

    return isInstance;
}

export function CreateUpdateEstimateItemDtoFromJSON(json: any): CreateUpdateEstimateItemDto {
    return CreateUpdateEstimateItemDtoFromJSONTyped(json, false);
}

export function CreateUpdateEstimateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateEstimateItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimateByMonthId': json['estimateByMonthId'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'positionId': json['positionId'],
        'comment': json['comment'],
        'positionType': json['positionType'],
        'rcSalesTotal': !exists(json, 'rcSalesTotal') ? undefined : json['rcSalesTotal'],
        'salesSurchargePercent': !exists(json, 'salesSurchargePercent') ? undefined : json['salesSurchargePercent'],
        'salesDiscountPercent': !exists(json, 'salesDiscountPercent') ? undefined : json['salesDiscountPercent'],
        'salesNet': !exists(json, 'salesNet') ? undefined : json['salesNet'],
        'salesCommissionPercent': !exists(json, 'salesCommissionPercent') ? undefined : json['salesCommissionPercent'],
        'salesCommissionAmount': !exists(json, 'salesCommissionAmount') ? undefined : json['salesCommissionAmount'],
        'salesNetTechnicalCost': !exists(json, 'salesNetTechnicalCost') ? undefined : json['salesNetTechnicalCost'],
        'salesNetTotal': !exists(json, 'salesNetTotal') ? undefined : json['salesNetTotal'],
        'rcPurchase': !exists(json, 'rcPurchase') ? undefined : json['rcPurchase'],
        'purchaseTechnicalCost': !exists(json, 'purchaseTechnicalCost') ? undefined : json['purchaseTechnicalCost'],
        'purchaseDiscountPercent': !exists(json, 'purchaseDiscountPercent') ? undefined : json['purchaseDiscountPercent'],
        'purchaseNetTotal': !exists(json, 'purchaseNetTotal') ? undefined : json['purchaseNetTotal'],
        'purchaseNet': !exists(json, 'purchaseNet') ? undefined : json['purchaseNet'],
        'purchaseSurchargePercent': !exists(json, 'purchaseSurchargePercent') ? undefined : json['purchaseSurchargePercent'],
        'salesInvoicesSum': !exists(json, 'salesInvoicesSum') ? undefined : json['salesInvoicesSum'],
        'salesCommissionSum': !exists(json, 'salesCommissionSum') ? undefined : json['salesCommissionSum'],
        'profileCategoryId': json['profileCategoryId'],
        'isUpSell': json['isUpSell'],
        'netCustomerAccount': !exists(json, 'netCustomerAccount') ? undefined : json['netCustomerAccount'],
        'numberOfMedia': json['numberOfMedia'],
        'additionalDescription': json['additionalDescription'],
        'orderNumber': json['orderNumber'],
        'ownName': json['ownName'],
        'technologyDictionaryId': json['technologyDictionaryId'],
        'regionDictionaryId': json['regionDictionaryId'],
        'formatRadioId': json['formatRadioId'],
        'formatPressId': json['formatPressId'],
        'formatInternetId': json['formatInternetId'],
        'formatInternetSTId': json['formatInternetSTId'],
        'emissionTypeRadioId': json['emissionTypeRadioId'],
        'emissionTypeCinemaId': json['emissionTypeCinemaId'],
        'purchaseFormInternetId': json['purchaseFormInternetId'],
        'purchaseFormInternetSTId': json['purchaseFormInternetSTId'],
        'categoryInternetId': json['categoryInternetId'],
        'categoryInternetSTId': json['categoryInternetSTId'],
        'categoryLabconId': json['categoryLabconId'],
        'copySizeId': json['copySizeId'],
        'purchaseMethodId': json['purchaseMethodId'],
        'mediumId': json['mediumId'],
        'labconChannelId': json['labconChannelId'],
        'service': json['service'],
        'action': json['action'],
        'actionGroup': json['actionGroup'],
        'serviceExternalWorkPercent': json['serviceExternalWorkPercent'],
        'serviceCreationPercent': json['serviceCreationPercent'],
        'serviceChangeProductionPercent': json['serviceChangeProductionPercent'],
        'serviceStrategyPercent': json['serviceStrategyPercent'],
        'serviceProductionBroadcastPercent': json['serviceProductionBroadcastPercent'],
        'serviceClientServicePercent': json['serviceClientServicePercent'],
        'changeMediaplannerTs': !exists(json, 'changeMediaplannerTs') ? undefined : (json['changeMediaplannerTs'] === null ? null : new Date(json['changeMediaplannerTs'])),
        'changeMediaplannerOwnerId': !exists(json, 'changeMediaplannerOwnerId') ? undefined : json['changeMediaplannerOwnerId'],
    };
}

export function CreateUpdateEstimateItemDtoToJSON(value?: CreateUpdateEstimateItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimateByMonthId': value.estimateByMonthId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'positionId': value.positionId,
        'comment': value.comment,
        'positionType': value.positionType,
        'rcSalesTotal': value.rcSalesTotal,
        'salesSurchargePercent': value.salesSurchargePercent,
        'salesDiscountPercent': value.salesDiscountPercent,
        'salesNet': value.salesNet,
        'salesCommissionPercent': value.salesCommissionPercent,
        'salesCommissionAmount': value.salesCommissionAmount,
        'salesNetTechnicalCost': value.salesNetTechnicalCost,
        'salesNetTotal': value.salesNetTotal,
        'rcPurchase': value.rcPurchase,
        'purchaseTechnicalCost': value.purchaseTechnicalCost,
        'purchaseDiscountPercent': value.purchaseDiscountPercent,
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseNet': value.purchaseNet,
        'purchaseSurchargePercent': value.purchaseSurchargePercent,
        'salesInvoicesSum': value.salesInvoicesSum,
        'salesCommissionSum': value.salesCommissionSum,
        'profileCategoryId': value.profileCategoryId,
        'isUpSell': value.isUpSell,
        'netCustomerAccount': value.netCustomerAccount,
        'numberOfMedia': value.numberOfMedia,
        'additionalDescription': value.additionalDescription,
        'orderNumber': value.orderNumber,
        'ownName': value.ownName,
        'technologyDictionaryId': value.technologyDictionaryId,
        'regionDictionaryId': value.regionDictionaryId,
        'formatRadioId': value.formatRadioId,
        'formatPressId': value.formatPressId,
        'formatInternetId': value.formatInternetId,
        'formatInternetSTId': value.formatInternetSTId,
        'emissionTypeRadioId': value.emissionTypeRadioId,
        'emissionTypeCinemaId': value.emissionTypeCinemaId,
        'purchaseFormInternetId': value.purchaseFormInternetId,
        'purchaseFormInternetSTId': value.purchaseFormInternetSTId,
        'categoryInternetId': value.categoryInternetId,
        'categoryInternetSTId': value.categoryInternetSTId,
        'categoryLabconId': value.categoryLabconId,
        'copySizeId': value.copySizeId,
        'purchaseMethodId': value.purchaseMethodId,
        'mediumId': value.mediumId,
        'labconChannelId': value.labconChannelId,
        'service': value.service,
        'action': value.action,
        'actionGroup': value.actionGroup,
        'serviceExternalWorkPercent': value.serviceExternalWorkPercent,
        'serviceCreationPercent': value.serviceCreationPercent,
        'serviceChangeProductionPercent': value.serviceChangeProductionPercent,
        'serviceStrategyPercent': value.serviceStrategyPercent,
        'serviceProductionBroadcastPercent': value.serviceProductionBroadcastPercent,
        'serviceClientServicePercent': value.serviceClientServicePercent,
        'changeMediaplannerTs': value.changeMediaplannerTs === undefined ? undefined : (value.changeMediaplannerTs === null ? null : value.changeMediaplannerTs.toISOString()),
        'changeMediaplannerOwnerId': value.changeMediaplannerOwnerId,
    };
}

