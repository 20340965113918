import { DefaultApi, ExchangeDto } from '../autogenerated/pokApiClient';

export interface ExchangeApiContextInterface {
  getExchangeRate: (isoCode: string, date: Date) => Promise<ExchangeDto>;
}

export const ExchangeApiContext = (api: DefaultApi) => {
  return {
    getExchangeRate: (isoCode: string, date: Date) =>
      api.exchangeApiControllerGetExchangeRate(isoCode, date),
  };
};
