/*eslint max-lines-per-function: ["error", 170]*/
/*eslint complexity: ["error", 14]*/

import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateOrderDto,
  GetOrderDtoStatusEnum,
  GetOrderEstimateItemDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import { useNotifications } from '../../../common/hooks/useNotifications';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { OrderStatusEnumText } from '../../../common/pokCore/validation/schemas';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useAuth } from '../../../common/hooks/useAuth';

import { OrderForm } from './OrderForm';

interface OrderEditorProps {
  id?: string;
  systemNumber?: number;
  project: GetProjectDto;
  resultProject?: GetProjectDto;
  order: CreateUpdateOrderDto;
  deactivateOrder: (id: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  handleClose?: () => void;
  readOnly?: boolean;
  show?: boolean;
  status?: GetOrderDtoStatusEnum;
  orderEstimateItems?: GetOrderEstimateItemDto[];
  rejectedNote?: string;
  onCompleted: (
    order: CreateUpdateOrderDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const OrderEditor: React.FC<OrderEditorProps> = props => {
  const [order, setOrder] = useState(props.order);
  const [emails, setEmails] = useState(props.order.emails || []);
  const [orderEstimateItemsIds, setOrderEstimateItemsIds] = useState<string[]>(
    props.order.orderEstimateItemsIds || [],
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const notifications = useNotifications();
  const pok = usePokCore();
  const auth = useAuth();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setOrder(props.order);
    }
  }, [props.order, props.show]);

  const createOrder = async () => {
    setIsLoadingSave(true);
    props
      .onCompleted({ ...order, emails, orderEstimateItemsIds }, order.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingSave(false));
  };

  const deactivateOrder = async () => {
    setIsLoadingRemove(true);
    props
      .deactivateOrder(order.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingRemove(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateOrderDto>) => {
    setOrder({ ...order, ...obj });
  };

  useEffect(() => {
    setEmails(props.order.emails);
    setOrderEstimateItemsIds(props.order.orderEstimateItemsIds);
  }, [props.order.emails, props.order.orderEstimateItemsIds]);

  const authorized = order?.id
    ? auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${
                  props.readOnly ? 'Szczegóły' : 'Edycja'
                } zamówienia (nr sys. ${props?.systemNumber})`
              : 'Utwórz zamówienie'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderForm
            order={order}
            resultProject={props?.resultProject}
            propertyChange={propertyChange}
            setEmails={mail => {
              setEmails(mail);
            }}
            emails={emails}
            setOrderEstimateItems={ids => {
              setOrderEstimateItemsIds(ids);
            }}
            readOnly={props.readOnly}
            orderEstimateItems={props.orderEstimateItems}
          />
          <Form className="d-grid gap-4 mb-3">
            {props.status && (
              <FormRow controlId="status" label="Status">
                <Form.Control
                  value={
                    OrderStatusEnumText[props.status] +
                    (!props.order.active ? ' - usunięte' : '')
                  }
                  disabled
                />
              </FormRow>
            )}
            {props.rejectedNote && (
              <FormRow controlId="rejectedNote" label="Uzasadnienie odrzucenia">
                <DynamicTextarea disabled readOnly value={props.rejectedNote} />
              </FormRow>
            )}
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-items-center w-100">
            {props.id && (
              <LastEditorInfo
                id={props.id}
                className="color-primary"
                method={pok.orders.getLastEditor}
              />
            )}
            <div className="d-flex marginLeft gap-2">
              {((order.id && !props.readOnly) || authorized) && (
                <ConfirmationButton
                  onOK={deactivateOrder}
                  confirmation="Czy na pewno chcesz usunąć zamówienie?"
                  variant="outline-danger"
                  isLoading={isLoadingRemove}
                >
                  Usuń
                </ConfirmationButton>
              )}
              <Button variant="outline-secondary" onClick={props.handleClose}>
                Zamknij
              </Button>
              {!props.readOnly && (
                <Button
                  variant="primary"
                  onClick={createOrder}
                  isLoading={isLoadingSave}
                >
                  Zapisz
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
