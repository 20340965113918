/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExcelDto
 */
export interface CreateUpdateExcelDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelDto
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelDto
     */
    projectId?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateUpdateExcelDto
     */
    fileContent: object;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelDto
     */
    profileId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExcelDto
     */
    date?: Date | null;
}

/**
 * Check if a given object implements the CreateUpdateExcelDto interface.
 */
export function instanceOfCreateUpdateExcelDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileContent" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function CreateUpdateExcelDtoFromJSON(json: any): CreateUpdateExcelDto {
    return CreateUpdateExcelDtoFromJSONTyped(json, false);
}

export function CreateUpdateExcelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExcelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'configId': !exists(json, 'configId') ? undefined : json['configId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'fileContent': json['fileContent'],
        'fileName': json['fileName'],
        'companyId': json['companyId'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
    };
}

export function CreateUpdateExcelDtoToJSON(value?: CreateUpdateExcelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configId': value.configId,
        'projectId': value.projectId,
        'fileContent': value.fileContent,
        'fileName': value.fileName,
        'companyId': value.companyId,
        'profileId': value.profileId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
    };
}

