import {
  CreateUpdateEmailConfigDto,
  CreateUpdateEmailConfigDtoFromJSON,
  DefaultApi,
  GetEmailConfigDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { EmailConfigSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { emailConfigForGrid } from '../../../pok/components/EmailConfig/EmailConfigSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { FilterColumn } from '../../types/FilterColumn';

export interface EmailConfigContextInterface {
  getById: (id: string) => Promise<GetEmailConfigDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateEmailConfigDto) => Promise<void>;
  create: (dto: CreateUpdateEmailConfigDto) => Promise<GetEmailConfigDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const EmailConfigContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.emailConfigControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.emailConfigControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(emailConfigForGrid);
    },
    getAllCount: async (filterText?: string, filterColumns?: FilterColumn) =>
      api.emailConfigControllerGetAllCount(filterText || '', filterColumns),
    update: (id: string, dto: CreateUpdateEmailConfigDto) =>
      api.emailConfigControllerUpdate(id, dto),
    create: (dto: CreateUpdateEmailConfigDto) =>
      api.emailConfigControllerCreate(dto),
    getLastEditor: (id: string) => api.emailConfigControllerGetLastEditor(id),
  };
};

export const newEmailConfig = () => {
  return CreateUpdateEmailConfigDtoFromJSON({
    teamIds: [],
    emails: [],
  });
};

export const validate = (emailConfig: CreateUpdateEmailConfigDto) => {
  return validateAgainst(EmailConfigSchema, emailConfig);
};

export const convert = (emailConfig?: GetEmailConfigDto) => {
  if (!emailConfig) {
    return newEmailConfig();
  }
  const converted = CreateUpdateEmailConfigDtoFromJSON(emailConfig);

  converted.companyId = emailConfig.company?.id;
  converted.teamIds =
    emailConfig.emailConfigTeams?.map(({ team }) => team.id) || [];

  return converted;
};
