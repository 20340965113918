/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleInCompanyInTokenDto } from './RoleInCompanyInTokenDto';
import {
    RoleInCompanyInTokenDtoFromJSON,
    RoleInCompanyInTokenDtoFromJSONTyped,
    RoleInCompanyInTokenDtoToJSON,
} from './RoleInCompanyInTokenDto';
import type { TeamInCompanyInTokenDto } from './TeamInCompanyInTokenDto';
import {
    TeamInCompanyInTokenDtoFromJSON,
    TeamInCompanyInTokenDtoFromJSONTyped,
    TeamInCompanyInTokenDtoToJSON,
} from './TeamInCompanyInTokenDto';

/**
 * 
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    refreshToken: string;
    /**
     * 
     * @type {Array<RoleInCompanyInTokenDto>}
     * @memberof TokenDto
     */
    rolesInCompanies: Array<RoleInCompanyInTokenDto>;
    /**
     * 
     * @type {Array<TeamInCompanyInTokenDto>}
     * @memberof TokenDto
     */
    teamsInCompanies: Array<TeamInCompanyInTokenDto>;
}

/**
 * Check if a given object implements the TokenDto interface.
 */
export function instanceOfTokenDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "rolesInCompanies" in value;
    isInstance = isInstance && "teamsInCompanies" in value;

    return isInstance;
}

export function TokenDtoFromJSON(json: any): TokenDto {
    return TokenDtoFromJSONTyped(json, false);
}

export function TokenDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
        'rolesInCompanies': ((json['rolesInCompanies'] as Array<any>).map(RoleInCompanyInTokenDtoFromJSON)),
        'teamsInCompanies': ((json['teamsInCompanies'] as Array<any>).map(TeamInCompanyInTokenDtoFromJSON)),
    };
}

export function TokenDtoToJSON(value?: TokenDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'rolesInCompanies': ((value.rolesInCompanies as Array<any>).map(RoleInCompanyInTokenDtoToJSON)),
        'teamsInCompanies': ((value.teamsInCompanies as Array<any>).map(TeamInCompanyInTokenDtoToJSON)),
    };
}

