import { useNavigate } from 'react-router-dom';

function useChangeTab<T>() {
  const navigate = useNavigate();

  return (key: T) =>
    navigate(`${location.pathname}${location.search || ''}`, {
      state: {
        tabKey: key,
      },
    });
}

export default useChangeTab;
