import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { CreateUpdateBudgetDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import BudgetBrandSelector from '../Selects/BudgetBrandSelector';

interface BudgetEditorFormProps {
  id?: string;
  refresh?: boolean;
  budget: CreateUpdateBudgetDto;
  propertyChange?: (obj: Partial<CreateUpdateBudgetDto>) => void;
  readOnly?: boolean;
}

export const BudgetEditorForm: React.FC<BudgetEditorFormProps> = props => {
  const [selectedBudgetOptions, setSelectedBudgetOptions] =
    useState<Option[]>();

  const handleEmployeeSelected = async (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if ((option as Option)?.value) {
      props.propertyChange &&
        props.propertyChange({ managerId: (option as Option)?.value });
    }
  };

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="name" label="Nazwa">
          <Form.Control
            type="text"
            value={props.budget?.name || ''}
            onChange={e =>
              props.propertyChange &&
              props.propertyChange({ name: e.target.value })
            }
            disabled={props.readOnly}
          />
        </FormRow>
        <FormRow controlId="manager" label="Szef budżetu">
          <EmployeeSelector
            value={props.budget?.managerId}
            onChange={handleEmployeeSelected}
            readOnly={props.readOnly}
          />
        </FormRow>
        <FormRow controlId="brandIds" label="Brandy">
          <BudgetBrandSelector
            isMulti={true}
            budgetId={props.id}
            refresh={props.refresh}
            value={selectedBudgetOptions?.map(({ value }) => value)}
            valueOption={selectedBudgetOptions}
            onChange={options => {
              const convertedOptions = options as Option[];
              setSelectedBudgetOptions(convertedOptions);
              if (props.propertyChange) {
                props.propertyChange({
                  brandIds: convertedOptions.map(({ value }) => value),
                });
              }
            }}
            readOnly={props.readOnly}
            placeholder={props.readOnly ? '' : 'Wpisz...'}
          />
        </FormRow>
        <FormRow
          controlId="downloadDocuments"
          label="Pobieranie hurtowe dokumentów"
        >
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.budget?.downloadDocuments ?? true}
            onChange={e =>
              props.propertyChange &&
              props.propertyChange({ downloadDocuments: e.target.checked })
            }
            disabled={props.readOnly}
          />
        </FormRow>
        {!props.readOnly && (
          <FormRow controlId="active" label="Aktywny">
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={props.budget?.active ?? true}
              onChange={e =>
                props.propertyChange &&
                props.propertyChange({ active: e.target.checked })
              }
              disabled={props.readOnly}
            />
          </FormRow>
        )}
      </Form>
    </>
  );
};
