import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { SalesInvoiceSearch } from '../../components/SalesInvoices/SalesInvoiceSearch';
import { SalesInvoiceEditorTabs } from '../SalesInvoiceView/SalesInvoiceEditorTabs';
import { useNavigation } from '../../../common/navigation';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import useConfirmationModal from '../../../common/hooks/useConfirmationModal';

export const SalesInvoicesReadyToCorrection: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const { confirm, RenderModal } = useConfirmationModal();
  const [isLoading, setIsLoading] = useState(false);

  const createCorrection = async (id: string) => {
    try {
      const salesInvoice = await pok.salesInvoices.getById(id);
      await confirm(
        `Uwaga: zostanie automatycznie utworzony szkic korekty do faktury sprzedaży nr ${
          salesInvoice?.originalInvoice?.number || salesInvoice.number
        }. Czy na pewno chcesz kontynuować?`,
      );
      setIsLoading(true);
      pok.salesInvoices
        .createCorrection(id)
        .then(data => {
          nav.salesInvoiceEditor({
            salesInvoiceId: data.id,
            tab: SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
            notSavedTab: SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
          });
        })
        .catch(async (response: ResponseError) => {
          const errors = await responseErrors(response);
          notifications.error(errors.join('. '));
        })
        .finally(() => setIsLoading(false));
    } catch {
      /* empty */
    }
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          Faktury sprzedaży do skorygowania
        </Card.Title>
        <SalesInvoiceSearch
          getData={pok.salesInvoices.getAllByPortionByNegativePositionsDiff}
          getCount={pok.salesInvoices.getAllCountByNegativePositionsDiff}
          onRowClick={createCorrection}
        />
      </Card.Body>
      <RenderModal size="lg" isLoading={isLoading} />
    </Card>
  );
};
