import React from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

import { PurchaseInvoiceSearch } from './PurchaseInvoiceSearch';

enum PurchaseInvoicesTabs {
  ALL = 'Wszystkie',
  SETTLED = 'Wprowadzone, rozliczone',
  WITHOUT_TEAMS_PROJECTS = 'Bez zespołów i projektów',
  GENERATED = 'Wprowadzone',
}

export const PurchaseInvoicesView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();

  const handleClick = (id: string = 'utworz', newWindow?: boolean) => {
    nav.purchaseInvoiceEditor(id, newWindow);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Faktury zakupu</Card.Title>
        <Button
          variant="outline-primary"
          onClick={() => handleClick()}
          className="mb-3"
        >
          Nowa faktura zakupu
        </Button>
        <TabsHistory
          activeKey={PurchaseInvoicesTabs.GENERATED}
          id="purchaseInvoicesView"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={PurchaseInvoicesTabs.GENERATED}
            title={PurchaseInvoicesTabs.GENERATED}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={pok.purchaseInvoices.getAllGeneratedByPortion}
              getCount={pok.purchaseInvoices.getCountGenerated}
              onRowClick={handleClick}
              withCountDays={true}
              displayCurrency={true}
              displayTeamsProjects={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={PurchaseInvoicesTabs.SETTLED}
            title={PurchaseInvoicesTabs.SETTLED}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={pok.purchaseInvoices.getAllGeneratedSettledByPortion}
              getCount={pok.purchaseInvoices.getCountGeneratedSettled}
              onRowClick={handleClick}
              withCountDays={true}
              displayCurrency={true}
              displayTeamsProjects={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={PurchaseInvoicesTabs.WITHOUT_TEAMS_PROJECTS}
            title={PurchaseInvoicesTabs.WITHOUT_TEAMS_PROJECTS}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={
                pok.purchaseInvoices.getAllWithoutTeamsAndProjectsPortion
              }
              getCount={pok.purchaseInvoices.getCountWithoutTeamsAndProjects}
              onRowClick={handleClick}
              withCountDays={true}
              displayCurrency={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={PurchaseInvoicesTabs.ALL}
            title={PurchaseInvoicesTabs.ALL}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={pok.purchaseInvoices.getAllByPortion}
              getCount={pok.purchaseInvoices.getAllCount}
              onRowClick={handleClick}
              displayStatus={true}
              displayCurrency={true}
              withCountDays={true}
              displayTeamsProjects={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
