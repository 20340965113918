import React from 'react';
import { Card } from 'react-bootstrap';

import { PurchaserTab } from '../../components/Purchasers/PurchaserTab';

export const AllPurchasersView: React.FC = () => (
  <Card className="main-card">
    <Card.Body className="m-3">
      <Card.Title className="pb-3">Zamawiający</Card.Title>
      <PurchaserTab />
    </Card.Body>
  </Card>
);
