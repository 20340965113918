import React, { useState } from 'react';
import { uniq } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  GetProjectDto,
  GetSalesInvoiceDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { validateInvoiceEstimateItems } from '../../../common/pokCore/contexts/SalesInvoicesContext';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';

import { SalesInvoiceMediaPlanPositionsGrid } from './SalesInvoiceMediaPlanPositionsGrid';
import { SalesInvoiceMediaPlanPositionsControls } from './SalesInvoiceMediaPlanPositionsControls';
import useSalesInvoiceMediaplanPositions from './useSalesInvoiceMediaplanPositions';

interface SalesInvoiceMediaPlanPositionsProps {
  readOnly?: boolean;
  salesInvoice?: GetSalesInvoiceDto;
  id?: string;
  handleRefresh: () => void;
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void;
  projects: GetProjectDto[];
  parentAmount?: string;
}

export const SalesInvoiceMediaPlanPositions: React.FC<
  SalesInvoiceMediaPlanPositionsProps
> = props => {
  const {
    availableEstimateItems,
    salesInvoiceEstimateItems,
    salesInvoiceOriginalEstimateItems,
    isLoading,
    propertyChange,
  } = useSalesInvoiceMediaplanPositions(props);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const pok = usePokCore();
  const notifications = useNotifications();
  const isCorrection = !!props.salesInvoice?.parent;

  const handleGoToNextStep = () => {
    props.handleRefresh();
    navigate(`${location.pathname}${location.search || ''}`, {
      state: {
        tabKey: SalesInvoiceEditorTabs.INVOICE_POSITIONS,
        resetSalesInvoicePositions: !isCorrection,
      },
    });
  };

  const onComplete = async (): Promise<{
    saved: boolean;
    errors: string[];
  }> => {
    if (salesInvoiceEstimateItems && props.salesInvoice) {
      const { errors, isValid } = await validateInvoiceEstimateItems(
        salesInvoiceEstimateItems,
        availableEstimateItems,
        props.salesInvoice,
      );

      if (!isValid) {
        return {
          saved: false,
          errors: uniq(errors),
        };
      }

      try {
        setIsSaving(true);
        await pok.salesInvoices.createEstimateItemPositions({
          estimateItems: salesInvoiceEstimateItems.filter(
            ({ amount, commissionAmount }) =>
              amount !== '0' || commissionAmount !== '0' || isCorrection,
          ),
          salesInvoiceId: props.salesInvoice?.id,
        });
        props.setNotSavedTab(undefined);

        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      } finally {
        setIsSaving(false);
      }
    }

    return {
      saved: false,
      errors: [],
    };
  };

  const createInvoiceEstimateItems = async () => {
    onComplete()
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        setSaveErrors(saveStatus.errors);
        setShowSaveErrors(false);
        notifications.saveCompleted();
        handleGoToNextStep();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <>
      {isLoading ? (
        <FormSkeleton />
      ) : (
        <SalesInvoiceMediaPlanPositionsGrid
          {...props}
          availableEstimateItems={availableEstimateItems}
          salesInvoiceEstimateItems={salesInvoiceEstimateItems}
          salesInvoiceOriginalEstimateItems={salesInvoiceOriginalEstimateItems}
          propertyChange={propertyChange}
          parentAmount={props.parentAmount}
        />
      )}
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        className="m-3"
      />
      <SalesInvoiceMediaPlanPositionsControls
        isSaving={isSaving}
        salesInvoice={props.salesInvoice}
        createInvoiceEstimateItems={createInvoiceEstimateItems}
        isSaveDisabled={
          !availableEstimateItems ||
          !salesInvoiceEstimateItems ||
          !!props.readOnly
        }
      />
    </>
  );
};
