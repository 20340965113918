import {
  CreateUpdateCompanyDto,
  CreateUpdateCompanyDtoFromJSON,
  DefaultApi,
  GetCompanyDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { GetCompanyWithoutLogoDto } from '../autogenerated/pokApiClient/models/GetCompanyWithoutLogoDto';
import { CompanySchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface CompanyContextInterface {
  getById: (id: string) => Promise<GetCompanyDto>;
  getAllNotAssignedAndCurrent: (
    companyId: string | undefined,
  ) => Promise<GetCompanyWithoutLogoDto[]>;
  getCompanyIdByClientId: (clientId: string) => Promise<string | undefined>;
  getMany: () => Promise<GetCompanyDto[]>;
  getManyWithoutLogo: () => Promise<GetCompanyWithoutLogoDto[]>;
  update: (id: string, dto: CreateUpdateCompanyDto) => Promise<void>;
  create: (dto: CreateUpdateCompanyDto) => Promise<GetCompanyDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const CompanyContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.companyControllerGet(id),
    getAllNotAssignedAndCurrent: (companyId: string | undefined) =>
      api.companyControllerGetAllNotAssignedAndCurrent(companyId),
    getCompanyIdByClientId: async (clientId: string) => {
      const companyId =
        await api.companyControllerGetCompanyIdByClientId(clientId);
      return companyId && companyId !== '' ? companyId : undefined;
    },
    getMany: () => api.companyControllerGetMany(),
    getManyWithoutLogo: () => api.companyControllerGetManyWithoutLogo(),
    update: (id: string, dto: CreateUpdateCompanyDto) =>
      api.companyControllerUpdate(id, dto),
    create: (dto: CreateUpdateCompanyDto) => api.companyControllerCreate(dto),
    getLastEditor: (id: string) => api.companyControllerGetLastEditor(id),
  };
};

export const newCompany = (clientId?: string) => {
  return CreateUpdateCompanyDtoFromJSON({
    clientId: clientId,
  });
};

export const validate = (dictionary: CreateUpdateCompanyDto) =>
  validateAgainst(CompanySchema, dictionary);

export const convert = (company?: GetCompanyDto) => {
  if (!company) {
    return newCompany();
  }
  const converted = CreateUpdateCompanyDtoFromJSON(company);

  return converted;
};
