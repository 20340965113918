export default {
  menu: {
    projects: 'Projekty',
    projectsAndTeams: 'Projekty i zespoły',
    projectsApplications: 'Wnioski do projektów',
    delayedProjects: 'Projekty po terminie',
    canceledProjects: 'Projekty do anulowania',
    project: 'Projekt',
    genitiveProject: 'projektu',
    accusativeProject: 'projekt',
    genitiveProjectPlural: 'projektów',
    projectNumber: 'Numer projektu',
    internalProject: 'Projekt zlecający',
    projectList: 'Lista projektów',
    withProjects: 'z projektami',
    connectionsWithProjectsFVZ:
      'Powiązania z projektami zostaną wyjaśnione przez zespoły',
    estimate: 'Kosztorys',
    estimate2: 'kosztorysu',
    estimates: 'Kosztorysy',
    addNewProject: 'Dodawanie nowego projektu',
    newProjectName: 'Nazwa nowego projektu',
    chooseProject: 'Wybieranie projektu',
    sourceProject: 'Projekt źródłowy',
    sourceProject2: 'projekt źródłowy',
    addProject: 'Dodaj projekt',
    editProject: 'Edycja projektu',
    emptyProjectsList: 'Brak projektów w systemie.',
    projectNotFound: 'Nie znaleziono projektu o takim ID.',
    salesInvoiceToProject: 'do projektu',
    estimateItemPosition: 'Pozycja kosztorysu',
    addEstimateItemPosition: 'Dodaj pozycje kosztorysu',
    chooseEstimateItem: 'Wybieranie pozycji kosztorysu',
    estimateItemInvoiceModifyWarning:
      'Są już zapisane pozycje faktury. Czy na pewno chcesz zaktualizować pozycje kosztorysu?',
    estimateItemInvoiceTabTitle: 'Pozycje kosztorysu',
    deleteProjectWarning: 'Czy na pewno usunąć wybrany projekt?',
    deleteProjectTooltip: 'Usuń projekt',
    unsettledPurchaseInvoicesAssignedToProject:
      'Nierozliczone FVZ przypisane do projektów',
    purchaseInvoicesAssignedToProject: 'Faktury zakupu przypisane do projektów',
    orderResultProject: 'Projekt podzlecony',
    readyForInvoicing: 'Projekty gotowe do fakturowania',
    readyForCorrection: 'Projekty z ujemną kwotą do fakturowania',
    applicationUnblocks: 'Wnioski o odblokowanie kosztorysu',
    projectsListModifiedInvoiceAlert:
      'Lista projektów została zmodyfikowana. Po zapisie nastąpi zresetowanie pozycji kosztorysu oraz pozycji faktury.',
    selectProjectsPurchaseInvoiceAlert:
      'Aby dodać kilka projektów należy zaznaczyć je klikając w wiersz, zaznaczone projekty zmienią kolor na żółty. Po zapisaniu zmian, wybrane projekty (jeden lub więcej) zostaną dodane',
    selectProjectPurchaseInvoiceAlertError:
      'Proszę zaznaczyć jeden lub kilka projektów.',
    sameProject: 'tego samego projektu',
  },
} as const;
