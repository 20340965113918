import React from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import Select, { MultiValue } from 'react-select';

import {
  CreateUpdatePositionDto,
  CreateUpdatePositionDtoPositionTypeEnum,
  GetClientDto,
  GetProfileDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { Option } from '../Selects/Selector';
import DictionarySelector from '../Selects/DictionarySelector';
import { PositionTypeEnum } from '../../../common/pokCore/validation/schemas';
import TeamSelector from '../Selects/TeamSelector';
import CompanySelector from '../Selects/CompanySelector';
import { ClientSelector } from '../Selects/ClientSelector';
import ProfileSelector from '../Selects/ProfileSelector';

interface PositionEditorFormProps {
  id?: string;
  position: CreateUpdatePositionDto;
  client?: GetClientDto;
  team?: GetTeamDto;
  teams?: GetTeamDto[];
  profiles?: GetProfileDto[];
  companyId?: string | null;
  propertyChange: (obj: Partial<CreateUpdatePositionDto>) => void;
  positionsProvider: (text: string) => Promise<Option[]>;
}

const getOptionValues = (
  options: Option | MultiValue<Option> | null | undefined,
) => (options ? (options as Option[]).map(({ value }) => value) : []);

const positionTypeKeys = Object.keys(PositionTypeEnum);
const positionTypeValues = Object.values(PositionTypeEnum);
const POSITION_TYPE_OPTIONS = positionTypeKeys.map((value, i) => ({
  value,
  label: positionTypeValues[i],
}));

export const PositionEditorForm: React.FC<PositionEditorFormProps> = props => {
  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="client" label="Kontrahent">
        <ClientSelector
          onChange={e =>
            props.propertyChange({
              clientId: (e as Option).value,
            })
          }
          value={props.client}
          readOnly={!!props.client?.id}
        />
      </FormRow>
      <FormRow controlId="name" label="Nazwa">
        <Form.Control
          type="text"
          value={props.position.name || ''}
          onChange={e =>
            props.propertyChange({ name: e.target.value || undefined })
          }
        />
      </FormRow>
      <FormRow controlId="groupName" label="Nazwa grupy">
        <CreatableClearableSelector
          value={props.position?.groupName}
          onChange={option =>
            props.propertyChange({
              groupName: (option as Option)?.label,
            })
          }
          provider={props.positionsProvider}
        />
      </FormRow>
      <FormRow controlId="positionType" label="Typ pozycji">
        <Select
          value={
            props.position?.positionType
              ? {
                  label: props.position.positionType.toString(),
                  value: Object.keys(CreateUpdatePositionDtoPositionTypeEnum)[
                    Object.values(
                      CreateUpdatePositionDtoPositionTypeEnum,
                    ).indexOf(props.position.positionType)
                  ].toString(),
                }
              : null
          }
          options={POSITION_TYPE_OPTIONS}
          defaultValue={undefined}
          onChange={option =>
            props.propertyChange({
              positionType: option?.value
                ? (PositionTypeEnum[
                    option.value as keyof typeof PositionTypeEnum
                  ] as unknown as CreateUpdatePositionDtoPositionTypeEnum)
                : undefined,
            })
          }
          placeholder="Wskaż..."
        />
      </FormRow>
      <FormRow controlId="dictionaryId" label="Kanał komunikacji">
        <DictionarySelector
          dictionary="Kanały komunikacji"
          value={props.position?.dictionaryId}
          onChange={option =>
            props.propertyChange({ dictionaryId: option.value || undefined })
          }
        />
      </FormRow>
      <FormRow controlId="companies" label="Spółki">
        <CompanySelector
          isMulti={true}
          value={props.position.companyIds}
          onChange={options => {
            props.propertyChange({
              companyIds: getOptionValues(options),
            });
          }}
        />
      </FormRow>
      <FormRow controlId="teams" label="Zespoły">
        <TeamSelector
          isMulti={true}
          value={props.position.teamIds || []}
          clearable={true}
          companyIds={props.position.companyIds}
          onChange={options => {
            props.propertyChange({
              teamIds: getOptionValues(options),
            });
          }}
        />
      </FormRow>
      <FormRow controlId="profiles" label="Profile mediaplanów">
        <ProfileSelector
          isMulti={true}
          value={props.position.profileIds || []}
          companyIds={props.position.companyIds}
          teamIds={props.position.teamIds ?? []}
          onChange={options => {
            props.propertyChange({
              profileIds: getOptionValues(options),
            });
          }}
        />
      </FormRow>
      {props.companyId && (
        <FormRow controlId="team" label="Zespół specjalistyczny">
          <TeamSelector
            value={props.team?.id}
            companyId={props.companyId}
            clearable={true}
            onChange={option => {
              const newOption = option as Option;
              props.propertyChange({ teamId: newOption?.value || undefined });
            }}
          />
        </FormRow>
      )}
      <FormRow
        controlId="notPurchaseDeficiencies"
        label="Nie podlega brakom FVZ"
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.position.notPurchaseDeficiencies ?? false}
          onChange={e =>
            props.propertyChange({ notPurchaseDeficiencies: e.target.checked })
          }
        />
      </FormRow>
      <FormRow controlId="active" label="Aktywna">
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.position.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
    </Form>
  );
};
