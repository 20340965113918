/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPaeEstimateItemsDto } from './GetPaeEstimateItemsDto';
import {
    GetPaeEstimateItemsDtoFromJSON,
    GetPaeEstimateItemsDtoFromJSONTyped,
    GetPaeEstimateItemsDtoToJSON,
} from './GetPaeEstimateItemsDto';

/**
 * 
 * @export
 * @interface CreatePaeImportDto
 */
export interface CreatePaeImportDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaeImportDto
     */
    estimateByMonthId: string;
    /**
     * 
     * @type {Array<GetPaeEstimateItemsDto>}
     * @memberof CreatePaeImportDto
     */
    positions: Array<GetPaeEstimateItemsDto>;
}

/**
 * Check if a given object implements the CreatePaeImportDto interface.
 */
export function instanceOfCreatePaeImportDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimateByMonthId" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function CreatePaeImportDtoFromJSON(json: any): CreatePaeImportDto {
    return CreatePaeImportDtoFromJSONTyped(json, false);
}

export function CreatePaeImportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaeImportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimateByMonthId': json['estimateByMonthId'],
        'positions': ((json['positions'] as Array<any>).map(GetPaeEstimateItemsDtoFromJSON)),
    };
}

export function CreatePaeImportDtoToJSON(value?: CreatePaeImportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimateByMonthId': value.estimateByMonthId,
        'positions': ((value.positions as Array<any>).map(GetPaeEstimateItemsDtoToJSON)),
    };
}

