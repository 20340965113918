import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';

import { OrderSearch } from './OrderSearch';

export const OrdersCompletedTab: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();

  return (
    <OrderSearch
      getData={pok.orders.getAllByPortionCompleted}
      getCount={pok.orders.getAllCountCompleted}
      onRowClick={nav.orderEditorByTeam}
    />
  );
};
