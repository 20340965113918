/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBudgetDto } from './GetBudgetDto';
import {
    GetBudgetDtoFromJSON,
    GetBudgetDtoFromJSONTyped,
    GetBudgetDtoToJSON,
} from './GetBudgetDto';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetPurchaserDto
 */
export interface GetPurchaserDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaserDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaserDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaserDto
     */
    name: string;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetPurchaserDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetPurchaserDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {GetBudgetDto}
     * @memberof GetPurchaserDto
     */
    budget: GetBudgetDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaserDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<GetTeamDto>}
     * @memberof GetPurchaserDto
     */
    teams?: Array<GetTeamDto>;
}

/**
 * Check if a given object implements the GetPurchaserDto interface.
 */
export function instanceOfGetPurchaserDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "budget" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetPurchaserDtoFromJSON(json: any): GetPurchaserDto {
    return GetPurchaserDtoFromJSONTyped(json, false);
}

export function GetPurchaserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'name': json['name'],
        'company': GetCompanyDtoFromJSON(json['company']),
        'client': GetClientDtoFromJSON(json['client']),
        'budget': GetBudgetDtoFromJSON(json['budget']),
        'active': json['active'],
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(GetTeamDtoFromJSON)),
    };
}

export function GetPurchaserDtoToJSON(value?: GetPurchaserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'name': value.name,
        'company': GetCompanyDtoToJSON(value.company),
        'client': GetClientDtoToJSON(value.client),
        'budget': GetBudgetDtoToJSON(value.budget),
        'active': value.active,
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(GetTeamDtoToJSON)),
    };
}

