/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';

/**
 * 
 * @export
 * @interface GetOrderExecutionEmployeeDto
 */
export interface GetOrderExecutionEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof GetOrderExecutionEmployeeDto
     */
    id: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetOrderExecutionEmployeeDto
     */
    employee: GetEmployeeDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderExecutionEmployeeDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetOrderExecutionEmployeeDto interface.
 */
export function instanceOfGetOrderExecutionEmployeeDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "employee" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetOrderExecutionEmployeeDtoFromJSON(json: any): GetOrderExecutionEmployeeDto {
    return GetOrderExecutionEmployeeDtoFromJSONTyped(json, false);
}

export function GetOrderExecutionEmployeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderExecutionEmployeeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employee': GetEmployeeDtoFromJSON(json['employee']),
        'active': json['active'],
    };
}

export function GetOrderExecutionEmployeeDtoToJSON(value?: GetOrderExecutionEmployeeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'employee': GetEmployeeDtoToJSON(value.employee),
        'active': value.active,
    };
}

