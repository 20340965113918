/*eslint complexity: ["error", 15]*/
import {
  CreateUpdateEstimateItemDto,
  CreateUpdateEstimateItemDtoPositionTypeEnum,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { PositionTypeEnum } from '../../../common/pokCore/validation/schemas';

export const fieldsToClear = (
  positionType: string,
  commission: boolean | undefined,
) => {
  if (!commission) {
    return {};
  }
  if (positionType === PositionTypeEnum.Standard) {
    return {
      salesCommissionAmount: null,
    };
  }
  if (positionType === PositionTypeEnum.Commission) {
    return {
      salesNet: null,
      purchaseNetTotal: null,
      isUpSell: false,
      netCustomerAccount: null,
      numberOfMedia: null,
      additionalDescription: null,
      orderNumber: null,
      ownName: null,
      technologyDictionaryId: null,
      regionDictionaryId: null,
      formatRadioId: null,
      formatPressId: null,
      formatInternetId: null,
      formatInternetSTId: null,
      emissionTypeRadioId: null,
      emissionTypeCinemaId: null,
      purchaseFormInternetId: null,
      purchaseFormInternetSTId: null,
      categoryInternetId: null,
      categoryInternetSTId: null,
      categoryLabconId: null,
      copySizeId: null,
      purchaseMethodId: null,
      mediumId: null,
      labconChannelId: null,
    };
  }
  if (positionType === PositionTypeEnum.TransferredCommission) {
    return {
      salesNet: null,
      isUpSell: false,
      netCustomerAccount: null,
      numberOfMedia: null,
      additionalDescription: null,
      orderNumber: null,
      ownName: null,
      technologyDictionaryId: null,
      regionDictionaryId: null,
      formatRadioId: null,
      formatPressId: null,
      formatInternetId: null,
      formatInternetSTId: null,
      emissionTypeRadioId: null,
      emissionTypeCinemaId: null,
      purchaseFormInternetId: null,
      purchaseFormInternetSTId: null,
      categoryInternetId: null,
      categoryInternetSTId: null,
      categoryLabconId: null,
      copySizeId: null,
      purchaseMethodId: null,
      mediumId: null,
      labconChannelId: null,
    };
  }
};

export const isFieldHidden = (
  key: string,
  positionType: CreateUpdateEstimateItemDtoPositionTypeEnum,
  commission?: boolean,
): boolean => {
  const nonCommissionKeys = [
    'netCustomerAccount',
    'numberOfMedia',
    'additionalDescription',
    'orderNumber',
    'ownName',
    'technologyDictionary',
    'regionDictionary',
    'formatRadio',
    'formatPress',
    'formatInternet',
    'formatInternetST',
    'emissionTypeRadio',
    'emissionTypeCinema',
    'purchaseFormInternet',
    'purchaseFormInternetST',
    'categoryInternet',
    'categoryInternetST',
    'categoryLabcon',
    'copySize',
    'purchaseMethod',
    'medium',
  ];

  if (!commission || !key) {
    return false;
  }
  if (key === 'salesCommissionPercent') {
    return true;
  }
  if (
    key === 'salesNet' &&
    (positionType === PositionTypeEnum.TransferredCommission ||
      positionType === PositionTypeEnum.Commission)
  ) {
    return true;
  }
  if (
    key === 'salesCommissionAmount' &&
    (positionType === PositionTypeEnum.Standard || positionType === undefined)
  ) {
    return true;
  }
  if (
    key === 'purchaseNetTotal' &&
    positionType === PositionTypeEnum.Commission
  ) {
    return true;
  }
  if (
    (positionType === PositionTypeEnum.TransferredCommission ||
      positionType === PositionTypeEnum.Commission) &&
    nonCommissionKeys.includes(key)
  ) {
    return true;
  }
  return false;
};

export const isSalesTotalDisabled = (
  schema: ProfileSchemaDto,
  item: CreateUpdateEstimateItemDto,
  commission?: boolean,
): boolean => {
  return (
    ((schema.rcSalesTotal &&
      !isFieldHidden('rcSalesTotal', item.positionType, commission)) ||
      (schema.salesSurchargePercent &&
        !isFieldHidden(
          'salesSurchargePercent',
          item.positionType,
          commission,
        )) ||
      (schema.salesDiscountPercent &&
        !isFieldHidden(
          'salesDiscountPercent',
          item.positionType,
          commission,
        )) ||
      (schema.salesNet &&
        !isFieldHidden('salesNet', item.positionType, commission)) ||
      (schema.salesCommissionPercent &&
        !isFieldHidden(
          'salesCommissionPercent',
          item.positionType,
          commission,
        )) ||
      (schema.salesCommissionAmount &&
        !isFieldHidden(
          'salesCommissionAmount',
          item.positionType,
          commission,
        )) ||
      (schema.salesNetTechnicalCost &&
        !isFieldHidden(
          'salesNetTechnicalCost',
          item.positionType,
          commission,
        ))) === true
  );
};

export const isPurchaseTotalDisabled = (
  schema: ProfileSchemaDto,
  item: CreateUpdateEstimateItemDto,
  commission?: boolean,
): boolean => {
  return (
    ((schema.rcPurchase &&
      !isFieldHidden('rcPurchase', item.positionType, commission)) ||
      (schema.purchaseSurchargePercent &&
        !isFieldHidden(
          'purchaseSurchargePercent',
          item.positionType,
          commission,
        )) ||
      (schema.purchaseDiscountPercent &&
        !isFieldHidden(
          'purchaseDiscountPercent',
          item.positionType,
          commission,
        )) ||
      (schema.purchaseNet &&
        !isFieldHidden('purchaseNet', item.positionType, commission)) ||
      (schema.purchaseTechnicalCost &&
        !isFieldHidden(
          'purchaseTechnicalCost',
          item.positionType,
          commission,
        ))) === true
  );
};
