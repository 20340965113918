/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateAttachmentDto
 */
export interface CreateUpdateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    dictionaryTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    dictionaryShortname?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    name?: string | null;
    /**
     * 
     * @type {object}
     * @memberof CreateUpdateAttachmentDto
     */
    fileContent?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    enObjectType: CreateUpdateAttachmentDtoEnObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    clientId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateAttachmentDto
     */
    estimateItemsIds?: Array<string>;
}


/**
 * @export
 */
export const CreateUpdateAttachmentDtoEnObjectTypeEnum = {
    Budget: 'Budget',
    Project: 'Project',
    PurchaseInvoice: 'PurchaseInvoice',
    EstimateItemCommentMediaplanner: 'EstimateItemCommentMediaplanner',
    EstimateItemCommentFinance: 'EstimateItemCommentFinance'
} as const;
export type CreateUpdateAttachmentDtoEnObjectTypeEnum = typeof CreateUpdateAttachmentDtoEnObjectTypeEnum[keyof typeof CreateUpdateAttachmentDtoEnObjectTypeEnum];


/**
 * Check if a given object implements the CreateUpdateAttachmentDto interface.
 */
export function instanceOfCreateUpdateAttachmentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enObjectType" in value;
    isInstance = isInstance && "objectId" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function CreateUpdateAttachmentDtoFromJSON(json: any): CreateUpdateAttachmentDto {
    return CreateUpdateAttachmentDtoFromJSONTyped(json, false);
}

export function CreateUpdateAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dictionaryTypeId': !exists(json, 'dictionaryTypeId') ? undefined : json['dictionaryTypeId'],
        'dictionaryShortname': !exists(json, 'dictionaryShortname') ? undefined : json['dictionaryShortname'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileContent': !exists(json, 'fileContent') ? undefined : json['fileContent'],
        'enObjectType': json['enObjectType'],
        'objectId': json['objectId'],
        'companyId': json['companyId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'estimateItemsIds': !exists(json, 'estimateItemsIds') ? undefined : json['estimateItemsIds'],
    };
}

export function CreateUpdateAttachmentDtoToJSON(value?: CreateUpdateAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dictionaryTypeId': value.dictionaryTypeId,
        'dictionaryShortname': value.dictionaryShortname,
        'description': value.description,
        'note': value.note,
        'name': value.name,
        'fileContent': value.fileContent,
        'enObjectType': value.enObjectType,
        'objectId': value.objectId,
        'companyId': value.companyId,
        'clientId': value.clientId,
        'estimateItemsIds': value.estimateItemsIds,
    };
}

