import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { CreateUpdateTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';

import { TeamModalEditor } from './TeamModalEditor';

interface TeamEditorDetailsProps {
  id?: string;
  systemNumber?: number;
  team: CreateUpdateTeamDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    team: CreateUpdateTeamDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const TeamDetailsEditor: React.FC<TeamEditorDetailsProps> = props => {
  const [show, setShow] = useState(false);

  const pok = usePokCore();

  useEffect(() => {
    if (props.show) {
      setShow(props.show);
    }
  }, [props.show]);

  const addEditTeam = async (team: CreateUpdateTeamDto) => {
    if (!team.companyId) {
      team.companyId = pok.getCompanyId();
    }

    const retVal = props.onCompleted(team, props.id);
    if ((await retVal).saved) {
      setShow(false);
    }
    return retVal;
  };

  const handleClose = () => {
    setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button onClick={() => setShow(true)} variant="outline-success">
          Edycja
        </Button>
      </div>
      <TeamModalEditor
        id={props?.id}
        systemNumber={props?.systemNumber}
        team={props.team}
        onCompleted={addEditTeam}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};
