import {
  CreateUpdateExcelConfigHeaderDto,
  CreateUpdateExcelConfigHeaderDtoFromJSON,
  DefaultApi,
  GetExcelConfigHeaderDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { ExcelConfigHeaderSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface ExcelConfigHeaderContextInterface {
  getById: (id: string) => Promise<GetExcelConfigHeaderDto>;
  getAllByConfigId: (configId: string) => Promise<GetExcelConfigHeaderDto[]>;
  update: (id: string, dto: CreateUpdateExcelConfigHeaderDto) => Promise<void>;
  create: (
    dto: CreateUpdateExcelConfigHeaderDto,
  ) => Promise<GetExcelConfigHeaderDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const ExcelConfigHeaderContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.excelConfigHeaderControllerGet(id),
    getAllByConfigId: (configId: string) =>
      api.excelConfigHeaderControllerGetAllByConfig(configId),
    update: (id: string, dto: CreateUpdateExcelConfigHeaderDto) =>
      api.excelConfigHeaderControllerUpdate(id, dto),
    create: (dto: CreateUpdateExcelConfigHeaderDto) =>
      api.excelConfigHeaderControllerCreate(dto),
    getLastEditor: (id: string) =>
      api.excelConfigHeaderControllerGetLastEditor(id),
  };
};

export const newHeader = (configId?: string) => {
  return CreateUpdateExcelConfigHeaderDtoFromJSON({
    excelConfigId: configId,
  });
};

export const validate = (header: CreateUpdateExcelConfigHeaderDto) =>
  validateAgainst(ExcelConfigHeaderSchema, header);

export const convert = (
  header?: GetExcelConfigHeaderDto,
  configId?: string,
) => {
  if (!header) {
    return newHeader(configId);
  }
  const converted = CreateUpdateExcelConfigHeaderDtoFromJSON(header);
  return converted;
};
