import React, { useState, useEffect } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetBrandDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface BrandSelectorProps {
  purchaserId?: string;
  readOnly?: boolean;
  className?: string;
  onChange?: (option: Option) => void;
  value?: string;
}

export default (props: BrandSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [brands, setBrands] = useState<GetBrandDto[]>([]);

  useEffect(() => {
    let mounted = true;
    if (props.purchaserId) {
      pok.brands
        .getAllByPurchaserId(props.purchaserId)
        .then(data => {
          if (mounted) {
            setBrands(data);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setBrands([]);
    }
    return () => {
      mounted = false;
    };
  }, [notifications, pok.brands, props.purchaserId]);

  const provider = async (text: string) => {
    const values = brands.filter(
      v =>
        v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) &&
        v.active,
    );

    return values.map(v => {
      return {
        badge: '',
        label: v.name,
        value: v.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={'brand-' + JSON.stringify(brands)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      clearable={false}
      provider={provider}
      deletedValueName={id => pok.brands.getOptionById(id)}
      value={props.value}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
