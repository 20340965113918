import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import * as mime from 'mime';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface DownloadAttachmentProps {
  id: string;
  icon?: Icon.Icon;
  iconSize?: number;
  className?: string;
  tooltipText?: string;
  modalTitleText?: string;
}

export const DownloadAttachment: React.FC<DownloadAttachmentProps> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [note, setNote] = useState('');

  const IconA = props.icon ? props.icon : Icon.FileImage;

  const handleClick = () => {
    setNote('');
    setShowInfoModal(false);
    pok.attachments
      .getFile(props.id)
      .then(doc => {
        if (doc.buffer) {
          const file = new Blob([Buffer.from(doc.buffer as ArrayBuffer)], {
            type: mime.getType(doc.name) || undefined,
          });
          window.open(URL.createObjectURL(file));
        } else if (doc.note) {
          setNote(doc.note);
          setShowInfoModal(true);
        }
      })
      .catch(error => {
        notifications.caughtError(error);
      });
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>{props.tooltipText ?? 'Otwórz'}</Tooltip>}
      >
        <Button
          className="ps-2 py-0"
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <IconA
            className={props.className ?? 'icon-blue'}
            size={props.iconSize ?? 22}
          />
        </Button>
      </OverlayTrigger>
      <Modal
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{props.modalTitleText || 'Notatka'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{note}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowInfoModal(false)}
          >
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
