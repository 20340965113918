import {
  DefaultApi,
  GetDictionaryDto,
  CreateUpdateDictionaryDto,
  CreateUpdateDictionaryDtoFromJSON,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { DictionarySchema } from '../validation/schemas';

export interface DictionaryContextInterface {
  getById: (id: string) => Promise<GetDictionaryDto>;
  getActive: () => Promise<GetDictionaryDto[]>;
  update: (id: string, dto: CreateUpdateDictionaryDto) => Promise<void>;
  create: (dto: CreateUpdateDictionaryDto) => Promise<GetDictionaryDto>;
  changeToInactive: (id: string) => Promise<void>;
  getByDictionaryType: (typeName: string) => Promise<GetDictionaryDto[]>;
  getByDictionaryTypeAndShortname: (
    typeName: string,
    symbol: string,
  ) => Promise<GetDictionaryDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const DictionaryContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.dictionaryControllerGet(id),
    getActive: () =>
      api
        .dictionaryControllerGetActive()
        .then(dict =>
          dict.sort((a, b) => a.value.localeCompare(b.value, 'pl')),
        ),
    update: (id: string, dto: CreateUpdateDictionaryDto) =>
      api.dictionaryControllerUpdate(id, dto),
    create: (dto: CreateUpdateDictionaryDto) =>
      api.dictionaryControllerCreate(dto),
    changeToInactive: (id: string) =>
      api.dictionaryControllerChangeToInactive(id),
    getByDictionaryType: (typeName: string) =>
      api.dictionaryControllerGetByType(typeName),
    getByDictionaryTypeAndShortname: (typeName: string, symbol: string) =>
      api.dictionaryControllerGetByTypeAndShortname(typeName, symbol),
    getLastEditor: (id: string) => api.dictionaryControllerGetLastEditor(id),
  };
};

export const newDictionary = (parentId?: string, dictionaryTypeId?: string) => {
  return CreateUpdateDictionaryDtoFromJSON({
    parentId: parentId,
    dictionaryTypeId: dictionaryTypeId,
  });
};

export const validate = (dictionary: CreateUpdateDictionaryDto) =>
  validateAgainst(DictionarySchema, dictionary);

export const convert = (dictionary: GetDictionaryDto) => {
  if (!dictionary) {
    return newDictionary();
  }
  const converted = CreateUpdateDictionaryDtoFromJSON(dictionary);
  converted.dictionaryTypeId = dictionary.dictionaryType.id;

  return converted;
};
