/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SchemaAttributesDto } from './SchemaAttributesDto';
import {
    SchemaAttributesDtoFromJSON,
    SchemaAttributesDtoFromJSONTyped,
    SchemaAttributesDtoToJSON,
} from './SchemaAttributesDto';

/**
 * 
 * @export
 * @interface ProfileSchemaDto
 */
export interface ProfileSchemaDto {
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    rcSalesTotal?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesSurchargePercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesDiscountPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesNet?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesCommissionPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesCommissionAmount?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesNetTechnicalCost?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    salesNetTotal?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    rcPurchase?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseTechnicalCost?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseDiscountPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseNetTotal?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseNet?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseSurchargePercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    numberOfMedia?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    technologyDictionary?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    regionDictionary?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    additionalDescription?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    formatRadio?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    formatPress?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    formatInternet?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    formatInternetST?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    emissionTypeRadio?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    emissionTypeCinema?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseFormInternet?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseFormInternetST?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    categoryInternet?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    categoryInternetST?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    categoryLabcon?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    isUpSell?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    netCustomerAccount?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    copySize?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    purchaseMethod?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    orderNumber?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    medium?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    ownName?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    labconChannel?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    service?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    action?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    serviceExternalWorkPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    serviceCreationPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    serviceChangeProductionPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    serviceStrategyPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    serviceProductionBroadcastPercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    serviceClientServicePercent?: SchemaAttributesDto;
    /**
     * 
     * @type {SchemaAttributesDto}
     * @memberof ProfileSchemaDto
     */
    actionGroup?: SchemaAttributesDto;
}

/**
 * Check if a given object implements the ProfileSchemaDto interface.
 */
export function instanceOfProfileSchemaDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileSchemaDtoFromJSON(json: any): ProfileSchemaDto {
    return ProfileSchemaDtoFromJSONTyped(json, false);
}

export function ProfileSchemaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileSchemaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rcSalesTotal': !exists(json, 'rcSalesTotal') ? undefined : SchemaAttributesDtoFromJSON(json['rcSalesTotal']),
        'salesSurchargePercent': !exists(json, 'salesSurchargePercent') ? undefined : SchemaAttributesDtoFromJSON(json['salesSurchargePercent']),
        'salesDiscountPercent': !exists(json, 'salesDiscountPercent') ? undefined : SchemaAttributesDtoFromJSON(json['salesDiscountPercent']),
        'salesNet': !exists(json, 'salesNet') ? undefined : SchemaAttributesDtoFromJSON(json['salesNet']),
        'salesCommissionPercent': !exists(json, 'salesCommissionPercent') ? undefined : SchemaAttributesDtoFromJSON(json['salesCommissionPercent']),
        'salesCommissionAmount': !exists(json, 'salesCommissionAmount') ? undefined : SchemaAttributesDtoFromJSON(json['salesCommissionAmount']),
        'salesNetTechnicalCost': !exists(json, 'salesNetTechnicalCost') ? undefined : SchemaAttributesDtoFromJSON(json['salesNetTechnicalCost']),
        'salesNetTotal': !exists(json, 'salesNetTotal') ? undefined : SchemaAttributesDtoFromJSON(json['salesNetTotal']),
        'rcPurchase': !exists(json, 'rcPurchase') ? undefined : SchemaAttributesDtoFromJSON(json['rcPurchase']),
        'purchaseTechnicalCost': !exists(json, 'purchaseTechnicalCost') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseTechnicalCost']),
        'purchaseDiscountPercent': !exists(json, 'purchaseDiscountPercent') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseDiscountPercent']),
        'purchaseNetTotal': !exists(json, 'purchaseNetTotal') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseNetTotal']),
        'purchaseNet': !exists(json, 'purchaseNet') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseNet']),
        'purchaseSurchargePercent': !exists(json, 'purchaseSurchargePercent') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseSurchargePercent']),
        'numberOfMedia': !exists(json, 'numberOfMedia') ? undefined : SchemaAttributesDtoFromJSON(json['numberOfMedia']),
        'technologyDictionary': !exists(json, 'technologyDictionary') ? undefined : SchemaAttributesDtoFromJSON(json['technologyDictionary']),
        'regionDictionary': !exists(json, 'regionDictionary') ? undefined : SchemaAttributesDtoFromJSON(json['regionDictionary']),
        'additionalDescription': !exists(json, 'additionalDescription') ? undefined : SchemaAttributesDtoFromJSON(json['additionalDescription']),
        'formatRadio': !exists(json, 'formatRadio') ? undefined : SchemaAttributesDtoFromJSON(json['formatRadio']),
        'formatPress': !exists(json, 'formatPress') ? undefined : SchemaAttributesDtoFromJSON(json['formatPress']),
        'formatInternet': !exists(json, 'formatInternet') ? undefined : SchemaAttributesDtoFromJSON(json['formatInternet']),
        'formatInternetST': !exists(json, 'formatInternetST') ? undefined : SchemaAttributesDtoFromJSON(json['formatInternetST']),
        'emissionTypeRadio': !exists(json, 'emissionTypeRadio') ? undefined : SchemaAttributesDtoFromJSON(json['emissionTypeRadio']),
        'emissionTypeCinema': !exists(json, 'emissionTypeCinema') ? undefined : SchemaAttributesDtoFromJSON(json['emissionTypeCinema']),
        'purchaseFormInternet': !exists(json, 'purchaseFormInternet') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseFormInternet']),
        'purchaseFormInternetST': !exists(json, 'purchaseFormInternetST') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseFormInternetST']),
        'categoryInternet': !exists(json, 'categoryInternet') ? undefined : SchemaAttributesDtoFromJSON(json['categoryInternet']),
        'categoryInternetST': !exists(json, 'categoryInternetST') ? undefined : SchemaAttributesDtoFromJSON(json['categoryInternetST']),
        'categoryLabcon': !exists(json, 'categoryLabcon') ? undefined : SchemaAttributesDtoFromJSON(json['categoryLabcon']),
        'isUpSell': !exists(json, 'isUpSell') ? undefined : SchemaAttributesDtoFromJSON(json['isUpSell']),
        'netCustomerAccount': !exists(json, 'netCustomerAccount') ? undefined : SchemaAttributesDtoFromJSON(json['netCustomerAccount']),
        'copySize': !exists(json, 'copySize') ? undefined : SchemaAttributesDtoFromJSON(json['copySize']),
        'purchaseMethod': !exists(json, 'purchaseMethod') ? undefined : SchemaAttributesDtoFromJSON(json['purchaseMethod']),
        'orderNumber': !exists(json, 'orderNumber') ? undefined : SchemaAttributesDtoFromJSON(json['orderNumber']),
        'medium': !exists(json, 'medium') ? undefined : SchemaAttributesDtoFromJSON(json['medium']),
        'ownName': !exists(json, 'ownName') ? undefined : SchemaAttributesDtoFromJSON(json['ownName']),
        'labconChannel': !exists(json, 'labconChannel') ? undefined : SchemaAttributesDtoFromJSON(json['labconChannel']),
        'service': !exists(json, 'service') ? undefined : SchemaAttributesDtoFromJSON(json['service']),
        'action': !exists(json, 'action') ? undefined : SchemaAttributesDtoFromJSON(json['action']),
        'serviceExternalWorkPercent': !exists(json, 'serviceExternalWorkPercent') ? undefined : SchemaAttributesDtoFromJSON(json['serviceExternalWorkPercent']),
        'serviceCreationPercent': !exists(json, 'serviceCreationPercent') ? undefined : SchemaAttributesDtoFromJSON(json['serviceCreationPercent']),
        'serviceChangeProductionPercent': !exists(json, 'serviceChangeProductionPercent') ? undefined : SchemaAttributesDtoFromJSON(json['serviceChangeProductionPercent']),
        'serviceStrategyPercent': !exists(json, 'serviceStrategyPercent') ? undefined : SchemaAttributesDtoFromJSON(json['serviceStrategyPercent']),
        'serviceProductionBroadcastPercent': !exists(json, 'serviceProductionBroadcastPercent') ? undefined : SchemaAttributesDtoFromJSON(json['serviceProductionBroadcastPercent']),
        'serviceClientServicePercent': !exists(json, 'serviceClientServicePercent') ? undefined : SchemaAttributesDtoFromJSON(json['serviceClientServicePercent']),
        'actionGroup': !exists(json, 'actionGroup') ? undefined : SchemaAttributesDtoFromJSON(json['actionGroup']),
    };
}

export function ProfileSchemaDtoToJSON(value?: ProfileSchemaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rcSalesTotal': SchemaAttributesDtoToJSON(value.rcSalesTotal),
        'salesSurchargePercent': SchemaAttributesDtoToJSON(value.salesSurchargePercent),
        'salesDiscountPercent': SchemaAttributesDtoToJSON(value.salesDiscountPercent),
        'salesNet': SchemaAttributesDtoToJSON(value.salesNet),
        'salesCommissionPercent': SchemaAttributesDtoToJSON(value.salesCommissionPercent),
        'salesCommissionAmount': SchemaAttributesDtoToJSON(value.salesCommissionAmount),
        'salesNetTechnicalCost': SchemaAttributesDtoToJSON(value.salesNetTechnicalCost),
        'salesNetTotal': SchemaAttributesDtoToJSON(value.salesNetTotal),
        'rcPurchase': SchemaAttributesDtoToJSON(value.rcPurchase),
        'purchaseTechnicalCost': SchemaAttributesDtoToJSON(value.purchaseTechnicalCost),
        'purchaseDiscountPercent': SchemaAttributesDtoToJSON(value.purchaseDiscountPercent),
        'purchaseNetTotal': SchemaAttributesDtoToJSON(value.purchaseNetTotal),
        'purchaseNet': SchemaAttributesDtoToJSON(value.purchaseNet),
        'purchaseSurchargePercent': SchemaAttributesDtoToJSON(value.purchaseSurchargePercent),
        'numberOfMedia': SchemaAttributesDtoToJSON(value.numberOfMedia),
        'technologyDictionary': SchemaAttributesDtoToJSON(value.technologyDictionary),
        'regionDictionary': SchemaAttributesDtoToJSON(value.regionDictionary),
        'additionalDescription': SchemaAttributesDtoToJSON(value.additionalDescription),
        'formatRadio': SchemaAttributesDtoToJSON(value.formatRadio),
        'formatPress': SchemaAttributesDtoToJSON(value.formatPress),
        'formatInternet': SchemaAttributesDtoToJSON(value.formatInternet),
        'formatInternetST': SchemaAttributesDtoToJSON(value.formatInternetST),
        'emissionTypeRadio': SchemaAttributesDtoToJSON(value.emissionTypeRadio),
        'emissionTypeCinema': SchemaAttributesDtoToJSON(value.emissionTypeCinema),
        'purchaseFormInternet': SchemaAttributesDtoToJSON(value.purchaseFormInternet),
        'purchaseFormInternetST': SchemaAttributesDtoToJSON(value.purchaseFormInternetST),
        'categoryInternet': SchemaAttributesDtoToJSON(value.categoryInternet),
        'categoryInternetST': SchemaAttributesDtoToJSON(value.categoryInternetST),
        'categoryLabcon': SchemaAttributesDtoToJSON(value.categoryLabcon),
        'isUpSell': SchemaAttributesDtoToJSON(value.isUpSell),
        'netCustomerAccount': SchemaAttributesDtoToJSON(value.netCustomerAccount),
        'copySize': SchemaAttributesDtoToJSON(value.copySize),
        'purchaseMethod': SchemaAttributesDtoToJSON(value.purchaseMethod),
        'orderNumber': SchemaAttributesDtoToJSON(value.orderNumber),
        'medium': SchemaAttributesDtoToJSON(value.medium),
        'ownName': SchemaAttributesDtoToJSON(value.ownName),
        'labconChannel': SchemaAttributesDtoToJSON(value.labconChannel),
        'service': SchemaAttributesDtoToJSON(value.service),
        'action': SchemaAttributesDtoToJSON(value.action),
        'serviceExternalWorkPercent': SchemaAttributesDtoToJSON(value.serviceExternalWorkPercent),
        'serviceCreationPercent': SchemaAttributesDtoToJSON(value.serviceCreationPercent),
        'serviceChangeProductionPercent': SchemaAttributesDtoToJSON(value.serviceChangeProductionPercent),
        'serviceStrategyPercent': SchemaAttributesDtoToJSON(value.serviceStrategyPercent),
        'serviceProductionBroadcastPercent': SchemaAttributesDtoToJSON(value.serviceProductionBroadcastPercent),
        'serviceClientServicePercent': SchemaAttributesDtoToJSON(value.serviceClientServicePercent),
        'actionGroup': SchemaAttributesDtoToJSON(value.actionGroup),
    };
}

