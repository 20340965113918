import { DefaultApi } from '../autogenerated/pokApiClient';

export interface SavedPdfContextInterface {
  hasInvoiceSavedPdf: (invoiceId: string) => Promise<boolean>;
}

export const SavedPdfContext = (api: DefaultApi) => {
  return {
    hasInvoiceSavedPdf: (invoiceId: string) =>
      api
        .savedPdfControllerHasInvoiceSavedPdf(invoiceId)
        .then(hasPdf => (hasPdf as unknown as string) === 'true'),
  };
};
