import React from 'react';

import { InvoiceStatusEnum } from '../../../common/pokCore/validation/schemas';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { CreateUpdateSalesInvoiceDtoStatusEnum } from '../../../common/pokCore/autogenerated/pokApiClient';

interface SalesInvoiceDeactivateConfirmProps {
  invoiceStatus: CreateUpdateSalesInvoiceDtoStatusEnum;
  isDeactivated?: boolean;
  handleRemove: () => void;
  disabled: boolean;
}

export const SalesInvoiceDeactivateConfirm: React.FC<
  SalesInvoiceDeactivateConfirmProps
> = ({ invoiceStatus, isDeactivated, handleRemove, disabled }) => {
  if (invoiceStatus !== InvoiceStatusEnum.Draft || isDeactivated) {
    return;
  }

  return (
    <ConfirmationButton
      onOK={handleRemove}
      confirmation="Czy na pewno chcesz usunąć wyświetlany szkic faktury?"
      variant="outline-danger"
      disabled={disabled}
    >
      Usuń
    </ConfirmationButton>
  );
};
