/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DecisionSpecialInvoicingDto
 */
export interface DecisionSpecialInvoicingDto {
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    note: string;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionSpecialInvoicingDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    companyId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DecisionSpecialInvoicingDto
     */
    projectIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    correctionNote: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    financeNote: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionSpecialInvoicingDto
     */
    status: DecisionSpecialInvoicingDtoStatusEnum;
}


/**
 * @export
 */
export const DecisionSpecialInvoicingDtoStatusEnum = {
    New: 'New',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
    ToBeCorrected: 'ToBeCorrected'
} as const;
export type DecisionSpecialInvoicingDtoStatusEnum = typeof DecisionSpecialInvoicingDtoStatusEnum[keyof typeof DecisionSpecialInvoicingDtoStatusEnum];


/**
 * Check if a given object implements the DecisionSpecialInvoicingDto interface.
 */
export function instanceOfDecisionSpecialInvoicingDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "projectIds" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "correctionNote" in value;
    isInstance = isInstance && "financeNote" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DecisionSpecialInvoicingDtoFromJSON(json: any): DecisionSpecialInvoicingDto {
    return DecisionSpecialInvoicingDtoFromJSONTyped(json, false);
}

export function DecisionSpecialInvoicingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionSpecialInvoicingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'note': json['note'],
        'active': json['active'],
        'companyId': json['companyId'],
        'projectIds': json['projectIds'],
        'rejectedNote': json['rejectedNote'],
        'correctionNote': json['correctionNote'],
        'financeNote': json['financeNote'],
        'status': json['status'],
    };
}

export function DecisionSpecialInvoicingDtoToJSON(value?: DecisionSpecialInvoicingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'note': value.note,
        'active': value.active,
        'companyId': value.companyId,
        'projectIds': value.projectIds,
        'rejectedNote': value.rejectedNote,
        'correctionNote': value.correctionNote,
        'financeNote': value.financeNote,
        'status': value.status,
    };
}

