import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  CreateUpdateSymphonyClientDto,
  GetSymphonyClientDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  validate,
  convert,
  newSymphonyCode,
} from '../../../common/pokCore/contexts/SymphonyClientContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

import { SymphonyEditor } from './SymphonyEditor';
import { SymphonySearch } from './SymphonySearch';

interface SymphonyTabProps {
  clientId: string;
  filterToCurrentCompany?: boolean;
}

export const SymphonyTab: React.FC<SymphonyTabProps> = props => {
  const [show, setShow] = useState<boolean>(false);
  const [symphonyClient, setSymphonyClient] = useState<GetSymphonyClientDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const pok = usePokCore();

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.symphonyClients.getAllByPortionForClient(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          props.clientId ? props.clientId : undefined,
          props.filterToCurrentCompany,
        )) as GridGetterFunction,
    [pok.symphonyClients, props.clientId, props.filterToCurrentCompany],
  );

  const getCount = useMemo(
    () =>
      (filterText =>
        pok.symphonyClients.getAllCountForClient(
          filterText,
          props.clientId ? props.clientId : undefined,
          props.filterToCurrentCompany,
        )) as GridCountFunction,
    [pok.symphonyClients, props.clientId, props.filterToCurrentCompany],
  );

  const handleAdd = () => {
    setShow(true);
    setSymphonyClient(undefined);
  };

  const handleClose = () => {
    setShow(false);
  };

  const addEditSymphonyClient = async (
    symphonyClientCU: CreateUpdateSymphonyClientDto,
    id?: string,
  ) => {
    const status = await validate(symphonyClientCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.symphonyClients.update(id, symphonyClientCU);
        } else {
          await pok.symphonyClients.create(symphonyClientCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClick = async (id: string) => {
    const getSymphonyClient = await pok.symphonyClients.getById(id);
    setSymphonyClient(getSymphonyClient);
    setShow(true);
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleAdd}>
        Nowy kod
      </Button>
      <SymphonySearch
        getData={getData}
        getCount={getCount}
        onRowClick={handleClick}
        refresh={refresh}
      />
      <SymphonyEditor
        id={symphonyClient?.id}
        symphonyClient={
          symphonyClient
            ? convert(symphonyClient)
            : newSymphonyCode(props.clientId)
        }
        onCompleted={addEditSymphonyClient}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};
