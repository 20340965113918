import React from 'react';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import { Option } from '../Selects/Selector';
import DictionaryMultiSelector from '../Selects/DictionaryMultiSelector';

interface SalesInvoiceFormCodesProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
}

export const SalesInvoiceFormCodes: React.FC<SalesInvoiceFormCodesProps> = ({
  salesInvoice,
  propertyChange,
  readOnly,
}) => {
  return (
    <>
      <FormRow controlId="documentCodeIds" label="Kody dokumentu">
        <DictionaryMultiSelector
          dictionary="Kody dokumentu"
          value={salesInvoice?.documentCodeIds}
          onChange={options =>
            propertyChange({
              documentCodeIds:
                (options as Option[])?.map(({ value }) => value) || [],
            })
          }
          readOnly={readOnly}
        />
      </FormRow>
      <FormRow controlId="gtuCodeId" label="Kod GTU">
        <DictionarySelector
          dictionary="Kody GTU"
          value={salesInvoice?.gtuCodeId}
          onChange={option =>
            propertyChange({
              gtuCodeId: option.value,
            })
          }
          clearable
          readOnly={readOnly}
        />
      </FormRow>
    </>
  );
};
