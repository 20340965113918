import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetClientWithSymphonyCodeDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface ClientSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  withMerging?: boolean;
  clientHeadId?: string;
  refresh?: boolean;
}

export const clientForGrid = (
  client: GetClientWithSymphonyCodeDto & { headId?: string },
) => {
  return {
    key: client.id,
    values: {
      'client.systemNumber': client.systemNumber,
      'client.dealGroupNumber': client.dealGroupNumber,
      'client.name': client.name,
      'client.nip': client.nip,
      'client.country': client.country,
      'client.place': client.place,
      'client.street': client.street,
      'client.numbers': client.numbers,
      'client.flat': client.flat,
      'client.addressEx': client.addressEx,
      'symphonyClient.code': client.symphonyCode, // used in ClientWithSymphonyCodeSearch
    },
  };
};

export const ClientSearch: React.FC<ClientSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'client.systemNumber' },
    { header: 'Grupowanie deali', property: 'client.dealGroupNumber' },
    { header: 'Nazwa', property: 'client.name' },
    { header: 'NIP', property: 'client.nip' },
    { header: 'Państwo', property: 'client.country' },
    { header: 'Miejscowość', property: 'client.place' },
    { header: 'Ulica', property: 'client.street' },
    { header: 'Numer', property: 'client.numbers' },
    { header: 'Lokal', property: 'client.flat' },
    {
      header: 'Adres zagraniczny',
      property: 'client.addressEx',
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="client.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak kontrahentów w systemie"
      additionalId={props.clientHeadId}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
      withFilterInColumns={true}
    />
  );
};
